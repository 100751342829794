import { SBBox, SBTypography } from "modules/Commons";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

export const SBTableToolbarContainer = ({
	showCount,
	count,
	itemsCount,
	selectedItems,
	editActions,
	onApply,
}: any) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const Component = () => editActions({ selectedItems, onApply });

	return (
		<>
			<SBBox
				data-testid="sb-table-toolbar-container"
				display="flex"
				alignItems="center"
				className={classes.tableTopbar}
			>
				<SBTypography mx={2}>
					{clsx(
						showCount && count,
						showCount && t("item", { count: count }),
						itemsCount > 0 && showCount && "|",
						itemsCount > 0 && itemsCount,
						itemsCount > 0 &&
						t("selectedItem", { count: itemsCount })
					)}
				</SBTypography>
				{Component && <Component />}
			</SBBox>
		</>
	);
};
