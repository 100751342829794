import { styled, alpha } from "@mui/system";
import { Slider } from "@mui/material";

export const SBStyledDateSlider = styled(Slider)(
	({ theme }) => `
		& .MuiSlider-rail {
			border-radius: 2px;
			background-color: currentColor;
			height: 18px;
			opacity: 0.38;
		}

		& .MuiSlider-track {
			display: block;
			position: absolute;
			height: 18px;
			border-radius: 2px;
			background-color: currentColor;
		}

		& .MuiSlider-thumb {
			position: absolute;
			width: 8px;
			height: 16px;
			margin-left: 1px;
			box-sizing: border-box;
			border-radius: 4px;
			outline: 0;
			border: 1px solid ${theme.palette.gray4.main};
			background-color: ${theme.palette.gray5.main};

			z-index: 9;
			:hover,
			&.Mui-focusVisible {
			box-shadow: 0 0 0 0.25rem ${alpha(
				theme.palette.mode === "light" ? "#1976d2" : "#90caf9",
				0.15
			)};
			}

			&.Mui-active {
			box-shadow: 0 0 0 0.25rem ${alpha(
				theme.palette.mode === "light" ? "#1976d2" : "#90caf9",
				0.3
			)};
			}
		}

		& .MuiSlider-markLabel {
			z-index: 10; 
			top: 18px;
			margin-left: 15px;
			font-weight: bold;
			pointer-event: none;
		}

		&.MuiSlider-root {
			margin-bottom: 20px;
			padding:0;
			height: 12px;
		}

		& .MuiSlider-mark {
			background-color: ${theme.palette.gray4.main};
			width: 1px;
			height: 40px;
			top: 17px;
			z-index: 0;
			display: none;
			pointer-event: none;

			& ~ .MuiSlider-mark {
				display: inherit;
			}
		}
	`
);
