import { SBTextField, SBTooltip } from "modules/Commons";
import SBTableCell, { SBTableCellProps } from "../SBTableCell";
import { useFormContext } from "react-hook-form";
import { TableCellType } from "../tableCellType";
import { useState } from "react";
import _ from "lodash";

export default ({
	value,
	type,
	name,
	padding,
	onChange,
	style,
	align = "left",
}: SBEditableTableCellProps) => {
	const form = useFormContext();
	const [v, setV] = useState(value);

	const hasForm = !!form;
	const staticValue = form ? form.getValues(name) : "";

	const [showField, setShowField] = useState(false);

	return (
		<SBTableCell
			align={align}
			padding={padding}
			style={{ pointerEvents: "all", minWidth: 100, ...style }}
		>
			{hasForm ? (
				<>
					{showField ? (
						<SBTextField
							prop={name}
							type={type as any}
							onBlur={() => setShowField(false)}
							inputProps={{
								autoFocus: true,
								style: {
									textAlign: "center",
									padding: "3px 5px 3px 5px",
									backgroundColor: "white",
									fontSize: 12,
								},
							}}
						/>
					) : (
						<SBTooltip
							placement="right"
							title={
								_.get((form as any).errors, name)?.message ?? ""
							}
						>
							<div
								style={{
									pointerEvents: "all",
									border: "1px solid ",
									borderColor: !!_.get(
										(form as any).errors,
										name
									)?.message
										? "#ff000088"
										: "#00000044",
									textAlign: "center",
									padding: "3px 5px 3px 5px",
									backgroundColor: "white",
									borderRadius: 4,
									minHeight: 24,
								}}
								onClick={() => setShowField(true)}
							>
								{staticValue}
							</div>
						</SBTooltip>
					)}
				</>
			) : (
				<SBTextField
					onBlur={(event: any) => onChange(event.target.value)}
					onChange={(event: any) => setV(event.target.value)}
					value={v}
					type={type === TableCellType.Number ? "number" : "text"}
					inputProps={{
						style: {
							textAlign: "right",
							padding: "3px 10px 3px 5px",
							backgroundColor: "white",
							pointerEvents: "all",
						},
					}}
				/>
			)}
		</SBTableCell>
	);
};

export interface SBEditableTableCellProps
	extends Omit<SBTableCellProps, "onChange"> {
	onChange: (e: number | string | null) => void;
	value: string | number;
	type: string;
	name: string;
}
