import { SBTranslate } from "i18n";
import { SBBox, SBSelect, SBTypography } from "modules/Commons";
import { RecommendationMode } from "modules/InventoryConfig/models/recommendationMode";
import { useContext, useEffect, useState } from "react";
import { TipsContext } from "views/ConfigsView/ConfigTips";

const ConfigRecommendationMode = ({ value, onUpdate }: ConfigRecommendationModeProps) => {
	const tipsContext = useContext(TipsContext);

	const [recommendationMode, setRecommendationMode] = useState<RecommendationMode | null>(value);
	const recomendationModeValues = [
		{ text: SBTranslate("notDefined"), value: RecommendationMode.RecommendationNotDefined, tipsContext: "NotDefined", },
		{ text: SBTranslate("onDemand"), value: RecommendationMode.RecommendationOnDemand, tipsContext: "SupplyRecommendatioOnDemand" },
		{ text: SBTranslate("scheduled"), value: RecommendationMode.RecommendationScheduled, tipsContext: "SupplyRecommendationScheduled" },
		{ text: SBTranslate("never"), value: RecommendationMode.RecommendationNever, tipsContext: "SupplyRecommendationNever" },
	]

	const updateRecommendationMode = (value: number) => {
		tipsContext.showTip(recomendationModeValues.find(r => r.value === value)?.tipsContext ?? "");
		if (value === RecommendationMode.RecommendationNotDefined)
			setRecommendationMode(null);
		else
			setRecommendationMode(value);
	}

	useEffect(() => {
		onUpdate(recommendationMode);
	}, [recommendationMode]);

	return (
		<SBBox>
			<SBTypography mb={1}
				fontWeight="500"
				color="gray3.main"
				variant={"body1"}
				textTransform={"uppercase"}  >
				{SBTranslate("receiveRecommendation")}
			</SBTypography>
			<SBSelect
				fullWidth
				getText={item => item.text}
				getValue={item => item.value}
				onChange={(event) => updateRecommendationMode(Number(event.target.value))}
				value={recommendationMode ?? RecommendationMode.RecommendationNotDefined}
				items={recomendationModeValues}
			></SBSelect>

		</SBBox>
	);
};

interface ConfigRecommendationModeProps {
	value: RecommendationMode;
	onUpdate: (v: RecommendationMode | null) => void;
}
export default ConfigRecommendationMode;
