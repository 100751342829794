import { SBTypography } from "modules/Commons";
import { SBBox } from "modules/Commons";
import PropTypes from "prop-types";
import { useStyles } from "./style";

export const SBTableToolbarTitle = ({ title }: { title: string }) => {
	const classes = useStyles();

	return (
		<SBBox
			data-testid="sb-table-toolbar-title"
			className={classes.tableTopbarTitle}
		>
			<SBTypography noWrap fontWeight="bold">
				{title}
			</SBTypography>
		</SBBox>
	);
};

SBTableToolbarTitle.propTypes = {
	title: PropTypes.string,
};
