import { SBTranslate } from "i18n"
import { SBBox, SBCard, SBChip, SBLoading, SBTypography } from "modules/Commons"
import { useStyles } from "./style";
import { useSystemTheme } from "themes/base";
import { useEffect, useRef, useState } from "react";
import { INotification } from "domain/Utils/models/INotification";
import { SystemNotificationsRequester } from "modules/SystemNotification/services/SystemNotificationsRequester";
import SBNotificationCard from "views/Commons/SBNotification/SBNotificationCard";
import { Stack } from "@mui/material";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { useWindowSize } from "hooks/useWindowResize";

export default () => {
	const [notifications, setNotifications] = useState<INotification[]>([]);
	const [notificationsCount, setNotificationsCount] = useState<number>(0);
	const [unreadCount, setUnreadCount] = useState<number>(0);
	const [hasMore, setHasMore] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);
	const [listHeight, setListHeight] = useState<number>(0);
	const [skip, setSkip] = useState<number>(0);
	const [unreadOnly, setUnreadOnly] = useState<boolean>(false);

	const windowSize = useWindowSize();
	const classes = useStyles();
	const theme = useSystemTheme();
	const listRef = useRef(null);

	const take = 50;

	const getListHeight = () => setListHeight(windowSize[1] - 208);

	useEffect(() => {
		setLoading(true)
		getListHeight();
		getNotificationsCount();
		getUnreadCount();
		fetchNotifications(skip, take)
			.then(result => {
				setNotifications(result)
			})
			.finally(() => setLoading(false))
	}, [])

	useEffect(() => {
		getListHeight();
	}, [windowSize])


	const fetchNotifications = async (skip: number, take: number) => {
		return await SystemNotificationsRequester.fetchNotifications(skip, take)
			.then((result) => {
				setSkip(current => current + take)
				return result;
			});
	};

	const getNotificationsCount = async () => {
		await SystemNotificationsRequester.fetchNotificationsCount()
			.then(result => setNotificationsCount(result));
	}

	const getUnreadCount = async () => {
		await SystemNotificationsRequester.fetchNotSeenCount()
			.then(result => setUnreadCount(result));
	}

	const handleMoreNotifications = async () => {
		fetchNotifications(skip, take)
			.then((result) => {
				if (result.length === 0) {
					setHasMore(false);
					return;
				}
				setNotifications(current => current.concat(result))
			})
	}

	const getCurrentUnreadCount = () => notifications.filter(n => !n.seen).length;
	const isItemLoaded = (index: number) => !hasMore || index + 1 < notifications.length;

	return (
		<SBBox height={1} pl={11}>
			<SBLoading loading={loading}>
				<SBCard className={classes.notificationsContainer}>
					<SBTypography
						fontWeight="700"
						color={theme.palette.blackTitle.main}
						fontSize={18}
					>
						{SBTranslate("notification.notifications")}
					</SBTypography>
					<Stack direction="row" spacing={1} mr={4} mt={2} pb={2}>
						<SBChip
							label={SBTranslate("notification.all", { count: notificationsCount })}
							variant={!unreadOnly ? "filled" : "outlined"}
							color="primary"
							fontWeight="bold"
							onClick={() => { setUnreadOnly(false) }}
						/>
						<SBChip
							label={SBTranslate("notification.unread", { count: unreadCount })}
							variant={unreadOnly ? "filled" : "outlined"}
							color="primary"
							fontWeight="bold"
							onClick={() => { setUnreadOnly(true) }}
							disabled={unreadCount == 0}
						/>
					</Stack>
					{notifications && notifications.length > 0 &&
						<InfiniteLoader
							ref={listRef}
							isItemLoaded={isItemLoaded}
							itemCount={unreadOnly ? getCurrentUnreadCount() : notifications.length}
							loadMoreItems={handleMoreNotifications}
						>
							{({ onItemsRendered, ref }) => (
								<List
									width="100%"
									height={listHeight}
									itemCount={unreadOnly ? getCurrentUnreadCount() : notifications.length}
									itemSize={110}
									onItemsRendered={onItemsRendered}
									ref={ref}
								>
									{({ index, style }) => {
										const item = (unreadOnly && !notifications[index].seen) || !unreadOnly ? notifications[index] : null;
										return item && (
											<SBBox style={style}>
												<SBNotificationCard notificationDTO={item} />
											</SBBox>
										);
									}}
								</List>
							)}
						</InfiniteLoader>
					}
				</SBCard>
			</SBLoading>
		</SBBox>
	)
}