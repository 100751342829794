import homeEmptyState from "assets/homeEmptyState.svg";
import { SBTranslate } from "i18n";
import { SBBox, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";

export const HomeEmptyState = () => {
	const { t } = useTranslation();
	return (
		<SBBox
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height={0.8}
			mt="10%"
		>
			<img height={200} src={homeEmptyState} alt="" />
			<SBTypography
				mt={2}
				py={2}
				variant="h5"
				color="darkPurple.main"
				fontWeight="700"
				textAlign="center"
				height={10}
				fontSize={"19px"}
			>
				{SBTranslate("views.home.emptyStateTitle")}
			</SBTypography>

			<SBTypography
				textAlign="center"
				fontWeight="400"
				width={"510px"}
				mt={2}
				line-height={"160%"}
				fontSize={"17px"}
			>
				{SBTranslate("views.home.emptyStateSubTitle")}
			</SBTypography>
		</SBBox>
	);
};
