import { Menu } from "@mui/material";
import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { ReactNode, MouseEvent } from "react";
import { SBBox } from "modules/Commons";

export default forwardRef((props: SBMenuProps, ref) => {
	const { canOpen = true, content, action, isOpen, outsideEl } = props;

	const [anchorEl, setAnchorEl] = useState<Element | null>(null);

	const open = Boolean(anchorEl);

	const handleClick = (event: MouseEvent<HTMLElement>): void =>
		setAnchorEl(event.currentTarget as any);

	const handleClose = (): void => setAnchorEl(null);

	useImperativeHandle(ref, () => ({
		close: () => {
			setAnchorEl(null);
		},
	}));

	useEffect(() => {
		if (isOpen) setAnchorEl(outsideEl.current as any);
		else setAnchorEl(null);
	}, [isOpen]);

	return (
		<SBBox height="100%">
			<SBBox onClick={handleClick} height="100%">
				{action}
			</SBBox>
			<Menu
				anchorEl={anchorEl}
				open={canOpen ? open : false}
				onClose={handleClose}
				MenuListProps={{
					style: { padding: 0, backgroundColor: "transparent" },
				}}
			>
				{content}
			</Menu>
		</SBBox>
	);
});

export interface SBMenuProps {
	canOpen: boolean | undefined;
	action?: ReactNode;
	content: ReactNode;
	isOpen?: boolean;
	outsideEl?: any;
}
