import { IEntity } from "domain/Domain/Entities/IEntity";
import { FilterType, IQueryFilterModel } from "domain/Utils";
import useSystemFilters from "hooks/useSystemFilters";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useRouteFilters = () => {
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const { fromItems } = useSystemFilters();

	const get = (key: string) => query?.get(key);

	const fetchInitialFilters = (key: string) =>
		query
			?.getAll(`${key}`)
			.map((c) => ({ clientID: c, type: key } as IEntity)) ?? [];

	const initialDateValue = (
		key: string,
		defaultValue?: DateTime
	): DateTime | undefined => {
		return defaultValue
			? defaultValue
			: get(key)
				? DateTime.fromISO(get(key)!)
				: undefined;
	};

	const queryFilters = useMemo((): IQueryFilterModel => {
		const { products, stores, categories } = fromItems({
			products: fetchInitialFilters(FilterType.Product),
			categories: fetchInitialFilters(FilterType.Category),
			stores: fetchInitialFilters(FilterType.Store),
		});

		const context = initialDateValue("context");
		const start = initialDateValue("start");
		const end = initialDateValue("end");
		const tag = get("tag")!;
		const tagType = get("tagType")!;
		const emergencial = get("emergencial");

		return {
			products,
			categories,
			stores,
			context,
			start,
			end,
			tag,
			tagType,
			emergencial:
				emergencial === null ? undefined : emergencial == "true",
			filters: [...products, ...categories, ...stores],
		};
	}, []);

	return {
		queryFilters,
		emergencial: () => {
			const emergencial = get("emergencial");
			return emergencial === null || emergencial === undefined
				? undefined
				: emergencial == "true";
		},
	};
};
