export default ({ show, emptyState, children }: SBEmptyStateProps) => {
	return show ? children : emptyState;
};

interface SBEmptyStateProps {
	/** Flag that indicates if will show the content or the emptyState.
	 * True: Show content
	 * False: Show empty state
	 */
	show: boolean;
	emptyState: JSX.Element;
	children: any;
}
