import { TableBody } from "@mui/material";
import { useMemo, useRef } from "react";
import { SBTableRow } from "../SBTableRow";

export default ({
	collapseFieldName,
	rows,
	columns,
	showGroups,
	selectable,
	actionable,
	striped,
	handleClick,
	handleCheckboxClick,
	handleEditableChange,
	isSelected,
	isModified,
	formTableIndex,
	getID = (c) => c.id,
}) => {
	const rowRef = useRef();

	const oneDimensionColumns = (
		showGroups ? columns.flatMap((c) => c.headers) : columns
	).filter((c) => !c.hide);

	const rowsToShow = useMemo(() => rows, [rows]);

	return (
		<TableBody data-testid="sb-table-content" ref={rowRef}>
			{rowsToShow.map((row, index) => (
				<SBTableRow
					key={getID(row)}
					collapseFieldName={collapseFieldName}
					row={row}
					columns={columns}
					striped={striped}
					formTableIndex={formTableIndex}
					index={index}
					oneDimensionColumns={oneDimensionColumns}
					handleEditableChange={handleEditableChange}
					selectable={selectable}
					actionable={actionable}
					isSelected={isSelected}
					isModified={isModified}
					onClick={handleClick}
					onChecked={handleCheckboxClick}
					getID={getID}
					ref={rowRef}
				/>
			))}
		</TableBody>
	);
};
