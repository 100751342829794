import { SBBox, SBIcon, SBTypography } from "modules/Commons";
import { createContext, useContext, useState } from "react";
import { SBTranslate } from "i18n";
import { useTranslation } from "react-i18next";

export const TipsContext = createContext({
	tipsMessage: "",
	isOpen: false,
	showTip: (message: string) => { },
});

export default ({ loading, children }: any) => {
	const { t } = useTranslation();
	const [tipsMessage, setTipsMessage] = useState("");
	const [isOpen, setIsOpen] = useState(false);

	return (
		<TipsContext.Provider
			value={{
				tipsMessage,
				isOpen,
				showTip: (message: string) => {
					if (message !== "") {
						setTipsMessage("config" + message + "Tip");
						setIsOpen(true);
					} else {
						setTipsMessage(message);
						setIsOpen(false);
					}
				},
			}}
		>
			{children}
			{tipsMessage != "" && !loading ? (
				<SBBox
					p="18px"
					style={{
						background: "#F9F8F8",
						border: "1px dashed #7F51FF",
						boxSizing: "border-box",
						borderRadius: "8px",
						// margin: "16px 0px",
					}}
					mb={2}
				>
					<SBBox display="flex">
						<SBIcon icon="help-circle" color="darkPurple" />
						<SBTypography
							fontWeight="700"
							ml={1}
							color="darkPurple.main"
						>
							{t(tipsMessage + "Title")}
						</SBTypography>
						<SBTypography
							fontWeight="500"
							ml="auto"
							onClick={() => setIsOpen((o) => !o)}
							color="darkPurple.main"
						>
							{SBTranslate("hide")}
						</SBTypography>
					</SBBox>
					<SBBox
						mt={1}
						style={{
							height: isOpen ? "100%" : "0px",
							overflowY: "hidden",
						}}
					>
						<SBTypography>{t(tipsMessage)}</SBTypography>
					</SBBox>
				</SBBox>
			) : null}
		</TipsContext.Provider>
	);
};
