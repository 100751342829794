import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme: any) => ({
	gaugeInfo: {
		position: "relative",
		"&.hover:hover": {
			cursor: "pointer",
			background: "rgba(0,0,0,.05)",
			"& .name": {
				textDecoration: "underline !important",
			},
		},
	},
}));
