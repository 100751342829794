import { DateTime } from "luxon";
import { ISimulationModel } from "../models/ISimulationModel";
import { ISimulationPoint } from "../models/ISimulationPoint";
import { SimulationPeriod } from "../models/simulationPeriod";

export const SimulationPeriodSummarizer = (() => {
	const fetchEmptyModel = (): ISimulationModel => ({
		csl: 0,
		end: 0,
		forecast: 0,
		maxForecast: 0,
		minForecast: 0,
		need: 0,
		onOrder: 0,
		onHand: 0,
		position: 0,
		projected: false,
		projectedEnd: 0,
		received: 0,
		requested: 0,
		rupture: 0,
		sales: 0,
		start: 0,
		count: 0,
		hadStockoutEvent: 0,
		orders: 0,
	});

	const addOnModel = (
		toSumModel: ISimulationModel,
		model: ISimulationModel,
		simulationPeriod: SimulationPeriod,
		isFirst: boolean,
		isLast: boolean
	) => {
		const shouldReplaceValue = simulationPeriod !== SimulationPeriod.Daily;
		// const sum = ["received", "request", "rupture", "sales"];
		// const end = ["csl", "end", "position", "projectedEnd", "OnHand", "OnOrder"];
		// const start = ["start"];

		//csl, onhand e onorder fora do geral

		toSumModel.csl = toSumModel.csl?.add({
			value: model.csl,
			replace: isLast,
		});

		toSumModel.end = toSumModel.end?.add({
			value: model.end,
			replace: isLast && shouldReplaceValue,
		});

		toSumModel.position = toSumModel.position?.add({
			value: model.position,
			replace: isLast && shouldReplaceValue,
		});

		toSumModel.projectedEnd = toSumModel.projectedEnd?.add({
			value: model.projectedEnd,
			replace: isLast && shouldReplaceValue,
		});

		toSumModel.onOrder = toSumModel.onOrder?.add({
			value: model.onOrder,
			replace: isLast && shouldReplaceValue,
		});

		toSumModel.start = toSumModel.start?.add({
			value: model.start,
			replace: isLast && shouldReplaceValue,
		});

		toSumModel.received += model.received;
		toSumModel.requested += model.requested;
		toSumModel.rupture += model.rupture;
		toSumModel.sales += model.sales;

		toSumModel.forecast += model.forecast;
		toSumModel.maxForecast += model.maxForecast;
		toSumModel.minForecast += model.minForecast;
		toSumModel.need += model.need;
		toSumModel.count++;
		toSumModel.projected = model.projected;
		toSumModel.orders += model.need > 0 ? 1 : 0;

		toSumModel.hadStockoutEvent += model.hadStockoutEvent;
	};

	const summarize = (
		result: any,
		selectedPeriod: SimulationPeriod
	): ISimulationPoint[] => {
		const groupedItems: { dateTime: DateTime }[] = result?.points?.map(
			(c: any) => ({
				...c,
				dateTime:
					selectedPeriod === SimulationPeriod.All
						? SimulationPeriod.All
						: DateTime.fromFormat(c.date, "yyyy-MM-dd"),
			})
		);

		const periodizedItems = groupedItems?.groupBy<{ dateTime: DateTime }>(
			(c) =>
				selectedPeriod === SimulationPeriod.All
					? c.dateTime
					: c.dateTime.toFormat(selectedPeriod)
		);

		return Object.keys(periodizedItems).map((key) => {
			var group: any[] = periodizedItems[key];
			var summed = group.reduce(
				(a, b, index) => {
					const isFirst = index === 0;
					const isLast = index === group.length - 1;
					addOnModel(a.real, b.real, selectedPeriod, isFirst, isLast);
					addOnModel(
						a.projected,
						b.projected,
						selectedPeriod,
						isFirst,
						isLast
					);

					return a;
				},
				{
					real: fetchEmptyModel(),
					projected: fetchEmptyModel(),
				}
			);

			return {
				ignoreDateConstraint: true,
				date: key,
				...summed,
			};
		});
	};
	return { summarize, fetchEmptyModel };
})();
