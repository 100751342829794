import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	container: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "space-between",
		justifyContent: "space-between",
	},
	value: {
		display: "flex",
		width: "100%",
		padding: ".5rem .5rem",
		justifyContent: "space-between",
		"&:not(:last-child)": {
			borderBottom: "1px solid #A7A7A733",
		},
	},

	action: (props: any) => ({
		border: `1px solid`,
		borderRadius: "4px",
		cursor: "pointer",
		height: "37px",
		marginLeft: "auto",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		paddingInline: "1rem",
		color: props.color,
	}),
}));
