import { useCSV } from "hooks/useCSV";
import useExporter from "hooks/useExporter";
import { DateTime } from "luxon";
import { ForwardedRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { SBTableColumn } from "../../models/TableColumn";
import { Row, RowSelectionState } from "@tanstack/react-table";
import { SBNewTableRef } from "..";
import { IEntity } from "domain/Domain";

export default <T extends IEntity>(props: useTableCSVExporterProps<T>) => {
	const { title, defaultColumns, ref, rows, rowSelection } = props;

	const csvWorker = useCSV();

	const { i18n } = useTranslation();

	const { exportCSV } = useExporter({
		columns: defaultColumns,
		showGroups: true,
	});

	useImperativeHandle(ref, () => ({
		exportCSV,
		downloadCSV: () => {
			let filteredItem = rows.map((c) => c.original);
			if (Object.keys(rowSelection).length > 0)
				filteredItem = filteredItem.filter(
					(_, index) => rowSelection[index]
				);
			const csv = exportCSV(filteredItem, [], i18n.language);
			const fileName = `${title}_${DateTime.now().toISODate()}`;
			csvWorker.downloadCSV({ csv, title: fileName });
		},
		selecteds: () => rowSelection,
	}));

	return {};
};

interface useTableCSVExporterProps<T extends IEntity> {
	title: string;
	rows: Row<T>[];
	defaultColumns: SBTableColumn<T>[];
	ref: ForwardedRef<SBNewTableRef>;
	rowSelection: RowSelectionState;
}
