import useAuthorizator from "hooks/useAuthorizator";
import { SBBox, SBDataTable, SBGrid, SBLoading } from "modules/Commons";
import SBEmptyState from "modules/Commons/SBLayouts/SBEmptyState";
import { useGlobalInventoryContext } from "modules/Inventory/components/SBGlobalInventoryInfoModal";
import { OptimizationRequester } from "modules/Optimizations/services/OptimizationRequester";
import TransferenceConfirmation from "modules/Transferences/components/TransferenceConfirmation";
import TransferenceEmptyState from "modules/Transferences/components/TransferenceEmptyState";
import TransferenceSummarization from "modules/Transferences/components/TransferenceSummarization";
import { useTransferencePlanHeaders } from "modules/Transferences/models/tableHeaders";
import { TransferencePlan } from "modules/Transferences/models/transferencePlan";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SBComposedFilter from "views/Commons/SBComposedFilter";
import { useTransferenceContext } from "contexts/TransferenceContext";
import { IComposedFilter } from "domain/Utils";
import { transformFilters } from "modules/SystemContext/services/SystemContextRequester";
import { NotificationsRequester } from "modules/Notifications/services/NotificationsRequester";
import { useSystemFilterContext } from "contexts/SystemFilterContext";
import { useDateStorageCache } from "hooks/useDateStorageCache";
import SBContextDateInfo from "views/Commons/SBContextDateInfo";
import { DateTime } from "luxon";
import { useMixpanelContext } from "contexts/MixpanelContext";

export default () => {
	const { transfer } = useTransferenceContext();
	const handleClick = (id: number) => {
		openMenu(id);
	};
	const tableRef = useRef<any>();
	const { t } = useTranslation();
	const { headers } = useTransferencePlanHeaders(handleClick);
	const { hasTransferenceIntegration } = useAuthorizator();
	const { context, lastDatabaseUpdate, filtersContext, setFiltersContextState, notCurrentContext } = useSystemFilterContext();
	const { getDateItem, setDateItem } = useDateStorageCache();
	const { trackHistoryChange, trackExport } = useMixpanelContext();

	const [availableDates, setAvailableDates] = useState<string[]>([]);
	const [allowTransfer, setAllowTransfer] = useState(true);
	const [loading, setLoading] = useState(false);
	const [loadingExport, setLoadingExport] = useState(false);
	const [dialogStatus, setDialogStatus] = useState(false);

	const { openMenu, isOpen } = useContext(useGlobalInventoryContext);

	const [transferencePlans, setTransferencePlans] = useState(
		[] as TransferencePlan[]
	);

	const hasAnyPlans = useMemo(
		() => transferencePlans?.length > 0,
		[transferencePlans]
	);

	const fetchPlans = async () => {
		setLoading(true);
		OptimizationRequester.getTransferencePlans(filtersContext?.items ?? [])
			.then((d) => setTransferencePlans(d))
			.finally(() => setLoading(false));
	};

	const getAvailableDates = async () => {
		const result = await NotificationsRequester.homeStatisticsDates();
		if (result) setAvailableDates(result)
	};

	const handleOnApply = async (obj: IComposedFilter) => {
		obj.picker?.date && setDateItem(obj.picker.date.toFormat("yyyy-MM-dd"))

		setLoading(true);
		if (obj.filter)
			setFiltersContextState(obj.filter);
		else
			setFiltersContextState([]);

		setAllowTransfer(getDateItem() === lastDatabaseUpdate?.toISODate());

		const filter = obj.filter!;
		trackHistoryChange({ date: getDateItem() });

		OptimizationRequester.getTransferencePlans(filter)
			.then((d) => setTransferencePlans(d))
			.finally(() => setLoading(false));
	};

	const handleExport = () => {
		setLoadingExport(true);
		trackExport({ date: getDateItem() });
		tableRef.current.downloadCSV({});
		setLoadingExport(false);
	};

	const sendPurchaseToRP = () => {
		transfer(tableRef.current.selecteds(), transferencePlans);
	};

	useEffect(() => {
		if (context)
			fetchPlans();
	}, [context]);

	useEffect(() => {
		getAvailableDates();
		setAllowTransfer(getDateItem() === lastDatabaseUpdate?.toISODate());
	}, []);

	return (
		<SBBox height={1} pl={11}>
			<SBBox display="flex" justifyContent="end">
				<SBComposedFilter.Base onApply={handleOnApply}>
					<SBComposedFilter.Picker date={getDateItem() && DateTime.fromISO(getDateItem())} range={false} availableDates={availableDates} />
					<SBComposedFilter.Filter />
					<SBComposedFilter.Applicator />
					<SBComposedFilter.Actions
						loading={loadingExport}
						actions={[
							{
								hide: !hasTransferenceIntegration() || !allowTransfer,
								title: "transfer",
								icon: { name: "shopping-cart" },
								onClick: () => setDialogStatus(true),
							},
							{
								title: "exportCSV",
								icon: { name: "shopping-cart" },
								onClick: handleExport,
							},
						]}
					/>
				</SBComposedFilter.Base>
			</SBBox>

			{notCurrentContext() ? <SBGrid container>
				<SBContextDateInfo context={DateTime.fromISO(getDateItem())} />
			</SBGrid> : <></>}

			<SBLoading loading={loading}>
				<SBEmptyState
					show={hasAnyPlans}
					emptyState={<TransferenceEmptyState />}
				>
					<TransferenceSummarization
						transferencePlans={transferencePlans}
					/>

					<SBBox
						width={isOpen ? "50vw" : "100%"}
						style={{
							transition: "all 0.25s ease",
							marginTop: "1rem",
						}}
					>
						<SBDataTable
							ref={tableRef}
							selectable
							showCount
							pagination
							showGroups
							deepSearch
							collapseFieldName={"plans"}
							title={t("suggestedTransferences") as string}
							columns={headers}
							rows={transferencePlans}
							getID={(c: TransferencePlan) => c.uuid}
							onChangeAny={(
								_: any,
								items: TransferencePlan[]
							) => {
								setTransferencePlans(items);
							}}
						/>
					</SBBox>
				</SBEmptyState>
			</SBLoading>
			<TransferenceConfirmation
				open={dialogStatus}
				onConfirm={sendPurchaseToRP}
				onClose={() => setDialogStatus(false)}
				onCancel={() => setDialogStatus(false)}
			/>
		</SBBox>
	);
};
