import { IInventory } from "domain/Inventory";
import { createContext } from "react";

export const InventoryVirtualStatusContext = createContext(
	{} as InventoryVirtualStatusContextProps
);

export interface InventoryVirtualStatusConfig {
	title: string;
	columnName: string;
}

export interface InventoryVirtualStatusContextProps {
	fetchData: (
		d: Promise<IInventory[]>,
		configs: InventoryVirtualStatusConfig
	) => void;
}
