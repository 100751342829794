import configViewEmptyState from "assets/configViewEmptyState.svg";
import { SBBox, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";

export default () => {
	const { t } = useTranslation();
	return (
		<SBBox
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height="80vh"
		>
			<img height={300} src={configViewEmptyState} alt="" />
			<SBTypography
				mt={2}
				py={2}
				variant="h5"
				color="darkPurple.main"
				fontWeight="700"
			>
				{t("configEmptyStateTitle")}
			</SBTypography>

			<SBTypography textAlign="center" fontWeight="400" width={0.3}>
				{t("configEmptyStateSubTitle")}
			</SBTypography>
		</SBBox>
	);
};
