import { SBBox, SBCard, SBGrid, SBTypography } from "modules/Commons";
import SBThreeLevelGauge from "modules/Commons/SBDataDisplays/SBThreeLevelGauge";
import { useTranslation } from "react-i18next";
import noDataToDisplay from "assets/noDataToDisplay.svg";
import { SBTranslate } from "i18n";

export default ({ volatility }: ExplorationsListingProps) => {
	const { t } = useTranslation();
	const configs = {
		low: {
			max: 3,
			min: 2,
			label: t("unstable"),
		},
		medium: {
			max: 2,
			min: 1,
			label: t("balanced"),
		},
		high: {
			max: 1,
			min: 0,
			label: t("stable"),
		},
	};

	const volatiltyText = () => {
		if (volatility > configs.low.min)
			return {
				amount: SBTranslate("highVariation"),
				behaviour: SBTranslate("irregularBehaviour"),
			};
		if (volatility > configs.medium.min)
			return {
				amount: SBTranslate("mediumVariation"),
				behaviour: SBTranslate("closeToMean"),
			};
		return { amount: SBTranslate("lowVariation"), behaviour: SBTranslate("closeToMean") };
	};

	return (
		<SBBox flexGrow={1} pt={1}>
			<SBCard>
				<SBTypography px={3} pt={3} fontWeight="bold">
					{SBTranslate("volatilityTitle")}
				</SBTypography>
				{isNaN(volatility) ? (
					<SBBox pb={4} display="flex" justifyContent="center">
						<img src={noDataToDisplay} height="80px" />
					</SBBox>
				) : (
					<SBGrid container px={3} height={1} pb={3}>
						<SBGrid
							item
							xs={6}
							justifyContent="center"
							pt={2}
							display="flex"
							alignItems="center"
						>
							{SBTranslate("volatilityText", {
								volatility,
								...volatiltyText(),
							})}
						</SBGrid>
						<SBGrid
							item
							xs={6}
							justifyContent="center"
							alignItems="center"
							display="flex"
							flexDirection="column"
						>
							<SBThreeLevelGauge
								value={volatility}
								configs={configs}
							/>
						</SBGrid>
					</SBGrid>
				)}
			</SBCard>
		</SBBox>
	);
};

export interface ExplorationsListingProps {
	volatility: number;
}
