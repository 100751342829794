import clsx from "clsx";
import { IChartInfo } from "domain/Chart/Entities/IChartInfo";
import { SBBox, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

export default (props: SimulationFilterItemProps) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { value, onClick, isSelected } = props;

	return (
		<SBBox
			mr={2}
			data-testid="sb-simulation-filter-item"
			onClick={() => onClick(value)}
		>
			<SBTypography
				className={clsx(
					classes.sbSimulationFilterItem,
					isSelected && "active"
				)}
				noWrap
			>
				{t(value.label)}
			</SBTypography>
		</SBBox>
	);
};

export interface SimulationFilterItemProps {
	value: IChartInfo;
	onClick: (item: IChartInfo) => void;
	isSelected: boolean;
}
