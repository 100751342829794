import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	sbDateSliderContainer: {
		backgroundColor: "white",
		boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
		borderRadius: 4,
		width: "100%",
		// ".MuiSlider-mark": {
		// 	pointerEvents: "none !important",
		// },
	},
}));
