import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	container: {
		height: `calc(296px)`,
		overflowY: "auto",
		border: "1px solid rgba(0,0,0,.2)",
		borderBottomLeftRadius: "4px",
		borderBottomRightRadius: "4px",
		"&.small": {
			height: "135px",
		},
	},
	tableContainer: {
		borderTop: "1px dashed " + theme.palette.gray3.main,
	},
}));
