import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	configUploaderModal: {
		display: "flex",
		height: "100%",
	},
	cronGeneratorContainer: {
		width: "0px",
		transition: "all 0.5s ",
		"&.open": {
			width: "40%",
		},
	},
	configUploaderMenuHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "16px",
		width: "100%",
	},
}));
