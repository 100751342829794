import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
	sbTableHeaderGroup: {
		borderRight: "4px solid white !important",
		borderBottom: `2px solid #7F51FF !important`,
		backgroundColor: "#F9F8FD !important",
		color: "#A7A7A7 !important",
		fontWeight: "600 !important",
		textTransform: "uppercase",
	},
}));
