import { useStyles } from "./style";
import { Tooltip, TooltipProps } from "@mui/material";
import clsx from "clsx";

export default ({ ...props }: SBStyledTooltipProps) => {
	const classes = useStyles();
	return (
		<Tooltip
			{...props}
			data-testid="sb-styled-tooltip"
			classes={{
				popper: clsx(classes.sbActiveLinkIconTooltip, props.className),
			}}
		/>
	);
};

export interface SBStyledTooltipProps extends TooltipProps {}
