import { useCSV } from "hooks/useCSV";
import { IForecastReal } from "../../../domain/Predictive/Models/IForecastReal";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { SBTranslate } from "i18n";

export const useForecastExporter = () => {
	const { toCSV, downloadCSV } = useCSV();
	const { getLanguage, getCSVSeparator } = useSystemLanguage();

	const asCSV = ({ demand }: asCSVProps) => {
		const lang = getLanguage();
		const dateHeader = SBTranslate("commons.date");
		const realHeader = SBTranslate("commons.real");
		const forecastHeader = SBTranslate("commons.forecast");
		const maxHeader = SBTranslate("commons.max");
		const minHeader = SBTranslate("commons.min");

		const forecast = Object.keys(demand).map((key) => {
			const formatter = (c: number) =>
				Intl.NumberFormat(lang, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
					style: "decimal",
					useGrouping: false,
				}).format(c);

			const min = formatter(Math.max(demand[key]?.min, 0)) ?? 0;
			const real = formatter(demand[key]?.real) ?? 0;
			const forecast = formatter(demand[key]?.forecast) ?? 0;
			const max = formatter(demand[key]?.max) ?? 0;

			return {
				[dateHeader]: key,
				[realHeader]: real,
				[forecastHeader]: forecast,
				[maxHeader]: max,
				[minHeader]: min,
			};
		});

		const csv = toCSV({
			json: forecast,
			headers: [
				SBTranslate("commons.date"),
				SBTranslate("commons.real"),
				SBTranslate("commons.min"),
				SBTranslate("commons.forecast"),
				SBTranslate("commons.max"),
			] as any,
			separator: getCSVSeparator(),
		});
		downloadCSV({ csv, title: "forecast" });
	};

	return {
		asCSV,
	};
};

interface asCSVProps {
	demand: {
		[key: string]: IForecastReal;
	};
}
