import { TableRow } from "@mui/material";
import SBTableHeaderAction from "./SBTableHeaderAction";
import SBTableHeaderCell from "./SBTableHeaderCell";
import {
	SBTableHeaderSelection,
	SBTableHeaderSelectionProps,
} from "./SBTableHeaderSelection";
import { SBTableColumn } from "../../models/TableColumn";

/** Build the row of headers of the table */
export default ({
	columns,
	disabled,
	checked,
	indeterminate,
	selectable,
	actionable,
	onSelectAllClick,
}: SBTableHeaderCellsProps) => {
	return (
		<TableRow>
			<SBTableHeaderSelection
				selectable={selectable}
				onSelectAllClick={onSelectAllClick}
				disabled={disabled}
				checked={checked}
				indeterminate={indeterminate}
			/>
			{columns &&
				columns.map((cell) => {
					const itemKey = cell?.key ?? cell.id;

					return (
						<SBTableHeaderCell
							key={itemKey + ""}
							width={cell.width}
							align={cell.align}
							name={cell.name}
						/>
					);
				})}
			<SBTableHeaderAction actionable={actionable} />
		</TableRow>
	);
};
export interface SBTableHeaderCellsProps extends SBTableHeaderSelectionProps {
	/** Columns to render on header */
	columns: SBTableColumn<any>[];
	/** Add column on last position to actions */
	actionable?: boolean;
}
