import { ReviewPeriodExpression } from "domain/Predictive/Enums/reviewPeriodExpressionPosition";
import { SBBox, SBGrid, SBSwitch } from "modules/Commons";
import { TransferenceMode } from "modules/InventoryConfig/models/transferenceMode";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ConfigGroupmentDivider from "../ConfigGroupmentDivider";
import ConfigTextField from "../ConfigTextField";
import ConfigTransferenceMode from "./ConfigTransferenceMode";
import CreateInventoryStatus from "./CreateInventoryStatus";
import SupplyReviewPeriod from "./SupplyReviewPeriod";
import ConfigEmergencialCoverageMode from "./ConfigEmergencialCoverageMode";
import { EmergencialCoverageMode } from "modules/EmergencialLeadTime/models/emergencialCoverageMode";
import { RecommendationMode } from "modules/InventoryConfig/models/recommendationMode";
import ConfigRecommendationMode from "./ConfigRecommendationMode";
import { useEffect, useState } from "react";


export default ({
	fetchPropError,
	suppliers,
	inventoryFormulas,
	stores,
}: CreateInventorySupplyProps) => {
	const { t } = useTranslation();
	const { setValue, getValues } = useFormContext();
	const unitsText = <>{t("unit", { count: 2 })}</>;
	const daysText = <>{t("days", { count: 2 })}</>;

	const onSetReviewPeriod = (v: any) => {
		setValue(
			"reviewPeriodExpression",
			ReviewPeriodExpression.Modifiers.getValidExpressions(v)
		);
	};

	const onSetSourcing = (v: any) => {
		setValue("sourcingID", v.sourcingID);
		setValue("sourcingType", v.sourcingType);
	};

	const onSetTransfernceMode = (v: TransferenceMode | null) => {
		setValue("transferenceMode", v);
	};

	const onSetEmergencialCoverageMode = (v: EmergencialCoverageMode | null) => {
		setValue("emergencialCoverageMode", v);
	};

	const [recommendationMode, setRecommendationMode] = useState<RecommendationMode | null>(null);

	const onSetRecommendationMode = (v: RecommendationMode | null) => {
		setValue("recommendationMode", v);
		setRecommendationMode(v);
	};


	const getEmergencialCoverageMode = getValues("emergencialCoverageMode");
	const getRecommendationMode = getValues("recommendationMode");
	const initialValue = getValues("transferenceMode");
	const initialSupplyValue = { sourcingID: getValues("sourcingID"), sourcingType: getValues("sourcingType") };
	useEffect(() => {
		const reviewPeriodNumeric = getValues("reviewPeriodNumeric");
	}, []);

	return (
		<SBBox height={1}>
			<ConfigGroupmentDivider title={t("supplyConfig")} />
			<SBGrid container spacing={4} marginBottom={2}>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="supply"
						type="integer"
						prop="moq"
						title={t("moq")}
						endAdornment={unitsText}
						helperText={fetchPropError("moq")}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="supply"
						type="integer"
						prop="lotSize"
						title={t("lotSize")}
						endAdornment={unitsText}
						helperText={fetchPropError("lotSize")}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="supply"
						type="integer"
						prop="capacity"
						title={t("capacity")}
						endAdornment={unitsText}
						helperText={fetchPropError("capacity")}
					/>
				</SBGrid>

				<SBGrid item xs={4}>
					<ConfigTextField
						section="supply"
						type="integer"
						prop="leadTime"
						title={t("leadTime")}
						endAdornment={daysText}
						helperText={fetchPropError("leadTime")}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="supply"
						type="integer"
						prop="emergencialLeadTime"
						title={t("emergencialLeadTime")}
						endAdornment={daysText}
						helperText={fetchPropError("emergencialLeadTime")}
					/>
				</SBGrid>
			</SBGrid>
			<SBGrid item xs={6} marginBottom={2}>
				<ConfigEmergencialCoverageMode
					value={getEmergencialCoverageMode}
					onUpdate={onSetEmergencialCoverageMode}
				/>
			</SBGrid>
			<SBGrid item xs={12} marginBottom={2}>
				<ConfigTransferenceMode
					value={initialValue}
					onUpdate={onSetTransfernceMode}
				/>
			</SBGrid>
			<SBGrid marginBottom={2}>
				<CreateInventoryStatus
					sourcingValue={initialSupplyValue}
					onUpdate={onSetSourcing}
					suppliers={suppliers}
					inventoryFormulas={inventoryFormulas}
					stores={stores}
					fetchPropError={fetchPropError}
				/>
			</SBGrid>
			<SBGrid marginBottom={2}>
				<ConfigRecommendationMode
					value={getRecommendationMode}
					onUpdate={onSetRecommendationMode}
				/>
			</SBGrid>

			<SupplyReviewPeriod
				recommendationMode={recommendationMode}
				fetchPropError={fetchPropError}
				onUpdate={onSetReviewPeriod}
			/>
		</SBBox>
	);
};

export interface CreateInventorySupplyProps {
	onUpdate: (supply: {
		moq: number | null;
		lotSize: number | null;
		capacity: number | null;
		leadTime: number | null;
		reviewPeriodExpression: string | null;
		sourcingID: string | null;
		sourcingType: number | null;
	}) => void;
	values: {
		moq: number;
		lotSize: number;
		capacity: number;
		leadTime: number;
		reviewPeriodExpression: string | null;
		sourcingID: string | null;
		sourcingType: string | null;
	};
	fetchPropError: any;
	suppliers: any;
	inventoryFormulas: any;
	stores: any;
}
