import { SBBox, SBTypography } from "modules/Commons";
import noRecommendation from "assets/noRecommendation.svg";
import { SBTranslate } from "i18n";

export default ({ label }: { label: string }) => {
	return (
		<SBBox
			display="flex"
			height={"100%"}
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
		>
			<img height={130} src={noRecommendation} alt="" />
			<SBTypography
				textTransform="none"
				textAlign="center"
				color="darkPurple.main"
				fontWeight="600"
				fontSize="16px"
				lineHeight="25.6px"
				letterSpacing="0.45px"
				mt={"25px"}
			>
				{SBTranslate("commons.thereIsNoneAtThisMoment", { label })}
			</SBTypography>
		</SBBox>
	);
};
