/* eslint-disable import/no-anonymous-default-export */
import {
	SBBox,
	SBButton,
	SBGrid,
	SBLoading,
	SBFade,
	SBOutlinedButton,
	SBTypography,
	SBIcon,
} from "modules/Commons";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfigTips from "../ConfigTips";
import ConfigCSL from "./ConfigCSL";
import ConfigFilter from "./ConfigFilter";
import ConfigOption from "./ConfigOption";
import ConfigProduct from "./ConfigProduct";
import ConfigStock from "./ConfigStock";
import ConfigSupply from "./ConfigSupply";
import ConfigTotalCosts from "./ConfigTotalCosts";

export default ({
	config,
	loading,
	onUpdate,
	onClose,
	fetchPropError,
	suppliers,
	inventoryFormulas,
	stores,
}: any) => {
	const { t } = useTranslation();
	const [selectedOption, setSelectedOption] = useState("filter");

	const options = [
		{ name: "filter", errors: ["name", ""] },
		{ name: "statistics", errors: ["csl", "minCSL", "maxCSL"] },
		{
			name: "stock",
			errors: [
				"min",
				"max",
				"emergencyInventory",
				"allowBackorder",
				"transferenceMode",
			],
		},
		{
			name: "supply",
			errors: [
				"lotSize",
				"moq",
				"capacity",
				"leadTime",
				"sourcingID",
				"reviewPeriodExpression",
			],
		},
		{
			name: "product",
			errors: ["cost", "price", "salvage", "penalty", "volune", "weight"],
		},
		{
			name: "TRC",
			errors: [],
		},
	];

	const Component = (() => {
		switch (selectedOption) {
			case "statistics":
				return ConfigCSL;
			case "stock":
				return ConfigStock;
			case "supply":
				return ConfigSupply;
			case "product":
				return ConfigProduct;
			case "filter":
				return ConfigFilter;
			case "trc":
			default:
				return ConfigTotalCosts;
		}
	})();

	return (
		<SBBox px={3}>
			<ConfigTips loading={loading}>
				<SBBox display="flex" alignItems="center" mt={2}>
					<SBTypography fontWeight="700">{t("config")}</SBTypography>
					<SBBox ml="auto" mr={2}>
						<SBButton loading={loading} type="submit">
							<SBIcon icon="save" color="#fff" />
							<SBTypography fontWeight="700" ml={1}>
								{t("save")}
							</SBTypography>
						</SBButton>
					</SBBox>
					<SBOutlinedButton disabled={loading} onClick={onClose}>
						<SBTypography py={0.3} fontWeight="700">
							{t("close")}
						</SBTypography>
					</SBOutlinedButton>
				</SBBox>
				<SBGrid container>
					<SBBox display="flex" width={1} alignItems="end">
						{options.map((option) => (
							<ConfigOption
								key={option.name}
								selected={option.name === selectedOption}
								onClick={() => setSelectedOption(option.name)}
							>
								<SBTypography
									fontWeight="700"
									color={
										option.errors.some((error) =>
											fetchPropError(error)
										)
											? "error"
											: ""
									}
								>
									{t(option.name)}
								</SBTypography>
							</ConfigOption>
						))}
					</SBBox>
				</SBGrid>
				{!loading ? (
					<SBFade in={!loading} timeout={250}>
						<SBBox my={1}>
							<Component
								fetchPropError={fetchPropError}
								values={config}
								suppliers={suppliers}
								inventoryFormulas={inventoryFormulas}
								stores={stores}
								onUpdate={(value: any) =>
									onUpdate({
										...config,
										...value,
									})
								}
							/>
						</SBBox>
					</SBFade>
				) : (
					<SBLoading limited loading />
				)}
			</ConfigTips>
		</SBBox>
	);
};
