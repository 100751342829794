import { IProductPurchaseOrder } from "domain/Domain/Models/IProductPurchaseOrder";
import { IComposedFilter, IQueryFilterModel } from "domain/Utils";
import { useFilterToQueryString } from "hooks/useFilterToQueryString/indext";
import { useRequest } from "hooks/useRequest";
import { IPurchasePlan } from "../models/IPurchasePlans";
import { transformFilters } from "modules/SystemContext/services/SystemContextRequester";

export const PurchasesRequester = (() => {
	const request = useRequest("purchase");
	const { fetchQueryString } = useFilterToQueryString();

	const toSystmeFilter = (obj: IComposedFilter) => {
		const filter = obj.filter ?? [];

		const products = {
			product: filter
				.filter((p) => p.type === "product")
				.map((p) => p.name),
		};

		const stores = {
			store: filter.filter((p) => p.type === "store").map((p) => p.name),
		};

		const groupedCategories = filter
			.filter((p) => p.type === "category")
			.groupBy((p: any) => p.deep);

		const categories = {} as { [key: string]: string[] };

		Object.keys(groupedCategories).flatMap((c) => {
			categories["category " + c] = groupedCategories[c].map(
				(d: any) => d.name
			);
		});
		return { ...products, ...stores, ...categories };
	};

	return {
		sendPurchaseToRP: (data: IProductPurchaseOrder[]) => {
			return request.POST("", data);
		},
		fetchPurchase: (filter: IComposedFilter) => {
			return request
				.POST("recommend", { tags: toSystmeFilter(filter) })
				.then((result) => {
					return result.data as IPurchasePlan[];
				});
		},
		fetchRecomendation: (queryFilters: any) => {
			const query = fetchQueryString({
				emergencial: queryFilters.emergencial!,
				tag:
					queryFilters.tag !== btoa("Alerts")
						? queryFilters.tag!
						: "",
				tagType: queryFilters.tagType!,
			});

			return request
				.GET(`${queryFilters.tagType}${query}`)
				.then((result) => {
					return result.data as IPurchasePlan[];
				});
		},
		runOnDemand: (filter: IComposedFilter) => {
			const products = filter?.filter?.filter(
				(c: any) => c.type === "product"
			);
			const categories = filter?.filter?.filter(
				(c: any) => c.type === "category"
			);
			const stores = filter?.filter?.filter(
				(c: any) => c.type === "store"
			);

			return request
				.POST(`purchaseOnDemand`, {
					products,
					categories,
					stores,
				})
				.then((result) => {});
		},
		fetchFilteredRecomendation: (filter: any) => {
			return request.POST(`purchase`, filter || {}).then((result) => {
				return result.data as IPurchasePlan[];
			});
		},
		fetchComposedFilteredRecomendation: (filter: IComposedFilter) => {
			const builtFilter = transformFilters({
				products:
					filter.filter?.filter((c) => c.type == "product") ?? [],
				stores: filter.filter?.filter((c) => c.type == "store") ?? [],
				categories:
					filter.filter?.filter((c) => c.type == "category") ?? [],
			});

			return request
				.POST(`purchase`, builtFilter || {})
				.then((result) => {
					return result.data as IPurchasePlan[];
				});
		},
	};
})();
