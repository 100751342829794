import { configureYupLocale } from "domain/Domain/Services/Validator";
import { StorageKey } from "domain/Utils";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const useSystemLanguage = () => {
	const { i18n } = useTranslation();
	const defaultLanguage = navigator.language;

	useEffect(() => {
		i18n.changeLanguage(getLanguage());
	}, []);

	const changeLanguage = () => {
		let language = defaultLanguage;

		if (i18n.language === "pt-BR") language = "en-US";
		else language = "pt-BR";

		i18n.changeLanguage(language);
		configureYupLocale();
		localStorage.setItem(StorageKey.SBLanguage, language);
	};

	const getLanguage = () => {
		var language = localStorage.getItem(StorageKey.SBLanguage);

		if (!language) {
			language = navigator.language;
			localStorage.setItem(StorageKey.SBLanguage, language);
		}

		return language;
	};

	const getCSVSeparator = () => {
		return i18n.language === "pt-BR" ? ";" : ",";
	};

	return {
		changeLanguage,
		getLanguage,
		getCSVSeparator,
	};
};
