import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";
export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	titleLine: {
		display: "flex",
		alignItems: "center",
		"& > p": {
			fontWeight: "bold",
			fontSize: "1rem",
		},
	},
	export: {
		marginLeft: "auto",
		display: "flex",
		alignItems: "center",
		borderBottom: "1px solid rgba(0,0,0,0)",
		"&:hover": {
			cursor: "pointer",
			borderBottom: "1px solid rgba(0,0,0,0.2)",
		},
		"& > p": {
			marginLeft: ".5rem",
			color: theme.palette.gray3.main,
		},
	},
}));
