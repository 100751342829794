import { ButtonProps, Dialog, DialogProps, Divider } from "@mui/material";
import SBIcon from "modules/Commons/SBDataDisplays/SBIcon";
import SBTypography from "modules/Commons/SBDataDisplays/SBTypography";
import SBBox from "modules/Commons/SBLayouts/SBBox";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SBButton from "../SBButton";
import SBIconButton from "../SBIconButton";
import SBOutlinedButton from "../SBOutlinedButton";

/** Common button  */
export default (props: SBConfirmDialog) => {
	const { t } = useTranslation();

	const {
		title,
		cancelText = t("cancel"),
		confirmText = t("confirm"),
		cancelColor = "primary",
		confirmColor = "primary",
		children,
		onConfirm,
		onCancel,
		onClose,
		open = false,
		width,
		...otherProps
	} = props;

	const [isOpen, setIsOpen] = useState<boolean>(open);

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	return (
		<Dialog open={isOpen} {...otherProps}>
			<SBBox
				style={{
					backgroundColor: "#fff",
					border: "1px solid #E0E0E0",
					boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
					borderRadius: "4px",
				}}
			>
				<SBBox
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					px="24px"
					py="10px"
				>
					<SBTypography
						fontSize="18px"
						fontWeight="700"
						color="black"
					>
						{title}
					</SBTypography>
					<SBIconButton onClick={() => onClose()}>
						<SBIcon icon="x" size={25} />
					</SBIconButton>
				</SBBox>
				<Divider />
				<SBBox p="24px">{children}</SBBox>
				<SBBox display="flex" px="24px" pb="24px" justifyContent="end">
					{onCancel && (
						<SBBox display="flex" mr="24px">
							<SBOutlinedButton
								color={cancelColor}
								onClick={() => {
									onCancel();
									setIsOpen(false);
									onClose();
								}}
							>
								<SBTypography textTransform="capitalize">
									{cancelText}
								</SBTypography>
							</SBOutlinedButton>
						</SBBox>
					)}
					<SBBox>
						<SBButton
							color={confirmColor}
							onClick={() => {
								onConfirm();
								setIsOpen(false);
								onClose();
							}}
						>
							<SBTypography textTransform="capitalize">
								{confirmText}
							</SBTypography>
						</SBButton>
					</SBBox>
				</SBBox>
			</SBBox>
		</Dialog>
	);
};

export interface SBConfirmDialog extends DialogProps {
	title: string;
	cancelText?: string;
	confirmText?: string;
	children?: JSX.Element;
	cancelColor?: string;
	confirmColor?: string;
	width?: string | number;
	onConfirm: () => void;
	onClose: () => void;
	onCancel?: () => void;
}
