import { useRequest } from "hooks/useRequest";
import { DateTime } from "luxon";
import { IInventoryStatisticsAccumulator } from "../../../domain/Utils/models/IInventoryStatisticsAccumulator";

export const ExplorationsRequester = (() => {
	const request = useRequest("explorations");
	return {
		explore: (filter: any, start?: DateTime, end?: DateTime) => {
			// var startQuery = !!start
			// 	? `start=${start.toFormat("yyyy-MM-dd")}`
			// 	: "";
			// var endQuery = !!end ? `end=${end.toFormat("yyyy-MM-dd")}` : "";
			return (
				request
					// .POST(`?${startQuery}&${endQuery}`, {
					.POST("", {
						products:
							filter?.filter((c: any) => c.type === "product") ??
							[],
						categories:
							filter?.filter((c: any) => c.type === "category") ??
							[],
						stores:
							filter?.filter((c: any) => c.type === "store") ??
							[],
					})
					.then((c) => c.data as IInventoryStatisticsAccumulator)
			);
		},
	};
})();
