import { IDataFail } from "domain/Utils/models/IDataFail";
import useTableColumnBuilder from "hooks/useTableColumnBuilder";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

export const useDataProofHeaders = () => {
	const { t } = useTranslation();
	const { add, build } = useTableColumnBuilder<IDataFail>();

	const setDescription = (type: string, field?: string, value?: string) => {
		switch (type) {
			case "Outlier": {
				return t("outlierAlert", {
					value: value,
				});
			}
			case "Reconstruction": {
				return t("reconstructionAlert", {
					field: field,
				});
			}
			case "InvalidSales": {
				return t("invalidSalesAlert");
			}
			case "NegativeValue": {
				return t("negativeValueAlert", {
					field: field,
				});
			}
			case "IncoherenceBetweenyHistories": {
				return t("incoherenceBetweenyHistoriesAlert");
			}
			default: {
				return "-------------";
			}
		}
	};

	add({
		id: () => "Product",
		name: t("product"),
		headers: [
			{
				id: (c) => c.inventory?.product.clientID,
				name: t("code"),
				key: "codProduct",
				align: "left",
				style: {
					fontWeight: "bold",
					color: "#7F51FF",
				},
			},
			{
				id: (c) => c.inventory?.product.name,
				name: t("name"),
				key: "nameProduct",
				align: "left",
			},
		],
	});

	add({
		id: () => "Category",
		name: t("category"),
		headers: [
			{
				id: (c) => c.inventory?.product.category.clientID,
				name: t("code"),
				key: "codCategory",
				align: "left",
				style: {
					fontWeight: "bold",
					color: "#7F51FF",
				},
			},
			{
				id: (c) => c.inventory?.product.category.name,
				name: t("name"),
				key: "nameCategory",
				align: "left",
			},
		],
	});

	add({
		id: () => "Store",
		name: t("store"),
		headers: [
			{
				id: (c) => c.inventory?.store.clientID,
				name: t("code"),
				key: "codStore",
				align: "left",
				style: {
					fontWeight: "bold",
					color: "#7F51FF",
				},
			},
			{
				id: (c) => c.inventory?.store.name,
				name: t("name"),
				key: "nameStore",
				align: "left",
			},
		],
	});

	add({
		id: () => "Alert",
		name: t("alert"),
		headers: [
			{
				id: (c) =>
					t(c.status.charAt(0).toLowerCase() + c.status.slice(1)),
				name: t("status"),
				key: "status",
				align: "left",
			},
			{
				id: (c) => t(c.type.charAt(0).toLowerCase() + c.type.slice(1)),
				name: t("type"),
				key: "type",
				align: "left",
			},
			{
				id: (c) => DateTime.fromISO(c.date).toFormat(t("dateFormat")),
				name: t("date"),
				key: "date",
				align: "left",
			},
			{
				id: (c) => {
					return setDescription(c.type, c.field, c.value);
				},
				name: t("description"),
				key: "description",
				align: "left",
			},
		],
	});

	return build();
};
