import { SBTypography } from "modules/Commons";
import SBTableCell, { SBTableCellProps } from "../SBTableCell";

export default ({
	value,
	padding,
	style,
	color,
	align = "right",
	onClick,
}: SBNumberTableCellProps) => {
	return (
		<SBTableCell
			align={align}
			padding={padding}
			style={style}
			onClick={onClick}
		>
			<SBTypography color={color}>{value}</SBTypography>
		</SBTableCell>
	);
};

export interface SBNumberTableCellProps extends SBTableCellProps {
	onClick: (e: any) => void;
	value: string | number;
}
