import clsx from "clsx";
import { DateTime } from "luxon";
import { SBBox, SBChip, SBIcon, SBTypography } from "modules/Commons";
import { TableCellType } from "modules/Commons/SBDataDisplays/SBTable/SBTableCells/tableCellType";
import { useTranslation } from "react-i18next";

const PercentageNoDecimalFormatter = (value: number) => {
	return `${(value * 100).systemFormat()}%`;
};

const DecimalFormatter = (value: number) => {
	if (!value) return "0";
	return `${value.systemFormat({ maximumFractionDigits: 0 })}`;
};

const CurrencyFormatter = (value: number) => {
	if (!value) return "0.00";
	return `${value.systemFormat({ minimumFractionDigits: 2 })} `;
};

const DateFormatter = (value: string) => {
	return DateTime.fromISO(value).toFormat(useTranslation().t("dateFormat"));
};

export const useHeaders = () => {
	const { t } = useTranslation();
	const returnText = (text: string | number) => text || "-";

	const headers = [
		{
			id: "Store",
			name: t("store"),
			headers: [
				{
					id: (e: any) => e.inventory.store.clientID,
					key: "id",
					name: t("code"),
					align: "left",
					color: () => "#7F51FF",
					noWrap: true,
					style: {
						fontWeight: "bold",
					},
				},
				{
					id: (e: any) => e.inventory.store.name,
					key: "name",
					name: t("name"),
					align: "left",
					noWrap: true,
				},
				{
					id: (e: any) =>
						e.distributionPlans?.length ? (
							<SBChip
								color="primary"
								label={
									<SBBox display="flex" alignItems="center">
										<SBBox mb={0.3} mr={0.3}>
											<SBIcon
												icon="home"
												size="11"
												color="white"
											/>
										</SBBox>
										<SBTypography
											variant="caption"
											mr={0.3}
										>
											{e.distributionPlans?.length}
										</SBTypography>
										<SBTypography
											variant="caption"
											textTransform="capitalize"
										>
											{t("destination")}
										</SBTypography>
									</SBBox>
								}
							/>
						) : null,
					key: "",
					name: t(""),
					align: "left",
					noWrap: true,
				},
			],
		},
		{
			id: "Product",
			name: t("product"),
			headers: [
				{
					id: (e: any) => e.inventory.product.clientID,
					key: "id",
					name: t("code"),
					color: () => "#7F51FF",
					align: "left",
					noWrap: true,
					style: {
						fontWeight: "bold",
					},
				},
				{
					id: (e: any) => e.inventory.product.name,
					key: "name",
					name: t("name"),
					align: "left",
					noWrap: true,
				},
			],
		},
		// {
		// 	id: "CSL",
		// 	name: t("csl"),
		// 	headers: [
		// 		{
		// 			id: (e: any) => e.reachedCSL,
		// 			key: "reachedCSL",
		// 			name: t("reached"),
		// 			align: "right",
		// 			noWrap: true,
		// 			formatter: PercentageNoDecimalFormatter,
		// 		},
		// 		{
		// 			id: (e: any) => e.currentCSL,
		// 			key: "reachedCSL",
		// 			name: t("current"),
		// 			align: "right",
		// 			noWrap: true,
		// 			formatter: PercentageNoDecimalFormatter,
		// 		},
		// 	],
		// },
		{
			id: "Position",
			name: t("position"),
			headers: [
				{
					id: (e: any) => e.inventory.onHand,
					key: "onHand",
					name: t("onHand"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
				},
				{
					id: (e: any) => e.inventory.onOrder,
					key: "onOrder",
					name: t("onOrder"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
				},
			],
		},
		{
			id: "Distribution",
			name: t("distribution"),
			headers: [
				{
					id: (c: any) => c.netNeed,
					csvName: `${t("needed")} (${t("unit")})`,
					name: t("needed"),
					type: TableCellType.Number,
					formatter: (c: any) => c?.systemFormat() ?? "--",
				},
				{
					id: (c: any) => c.amount,
					csvName: `${t("qty")} (${t("unit")})`,
					name: t("qty"),
					style: {
						minWidth: 100,
					},
					noWrap: true,
					type: TableCellType.Number,
					formatter: (c: any) => c?.systemFormat() ?? "--",
				},
				{
					id: (c: any) =>
						c.status
							?.map((s: string) => t(s.trimStart()))
							.join(",") || "-",
					key: "status",
					name: t("status"),
					align: "left",
					type: TableCellType.Number,
					style: {
						whiteSpace: "nowrap",
					},
				},
				{
					id: (c: any) => c.unitCost * c.amount ?? "--",
					key: "totalCost",
					csvName: `${t("totalCost")} (${t("$")})`,
					name: t("totalCost", { moneyTag: "$" }),
					type: TableCellType.Number,
					formatter: CurrencyFormatter,
					width: "50%",
				},
				{
					id: (c: any) => c.volume * c.amount,
					key: "volume",
					csvName: `${t("volume")} (${t("m³")})`,
					name: t("volume"),
					type: TableCellType.Number,
					formatter: (c: any) => c?.systemFormat() ?? "--",
				},
				{
					id: (c: any) => c.weight * c.amount,
					csvName: `${t("weight")} (${t("Kg")})`,
					key: "weight",
					name: t("weight"),
					type: TableCellType.Number,
					formatter: (c: any) => c?.systemFormat() ?? "--",
				},
				{
					id: (e: any) => e.orderRequestDate,
					key: "orderRequesterDate",
					name: t("requested"),
					align: "center",
					noWrap: true,
					formatter: returnText,
				},
				{
					id: (e: any) => e.orderDeliverDate,
					key: "orderDeliverDate",
					name: t("delivery"),
					align: "center",
					noWrap: true,
					formatter: returnText,
				},
				{
					id: (e: any) => e.cycleEndDate,
					key: "cycleEndDate",
					name: t("coverage"),
					align: "center",
					noWrap: true,
					formatter: returnText,
				},
			],
		},
		{
			id: "Forecast",
			name: t("forecast"),
			headers: [
				{
					id: (e: any) => e.forecast,
					key: "predicted",
					name: t("predicted"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
				},
				{
					id: (e: any) => e.rupture,
					key: "rupture",
					name: t("rupture"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
				},
				{
					id: (e: any) => e.lostSales,
					key: "lostSales",
					name: t("lostSales"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
				},
				{
					id: (e: any) => e.expectedSales,
					key: "expectedSales",
					name: t("expectedSales"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
				},
				{
					id: (e: any) => e.sales,
					key: "sales",
					name: t("sales"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
				},
			],
		},
	];

	return { headers };
};
