import { useSnackbar } from "modules/Commons/SBOverlays/SBSnackbar";
import { useState } from "react";

export default () => {
	const [loading, setLoading] = useState(false);

	const useLoading = async (func: () => Promise<any>) => {
		setLoading(true);
		var result = await func();
		setLoading(false);
		return result;
	};

	const startLoading = () => setLoading(true);

	const stopLoading = () => setLoading(false);

	return {
		loading,
		useLoading,
		startLoading,
		stopLoading,
	};
};
