import { SBBox, SBCard, SBGrid, SBTypography } from "modules/Commons";
import SBSimpleLine from "modules/Commons/SBDataDisplays/SBSimpleLine";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import noDataToDisplay from "assets/noDataToDisplay.svg";

export default ({ demand, days }: ExplorationsListingProps) => {
	const { t } = useTranslation();
	const data = [
		{ a: 1 },
		{ a: 2 },
		{ a: 3 },
		{ a: 2 },
		{ a: 5 },
		{ a: 4 },
		{ a: 8 },
	];

	const positive = useMemo(() => demand > 0, [demand]);

	const color = useMemo(() => {
		return positive ? "#6FCF97" : "#EB5757";
	}, [positive]);

	const formattedDemand = useMemo(
		() => `${(demand * 100).systemFormatAsPercentage()}%`,
		[demand]
	);
	
	return (
		<SBBox flexGrow={1} alignItems="center" height={147} pt={1}>
			<SBCard>
				<SBTypography px={3} pt={3} fontWeight="bold">
					{t("sales")}
				</SBTypography>
				{isNaN(demand) ? (
					<SBBox pb={4} display="flex" justifyContent="center">
						<img src={noDataToDisplay} height="80px" />
					</SBBox>
				) : (
					<SBGrid container px={3} pb={4}>
						<SBGrid
							item
							xs={6}
							justifyContent="center"
							alignItems="center"
							display="flex"
						>
							{t("yourDemand", { count: days, demand: formattedDemand, days })} 
						</SBGrid>

						<SBGrid
							item
							xs={6}
							justifyContent="center"
							alignItems="center"
							display="flex"
							flexDirection="column"
						>
							<SBSimpleLine
								data={positive ? data : data.reverse()}
								color={color}
								dataKey="a"
							/>
							<SBTypography color={color} fontWeight="bold">
								{formattedDemand}
							</SBTypography>
						</SBGrid>
					</SBGrid>
				)}
			</SBCard>
		</SBBox>
	);
};

export interface ExplorationsListingProps {
	demand: number;
	days?: number;
}
