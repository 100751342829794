import { useTheme } from "@mui/material";
import { useLocation, useRouteMatch } from "react-router-dom";
import { SBIcon, SBStyledTooltip, SBIconButton, SBBox } from "modules/Commons";
import SBLink from "modules/Commons/SBRoutes/SBLink";
import SBActiveLink from "../SBActiveLink";
import clsx from "clsx";

/** Leftbar Icon Button with Link */
export default ({ title, icon, to, onClick }: SBActiveLinkIconProps) => {
	const theme = useTheme();
	const match = useRouteMatch({ path: to, exact: true });
	const isSub = to.indexOf(useLocation().pathname.split("/")[1]) >= 0;
	const color = isSub ? theme.palette.primary.main : "";
	const { search } = useLocation();

	return (
		<SBStyledTooltip title={title} placement="right" className="chooseMenu">
			<SBBox>
				<SBActiveLink to={to}>
					<SBIconButton
						style={{
							backgroundColor: isSub ? "rgba(0,0,0,0.04)" : "",
						}}
						data-testid="sb-active-link-icon"
						onClick={onClick}
					>
						<SBIcon size={20} icon={icon} color={color} />
					</SBIconButton>
				</SBActiveLink>
			</SBBox>
		</SBStyledTooltip>
	);
};

export interface SBActiveLinkIconProps {
	/** Title that will appear on tooltip */
	title: string | JSX.Element;
	/** Icon that will render inside SBIcon */
	icon: string;
	/** Route that will be redirected TO when clicked */
	to: string;
	/** Function to be triggered when clicked on the button*/
	onClick?: () => void;
}
