import clsx from "clsx";
import { DateTime } from "luxon";
import { SimulationPeriod } from "modules/Simulations/models/simulationPeriod";
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";

export default (props: SimulationChartTooltipXAxisProps) => {
	const classes = useStyles();
	const { payload, x, y, firstDate, lastDate } = props;
	const { t } = useTranslation();

	if (payload) {
		const firstDateTime = firstDate
			? DateTime.fromFormat(firstDate, "yyyy-MM-dd")
			: null;
		const lastDateTime = lastDate
			? DateTime.fromFormat(lastDate, "yyyy-MM-dd")
			: null;
		const formattedValue = payload.value
			? DateTime.fromFormat(payload.value, "yyyy-MM-dd")
			: null;

		const toShowValue = formattedValue?.toFormat(SimulationPeriod.Monthly);

		if (formattedValue?.day == 1)
			return (
				<g transform={`translate(${x},${y})`}>
					<line
						x1="0"
						y1="-9"
						x2="0"
						y2="-2"
						className={clsx(classes.line)}
					/>
					<text x={15} dy={9} className={clsx(classes.text)}>
						{toShowValue}
					</text>
				</g>
			);
	}
	return null;
};

export interface SimulationChartTooltipXAxisProps {
	payload: any;
	x: number;
	y: number;
	ignoreDateConstraint?: boolean;
	dateFormat?: string;
	firstDate?: string;
	lastDate?: string;
	simulationPeriod?: SimulationPeriod;
}
