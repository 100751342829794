import * as yup from "yup";
import Cron from "i18n/cron";
import {
	ReviewPeriodExpressionPosition,
	ReviewPeriodExpression,
} from "domain/Predictive/Enums/reviewPeriodExpressionPosition";

const { Verifications, Modifiers } = ReviewPeriodExpression;
const { DayOfMonth, DayOfWeek } = ReviewPeriodExpressionPosition;

function noDayZero(this: any, t: any) {
	return this.test({
		message: "Value 0 is not allowed",
		name: "noDayZero",
		test: function () {
			if (!!this.parent.reviewPeriodExpression) {
				const expressionUnmounted = Cron({ t }).unmountCronExpression(
					this.parent.reviewPeriodExpression
				);
				if (
					expressionUnmounted.monthDays.find((e) => e === "0") ||
					expressionUnmounted.monthDays.find((e) => e === "00")
				) {
					return false;
				}
				return true;
			}
			return true;
		},
	});
}

function daysInWeekReviewPeriodRequired(this: any, t: any) {
	return this.test({
		message: "You need to provide at least one day",
		name: "daysInWeekRPERequired",
		test: function () {
			if (Verifications.CheckType(this.originalValue) === DayOfWeek) {
				const days = Modifiers.getDaysInWeek(this.originalValue, t);
				if (days.length > 0 && days[0] === "#undefined") return false;
			}
			return true;
		},
	});
}

function daysInMonthReviewPeriodRequired(this: any) {
	return this.test({
		message: "You need to provide at least one day",
		name: "daysInMonthRPERequired",
		test: function () {
			if (Verifications.CheckType(this.originalValue) === DayOfMonth) {
				const days = Modifiers.getDaysInMonth(this.originalValue);

				if (days.length > 0 && days[0] === "") return false;
			}
			return true;
		},
	});
}

function sourcingRequired(
	this: any,
	config: {
		sourcingType: string;
	}
) {
	return this.test({
		message: "Provider is a required field",
		name: "sourcingRequired",
		test: function () {
			const sourcingType = this.parent[config.sourcingType];
			if (sourcingType === 0) return true;
			if (this.originalValue === null) return false;
			return true;
		},
	});
}

yup.addMethod(yup.number, "sourcingRequired", sourcingRequired);
yup.addMethod(yup.string, "noDayZero", noDayZero);
yup.addMethod(
	yup.string,
	"daysInWeekReviewPeriodRequired",
	daysInWeekReviewPeriodRequired
);
yup.addMethod(
	yup.string,
	"daysInMonthReviewPeriodRequired",
	daysInMonthReviewPeriodRequired
);

declare module "yup" {
	interface NumberSchema {
		sourcingRequired: (config: { sourcingType: string }) => NumberSchema;
	}

	interface StringSchema {
		noDayZero: () => StringSchema;
		daysInWeekReviewPeriodRequired: () => StringSchema;
		daysInMonthReviewPeriodRequired: () => StringSchema;
	}
}
