import { IEntity } from "domain/Domain/Entities/IEntity";
import { useRequest } from "hooks/useRequest";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { DateTime } from "luxon";
import { ISimulation } from "modules/Simulations/models/ISimulation";
import { SimulationPeriod } from "../models/simulationPeriod";

function getKeyByValue<T>(value: string, enumeration: any) {
	const indexOfS = Object.values(enumeration).indexOf(value as unknown as T);
	const key = Object.keys(enumeration)[indexOfS];
	return key;
}

export const SimulationsRequester = (() => {
	const request = useRequest("simulation");
	return {
		...request,
		simulate: ({
			products,
			categories,
			stores,
			startDate,
			endDate,
			useRealPosition = false,
		}: SimulationFilterBody) => {
			return SimulationsRequester.POST(
				`products/${startDate.toFormat(
					"yyyy-MM-dd"
				)}/${endDate.toFormat(
					"yyyy-MM-dd"
				)}?useRealPositionOnProjection=${useRealPosition}`,
				{
					products,
					categories,
					stores,
				}
			).then((c) => c.data as ISimulation[]);
		},
		simulateCSV: ({
			products,
			categories,
			stores,
			startDate,
			endDate,
			useRealPosition = false,
		}: SimulationFilterBody) => {
			return SimulationsRequester.POST(
				`products/csv/${startDate.toFormat(
					"yyyy-MM-dd"
				)}/${endDate.toFormat(
					"yyyy-MM-dd"
				)}?useRealPositionOnProjection=${useRealPosition}`,
				{
					products,
					categories,
					stores,
				}
			).then((c) => c.data as string);
		},
		summarizedCSV: ({
			products,
			categories,
			stores,
			startDate,
			endDate,
			timePeriod,
			useRealPosition = false,
		}: SimulationFilterBody) => {
			const formattedStart = startDate.toFormat("yyyy-MM-dd");
			const formattedEnd = endDate.toFormat("yyyy-MM-dd");
			const timePeriodName = getKeyByValue(timePeriod!, SimulationPeriod); //TODO: Verificar Timeperiod Antess
			return SimulationsRequester.POST(
				`summarized/csv/${formattedStart}/${formattedEnd}?useRealPositionOnProjection=${useRealPosition}&timePeriod=${timePeriodName}`,
				{
					products,
					categories,
					stores,
				}
			).then((c) => c.data as string);
		},
	};
})();

export interface SimulationFilterBody {
	startDate: DateTime;
	endDate: DateTime;
	products: IEntity[];
	categories: IEntity[];
	stores: IEntity[];
	useRealPosition?: boolean;
	timePeriod: SimulationPeriod;
}
