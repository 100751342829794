import { SBBox, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";

export default ({ min, max }: SBSquaredIconRangeProps) => {
	const { t } = useTranslation();

	return min || max ? (
		<SBBox display="flex" flexDirection="column">
			<SBTypography
				fontWeight="700"
				textTransform="uppercase"
				variant="caption"
				mb={0}
				lineHeight={1.5}
			>
				{t("max")}: {max}
			</SBTypography>
			<SBTypography
				fontWeight="700"
				textTransform="uppercase"
				lineHeight={1}
				variant="caption"
			>
				{t("min")}: {min}
			</SBTypography>
		</SBBox>
	) : null;
};

export interface SBSquaredIconRangeProps {
	/** Min value to be displayed */
	min?: string | number;
	/** Max value to be displayed */
	max?: string | number;
}
