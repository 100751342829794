import axios from "axios";
import { useClientIdentificator } from "hooks/useClientIdentificator";
import jwt_decode from "jwt-decode";
import Environment from "utils/environment";
import { StorageKey, LoginFormKey } from "domain/Utils";
import useRememberMe from "hooks/useRememberMe";

export const useIdentityAuthentication = () => {
	const { clientEnvironment, client, environment } = useClientIdentificator();
	const { storage } = useRememberMe();

	const login = async (email: string, password: string): Promise<any> => {
		const formData = new URLSearchParams();

		formData.append(LoginFormKey.GrantType, Environment.AuthorityGrantType);
		formData.append(LoginFormKey.Scope, Environment.AuthorityScope);
		formData.append(LoginFormKey.Username, email);
		formData.append(LoginFormKey.Password, password);
		formData.append(LoginFormKey.Tenant, client);
		formData.append(LoginFormKey.Environment, environment);
		formData.append(LoginFormKey.ClientId, Environment.AuthorityClientId);
		formData.append(LoginFormKey.ClientSecret, Environment.AuthoritySecret);

		const auth = await axios.post(Environment.LoginURL, formData.toString(), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});

		saveCredentials(auth);
		return auth;
	};

	const logout = (): void => {
		storage().removeItem(StorageKey.Identity);
		storage().removeItem(StorageKey.SBDate);
		storage().removeItem(StorageKey.SBFilters);
	};

	const getAccount = (): any => {
		try {
			const token = getCredentials().data.access_token;
			return jwt_decode(token);
		} catch (e) {
			return null;
		}
	};

	const getToken = () => {
		try {
			return getCredentials().data.access_token;
		} catch (e) {
			return "";
		}
	};

	const userIsLogged = (): boolean => {
		return !!getAccount();
	};

	const saveCredentials = (auth: any) => storage().setItem(StorageKey.Identity, JSON.stringify(auth));

	const getCredentials = (): any => {
		try {
			const identity = storage().getItem(StorageKey.Identity);
			return JSON.parse(identity ?? "");
		} catch (e) {
			return (e as Error).message;
		}
	};

	return {
		login,
		logout,
		getToken,
		userIsLogged,
	};
};
