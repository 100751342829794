import { ReactNode } from "react";
import SBTooltip from "../SBDataDisplays/SBTooltip";
import SBTypography from "../SBDataDisplays/SBTypography";
import SBBox from "../SBLayouts/SBBox";
import SBCard from "../SBLayouts/SBCard";

export default ({
	item,
	tootltip,
	ignoreCard,
}: {
	ignoreCard?: boolean;
	item?: {
		label: string;
		value?: number;
		range?: string;
		formatter?: (val?: number) => string;
	};
	tootltip: string;
}) => {
	const content = (child: ReactNode) =>
		ignoreCard ? (
			<>{child}</>
		) : (
			<SBCard style={{ width: "100%", height: "100%" }}>{child}</SBCard>
		);

	return content(
		<SBBox p={3}>
			<SBBox>
				<SBTooltip title={tootltip} placement="top">
					<SBTypography fontWeight="400" color="gray2.main">{item?.label}</SBTypography>
				</SBTooltip>
				<SBTypography fontWeight="600" variant="h5" color="gray2.main">
					{item?.formatter
						? item.formatter(item?.value)
						: item?.value}
				</SBTypography>
				<SBTypography textTransform="uppercase" fontWeight="600" color="gray2.main" variant="caption">
					{item?.range}
				</SBTypography>
			</SBBox>
		</SBBox>
	);
};
