import { DateTime } from "luxon";
import { useStyles } from "./style";
import clsx from "clsx";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
	SBBox,
	SBDatePicker,
	SBIcon,
	SBIconButton,
	SBMenu,
} from "modules/Commons";
import { DateRangeType } from "modules/Commons/SBDate/models/dateRangeType";
import { useTranslation } from "react-i18next";
import { IDatePicker } from "domain/Utils/models/IDatePicker";
import { SBTranslate } from "i18n";
import { useSystemFilterContext } from "contexts/SystemFilterContext";

export default forwardRef(
	(
		{
			date,
			startDate,
			endDate,
			minDate,
			maxDate,
			availableDates,
			onChange,
			setDateRangeType = () => { },
			dateRangeType = DateRangeType.Daily,
			fixedStart = false,
			range = true,
			solo,
		}: {
			date?: DateTime;
			startDate?: DateTime;
			endDate?: DateTime;
			minDate?: DateTime;
			maxDate?: DateTime;
			availableDates?: string[];
			onChange?: (value: IDatePicker) => any;
			setDateRangeType?: (value: DateRangeType) => any;
			dateRangeType?: DateRangeType;
			fixedStart?: boolean;
			range?: boolean;
			solo?: boolean;
		},
		ref
	) => {
		const { t } = useTranslation();
		const classes = useStyles();
		const menuRef = useRef();
		const { context } = useSystemFilterContext();
		const [selectedDate, setSelectedDate] = useState<DateTime | undefined>(date);
		const [firstUpdate, setFirstUpdate] = useState(true);

		useImperativeHandle(ref, () => ({
			close: () => (menuRef.current as any).close(),
		}));

		const handleOnApply = (value: IDatePicker) => {
			setSelectedDate(value.date);
			(menuRef.current as any).close();
			onChange && onChange(value);
		};

		useEffect(() => {
			if (firstUpdate) setFirstUpdate(false);
			else !solo && setSelectedDate(context);
		}, [context]);

		return (
			<SBBox display="flex" height="100%">
				<SBMenu
					ref={menuRef}
					canOpen
					action={
						<div className={clsx(classes.sbIconDateBox)}>
							<SBIconButton>
								<SBIcon
									icon="calendar"
									color="#828282"
									size={17}
								/>
							</SBIconButton>
						</div>
					}
					content={
						<SBDatePicker
							fixedStart={fixedStart}
							range={range}
							defaultValue={{ date: selectedDate, startDate, endDate }}
							onApply={handleOnApply}
							onChangeType={setDateRangeType}
							minDate={minDate}
							maxDate={maxDate}
							availableDates={availableDates}
							type={dateRangeType}
						/>
					}
				/>
				<SBBox
					className={clsx(classes.sbDateBox, classes.sbLeftDateBox)}
				>
					{range ? (startDate && startDate?.toFormat(t("dateFormat"))) || "-" : (selectedDate && selectedDate.toFormat(SBTranslate("dateFormat"))) || "-"}
				</SBBox>
				{range ?
					<SBBox
						className={clsx(classes.sbDateBox, classes.sbRightDateBox)}
					>
						{(endDate && endDate.toFormat(t("dateFormat"))) || "-"}
					</SBBox>
					:
					<></>
				}
			</SBBox>
		);
	}
);
