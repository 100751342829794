import homeDeadInventoryIconCard from "assets/homeDeadInventoryIconCard.svg";
import greenCheck from "assets/greenCheck.svg";
import SBDeadInventoryBaseCard from "../SBDeadInventoryBaseCard";

export default ({ onSeeAll, deadInventoriesCount, deadInventoriesPositions, loading }: SBWithDeadInventoryCardProps) => {
    return (
        <SBDeadInventoryBaseCard
            icon={<img height={104} src={deadInventoriesCount > 0 ? homeDeadInventoryIconCard : greenCheck} alt="" />}
            deadInventoriesCount={deadInventoriesCount}
            deadInventoriesPositions={deadInventoriesPositions}
            onSeeAll={onSeeAll}
            loading={loading}
            title="views.home.deadInventoryCardTitle"
            subTitle="views.home.deadInventoryCardSubTitle"
            description="views.home.deadInventoryCardSubTitle_other"
        />
    );
};

interface SBWithDeadInventoryCardProps {
    deadInventoriesCount: number;
    deadInventoriesPositions: number;
    loading?: boolean | undefined;
    onSeeAll?: () => void;
}
