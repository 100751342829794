import clsx from "clsx";
import { IEntity } from "domain/Domain/Entities/IEntity";
import { SBBox, SBIcon, SBTypography } from "modules/Commons";
import { useMemo } from "react";
import { useStyles } from "./style";

export default ({
	item,
	onClick,
	selectedItems,
	children,
	filterIsolated,
	filterType,
	deep,
	getItemType = (item) => item.type!,
	getItemDepth = (item) => item.deep!,
	getItemIcon = (item) => item.icon!,
	getItemTitle = (item) => item.name!,
	getItemID = (item) => item.clientID!,
}: SystemSearchContentProps<IEntity>) => {
	const classes = useStyles();
	const itemDepth = getItemDepth(item);
	const type = getItemType(item);
	const itemID = getItemID(item);
	const icon = getItemIcon(item);
	const title = getItemTitle(item);

	const verifyFilterType = () => {
		if (!filterType) return false;

		if (filterIsolated === filterType) {
			if (filterType !== item.type) return true;
			else return false;
		} else {
			if (filterType && filterType !== type && filterIsolated === type)
				return true;
			else return false;
		}
	};

	const isSelected = useMemo(
		() => !!selectedItems.find((c) => getItemID(c) === itemID),
		[item, selectedItems]
	);

	const isWrongDepth = useMemo(
		() => (deep && itemDepth && deep !== itemDepth) || verifyFilterType(),
		[item, selectedItems]
	);

	return (
		<SBBox
			key={item?.name}
			py={1}
			display="flex"
			onClick={isWrongDepth ? () => {} : () => onClick(item)}
			alignItems="center"
			className={clsx(
				classes.systemSearchContent,
				isWrongDepth && "disabled",
				isSelected && "selected"
			)}
		>
			<SBBox pl={2} pr={1} display="column">
				<SBIcon
					icon={icon}
					color={isSelected ? "#7F51FF" : "gray"}
					size={20}
				/>
			</SBBox>
			<SBBox pl={1} display="column" maxWidth={0.8}>
				<SBTypography fontWeight="bold" variant="caption" noWrap>
					{itemID}
				</SBTypography>
				<SBTypography variant="body1" noWrap>
					{title}
				</SBTypography>
				{children}
			</SBBox>
		</SBBox>
	);
};

interface SystemSearchContentProps<IEntity> {
	/** Item to render*/
	item: IEntity;
	/** List of selected items to check if item is selected */
	selectedItems: IEntity[];
	/** Children element  */
	children?: JSX.Element | React.ReactNode;
	/** Depth of the category of the item*/
	deep?: number;
	filterIsolated: any;
	filterType: any;
	/** Func to execute when clicked on item*/
	onClick: (item: IEntity) => void;
	/** Func to get ID from item*/
	getItemID?: (item: IEntity) => string;
	/** Func to get Type from item*/
	getItemType?: (item: IEntity) => string;
	/** Func to get Icon from item*/
	getItemIcon?: (item: IEntity) => string;
	/** Func to get Title from item*/
	getItemTitle?: (item: IEntity) => string;
	/** Func to get Depth from item*/
	getItemDepth?: (item: IEntity) => number;
}
