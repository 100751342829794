import { useRef } from "react";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { SBTranslate } from "i18n";
import { SBBox, SBButton, SBIcon, SBTypography } from "modules/Commons";
import clsx from "clsx";
import { IComposedFilter } from "domain/Utils";

export default ({
	disabled,
	onApply,
	applicatorActions,
	onClickParams,
}: SBApplyButtonProps) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const ref = useRef();

	return (
		<SBBox ref={ref} ml={2} mr="auto" display={"flex"}>
			<SBBox>
				<SBButton disabled={disabled} onClick={onApply}>
					{SBTranslate("apply")}
				</SBButton>
			</SBBox>
			<SBBox ml={2}>
				{applicatorActions?.map((action) => {
					return (
						<SBButton disabled={disabled} onClick={() => action.onClick(onClickParams?.())}>
							<SBIcon icon="package-plus" color="white" className={clsx(classes.sbIconButton)} />
							<SBTypography textTransform="none" lineHeight="12px" fontSize="12px" fontWeight={500}>
								{t(action.title)}
							</SBTypography>
						</SBButton>
					);
				})}
			</SBBox>
		</SBBox>
	);
};

export interface SBApplyButtonProps {
	disabled?: boolean;
	onApply?: any;
	applicatorActions?: {
		title: string;
		onClick: (params?: IComposedFilter) => any;
	}[];
	applicatorLoading?: boolean;
	onClickParams?: () => IComposedFilter
}