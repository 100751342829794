import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	itemBaseContainer: {
		active: {
			backgroundColor: "#7f51ff44",
		},
		"&.active-left": {
			borderRadius: "0% 50% 50% 0% !important",
		},
		"&.active-right": {
			borderRadius: "50% 0% 0% 50% !important",
		},
		"&.hoverable:not(.active):not(.on-middle):hover": {
			borderRadius: "50%",
			backgroundColor: "#7f51ff",
			color: "white",
		},
		"&.hoverable:hover": {
			cursor: "pointer",
		},
		"&.disabled": {
			pointerEvents: "none",
			opacity: 0.5,
		},
		"&.top-right-radius:not(.active)": {
			borderTopRightRadius: "10px",
		},
		"&.top-left-radius:not(.active)": {
			borderTopLeftRadius: "10px",
		},
		"&.bottom-right-radius:not(.active)": {
			borderBottomRightRadius: "10px",
		},
		"&.bottom-left-radius:not(.active)": {
			borderBottomLeftRadius: "10px",
		},
		"&.on-middle": {
			backgroundColor: "#7f51ff44",
			color: "black",
		},
		"&.isLastDataBaseUpdate": {
			color: "#7f51ff",
			borderRadius: "50%",
			border: "1px dashed #7f51ff",
		},
	},
	item: {
		border: "none",
		width: 56,
		height: 56,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: 10,
		"&.active": {
			backgroundColor: "#7f51ff",
			cursor: "pointer",
			borderRadius: "50% !important",
			color: "white",
		},
	},
}));
