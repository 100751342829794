import SBCheckboxTableCell, {
	SBCheckboxTableCellProps,
} from "modules/Commons/SBDataDisplays/SBTable/SBTableCells/SBCheckboxTableCell";

/** Show the checkbox to select all items on header */
export const SBTableHeaderSelection = ({
	selectable,
	onSelectAllClick,
	disabled,
	checked,
	indeterminate,
}: SBTableHeaderSelectionProps) => {
	return selectable ? (
		<SBCheckboxTableCell
			disabled={disabled}
			checked={checked}
			indeterminate={indeterminate}
			onChange={onSelectAllClick}
		/>
	) : null;
};

export interface SBTableHeaderSelectionProps extends SBCheckboxTableCellProps {
	/** Render the checkbox to selection if true */
	selectable?: boolean;
	/** Trigger the funcition when checkbox is clicked */
	onSelectAllClick?: () => {};
}
