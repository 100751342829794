import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	slideOutsideContainer: {
		right: 50,
		position: "fixed",
		zIndex: 1001,
		width: "350px",
		boxShadow: "1px 1px 5px 5px rgba(0,0,0,.1)",
		background: "white",
		bottom: 30,
		borderRadius: 8,
		overflowY: "auto",
		minHeight: "172px",
	},
}));
