import _ from "lodash";
import { useTranslation } from "react-i18next";

export const CustomizedContent = (props: any) => {
	const { t } = useTranslation();

	const {
		root,
		depth,
		currentDepth,
		x,
		y,
		width,
		height,
		size,
		name,
		onClick,
	} = props;

	return (
		<g onClick={() => onClick(root.name ?? name)}>
			<rect
				x={x}
				y={y}
				width={width}
				height={height}
				style={{
					pointerEvents: depth == currentDepth + 1 ? "all" : "none",
					fill: depth < 2 ? props.color : "none",

					stroke: "#fff",
					strokeWidth: 2 / (depth + 1e-10),
					strokeOpacity: 1 / (depth + 1e-10),
				}}
			/>
			{depth === 1 ? (
				<text
					x={x + width / 2}
					y={y + height / 2 + 7}
					textAnchor="middle"
					fill="#4F4F4F"
					fontSize={20}
					fontWeight={700}
				>
					{currentDepth === 0
						? `${t(
								name.charAt(0).toLowerCase() + name.slice(1)
						  )} (${size})`
						: `${name} (${size})`}
				</text>
			) : null}
		</g>
	);
};
