import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	uploaderContainer: {
		display: "flex",
		width: "100%",
		height: "calc(100% - 70px)",
		overflow: "hidden",
	},
	uploaderSideContent: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		width: "37%",
		height: "100%",
	},
	configCronButtonContainer: {
		display: "flex",
		flexDirection: "column",
		background: `${theme.palette.lightPurple1.main}`,
		padding: "24px",
		border: `2px dashed ${theme.palette.primary.main}`,
		boxSizing: "border-box",
		borderRadius: "8px",
		margin: "0 16px 24px",
	},
	configCronButtonTitle: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		marginBottom: "16px",
	},
	configCronButton: {
		border: `1px solid ${theme.palette.primary.main}`,
	},
	uploaderContent: {
		display: "flex",
		flexDirection: "column",
		width: "63%",
		height: "100%",
	},
	uploaderMainContent: {
		display: "flex",
		justifyContent: "center",
		width: "calc(100% - 32px)",
		height: "100%",
		margin: "16px",
		"&.onUpload": {
			margin: "16px 16px 83px",
		},
	},
}));
