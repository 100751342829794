export enum DomainEnum {
	Product = "product",
	Store = "store",
	Purchase = "purchase",
	Cost = "cost",
	Supplier = "supplier",
	CSL = "csl",
	End = "end",
	Forecast = "forecast",
	Need = "need",
	ProjectedEnd = "projectedEnd",
	Position = "position",
	Received = "received",
	Requested = "requested",
	Rupture = "rupture",
	Sales = "sales",
	Start = "start",
	OnOrder = "onOrder",
	Emergency = "emergency",
	Recommended = "recommended",
	Suggested = "suggested",
}
