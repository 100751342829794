import { DateTime } from "luxon";
import { SBBox, SBOutlinedButton, SBTypography } from "modules/Commons";
import { useStyles } from "./styles";
import SBNotificationCardStack from "../SBNotificationCardStack";
import { INotification } from "domain/Utils/models/INotification";
import NotificationFactory from "domain/Utils/Factories/NotificationFactory";
import parse from 'html-react-parser';
import { utcTimeZoneConverter } from "utils/timeZoneConverter";

export default (({ notificationDTO, onClose = () => null }: NotificationCardProps) => {
	const classes = useStyles();
	const { build } = NotificationFactory();
	const notification = build(notificationDTO);
	const action = notification?.action;

	return (
		<SBBox className={classes.notificationCard}>
			<SBBox className={classes.notificationTitle}>
				<SBTypography fontWeight={600} fontSize={14}>
					{notification?.title}
				</SBTypography>
				{!notification?.seen && <SBBox className={classes.seenDot} />}
				<SBNotificationCardStack stack={notification?.stack} />
			</SBBox>
			<SBTypography my={1} fontSize={12} className={classes.notificationCardDetails}>
				{parse(notification?.description as string)}
			</SBTypography>
			<SBBox className={classes.notificationCardFooter}>
				<SBBox>
					{notification?.actionText && action && (
						<SBOutlinedButton onClick={() => {
							action();
							onClose();
						}}>
							<SBTypography textTransform="none">
								{notification.actionText}
							</SBTypography>
						</SBOutlinedButton>
					)}
				</SBBox>
				<SBBox>
					<SBTypography fontSize={12} fontWeight={600}>
						{utcTimeZoneConverter(notification?.date ?? DateTime.now()).toLocaleString(DateTime.DATETIME_SHORT)}
					</SBTypography>
				</SBBox>
			</SBBox>

		</SBBox>
	);
});

interface NotificationCardProps {
	notificationDTO: INotification;
	onClose?: () => void;
}
