import { TableRow } from "@mui/material";
import { SBTableColumnGroup } from "../../models/TableColumnGroup";
import SBTableHeaderGroup from "./SBTableHeaderGroup";

/** Show table header group  */
export default ({
	showGroups,
	groups,
	selectable,
}: SBTableHeaderGroupsProps) => {
	return (
		<TableRow>
			{showGroups &&
				groups &&
				groups.map((cell, index) => (
					<SBTableHeaderGroup
						key={cell.id}
						name={cell.name}
						selectable={selectable}
						isFirst={index === 0}
						colSpan={cell && cell.headers && cell.headers.length}
						align={cell.align}
					/>
				))}
		</TableRow>
	);
};
export interface SBTableHeaderGroupsProps {
	/** Used to decide if will show the group of the headers*/
	showGroups?: boolean;
	/** Used to check if the first group will have +1 on the colSpan */
	selectable?: boolean;
	/** List of groups to render */
	groups: SBTableColumnGroup[];
}
