import { SBTypography } from "modules/Commons";
import SBTableCell, { SBTableCellProps } from "../SBTableCell";

export default ({
	value,
	padding,
	color,
	style,
	maxWidth,
	align = "left",
	noWrap = false,
	onClick,
}: SBStringTableCellProps) => {
	return (
		<SBTableCell
			align={align}
			padding={padding}
			style={style}
			onClick={onClick}
		>
			<SBTypography
				style={{ ...style, maxWidth }}
				color={color}
				noWrap={noWrap}
			>
				{value}
			</SBTypography>
		</SBTableCell>
	);
};

export interface SBStringTableCellProps extends SBTableCellProps {
	onClick: (e: any) => void;
	value: string | number;
	maxWidth: string;
	noWrap?: boolean;
}
