import { StorageKey } from "domain/Utils";
import useRememberMe from "hooks/useRememberMe";
export const useDateStorageCache = () => {
	const loc = window.location;
	const { storage } = useRememberMe();
	const setDateItem = (item: string) => {
		storage().setItem(StorageKey.SBDate, JSON.stringify(item));
	};

	const setIfNotEmpty = (item?: string) => {
		if (item != null && item != "")
			storage().setItem(StorageKey.SBDate, JSON.stringify(item));
	};

	const getDateItem = () => {
		var foundDate = storage().getItem(StorageKey.SBDate);
		if (!foundDate) return null;
		return JSON.parse(foundDate);
	};

	const getURLDate = (): string | null => new URLSearchParams(loc.search).get(StorageKey.Context);


	return {
		setDateItem,
		setIfNotEmpty,
		getDateItem,
		getURLDate
	};
};
