import { realpathSync } from "fs";
import Cron from "i18n/cron";

export enum ReviewPeriodExpressionPosition {
	Seconds = 0,
	Minutes = 1,
	Hours = 2,
	DayOfMonth = 3,
	Month = 4,
	DayOfWeek = 5,
	Year = 6,
	Default = 7,
	NotDefined = 8,
}

export const ReviewPeriodExpression = {
	Default: {
		allDays: "* * * * * ? *",
		nullable: null,
	},
	Verifications: {
		IsDefault: (value: ReviewPeriodExpressionPosition) =>
			value === ReviewPeriodExpressionPosition.Default,
		IsDayOfWeek: (value: ReviewPeriodExpressionPosition) =>
			value === ReviewPeriodExpressionPosition.DayOfWeek,
		IsDayOfMonth: (value: ReviewPeriodExpressionPosition) =>
			value === ReviewPeriodExpressionPosition.DayOfMonth,
		IsNotDefined: (value: ReviewPeriodExpressionPosition) =>
			value === ReviewPeriodExpressionPosition.NotDefined,
		CheckType: (value: string | null) => {
			if (!value || value === null)
				return ReviewPeriodExpressionPosition.NotDefined;

			const items = value.split(" ");

			if (
				items[ReviewPeriodExpressionPosition.DayOfMonth] !== "*" &&
				items[ReviewPeriodExpressionPosition.DayOfMonth] !== "?"
			)
				return ReviewPeriodExpressionPosition.DayOfMonth;

			if (
				items[ReviewPeriodExpressionPosition.DayOfWeek] !== "*" &&
				items[ReviewPeriodExpressionPosition.DayOfWeek] !== "?"
			)
				return ReviewPeriodExpressionPosition.DayOfWeek;

			return ReviewPeriodExpressionPosition.Default;
		},
	},
	Modifiers: {
		getValidExpressions: (expression: string | null): string | null => {
			if (expression === null) return null;
			return expression;
		},
		updateDayInMonth: (value: string) => {
			if (!value) return [];

			var v = value.split(",");

			for (var i = 0; i < v.length; i++)
				v[i] =
					v[i] === "L" || v[i] === "l"
						? v[i].toUpperCase()
						: v[i]
								.limitedNumber({ final: 31 })
								.naturalNumber({ withoutZero: true });
			return v.distinct();
		},
		updateDaysInWeek: (value: any, daysInWeek: any[]): any[] => {
			var newDaysInWeek = [...daysInWeek];

			if (value.checked) newDaysInWeek.push(`${value.day}#${value.week}`);
			else
				newDaysInWeek.splice(
					newDaysInWeek.indexOf(`${value.day}#${value.week}`),
					1
				);

			return newDaysInWeek;
		},

		getDaysInMonth: (expression: string | null): string[] => {
			if (!expression) return [];
			var v = expression
				.split(" ")
				[ReviewPeriodExpressionPosition.DayOfMonth].split("|");

			for (var i = 0; i < v.length; i++)
				v[i] =
					v[i] === "L"
						? v[i]
						: v[i]
								.limitedNumber({ final: 31 })
								.naturalNumber({ withoutZero: true });
			return v;
		},
		getDaysInWeek: (expression: string | null, t: any): string[] => {
			if (!expression) return [];

			const { unmountCronExpression } = Cron({ t });
			const weekDays = unmountCronExpression(expression).weekDays;
			return !!weekDays
				? weekDays.map((weekDay: any) => `${weekDay[0]}#${weekDay[1]}`)
				: [];
		},
	},
};
