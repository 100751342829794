import IcomoonReact from "icomoon-react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import clsx from "clsx";
import SBTooltip from "../SBTooltip";
import { ReactNode } from "react";

const iconSet = require("../../../../assets/selection.json");

export default ({
	icon,
	size = 20,
	color = "",
	fillColor = "",
	style = {},
	className = {},
	hoverColor = "",
	hoverFillColor = "",
	tooltip,
}: SBIconProps) => {
	const theme = useTheme();
	const pallete = theme?.palette as any; //TODO: Fix any of icon

	const themedColor = pallete[color] ? pallete[color].main : color;

	const themedFillColor = pallete[fillColor]
		? pallete[fillColor].main
		: fillColor;
	const themedHoverColor = pallete[hoverColor]
		? pallete[hoverColor].main
		: null;
	const themedHoverFillColor = pallete[hoverFillColor]
		? pallete[hoverFillColor].main
		: null;

	const classes = makeStyles(() => ({
		styledIcon: {
			transition: "500ms",
			" & > path": {
				stroke: themedColor,
				transition: "500ms",
			},
		},
		styledHoverIconFill: {
			"&:hover path": {
				fill: themedHoverFillColor,
			},
		},
	}))();

	const parent = (children: ReactNode) =>
		tooltip ? (
			<SBTooltip title={tooltip}>
				<>{children}</>
			</SBTooltip>
		) : (
			<>{children}</>
		);

	return parent(
		<IcomoonReact
			data-testid="sb-icon"
			iconSet={iconSet}
			icon={icon}
			size={size}
			className={clsx(
				classes.styledIcon,
				className,
				themedHoverFillColor && classes.styledHoverIconFill
			)}
			color={themedFillColor}
			style={style}
		/>
	);
};

export interface SBIconProps {
	/** Icon from the file selection.json to be rendered*/
	icon: string;
	/** Size of the icon */
	size?: string | number;
	/** Color of the icon. Can be only the theme colors */
	color?: string;
	/** Color of the icon. Can be only the theme colors */
	fillColor?: string;
	/** Color of the icon when hover. Can be only the theme colors */
	hoverColor?: string;
	/** Color of the icon when hover. Can be only the theme colors */
	hoverFillColor?: string;
	/** Tooltip explanation of icon */
	tooltip?: string;
	/** Aditional styles to use on item */
	style?: {};
	/** Aditional classes to use on item */
	className?: {};
}
