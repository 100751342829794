import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	inventoryTitle: {
		padding: "4px 8px",
		paddingTop: "5px",
		borderRadius: "4px",
		display: "flex",
		flexDirection: "column",
		width: "100%",
		"&.bordered": {
			border: "1px solid #eeeeeeaa",
		},
		"&.row": {
			flexDirection: "row",
		},
		"& .content": {
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
			width: "100%",
			"& .title": {
				marginLeft: "0.25rem",
				fontSize: theme.typography!.subtitle2?.fontSize,
				color: theme.palette.iconGray.main,
				fontWeight: "500",
			},
			"& .name": {
				fontWeight: "700",
				fontSize: theme.typography!.subtitle2?.fontSize,
				color: theme.palette.darkPurple.main,
				"&:nth-child(2)": {
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					overflow: "hidden",
					width: "inherit",
					marginLeft: ".3rem",
					marginRight: ".3rem",
				},
			},
		},
	},
}));
