import { IEntity } from "domain/Domain";
import { FilterType, IQueryFilterModel } from "domain/Utils";

export const useFilterToQueryString = () => {
	const buildFilter = (entities: IEntity[] | undefined, key: string) =>
		entities?.map((c) => `${key}=${c.clientID}`).join("&") ?? [];

	const fetchQueryString = ({
		context: date,
		start,
		end,
		products,
		categories,
		stores,
		tag,
		emergencial,
	}: IQueryFilterModel) => {
		const productsFilter = buildFilter(products, FilterType.Product);
		const storesFilter = buildFilter(stores, FilterType.Store);
		const categoriesFilter = buildFilter(categories, FilterType.Category);
		const dateFilter = date ? `context=${date.toISODate()}` : null;
		const startFilter = start ? `start=${start.toISODate()}` : null;
		const endFilter = end ? `end=${end.toISODate()}` : null;

		const tagFilter = tag ? `tag=${tag}` : null;
		const emergencialFilter =
			emergencial != undefined && emergencial != null
				? `emergencial=${emergencial}`
				: null;

		const queryString = `${[
			dateFilter ?? null,
			startFilter ?? null,
			endFilter ?? null,
			productsFilter ?? null,
			categoriesFilter ?? null,
			storesFilter ?? null,
			tagFilter ?? null,
			emergencialFilter ?? null,
		]
			.filter((c) => c)
			.join("&")}`;

		return queryString.length > 0 ? "?" + queryString : "";
	};

	return { fetchQueryString, buildFilter };
};
