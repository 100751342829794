import SimulationChartTooltip from "../SimulationChartTooltip";
import {
	YAxis,
	Tooltip,
	AreaChart,
	Area,
	ResponsiveContainer,
	ReferenceLine,
	XAxis,
	ReferenceDot,
	Label,
} from "recharts";
import { useMemo, useCallback, useEffect, useState } from "react";
import {
	NameType,
	ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { TooltipProps } from "recharts/types/component/Tooltip";
import SimulationChartTooltipXAxis from "../SimulationChartTooltipXAxis";
import { DateTime } from "luxon";
import { IChartFilter } from "domain/Chart/Entities/IChartFilter";
import { IChart } from "domain/Chart/Entities/IChart";
import { ISimulationPoint } from "modules/Simulations/models/ISimulationPoint";
import { useTheme } from "@mui/system";
import { BaseThemeOptions } from "themes/base";
import { useTranslation } from "react-i18next";
import { IChartTooltipRow } from "domain/Chart/Entities/IChartTooltipRow";
import { ISimulationModel } from "modules/Simulations/models/ISimulationModel";

export default ({
	dataArray,
	chartID,
	chartFilter,
	selectedChart,
	realChart,
	projectedChart,
	cardIndex,
	tooltipRows,
	ignoreDateConstraint,
	simulationPeriod,
}: any) => {
	const { t } = useTranslation();
	const theme = useTheme<BaseThemeOptions>();
	const [loading, setLoading] = useState(true);
	const [min, setMin] = useState(0);
	const [max, setMax] = useState(0);
	const [realNegativeValues, setRealNegativeValues] = useState<any>([]);
	const [projectedNegativeValues, setProjectedNegativeValues] = useState<any>(
		[]
	);

	const [dateLimits, setDateLimits] = useState<any>([]);
	const [lastRealDay, setLastRealDay] = useState<any>();
	const [line, setLine] = useState<any>([]);
	const [projectedLimit, setProjectedLimit] = useState<any>(0);

	const cardChartID = chartID + cardIndex;

	const isForecast = selectedChart === "forecast";
	const formatY = (a: number) => a.nearestThousand({});

	const fetchCurrentChart = useMemo(
		() => (selectedChart === "forecast" ? "sales" : selectedChart),
		[selectedChart]
	);

	const buildNegativeValues = useCallback(() => {
		const realNegative = dataArray.filter(
			(c: any) => c.real && c.real[fetchCurrentChart] < 0
		);

		const projectedNegative = dataArray.filter(
			(c: any) => c.projected && c.projected[fetchCurrentChart] < 0
		);

		setProjectedNegativeValues(projectedNegative);
		setRealNegativeValues(realNegative);
	}, [dataArray, selectedChart, fetchCurrentChart]);

	const fetchChartData = () => {
		const data = (chartFilter ? line.filter(chartFilter) : line).map(
			(c: any) => ({
				...c,
				projected: {
					...c.projected,
					position: c.projected.onHand + c.projected.onOrder,
					range: [c.projected.minForecast, c.projected.maxForecast],
				},
			})
		);

		if (selectedChart === "forecast")
			return data.map((d: any) => ({
				...d,
				real: !d.real.projected
					? {
							...d.real,
							position: d.projected.onHand + d.projected.onOrder,
					  }
					: null,
			}));

		return data;
	};

	const buildLimits = useCallback(() => {
		const days = dataArray.filter(
			(c: any) => DateTime.fromFormat(c.date, simulationPeriod).day === 1
		);
		setDateLimits(days);
	}, [dataArray, selectedChart, fetchCurrentChart]);

	useEffect(() => {
		setLoading(true);
		var filteredData = chartFilter
			? dataArray.filter(chartFilter)
			: dataArray;

		setMin(
			Math.min(
				...filteredData.map((c: any) =>
					Math.round(
						Math.min(
							(c.real && c.real[fetchCurrentChart]) || 0,
							c.projected[selectedChart]
						)
					)
				)
			)
		);
		setMax(
			Math.max(
				...filteredData.map((c: any) =>
					Math.round(
						Math.max(
							(c.real && c.real[fetchCurrentChart]) || 0,
							c.projected[selectedChart]
						)
					)
				)
			)
		);

		const indexOfProjected = filteredData.findIndex(
			(d: any) => d.real.projected
		);

		setLastRealDay(filteredData[indexOfProjected - 1]);

		if (indexOfProjected > 0)
			setProjectedLimit((indexOfProjected / filteredData.length) * 100);
		else if (indexOfProjected === -1) setProjectedLimit(100);
		else setProjectedLimit(0);

		buildNegativeValues();
		buildLimits();

		setLine(filteredData);
		setLoading(false);
	}, [
		selectedChart,
		buildNegativeValues,
		fetchCurrentChart,
		chartFilter,
		buildLimits,
		dataArray,
	]);
	if (loading) return <></>;

	return (
		<ResponsiveContainer height={238} id={cardChartID}>
			<AreaChart
				margin={{ top: 15, left: -15, right: 10, bottom: 0 }}
				data={fetchChartData()}
				key={min + max + cardChartID}
			>
				{realNegativeValues?.map((n: any) => (
					<ReferenceLine
						key={n.date + "realNegative"}
						stroke="#ff000099"
						segment={[
							{ x: n.date, y: max },
							{ x: n.date, y: max * 0.95 },
						]}
					/>
				))}
				{projectedNegativeValues?.map((n: any) => (
					<ReferenceLine
						key={n.date + "projectedNegative"}
						stroke="#0000ee99"
						segment={[
							{ x: n.date, y: max * 0.94 },
							{ x: n.date, y: max * 0.89 },
						]}
					/>
				))}

				{dateLimits?.map((n: any) => (
					<ReferenceLine
						key={n.date + "reference"}
						x={n.date}
						stroke="#dddddd77"
						strokeDasharray="3 1"
					/>
				))}
				{lastRealDay && (
					<>
						<ReferenceLine
							key={lastRealDay.date + "reference"}
							x={lastRealDay.date}
							stroke={theme.palette.primary.main}
							strokeWidth={2}
						>
							<Label
								position="top"
								fontWeight={700}
								fontSize={12}
								value={lastRealDay.date}
								fill={theme.palette.primary.main}
							/>
						</ReferenceLine>
						<ReferenceDot
							x={lastRealDay.date}
							r={5}
							y={max * 0.98}
							stroke="none"
							fill={theme.palette.primary.main}
						/>
					</>
				)}

				{realChart && realChart.show ? (
					<>
						<defs>
							<linearGradient
								id={`realLimit${chartID}${cardChartID}`}
								x1="0%"
								y1="0"
								x2="100%"
								y2="0"
							>
								<stop
									offset="0%"
									stopColor={`${realChart.color}88`}
								/>
								<stop
									offset={`${projectedLimit}%`}
									stopColor={`${realChart.color}88`}
								/>
								<stop
									offset={`${projectedLimit}%`}
									stopColor={`${realChart.color}33`}
								/>
								<stop
									offset={`${100}%`}
									stopColor={`${realChart.color}33`}
								/>
							</linearGradient>
						</defs>
						<Area
							type="monotone"
							dataKey={`real.${fetchCurrentChart}`}
							name="Real"
							stroke={realChart.color}
							strokeWidth={isForecast ? 2 : 1}
							fill={
								selectedChart !== "forecast"
									? `url(#realLimit${chartID}${cardChartID})`
									: "transparent"
							}
						/>
					</>
				) : null}

				{projectedChart && projectedChart.show ? (
					<>
						<Area
							type="monotone"
							dataKey={`projected.${selectedChart}`}
							name="Projected"
							color={projectedChart.color}
							stroke={projectedChart.color}
							fill={
								isForecast
									? "transparent"
									: `${projectedChart.color}33`
							}
							strokeDasharray="5 1"
						/>

						{isForecast ? (
							<Area
								type="monotone"
								dataKey="projected.range"
								fill="#2D9CDB77"
								stroke="transparent"
							/>
						) : null}
					</>
				) : null}

				<XAxis
					dataKey="date"
					type="category"
					interval={0}
					tickLine={false}
					tick={(props: any) => (
						<SimulationChartTooltipXAxis
							payload={props.payload}
							ignoreDateConstraint={ignoreDateConstraint}
							simulationPeriod={simulationPeriod}
							x={props.x}
							y={props.y}
						/>
					)}
				/>
				<YAxis
					id="yAxis"
					interval={0}
					type="number"
					tickLine={false}
					ticks={[0, max]}
					domain={["dataMin", "dataMax"]}
					style={{ fontSize: 10, color: "#828282" }}
					tickFormatter={formatY}
				/>
				<Tooltip
					content={(props: TooltipProps<ValueType, NameType>) => (
						<SimulationChartTooltip
							{...props}
							element={document.getElementById(chartID)}
							container={document.getElementById(cardChartID)}
							selectedChart={selectedChart}
							tooltipRows={tooltipRows}
						/>
					)}
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
};

export interface SimulateCardChartProps {
	dataArray: any[];
	chartID: any;
	chartFilter: any;
	selectedChart: IChartFilter;
	realChart: IChart<ISimulationPoint>;
	projectedChart: IChart<ISimulationPoint>;
	cardIndex: any;
	tooltipRows: IChartTooltipRow<ISimulationModel>[];
}
