/* eslint-disable import/no-anonymous-default-export */
import { Fade, FadeProps } from "@mui/material";
import { Box } from "@mui/system";

export default (props: SBFadeInterface) => {
	return (
		<Fade in={props.in} timeout={props.timeout}>
			<Box>{props.children}</Box>
		</Fade>
	);
};

export interface SBFadeInterface extends FadeProps {
	in: boolean | false;
	children: any;
	timeout?: number;
}
