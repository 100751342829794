import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";
import semilogo from "../../assets/semilogo.svg";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	mainContainer: {
		backgroundColor: "#f9f8fd",
		background: `url("${semilogo}") no-repeat`,
		backgroundPosition: "top right",
		overflowY: "unset",
		height: "100vh",
	},
}));
