import SBBox from "modules/Commons/SBLayouts/SBBox";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { useSystemTheme } from "themes/base";
import { formatInteger } from "utils/formatters";

export default (props: SBStrokedGauge) => {
    const { size, data, getTotal } = props;
    const theme = useSystemTheme();

    const sectors = Array(size).fill({ value: 1 })

    const maxOptimizedIndex = (data.value / getTotal()) * sectors.length

    return (
        <SBBox
            width={1}
            height={1}
            style={{
                position: "relative",
                margin: "auto",
            }}
        >
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        isAnimationActive={false}
                        startAngle={220}
                        endAngle={-40}
                        dataKey="value"
                        data={sectors}
                        cy={105}
                        innerRadius={70}
                        outerRadius={90}
                        paddingAngle={14}
                    >
                        {sectors.map((name, index) => (
                            <Cell key={`cell-${name}`} fill={index < maxOptimizedIndex ? theme.palette.primary.main : "#D9D9D9"} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <SBBox
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "33%",
                    fontWeight: "bold",
                    transform: "translate(-50%, 50%)",
                    textAlign: "center",
                    color: "#7F51FF",
                    fontSize: "31px",
                }}
            >
                <SBBox>{`${formatInteger((data.value / getTotal()) * 100)}%`}</SBBox>
            </SBBox>
        </SBBox>
    );
};

interface SBStrokedGauge {
    size: number;
    data: {
        color: string;
        label: string;
        value: number;
        formattedValue: string;
    };
    getTotal: () => number;
}
