import { SBBox, SBIcon } from "modules/Commons";
import { SBIconProps } from "../../SBIcon";

export default ({ icon, fillColor, color, bgColor }: SBSquaredIconBoxProps) => {
	return (
		<SBBox
			data-testid="sb-squared-icon-box"
			height="3rem"
			width="3rem"
			minWidth="3rem"
			borderRadius="5px"
			bgcolor={bgColor}
			display="flex"
			justifyContent="center"
			alignItems="center"
			sx={{
				transition: "500ms",
			}}
		>
			<SBIcon
				icon={icon}
				fillColor={fillColor}
				color={color}
				size="1.5rem"
			/>
		</SBBox>
	);
};
export interface SBSquaredIconBoxProps extends SBIconProps {
	/** Color of the Squared Icon box */
	bgColor: string;
}
