import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { SBBox, SBCard, SBTooltip, SBTypography } from "modules/Commons";
import SBDonutChart from "modules/Commons/SBDataDisplays/SBDonutChart";
import SBWithIndicator from "modules/Commons/SBValueWithIndicator";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSystemTheme } from "themes/base";
import { formatCurrency, formatCurrencyShort, formatInteger } from "utils/formatters";
import { SBTranslate } from "i18n";
import NoDataCostImage from "assets/noDataCost.svg";

export default ({ statistics }: SBTotalCostCardProps) => {
	const { t } = useTranslation();
	const { getLanguage } = useSystemLanguage();
	const theme = useSystemTheme();

	const getTotal = () =>
		statistics?.policyCost?.costPerStockoutEvent.value +
		statistics?.policyCost?.rupture.value +
		statistics?.policyCost?.costPerTransaction.value +
		statistics?.policyCost?.holding.value ?? 0;

	const hasAnyValue = !!statistics?.policyCost?.costPerStockoutEvent.value ||
		!!statistics?.policyCost?.rupture.value ||
		!!statistics?.policyCost?.costPerTransaction.value ||
		!!statistics?.policyCost?.holding.value;

	const costPerStockout = useMemo(
		() => ({
			color: theme.palette.error.main,
			label: SBTranslate("stockout"),
			value: statistics?.policyCost.costPerStockoutEvent.value,
			formattedValue: `(${formatInteger(
				(statistics?.policyCost.costPerStockoutEvent.value / getTotal()) * 100
			)}%)`,
		}),
		[statistics, getLanguage()]
	);

	const rupture = useMemo(
		() => ({
			color: theme.palette.excess.main,
			label: SBTranslate("rupture"),
			value: statistics?.policyCost.rupture.value,
			formattedValue: `(${formatInteger(
				(statistics?.policyCost.rupture.value / getTotal()) * 100
			)}%)`,
		}),
		[statistics, getLanguage()]
	);

	const costPerTransaction = useMemo(
		() => ({
			color: theme.palette.primary.main,
			label: SBTranslate("transaction"),
			value: statistics?.policyCost.costPerTransaction.value,
			formattedValue: `(${formatInteger(
				(statistics?.policyCost.costPerTransaction.value / getTotal()) * 100
			)}%)`,
		}),
		[statistics, getLanguage()]
	);

	const holding = useMemo(
		() => ({
			color: theme.palette.blue3.main,
			label: SBTranslate("holding"),
			value: statistics?.policyCost.holding.value,
			formattedValue: `(${formatInteger(
				(statistics?.policyCost.holding.value / getTotal()) * 100
			)}%)`,
		}),
		[statistics, getLanguage()]
	);

	const total = useMemo(
		() => ({
			color: theme.palette.gray2.main,
			label: t("total"),
			value: formatCurrencyShort(statistics?.policyCost.costPerStockoutEvent.value +
				statistics?.policyCost.rupture.value +
				statistics?.policyCost.costPerTransaction.value +
				statistics?.policyCost.holding.value),
		}),
		[statistics, getLanguage()]
	);

	return (
		<SBCard style={{ height: "100%" }}>
			<SBBox p={4} height={1}>
				<SBTooltip placement="top-start" title={SBTranslate("tips.cost")}>
					<SBTypography
						fontWeight="700"
						color={theme.palette.gray2.main}
						fontSize={18}
					>
						{SBTranslate("cost")}
					</SBTypography>
				</SBTooltip>
				{hasAnyValue ?
					<SBBox display="flex" alignItems="center" justifyContent="space-between" flexDirection="row-reverse" height={0.9} mt={1}>
						<SBBox
							width="182px"
							height={1}
							mx={2}
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<SBDonutChart
								items={[costPerStockout, rupture, costPerTransaction, holding]}
								label={<div>{SBTranslate("totalCost")}</div>}
								total={total}
								innerRadius={75}
								outerRadius={90}
								width={182}
								height={182}
								fontSize="1rem"
							/>
						</SBBox>
						<SBBox
							width={1}
							height="190px"
							display="flex"
							flexDirection="column"
							justifyContent="space-between"
						>
							<SBWithIndicator
								value={costPerStockout}
								formatter={formatCurrencyShort}
							/>
							<SBWithIndicator
								value={rupture}
								formatter={formatCurrencyShort}
							/>
							<SBWithIndicator
								value={costPerTransaction}
								formatter={formatCurrencyShort}
							/>
							<SBWithIndicator
								value={holding}
								formatter={formatCurrencyShort}
							/>
						</SBBox>
					</SBBox>
					:
					<SBBox display="flex"
						justifyContent="center"
						width="100%"
					>
						<img src={NoDataCostImage} alt="No data" />
					</SBBox>
				}

			</SBBox>
		</SBCard>
	);
};

interface SBTotalCostCardProps {
	statistics: IHomeStatistics;
}
