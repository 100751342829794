import TableContainer from "@mui/material/TableContainer";
import { useState, createContext } from "react";
import SBTableToolbar from "../SBTableToolbar";
import SBTableSummarization from "../SBTableSummarization";
import SBTablePagination from "../SBTablePagination";
import { useStyles } from "./style";
import SBDataTableContent from "./SBDataTableContent";
import { SBBox } from "modules/Commons";
import { forwardRef, useImperativeHandle } from "react";
import { DateTime } from "luxon";
import { debounce } from "lodash";
import { useCSV } from "hooks/useCSV";
import clsx from "clsx";
import { SBTableColumn } from "../models/TableColumn";

export const DataTableContext = createContext({});

export default forwardRef(
	(
		{
			showCount = false,
			collapseFieldName = null,
			clickLine = null,
			rows,
			columns,
			summarization,
			editActions = () => false,
			title = "",
			showGroups,
			showHeader = true,
			noEmptyRows = false,
			pagination = false,
			unfolding = false,
			flattenedSearch = false,
			unfoldingComponent = null,
			selectable = false,
			actionable = false,
			striped = true,
			formTableIndex = false,
			getID = (v: any) => v.id,
			toolbarContent,
			fixedRowsPerPage = false,
			onChangeAny,
			deepSearch,
			filters,
			toolbarAfterContent,
			onActive,
			parentClickable = false,
			fullWidth = false,
		}: SBDataTableProps,
		ref
	) => {
		const classes = useStyles();
		const csvWorker = useCSV();
		const [list, setList] = useState<any>(rows);
		const [page, setPage] = useState<any>(0);
		const [search, setSearch] = useState<any>("");
		const [searching] = useState<any>(false);
		const [rowsPerPage, setRowsPerPage] = useState<any>(
			fixedRowsPerPage || 10
		);
		const [selected, setSelected] = useState<any>([]);
		const [modified, setModified] = useState<any>([]);

		const filter = (v: any) => {
			return search
				? v.hasAny(search, {
						deepSearch:
							(unfolding && !flattenedSearch) || deepSearch,
				  })
				: v;
		};

		const filteredValue = () => {
			const value = flattenedSearch && search ? list.flatten() : list;
			let filteredValue = value.filter(filter);

			if (filters)
				filteredValue = filters.flatMap((c: any) => value.filter(c));

			if (collapseFieldName) {
				filteredValue = filteredValue.map((f: any) => ({
					...f,
					[collapseFieldName]: f[collapseFieldName].filter(filter),
				}));
			}
			return filteredValue;
		};

		const handleClick = (_: any, id: any) => {
			if (!selectable) return;

			var clickedItem = list.find((c: any) => getID(c) === id);

			if (!parentClickable && collapseFieldName && clickedItem) {
				let itemsToToggle = clickedItem[collapseFieldName].map(
					(c: any) => getID(c)
				);

				const needToToggleOff = selected.some(
					(c: any) => itemsToToggle.indexOf(c) >= 0
				);

				if (needToToggleOff)
					setSelected((items: any) => {
						return items.reduce((a: any, b: any) => {
							if (itemsToToggle.indexOf(b) >= 0) return [...a];
							return [...a, b];
						}, []);
					});
				else
					setSelected((items: any) => {
						const v = [...items, ...itemsToToggle].distinct();
						return v;
					});
			} else {
				setSelected((items: any) => items.toggleItem(id));
			}
		};

		const handleSearch = debounce((text) => {
			setSearch(text);
		}, 1000);

		const handleChangePage = (_: any, newPage: any) => {
			setPage(newPage - 1);
		};

		const handleChangeRowsPerPage = (event: any) => {
			setRowsPerPage(parseInt(event.target.value, 10));
			setPage(0);
		};

		const handleSelectAllClick = (event: any) => {
			if (event.target.checked) {
				const values = collapseFieldName
					? filteredValue().flatMap((f: any) => f[collapseFieldName])
					: filteredValue();

				return setSelected(values.map((n: any) => getID(n)));
			}

			setSelected([]);
		};

		const applyMultipleEdit = (c: any) => {
			const newList = list.triggerChange({
				condition: (item: any) => selected.indexOf(getID(item)) >= 0,
				newValue: (item: any) => ({
					amount: Math.round(c.action(+item.amount)),
				}),
				beforeFind: (item: any) =>
					setModified((c: any) => [...c, getID(item)]),
			});
			setList(newList);
		};

		const handleEditableChange = (
			value: any,
			row: any,
			column: any,
			setter: any
		) => {
			const newList = list.triggerChange({
				condition: (item: any) => getID(row) === getID(item),
				newValue: (item: any) => {
					var newRow = { ...item };

					if (!!setter) setter(newRow, value);
					else newRow[column] = +value;

					return newRow;
				},
				beforeFind: (item: any) =>
					setModified((c: any) => [...c, getID(item)]),
				children: collapseFieldName,
			});
			setList(newList);

			if (onChangeAny) onChangeAny(formTableIndex, newList);
		};

		const filteredAndSlicedItems = () =>
			filteredValue().slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			);

		const exportCSV = (items: any[]) => {
			const separator = navigator.language === "en-US" ? "," : ";";

			let csvHeader = showGroups
				? columns.flatMap((c: any) => c.headers)
				: columns;

			csvHeader = csvHeader.filter((header: any) => !header.csvHide);

			const csvChildHeaders = csvHeader.reduce(
				(a: [], b: SBTableColumn<any>) => {
					if (b.child) return [...a, b.child];
					return [...a, b];
				},
				[]
			);

			const csvBody = items
				.map((item: any) => {
					const firstHeader = csvHeader
						.map((header: any) => {
							const value =
								typeof header.id === "function"
									? header.id(item)
									: item[header.id];

							return !!value
								? `"${value.toLocaleString(
										navigator.language
								  )}"`
								: "";
						})
						.join(separator);

					const childs = collapseFieldName
						? item[collapseFieldName]
								.filter(
									(item: any) =>
										selected.length == 0 ||
										selected.indexOf(item.uuid) >= 0
								)
								.map((childItem: any) => {
									return csvChildHeaders
										.map((header: any) => {
											const value =
												typeof header.id === "function"
													? header.id(childItem)
													: childItem[header.id];

											return !!value
												? `"${value.toLocaleString(
														navigator.language
												  )}"`
												: "";
										})
										.join(separator);
								})
						: [];

					const linesToReturn = [];

					if (
						selected.length > 0 &&
						selected.indexOf(item.uuid) >= 0
					) {
						linesToReturn.push(firstHeader);
					} else if (!collapseFieldName && selected.length == 0) {
						linesToReturn.push(firstHeader);
					}

					return [...linesToReturn, ...childs];
				})
				.flatMap((c) => c)
				.join("\n");

			const csv =
				csvHeader.map((c: any) => c.csvName || c.name).join(separator) +
				"\n" +
				csvBody;
			return csv;
		};

		useImperativeHandle(ref, () => ({
			exportCSV: exportCSV,
			downloadCSV: () => {
				const csv = exportCSV(list);
				const fileName = `${title}_${DateTime.now().toISODate()}`;
				csvWorker.downloadCSV({ csv, title: fileName });
			},
			selecteds: () => selected,
		}));

		return (
			<SBBox className={clsx(classes.sbDataTable, onActive && "active")}>
				{showHeader ? (
					<SBTableToolbar
						title={title}
						showCount={showCount}
						count={rows.length}
						selectedCount={selected.length}
						selectedItems={selected}
						editActions={editActions}
						onApply={applyMultipleEdit}
						onSearch={handleSearch}
						searching={searching}
						toolbarContent={toolbarContent}
						toolbarAfterContent={toolbarAfterContent}
					/>
				) : null}
				{summarization ? (
					<SBTableSummarization
						selected={selected}
						list={list}
						getID={getID}
						summarization={summarization}
					/>
				) : null}
				<TableContainer>
					<SBDataTableContent
						fullWidth={fullWidth}
						collapseFieldName={collapseFieldName}
						formTableIndex={formTableIndex}
						rows={rows}
						rowsToShow={filteredAndSlicedItems()}
						columns={columns}
						selectable={selectable}
						selected={selected}
						modified={modified}
						actionable={actionable}
						handleSelectAllClick={handleSelectAllClick}
						unfolding={unfolding}
						unfoldingComponent={unfoldingComponent}
						page={page}
						rowsPerPage={rowsPerPage}
						showGroups={showGroups}
						handleClick={clickLine || handleClick}
						handleCheckboxClick={handleClick}
						handleEditableChange={handleEditableChange}
						striped={striped}
						getID={getID}
						noEmptyRows={noEmptyRows}
					/>
				</TableContainer>
				{pagination ? (
					<SBTablePagination
						rows={filteredValue()}
						currentPage={page}
						rowsPerPage={rowsPerPage}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						fixedRowsPerPage={fixedRowsPerPage}
					/>
				) : null}
			</SBBox>
		);
	}
);

export interface SBDataTableProps {
	showCount?: boolean;
	fullWidth?: boolean;
	collapseFieldName?: string | null;
	clickLine?: any;
	rows?: any;
	columns?: any;
	summarization?: any;
	editActions?: any;
	title?: any;
	showGroups?: any;
	showHeader?: any;
	noEmptyRows?: any;
	pagination?: any;
	unfolding?: any;
	flattenedSearch?: any;
	unfoldingComponent?: any;
	selectable?: any;
	actionable?: any;
	striped?: any;
	formTableIndex?: any;
	getID?: any;
	toolbarContent?: any;
	fixedRowsPerPage?: any;
	onChangeAny?: any;
	deepSearch?: any;
	filters?: any;
	toolbarAfterContent?: any;
	onActive?: boolean;
	parentClickable?: boolean;
}
