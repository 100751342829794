import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	searchInput: {
		borderRadius: 8,
		backgroundColor: "#F9F8FD",
		"& label.Mui-focused": {
			color: "transparent",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "transparent",
		},
		"& .MuiOutlinedInput-root": {
			"& input ": {
				color: "#7F51FF",
				fontWeight: "600",
				opacity: 1,
			},
			"& fieldset": {
				borderColor: "transparent",
			},
			"&:hover fieldset": {
				borderColor: "transparent",
			},
			"&.Mui-focused fieldset": {
				borderColor: "transparent",
			},
		},
	},
	container: {
		border: "1px solid #E2DDF2",
		position: "absolute",
		zIndex: 10000,
		width: "100%",
		top: -1,
		borderBottomRightRadius: 4,
		borderBottomLeftRadius: 4,
		left: 0,
		backgroundColor: theme.palette.white.main,
	},
}));
