import _ from "lodash";
import * as yup from "yup";
import { Maybe } from "yup/lib/types";
import i18n from "i18next";
import ConfigFilter from "views/ConfigsView/ConfigSideMenu/ConfigFilter";

const isValid = (value: string | number) =>
	value === undefined || value === null || value === 0;

const isString = (value: string | number) => typeof value === "string";
function between(
	this: any,
	min: { prop: string; label: string },
	max: { prop: string; label: string },
	msg = null
) {
	return this.test({
		message: msg || i18n.t("isBetween", { min: min.label, max: max.label }),
		name: "between",
		exclusive: true,
		params: { min, max },
		test: function (value: Maybe<number>) {
			const minValue = this.parent[min.prop];
			const maxValue = this.parent[max.prop];
			const minIsValid = isValid(minValue) || (value && minValue < value);
			const maxIsValid = isValid(maxValue) || (value && maxValue > value);
			return value === null || (minIsValid && maxIsValid);
		},
	});
}

function moreThanIfNotNull(
	this: any,
	min: { prop: string | number; label: string; num: number },
	msg = null
): any {
	return this.test({
		message: msg || i18n.t("isMoreThanIfNotNull", { min: min.label }),
		name: "moreThanIfNotNull",
		params: { min },
		test: function (value: Maybe<number>) {
			const minValue = isString(min.prop)
				? _.get(this.from[1]?.value, min.prop) ?? this.parent[min.prop]
				: min.prop;

			const minIsValid = isValid(minValue) || (value && minValue < value);
			return value === null || minIsValid;
		},
	});
}

function moreThanOrEqualIfNotNull(
	this: any,
	min: {
		prop: string | number;
		label: string;
	},
	msg = null
): any {
	return this.test({
		message: msg || i18n.t("isMoreOrEqualIfNotNull", { min: min.label }),
		name: "moreOrEqualIfNotNull",
		params: { min },
		test: function (value: Maybe<number>) {
			const minValue = isString(min.prop)
				? this.parent[min.prop]
				: min.prop;

			const minIsValid =
				isValid(minValue) || (value && minValue <= value);
			return value === null || minIsValid;
		},
	});
}
function lessThanIfNotNull(
	this: any,
	max: { prop: string | number; label: string },
	msg = null
) {
	return this.test({
		message: msg || i18n.t("isLessThanIfNotNull", { max: max.label }),
		name: "lessThanIfNotNull",
		params: { max },
		test: function (value: Maybe<number>) {
			const maxValue = isString(max.prop)
				? this.parent[max.prop]
				: max.prop;

			const maxIsValid = isValid(maxValue) || (value && maxValue > value);
			return value === null || maxIsValid;
		},
	});
}

function lessThanOrEqualIfNotNull(
	this: any,
	max: { prop: string | number; label: string },
	msg = null
) {
	return this.test({
		message:
			msg || i18n.t("isLessOrEqualThanIfNotNull", { max: max.label }),
		name: "lessOrEqualThanIfNotNull",
		params: { max },
		test: function (value: Maybe<number>) {
			const maxValue = isString(max.prop)
				? this.parent[max.prop]
				: max.prop;

			const maxIsValid =
				isValid(maxValue) || (value && maxValue >= value);
			return value === null || maxIsValid;
		},
	});
}

function emptyStringAsNull(this: any) {
	return this.transform(function (value: string, originalValue: string) {
		return originalValue === "" ? null : value;
	});
}

function isMultipleOf(
	this: any,
	config: { prop: string | number; label: string },
	msg = null
) {
	return this.test({
		message: i18n.t("multipleOf", { config: config.label }),
		name: "isMultipleOf",
		test: function (value: Maybe<number>) {
			const maxValue = isString(config.prop)
				? this.parent[config.prop]
				: config.prop;

			const maxIsValid =
				isValid(maxValue) || (value && value % maxValue === 0);

			return value === null || maxIsValid;
		},
	});
}

yup.addMethod(yup.number, "between", between);
yup.addMethod(yup.number, "moreThanIfNotNull", moreThanIfNotNull);
yup.addMethod(yup.number, "lessThanIfNotNull", lessThanIfNotNull);
yup.addMethod(yup.number, "moreThanOrEqualIfNotNull", moreThanOrEqualIfNotNull);
yup.addMethod(yup.number, "lessThanOrEqualIfNotNull", lessThanOrEqualIfNotNull);
yup.addMethod(yup.number, "emptyStringAsNull", emptyStringAsNull);
yup.addMethod(yup.number, "isMultipleOf", isMultipleOf);

export const configureYupLocale = () => {
	yup.setLocale({ mixed: { required: i18n.t("requiredName"), default: "" } });
};
declare module "yup" {
	interface NumberSchema {
		between: (
			min: { prop: string; label: string },
			max: { prop: string; label: string },
			msg?: string
		) => NumberSchema;
		emptyStringAsNull: () => NumberSchema;
		isMultipleOf: (
			value: {
				prop: string | number;
				label: string;
			},
			msg?: string
		) => NumberSchema;
		lessThanIfNotNull: (
			max: {
				prop: string | number;
				label: string;
			},
			msg?: string
		) => NumberSchema;
		moreThanIfNotNull: (
			min: {
				prop: string | number;
				label: string;
			},
			msg?: string
		) => NumberSchema;
		lessThanOrEqualIfNotNull: (
			max: {
				prop: string | number;
				label: string;
			},
			msg?: string
		) => NumberSchema;
		moreThanOrEqualIfNotNull: (
			min: {
				prop: string | number;
				label: string;
			},
			msg?: string
		) => NumberSchema;
	}
}
