import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import { SBTranslate } from "i18n";
import { useMemo } from "react";
import { formatDecimal, minmax, percentageFormatter } from "utils/formatters";

export default ({ statistics, isMonthly }: useForecastStatsProps) => {
	const stats = useMemo(() => {
		const evaluation = isMonthly
			? statistics?.evaluation?.monthlyEvaluation
			: statistics?.evaluation?.dailyEvaluation;

		const avgDemand = isMonthly
			? statistics?.evaluation?.montlhyAvgDemand
			: statistics?.evaluation?.avgDemand;

		const stdDev = isMonthly
			? statistics?.evaluation?.monthlyDemandStdDev
			: statistics?.evaluation?.demandStdDev;

		return [
			{
				tooltip: SBTranslate("tips.demand"),
				label: `${SBTranslate("demandAverage")}`,
				value: avgDemand?.value,
				range: `± ${formatDecimal(stdDev)} ${SBTranslate("deviation")}`,
				formatter: formatDecimal,
			},
			{
				tooltip: SBTranslate("tips.rmse"),
				label: `RMSE - ${SBTranslate("deviation")}`,
				value: evaluation?.rmse,
				range: minmax(
					formatDecimal(
						isMonthly
							? statistics?.evaluation?.monthlyEvaluationMin?.rmse
							: statistics?.evaluation?.dailyEvaluationMin?.rmse
					),
					formatDecimal(
						isMonthly
							? statistics?.evaluation?.monthlyEvaluationMax?.rmse
							: statistics?.evaluation?.dailyEvaluationMax?.rmse
					)
				).toUpperCase(),
				formatter: formatDecimal,
			},
			{
				tooltip: SBTranslate("tips.r2"),
				label: `r - ${SBTranslate("explainability")}`,
				value: evaluation?.r2,
				range: minmax(
					percentageFormatter(
						isMonthly
							? statistics?.evaluation?.monthlyEvaluationMin?.r2
							: statistics?.evaluation?.dailyEvaluationMin?.r2
					),
					percentageFormatter(
						isMonthly
							? statistics?.evaluation?.monthlyEvaluationMax?.r2
							: statistics?.evaluation?.dailyEvaluationMax?.r2
					)
				).toUpperCase(),
				formatter: percentageFormatter,
			},
			{
				tooltip: SBTranslate("tips.mpe"),
				label: `PE - ${SBTranslate("bias")}`,
				value: evaluation?.mpe,
				range: minmax(
					percentageFormatter(
						isMonthly
							? statistics?.evaluation?.monthlyEvaluationMin?.mpe
							: statistics?.evaluation?.dailyEvaluationMin?.mpe
					),
					percentageFormatter(
						isMonthly
							? statistics?.evaluation?.monthlyEvaluationMax?.mpe
							: statistics?.evaluation?.dailyEvaluationMax?.mpe
					)
				).toUpperCase(),
				formatter: percentageFormatter,
			},
			{
				tooltip: SBTranslate("tips.mape"),
				label: `APE - ${SBTranslate("error")}`,
				value: evaluation?.mape,
				range: minmax(
					percentageFormatter(
						isMonthly
							? statistics?.evaluation?.monthlyEvaluationMin?.mape
							: statistics?.evaluation?.dailyEvaluationMin?.mape
					),
					percentageFormatter(
						isMonthly
							? statistics?.evaluation?.monthlyEvaluationMax?.mape
							: statistics?.evaluation?.dailyEvaluationMax?.mape
					)
				).toUpperCase(),
				formatter: percentageFormatter,
			},
			{
				tooltip: SBTranslate("tips.cv"),
				label: `CV - ${SBTranslate("volatility")}`,
				value: evaluation?.cv,
				range: minmax(
					formatDecimal(
						isMonthly
							? statistics?.evaluation?.monthlyEvaluationMin?.cv
							: statistics?.evaluation?.dailyEvaluationMin?.cv
					),
					formatDecimal(
						isMonthly
							? statistics?.evaluation?.monthlyEvaluationMax?.cv
							: statistics?.evaluation?.dailyEvaluationMax?.cv
					)
				).toUpperCase(),
				formatter: formatDecimal,
			},
		];
	}, [statistics, isMonthly]);
	return { stats };
};

interface useForecastStatsProps {
	isMonthly: boolean;
	statistics: IHomeStatistics;
}
