import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	sbExportButton: {
		"&.disabled": {
			borderColor: `${theme.palette.gray4.main} !important`,
		},

		"&.active": {
			borderBottom: "1px solid transparent !important",
			borderRadius: "4px !important",
			borderBottomLeftRadius: "0 !important",
			borderBottomRightRadius: "0 !important",
			transition: " !important",
			transitionDelay: "0s !important",
		},
	},
	sbExportButtonContainer: {
		border: "1px solid",
		background: `${theme.palette.gray7.main} !important`,
		borderColor: "transparent",
		padding: 0,
		zIndex: 100,
		// paddingLeft: "12px !important",
		fontWeight: "bold !important",
		position: "absolute",
		borderTop: "none",
		borderBottomLeftRadius: "4px",
		borderBottomRightRadius: "4px",
		overflow: "hidden",
		transition: "height 0.2s, border-color .2s",
		"&.active": {
			borderColor: "#7F51FF80",
		},
		"&:hover": {
			borderColor: theme.palette.primary.main,
		},
	},
	sbExportButtonIcon: {
		border: `1px solid`,
		borderRadius: "50%",
		padding: "0px 1px",
		transition: "transform 0.2s ease",
		backgroundColor: theme.palette.gray6.main,

		"&.active": {
			border: `1px solid`,
			transform: "rotate(180deg)",
			borderColor: theme.palette.primary.main,
		},
	},
}));
