import { SBBox, SBTooltip, SBTypography } from "modules/Commons";
import { ReactNode } from "react";

export default ({
	title,
	tip,
	valueTip,
	value,
	fontColor,
	children,
	append,
}: SBSquaredIconTitleProps) => {
	return title || value ? (
		<SBBox ml={1.5} width={1}>
			<SBTooltip title={tip ?? ""}>
				<SBTypography>
					{title}
				</SBTypography>
			</SBTooltip>
			<SBTooltip title={valueTip ?? ""}>
				<SBTypography width={1}
					noWrap
					fontWeight="bold"
					color={fontColor}
					variant="h6"
					sx={{ transition: "500ms" }}
				>
					<span>{append}</span>
					<span>{value}</span>
					{children}
				</SBTypography>
			</SBTooltip>
		</SBBox>
	) : null;
};

export interface SBSquaredIconTitleProps {
	/** Title of the squarebox indicator */
	title: string | JSX.Element;
	/** Tip of the squarebox indicator */
	tip?: string;
	/** Value Tip of the squarebox indicator */
	valueTip?: string;
	/** Value of the squarebox indicator */
	value: string | number;
	/** Color of the font of the value and childrens  */
	fontColor: string;
	/** Children that will be on the side of the value */
	children: ReactNode[] | JSX.Element | null | false;
	/** Prepend on title */
	append?: ReactNode;
}
