import { TableCellType } from "./tableCellType";
import SBStringTableCell from "./SBStringTableCell";
import SBChipTableCell from "./SBChipTableCell";
import SBCheckboxTableCell from "./SBCheckboxTableCell";
import SBNumberTableCell from "./SBNumberTableCell";
import SBEditableTableCell from "./SBEditableTableCell";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import SBTableCell from "./SBTableCell";
import { SBTableColumn } from "../models/TableColumn";
import _ from "lodash";

export default ({
	index,
	row,
	oneDimensionColumns,
	handleEditableChange,
	selectable,
	actionable,
	isSelected,
	isModified,
	formTableIndex = 0,
	onClick,
	onChecked,
	isIndeterminate,
	getID = (c: any) => c.id,
	isChild,
}: SBTableCellsProps) => {
	const isItemSelected = selectable ? isSelected(getID(row)) : false;
	const isItemModified = isModified ? isModified(getID(row)) : false;

	const onEdit = (event: any, columnId: any, setter: any) => {
		handleEditableChange(event, row, columnId, setter);
	};

	return (
		<>
			{selectable ? (
				<SBCheckboxTableCell
					indeterminate={isIndeterminate}
					style={{
						borderBottom: "none",
						borderLeft: isItemModified ? "1px solid #7F51FF" : "",
						pointerEvents: "all",
						width: 80,
					}}
					onClick={onChecked}
					checked={isItemSelected}
				/>
			) : null}

			{oneDimensionColumns.map((column) => {
				const handleClick = column.disableClick ? undefined : onClick;
				const columnConfig =
					isChild && column.child ? column.child : column;

				const itemIsEditable = columnConfig?.editable || false;

				const itemType = columnConfig?.type || null;
				const itemId = columnConfig?.id;
				const itemName = columnConfig?.name;
				const itemKey = columnConfig?.key ?? itemName;

				const label =
					typeof itemId === "function"
						? itemId(row)
						: _.get(row, itemId) ?? null;

				const color =
					(columnConfig?.color && columnConfig?.color(label)) ?? "";
				const value =
					(columnConfig?.formatter &&
						columnConfig?.formatter(label as never)) ??
					label;
				const columnStyle = columnConfig?.style ?? {};
				const align = columnConfig?.align ?? undefined;
				const noWrap = columnConfig?.noWrap ?? undefined;
				const maxWidth = columnConfig?.maxWidth ?? undefined;
				const setter = columnConfig?.setter ?? undefined;

				const rowStyle = row?.style ?? {};
				const Component = ChooseCell(itemIsEditable, itemType);
				return (
					<Component
						key={itemKey}
						name={`${formTableIndex}.${
							row.accessor || index
						}.${itemKey}`}
						padding="normal"
						label={label}
						value={value}
						color={color}
						type={itemType}
						align={align}
						noWrap={noWrap}
						maxWidth={maxWidth}
						onChange={(event: any) => onEdit(event, itemId, setter)}
						onClick={handleClick}
						style={{
							borderBottom: "none",
							minWidth: 80,
							...columnStyle,
							...rowStyle,
						}}
					/>
				);
			})}
			{actionable ? (
				<SBTableCell style={{ borderBottom: "none" }}>
					<MoreHoriz color={"gray" as any} />
				</SBTableCell>
			) : null}
		</>
	);
};

const ChooseCell = (isEditable: boolean, type: string | null): any => {
	if (isEditable) return SBEditableTableCell;
	switch (type) {
		case TableCellType.Number:
			return SBNumberTableCell;
		case TableCellType.Checkbox:
			return SBCheckboxTableCell;
		case TableCellType.Badge:
			return SBChipTableCell;
		default:
			return SBStringTableCell;
	}
};

export interface SBTableCellsProps {
	row: any;
	index: number;
	oneDimensionColumns: SBTableColumn<any>[];
	selectable: boolean;
	actionable: boolean;
	formTableIndex: number;
	isSelected: (v: any) => boolean;
	isModified: (v: any) => boolean;
	handleEditableChange: (
		event: MouseEvent,
		row: any,
		columnId: number,
		setter: (c: any) => void
	) => void;
	onClick: (v?: any) => any;
	onChecked: (v?: any) => any;
	getID: (v: any) => any;
	isChild?: boolean;
	isIndeterminate?: boolean;
}
