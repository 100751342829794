import { List, ListItem } from "@mui/material";
import { SBTranslate } from "i18n";
import { SBBox, SBIcon, SBTypography } from "modules/Commons";
import { useStyles } from "./style";

export default () => {
	const classes = useStyles();

	const tips = [
		SBTranslate("configUploadTips.separator"),
		SBTranslate("configUploadTips.actions"),
		SBTranslate("configUploadTips.id"),
		SBTranslate("configUploadTips.booleanFields"),
		SBTranslate("configUploadTips.mustBeFilled"),
		SBTranslate("configUploadTips.productStoreCategory"),
		SBTranslate("configUploadTips.sorcing"),
		SBTranslate("configUploadTips.sorcingClientID"),
		SBTranslate("configUploadTips.transference"),
		SBTranslate("configUploadTips.recommendation"),
		SBTranslate("configUploadTips.configsValidations"),
		SBTranslate("configUploadTips.name"),
		SBTranslate("configUploadTips.percentageFields"),
		SBTranslate("configUploadTips.reviewPeriodNumeric"),
	]

	return (
		<SBBox className={classes.configUploaderTipsContainer}>
			<SBBox display="flex" mb={2}>
				<SBIcon icon="help-circle" color="primary" />
				<SBTypography
					fontWeight="700"
					ml={1}
					color="primary.main"
				>
					{SBTranslate("requirements")}
				</SBTypography>
			</SBBox>
			<SBBox className={classes.configUploaderTips}>
				<List>
					{tips.map(t => <ListItem>
						<SBTypography
							color="gray3.main"
						>
							{t}
						</SBTypography>
					</ListItem>)}
				</List>
			</SBBox>
		</SBBox>
	);
};
