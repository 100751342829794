import purchaseDatePlaceholder from "assets/purchaseDatePlaceholder.svg";
import { SBBox, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";

export const PurchaseDatePlaceholder = () => {
	const { t } = useTranslation();
	return (
		<SBBox
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height={0.8}
			mt="5%"
		>
			<img height={200} src={purchaseDatePlaceholder} alt="" />
			<SBTypography
				mt={2}
				py={2}
				variant="h5"
				color="darkPurple.main"
				fontWeight="700"
			>
				{t("purchaseDatePlaceholderTitle")}
			</SBTypography>

			<SBTypography textAlign="center" fontWeight="400" width={0.3}>
				{t("purchaseDatePlaceholderSubTitle")}
			</SBTypography>
		</SBBox>
	);
};
