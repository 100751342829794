import { Divider, Stack, Toolbar } from "@mui/material";
import clsx from "clsx";
import { SBGrid } from "modules/Commons";
import SBIcon from "modules/Commons/SBDataDisplays/SBIcon";
import SBTypography from "modules/Commons/SBDataDisplays/SBTypography";
import SBIconButton from "modules/Commons/SBForms/SBIconButton";
import SBBox from "modules/Commons/SBLayouts/SBBox";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SBIndeterminateCheckbox from "../SBIndeterminateCheckbox";
import { useStyles } from "./style";
import SBExpandableField from "modules/Commons/SBDataDisplays/SBExpandableField";
import { Column, ColumnDef } from "@tanstack/react-table";
import { IEntity } from "domain/Domain/Entities/IEntity";
import _, { some } from "lodash";
import { SBTranslate } from "i18n";

export default (props: SBTableToolbarProps) => {
	const {
		title,
		rowCount,
		selectedCount,
		columns,
		toolbarContent,
		handleSearch = () => true,
		handleExternalSearch = () => true,
	} = props;
	const { t } = useTranslation();
	const [showColumnsConfig, setShowColumnsConfig] = useState(false);
	const classes = useStyles();

	const onSearch = _.debounce(handleSearch, 1250);

	const Base = useMemo(() => {
		return (
			<>
				<SBBox borderRight="1px solid #E0E0E0" py={3} px={3} mr={2}>
					<SBTypography fontWeight="bold">{title}</SBTypography>
				</SBBox>

				<SBBox display="flex">
					<SBTypography>
						{`${rowCount} ${t("item", { count: rowCount })}`}
					</SBTypography>
					{selectedCount > 0 && (
						<>
							<SBTypography mx={1}>
								{rowCount && selectedCount > 0 && "|"}
							</SBTypography>
							<SBTypography mr={2}>
								{`${selectedCount} ${t("selectedItem", {
									count: selectedCount,
								})}`}
							</SBTypography>
						</>
					)}
				</SBBox>

				<Stack mr={3} direction="row" spacing={3}>
					<SBBox px={2} display="flex">
						{toolbarContent?.({
							handleSearch: handleExternalSearch,
						})}
					</SBBox>
				</Stack>
				<Stack ml="auto" mr={3}>
					<SBBox ml="auto" display="flex">
						<SBExpandableField
							icon="search"
							placeholder={`${t("search")}...`}
							onChange={(e) => onSearch(e.target.value)}
						/>
						<SBIconButton onClick={() => setShowColumnsConfig(true)}>
							<SBIcon
								icon="settings"
								tooltip={SBTranslate("commons.tableConfig")}
							/>
						</SBIconButton>
					</SBBox>
				</Stack>
			</>
		);
	}, [selectedCount]);

	return (
		<Toolbar className={clsx(classes.tableTopbar)}>
			{Base}
			{showColumnsConfig && (
				<SBGrid
					container
					style={{
						width: "100%",
						position: "absolute",
						top: 0,
						left: 0,
						background: "white",
						zIndex: 10,
						boxShadow: "0px 2px 24px rgba(0, 0, 0, 0.08)",
						borderRadius: 8,
					}}
				>
					<SBGrid
						item
						xs={12}
						display="flex"
						alignItems="center"
						borderBottom="1px solid #E0E0E0;"
					>
						<SBBox
							display="flex"
							alignItems="center"
							width={1}
							py={1}
							px={2}
						>
							<SBIcon icon="sliders" color="primary" />
							<SBTypography fontWeight="bold" pl={2}>
								{t("editColumns")}
							</SBTypography>
							<SBBox ml="auto">
								<SBIconButton
									size="small"
									onClick={() => setShowColumnsConfig(false)}
								>
									<SBIcon icon="x" />
								</SBIconButton>
							</SBBox>
						</SBBox>
					</SBGrid>
					<Divider />
					<SBGrid container p={2} pb={3} spacing={3}>
						{columns.filter((c: any) => !c.columnDef.hideToggle).map((column: any) => {
							const leafColumns = column
								.getLeafColumns()

							const isAllSelected = leafColumns.every(
								(leaf: any) => leaf.getIsVisible()
							);
							const someIsSelected = leafColumns.some(
								(leaf: any) => leaf.getIsVisible()
							);

							return (
								<SBGrid item flexGrow={1} key={column.key}>
									<label style={{ cursor: "pointer" }}>
										<SBBox
											display="flex"
											alignItems="center"
										>
											<SBIndeterminateCheckbox
												key={`${isAllSelected}${someIsSelected}`}
												checked={isAllSelected}
												indeterminate={someIsSelected}
												onChange={(e) => {
													const ableFilters =
														leafColumns.filter(
															(leaf: any) =>
																!leaf.columnDef
																	.fixed
														);

													for (var leaf of ableFilters)
														leaf.getToggleVisibilityHandler()(
															e
														);
												}}
											/>
											<SBTypography
												fontWeight="500"
												ml={1}
												textTransform="uppercase"
											>
												{t(column.id)}
											</SBTypography>
										</SBBox>
									</label>
									<SBBox
										borderLeft="1px solid #E0E0E0"
										ml={0.7}
										pl={2}
										my={1}
									>
										{leafColumns.map((leaf: any) => {
											const fixed = leaf.columnDef.fixed;
											const name = t(
												leaf.columnDef.header
											);
											const hideToggle = leaf.columnDef.hideToggle
											return !hideToggle && (
												<SBGrid
													item
													key={leaf.id}
													xs
													py={0.5}
												>
													<label
														style={{
															cursor: "pointer",
															display: "flex",
															alignItems:
																"center",
														}}
													>
														<SBIndeterminateCheckbox
															{...{
																type: "checkbox",
																disabled: fixed,
																checked:
																	leaf.getIsVisible(),
																onChange: !fixed
																	? leaf.getToggleVisibilityHandler()
																	: () => { },
															}}
														/>
														<span
															style={{
																marginLeft: 5,
																marginRight: 5,
															}}
														>
															{name}
														</span>

														{fixed && (
															<SBIcon icon="padlock" />
														)}
													</label>
												</SBGrid>
											);
										})}
									</SBBox>
								</SBGrid>
							);
						})}
					</SBGrid>
				</SBGrid>
			)}
		</Toolbar>
	);
};

interface SBTableToolbarProps {
	title: string;
	rowCount: number;
	selectedCount: number;
	columns: Column<IEntity, unknown>[];
	toolbarContent?: any;
	handleSearch: (search: string) => void;
	handleExternalSearch?: (search: string) => void;
}
type ExtendedColumnDef = ColumnDef<IEntity, unknown> & { hideToggle: boolean };
