import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	line: {
		stroke: theme.palette.gray2.main,
		"&.lastDate": {
			stroke: theme.palette.green1.main,
		},
	},
	text: {
		textAnchor: "end",
		fontSize: 9,
		left: 10,
		"&.lastDate": {
			fill: theme.palette.green1.main,
		},
		"&.rotated": {
			transform: "rotateZ(-50deg)",
		},
	},
}));
