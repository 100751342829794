import { Divider } from "@mui/material";
import { SBBox } from "modules/Commons";
import { TransferenceResponse } from "modules/Transferences/models/transferenceResponse";
import { useTranslation } from "react-i18next";
import TransferenceLineInfo from "../TransferenceLineInfo";

export default ({ transference, isLastItem }: TransferenceInfoItemProps) => {
	const { t } = useTranslation();

	return (
		<SBBox pr={3} width="100%">
			<SBBox>
				<TransferenceLineInfo
					name={`${t("sourceStore")}: `}
					value={transference.sourceStore}
				/>
				<TransferenceLineInfo
					name={`${t("destinationStore")}: `}
					value={transference.destinationStore}
				/>
				<TransferenceLineInfo
					name={`${t("product")}: `}
					value={transference.product}
				/>
				<TransferenceLineInfo
					name={`${t("description")}: `}
					value={transference.description}
				/>
			</SBBox>
			<Divider hidden={isLastItem} style={{ margin: "16px 0" }} />
		</SBBox>
	);
};

interface TransferenceInfoItemProps {
	transference: TransferenceResponse;
	isLastItem: boolean;
}
