import { Cell, Row } from "@tanstack/react-table";
import { IEntity } from "domain/Domain/Entities/IEntity";
import { VirtualItem } from "react-virtual";
import SBTablePadding from "../SBTablePadding";
import SBTableRow from "../SBTableRow";

export function SBTableVirtualizedBody<T extends IEntity>(
	props: SBTableVirtualizedBodyProps<T>
) {
	const { rows, rowVirtualizer, onClick, reRenderRefs } = props;
	const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

	const paddingTop =
		virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;

	const paddingBottom =
		virtualRows.length > 0
			? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
			: 0;

	return <tbody>
		<SBTablePadding padding={paddingTop} />
		{virtualRows?.map((virtualRow) => (
			<SBTableRow
				key={virtualRow.key}
				virtualRow={virtualRow}
				rows={rows}
				onClick={onClick}
				reRenderRefs={reRenderRefs}
			/>
		))}
		<SBTablePadding padding={paddingBottom} />
	</tbody>
}

interface SBTableVirtualizedBodyProps<T extends IEntity> {
	rows: Row<T>[];
	rowVirtualizer: {
		virtualItems: VirtualItem[];
		totalSize: number;
		scrollToOffset: (index: number, options?: any) => void;
		scrollToIndex: (index: number, options?: any) => void;
		measure: () => void;
	};
	onClick: (entity: Cell<T, any>) => void;
	reRenderRefs: any[];
}
