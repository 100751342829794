import clsx from "clsx";
import { SBTooltip } from "modules/Commons";
import { SBTableColumn } from "modules/Commons/SBDataDisplays/SBTable/models/TableColumn";
import { useStyles } from "./styles";

export function SBSimpleTable<T>(props: SBSimpleTableProps<T>) {
	const classes = useStyles();
	const { rows, columns, fullWidth } = props;

	return (
		<div className={classes.container}>
			<table className={clsx(fullWidth && "fullWidth")}>
				<thead>
					<tr>
						{columns.map((column, index) => {
							return (
								<th
									key={column.name + index}
									className={clsx(
										index % 2 != 0 && "stripe",
										index === 0 && "firstColumn"
									)}
									colSpan={column.headers?.length ?? 1}
								>
									{column.name}
								</th>
							);
						})}
					</tr>
					<tr>
						{columns.map((groups, index) => {
							return groups.headers!.map((column) => {
								return (
									<th
										key={column.name + index}
										className={clsx(
											index % 2 != 0 && "stripe",
											index === 0 && "firstColumn"
										)}
									>
										{column.name}
									</th>
								);
							});
						})}
					</tr>
				</thead>

				<tbody>
					{rows?.map((row, rowIndex) => {
						return (
							<tr
								key={rowIndex}
								className={clsx(rowIndex % 2 == 0 && "oddRow")}
							>
								{columns.map((groups, index) => {
									return groups.headers!.map((column) => {
										var val = column.id(row);

										return (
											<td
												key={
													column.name + index + "row"
												}
												className={clsx(
													index % 2 != 0 && "stripe",
													index === 0 && "firstColumn"
												)}
											>
												{!!column.tip && index === 0 ? (
													<SBTooltip
														title={column.tip ?? ""}
													>
														{val}
													</SBTooltip>
												) : (
													val
												)}
											</td>
										);
									});
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

interface SBSimpleTableProps<T> {
	rows: T[];
	columns: SBTableColumn<T>[];
	fullWidth?: boolean;
}
