import { IChart } from "domain/Chart/Entities/IChart";
import { IChartFilter } from "domain/Chart/Entities/IChartFilter";
import { useSimulationCharts } from "modules/Simulations/hooks/useSimulationCharts";
import { ISimulationPoint } from "modules/Simulations/models/ISimulationPoint";
import { SimulationPeriod } from "modules/Simulations/models/simulationPeriod";
import { useMemo } from "react";
import SimulationBarChart from "./SimulationBarChart";
import SimulationLineChart from "./SimulationLineChart";

export default ({
	dataArray,
	chartID,
	chartFilter,
	selectedChart,
	realChart,
	projectedChart,
	cardIndex,
	ignoreDateConstraint,
	simulationPeriod,
}: any) => {
	const { tooltips } = useSimulationCharts();

	var Component =
		selectedChart === "order" ||
		selectedChart === "requested" ||
		selectedChart === "rupture"
			? SimulationBarChart
			: SimulationLineChart;

	const chartData = useMemo(() => {
		return dataArray.summarization?.points ?? dataArray.result.points;
	}, [dataArray]);

	return (
		<Component
			chartID={chartID}
			dataArray={chartData}
			chartFilter={chartFilter}
			selectedChart={selectedChart}
			realChart={realChart}
			projectedChart={projectedChart}
			cardIndex={cardIndex}
			tooltipRows={tooltips(simulationPeriod)}
			ignoreDateConstraint={ignoreDateConstraint}
			simulationPeriod={simulationPeriod}
		/>
	);
};

export interface SimulateCardChartProps {
	dataArray: any[];
	chartID: any;
	chartFilter: any;
	selectedChart: IChartFilter;
	realChart: IChart<ISimulationPoint>;
	projectedChart: IChart<ISimulationPoint>;
	cardIndex: any;
	ignoreDateConstraint: boolean;
	simulationPeriod: SimulationPeriod;
}
