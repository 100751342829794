import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	sbPagination: {
		"& .MuiPaginationItem-root": {
			backgroundColor: "white",
			borderRadius: "8px",
			"&.Mui-selected": {
				border: "1px solid #7F51FF !important",
				backgroundColor: "white",
				boxSizing: "border-box",
				boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.16)",
			},
		},
	},
}));
