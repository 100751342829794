import { tooltipClasses } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	sbActiveLinkIconTooltip: {
		[`& .${tooltipClasses.tooltip}`]: {
			borderRadius: "8px",
			padding: 0,
			backgroundColor: theme?.palette?.darkPurple?.main,
		},
		[`&.chooseMenu .${tooltipClasses.tooltip}`]: {
			color: "black",
			padding: 0,
			width: "170px",
			top: "-22px",
			left: "-14px",
			borderLeft: "2px solid " + theme.palette?.gray6?.main,
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			position: "absolute",
			boxShadow: "3px 8px 8px rgba(0, 0, 0, 0.1)",
			zIndex: 100000,
			backgroundColor: "white",
		},
	},
}));
