import SBBox from "modules/Commons/SBLayouts/SBBox";
import { ReactNode } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

export default ({
	items,
	label,
	total,
	width = "100%",
	height = 170,
	innerRadius = 55,
	outerRadius = 70,
	fontSize = "1.2rem",
}: SBThreeLevelGauge) => {
	return (
		<SBBox style={{ position: "relative", width: width, height: height }}>
			<ResponsiveContainer>
				<PieChart width={730} height={250}>
					<Pie
						data={items}
						dataKey="value"
						nameKey="name"
						cx="50%"
						cy="50%"
						innerRadius={innerRadius}
						outerRadius={outerRadius}
						blendStroke
					>
						{items.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={entry.color} />
						))}
					</Pie>
				</PieChart>
			</ResponsiveContainer>
			<SBBox
				style={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
					fontSize: fontSize,
					fontWeight: "bold",
					color: total.color,
					textAlign: "center",
				}}
			>
				{label}
				{total.value}
			</SBBox>
		</SBBox>
	);
};

interface SBThreeLevelGauge {
	label?: ReactNode;
	items: {
		value: number | string;
		label: string;
		color: string;
		formattedValue?: string;
	}[];
	total: {
		value: number | string;
		label: string;
		color?: string;
		formattedValue?: string;
	};
	width?: number | string;
	height?: number;
	innerRadius?: number;
	outerRadius?: number;
	fontSize?: string;
}
