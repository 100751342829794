import { DateTime } from "luxon";
import { SBTranslate, SBTranslateType } from "i18n";
import { INotification } from "./INotification";
import { NotificationsTypeEnum } from "../Enums/Notificationtype";
import { useSystemLanguage } from "hooks/useSystemLanguage";
export interface TranslatedNotification {
	type: NotificationsTypeEnum;
	date: DateTime;
	stack: number;
	guid: string;
	seen: number | boolean;
	title: string;
	description: string;
	actionText?: string;
	action?: () => void;
}

interface NotificationConstructorProps {
	dto: INotification;
	titleKey: string;
	descriptionKey: string;
	actionTextKey?: string;
	parameters: any;
	action?: () => void;
}

export default (
	notification: NotificationConstructorProps
): TranslatedNotification => {
	const { getLanguage } = useSystemLanguage();
	const { dto, titleKey, descriptionKey, actionTextKey, parameters, action } =
		notification;
	const translatedTitle = SBTranslate(
		titleKey as SBTranslateType,
		parameters
	);
	const translatedDescription = SBTranslate(
		descriptionKey as SBTranslateType,
		parameters
	);
	const translatedActionText = SBTranslate(
		actionTextKey as SBTranslateType,
		parameters
	);

	return {
		type: dto.type,
		date: DateTime.fromISO(dto.date).setLocale(getLanguage()),
		stack: dto.stack,
		guid: dto.guid,
		seen: dto.seen,
		title: translatedTitle,
		description: translatedDescription,
		actionText: translatedActionText,
		action: action,
	};
};
