import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import { SBTranslate } from "i18n";
import { t } from "i18next";
import { SBBox, SBIcon } from "modules/Commons";
import { NotificationsRequester } from "modules/Notifications/services/NotificationsRequester";
import { useGlobalInventoriesStatusContext } from "..";
import SBInventoryTableTopItems from "../SBInventoryTableTopItems";
import { useSystemFilterContext } from "contexts/SystemFilterContext";

export default ({ topStats }: SBInventoryExcessTopItemsProps) => {
	const { filtersContext } = useSystemFilterContext();
	const { fetchData } = useGlobalInventoriesStatusContext();

	const fetchReserveds = async () => {
		fetchData(NotificationsRequester.excess(filtersContext), {
			title: "inventoriesInExcess",
			columnName: "excess",
		});
	};
	return (
		<SBInventoryTableTopItems
			name={SBTranslate("inventoriesInExcess")}
			label={"csl"}
			quantity={5}
			formatter={(c: any) => `${(+c?.csl! * 100).toFixed(0)}%`}
			prepend={
				<SBBox style={{ transform: "rotateX(180deg)" }}>
					<SBIcon icon="select" fillColor="error" size={25} />
				</SBBox>
			}
			title={`${t("top5")} - ${t("inventoriesInExcess")}`}
			items={topStats?.currentStatus?.productsWithExcess as any}
			showAll={fetchReserveds}
		/>
	);
};

interface SBInventoryExcessTopItemsProps {
	topStats: IHomeStatistics;
}
