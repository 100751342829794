import { CircularProgress } from "@mui/material";
import { SBBox, SBIcon, SBTextField, SBTypography } from "modules/Commons";
import { SystemContextRequester } from "modules/SystemContext/services/SystemContextRequester";
import { useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useEffect } from "react";
import { FixedSizeList as List } from "react-window";
import { IEntity } from "domain/Domain/Entities/IEntity";
import SBSystemSearchContent from "../SBSystemSearchContent";
import SBEntityChips from "../../SBEntityChips";
import { debounce } from "lodash";
import { useSystemFilterContext } from "contexts/SystemFilterContext";

export default forwardRef(
	(
		{
			search,
			items = [],
			noSearch = false,
			hasSelection = true,
			onApply,
			itemSize = 59,
			categoryDeep,
			filterIsolated,
			filterType,
		}: SystemSearchContainerProps,
		ref
	) => {
		const { t } = useTranslation();
		const classes = useStyles();
		const [loading, setLoading] = useState(false);
		const [searchedItems, setSearchedItems] = useState<IEntity[]>([]);
		const [selectedItems, setSelectedItems] = useState<IEntity[]>([]);
		const [, setSearchString] = useState(search);
		const { filtersContext, clearContextFilter, setClearContextFilter } = useSystemFilterContext();

		const handleSearch = debounce((e) => {
			setSearchString(e.target.value);
			searchItems(e.target.value);
		}, 1000);

		const searchItems = (text: string) => {
			setLoading(true);
			SystemContextRequester.systemSearch(text, false)
				.then(({ stores, products, categories }) => {
					setSearchedItems([...products, ...categories, ...stores]);
				})
				.finally(() => {
					setLoading(false);
				});
		};

		const handleClickItem = (item: any) => {
			const newItems = [...selectedItems];

			const itemIndex = newItems.findIndex(
				(c) => c.clientID === item.clientID
			);

			if (itemIndex >= 0) newItems.splice(itemIndex, 1);
			else newItems.push(item);

			setSelectedItems(newItems);
			onApply(newItems);
		};

		useImperativeHandle(ref, () => ({
			setSearch: (value: string) => {
				handleSearch(value);
			},
		}));

		useEffect(() => {
			setSelectedItems(filtersContext?.items ?? []);
		}, [filtersContext?.items])

		useEffect(() => {
			if ((items.length > 0 && searchedItems.length === 0) || clearContextFilter) {
				setSelectedItems([...items]);
				setSearchedItems([...items]);
			}
			if (clearContextFilter) setClearContextFilter(false);

		}, [items.length, searchedItems.length]);

		return (
			<SBBox
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				height={0.8}
				style={{
					position: "relative",
					width: "100%",
				}}
			>
				<SBBox className={classes.container}>
					<SBBox p={1}>
						{!noSearch && !clearContextFilter && (
							<SBTextField
								onChange={handleSearch}
								className={classes.searchInput}
								placeholder={t("search")}
								InputProps={{
									endAdornment: (
										<SBIcon icon="search" color="#7F51FF" />
									),
								}}
							/>
						)}
					</SBBox>
					<SBBox
						display="flex"
						width={1}
						style={{ borderBottom: "1px solid #E2DDF2" }}
					>
						<SBTypography
							variant="body2"
							pl={1.5}
							pb={1}
							mr="auto"
							color="primary"
							fontWeight="bold"
						>
							{t("yourSearch")} (
							{loading ? (
								<CircularProgress size={12} color="primary" />
							) : (
								searchedItems.length
							)}
							)
						</SBTypography>
					</SBBox>
					<SBBox
						style={{
							minHeight: 0,
							maxHeight: loading ? 0 : 400,
							transition: "max-height 0.4s ease-in-out",
							overflow: "hidden",
						}}
					>
						<List
							width="100%"
							height={400}
							itemCount={searchedItems.length}
							itemSize={itemSize}
							style={{ overflowX: "hidden" }}
						>
							{({ index, style }) => {
								const item = searchedItems[index];
								return (
									<SBBox style={style}>
										<SBSystemSearchContent
											key={item?.id}
											deep={categoryDeep}
											filterIsolated={filterIsolated}
											filterType={filterType}
											item={item}
											onClick={() =>
												hasSelection
													? handleClickItem(item)
													: null
											}
											selectedItems={selectedItems}
										/>
									</SBBox>
								);
							}}
						</List>
					</SBBox>
					{hasSelection && (
						<SBEntityChips
							items={selectedItems}
							onDelete={handleClickItem}
						/>
					)}
				</SBBox>
			</SBBox>
		);
	}
);

interface SystemSearchContainerProps {
	search?: string;
	items: IEntity[];
	noSearch?: boolean;
	onlyActives?: boolean;
	hasSelection?: boolean;
	onApply: (newItems: IEntity[]) => void;
	itemSize: number;
	categoryDeep?: number;
	filterIsolated?: any;
	filterType?: any;
	useFiltersOptions?: any;
}
