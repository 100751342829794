export enum DateRangeType {
	Daily = "daily",
	Monthly = "monthly",
	Trimester = "quarterly",
	Semester = "semiannual",
	Year = "yearly",
}

export const AllTypes = [
	DateRangeType.Daily,
	DateRangeType.Monthly,
	DateRangeType.Trimester,
	DateRangeType.Semester,
	DateRangeType.Year,
];

export const DateMultipliers = (dateRangeType: DateRangeType): number => {
	switch (dateRangeType) {
		case DateRangeType.Daily:
			return 0;
		case DateRangeType.Monthly:
			return 1;
		case DateRangeType.Trimester:
			return 3;
		case DateRangeType.Semester:
			return 6;
		case DateRangeType.Year:
			return 12;
		default:
			return 0;
	}
};

export const isMonthMultiplier = (rangeSize: number & { isBetween: any }) => {
	return rangeSize.isBetween({
		end: DateMultipliers(DateRangeType.Monthly),
	});
};

export const isTrimesterMultiplier = (
	rangeSize: number & { isBetween: any }
) => {
	return rangeSize.isBetween({
		start: DateMultipliers(DateRangeType.Monthly),
		end: DateMultipliers(DateRangeType.Trimester),
		exclusiveStart: true,
	});
};

export const isSemesterMultiplier = (
	rangeSize: number & { isBetween: any }
) => {
	return rangeSize.isBetween({
		start: DateMultipliers(DateRangeType.Trimester),
		end: DateMultipliers(DateRangeType.Semester),
		exclusiveStart: true,
	});
};

export const isYearMultiplier = (rangeSize: number & { isBetween: any }) => {
	return rangeSize.isBetween({
		start: DateMultipliers(DateRangeType.Semester),
		end: DateMultipliers(DateRangeType.Year),
		exclusiveStart: true,
	});
};

export const fetchDateRangeMultiplier = (
	rangeSize: number & { isBetween: any }
) => {
	if (isMonthMultiplier(rangeSize))
		return DateMultipliers(DateRangeType.Monthly);
	if (isTrimesterMultiplier(rangeSize))
		return DateMultipliers(DateRangeType.Trimester);
	if (isSemesterMultiplier(rangeSize))
		return DateMultipliers(DateRangeType.Semester);
	if (isYearMultiplier(rangeSize)) return DateMultipliers(DateRangeType.Year);
	return null;
};
