import { Card, CardProps } from "@mui/material";

/** Common Card */
export default (props: CardProps) => {
	return (
		<Card
			{...props}
			style={{
				...props.style,
				boxShadow: "0px 2px 16px 0px #00000014",
			}}
		/>
	);
};
