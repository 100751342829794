import { HubConnectionBuilder, LogLevel, HttpTransportType, HubConnection, HubConnectionState, } from "@microsoft/signalr";
import { useClientIdentificator } from "hooks/useClientIdentificator";
import { useEffect, useState } from "react";

export const useSignalR = ({ url, events }: useSignalRProps) => {
	const [connection, setConnection] = useState<HubConnection>();
	const { clientEnvironment } = useClientIdentificator();

	const connect = (url: string) => {
		return new HubConnectionBuilder()
			.withUrl(`${url}?tenant=${clientEnvironment}`, {
				skipNegotiation: true,
				transport: HttpTransportType.WebSockets
			})
			.configureLogging(LogLevel.Information)
			.build();
	};

	const establishConnection = () => {
		const connection = connect(url);
		setConnection(connection);
	}

	useEffect(() => {
		establishConnection();
	}, [])

	useEffect(() => {
		if (connection) {
			connection.start()
				.then((c: any) => {
					for (var event of events)
						connection.on(event.method, event.on)
				})
				.catch((err: any) => console.log(err));

			connection.onclose(() => {
				setTimeout(() => {
					establishConnection();
				}, 5000);
			})
		}
	}, [connection])

	return {
		send: (method: string, ...params: any[]) => {
			connection?.invoke(method, ...params)
		}
	};
};

interface useSignalRProps {
	url: string;
	events: {
		method: string;
		on: (...args: any[]) => void;
	}[]
};
