import { SBBox, SBTypography } from "modules/Commons";
import SBConfirmDialog from "modules/Commons/SBForms/SBConfirmDialog";
import purchaseConfirm from "assets/purchaseDatePlaceholder.svg";
import { useTranslation } from "react-i18next";

export default ({
	open,
	onClose,
	countOfItems,
	onConfirm,
	onCancel,
}: {
	open: boolean;
	onClose: () => any;
	countOfItems?: number;
	onConfirm: () => any;
	onCancel: () => any;
}) => {
	const { t } = useTranslation();
	return (
		<SBConfirmDialog
			key={open + ""}
			open={open}
			title={t("transferenceConfirmationTitle")}
			onConfirm={onConfirm}
			onCancel={onCancel}
			onClose={onClose}
			confirmColor="primary"
		>
			<SBBox display="flex" flexDirection="column" width="25rem">
				<SBBox mx="auto">
					<img height={140} width={190.21} src={purchaseConfirm} />
				</SBBox>
				<SBTypography mt="1.5rem" color="blackTitle.main">
					{t("transferenceConfirmationText", {
						itemsCount: countOfItems,
					})}
				</SBTypography>
			</SBBox>
		</SBConfirmDialog>
	);
};
