import { Pagination, PaginationItem, PaginationProps } from "@mui/material";
import { useStyles } from "./style";

export default (props: SBPaginationProps) => {
	const classes = useStyles();
	return (
		<Pagination
			className={classes.sbPagination}
			data-testid="sb-pagination"
			renderItem={(item) => <PaginationItem {...item} />}
			{...props}
		/>
	);
};

export interface SBPaginationProps extends PaginationProps {}
