import { SBChip } from "modules/Commons";
import SBTableCell, { SBTableCellProps } from "../SBTableCell";

export default ({
	label,
	padding,
	color,
	style,
	align = "right",
	onClick,
}: SBChipTableCellProps) => {
	return (
		<SBTableCell
			align={align}
			padding={padding}
			style={style}
			onClick={onClick}
		>
			<SBChip color={color} label={label} />
		</SBTableCell>
	);
};

export interface SBChipTableCellProps extends SBTableCellProps {
	onClick: () => void;
	label?: string | JSX.Element;
	color:
		| "default"
		| "primary"
		| "secondary"
		| "error"
		| "info"
		| "success"
		| "warning"
		| undefined;
}
