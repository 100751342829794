import { IInventory } from "domain/Inventory";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import SBInventoryPositionModal from "views/Commons/SBInventoryPositionModal";
import { InventoryStatusContext } from "../SBGlobalInventoriesStatus/context";
import { InventoryVirtualStatusConfig, InventoryVirtualStatusContext, InventoryVirtualStatusContextProps } from "./context";
import SBInventoryVirtualPositionModal from "views/Commons/SBInventoryVirtualPositionModal";

export const SBGlobalInventoriesVirtualStatusProvider = (
	props: SBGlobalInventoryVirtualStatusProviderProps
) => {
	const { children } = props;
	const { pathname } = useLocation();
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [configs, setConfigs] = useState({} as InventoryVirtualStatusConfig);
	const [statistics, setStatistics] = useState<IInventory[]>([]);

	const value = {
		fetchData: async (d, c) => {
			setLoading(true);
			setIsOpen(true);
			setConfigs(c);
			const result = await d;
			setStatistics(result);
			setLoading(false);
		},
	} as InventoryVirtualStatusContextProps;

	useEffect(() => {
		setIsOpen(false);
	}, [pathname]);

	return (
		<InventoryVirtualStatusContext.Provider value={value}>
			{children}
			<SBInventoryVirtualPositionModal
				inventories={statistics}
				isMenuOpen={isOpen}
				loading={loading}
				closeMenu={() => setIsOpen(false)}
				title={configs.title}
				itemName={configs.columnName}
			/>
		</InventoryVirtualStatusContext.Provider>
	);
};

export const useGlobalInventoriesVirtualStatusContext = () =>
	useContext(InventoryVirtualStatusContext);

interface SBGlobalInventoryVirtualStatusProviderProps {
	children: ReactNode;
}
