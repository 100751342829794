import { useSystemFilterContext } from "contexts/SystemFilterContext";
import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import useFilterCache from "hooks/useFilterCache";
import { SBTranslate } from "i18n";
import SBDeadInventory from "modules/DeadInventory/components/SBDeadInventory";
import { useGlobalInventoriesDeadStatusContext } from "modules/Inventory/components/SBGlobalInventoriesDeadStatus";
import { NotificationsRequester } from "modules/Notifications/services/NotificationsRequester";

export default ({ statistics }: SBDeadInventoryCardProps) => {
	const { filtersContext } = useSystemFilterContext();
	const { fetchData } = useGlobalInventoriesDeadStatusContext();

	const fetchDeadInventories = async () => {
		fetchData(new Promise(async (res, rej) => {
			res(await NotificationsRequester.deadInventories(filtersContext));
		}), {
			title: SBTranslate("commons.deadInventory_other"),
			columnName: SBTranslate("commons.deadInventory"),
		});
	};

	return (<SBDeadInventory
		onSeeAll={fetchDeadInventories}
		deadInventoriesCount={statistics.deadInventories}
		deadInventoriesPositions={statistics.deadInventoriesPosition}
	/>)
};

interface SBDeadInventoryCardProps {
	statistics: IHomeStatistics;
}
