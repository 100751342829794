import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	tooltip: {
		backgroundColor: "transparent",
		minWidth: "calc(50vw)",
	},
	arrow: {
		fontSize: 16,
		width: 17,
		backgroundColor: "transparent",
		"&::before": {
			border: "2px solid #777",
			backgroundColor: "transparent",
			boxSizing: "border-box",
		},
	},
	box: {
		marginLeft: "-8px",
		marginTop: "-60px",
	},
	simulationCardCost: {
		border: "2px solid #777",
	},
}));
