import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	tableTopbar: {
		borderBottom: "1px solid",
		borderBottomColor: theme.palette?.gray5?.main,
		padding: "0 !important",
		margin: 0,
		display: "flex",
		alignItems: "center",
	},
	tableTopbarContent: {
		height: "64px",
		alignItems: "center",
		width: "100%",
		overflowY: "auto",
		display: "flex",
	},
}));
