import { TableHead } from "@mui/material";
import { SBTableColumn } from "../models/TableColumn";
import { SBTableColumnGroup } from "../models/TableColumnGroup";
import SBTableHeaderGroups, {
	SBTableHeaderGroupsProps,
} from "./SBTableHeaderGroups";
import SBTableHeaderCells, {
	SBTableHeaderCellsProps,
} from "./SBTableHeaderCells";

/** Table headers with groups if passed */
export default ({
	disabled,
	checked,
	indeterminate,
	selectable,
	actionable,
	onSelectAllClick,
	columns,
	showGroups,
}: SBTableHeadersProps) => {
	const headersGroup = columns as SBTableColumnGroup[];

	const oneDimensionHeaders = (
		showGroups
			? headersGroup.flatMap((c) => c.headers)
			: (columns as SBTableColumn<any>[])
	).filter((c) => !c.hide);

	return (
		<TableHead>
			<SBTableHeaderGroups
				showGroups={showGroups}
				groups={headersGroup.filter((c) => !c.hide)}
				selectable={selectable}
			/>
			<SBTableHeaderCells
				columns={oneDimensionHeaders}
				selectable={selectable}
				actionable={actionable}
				onSelectAllClick={onSelectAllClick}
				disabled={disabled}
				checked={checked}
				indeterminate={indeterminate}
			/>
		</TableHead>
	);
};

export interface SBTableHeadersProps
	extends Omit<SBTableHeaderCellsProps, "columns">,
		Omit<SBTableHeaderGroupsProps, "groups"> {
	/** Headers to render */
	columns: SBTableColumn<any>[] | SBTableColumnGroup[];
}
