import { Toolbar } from "@mui/material";
import { SBTableToolbarTitle } from "./SBTableToolbarTitle";
import { SBTableToolbarContainer } from "./SBTableToolbarContainer";
import { SBTableToolbarActions } from "./SBTableToolbarActions";
import { useStyles } from "./style";
import { SBBox } from "modules/Commons";

export default ({
	showCount,
	count,
	selectedCount,
	title,
	editActions,
	onApply,
	onSearch,
	children,
	toolbarContent,
	searching,
	toolbarAfterContent,
	selectedItems,
}: SBTableToolbarProps) => {
	const classes = useStyles();

	return (
		<SBBox className={classes.tableTopbar}>
			<SBTableToolbarTitle title={title} />

			<SBBox className={classes.tableTopbarContent}>
				<SBTableToolbarContainer
					showCount={showCount}
					count={count}
					itemsCount={selectedCount}
					selectedItems={selectedItems}
					editActions={editActions}
					onApply={onApply}
				/>
				<SBBox>{toolbarContent}</SBBox>

				<SBTableToolbarActions
					onSearch={onSearch}
					searching={searching}
					children={children}
				/>
				<SBBox>{toolbarAfterContent}</SBBox>
			</SBBox>
		</SBBox>
	);
};

export interface SBTableToolbarProps {
	showCount: boolean;
	count: string;
	selectedCount?: any;
	title?: any;
	editActions?: any;
	onApply?: any;
	onSearch?: any;
	children?: any;
	toolbarContent?: any;
	selectedItems?: any;
	toolbarAfterContent?: any;
	searching?: any;
}
