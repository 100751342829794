import { Translate } from "i18n/translate";
import { SBBox, SBIcon, SBIconButton, SBTypography } from "modules/Commons";

/**
 * Message of invalid credentials
 */
export default ({ onClose }: LoginFormInvalidProps) => {
	return (
		<SBBox
			display="flex"
			my={1}
			p={2}
			width={1}
			alignItems="center"
			bgcolor="darkPurple.main"
			borderRadius={2}
			justifyContent="space-between"
		>
			<SBBox display="flex" flexDirection="column" justifyContent="start">
				<SBTypography color="#fff" fontWeight="700">
					{Translate("loginError")}
				</SBTypography>
				<SBTypography color="#fff" fontWeight="400">
					{Translate("loginErrorMessage")}
				</SBTypography>
			</SBBox>
			<SBIconButton
				onClick={() => onClose(false)}
				data-testid="login-form-invalid-close"
			>
				<SBIcon icon="x" color="white" />
			</SBIconButton>
		</SBBox>
	);
};

interface LoginFormInvalidProps {
	/** Action to execute when clicked on the close button */
	onClose: (value: boolean) => any;
}
