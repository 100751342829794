import { SBBox, SBTypography } from "modules/Commons";
import { useStyles } from "./style";

/** Header of the calendar */
export const SBDatePickerBaseHeader = (props: SBDatePickerBaseHeaderProps) => {
	const classes = useStyles();
	const { headers, dimension, getter = (value: string) => value[0] } = props;

	const hasAnyHeader = headers && headers.length > 0;

	return (
		<SBBox display="flex" alignItems="end">
			{hasAnyHeader &&
				headers.map((c) => {
					return (
						<SBTypography
							pt={1}
							key={c}
							className={classes.sbDatePickerBaseHeader}
							width={dimension}
							height={dimension}
							fontWeight="700"
						>
							{getter(c)}
						</SBTypography>
					);
				})}
		</SBBox>
	);
};

export interface SBDatePickerBaseHeaderProps {
	/** Headers of the selected calendar. EX: if daily is selected headers will be [mon, tue, wen, thu, fry, sat, sun]. */
	headers: Array<string>;
	/** Dimension in size that each header will have */
	dimension: number;
	/** Getter of the value to show on the header. By default will be the first letter*/
	getter?: (value: string) => string;
}
