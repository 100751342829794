import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
	sbSimulationChartToolTip: {
		position: "absolute",
		zIndex: 1000,
		borderRadius: "8px !important",

		"& .MuiTableCell-root": {
			borderBottom: "0.5px solid #E2DDF299;",
			textAlign: "right",

			"&:first-child": {
				textAlign: "left",
			},
		},

		"& .MuiTableRow-root": {
			"&.active": {
				backgroundColor: theme.palette.lightPurple.main,
				"& > *": {
					fontWeight: 700,
					"&.column": {
						color: theme.palette.primary.main,
						whiteSpace: "nowrap",
					},
				},
			},

			"& > *": {
				"&.real": {
					color: theme.palette.success.main,
				},
				"&.projected": {
					color: theme.palette.blue2.main,
				},
			},
		},
	},
}));
