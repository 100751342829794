import { SBBox, SBTypography } from "modules/Commons";

export default ({ name, value }: TransferenceLineInfoProps) => {
	return (
		<SBBox display="flex" lineHeight={2}>
			<SBTypography variant="body1" fontWeight={400} mr={0.3}>
				{name}
				<SBBox fontWeight={600} mr={0.3} component="span">
					{value}
				</SBBox>
			</SBTypography>
		</SBBox>
	);
};

interface TransferenceLineInfoProps {
	name: string;
	value: string;
}
