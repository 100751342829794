import { Divider } from "@mui/material";
import { SBBox, SBIcon, SBTooltip, SBTypography } from "modules/Commons";
import { IAccumulation } from "domain/Utils/models/IAccumulation";
import { useTranslation } from "react-i18next";

export default ({ title, items }: ExplorationsListingProps) => {
	const threshold = 0.5;
	const { t } = useTranslation();

	return (
		<SBBox>
			<SBTypography fontWeight="bold" textTransform="capitalize">
				{title}
			</SBTypography>
			{items.map((item, index: number) => (
				<>
					<SBBox display="flex" py={1} justifyContent="space-between">
						<SBBox display="flex">
							<SBTypography textTransform="capitalize" ml={1}>
								{item.title}
							</SBTypography>
						</SBBox>
						<SBBox display="flex">
							<SBTypography>
								{item.formatter
									? item.formatter(item.getter()?.value)
									: item.getter()?.value}
							</SBTypography>
							<SBTypography mx={1}>{item.unit}</SBTypography>
							<SBTooltip
								disableHoverListener={
									item.getter()?.validCount >= threshold
								}
								title={t("accumulationInvalidAmount", {
									amount:
										item.getter()?.validCount *
										100?.systemFormatAsPercentage(),
								})}
							>
								<SBIcon
									icon="alert-triangle"
									color={
										item.getter()?.validCount < threshold
											? "orange"
											: "transparent"
									}
								/>
							</SBTooltip>
						</SBBox>
					</SBBox>
					{index !== items.length - 1 && <Divider />}
				</>
			))}
		</SBBox>
	);
};

export interface ExplorationsListingProps {
	title: string;
	items: {
		title: string;
		getter: () => IAccumulation;
		unit?: string;
		warning?: (v: number) => any;
		formatter?: (v: number) => any;
	}[];
	exploration: any;
}
