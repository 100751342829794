import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
	tableTopbar: {
		borderBottom: "1px solid",
		borderBottomColor: "#E0E0E0",
		padding: 0,
		margin: 0,
		minHeight: 0,
	},
	tableTopbarContainer: {
		borderRight: "1px solid #E0E0E0",
		padding: "21px 24px",
		fontWeight: "bold",
	},
	tableInfo: {
		"& > p": {
			marginRight: 10,
			paddingTop: 4,
			marginLeft: 20,
		},
		"& > button:nth-child(2)": {
			marginRight: 10,
		},
	},
	tableActions: {
		marginLeft: "auto",
		paddingRight: "12px",
	},
	tableSearch: {
		border: "none",
		backgroundColor: "red",
		input: {
			paddingRight: 36,
		},
		"& fieldset": {
			borderRadius: 100,
		},
	},
}));
