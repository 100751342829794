import { SBBox, SBGrid, SBOutlinedButton, SBTypography } from "modules/Commons";
import { DateRangeType } from "modules/Commons/SBDate/models/dateRangeType";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const SBDatePickerConfigRange = () => {
	const { t } = useTranslation();
	const { getValues, setValue } = useFormContext();

	const [selectedPickers, setSelectedPickers] = useState(
		getValues("selectedPickers") ?? []
	);

	const toggleSelectedPicker = (picker: any) => {
		const newSelectedPickers = selectedPickers;

		const pickerIndex = newSelectedPickers.indexOf(picker);

		if (pickerIndex >= 0) newSelectedPickers.splice(pickerIndex, 1);
		else newSelectedPickers.push(picker);

		setSelectedPickers(newSelectedPickers);
		setValue("selectedPickers", newSelectedPickers);
		setPickers((c) => [...c]);
	};

	const [pickers, setPickers] = useState([
		DateRangeType.Daily,
		DateRangeType.Monthly,
		DateRangeType.Trimester,
		DateRangeType.Semester,
		DateRangeType.Year,
	]);

	return (
		<SBBox mt={2}>
			<SBTypography fontWeight="500">
				{t("consolidateByRanges", { count: 2 })}
			</SBTypography>
			<SBGrid container gap={1} mt={1}>
				{pickers.map((picker) => {
					const pickerIndex = selectedPickers.indexOf(picker);
					const isSelected = pickerIndex >= 0;

					return (
						<SBGrid item key={picker}>
							<SBOutlinedButton
								color={isSelected ? "primary" : "gray4"}
								bgcolor={isSelected ? "primary" : "gray4"}
								onClick={() => toggleSelectedPicker(picker)}
							>
								<SBTypography
									px={1}
									fontWeight={isSelected ? "bold" : "400"}
									color={isSelected ? "primary" : "#999"}
									style={{
										cursor: "pointer",
										textTransform: "capitalize",
									}}
								>
									{t(picker)}
								</SBTypography>
							</SBOutlinedButton>
						</SBGrid>
					);
				})}
			</SBGrid>
		</SBBox>
	);
};
