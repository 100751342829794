import { useMixpanelContext } from "contexts/MixpanelContext";
import { useSystemFilterContext } from "contexts/SystemFilterContext";
import { IComposedFilter } from "domain/Utils";
import { IHomeRowStatistics } from "domain/Utils/models/IHomeRowStatistics";
import { useDateStorageCache } from "hooks/useDateStorageCache";
import { SBTranslate } from "i18n";
import { DateTime } from "luxon";
import { SBBox, SBGrid, SBLoading } from "modules/Commons";
import { SBNewTable } from "modules/Commons/SBDataDisplays/SBTable/SBNewTable";
import { useHomeTableHeaders } from "modules/Home/domain/services/homeTableHeaders";
import { NotificationsRequester } from "modules/Notifications/services/NotificationsRequester";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SBComposedFilter from "views/Commons/SBComposedFilter";
import SBContextDateInfo from "views/Commons/SBContextDateInfo";

export default () => {
	const [loading, setLoading] = useState(true);
	const [availableDates, setAvailableDates] = useState<string[]>([]);
	const [statistics, setStatistics] = useState([] as IHomeRowStatistics[]);
	const tableRef = useRef<any>();

	const { context, notCurrentContext, filtersContext, setFiltersContextState } = useSystemFilterContext();
	const { getDateItem, setDateItem } = useDateStorageCache();
	const { trackExport } = useMixpanelContext();

	const { t } = useTranslation();
	const { headers } = useHomeTableHeaders();

	const downloadCSV = () => {
		setLoading(true);
		trackExport({ date: getDateItem() });
		tableRef.current.downloadCSV({});
		setLoading(false);
	};

	const getAvailableDates = async () => {
		const result = await NotificationsRequester.homeStatisticsDates();
		if (result) setAvailableDates(result)
	};

	const handleApply = async (filter: IComposedFilter) => {
		filter.picker?.date && setDateItem(filter.picker.date.toFormat("yyyy-MM-dd"))
		setLoading(true);
		setStatistics([]);

		if (filter.filter)
			setFiltersContextState(filter.filter);
		else
			setFiltersContextState([]);

		NotificationsRequester.homeStatisticsTable(filter)
			.then((statistics) => {
				setStatistics(statistics);
			})
			.catch(() => { })
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (context)
			handleApply({ filter: filtersContext?.items } as IComposedFilter);
	}, [context]);


	useEffect(() => { getAvailableDates(); }, []);

	return (
		<SBBox pl={10} mx="auto" style={{ overflowY: "auto" }} height={1}>
			<SBGrid container display="flex" alignItems="center">
				<SBComposedFilter.Base onApply={handleApply}>
					<SBComposedFilter.Picker date={getDateItem() && DateTime.fromISO(getDateItem())} range={false} availableDates={availableDates} />
					<SBComposedFilter.Filter />
					<SBComposedFilter.Applicator />
					<SBComposedFilter.Actions
						loading={loading}
						actions={[
							{
								icon: { name: "download" },
								title: t("exportCSV"),
								onClick: downloadCSV,
							},
						]}
					/>
				</SBComposedFilter.Base>
			</SBGrid>

			{notCurrentContext() ? <SBGrid container>
				<SBContextDateInfo context={DateTime.fromISO(getDateItem())} />
			</SBGrid> : <></>}

			<SBBox mt={3}>
				<SBLoading loading={loading}>
					<SBNewTable
						ref={tableRef}
						containerHeight={notCurrentContext() ? 280 : 225}
						virtualize
						defaultColumns={headers}
						startRows={statistics}
						title={SBTranslate("item_other")}
						onClick={() => { }}
					/>
				</SBLoading>
			</SBBox>
		</SBBox>
	);
};
