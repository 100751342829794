import clsx from "clsx";
import useTableColumnBuilder from "hooks/useTableColumnBuilder";
import _ from "lodash";
import { SBBox, SBTooltip } from "modules/Commons";
import { SBTableColumn } from "modules/Commons/SBDataDisplays/SBTable/models/TableColumn";
import { ISimulationModel } from "modules/Simulations/models/ISimulationModel";
import { ISimulationPoint } from "modules/Simulations/models/ISimulationPoint";
import { ISimulationRow } from "modules/Simulations/models/ISimulationRow";
import { SimulationPeriod } from "modules/Simulations/models/simulationPeriod";
import { SimulationPeriodSummarizer } from "modules/Simulations/services/SimulationPeriodSummarizer";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SBSimpleTable } from "views/Commons/SBSimpleTable";
import { useStyles } from "./style";

export default ({
	item,
	simulationPeriod,
	pointAsTable,
	chartsToShow,
}: SimulationSummarizationsInfoProps) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		systemFormatter,
		integerFormatter,
		decimalPercentageNoDecimalFormatter,
	} = useTableColumnBuilder<ISimulationModel>();

	const [hoverColumn, setHoverColumn] = useState(null);

	const getNewHeaders = (
		key: string,
		title: string,
		isAll: boolean = false
	): SBTableColumn<ISimulationModel> => {
		const headers = [] as SBTableColumn<ISimulationModel>[];
		headers.push({
			id: (d) => d.name,
			name: "",
		});
		headers.push({
			id: (d) => (d.name === "All" ? "string" : d.start),
			align: "right",
			name: t("onHand"),
			formatter: isAll ? () => "---" : systemFormatter,
		});
		headers.push({
			id: (d) => d.onOrder,
			align: "right",
			name: t("onOrder"),
			formatter: isAll ? () => "---" : systemFormatter,
		});
		headers.push({
			id: (d) => d.csl,
			align: "right",
			name: t("csl"),
			formatter: isAll
				? () => "---"
				: decimalPercentageNoDecimalFormatter,
		});
		headers.push({
			id: (d) => d.forecast,
			align: "right",
			name: t("forecast"),
			formatter: systemFormatter,
		});
		headers.push({
			id: (d) => d.sales,
			align: "right",
			name: t("sales"),
			formatter: systemFormatter,
		});
		headers.push({
			id: (d) => d.rupture,
			align: "right",
			name: t("rupture"),
			formatter: systemFormatter,
		});
		headers.push({
			id: (d) => d.hadStockoutEvent,
			align: "right",
			name: t("stockoutEvent_other"),
			formatter: integerFormatter,
		});
		headers.push({
			id: (d) => d.requested,
			align: "right",
			name: t("requested"),
			formatter: systemFormatter,
		});
		headers.push({
			id: (d) => d.received,
			align: "right",
			name: t("received"),
			formatter: systemFormatter,
		});
		headers.push({
			id: (d) => d.orders,
			align: "right",
			name: t("transaction_other"),
			formatter: integerFormatter,
			tip: t("simulationDatailsTransactionTip"),
		});

		return {
			key: key,
			id: () => title,
			name: title,
			align: "left",
			headers,
		} as SBTableColumn<ISimulationModel>;
	};

	const points = useMemo(() => {
		const v = SimulationPeriodSummarizer.summarize(
			item,
			SimulationPeriod.All
		) as ISimulationPoint[];
		return [...v, ...item?.points];
	}, [item]);

	const transposedColumns = useMemo(() => {
		const columns = [] as SBTableColumn<any>[];
		columns.push({
			id: () => "data",
			name: "data",
			headers: [
				{
					id: (item) => item.name,
					name: "values",
				},
			],
		});
		for (var point of points) {
			const date = _.clone(point.date);
			const headers = [] as SBTableColumn<any>[];

			if (chartsToShow.find((c: any) => c.name === "real")?.show) {
				headers.push({
					id: (item) => item[date].real,
					name: "real",
					key: "real" + date,
				});
			}

			if (chartsToShow.find((c: any) => c.name === "projected")?.show) {
				headers.push({
					id: (item) => item[date].projected,
					name: "projected",
					key: "projected" + date,
				});
			}

			columns.push({
				id: () => "",
				name: point.date,
				headers,
			} as SBTableColumn<any>);
		}
		return columns;
	}, []);

	const transposedRows = useMemo(() => {
		const rows = [] as any[];
		const props = getNewHeaders("All", "any").headers!;

		for (var prop of props) {
			const newObj = {
				name: prop.name,
				tip: prop.tip,
			};

			for (var point of points) {
				let real = prop.id(point.real) as string;
				let projected = prop.id(point.projected) as string;

				if (
					point.date === "All" &&
					(prop.name == t("onHand") ||
						prop.name === t("onOrder") ||
						prop.name === t("csl"))
				) {
					real = "---";
					projected = "---";
				} else {
					real = !!prop.formatter
						? //@ts-ignore
						  prop.formatter!(real)
						: real;
					projected = !!prop.formatter
						? //@ts-ignore
						  prop.formatter(projected)
						: projected;
				}

				//@ts-ignore
				newObj[point.date] = {
					real,
					projected,
				};
			}
			rows.push(newObj);
		}
		return rows;
	}, []);

	return (
		<SBBox className={clsx(classes.container)}>
			<SBSimpleTable columns={transposedColumns} rows={transposedRows} />
		</SBBox>
	);
};

interface SimulationSummarizationsInfoProps {
	item: ISimulationRow;
	simulationPeriod: SimulationPeriod;
	pointAsTable?: boolean;
	chartsToShow: any;
}
