import { TimePeriod } from "domain/DatePickerConfig/domain/enums/TimePeriod";
import { isNumber } from "lodash";
import { DateTime } from "luxon";
import { SBBox, SBGrid, SBTypography } from "modules/Commons";
import SBChartMonthlyXAxis from "modules/Commons/SBChart/SBChartMonthlyXAxis";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Area,
	AreaChart,
	CartesianGrid,
	Label,
	ReferenceDot,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { useSystemTheme } from "themes/base";
import { formatIntegerShort } from "utils/formatters";
import SBChartTooltipRow from "views/Commons/SBChartTooltipRow";

export default ({ dataPoints, dateFilter }: PlanDeviationChart) => {
	const { t } = useTranslation();
	const theme = useSystemTheme();

	const [showPlanned, setShowPlanned] = useState(true);
	const [showReal, setShowReal] = useState(true);
	const [, setGrouping] = useState(TimePeriod.DailyUTC);
	const [dateLimits, setDateLimits] = useState<any>([]);
	const [lastRealDay, setLastRealDay] = useState<any>({ date: "" });
	const [max, setMax] = useState(800);

	const arrayedDatapoints = useMemo(() => {
		const transformed = Object.keys(dataPoints).map((key) => {
			return {
				date: key,
				range: [
					dataPoints[key].min ?? 0,
					dataPoints[key].max,
				],
				...dataPoints[key],
				real: dataPoints[key].real,
				forecast: dataPoints[key].forecast,
			};
		});
		return transformed;
	}, [dataPoints]);

	const averageDataPoint = () => {
		const dataPointsWithRealValues = arrayedDatapoints.filter(a => a.real !== null);

		return dataPointsWithRealValues.reduce((a, b) => a + b.real, 0) /
			dataPointsWithRealValues.length;
	}

	const buildLimits = useCallback(() => {
		const days = arrayedDatapoints.filter(
			(c: any) => DateTime.fromISO(c.date).day === 1
		);
		setDateLimits(days);
	}, []);

	useEffect(() => {
		buildLimits();
	}, []);

	return (
		<SBBox display="flex" flexDirection="column" width={1}>
			<SBGrid item xs={12} display="flex" justifyContent="end">
				<SBBox display="flex" alignItems="center" mb={1}>
					{dateFilter && dateFilter({ onUpdate: setGrouping })}
					<SBTypography
						onClick={() => setShowReal((c) => !c)}
						style={{
							opacity: showReal ? 1 : 0.3,
							cursor: "pointer",
						}}
						display="flex"
						alignItems="center"
					>
						<SBBox
							mr={0.5}
							style={{
								height: 15,
								width: 15,
								borderRadius: "100%",
								backgroundColor: theme.palette.real.main,
							}}
						/>
						{t("real")}
					</SBTypography>
					<SBTypography
						ml={3}
						onClick={() => setShowPlanned((c) => !c)}
						style={{
							opacity: showPlanned ? 1 : 0.3,
							cursor: "pointer",
						}}
						alignItems="center"
						display="flex"
					>
						<SBBox
							mr={0.5}
							style={{
								height: 15,
								width: 15,
								borderRadius: "100%",
								backgroundColor: `${theme.palette.projected.main}`,
							}}
						/>
						{t("projected")}
					</SBTypography>
				</SBBox>
			</SBGrid>
			<ResponsiveContainer height={200}>
				<AreaChart
					data={arrayedDatapoints}
					margin={{ top: 15, left: 0, right: 15 }}
				>
					{lastRealDay && (
						<>
							<ReferenceLine
								key={lastRealDay.date + "reference"}
								x={lastRealDay.date}
								stroke={theme.palette.primary.main}
								strokeWidth={2}
							>
								<Label
									position="top"
									fontWeight={700}
									fontSize={12}
									value={lastRealDay.date}
									fill={theme.palette.primary.main}
								/>
							</ReferenceLine>
							<ReferenceDot
								x={lastRealDay.date}
								r={5}
								y={max * 0.98}
								stroke="none"
								fill={theme.palette.primary.main}
							/>
						</>
					)}
					{dateLimits?.map((n: any) => (
						<ReferenceLine
							key={n.date + "reference"}
							x={n.date}
							stroke="#dddddddd"
							strokeDasharray="3 1"
						/>
					))}
					<ReferenceLine y={averageDataPoint()} stroke="#666" />
					<CartesianGrid
						vertical={false}
						stroke="#BDBDBD"
						strokeDasharray="6 3"
					/>
					{showPlanned && (
						<>
							<Area
								stroke={theme.palette.primary.main}
								dot={false}
								type="monotone"
								dataKey="forecast"
								fillOpacity={0}
								strokeWidth={2}
							/>
							<Area
								type="monotone"
								dataKey="range"
								fill={`${theme.palette.projected.main}22`}
								stroke="transparent"
							/>
						</>
					)}
					{showReal && (
						<Area
							dot={false}
							stroke={theme.palette.real.main}
							type="monotone"
							fillOpacity={0}
							dataKey="real"
							strokeWidth={2}
						/>
					)}

					<XAxis
						dataKey="date"
						type="category"
						interval={0}
						tickLine={false}
						tick={(props: any) => {
							return (
								<SBChartMonthlyXAxis
									payload={props.payload}
									firstDate={arrayedDatapoints[0].date}
									lastDate={
										arrayedDatapoints[
											arrayedDatapoints.length - 1
										].date
									}
									x={props.x}
									y={props.y}
								/>
							);
						}}
					/>
					<YAxis
						tickFormatter={formatIntegerShort}
						tickLine={false}
						axisLine={true}
					/>
					<Tooltip wrapperStyle={{ outline: "none" }} content={SBChartTooltipRow} />
				</AreaChart>
			</ResponsiveContainer>
		</SBBox>
	)
};

interface ForecastPoints {
	[key: string]: {
		error: number;
		forecast: number;
		max: number;
		min: number;
		real: number;
	};
}

export interface PlanDeviationChart {
	dataPoints: ForecastPoints;
	dateFilter?: (props: { onUpdate: (grouping: TimePeriod) => void }) => ReactNode;
}