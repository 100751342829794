import { SBTranslate } from "i18n";
import i18n from "i18next";
import { DateTime } from "luxon";

export function formatIntegerShort(v?: number) {
	return v != undefined && v != null && !isNaN(v) && isFinite(v)
		? Intl.NumberFormat(i18n.language, {
				compactDisplay: "short",
				notation: "compact",
		  }).format(v)
		: "--";
}

export function formatCurrency(v?: number) {
	return v != undefined && v != null && !isNaN(v) && isFinite(v)
		? Intl.NumberFormat(i18n.language, {
				style: "currency",
				currency: "BRL",
		  }).format(v)
		: "--";
}

export function formatCurrencyShort(v?: number) {
	return v != undefined && v != null && !isNaN(v) && isFinite(v)
		? Intl.NumberFormat(i18n.language, {
				compactDisplay: "short",
				notation: "compact",
				style: "currency",
				currency: "BRL",
		  }).format(v)
		: "--";
}

export function formatInteger(v?: number) {
	return v != undefined && v != null && !isNaN(v) && isFinite(v)
		? v?.systemFormat({
				maximumFractionDigits: 0,
				minimumFractionDigits: 0,
		  })
		: "--";
}

export function formatDecimal(v?: number) {
	return v != undefined && v != null && !isNaN(v) && isFinite(v)
		? `${v.systemFormat({
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
		  })}`
		: "--";
}

export function percentageFormatter(v?: number) {
	return v != undefined && v != null && !isNaN(v) && isFinite(v)
		? `${(v * 100).systemFormat({
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
		  })}%`
		: "--";
}

export const minmax = (min: string, max: string) =>
	`${SBTranslate("min")}: ${min} - ${SBTranslate("max")}: ${max}`;

export function getKeyByValue<T>(value: string | number, enumeration: any) {
	const indexOfS = Object.values(enumeration).indexOf(value as unknown as T);
	const key = Object.keys(enumeration)[indexOfS];
	return key;
}

export function getFlagsByEnum<T>(value: number, enumeration: any) {
	const indexOfS = Object.values(enumeration).filter(
		(c) => typeof c === "number"
	) as number[];

	const keys = indexOfS.reduce((a: any, b) => {
		if ((value & b) == b) return [...a, enumeration[b + ""]];
		return a;
	}, []);
	return keys as string[];
}

export function dateFormatter(value: string, format: string) {
	return DateTime.fromFormat(value, "yyyy-MM-dd").toFormat(format);
}
