import { Tooltip, TooltipProps } from "@mui/material";
import SBBox from "modules/Commons/SBLayouts/SBBox";
import { forwardRef } from "react";

export default forwardRef((props: SBTooltipProps, ref) => {
	const { children, ...otherProps } = props;
	return (
		<Tooltip ref={ref} {...otherProps}>
			<SBBox style={{ cursor: "help" }}>{children}</SBBox>
		</Tooltip>
	);
});

export interface SBTooltipProps extends TooltipProps {}
