import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	sbDatePicker: {
		minWidth: 334,
		maxWidth: 334,
		maxHeight: "82vh",
		"& form": { padding: 10, height: "100%" },
	},

	sbDatePickerRangeContainer: {
		textAlign: "right",
		height: "100%",
	},
}));
