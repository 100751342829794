import {
	Cell,
	RowSelectionState
} from "@tanstack/react-table";
import { IEntity } from "domain/Domain/Entities/IEntity";
import {
	ForwardedRef,
	forwardRef,
	useRef
} from "react";
import { useVirtual } from "react-virtual";
import { SBTableColumn } from "../models/TableColumn";
import { SBTableBody } from "./SBTableBody";
import { SBTableHeader } from "./SBTableHeader";
import SBTableToolbar from "./SBTableToolbar";
import { SBTableVirtualizedBody } from "./SBTableVirtualizedBody";
import useTableCSVExporter from "./services/useTableCSVExporter";
import useTableManager from "./services/useTableManager";
import { useTableSelectionProps } from "./services/useTableSelection";
import "./style.scss";

export const SBNewTable = forwardRef(
	(props: SBNewTableProps<any>, ref: ForwardedRef<SBNewTableRef>) => {
		const {
			onDataUpdated,
			title,
			startRows,
			defaultColumns,
			defaultFilter,
			onClick,
			onSelect,
			toolbarContent,
			disableToolbar,
			virtualize,
			hasSelection,
			fullWidth,
			containerHeight = 310,
		} = props;

		const tableContainerRef = useRef<HTMLDivElement>(null);

		const {
			manager: { table, rows, columns, toUpdateRefs },
			selection: { rowSelection },
			filter: { setExternalFilter, setGlobalFilter, setColumnFilters }
		} = useTableManager<IEntity>({ defaultColumns, hasSelection, startRows, onSelect, onDataUpdated, defaultFilter });

		const rowVirtualizer = useVirtual({
			parentRef: tableContainerRef,
			size: rows.length,
			overscan: 5,
		});

		useTableCSVExporter({ defaultColumns, ref, rows, rowSelection, title });

		return (
			<div className="sb-table p-2" style={{ position: "relative" }}>
				{!disableToolbar && (
					<SBTableToolbar
						title={title}
						rowCount={table.getRowModel().rows.length}
						selectedCount={Object.keys(rowSelection).length}
						columns={columns}
						toolbarContent={(c: any) => toolbarContent?.({
							...c,
							setColumnFilters,
							filterByColumnName: (key: string, filter: any) => {
								setColumnFilters(c => {
									if (filter == null)
										return c.filter(c => c.id != key);
									else
										return [...c, { id: key, value: filter }];
								});
							}
						})}
						handleSearch={(e) => setGlobalFilter(String(e))}
						handleExternalSearch={(e) => setExternalFilter(e)}
					/>
				)}
				<div
					ref={tableContainerRef}
					className="container"
					style={{
						height: `calc(100vh - ${containerHeight}px)`,
					}}
				>
					<table
						style={{
							width: fullWidth ? "100%" : "initial",
							minWidth: "100%",
						}}
					>
						<SBTableHeader
							headerGroups={table.getHeaderGroups()}
							reRenderRefs={toUpdateRefs}
						/>

						{virtualize ? (
							<SBTableVirtualizedBody

								rowVirtualizer={rowVirtualizer}
								rows={rows}
								onClick={onClick}
								reRenderRefs={toUpdateRefs}
							/>
						) : (
							<SBTableBody rows={rows} onClick={onClick} />
						)}
					</table>
				</div>
			</div>
		);
	}
);

interface SBNewTableProps<T extends IEntity> extends Omit<useTableSelectionProps<T>, 'data'> {
	title: string;
	containerHeight?: number;
	startRows: T[];
	defaultColumns: SBTableColumn<T>[];
	defaultFilter?: { id: string, value: any }[]
	onClick: (entity: Cell<T, any>) => void;
	onDataUpdated?: (v: T[]) => void;
	toolbarContent?: any;
	disableToolbar?: boolean;
	virtualize?: boolean;
	hasSelection?: boolean;
	fullWidth?: boolean;
}

export interface SBNewTableRef {
	exportCSV: (items: any[], selectedItems: any[]) => string;
	downloadCSV: () => void;
	selecteds: () => RowSelectionState;
}
