import { Tooltip } from "@mui/material";
import { t } from "i18next";
import { SBOutlinedButton, SBIcon, SBTypography, SBBox } from "modules/Commons";

export default ({ onClick }: SimulationCardActionsProps) => {
	return (
		<Tooltip title={`${t("showSummarization")}`}>
			<SBBox width={1}>
				<SBOutlinedButton fullWidth onClick={onClick}>
					<SBIcon icon="info" color="primary" size={15} />
					<SBTypography
						ml={0.5}
						fontWeight="500"
						textTransform="capitalize"
					>
						{`${t("detail_other")}`}
					</SBTypography>
				</SBOutlinedButton>
			</SBBox>
		</Tooltip>
	);
};

interface SimulationCardActionsProps {
	/** Action on click summarization */
	onClick: () => void;
}
