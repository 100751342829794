import { SBBox, SBCard, SBGrid, SBTypography } from "modules/Commons";
import SBThreeLevelGauge from "modules/Commons/SBDataDisplays/SBThreeLevelGauge";
import noDataToDisplay from "assets/noDataToDisplay.svg";
import { useMemo } from "react";
import { SBTranslate } from "i18n";

export default ({ stockout, risk }: ExplorationsListingProps) => {
	const formattedRisk = useMemo(
		() => `${(risk * 100).systemFormatAsPercentage()}%`,
		[risk]
	);
	return (
		<SBBox flexGrow={1} pt={1}>
			<SBCard>
				<SBTypography px={3} pt={3} fontWeight="bold">
					{SBTranslate("stockoutRisk")}
				</SBTypography>
				{isNaN(risk) ? (
					<SBBox pb={4} display="flex" justifyContent="center">
						<img src={noDataToDisplay} height="80px" />
					</SBBox>
				) : (
					<SBGrid container px={3} height={1} pb={3}>
						<SBGrid
							item
							xs={6}
							justifyContent="center"
							pt={2}
							display="flex"
							alignItems="center"
						>
							{SBTranslate("stockoutRiskText", {
								risk: formattedRisk,
								stockout,
							})}
						</SBGrid>
						<SBGrid
							item
							xs={6}
							justifyContent="center"
							alignItems="center"
							display="flex"
							flexDirection="column"
						>
							<SBThreeLevelGauge value={risk} />
						</SBGrid>
					</SBGrid>
				)}
			</SBCard>
		</SBBox>
	);
};

export interface ExplorationsListingProps {
	stockout: string;
	risk: number;
}
