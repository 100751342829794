import {
	SimulationCard,
	SimulationPeriodSummarizer,
} from "modules/Simulations";
import { useEffect, useState } from "react";

export default (props: any) => {
	const {
		rowsToShow,
		chartID,
		chartFilter,
		simulationPeriod,
		ignoreDateConstraint,
	} = props;

	const [displayResult, setDisplayResult] = useState(rowsToShow);

	const periodizePoints = () => {
		const toDisplayRows = rowsToShow.map((row: any) => ({
			...row,
			summarization: {
				...row.result,
				points: SimulationPeriodSummarizer.summarize(
					row.result,
					simulationPeriod
				),
			},
		}));
		setDisplayResult(toDisplayRows);
	};

	useEffect(() => periodizePoints(), [simulationPeriod]);

	return displayResult?.map((row: any, index: any) => (
		<SimulationCard
			key={row.id}
			cardIndex={index}
			item={row}
			isSubItem={false}
			chartFilter={chartFilter}
			chartID={chartID}
			ignoreDateConstraint={ignoreDateConstraint}
			simulationPeriod={simulationPeriod}
			{...props}
		/>
	));
};

export interface SimulationUnfoldProps {
	rowsToShow: any;
	chartID: any;
	chartFilter: any;
}
