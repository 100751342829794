import { FormControlLabel } from "@mui/material";
import { IDataFail } from "domain/Utils/models/IDataFail";
import { SBBox, SBSwitch, SBTypography } from "modules/Commons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default ({ fails, handleSearch }: SBDataProofToolbarContentProps) => {
	const { t } = useTranslation();
	const [globalFilter, setGlobalFilter] = useState<string>("");

	const countAlerts = (alert: string) => {
		let counter = 0;
		fails.forEach((fail) => {
			if (fail.status === alert) counter++;
		});
		return counter;
	};

	const toggleFilter = (filter: string) => {
		let newFilter = "";
		if (globalFilter.includes(filter)) {
			newFilter = globalFilter.replace(`${filter}|`, "");
		} else {
			newFilter = `${globalFilter}${filter}|`;
		}
		setGlobalFilter(newFilter);
		return newFilter;
	};

	useEffect(() => {
		handleSearch(globalFilter);
	}, [globalFilter]);

	return (
		<SBBox>
			<FormControlLabel
				label={
					<SBTypography
						style={{
							fontSize: "14px",
							fontWeight: "700",
							color: "#A7A7A7",
						}}
					>
						{`${t("error")} (${countAlerts("Fail")})`}
					</SBTypography>
				}
				control={
					<SBSwitch
						onChange={() => {
							let filter = toggleFilter("Fail|Falha");
							setGlobalFilter(filter);
						}}
					/>
				}
				style={{ margin: "0" }}
			/>
			<FormControlLabel
				label={
					<SBTypography
						style={{
							fontSize: "14px",
							fontWeight: "700",
							color: "#A7A7A7",
						}}
					>
						{`${t("warning")} (${countAlerts("Warning")})`}
					</SBTypography>
				}
				control={
					<SBSwitch
						onChange={() => {
							let filter = toggleFilter("Warning|Aviso");
							setGlobalFilter(filter);
						}}
					/>
				}
				style={{ margin: "0" }}
			/>
		</SBBox>
	);
};

interface SBDataProofToolbarContentProps {
	fails: IDataFail[];
	handleSearch: (filter: string) => void;
}
