import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	sbSimulationCardCSLIndicator: {
		border: "1px solid #eeeeeeaa",
		padding: "3px 10px",
		borderRadius: "4px",
		"& .icon": {
			transform: "rotate(-90deg)",
			marginTop: -6,
			marginLeft: -5,

			"&.rotate": {
				transform: "rotate(90deg)",
			},
		},
		"& .text": {
			color: theme.palette.gray4.main,
			"&.success": {
				color: theme.palette.success.main,
			},
			"&.error": {
				color: theme.palette.error.main,
			},
		},
	},
}));
