export enum StorageKey {
    SBFilters = "sbFilters",
    SBDate = "sbDate",
    Identity = "IDENTITY",
    SBMaintenence = "sbMaintenence",
    SBLanguage = "sbLanguage",
    SBDatePickerConfig = "sbDatePickerConfig",
    SBLogin = "sbLogin",
    Context = "context",
    RememberMe = "rememberMe"
}