/* eslint-disable import/no-anonymous-default-export */
import { SBTextField } from "modules/Commons";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ConfigGroupmentDivider from "views/ConfigsView/ConfigSideMenu/ConfigGroupmentDivider";
import ConfigTextField from "views/ConfigsView/ConfigSideMenu/ConfigTextField";
import { TipsContext } from "views/ConfigsView/ConfigTips";

export default ({ value, onChange, fetchPropError }: any) => {
	const { t } = useTranslation();
	const tipsContext = useContext(TipsContext);

	return (
		<>
			<ConfigGroupmentDivider
				title={`${t("chooseDay")}. ${t("chooseDayMonthExample")}`}
			/>
			<ConfigTextField
				section="supply"
				value={value}
				onChange={(e) => onChange(e.target.value, e)}
				helperText={
					fetchPropError
						? fetchPropError("reviewPeriodExpression")
						: undefined
				}
				onClick={() => {
					tipsContext.showTip("SupplyEachDayInMonth");
				}}
			/>
		</>
	);
};

