import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	dragAreaContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
		"&.onDrag": {
			backgroundColor: "rgba(0, 0, 0, 0.07)",
			opacity: 1,
		},
	},
}));
