import { DateTime } from "luxon";
import { Horizontal, Vertical } from "../../models/positions";
import { useSystemFilterContext } from "contexts/SystemFilterContext";

export const DatePositionVerifier = (
	item: { date: DateTime; label: string },
	validItems: Array<{ date: DateTime; label: string }>,
	startDate: DateTime | undefined,
	endDate: DateTime | undefined,
	minDate: DateTime | undefined,
	maxDate: DateTime | undefined,
	availableDates: string[] | undefined,
	lineSize: number
) => {
	const { lastDatabaseUpdate } = useSystemFilterContext();

	const isStartDate = (validDate: DateTime) => {
		return (
			validDate &&
			startDate &&
			startDate.toISODate() === validDate.toISODate()
		);
	};

	const isEndDate = (validDate: DateTime) => {
		return (
			validDate &&
			endDate &&
			endDate.toISODate() === validDate.toISODate()
		);
	};

	const isOnMiddleOfRange = (date: DateTime) =>
		date && endDate && endDate >= date && startDate && startDate <= date;

	const isLowerThanMinDate = (date: DateTime) =>
		!minDate || (minDate && minDate < date);

	const isGreaterThanMaxDate = (date: DateTime) =>
		!maxDate || (maxDate && maxDate > date);

	const isAnAvailableDate = (date: DateTime) => {
		if (date && availableDates && availableDates.length > 0) {
			if (!availableDates.includes(date.toISODate())) return false;
		}
		return true;
	};

	const isLastDataBaseUpdate = (date: DateTime) =>
		date && date.toISODate() === lastDatabaseUpdate?.toISODate();

	const checkItemsAround = (
		index: number,
		vertical: Vertical,
		horizontal: Horizontal
	) => {
		if (!endDate) return false;

		if ((index + 1) % lineSize === horizontal) {
			const lastItemIndex = index + vertical * lineSize;
			const lastItem = validItems[lastItemIndex];

			if (!lastItem) return true;

			if (
				lastItem &&
				startDate &&
				endDate &&
				!(
					(lastItem.date > startDate && lastItem.date < endDate) ||
					isEndDate(lastItem.date) ||
					isStartDate(lastItem.date)
				)
			)
				return true;
		}
		return false;
	};

	const checkAllDiagonals = (index: number) => {
		return {
			topRight: checkItemsAround(index, Vertical.Top, Horizontal.Right),
			topLeft: checkItemsAround(index, Vertical.Top, Horizontal.Left),
			bottomRight: checkItemsAround(
				index,
				Vertical.Bottom,
				Horizontal.Right
			),
			bottomLeft: checkItemsAround(
				index,
				Vertical.Bottom,
				Horizontal.Left
			),
		};
	};

	const checkAllDirections = (index: number) => {
		return {
			...checkAllDiagonals(index),
			isStartDate: isStartDate(item.date),
			isEndDate: isEndDate(item.date),
			isOnMiddleOfRange: isOnMiddleOfRange(item.date),
			isLowerThanMinDate: isLowerThanMinDate(item.date),
			isGreaterThanMaxDate: isGreaterThanMaxDate(item.date),
			isAnAvailableDate: isAnAvailableDate(item.date),
			isLastDataBaseUpdate: isLastDataBaseUpdate(item.date),
		};
	};

	return {
		checkItemsAround,
		checkAllDiagonals,
		checkAllDirections,
		isStartDate,
		isEndDate,
		isOnMiddleOfRange,
		isLowerThanMinDate,
		isGreaterThanMaxDate,
		isAnAvailableDate,
		isLastDataBaseUpdate,
	};
};
