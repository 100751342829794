import { createRoot } from "react-dom/client";
import { BaseLayout } from "./BaseLayout";
import "assets/style.scss";
import "./utils/extensions";
import "./domain/Domain/Services/Validator";
import "./domain/Domain/Services/GroupConfigValidator";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<BaseLayout />);
