import { useMixpanelContext } from "contexts/MixpanelContext";
import { useSystemFilterContext } from "contexts/SystemFilterContext";
import { IComposedFilter } from "domain/Utils";
import useArrayState from "hooks/useArrayState";
import { useDateStorageCache } from "hooks/useDateStorageCache";
import { useRouteFilters } from "hooks/useRouteFilters";
import { SBTranslate } from "i18n";
import { DateTime } from "luxon";
import { SBBox, SBGrid, SBLoading } from "modules/Commons";
import SBEmptyState from "modules/Commons/SBLayouts/SBEmptyState";
import { useSnackbar } from "modules/Commons/SBOverlays/SBSnackbar";
import { NotificationsRequester } from "modules/Notifications/services/NotificationsRequester";
import OnDemandConfirmation from "modules/Purchases/components/OnDemandConfirmation";
import { PurchaseDatePlaceholder } from "modules/Purchases/components/PurchaseDatePlaceholder";
import SBPurchaseTable from "modules/Purchases/components/SBPurchaseTable";
import { IPurchasePlan } from "modules/Purchases/models/IPurchasePlans";
import { PurchasesRequester } from "modules/Purchases/services/PurchasesRequester";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SBComposedFilter from "views/Commons/SBComposedFilter";
import SBContextDateInfo from "views/Commons/SBContextDateInfo";

export default () => {
	const { t } = useTranslation();
	const { error } = useSnackbar();

	const tableRef = useRef<any>();
	const { context, filtersContext, setFiltersContextState, buildContextSwitch, notCurrentContext } = useSystemFilterContext();
	const { getDateItem, setDateItem } = useDateStorageCache();
	const { emergencial } = useRouteFilters();
	const { trackExport } = useMixpanelContext();

	const [loading, setLoading] = useState<boolean>(true);
	const [availableDates, setAvailableDates] = useState<string[]>([]);
	const [dialogStatus, setDialogStatus] = useState<boolean>(false);
	const [filter, setFilter] = useState<IComposedFilter | undefined>(undefined);
	const [loadingExport, setLoadingExport] = useState<boolean>(false);
	const [rows, setRows, { hasAny }] = useArrayState<IPurchasePlan>([]);

	const downloadCSV = () => {
		setLoadingExport(true);
		trackExport({ date: getDateItem() });
		tableRef.current.downloadCSV({});
		setLoadingExport(false);
	};

	const getAvailableDates = async () => {
		const result = await NotificationsRequester.homeStatisticsDates();
		if (result) setAvailableDates(result)
	};

	const handleApply = (obj: IComposedFilter) => {
		obj.picker?.date && setDateItem(obj?.picker?.date?.toFormat("yyyy-MM-dd"))
		setLoading(true);
		if (obj.filter)
			setFiltersContextState(obj.filter);
		else
			setFiltersContextState([]);

		!buildContextSwitch && fetchProcess(async () => {
			return PurchasesRequester.fetchComposedFilteredRecomendation(obj).finally(() => { setLoading(false); });
		});

	};

	const handleOnDemandApply = (filter: IComposedFilter | undefined) => {
		PurchasesRequester.runOnDemand(filter ?? {} as IComposedFilter);
	};

	const fetchProcess = async (fetcher: () => Promise<IPurchasePlan[]>) => {
		try {
			setRows([]);
			const result = await fetcher();
			if (result.length > 0) setRows(result);
			else error(SBTranslate("noItemsFound"));
		} catch {
			error(t("invalidDate"));
		}
	};

	const emergecialFlag = useMemo(() => {
		return emergencial()
	}, [])

	useEffect(() => {
		if (context)
			handleApply({ filter: filtersContext?.items } as IComposedFilter);
	}, [context]);

	useEffect(() => {
		getAvailableDates();
	}, []);

	useEffect(() => {
		buildContextSwitch && setLoading(true);
	}, [buildContextSwitch])

	return (
		<SBBox height={1} pl={11}>
			<SBBox>
				<SBComposedFilter.Base onApply={handleApply}>
					<SBComposedFilter.Picker date={getDateItem() && DateTime.fromISO(getDateItem())} range={false} availableDates={availableDates} />
					<SBComposedFilter.Filter />
					<SBComposedFilter.Applicator
						applicatorActions={[
							{
								title: SBTranslate("OnDemandRecommendationTitle"),
								onClick: (c) => {
									setDialogStatus(true);
									setFilter(c);
								}
							},
						]}
					/>
					<SBComposedFilter.Actions
						disabled={!hasAny}
						loading={loadingExport}
						actions={[
							{
								icon: { name: "download" },
								title: SBTranslate("exportCSV"),
								onClick: downloadCSV,
							},
						]}
					/>
				</SBComposedFilter.Base>
			</SBBox>

			{notCurrentContext() ? <SBGrid container>
				<SBContextDateInfo context={DateTime.fromISO(getDateItem())} />
			</SBGrid> : <></>}

			<SBLoading loading={loading}>
				<SBEmptyState
					show={hasAny}
					emptyState={<PurchaseDatePlaceholder />}
				>
					<SBPurchaseTable
						title="suggestedPurchases"
						ref={tableRef}
						rows={rows}
						onDataUpdated={setRows}
						containerHeight={notCurrentContext() ? 365 : undefined}
						emergencialStatus={emergecialFlag}
					/>
				</SBEmptyState>
			</SBLoading>
			<OnDemandConfirmation
				open={dialogStatus}
				onConfirm={() => handleOnDemandApply(filter)}
				onClose={() => setDialogStatus(false)}
				onCancel={() => setDialogStatus(false)}
			/>
		</SBBox>
	);
};
