import SBDatePickerBase from "../SBDatePickerBase";
import { useMemo, useState, useCallback, useEffect } from "react";
import { DateTime } from "luxon";
import { DateRangeType } from "../../models/dateRangeType";

export const SBDatePickerDaily = (props) => {
	const [months] = useState([...Array(12).keys()].map((c) => c + 1));
	const range = props.endYear - props.startYear;
	const size = 1;
	const currentYear = DateTime.now().year;
	const currentMonth = DateTime.now().month;
	const [years, setYears] = useState(
		props.startYear && props.endYear
			? [...Array(range + 1).keys()].flatMap((c) => [
					...[...Array(size).keys()].map(
						(i) => props.endYear - c + i - size + 1
					),
			  ])
			: [2023, 2022, 2021, 2020, 2019, 2018, 2017]
	);
	const [selectedYear, setSelectedYear] = useState(
		// props.startYear && props.endYear
		// 	? [...Array(range + 1).keys()].flatMap((c) => [
		// 			...[...Array(size).keys()].map(
		// 				(i) => props.endYear - c + i - size + 1
		// 			),
		// 	  ])[0]
		// 	:
		currentYear
	);
	const [selectedMonth, setSelectedMonth] = useState(currentMonth);

	useEffect(() => {
		const yearsArray = [];
		for (let year = props.endYear; year >= props.startYear; year--) {
			yearsArray.push(year);
		}
		setYears(yearsArray);
		// setSelectedYear(yearsArray.length > 0 ? yearsArray[0] : currentYear);
	}, [props.startYear, props.endYear]);

	const selectedMonthYear = useCallback(() => {
		return DateTime.fromFormat(
			`${selectedMonth > 9 ? "" : "0"}${selectedMonth} ${selectedYear}`,
			"MM yyyy"
		);
	}, [selectedMonth, selectedYear]);

	const firstDayOfTheMonth = useCallback(() => {
		return selectedMonthYear().startOf("month").weekday;
	}, [selectedMonthYear]);

	const fillStartDays = useCallback(() => {
		if (firstDayOfTheMonth() !== 7) {
			const previousMonth = selectedMonthYear().plus({ months: -1 });
			return [...Array(firstDayOfTheMonth()).keys()]
				.map((c) => {
					return previousMonth.daysInMonth - c;
				})
				.reverse();
		}
		return [];
	}, [firstDayOfTheMonth, selectedMonthYear]);

	const validQuarters = useMemo(() => {
		const days = [...Array(selectedMonthYear().daysInMonth).keys()].map(
			(c) => {
				const date = DateTime.now()
					.set({
						month: selectedMonthYear().month,
						day: 1,
						year: selectedYear,
					})
					.plus({ days: c });

				return {
					date,
					label: date.day,
				};
			}
		);
		const validDays = [...fillStartDays(), ...days];
		const finalDays =
			35 - validDays.length > 0
				? [...Array(35 - validDays.length).keys()].map((i) => i + 1)
				: [];
		return [...validDays, ...finalDays];
	}, [selectedYear, selectedMonthYear, fillStartDays]);

	const daysOfTheWeek = () =>
		[...Array(7).keys()].map(
			(d) => DateTime.now().set({ weekday: d }).weekdayShort
		);

	return (
		<SBDatePickerBase
			type={DateRangeType.Daily}
			startAlign
			years={years}
			selectedYear={selectedYear}
			onChangeItem={setSelectedYear}
			months={months}
			selectedMonth={selectedMonth}
			onChangeMonth={setSelectedMonth}
			validItems={validQuarters}
			getText={(item) => item}
			lineSize={7}
			headers={daysOfTheWeek()}
			{...props}
		/>
	);
};
