import { useStyles } from "./style";
import { SBBox, SBTypography, SBExpandableField } from "modules/Commons";
import SBTooltip from "modules/Commons/SBDataDisplays/SBTooltip";
import { useTranslation } from "react-i18next";

export const SBTableToolbarActions = ({
	onSearch,
	searching,
	children,
}: any) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<SBBox
			data-testid="sb-table-toolbar-actions"
			className={classes.tableActions}
		>
			<SBBox ml={4}>
				<SBExpandableField
					icon={!searching ? "search" : "x"}
					placeholder={`${t("search")}...`}
					onChange={(e) => onSearch(e.target.value)}
				/>
				<SBTypography>{children}</SBTypography>
			</SBBox>
		</SBBox>
	);
};
