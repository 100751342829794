import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	container: {
		background: "rgba(235, 87, 87, 0.05)",
		border: "1px dashed #EB5757",
		boxSizing: "border-box",
		borderRadius: "8px",
	},
}));
