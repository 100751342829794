import { IOrder } from "domain/Inventory/Models/IOrder";
import useTableColumnBuilder from "hooks/useTableColumnBuilder";
import { SBTranslate } from "i18n";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

export const useOrdersColumnBuilder = () => {
	const { t } = useTranslation();
	const { add, build } = useTableColumnBuilder<IOrder>();

	add({
		id: () => "",
		name: SBTranslate("order_other"),
		headers: [
			{
				id: (i) => DateTime.fromISO(i.request).toFormat(t("dateFormat")),
				noWrap: true,
				align: "right",
				tip: "cycleRequestTip",
				name: SBTranslate("request")
			},
			{
				id: (i) => DateTime.fromISO(i.received).toFormat(t("dateFormat")),
				noWrap: true,
				align: "center",
				tip: "cycleDeliveryTip",
				name: SBTranslate("expected")
			},
			{
				id: (i) => i.quantity,
				noWrap: true,
				align: "center",
				tip: "initialOnOrderTip",
				name: SBTranslate("quantity")
			},
		],
	})

	return { headers: build() };
};