import { SBBox, SBLoading } from "modules/Commons";
import SBEmptyState from "modules/Commons/SBLayouts/SBEmptyState";
import { OptimizationRequester } from "modules/Optimizations/services/OptimizationRequester";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DistributionSummarization from "./DistributionSummarization";
import DistributionViewTable from "./DistributionViewTable";
import DistributionViewTitle from "./DistributionViewTitle";
import { useStyles } from "./style";
export default () => {
	const [items, setItems] = useState<any>([]);
	const [loading, setLoading] = useState<any>(false);
	const classes = useStyles();

	useEffect(() => {
		setLoading(true);
		OptimizationRequester.distribute([]).then((data) => {
			setItems(data.data);
			setLoading(false);
		});
	}, []);

	return (
		<SBBox className={classes.mainBox}>
			<DistributionViewTitle
				items={items}
				setItems={setItems}
				handleApply={() => {}}
				loading={loading}
				setLoading={setLoading}
			/>
			<SBLoading loading={loading}>
				<SBEmptyState show={items.length > 0} emptyState={<></>}>
					<SBBox>
						<DistributionSummarization items={items} />
						<DistributionViewTable items={items} />
					</SBBox>
				</SBEmptyState>
			</SBLoading>
		</SBBox>
	);
};
