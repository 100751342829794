import * as React from "react";
import TreeView from "@mui/lab/TreeView";
import { styled } from "@mui/material/styles";

export default styled((props) => <TreeView {...props} />)(() => ({
	padding: 0,
	width: "100%",
	margin: 0,
	overflowY: "auto",
	overflowX: "hidden",
}));
