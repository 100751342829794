import { TableCell, TableHead, TableRow, TableBody } from "@mui/material";
import { IEntity } from "domain/Domain/Entities/IEntity";
import {
	SBBox,
	SBButton,
	SBIcon,
	SBIconButton,
	SBOutlinedButton,
	SBTypography,
	SBSlideMenu,
	SBTable,
} from "modules/Commons";
import { InventoryConfigRequester } from "modules/InventoryConfig/services/InventoryConfigRequester";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SBSystemSearch from "views/Commons/SBSystemSearch";
import InventoryConfigTable from "./InventoryConfigTable";
import { useStyles } from "./style";

export default ({ open, onClose, onChooseConfig }: any) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const [selectedItems, setSelectedItems] = useState<IEntity[]>([]);
	const [inventoryConfig, setInventoryConfig] = useState<any>(null);
	const [configs, setConfigs] = useState<any>([]);
	const [loading, setLoading] = useState<any>(false);

	const fetchInventory = () => {
		setInventoryConfig(null);
		setLoading(true);
		InventoryConfigRequester.POST(`build`, {
			products: selectedItems?.filter((c: any) => c.type === "product"),
			stores: selectedItems?.filter((c: any) => c.type === "store"),
			categories: selectedItems?.filter(
				(c: any) => c.type === "category"
			),
		})
			.then((result) => {
				setConfigs(result.data);
				// setInventoryConfig(result.data.item1);
				// setErrors(result.data.item2);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const isValid = (item: any) => {
		return (
			item?.csl?.value &&
			item?.reviewPeriodExpression?.value &&
			item?.leadTime?.value
		);
	};

	const handleSearch = (items: IEntity[]) => {
		setSelectedItems(items);
	};

	return (
		<SBSlideMenu open={open}>
			<SBBox display="flex" alignItems="center" py={2}>
				<SBBox width="60px" ml={1} mr={1}>
					{inventoryConfig && (
						<SBIconButton onClick={() => setInventoryConfig(null)}>
							<SBIcon icon="chevron-left" />
						</SBIconButton>
					)}
				</SBBox>
				<SBBox width="100%" mr={2}>
					<SBSystemSearch
						solo
						fullWidth
						defaultItems={selectedItems}
						onChange={handleSearch}
					/>
				</SBBox>
				<SBBox ml="auto">
					<SBButton
						fullWidth
						disabled={!selectedItems}
						loading={loading}
						onClick={() => fetchInventory()}
					>
						<SBIcon icon="search" color="white" />
						<SBTypography ml={2} textTransform="capitalize">
							{t("search")}
						</SBTypography>
					</SBButton>
				</SBBox>
				<SBBox ml={2} mr={4}>
					<SBOutlinedButton fullWidth onClick={onClose}>
						<SBTypography textTransform="capitalize" py={0.3}>
							{t("close")}
						</SBTypography>
					</SBOutlinedButton>
				</SBBox>
			</SBBox>
			<>
				{inventoryConfig ? (
					<SBBox>
						<InventoryConfigTable
							onChooseConfig={onChooseConfig}
							inventoryConfig={inventoryConfig?.item2}
							isValidConfig={isValid(inventoryConfig?.item2)}
							inventory={inventoryConfig?.item1}
							errors={inventoryConfig?.item3}
							onClose={undefined}
						/>
					</SBBox>
				) : (
					<SBTable
						size="small"
						className={classes.sbConfigFinderTable}
					>
						<TableHead>
							<TableRow>
								<TableCell>
									<SBTypography py={1} fontWeight="700">
										{t("product")}
									</SBTypography>
								</TableCell>
								<TableCell>
									<SBTypography py={1} fontWeight="700">
										{t("store")}
									</SBTypography>
								</TableCell>
								<TableCell align="center">
									<SBTypography py={1} fontWeight="700">
										{t("hasConfig")}
									</SBTypography>
								</TableCell>
								<TableCell align="center">
									<SBTypography py={1} fontWeight="700">
										{t("config")}
									</SBTypography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{configs &&
								configs.map((config: any) => (
									<TableRow>
										<TableCell>
											({config?.item1?.product?.clientID})
											{config?.item1?.product?.name}
										</TableCell>
										<TableCell>
											({config?.item1?.store?.clientID})
											{config?.item1?.store?.name}
										</TableCell>
										<TableCell align="center">
											<SBIcon
												icon={
													isValid(config?.item2) &&
														config?.item3?.length === 0
														? "check"
														: "x"
												}
												color={
													isValid(config?.item2) &&
														config?.item3?.length === 0
														? "success"
														: "error"
												}
											/>
										</TableCell>
										<TableCell align="center">
											<SBBox
												style={{ cursor: "pointer" }}
												onClick={() =>
													setInventoryConfig(config)
												}
											>
												<SBIcon
													size={15}
													icon="chevron-right"
												/>
											</SBBox>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</SBTable>
				)}
			</>
		</SBSlideMenu>
	);
};
