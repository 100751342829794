import { SBCheckbox } from "modules/Commons";
import SBTableCell from "../SBTableCell";

export default ({
	indeterminate,
	checked,
	style,
	onChange,
	disabled,
	onClick,
}: SBCheckboxTableCellProps) => {
	return (
		<SBTableCell padding="checkbox" style={style} onClick={onClick}>
			<SBCheckbox
				tabIndex={-1}
				data-testid="sb-checkbox-table-cell"
				color="primary"
				onChange={onChange}
				indeterminate={indeterminate}
				checked={checked}
				disabled={disabled}
			/>
		</SBTableCell>
	);
};

export interface SBCheckboxTableCellProps {
	/** Flag to show if checkbox is indeterminate instead of checked */
	indeterminate?: boolean;
	/** Flag to show value of checkbox */
	checked?: boolean;
	/** Flag to disable field */
	disabled?: boolean;
	/** Additional styles */
	style?: {};
	/** On change event */
	onChange?: () => void;
	/** On click event */
	onClick?: () => void;
}
