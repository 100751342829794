import { CssBaseline, ThemeProvider } from "@mui/material";
import MixpanelContext from "contexts/MixpanelContext";
import { requester } from "hooks/useRequest";
import useRouterHistory from "hooks/useRouterHistory";
import { SBSnackbar } from "modules/Commons";
import { ReactNode, createContext, useState } from "react";
import { Router } from "react-router-dom";
import { BaseTheme } from "themes/base";
import { BaseView } from "views/Commons/BaseView";
import { MaintenanceView } from "views/MaintenanceView";

export const BaseLayout = () => {
	const theme = BaseTheme();

	return (
		<MixpanelContext>
			<MaintenanceProvider>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<SBSnackbar>
						<Router history={useRouterHistory}>
							<BaseView />
						</Router>
					</SBSnackbar>
				</ThemeProvider>
			</MaintenanceProvider>
		</MixpanelContext>
	);
};

const MaintenanceContext = createContext({
	maintenance: true,
});

const MaintenanceProvider = ({ children }: { children: ReactNode }) => {
	requester.onMaintenance = () => {
		setMaintenence(true);
	};

	const [maintenance, setMaintenence] = useState(false);

	if (maintenance) return <MaintenanceView />;

	return (
		<MaintenanceContext.Provider value={{ maintenance }}>
			{children}
		</MaintenanceContext.Provider>
	);
};
