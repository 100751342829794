import { SBBox, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";

export default ({
	icon,
	statusLabel,
	statusCount,
}: TransferenceStatusCountInfoProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<SBBox display="flex">
			<img className={classes.modalInfoIcon} src={icon} />
			<SBBox display="flex" alignItems="center">
				<SBTypography variant="body1" fontWeight="500" mr={0.5}>
					{`${t(statusLabel)}:`}
				</SBTypography>
				<SBTypography variant="body1" fontWeight="500">
					{statusCount}
				</SBTypography>
			</SBBox>
		</SBBox>
	);
};

interface TransferenceStatusCountInfoProps {
	icon: string;
	statusLabel: string;
	statusCount: number;
}
