import { IEntity } from "domain/Domain";
import { IComposedFilter } from "domain/Utils";
import { useRequest } from "hooks/useRequest";
import { DateTime } from "luxon";
import { IPurchasePlan } from "modules/Purchases/models/IPurchasePlans";
import { TransferencePlan } from "modules/Transferences/models/transferencePlan";

export const OptimizationRequester = (() => {
	const request = useRequest("Optimization");
	return {
		purchase: (items: any, end: DateTime) => {
			const filterItems = items ?? [];
			return request
				.POST(`purchase/${end.toFormat("yyyy-MM-dd")}`, {
					products: filterItems.filter(
						(c: any) => c.type === "product"
					),
					categories: filterItems.filter(
						(c: any) => c.type === "category"
					),
					stores: filterItems.filter((c: any) => c.type === "store"),
				})
				.then((c) => {
					return (
						c.data && c.data.length > 0 ? c.data : []
					) as IPurchasePlan[];
				});
		},
		distribute: (filterItems: any) => {
			return request.POST(`distribute`, {
				products: filterItems.filter((c: any) => c.type === "product"),
				categories: filterItems.filter(
					(c: any) => c.type === "category"
				),
				stores: filterItems.filter((c: any) => c.type === "store"),
			});
		},
		getTransferencePlans: (filter: IEntity[]) => {
			const products = filter?.filter((c: any) => c.type === "product");
			const categories = filter?.filter(
				(c: any) => c.type === "category"
			);
			const stores = filter?.filter((c: any) => c.type === "store");

			return request
				.POST(
					`transference`,
					{
						products,
						categories,
						stores,
					} || {}
				)
				.then((response: any) => {
					return (response.data as TransferencePlan[]).map((plan) => {
						return {
							...plan,
							plans: plan.plans.map((child) => ({
								...child,
								sourceInventory: plan.inventory,
							})),
						};
					});
				});
		},
	};
})();
