import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	popper: {
		marginTop: "24px !important",
		zIndex: "10000",
	},
	notificationsContainer: {
		width: "380px",
		padding: "0 16px 8px",
	},
	notificationsButtonSeeAll: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		marginTop: "8px",
	},
	notificationIcon: {
		position: "relative",
	},
	notificationInfo: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		top: 2,
		left: 4,
		zIndex: 10,
		width: "18px",
		height: "18px",
		fontFamily: theme.typography.fontFamily,
		fontSize: "10px",
		lineHeight: "10px",
		fontWeight: "700",
		borderRadius: "50%",
		background: theme.palette.darkPurple.main,
		color: theme.palette.darkPurple.contrastText,

		"&.counterLimit": {
			fontSize: "8px",
			width: "20px",
			height: "20px",
			top: -1,
			left: 2,
		},
	},
}));
