import {
	createColumnHelper,
	DisplayColumnDef
} from "@tanstack/react-table";
import { SBTableColumn } from "../../models/TableColumn";
import { SBEditableColumn } from "../SBEditableColumn";
import { buildSelectColumn } from "./buildSelectColumn";
import { fetchColumnSize } from "./fetchColumnSize";

export function columnsAdapter<T>({
	columns,
	rows,
	hasSelection,
}: ColumnsAdapterProps<T>) {
	const columnHelper = createColumnHelper<T>();
	const minSizes = fetchColumnSize({ columns, rows });

	const selectableColumn = hasSelection ? [buildSelectColumn()] : [];

	return columns.map((column, index: number) => {
		let columns = column.headers?.map((header) => {
			return columnHelper.accessor(header.id, {
				id: header?.key,
				header: header.name,
				size: minSizes.get(header.key!),
				tip: header.tip,
				align: header.align,
				fixed: header.fixed,
				disabled: header.disabled,
				styleFn: header.styleFn,
				prepend: header.prepend,
				maxSize: header.maxWidth,
				filterFn: header.filterFn ?? "fuzzy" as any,
				enableHiding: header.hide,
				enableGrouping: header.enableGrouping,
				groupByTip: header.groupByTip,
				aggregationFn: header.aggregationFn,
				aggregatedCell: header.aggregatedCell,
				hideToggle: header.hideToggle,
				cell: header.editable
					? SBEditableColumn
					: (info: any) => {
						const row = info?.row?.original;
						const value = info.getValue() as any;
						const funcStyle = header?.styleFn
							? header?.styleFn(row)
							: undefined;

						return (
							<span
								style={{
									...header.style,
									width: header.width,
									//@ts-ignore
									...funcStyle,
								}}
							>
								{header.formatter
									? (header as any).formatter(value)
									: value}
							</span>
						);
					},
			} as DisplayColumnDef<T, unknown>);
		});

		if (index === 0) columns = [...selectableColumn, ...columns!];

		return columnHelper.group({
			header: column.name,
			hideToggle: column.hideToggle,
			cell: (info: any) => info.getValue(),
			columns,
		} as any);
	});
}

interface ColumnsAdapterProps<T> {
	columns: SBTableColumn<T>[];
	rows: T[];
	hasSelection?: boolean;
}
