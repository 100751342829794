import { SBTypography } from "modules/Commons";

export default ({ title }: ConfigGroupmentDivider) => {
	return (
		<SBTypography title="body" fontWeight="700" mb={2} color="gray2.main">
			{title}
		</SBTypography>
	);
};

export interface ConfigGroupmentDivider {
	title: string | any;
}
