import { useStyles } from "./style";
import clsx from "clsx";
import SBTableCell from "../../../SBTableCells/SBTableCell";

/** Group level of headers */
export default ({
	name,
	colSpan,
	selectable,
	isFirst,
	align = "center",
}: SBTableHeaderGroupProps) => {
	const classes = useStyles();
	const checkboxCol = isFirst ? +(selectable ?? 0) : 0;

	return (
		<SBTableCell
			align={align}
			data-testid="sb-table-header-group"
			colSpan={(colSpan ?? 0) + checkboxCol}
			className={clsx(classes.sbTableHeaderGroup)}
		>
			{name}
		</SBTableCell>
	);
};

export interface SBTableHeaderGroupProps {
	/** Name to display */
	name?: string;
	/** Colspan size of the group */
	colSpan?: number | 0;
	/** Used to check if the first group will have +1 on the colSpan */
	selectable?: boolean;
	/** See if the group is the first so it can add +1 on the colSpan */
	isFirst?: boolean;
	/** Alignment of the text inside cell */
	align?: "center" | "right" | "left";
}
