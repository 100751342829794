import { useLocation } from "react-router-dom";
import SBLink from "modules/Commons/SBRoutes/SBLink";

/** Leftbar Icon Button with Link */
export default ({ to, children }: SBActiveLinkProps) => {
	const { search } = useLocation();

	return <SBLink to={to + search}>{children}</SBLink>;
};

export interface SBActiveLinkProps {
	/** Route that will be redirected TO when clicked */
	to: string;
	/** Children */
	children?: JSX.Element;
}
