import { TextField, InputAdornment } from "@mui/material";
import { SBBox, SBTypography } from "modules/Commons";
import { Controller, useFormContext } from "react-hook-form";

export default (props: SBTextFieldProps) => {
	const {
		titleVariant,
		title,
		titleTransform,
		endAdornmentChild,
		InputProps,
		disabled,
		blockActions = false,
		width = 1,
		onChange,
		onClick,
		onBlur = () => { },
		type = "text",
		prop,
		inputProps,
		...otherProps
	} = props;

	const { register, onAnyBlur, control } = (useFormContext() ?? {}) as any;

	const { onChange: onChangeForm, ...hasProp } = !!prop
		? register(prop)
		: ({} as any);

	const buildedOnChange = (v: any) => {
		if (type === "integer") {
			let value = v.target.value;
			value = value.replace(/\D/g, "");
			v.target.value = value;
		}
		if (type === "positiveNumber") {
			let value = v.target.value;
			value = value.replace(/[^\d,.]/g, "");
			v.target.value = value;
		}

		onChangeForm && onChangeForm(v);
		onChange && onChange(v);
	};

	return (
		<SBBox
			display="flex"
			flexDirection="column"
			mb={title ? 2 : 0}
			width={width}
			onClick={onClick}
		>
			{title ? (
				<SBTypography
					mb={1}
					fontWeight="500"
					color="gray3.main"
					variant={titleVariant || "body1"}
					textTransform={titleTransform || "capitalize"}
				>
					{title}
				</SBTypography>
			) : null}
			{!disabled ? (
				<TextField
					{...otherProps}
					type={type === "integer" ? "text" : type}
					size="small"
					variant="outlined"
					autoComplete="off"
					disabled={blockActions}
					InputProps={{
						...InputProps,
						...hasProp,
						inputProps: {
							style:
								type !== "number" &&
									type !== "integer" &&
									type !== "positiveNumber"
									? { textAlign: "left" }
									: { textAlign: "right" },
							step: "any",
							...inputProps,
						},
						form: {
							autoComplete: "off",
						},
						endAdornment: endAdornmentChild ? (
							<InputAdornment position="end">
								{endAdornmentChild as JSX.Element}
							</InputAdornment>
						) : null,
						onChange: buildedOnChange,
						onBlur: (v: any) => {
							onBlur(v);
							onAnyBlur && onAnyBlur(v);
						},
					}}
				/>
			) : (
				<SBBox
					p={1}
					ml="auto"
					display="flex"
					width={width}
					borderBottom="1px solid rgb(0,0,0, .1)"
				>
					<SBTypography ml={type === "text" ? "none" : "auto"} mr={1}>
						{otherProps.value}
					</SBTypography>
					<SBTypography color="gray3.main">
						{endAdornmentChild as JSX.Element}
					</SBTypography>
				</SBBox>
			)}
		</SBBox>
	);
};

export interface SBTextFieldProps {
	endAdornmentChild?: Object | JSX.Element;
	titleTransform?: "capitalize" | "uppercase" | "lowercase";
	placeholder?: string | undefined;
	titleVariant?: any;
	error?: any;
	endAdornment?: Object;
	helperText?: string;
	title?: any;
	value?: any;
	onChange?: (v: any) => any;
	onClick?: (v: any) => any;
	onBlur?: (v: any) => any;
	InputProps?: any;
	disabled?: boolean;
	blockActions?: boolean;
	type?: "text" | "number" | "integer" | "positiveNumber" | "password";
	width?: string | number;
	className?: any;
	inputProps?: any;
	prop?: string;
}
