import { Divider } from "@mui/material";
import { IConfigCSVErrors } from "domain/Config/Models/IConfigCSVErrors";
import { SBTranslate } from "i18n";
import {
	SBBox,
	SBFileUploader,
	SBIcon,
	SBOutlinedButton,
	SBTypography,
} from "modules/Commons";
import { useSnackbar } from "modules/Commons/SBOverlays/SBSnackbar";
import { GroupConfigRequester } from "modules/GroupConfig/services/GroupConfigRequester";
import { useEffect, useState } from "react";
import ConfigUploadingState from "./ConfigLoadingState";
import ConfigUploadActions from "./ConfigUploadActions";
import ConfigUploadFinishedState from "./ConfigUploadFinishedState";
import ConfigUploaderErrors from "./ConfigUploaderErrors";
import ConfigUploaderTips from "./ConfigUploaderTips";
import { useStyles } from "./style";
import clsx from "clsx";
import { useSystemTheme } from "themes/base";

export default (props: ConfigUploaderHelperProps) => {
	const { configuringCron, setConfiguringCron, setIsConcluded } = props;
	const classes = useStyles();
	const theme = useSystemTheme();
	const { error } = useSnackbar();

	const [currentState, setCurrentState] = useState("onUpload");

	const [fileName, setFileName] = useState("");
	const [storeGuid, setStoreGuid] = useState<string>();
	const [uploadErrors, setUploadErrors] = useState<IConfigCSVErrors[]>([]);

	const onUpload = (value: string, type: string, lang: string) => {
		startLoading();
		GroupConfigRequester.importCSV(value, lang)
			.then((f) => {
				if (f.errors.length > 0) {
					setUploadErrors(f.errors)
					startError();
				} else {
					setStoreGuid(f.guid);
					startFinish();
				}
			})
			.catch(() => {
				error(SBTranslate("uploadFailure"));
				startUpload();
			});
	};

	const handleIncorrectType = () => error(SBTranslate("mustBeACSV"));

	const handleConclude = () => {
		startSaving();
		GroupConfigRequester
			.saveCSV(storeGuid!)
			.then(() => {
				startSaved();
				setIsConcluded(true);
			});
	}

	const startLoading = () => setCurrentState("onLoading");
	const startUpload = () => setCurrentState("onUpload");
	const startFinish = () => setCurrentState("onFinish");
	const startError = () => setCurrentState("onError");
	const startSaving = () => setCurrentState("onSaving");
	const startSaved = () => setCurrentState("onSaved");

	const fetchState = (state: string) => {
		switch (state) {
			case "onUpload":
				return <SBFileUploader onUploadRaw={onUpload} setFileName={setFileName} handleIncorrectType={handleIncorrectType} />;
			case "onFinish":
				return <ConfigUploadFinishedState text="validationWithNoErrors" />
			case "onLoading":
				return <ConfigUploadingState text="waitForCSV" />
			case "onError":
				return <ConfigUploaderErrors errors={uploadErrors} />
			case "onSaving":
				return <ConfigUploadingState text="waitForCSVSaving" />
			case "onSaved":
				return <ConfigUploadFinishedState text="finishedSaving" />
		}
	}

	useEffect(() => {
		setIsConcluded(false);
	}, [])

	return (
		<SBBox className={classes.uploaderContainer}>
			<SBBox className={classes.uploaderSideContent}>
				<ConfigUploaderTips />
				<SBBox className={classes.configCronButtonContainer}>
					<SBBox className={classes.configCronButtonTitle}>
						<SBIcon icon="settings" size={22} color={theme.palette.darkPurple.main} />
						<SBTypography variant="body1" fontWeight="500" color="darkPurple.main" ml={1}>
							{SBTranslate("configureCron")}
						</SBTypography>
					</SBBox>
					<SBOutlinedButton className={classes.configCronButton} onClick={() => setConfiguringCron(!configuringCron)}>
						<SBTypography fontWeight="700" color="primary.main" py={1} textTransform="none">
							{configuringCron ? SBTranslate("closeConfiguration") : SBTranslate("openConfiguration")}
						</SBTypography>
					</SBOutlinedButton>
				</SBBox>
			</SBBox>
			<Divider orientation="vertical" />
			<SBBox className={classes.uploaderContent}>
				<SBBox className={clsx(classes.uploaderMainContent, currentState === "onUpload" && "onUpload")}>
					{fetchState(currentState)}
				</SBBox>
				<ConfigUploadActions
					currentState={currentState}
					restartUpload={startUpload}
					fileName={fileName}
					onConclude={handleConclude}
				/>
			</SBBox>
		</SBBox>
	);
};

interface ConfigUploaderHelperProps {
	configuringCron: boolean;
	setConfiguringCron: (v: boolean) => void
	setIsConcluded: (v: boolean) => void
}
