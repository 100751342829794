import { IInventoryStatisticsSummary } from "domain/Inventory";
import {
	SBBox,
	SBCard,
	SBOutlinedButton,
	SBTypography,
} from "modules/Commons";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSystemTheme } from "themes/base";
import { SBInventoryTitle } from "modules/Inventory/components";
import SBEmptyState from "modules/Commons/SBLayouts/SBEmptyState";
import SupplyOptimizationEmptyState from "views/HomeViews/HomeEmptyState/SupplyOptimizationEmptyState";

export default ({
	quantity,
	title,
	prepend,
	label,
	showAll,
	name,
	items = [],
	formatter = (c) => c,
}: SBInventoryTableTopItemsProps) => {
	const { t } = useTranslation();
	const theme = useSystemTheme();
	return (
		<SBCard style={{ height: "100%", minHeight: "617px" }}>
			<SBBox
				p={3}
				pb={1}
				display="flex"
				flexDirection="column"
				height={1}
			>
				<SBBox display="flex" alignItems="center">
					{prepend && <SBBox mr={1}>{prepend}</SBBox>}
					<SBTypography variant="h5" fontWeight="700" color="gray2.main">
						{title}
					</SBTypography>
				</SBBox>
				<SBBox
					width={1}
					px={1}
					display="flex"
					justifyContent="space-between"
					mt={1}
				>
					<SBTypography
						px={1}
						width={0.7}
						variant="body2"
						textTransform="uppercase"
						color="gray4.main"
					>
						{t("name")}
					</SBTypography>
					<SBTypography
						width={0.3}
						variant="body2"
						textTransform="uppercase"
						color="gray4.main"
						textAlign="right"
					>
						{t(label)}
					</SBTypography>
				</SBBox>
				<SBEmptyState
					emptyState={
						<SBBox my="auto" py={5}>
							<SupplyOptimizationEmptyState label={name.toLocaleLowerCase()} />
						</SBBox>
					}
					show={items.length > 0}
				>
					{[...new Array(items.length).keys()].map((index) => {
						const key = items[index];
						return (
							<SBBox
								p={1}
								display="flex"
								alignItems="center"
								borderBottom={
									index < quantity - 1
										? `1px solid ${theme.palette.gray5.main}`
										: undefined
								}
							>
								<SBInventoryTitle
									hideBorder
									item={key?.inventory}
								/>
								<SBTypography
									textAlign="right"
									width={0.3}
								>
									{items && key
										? //  @ts-ignore
										formatter(key)
										: "-"}
								</SBTypography>
							</SBBox>
						);
					})}
				</SBEmptyState>
				{showAll && items.length > 0 && (
					<SBBox p={2} pt={0} mt="auto">
						<SBOutlinedButton fullWidth onClick={showAll}>
							<SBTypography textTransform="none">
								{t("showAllItems")}
							</SBTypography>
						</SBOutlinedButton>
					</SBBox>
				)}
			</SBBox>
		</SBCard>
	);
};

interface SBInventoryTableTopItemsProps {
	quantity: number;
	items: IInventoryStatisticsSummary[];
	title: ReactNode;
	label: string;
	name: string;
	formatter?: (v?: string | number) => any;
	prepend: ReactNode;
	showAll?: () => void;
}
