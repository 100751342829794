import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	topbar: {
		boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.08) !important",
		backgroundColor: "white",
	},
	logo: {
		cursor: "pointer",
	},
}));
