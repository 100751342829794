import { IAccordionItem } from "modules/InventoryStatistics/domain/IAccordionItem";
import { useState } from "react";
import SBInventoryAccordionTab from "../SBInventoryAccordionTab";
import { AccordionContainer } from "./style";

export default ({ items }: SBInventoryAccordionProps) => {
	const [openedState, setOpenedState] = useState(0);

	const itemsToShow = items?.filter((i) => i.showItem);

	return (
		<AccordionContainer>
			{itemsToShow.map((item, index) =>
				<SBInventoryAccordionTab
					key={item.icon}
					item={item}
					index={index}
					countOfTabs={itemsToShow.length}
					isOpen={openedState == index}
					onLabelClick={setOpenedState}
				/>
			)}
		</AccordionContainer>
	);
};

interface SBInventoryAccordionProps {
	/** Items to show on the accordion */
	items: IAccordionItem[];
}