import explorationEmptyState from "assets/explorationEmptyState.svg";
import { SBBox, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";

export default () => {
	const { t } = useTranslation();
	return (
		<SBBox
			id="exploration-emptystate"
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height="70vh"
		>
			<img height={200} src={explorationEmptyState} alt="" />

			<SBTypography
				mt={2}
				variant="h5"
				color="darkPurple.main"
				fontWeight="700"
				textAlign="center"
			>
				{t("explorationEmptyStateTitle")}
			</SBTypography>

			<SBTypography mt={2} textAlign="center" variant="body1" width={0.3}>
				{t("explorationEmptyStateSubTitle")}
			</SBTypography>
		</SBBox>
	);
};
