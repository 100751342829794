import { INotification } from "domain/Utils/models/INotification";
import { useRequest } from "hooks/useRequest";

export const SystemNotificationsRequester = (() => {
	const request = useRequest("systemNotification");

	return {
		fetchNotifications: async (skip: number, take: number) => {
			return request
				.GET(`notifications/${skip}/${take}`)
				.then((result) => {
					return result.data as INotification[];
				});
		},
		fetchNotificationsCount: async () => {
			return request.GET("notifications/count").then((result) => {
				return result.data as number;
			});
		},
		fetchNotSeenCount: async () => {
			return request.GET("notifications/notSeen").then((result) => {
				return result.data as number;
			});
		},
	};
})();
