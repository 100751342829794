import { StorageKey } from "domain/Utils"

export default (() => {
    const getRememberMe = (): boolean => JSON.parse(localStorage.getItem(StorageKey.RememberMe) as string) || false;
    const setRememberMe = (rememberMe: | boolean): void => localStorage.setItem(StorageKey.RememberMe, rememberMe.toString());
    const storage = () => getRememberMe() ? localStorage : sessionStorage;

    return {
        getRememberMe,
        setRememberMe,
        storage
    }
})