import { DateTime } from "luxon";
import { SBTableColumn } from "modules/Commons/SBDataDisplays/SBTable/models/TableColumn";
import { useTranslation } from "react-i18next";

export default function useTableColumnBuilder<T>() {
	const { t } = useTranslation();
	let columns: SBTableColumn<T>[] = [];

	const clear = () => {
		columns = [];
	};

	const add = (column: SBTableColumn<T>) => {
		columns.push(column);
	};

	const decimalPercentageFormatter = (value: number) => {
		return value != undefined && value != null && !isNaN(value)
			? `${(value * 100).systemFormat({
				maximumFractionDigits: 2,
				minimumFractionDigits: 2,
			})}%`
			: "--%";
	};

	const decimalPercentageNoDecimalFormatter = (value: number) => {
		return value != undefined && value != null && !isNaN(value)
			? `${(value * 100).systemFormat({
				maximumFractionDigits: 0,
				minimumFractionDigits: 0,
			})}%`
			: "--%";
	};

	const percentageDecimalFormatter = (value: number) => {
		return value != undefined && value != null && !isNaN(value)
			? `${value.systemFormat({
				maximumFractionDigits: 2,
				minimumFractionDigits: 2,
			})}%`
			: "--%";
	};

	const percentageNoDecimalFormatter = (value: number) => {
		return value != undefined && value != null && !isNaN(value)
			? `${value.systemFormat({
				maximumFractionDigits: 0,
				minimumFractionDigits: 0,
			})}%`
			: "--%";
	};

	const currencyFormatter = (value: number) => {
		if (!value) return "0.00";
		return `${value.systemFormat({ minimumFractionDigits: 2 })} `;
	};

	const dateFormatter = (value: string) => {
		return DateTime.fromISO(value).toFormat(t("dateFormat"));
	};

	const systemFormatter = (value: number) => {
		return (
			value?.systemFormat({
				maximumFractionDigits: 2,
				minimumFractionDigits: 2,
			}) ?? "---"
		);
	};

	const decimalFormatter = (value: number) => {
		return (
			value?.systemFormat({
				maximumFractionDigits: 2,
				minimumFractionDigits: 2,
			}) ?? "---"
		);
	};

	const integerFormatter = (value: number) => {
		return (
			value?.systemFormat({
				maximumFractionDigits: 0,
				minimumFractionDigits: 0,
			}) ?? "---"
		);
	};

	const build = () => columns;

	return {
		add,
		build,
		clear,
		percentageNoDecimalFormatter,
		percentageDecimalFormatter,
		decimalPercentageNoDecimalFormatter,
		decimalPercentageFormatter,
		currencyFormatter,
		dateFormatter,
		decimalFormatter,
		systemFormatter,
		integerFormatter,
	};
}
