import { IInventoryStatisticsSummary } from "domain/Inventory/Models/IInventoryStatisticsSummary";
import SBInventoryInfoModal from "modules/Inventory/components/SBInventoryInfoModal";
import { NotificationsRequester } from "modules/Notifications/services/NotificationsRequester";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router";

export const SBGlobalInventoryInfoProvider = (
	props: SBGlobalInventoryInfoProviderProps
) => {
	const { pathname } = useLocation();
	const { children } = props;
	const [statisticsID, setStatisticsID] = useState<number | undefined>(
		undefined
	);
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [statistics, setStatistics] = useState(
		{} as IInventoryStatisticsSummary
	);

	useEffect(() => {
		if (statisticsID) {
			setLoading(true);
			setIsOpen(true);
			NotificationsRequester.statistics(statisticsID).then((data) => {
				setStatistics(data.data);
				setLoading(false);
			});
		}
	}, [statisticsID]);

	useEffect(() => {
		closeModal();
	}, [pathname])

	const closeModal = () => {
		setIsOpen(false);
		setStatisticsID(undefined)
	}

	return (
		<InventoryInfoContext.Provider
			value={{
				isOpen,
				openMenu: (id: number) => setStatisticsID(id),
			}}
		>
			{children}
			<SBInventoryInfoModal
				isMenuOpen={isOpen}
				loading={loading}
				statistics={statistics}
				closeMenu={closeModal}
			/>
		</InventoryInfoContext.Provider>
	);
};

const InventoryInfoContext = createContext(
	{} as {
		isOpen: boolean;
		openMenu: (id: number) => void;
	}
);

export const useGlobalInventoryContext = InventoryInfoContext;

interface SBGlobalInventoryInfoProviderProps {
	children: ReactNode;
}
