import {
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material";
import clsx from "clsx";
import { SBCard, SBTable, SBTooltip, SBTypography } from "modules/Commons";
import { SBTableColumn } from "modules/Commons/SBDataDisplays/SBTable/models/TableColumn";
import SBSimpleTableRow from "./SBBasicTableRow";
import { useStyles } from "./style";

export default function SBBasicTable<T>({
	columns,
	rows,
	className,
}: SBBasicTable<T>) {
	const classes = useStyles();

	return (
		<SBCard
			className={clsx(classes.sbSimulationChartToolTip, className)}
			elevation={4}
		>
			<TableContainer>
				<SBTable size="small">
					<TableHead>
						<TableRow>
							{columns?.map((column) => {
								return (
									<TableCell key={column.key || column.name}>
										<SBTypography noWrap variant="body2">
											<SBTooltip title={column.name}>
												<>{column.name}</>
											</SBTooltip>
										</SBTypography>
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows?.map((row, index) => (
							<SBSimpleTableRow<T>
								key={index}
								row={row}
								columns={columns}
							/>
						))}
					</TableBody>
				</SBTable>
			</TableContainer>
		</SBCard>
	);
}

interface SBBasicTable<T extends {}> {
	/** Items that will be displayed on table */
	rows: T[];
	/** Columns to render on the basic table */
	columns: SBTableColumn<T>[];
	/** Columns to render on the basic table */
	className?: {};
}
