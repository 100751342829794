import { IStore } from "domain/Inventory";
import { IProduct } from "domain/Inventory/Models/IProduct";
import { DateTime } from "luxon";
import { SBBox, SBChip, SBIcon, SBTooltip } from "modules/Commons";
import { SBTableColumn } from "modules/Commons/SBDataDisplays/SBTable/models/TableColumn";
import { TransferencePlan } from "modules/Transferences/models/transferencePlan";
import { useTranslation } from "react-i18next";

const PercentageNoDecimalFormatter = (value: number) => {
	return `${(value * 100).systemFormat()}%`;
};

const DecimalFormatter = (value: number) => {
	if (!value) return "0";
	return `${value.systemFormat({ maximumFractionDigits: 0 })}`;
};

const NaturalFormatter = (value: number) => {
	if (!value) return "0";
	var v = value.toString().naturalNumber();
	parseInt(v);
	return v;
}

const DateFormatter = (value: string) => {
	var date = DateTime.fromISO(value);
	if (date.isValid) return date.toFormat(useTranslation().t("dateFormat"));
	return "-";
};

export const useTransferencePlanHeaders = (
	openInventoryInfo: (id: number) => void
) => {
	const { t } = useTranslation();

	const headers = [
		{
			id: () => "Source",
			name: t("source"),
			hide: true,
			headers: [
				{
					id: (e) => e.sourceInventory?.store.clientID ?? "",
					key: "sourceStoreCode",
					name: t("sourceStoreCode"),
					color: () => "#7F51FF",
					align: "left",
					disabled: true,
					hide: true,
					noWrap: true,
					style: {
						fontWeight: "bold",
					},
					child: {
						id: (e) => e.sourceInventory?.store.clientID,
						style: {
							color: "transparent",
							userSelect: "none",
						},
					},
				},
				{
					id: (e) => e.sourceInventory?.store.name ?? "",
					key: "sourceStoreNAme",
					name: t("sourceStoreName"),
					color: () => "#7F51FF",
					align: "left",
					disabled: true,
					hide: true,
					noWrap: true,
					style: {
						fontWeight: "bold",
					},
					child: {
						id: (e) => e.sourceInventory?.store.clientID,
						style: {
							color: "transparent",
							userSelect: "none",
						},
					},
				},
			],
		},
		{
			id: () => "Product",
			name: t("product"),
			headers: [
				{
					id: (e) => {
						return (
							<div
								style={{ cursor: "pointer" }}
								onClick={() =>
									openInventoryInfo(e?.inventory?.id)
								}
							>
								<SBIcon
									icon="inventory"
									hoverFillColor="primary"
								/>
							</div>
						);
					},
					align: "center",
					csvHide: true,
					width: 1,
					key: "inventory",
					name: "",
					fixed: true,
					disableClick: true,
				},
				{
					id: (e) => { },
					align: "center",
					csvHide: true,
					width: 1,
					fixed: true,
					disableClick: true,
					child: {
						id: (e) =>
							e.executedAmount > 0 ? (
								<SBTooltip
									title={t("transferenceExecutedAmount", {
										count: e.executedAmount,
									})}
								>
									<SBIcon icon="andamento" />
								</SBTooltip>
							) : (
								<></>
							),
						key: "transferenceInfo",
						align: "center",
						noWrap: true,
					},
				},
				{
					id: (e) => e.inventory?.product.clientID ?? "",
					key: "productID",
					name: t("code"),
					color: () => "#7F51FF",
					align: "left",
					noWrap: true,
					style: {
						fontWeight: "bold",
					},
					child: {
						id: (e: { product: IProduct }) => e.product.clientID,
						style: {
							color: "transparent",
							userSelect: "none",
						},
					},
				},
				{
					id: (e) => e.inventory?.product.name ?? "",
					key: "name",
					name: t("product"),
					align: "left",
					noWrap: true,
					child: {
						id: (e: { product: IProduct }) => e.product.name,
						style: {
							color: "transparent",
							userSelect: "none",
						},
					},
				},
			],
		},
		{
			id: () => "Store",
			name: t("store"),
			headers: [
				{
					id: (e) =>
						e.inventory?.store?.clientID ?? e.store?.clientID,
					key: "id",
					name: t("code"),
					align: "left",
					color: () => "#7F51FF",
					noWrap: true,
					style: {
						fontWeight: "bold",
					},
				},
				{
					id: (e) => e.inventory?.store?.name ?? e.store?.name,
					key: "name",
					name: t("store"),
					align: "left",
					noWrap: true,
				},
				{
					id: (e) =>
						e.plans?.length ? (
							<SBChip
								color="primary"
								fullWidth={false}
								label={
									<SBBox display="flex" alignItems="center">
										<SBBox mb={0.3} mr={0.3}>
											<SBIcon
												icon="home"
												size="11"
												color="white"
											/>
										</SBBox>
										{e.plans?.length}
									</SBBox>
								}
							/>
						) : null,
					csvHide: true,
					key: "",
					name: t(""),
					align: "left",
					noWrap: true,
					child: {
						id: (e) => (
							<SBChip
								color="gray6"
								fullWidth={false}
								label={<SBBox>{e.type}</SBBox>}
							/>
						),
						key: "statusType",
						name: t("type"),
						align: "left",
						noWrap: true,
					},
				},
			],
		},
		{
			id: () => "Category",
			name: t("category"),
			headers: [
				{
					id: (e) => e.inventory?.product?.category?.clientID,
					key: "categoryCode",
					name: t("code"),
					align: "left",
					noWrap: true,
					style: {
						fontWeight: "bold",
						color: "#7F51FF",
					},
					tip: "categoryClientIDTip",
				},
				{
					id: (e) => e.inventory?.product?.category?.name,
					key: "categoryName",
					name: t("name"),
					align: "left",
					noWrap: true,
					tip: "categoryNameTip",
				},
			],
		},
		{
			id: () => "Cycle",
			name: t("cycle"),
			headers: [
				{
					id: (e) => e.cycle?.request ?? "-",
					key: "request",
					name: t("request"),
					align: "right",
					noWrap: true,
					formatter: DateFormatter,
				},
				{
					id: (e) => e.cycle?.delivery ?? "-",
					key: "delivery",
					name: t("delivery"),
					align: "right",
					noWrap: true,
					formatter: DateFormatter,
				},
				{
					id: (e) => e.cycle?.coverage ?? "-",
					key: "coverage",
					name: t("coverage"),
					align: "right",
					noWrap: true,
					formatter: DateFormatter,
				},
				{
					id: (e) => e.cycle?.nextReview ?? "-",
					key: "nextReview",
					name: t("nextReview"),
					align: "right",
					noWrap: true,
					formatter: DateFormatter,
				},
			],
		},
		{
			id: () => "Config",
			name: t("config"),
			headers: [
				{
					id: (e) => e?.minCSL,
					key: "scarcityCSL",
					name: t("scarcity"),
					align: "right",
					noWrap: true,
					formatter: PercentageNoDecimalFormatter,
				},
				{
					id: (e) => e?.csl,
					key: "targetCSL",
					name: t("target"),
					align: "right",
					noWrap: true,
					formatter: PercentageNoDecimalFormatter,
				},
				{
					id: (e) => e?.maxCSL,
					key: "excessCSL",
					name: t("excess"),
					align: "right",
					noWrap: true,
					formatter: PercentageNoDecimalFormatter,
				},
			],
		},
		{
			id: () => "Initial",
			name: t("startPosition"),
			headers: [
				{
					id: (e) => e.initial?.csl,
					key: "csl",
					name: t("csl"),
					align: "right",
					noWrap: true,
					formatter: PercentageNoDecimalFormatter,
				},

				{
					id: (e) => e.initial?.position.onHand,
					key: "initialOnHand",
					name: t("onHand"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
				},
				{
					id: (e) => e.initial?.position.onOrder,
					key: "initialOnOrder",
					name: t("onOrder"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
				},
				{
					id: (e) => e.initial?.position.reserved,
					key: "pending",
					name: t("commons.pending"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
					tip: "tips.pending",
				},
				{
					id: (e) => e.plans.reduce((a, b) => a + b.initial.eus, 0),
					key: "finalRupture",
					name: t("rupture"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
					child: {
						id: (e) => e.initial.eus,
						key: "finalRuptureChild",
						name: t("rupture"),
						align: "right",
						noWrap: true,
						formatter: DecimalFormatter,
					},
				},
			],
		},
		{
			id: () => "Transference",
			name: t("transference"),
			headers: [
				{
					id: (e) => e.initial.excess,
					key: "available",
					name: t("available"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
					child: {
						id: (e) => "-",
						key: "available",
						name: t("available"),
						align: "right",
					},
				},
				{
					id: (e) => e.netNeed,
					key: "needed",
					name: t("needed"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
				},
				{
					id: () => "-",
					key: "transferenceOnHand",
					name: t("onHand"),
					align: "right",
					noWrap: true,
					child: {
						id: (e) => e.initial?.position.onHand + e?.netNeed,
						key: "childTransferenceOnHand",
						name: t("onHand"),
						align: "right",
						noWrap: true,
						formatter: DecimalFormatter,
					},
				},
			],
		},
		{
			id: () => "Suggestion",
			name: t("suggestion"),
			headers: [
				{
					id: (e) => e.final?.csl,
					key: "reachedCSL",
					name: t("reachedCSL"),
					align: "right",
					noWrap: true,
					formatter: PercentageNoDecimalFormatter,
				},
				{
					id: (e) => e.quantity,
					key: "Amount",
					name: t("quantity"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
				},
				{
					id: (e) => e.plans.reduce((a, b) => a + b.amount, 0),
					key: "finalOnHand",
					name: t("transfer"),
					align: "right",
					noWrap: true,
					formatter: NaturalFormatter,
					child: {
						editable: true,
						id: (e) => e.amount,
						setter: (e, value: any) => (e.amount = +value.naturalNumber()),
					},
				},
				{
					id: (e) => e.final?.position.onHand,
					key: "onHand",
					name: t("onHand"),
					noWrap: true,
					align: "right",
					formatter: DecimalFormatter,
					child: {
						editable: false,
						align: "right",
						id: (e) => e.amount,
						setter: (e, value: any) => (e.amount = +value),
					},
				},
				{
					id: (e) => e.final?.position.reserved,
					key: "finalReserved",
					name: t("reserved"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
				},
				{
					id: (e) => e.plans.reduce((a, b) => a + b.final.eus, 0),
					key: "expectedUnitShort",
					name: t("rupture"),
					align: "right",
					noWrap: true,
					formatter: DecimalFormatter,
					child: {
						id: (e) => e.final.eus,
						key: "expectedUnitShortChild",
						name: t("rupture"),
						align: "right",
						noWrap: true,
						formatter: DecimalFormatter,
					},
				},
			],
		},
	] as SBTableColumn<TransferencePlan>[];

	return { headers };
};
