import {
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { IChartTooltipRow } from "domain/Chart/Entities/IChartTooltipRow";
import useMousePosition from "hooks/useMousePosition";
import { SBCard, SBTable } from "modules/Commons";
import { ISimulationModel } from "modules/Simulations/models/ISimulationModel";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import {
	NameType,
	ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { TooltipProps } from "recharts/types/component/Tooltip";
import SimulationChartTooltipRow from "./SimulationChartTooltipRow";
import { useStyles } from "./style";

export default ({
	active,
	payload,
	label,
	element,
	container,
	tooltipRows,
	selectedChart,
}: SimulationChartTooltipProps) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipHeight = 117;
	const tooltipWidth = 350;
	const tooltipXOffset = 50;

	const [fixedY, setFixedY] = useState(0);
	const [mousePosition, setMousePosition] = useState<MouseEvent | null>(null);

	useMousePosition({ onMouseMove: setMousePosition });

	const setY = () => {
		if (fixedY === 0 && !!mousePosition) {
			setFixedY(
				window.scrollY +
					(container?.getBoundingClientRect().top ?? 0) -
					tooltipHeight
			);
		}
	};

	const setX = () => {
		const xMousePosition = mousePosition?.pageX ?? 0;

		const leftPosition =
			xMousePosition + tooltipWidth < window.innerWidth
				? xMousePosition + tooltipXOffset
				: xMousePosition - tooltipWidth + tooltipXOffset;

		return {
			left: leftPosition,
			top: fixedY,
		};
	};

	if (active && payload && payload.length) {
		setY();

		return createPortal(
			<SBCard
				id="sb-simulation-chart-tooltip"
				className={classes.sbSimulationChartToolTip}
				style={{ ...setX(), width: 280 }}
			>
				{payload && (
					<TableContainer>
						<SBTable size="small">
							<TableHead>
								<TableRow>
									<TableCell>{label}</TableCell>
									<TableCell>{t("real")}</TableCell>
									<TableCell>{t("projected")} </TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tooltipRows?.map((column, index: number) => (
									<SimulationChartTooltipRow
										key={column.label + index}
										payload={payload}
										selectedChart={selectedChart}
										column={column}
									/>
								))}
							</TableBody>
						</SBTable>
					</TableContainer>
				)}
			</SBCard>,
			element!
		);
	}
	return null;
};

export interface SimulationChartTooltipProps
	extends TooltipProps<ValueType, NameType> {
	/** Portal Item to render the tooltip */
	element: Element | null;
	/** Position of the start of the card */
	container: Element | null;
	/** All rows to show on tooltip chart */
	tooltipRows: IChartTooltipRow<ISimulationModel>[];
	/** Selected chart */
	selectedChart: string;
}
