import { useWindowSize } from "hooks/useWindowResize";
import SBBox from "modules/Commons/SBLayouts/SBBox";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { useSystemTheme } from "themes/base";
import { percentageFormatter } from "utils/formatters";

export default (props: SBValuesGauge) => {
	const { inner, outter, total } = props;
	const [width] = useWindowSize();
	const { t } = useTranslation();
	const theme = useSystemTheme();

	const [activeInner, setActiveInner] = useState<number | null>(null);
	const [activeOutter, setActiveOutter] = useState<number | null>(null);
	const cy = 130;

	const innerRadius = [75, 103];
	const outterRadius = [105, 115];

	const active = useMemo(() => {
		const isOutter = activeOutter != null && outter;
		const isInner = activeInner != null && inner;

		const currentValue = isOutter
			? outter[activeOutter!]
			: isInner
				? inner[activeInner!]
				: { value: total, color: theme.palette.gray2.main, name: "total" };

		return {
			name: currentValue?.name,
			value: currentValue?.value,
			color: currentValue?.color,
		};
	}, [activeInner, activeOutter]);

	return (
		<SBBox
			style={{
				position: "relative",
				height: 150,
				margin: "auto",
			}}
		>
			<ResponsiveContainer>
				<PieChart>
					<Pie
						activeIndex={activeInner!}
						isAnimationActive={false}
						dataKey="value"
						startAngle={180}
						cy={cy}
						endAngle={0}
						data={inner}
						innerRadius={innerRadius[0]}
						outerRadius={innerRadius[1]}
						onMouseEnter={(_, index: number) => {
							setActiveOutter(null);
							setActiveInner(index);
						}}
						onMouseLeave={() => setActiveInner(null)}
					>
						{inner.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={entry.color} />
						))}
					</Pie>
					{!!outter && (
						<Pie
							activeIndex={activeOutter!}
							isAnimationActive={false}
							dataKey="value"
							startAngle={180}
							endAngle={0}
							data={outter}
							cy={cy}
							innerRadius={outterRadius[0]}
							outerRadius={outterRadius[1]}
							onMouseEnter={(_, index: number | null) => {
								setActiveInner(null);
								setActiveOutter(index);
							}}
							onMouseLeave={() => setActiveOutter(null)}
						>
							{outter.map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={entry.color}
								/>
							))}
						</Pie>
					)}
				</PieChart>
			</ResponsiveContainer>
			<SBBox
				style={{
					position: "absolute",
					left: "50%",
					top: "48%",
					fontWeight: "700",
					transform: "translate(-50%, 50%)",
					textAlign: "center",
					color: active.color,
					fontSize: width < 1200 ? "13px" : "15px",
				}}
			>
				<SBBox>{t(active.name)}</SBBox>
				<SBBox display="flex" justifyContent="center">
					<SBBox mr={0.5} textAlign="center">
						{active?.value < 10
							? `0${active?.value}`
							: active?.value}
					</SBBox>
					<SBBox>
						({percentageFormatter(active?.value / total)})
					</SBBox>
				</SBBox>
			</SBBox>
		</SBBox>
	);
};

interface SBValuesGauge {
	total: number;
	inner: { name: string; value: number; color: string }[];
	outter?: { name: string; value: number; color: string }[];
	formatter?: (c: number) => any;
	thickness?: number;
	fontSize?: string | number;
	complement?: boolean;
}
