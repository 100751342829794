import { SBBox, SBCard, SBGrid, SBLoading } from "modules/Commons";
import ExplorationsListing from "modules/Explorations/components/ExplorationsListing";
import { ExplorationsRequester } from "modules/Explorations/services/ExplorationsRequester";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SBComposedFilter from "views/Commons/SBComposedFilter";
import ExplorationCSLInfo from "modules/Explorations/components/ExplorationCSLInfo";
import ExplorationsDemandInfo from "modules/Explorations/components/ExplorationsDemandInfo";
import ExplorationsStockoutInfo from "modules/Explorations/components/ExplorationsStockoutInfo";
import { IInventoryStatisticsAccumulator } from "domain/Utils/models/IInventoryStatisticsAccumulator";
import ExplorationsVolatilityInfo from "modules/Explorations/components/ExplorationsVolatilityInfo";
import SBEmptyState from "modules/Commons/SBLayouts/SBEmptyState";
import ExplorationEmptyState from "modules/Explorations/components/ExplorationEmptyState";
import { useSnackbar } from "modules/Commons/SBOverlays/SBSnackbar";

export default () => {
	const { t } = useTranslation();
	const snackbar = useSnackbar();
	const [loading, setLoading] = useState(false);
	const [exploration, setExplorations] =
		useState<IInventoryStatisticsAccumulator>({} as any);

	const fetchData = (props: any) => {
		setLoading(true);
		setExplorations({} as any);
		ExplorationsRequester.explore(props.filter, undefined, undefined)
			.then((c) => {
				setExplorations(c);
			})
			.catch(() => {
				snackbar.error(t("inventoryCreatedError"));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const values = [
		{
			title: t("cost"),
			getter: () => exploration?.inventoryConfig?.cost,
			formatter: (c: number) => c?.systemFormatAsMoney() ?? "-",
		},
		{
			title: t("price"),
			getter: () => exploration?.inventoryConfig?.price,
			formatter: (c: number) => c?.systemFormatAsMoney() ?? "-",
		},
		{
			title: t("profit"),
			getter: () => exploration?.inventoryConfig?.profit,
			formatter: (c: number) => c?.systemFormatAsMoney() ?? "-",
		},
		{
			title: t("crp"),
			getter: () => exploration?.crp,
			formatter: (c: number) => c?.systemFormatAsMoney() ?? "-",
		},
	];

	const leadTimes = [
		{
			title: t("leadTime"),
			getter: () => exploration?.inventoryConfig?.leadTime,
			formatter: (c: number) => c?.systemFormatAsInteger() ?? "-",
			unit: t("day", { count: exploration?.inventoryConfig?.leadTime.value }),
		},
		{
			title: t("reviewPeriod"),
			getter: () => exploration?.inventoryConfig?.reviewPeriod,
			formatter: (c: number) => c?.systemFormatAsInteger() ?? "-",
			unit: t("day", { count: exploration?.inventoryConfig?.reviewPeriod.value }),
		},
		{
			title: t("cycleTime"),
			getter: () => exploration?.inventoryConfig?.cycleTime,
			formatter: (c: number) => c?.systemFormatAsInteger() ?? "-",
			unit: t("day", { count: exploration?.inventoryConfig?.cycleTime.value }),
		},
	];

	const stock = [
		{
			title: t("min"),
			getter: () => exploration?.initPosition?.onHand?.min,
			formatter: (c: number) => c?.systemFormatAsInteger() ?? "-",
			unit: t("unit"),
		},
		{
			title: t("max"),
			getter: () => exploration?.initPosition?.onHand?.max,
			formatter: (c: number) => c?.systemFormatAsInteger() ?? "-",
			unit: t("unit"),
		},
		{
			title: t("onHand"),
			getter: () => exploration?.initPosition?.onHand?.sum,
			formatter: (c: number) => c?.systemFormatAsInteger() ?? "-",
			unit: t("unit"),
		},
		{
			title: t("onOrder"),
			getter: () => exploration?.initPosition?.onOrder?.sum,
			formatter: (c: number) => c?.systemFormatAsInteger() ?? "-",
			unit: t("unit"),
		},
		{
			title: t("position"),
			getter: () => exploration?.initPosition?.position,
			formatter: (c: number) => c?.systemFormatAsInteger() ?? "-",
			unit: t("unit"),
		},
	];

	const demandValue = useMemo(
		() => exploration?.demand?.value / exploration?.forecast?.value,
		[exploration]
	);

	const risk = useMemo(
		() => exploration?.initPosition?.risk?.value ?? undefined,
		[exploration]
	);

	const volatility = useMemo(
		() => exploration?.volatility?.value ?? undefined,
		[exploration]
	);

	const days = useMemo(
		() => exploration?.evaluationScope?.daysBetweenCount,
		[exploration]
	);

	return (
		<SBBox pl={11}>
			<SBBox pb={2}>
				<SBComposedFilter.Base onApply={fetchData}>
					<SBComposedFilter.Filter />
					<SBComposedFilter.Applicator />
				</SBComposedFilter.Base>
			</SBBox>
			<SBLoading loading={loading}>
				<SBEmptyState
					show={!!exploration.inventoryConfig}
					emptyState={<ExplorationEmptyState />}
				>
					<SBGrid container spacing={2} height="100%">
						<SBGrid item xs={4}>
							<SBCard>
								<SBBox px={3} pt={3} pb={1.5}>
									<ExplorationsListing
										title={t("values")}
										items={values}
										exploration={exploration}
									/>
								</SBBox>
								<SBBox px={3} pt={1.5} pb={3}>
									<ExplorationsListing
										title={t("cycle")}
										items={leadTimes}
										exploration={exploration}
									/>
								</SBBox>
							</SBCard>
						</SBGrid>
						<SBGrid
							item
							xs={4}
							display="flex"
							flexDirection="column"
							spacing={1}
						>
							<SBCard>
								<SBBox p={3}>
									<ExplorationsListing
										title={t("stock")}
										items={stock}
										exploration={exploration}
									/>
								</SBBox>
							</SBCard>
							<ExplorationsDemandInfo
								demand={demandValue}
								days={days}
							/>
						</SBGrid>
						<SBGrid item xs={4}>
							<SBBox
								p={1}
								bgcolor="lightPurple.main"
								borderRadius={1}
							>
								<ExplorationCSLInfo exploration={exploration} />
								<ExplorationsStockoutInfo
									risk={risk}
									stockout={""}
								/>
								<ExplorationsVolatilityInfo
									volatility={volatility}
								/>
							</SBBox>
						</SBGrid>
					</SBGrid>
				</SBEmptyState>
			</SBLoading>
		</SBBox>
	);
};
