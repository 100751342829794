import { IInventory } from "domain/Inventory";
import { createContext, useContext } from "react";

export const InventoryStatusContext = createContext(
	{} as InventoryStatusContextProps
);

export interface InventoryStatusConfig {
	title: string;
	columnName: string;
}

export interface InventoryStatusContextProps {
	fetchData: (
		d: Promise<IInventory[]>,
		configs: InventoryStatusConfig
	) => void;
}
