import { styled } from "@mui/styles";
import { SBBox } from "modules/Commons";
import { BaseThemeOptions } from "themes/base";

const labelSize = "47px";

export const TabContainer = styled("div")(
	({ isOpen, color, countOfTabs, disabled }: TabContainerProps) => ({
		display: "flex",
		width: isOpen
			? `calc(99.99% - calc(${labelSize} * ${countOfTabs - 1}))`
			: `${labelSize}`,
		transition: "all .25s ease-out",
		border: !disabled ? `1px solid ${color}` : "gray",
		overflow: "hidden",
		margin: 0,
	})
);

interface TabContainerProps {
	disabled?: boolean;
	index: number;
	isOpen: boolean;
	color: string | undefined;
	countOfTabs: number;
}

export const TabLabelContent = styled(SBBox)(
	({ color, disabled }: TabLabelContentProps) => ({
		display: "flex",
		flexDirection: "column",
		justifyContent: "end",
		alignItems: "center",
		minWidth: `${labelSize}`,
		borderRight: !disabled ? `1px solid ${color}` : "gray",
		background: !disabled
			? `linear-gradient(180deg, ${color}, 0%, ${color}9C 100%)`
			: "gray",
		color: "white",
		fontWeight: "700",
		letterSpacing: "0.1rem",
		cursor: "pointer",
		padding: "10px",
		paddingBottom: "15px",
	})
);

interface TabLabelContentProps {
	disabled?: boolean;
	color: string | undefined;
}

export const TabIconContainer = styled(SBBox)(
	({ theme }: TabIconContainerProps) => ({
		marginBottom: "auto",
		paddingBottom: "4px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		background: theme.palette.error.main,
		borderRadius: "50%",
		width: "30px",
		height: "30px",
	})
);

interface TabIconContainerProps {
	theme: BaseThemeOptions;
}

export const TabLabel = styled(SBBox)(() => ({
	textOrientation: "mixed",
	writingMode: "vertical-rl",
	transform: "rotate(-180deg)",
	marginBottom: "16px",
	color: "white",
}));

export const TabContent = styled(SBBox)(
	({ theme, isOpen }: TabContentProps) => ({
		display: isOpen ? "initial" : "none",
		width: `calc(100% - ${labelSize})`,
		padding: "16px",
		color: theme.palette.gray2.main,
	})
);

interface TabContentProps {
	theme: BaseThemeOptions;
	isOpen: Boolean;
}
