import { Alert, Slide, SlideProps, Snackbar } from "@mui/material";
import { createContext, useContext, useState } from "react";

function SlideTransition(props: SlideProps) {
	return <Slide {...props} direction="up" />;
}

export interface SBSnackbarFuncs {
	error: (message: string) => void;
	success: (message: string) => void;
	warning: (message: string) => void;
}

const SnackbarContext = createContext({} as SBSnackbarFuncs);
export const useSnackbar = () => useContext(SnackbarContext);

export default ({ children }: any) => {
	const [open, setOpen] = useState<boolean>(false);
	const [message, setMessage] = useState("");
	const [severity, setSeverity] = useState<"error" | "success" | "warning">();

	const error = (message: string) => {
		setOpen(true);
		setSeverity("error");
		setMessage(message);
	};

	const success = (message: string) => {
		setOpen(true);
		setSeverity("success");
		setMessage(message);
	};

	const warning = (message: string) => {
		setOpen(true);
		setSeverity("warning");
		setMessage(message);
	};

	const handleClose = () => setOpen(false);

	return (
		<SnackbarContext.Provider value={{ error, success, warning }}>
			{children}
			<Snackbar
				autoHideDuration={4000}
				TransitionComponent={SlideTransition}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={open}
				onClose={handleClose}
				key={message + severity}
			>
				<Alert
					variant="filled"
					onClose={handleClose}
					severity={severity}
					sx={{ width: "100%" }}
				>
					{message}
				</Alert>
			</Snackbar>
		</SnackbarContext.Provider>
	);
};
