import { IInventoryPosition } from "domain/Inventory";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { InventoryDeadStatusConfig, InventoryDeadStatusContext, InventoryDeadStatusContextProps } from "./context";
import SBDeadInventoryModal from "views/Commons/SBDeadInventoryModal";

export const SBGlobalInventoriesDeadStatusProvider = (
    props: SBGlobalInventoryDeadStatusProviderProps
) => {
    const { children } = props;
    const { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [configs, setConfigs] = useState({} as InventoryDeadStatusConfig);
    const [inventories, setInventories] = useState<IInventoryPosition[]>([]);

    const value = {
        fetchData: async (d, c) => {
            setLoading(true);
            setIsOpen(true);
            setConfigs(c);
            const result = await d;
            setInventories(result);
            setLoading(false);
        },
    } as InventoryDeadStatusContextProps;

    useEffect(() => {
        setIsOpen(false);
    }, [pathname]);

    return (
        <InventoryDeadStatusContext.Provider value={value}>
            {children}
            <SBDeadInventoryModal
                inventories={inventories}
                isMenuOpen={isOpen}
                loading={loading}
                closeMenu={() => setIsOpen(false)}
                title={configs.title}
                itemName={configs.columnName}
            />
        </InventoryDeadStatusContext.Provider>
    );
};

export const useGlobalInventoriesDeadStatusContext = () =>
    useContext(InventoryDeadStatusContext);

interface SBGlobalInventoryDeadStatusProviderProps {
    children: ReactNode;
}
