import {
	SBBox,
	SBButton,
	SBDataTable,
	SBIcon,
	SBTypography,
	SBLoading,
	SBOutlinedButton,
	SBSlideMenu,
	SBForm,
	SBIconButton,
	SBTooltip,
} from "modules/Commons";
import { useHeaders } from "./tableHeaders";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import InventoryConfigFinder from "./InventoryConfigFinder";
import ConfigSideMenu from "./ConfigSideMenu";
import { InventoryConfigRequester } from "modules/InventoryConfig/services/InventoryConfigRequester";
import { groupConfigValidator } from "domain/Config/Services/GroupConfigValidator";
import { SuppliersRequester } from "modules/Suppliers/services/SuppliersRequester";
import { InventoryFormulaRequester } from "modules/InventoryFormulas/services/InventoryFormulaRequester";
import { StoresRequester } from "modules/Stores/services/StoresRequester";
import ConfigEditMenu from "./ConfigEditMenu";
import ConfigViewEmptyState from "./ConfigViewEmptyState";
import ConfigExcludeDialog from "./ConfigExcludeDialog";
import ConfigDisableDialog from "./ConfigDisableDialog";
import ConfigEnableDialog from "./ConfigEnableDialog";
import { useSnackbar } from "modules/Commons/SBOverlays/SBSnackbar";
import { isBoolean } from "lodash";
import { useCSV } from "hooks/useCSV";
import ConfigUploaderMenu from "./ConfigUploaderMenu";
import SBComposedFilter from "views/Commons/SBComposedFilter";
import { SBTranslate } from "i18n";
import { GroupConfigRequester } from "modules/GroupConfig/services/GroupConfigRequester";

export default () => {
	const { headers } = useHeaders();
	const { error, success } = useSnackbar();
	const { t } = useTranslation();

	const tableRef = useRef();
	const formRef = useRef<any>();

	const { downloadCSV } = useCSV();

	const [rows, setRows] = useState<any>([]);
	const [config, setConfig] = useState<any>({});
	const [validation, setValidation] = useState<any>({});
	const [itemsToChangeStatus, setItemsToChangeStatus] = useState<[]>([]);

	const [isEnabling, setIsEnabling] = useState<boolean>(false);
	const [isDisabling, setIsDisabling] = useState<boolean>(false);
	const [isExcluding, setIsExcluding] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [openFinder, setOpenFinder] = useState<boolean>(false);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const [showOnlyActived, setShowOnlyActived] = useState<boolean>(false);
	const [loadingInventory, setLoadingInventory] = useState<boolean>(false);
	const [isUploading, setIsUploading] = useState(false);

	const [suppliers, setSuppliers] = useState([]);
	const [inventoryFormulas, setInventoryFormulas] = useState([]);
	const [stores, setStores] = useState([]);

	useEffect(() => {
		setValidation({});
		fetchRows(false);
	}, []);

	const fetchRows = (active: boolean) => {
		const getOposite = !active;
		setShowOnlyActived(getOposite);
		setLoading(true);
		InventoryConfigRequester.GET(`filtered/${getOposite}`)
			.then((c: any) => {
				setRows(c.data);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchConfig = (configID: number) => {
		setLoadingInventory(true);
		InventoryConfigRequester.GET(`${configID}`)
			.then((c: any) => {
				setConfig(c.data);
			})
			.finally(() => {
				setLoadingInventory(false);
			});
	};

	const fetchPropError = (prop: string) => {
		return validation[prop];
	};

	const startNewConfig = () => {
		setLoadingInventory(true);
		setValidation({});
		setIsMenuOpen(true);
		setConfig({
			id: 0,
			active: true,
			products: [],
			stores: [],
			categories: [],
		});
		setTimeout(() => {
			setLoadingInventory(false);
		}, 250);
	};

	useEffect(() => {
		SuppliersRequester.GET("").then((r: any) => {
			setSuppliers(r.data);
		});
		InventoryFormulaRequester.GET("").then((r: any) => {
			setInventoryFormulas(r.data);
		});
		StoresRequester.GET("").then((r: any) => {
			setStores(r.data);
		});
	}, []);

	const onDelete = (ids: number[]) => {
		setLoading(true);
		InventoryConfigRequester.POST(`delete`, ids)
			.then((result: any) => {
				success(t("inventorySucessfullyDeleted"));
				setRows((rows: any[]) =>
					rows.filter((c: any) => ids.indexOf(c.id) < 0)
				);
			})
			.catch(() => {
				error(t("couldntDeleteConfig"));
			})
			.finally(() => {
				setLoading(false);
				setLoadingInventory(false);
			});
	};

	const onChangeStatus = (ids: number[], status: boolean) => {
		setLoading(true);
		InventoryConfigRequester.POST(status ? "enable" : "disable", ids)
			.then((result: any) => {
				success(t(status ? "enable" : "disable"));
				setRows((rows: any[]) =>
					rows.filter((c: any) => ids.indexOf(c.id) < 0)
				);
			})
			.catch(() => {
				error(t(status ? "enable" : "disable"));
			})
			.finally(() => {
				setLoading(false);
				setLoadingInventory(false);
			});
	};

	const saveConfig = (newConfig: any) => {
		setLoadingInventory(true);
		InventoryConfigRequester.POST(``, newConfig)
			.then((result: any) => {
				setLoading(true);

				newConfig.products = newConfig.products.map((p: any) => ({
					...p,
					status: isBoolean(p.status) ? p.status : null,
				}));
				newConfig.stores = newConfig.stores.map((p: any) => ({
					...p,
					status: isBoolean(p.status) ? p.status : null,
				}));
				newConfig.categories = newConfig.categories.map((p: any) => ({
					...p,
					status: isBoolean(p.status) ? p.status : null,
				}));

				if (newConfig.id === 0) {
					const inventoryID = result.data ?? 0;
					newConfig.id = inventoryID;
					setRows((d: any) => [...d, newConfig]);
				} else {
					setRows((rows: any) =>
						rows.triggerChange({
							condition: (row: any) => row.id === newConfig.id,
							newValue: () => newConfig,
						})
					);
				}
				setConfig(newConfig);

				success(
					t("inventoryCreatedUpdated", {
						status: !!result.data ? t("updated") : t("created"),
					})
				);
			})
			.finally(() => {
				setLoading(false);
				setLoadingInventory(false);
				fetchRows(!showOnlyActived);
			});
	};

	const downloadConfigCSV = () => {
		GroupConfigRequester.exportCSV("exportCSV")
			.then((c: any) => {
				downloadCSV({ csv: c.data, title: "InventoryConfigs" });
			})
			.catch(() => {
				error(SBTranslate("couldntDownloadConfig"));
			});
	};

	return (
		<SBBox pl={11}>
			<SBBox
				mb={2}
				ml="auto"
				display="flex"
				justifyContent="space-between"
			>
				<SBBox display="flex" alignItems="center">
					<SBButton onClick={() => startNewConfig()}>
						<SBIcon icon="plus-square" color="#fff" />
						<SBTypography
							ml={1}
							fontWeight="700"
							color="white.main"
							textTransform="none"
						>
							{t("createNew")}
						</SBTypography>
					</SBButton>
				</SBBox>
				<SBBox display="flex">
					<SBOutlinedButton onClick={() => setOpenFinder(true)}>
						<SBIcon icon="search" color="primary" />
						<SBTypography
							ml={1}
							py={0.3}
							fontWeight="700"
							textTransform="none"
						>
							{t("findInventory")}
						</SBTypography>
					</SBOutlinedButton>
					<SBBox>
						<SBComposedFilter.Actions
							loading={false}
							actions={[
								{
									title: "exportCSV",
									icon: { name: "download" },
									onClick: () => downloadConfigCSV(),
								},
								{
									title: "uploadCSV",
									icon: { name: "upload" },
									onClick: () => setIsUploading((c) => !c),
								},
							]}
						/>
					</SBBox>
				</SBBox>
			</SBBox>
			{!loading ? (
				rows ? (
					<SBDataTable
						onActive={!showOnlyActived}
						key={"" + loading + showOnlyActived + rows.length}
						toolbarAfterContent={
							<SBBox mr={2}>
								<SBTooltip
									title={t(
										`show${showOnlyActived
											? "Disabled"
											: "Active"
										}`
									)}
								>
									<SBIconButton
										onClick={() =>
											fetchRows(showOnlyActived)
										}
									>
										<SBIcon
											size={24}
											icon={
												showOnlyActived
													? "eye-off"
													: "eye"
											}
											color={
												showOnlyActived
													? "grey4"
													: "primary"
											}
										/>
									</SBIconButton>
								</SBTooltip>
							</SBBox>
						}
						title={
							t(
								showOnlyActived
									? "enabledConfig"
									: "disabledConfig",
								{ count: 2 }
							) as string
						}
						ref={tableRef}
						getID={(c: any) => c.id}
						pagination
						showGroups
						selectable
						deepSearch
						rows={
							showOnlyActived
								? rows.filter((c: any) => c.active)
								: rows.filter((c: any) => !c.active)
						}
						columns={headers}
						editActions={({ selectedItems }: any) => (
							<ConfigEditMenu
								showOnlyActived={showOnlyActived}
								onDisable={() => {
									setIsDisabling(true);
									setItemsToChangeStatus(selectedItems);
								}}
								onEnable={() => {
									setIsEnabling(true);
									setItemsToChangeStatus(selectedItems);
								}}
								onDelete={() => {
									setIsExcluding(true);
									setItemsToChangeStatus(selectedItems);
								}}
							/>
						)}
						clickLine={(_: any, id: any) => {
							setConfig(null);
							setIsMenuOpen(true);
							fetchConfig(id);
						}}
					/>
				) : (
					<ConfigViewEmptyState />
				)
			) : (
				<SBLoading loading />
			)}
			<InventoryConfigFinder
				open={openFinder}
				onClose={() => setOpenFinder(false)}
				onChooseConfig={(id: any) => {
					setIsMenuOpen(true);
					fetchConfig(id);
				}}
			/>
			<SBSlideMenu open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
				{config && config.id >= 0 ? (
					<SBForm
						key={config.id}
						ref={formRef}
						validationSchema={groupConfigValidator().schema}
						defaultValues={config}
						onError={(v: any, e: any) => {
							setValidation([]);
							const errors = Object.keys(v).reduce(
								(a: any, b: any) => ({
									...a,
									[b]: v[b]?.message,
								}),
								{}
							);
							setValidation(errors);
						}}
						onSubmit={(v) => {
							setValidation([]);
							saveConfig(v);
						}}
					>
						<ConfigSideMenu
							fetchPropError={fetchPropError}
							loading={loadingInventory}
							suppliers={suppliers}
							inventoryFormulas={inventoryFormulas}
							stores={stores}
							config={config}
							onClose={() => {
								setIsMenuOpen(false);
								setValidation([]);
							}}
							onSave={saveConfig}
						/>
					</SBForm>
				) : (
					<SBLoading limited loading />
				)}
			</SBSlideMenu>
			<ConfigExcludeDialog
				open={isExcluding}
				countOfItems={itemsToChangeStatus.length}
				onClose={() => setIsExcluding(false)}
				onConfirm={() => onDelete(itemsToChangeStatus)}
				onCancel={() => setIsExcluding(false)}
			/>
			<ConfigDisableDialog
				open={isDisabling}
				countOfItems={itemsToChangeStatus.length}
				onClose={() => setIsDisabling(false)}
				onConfirm={() => onChangeStatus(itemsToChangeStatus, false)}
				onCancel={() => setIsDisabling(false)}
			/>
			<ConfigEnableDialog
				open={isEnabling}
				countOfItems={itemsToChangeStatus.length}
				onClose={() => setIsEnabling(false)}
				onConfirm={() => onChangeStatus(itemsToChangeStatus, true)}
				onCancel={() => setIsEnabling(false)}
			/>
			<ConfigUploaderMenu
				isUploading={isUploading}
				setIsUploading={setIsUploading}
				onSave={() => {
					setIsUploading(false);
					fetchRows(false);
				}}
			/>
		</SBBox>
	);
};
