// src\views\ConfigsView\ConfigSideMenu\ConfigSupply\ConfigEmergencial\index.tsx

import { SBTranslate } from "i18n";
import { SBBox, SBSelect, SBTypography } from "modules/Commons";
import { EmergencialCoverageMode } from "modules/EmergencialLeadTime/models/emergencialCoverageMode";
import { useContext, useEffect, useState } from "react";
import { TipsContext } from "views/ConfigsView/ConfigTips";


const ConfigEmergencialCoverageMode = ({ value, onUpdate }: ConfigEmergencialCoverageModeProps) => {
	const tipsContext = useContext(TipsContext);

	const [emergencialCoverageMode, setEmergencialCoverageMode] = useState<EmergencialCoverageMode | null>(value ?? null);

	const EmergencialCoverageModeValues = [
		{ text: SBTranslate("notDefined"), value: EmergencialCoverageMode.emergencialCoverageModeNotDefined, tipsContext: "NotDefined" },
		{ text: SBTranslate("untilNextCycleDelivery"), value: EmergencialCoverageMode.untilNextCycleDelivery, tipsContext: "EmergencialModeDelivery" },
		{ text: SBTranslate("untilNextCycleCoverage"), value: EmergencialCoverageMode.untilNextCycleCoverage, tipsContext: "EmergencialModeCycle" }
	]

	const updateEmergencialCoverageMode = (value: number) => {
		tipsContext.showTip(EmergencialCoverageModeValues.find(r => r.value == value)?.tipsContext ?? "")
		if (value === EmergencialCoverageMode.emergencialCoverageModeNotDefined) {
			setEmergencialCoverageMode(null);
		} else {
			setEmergencialCoverageMode(value);
		}
	}

	useEffect(() => {
		onUpdate(emergencialCoverageMode);
	}, [emergencialCoverageMode]);

	return (
		<SBBox>
			<SBTypography
				mb={1}
				fontWeight="500"
				color="gray3.main"
				variant={"body1"}
				textTransform={"uppercase"}>
				{SBTranslate("emergencialCoverageMode")}
			</SBTypography>
			<SBSelect
				fullWidth
				getText={item => item.text}
				getValue={item => item.value}
				onChange={(event) => updateEmergencialCoverageMode(Number(event.target.value))}
				value={emergencialCoverageMode ?? EmergencialCoverageMode.emergencialCoverageModeNotDefined}
				items={EmergencialCoverageModeValues}
			></SBSelect>
		</SBBox>
	);
};


interface ConfigEmergencialCoverageModeProps {
	value: EmergencialCoverageMode;
	onUpdate: (v: EmergencialCoverageMode | null) => void;
}
export default ConfigEmergencialCoverageMode;
