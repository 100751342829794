import { CellContext, ColumnDef } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useStyles } from "./style";

export const SBEditableColumn = (props: SBEditableColumnProps) => {
	const {
		getValue,
		row: { index },
		column: { id },
		table,
	} = props;

	const classes = useStyles();
	const initialValue = getValue();

	const [value, setValue] = useState<any>(initialValue);
	const [isOpen, setIsOpen] = useState(false);

	const onBlur = () => {
		(table.options.meta as any)?.updateData(index, id, value);
		setIsOpen(false);
	};

	useEffect(() => setValue(initialValue), [initialValue]);

	return isOpen ? (
		<input
			value={value as string}
			onChange={(e) => setValue(e.target.value)}
			onBlur={onBlur}
			className={classes.editableColumn}
		/>
	) : (
		<div
			className={classes.editableColumnPlaceholder}
			onMouseEnter={() => setIsOpen(true)}
		>
			{value}
		</div>
	);
};

interface SBEditableColumnProps extends CellContext<any, unknown> {}
