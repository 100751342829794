import {
	SBBox,
	SBCard,
	SBIcon,
	SBIconButton,
	SBTypography,
} from "modules/Commons";
import { useTranslation } from "react-i18next";
import TransferenceSlideInfo from "../TransferenceSlideInfo";
import { useEffect, useState } from "react";
import { TransferenceResponse } from "modules/Transferences/models/transferenceResponse";
import { useCSV } from "hooks/useCSV";
import { useTransferenceContext } from "contexts/TransferenceContext";
import TransferenceProcessLoadingState from "../TransferenceProcessLoadingState";
import { useStyles } from "./style";
import TransferenceProcessInfo from "../TransferenceProcessInfo";

export default ({ isOpen, close }: TransferenceInfoModalProps) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { toCSV, downloadCSV } = useCSV();
	const { transferenceStatus, transferences } = useTransferenceContext();
	const [failedTransferences, setFailedTransferences] = useState<
		TransferenceResponse[]
	>([]);
	const [completedTransferencessCount, setCompletedTransferencessCount] =
		useState<number>(0);
	const [failedTransferencessCount, setFailedTransferencessCount] =
		useState<number>(0);
	const [maximized, setMaximized] = useState<boolean>(false);

	const handleMaximizeClick = () => {
		setMaximized(!maximized);
	};

	const downloadTransferCSV = () => {
		if (transferenceStatus === "done") {
			const transfersCSV = toCSV({
				json: failedTransferences,
				separator: ";",
				headers: [
					"sourceStore",
					"destinationStore",
					"product",
					"description",
				],
			});
			downloadCSV({ csv: transfersCSV, title: "Failed_Transferences" });
		}
	};

	useEffect(() => {
		if (transferenceStatus === "done") {
			const failed = transferences?.filter((t) => t.status !== 0);
			setFailedTransferences(failed);
			setCompletedTransferencessCount(
				transferences?.length - failed.length
			);
			setFailedTransferencessCount(failed.length);
		} else {
			setFailedTransferences([]);
			setCompletedTransferencessCount(0);
			setFailedTransferencessCount(0);
			setMaximized(false);
		}
	}, [transferenceStatus]);

	return (
		<TransferenceSlideInfo open={isOpen}>
			<SBCard
				style={{
					borderRadius: "8px",
					backgroundColor: "#F9F8FD",
					border: "1px solid #7F51FF",
				}}
			>
				<SBBox className={classes.modalContainer}>
					<SBBox className={classes.modalHeader}>
						<SBTypography variant="body1" fontWeight="600">
							{t("transferProcess")}
						</SBTypography>
						<SBBox>
							{failedTransferencessCount > 0 ? (
								<>
									<SBIconButton onClick={handleMaximizeClick}>
										<SBIcon
											icon={
												maximized
													? "minimize"
													: "maximize"
											}
										/>
									</SBIconButton>
									<SBIconButton onClick={downloadTransferCSV}>
										<SBIcon icon="download" />
									</SBIconButton>
								</>
							) : (
								<></>
							)}
							<SBIconButton onClick={close}>
								<SBIcon icon="x" />
							</SBIconButton>
						</SBBox>
					</SBBox>
					<SBBox>
						{transferenceStatus === "done" ? (
							<TransferenceProcessInfo
								completedTransferencessCount={
									completedTransferencessCount
								}
								failedTransferencessCount={
									failedTransferencessCount
								}
								failedTransferences={failedTransferences}
								maximized={maximized}
							/>
						) : (
							<TransferenceProcessLoadingState />
						)}
					</SBBox>
				</SBBox>
			</SBCard>
		</TransferenceSlideInfo>
	);
};

interface TransferenceInfoModalProps {
	isOpen: boolean;
	close: () => void;
}
