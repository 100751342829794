import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	sbSimulationChartFilterLineIndicator: {
		height: 2,
		width: 15,
		border: "2px solid",
		marginRight: 5,
	},
	sbSimulationChartFilterRoundIndicator: {
		height: 15,
		width: 15,
		borderRadius: "50%",
		border: "2px dotted",
		marginRight: 5,
	},
}));
