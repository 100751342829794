import { SBBox, SBGrid, SBTypography } from "modules/Commons";
import { SBTranslate } from "i18n";
import { DateTime } from "luxon";

export default ({ context }: SBContextDateInfoProps) => {
    return (
        <SBGrid item>
            <SBBox
                px={2}
                py={0.8}
                mt={3}
            >
                <SBTypography
                    variant="body2"
                    color="#F2994A"
                    fontWeight={600}
                >
                    {SBTranslate("views.home.contextDateInfo", { date: context?.toFormat(SBTranslate("dateFormat")) })}
                </SBTypography>
            </SBBox>
        </SBGrid>
    );
};

export interface SBContextDateInfoProps {
    context: DateTime;
}