import { Authorization, Concept, useClientRules } from "hooks/useClientRules";

export default () => {
	const { authorizations } = useClientRules();

	const hasIntegration = (concept: Concept, authorization: Authorization) => {
		const auth = authorizations;
		return auth[concept] >= authorization;
	};

	return {
		hasPurchaseIntegration: () =>
			hasIntegration(Concept.PURCHASE, Authorization.INTEGRATION),
		hasTransferenceIntegration: () =>
			hasIntegration(Concept.TRANSFERENCE, Authorization.INTEGRATION),
		hasDistributionIntegration: () =>
			hasIntegration(Concept.DISTRIBUTION, Authorization.INTEGRATION),
	};
};
