import { useIdentityAuthentication } from "hooks/useIdentityAuthentication";

export const useAuthenticator = () => {
	let loggedUser: { data: { access_token: string } | null } | {} | null = {};
	let authenticator = useIdentityAuthentication();

	const token = (): string => {
		return authenticator.getToken();
	};

	const login = async (email: string, password: string): Promise<any> => {
		const result = await authenticator.login(email, password);
		loggedUser = result;
	};

	const logout = async () => {
		await authenticator.logout();
		loggedUser = null;
	};

	const isLogged = () => {
		return authenticator.userIsLogged();
	};

	return {
		loggedUser,
		login,
		isLogged,
		logout,
		token,
	};
};
