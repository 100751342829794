import { Switch, SwitchProps } from "@mui/material";

const blueColor = {
	500: "#7F51FF",
};

const greyColor = {
	400: "#BFC7CF",
	500: "#AAB4BE",
};

export default (props: SwitchProps) => {
	return (
		<Switch
			{...props}
			sx={{
				width: "61px",
				height: "41px",
				".MuiSwitch-switchBase": {
					padding: "14px",
					marginLeft: "0.5px",
					".MuiSwitch-thumb": {
						width: "12px",
						height: "12px",
						color: greyColor["500"],
					},
					"&.Mui-checked": {
						".MuiSwitch-thumb": {
							color: blueColor["500"],
						},
					},
				},
				".MuiSwitch-track": {
					bgcolor: "#fff",
					borderRadius: "15px",
					border: `1px solid ${greyColor["500"]}`,
				},
			}}
		/>
	);
};
