import { classes } from "istanbul-lib-coverage";
import {
	SBBox,
	SBGrid,
	SBIcon,
	SBIconButton,
	SBSelect,
	SBTextField,
	SBTypography,
} from "modules/Commons";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";

export const SBDatePickerConfigApply = ({ }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { getValues, setValue } = useFormContext();
	const [scope, setScope] = useState(["day", "week", "month", "year"]);
	const [toApplyItems, setToApplyItems] = useState(
		getValues("toApplyItems") ?? []
	);

	const onChangeText = (value: string, index: number) => {
		setToApplyItems((c: any[]) => {
			const newToApplyItems = c.triggerChange({
				condition: ((_: any, i: number) => index === i)!,
				newValue: (v: any) => ({ ...v, value: +value }),
			});

			setValue("toApplyItems", newToApplyItems);
			return newToApplyItems;
		});
		setScope((c) => [...c]);
	};

	const onChangeScope = (value: string, index: number) => {
		setToApplyItems((c: any[]) => {
			const newToApplyItems = c.triggerChange({
				condition: ((v: any, i: number) => index === i)!,
				newValue: (v: any) => ({ value: 0, scope: value }),
			});

			setValue("toApplyItems", newToApplyItems);
			return newToApplyItems;
		});
		setScope((c) => [...c]);
	};

	const onDelete = (index: number) => {
		setToApplyItems((c: any) => {
			const newToApplyItems = toApplyItems;
			newToApplyItems.splice(index, 1);

			setValue("toApplyItems", newToApplyItems);
			return newToApplyItems;
		});
		setScope((c) => [...c]);
	};
	return (
		<SBBox mt={2} >
			<SBBox display="flex" mb={1} alignItems="center">
				<SBTypography fontWeight="500" mr={1}>
					{t("createCycles", { count: 2 })}
				</SBTypography>
				<SBIconButton
					ml={2}
					onClick={() =>
						setToApplyItems((c: any) => [
							...c,
							{ value: 0, scope: "day" },
						])
					}
				>
					<SBIcon icon="plus-square" color="gray1" />
				</SBIconButton>
			</SBBox>
			<SBBox className={classes.DatePickerConfig}>

				{toApplyItems &&
					toApplyItems.map((item: any, index: any) => {
						return (
							<SBGrid
								key={index}
								container
								display="flex"
								alignItems="center"
								mb={2}
							>
								<SBGrid item xs={4}>
									<SBTextField
										value={item.value}
										onChange={(e) =>
											onChangeText(
												e.target.value.naturalNumber(),
												index
											)
										}
									/>
								</SBGrid>
								<SBGrid item xs={6} pl={2}>
									<SBSelect
										fullWidth
										style={{ width: "100%" }}
										items={scope}
										value={item.scope}
										label={t("period")}
										getText={(v) => t(v)}
										onChange={(e: any) =>
											onChangeScope(e.target.value, index)
										}
									/>
								</SBGrid>
								<SBGrid item xs={2} pl={1} justifyContent="end">
									<SBIconButton
										onClick={() => onDelete(index)}
									>
										<SBIcon icon="trash-2" size={20} />
									</SBIconButton>
								</SBGrid>
							</SBGrid>
						);
					})}
			</SBBox>
		</SBBox>
	);
};
