import { IInventory, IInventoryPosition } from "domain/Inventory";
import { createContext } from "react";

export const InventoryDeadStatusContext = createContext(
    {} as InventoryDeadStatusContextProps
);

export interface InventoryDeadStatusConfig {
    title: string;
    columnName: string;
}

export interface InventoryDeadStatusContextProps {
    fetchData: (
        d: Promise<IInventoryPosition[]>,
        configs: InventoryDeadStatusConfig
    ) => void;
}
