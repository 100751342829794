import { Stack } from "@mui/material";
import { Cell } from "@tanstack/react-table";
import { SBBox, SBChip } from "modules/Commons";
import { SBNewTable } from "modules/Commons/SBDataDisplays/SBTable/SBNewTable";
import { useGlobalInventoryContext } from "modules/Inventory/components/SBGlobalInventoryInfoModal";
import { IPurchasePlan } from "modules/Purchases/models/IPurchasePlans";
import { usePurchaseHeaders } from "modules/Purchases/models/usePurchaseHeaders";
import { forwardRef, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PurchasesSummarization } from "../PurchasesSummarization";
import { usePurchaseReportModal } from "../SBPurchaseReportModal";
import SBPurchaseRecommendationFilter from "../SBPurchaseRecommendationFilter";
import { isBoolean } from "lodash";

export default forwardRef((props: SBPurchaseTableProps, ref: any) => {

	const headers = usePurchaseHeaders();

	const [selectedItems, setSelectedItems] = useState([]);
	const { title, rows, onDataUpdated, containerHeight } = props;
	const { t } = useTranslation();
	const { open, handleOpen } = usePurchaseReportModal();
	const { openMenu, isOpen } = useContext(useGlobalInventoryContext);


	const handleClick = (cell: Cell<IPurchasePlan, any>) => {
		if (cell.column.id === "inventory")
			openMenu(cell.row.original.inventory.id);

		if (cell.column.id === "report")
			handleOpen(cell.row.original);
	};

	return (
		<SBBox mb={2} mt={2}>
			<SBBox>
				<PurchasesSummarization
					withShadow
					items={rows}
					selectedItems={selectedItems.map((index) => rows[+index])}
				/>
			</SBBox>
			<SBBox
				width={isOpen || open ? "50vw" : "100%"}
				style={{ transition: "all 0.25s ease", marginTop: "1rem" }}
			>
				<SBNewTable
					ref={ref}
					hasSelection
					virtualize
					title={t(title)}
					startRows={rows}
					defaultColumns={headers}
					onClick={handleClick}
					onSelect={(_, obj) => setSelectedItems(obj)}
					onDataUpdated={onDataUpdated}
					containerHeight={containerHeight}
					defaultFilter={isBoolean(props.emergencialStatus) ? [{ id: "emergencial", value: props.emergencialStatus }] : []}
					toolbarContent={({ filterByColumnName }: any) => (<SBPurchaseRecommendationFilter
						filterByColumnName={filterByColumnName}
						emergencialStatus={props.emergencialStatus}
					/>)}
				/>
			</SBBox>
		</SBBox>
	);
});

interface SBPurchaseTableProps {
	title: string;
	rows: IPurchasePlan[];
	emergencialStatus?: boolean;
	onDataUpdated: (items: any[]) => void;
	containerHeight?: number;
}
