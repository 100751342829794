import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import { SBTranslate } from "i18n";
import { t } from "i18next";
import { SBIcon } from "modules/Commons";
import { NotificationsRequester } from "modules/Notifications/services/NotificationsRequester";
import { useGlobalInventoriesStatusContext } from "..";
import SBInventoryTableTopItems from "../SBInventoryTableTopItems";
import { useSystemFilterContext } from "contexts/SystemFilterContext";

export default ({ topStats }: SBInventoryScarcityTopItemsProps) => {
	const { filtersContext } = useSystemFilterContext();
	const { fetchData } = useGlobalInventoriesStatusContext();

	const fetchReserveds = async () => {
		fetchData(NotificationsRequester.scarcity(filtersContext), {
			title: "inventoriesInScarcity",
			columnName: "scarcity",
		});
	};

	return (
		<SBInventoryTableTopItems
			name={SBTranslate("inventoriesInScarcity")}
			label={"csl"}
			quantity={5}
			formatter={(c: any) => `${(+c?.csl! * 100).toFixed(0)}%`}
			prepend={<SBIcon icon="select" fillColor="error" size={25} />}
			title={`${t("top5")} - ${t("inventoriesInScarcity")}`}
			showAll={fetchReserveds}
			items={topStats?.currentStatus?.productsWithScarcity as any}
		/>
	);
};

interface SBInventoryScarcityTopItemsProps {
	topStats: IHomeStatistics;
}
