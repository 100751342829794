import { useStyles } from "./style";
import SBTableCell from "modules/Commons/SBDataDisplays/SBTable/SBTableCells/SBTableCell";
import { SBTypography } from "modules/Commons";

/** Table header cell */
export default ({ width, name, align = "right" }: SBTableHeaderCellProps) => {
	const classes = useStyles();

	return (
		<SBTableCell
			data-testid="sb-table-header-cell"
			width={width}
			align={align}
			className={classes.sbTableHeaderCell}
		>
			<SBTypography
				style={{ textTransform: "uppercase" }}
				variant="caption"
				fontWeight="600"
				color="gray3.main"
				noWrap
			>
				{name}
			</SBTypography>
		</SBTableCell>
	);
};

export interface SBTableHeaderCellProps {
	/** Cell width */
	width?: number | string;
	/** Cell alignment */
	align?: "center" | "left" | "right";
	/** Cell display name */
	name: string;
}
