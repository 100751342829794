import { useTheme } from "@mui/system";
import SBBox from "modules/Commons/SBLayouts/SBBox";
import { useTranslation } from "react-i18next";
import {
	PolarAngleAxis,
	RadialBar,
	RadialBarChart,
	ResponsiveContainer,
} from "recharts";
import { BaseThemeOptions } from "themes/base";
import SBTypography from "../SBTypography";

export default ({
	value = 0,
	configs = {
		high: { min: 0.7, max: 1, label: "high" },
		medium: { min: 0.3, max: 0.7, label: "medium" },
		low: { min: 0, max: 0.3, label: "low" },
	},
	thickness = 1,
	fontSize = "0.65rem",
}: SBThreeLevelGauge) => {
	const { t } = useTranslation();
	const theme = useTheme<BaseThemeOptions>();
	var data = [{ value }];

	const isBetween = (value: number, config: { min: number; max: number }) =>
		config.min < value && value <= config.max;

	const getCurrent = () => {
		if (isBetween(value, configs.high)) return configs.high;
		if (isBetween(value, configs.medium)) return configs.medium;
		return configs.low;
	};

	const getColor = () => {
		if (configs.high.max < configs.low.max) {
			if (isBetween(value, configs.high))
				return theme.palette.success.main;
			if (isBetween(value, configs.medium))
				return theme.palette.orange1.main;
			return theme.palette.error2.main;
		} else {
			if (isBetween(value, configs.high))
				return theme.palette.error2.main;
			if (isBetween(value, configs.medium))
				return theme.palette.orange1.main;
			return theme.palette.success.main;
		}
	};

	const mediumRadius = ["90%", "110%"];
	const currentRadius = mediumRadius;

	return (
		<SBBox style={{ position: "relative", width: "100%", height: 170 }}>
			<ResponsiveContainer>
				<RadialBarChart
					innerRadius={currentRadius[0]}
					outerRadius={currentRadius[1]}
					cx="50%"
					cy="70%"
					startAngle={180}
					endAngle={0}
					data={data}
				>
					<PolarAngleAxis
						type="number"
						domain={
							configs.low.min < configs.high.max
								? [configs.low.min, configs.high.max]
								: [configs.high.min, configs.low.max]
						}
						angleAxisId={0}
						tick={false}
					/>

					<RadialBar
						height={200}
						cornerRadius={8}
						background
						dataKey="value"
						angleAxisId={0}
						fill={getColor()}
					/>
				</RadialBarChart>
			</ResponsiveContainer>
			<SBBox
				style={{
					position: "absolute",
					top: "47%",
					left: "50%",
					transform: "translate(-50%, -0%)",
					color: getColor(),
				}}
			>
				<SBTypography
					fontWeight="bold"
					textTransform="capitalize"
					style={{ fontSize }}
				>
					{t(getCurrent().label)}
				</SBTypography>
				<SBTypography
					textAlign="center"
					fontWeight="bold"
					textTransform="capitalize"
					variant="body2"
				>
					{value.systemFormat({
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
					})}
				</SBTypography>
			</SBBox>
		</SBBox>
	);
};

interface SBThreeLevelGauge {
	value: number;
	thickness?: number;
	fontSize?: string | number;
	configs?: {
		high: { min: number; max: number; label: string };
		medium: { min: number; max: number; label: string };
		low: { min: number; max: number; label: string };
	};
}
