import { styled } from "@mui/styles";
import { SBBox } from "modules/Commons";
import { BaseThemeOptions } from "themes/base";

const labelSize = "47px";

export const SlideMenuContent = styled(SBBox)(
	({ theme }: SlideMenuContentProps) => ({
		alignItems: "center",
		display: "flex",
		justifyContent: "space-between",
		//@ts-ignore
		padding: theme.spacing(1),
	})
);

interface SlideMenuContentProps {
	theme: BaseThemeOptions;
}
