import clsx from "clsx";
import { SBBox, SBSelect, SBTypography } from "modules/Commons";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { IChart } from "domain/Chart/Entities/IChart";
import { IChartFilter } from "domain/Chart/Entities/IChartFilter";
import { ISimulationPoint } from "modules/Simulations/models/ISimulationPoint";

export default (props: SimulationChartFilterProps) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { showingCharts, setShowingCharts, filters, setFilters } = props;

	const getChartBackgroundColor = (chart: IChartFilter) =>
		chart.color + (chart.show ? "88" : "22");

	const getChartBorderColor = (chart: IChartFilter) =>
		chart.color + (chart.show ? "FF" : "22");

	const getChartTitleColor = (chart: IChartFilter) =>
		chart.show ? "#000000" : "#999";

	return (
		<SBBox data-testid="simulation-chart-filter" display="flex">
			<SBBox
				display="flex"
				flexDirection="column"
				justifyContent="center"
			>
				<SBBox display="flex" mr={5}>
					{showingCharts &&
						showingCharts.map(
							(
								chart: IChart<ISimulationPoint>,
								index: number
							) => {
								const backgroundColor =
									getChartBackgroundColor(chart);
								const borderColor = getChartBorderColor(chart);
								const titleColor = getChartTitleColor(chart);

								return (
									<SBBox
										key={index}
										mr={2}
										display="flex"
										alignItems="center"
										style={{ cursor: "pointer" }}
										onClick={() =>
											setShowingCharts!({
												...chart,
												show: !chart.show,
											})
										}
									>
										<SBBox
											style={{
												borderColor,
												backgroundColor,
											}}
											className={clsx(
												classes.sbSimulationChartFilterRoundIndicator
											)}
										/>
										<SBTypography color={titleColor} noWrap>
											{t(chart.label)}
										</SBTypography>
									</SBBox>
								);
							}
						)}
				</SBBox>
			</SBBox>
			<SBBox
				display="flex"
				flexDirection="column"
				justifyContent="center"
			>
				<SBBox display="flex" alignItems="center">
					{filters &&
						filters.map((chart, index) => {
							const backgroundColor =
								getChartBackgroundColor(chart);
							const borderColor = getChartBorderColor(chart);
							const titleColor = getChartTitleColor(chart);

							return (
								<SBBox
									key={index}
									mr={2}
									display="flex"
									alignItems="center"
									style={{ cursor: "pointer" }}
									onClick={() =>
										setFilters!({
											...chart,
											show: !chart.show,
										})
									}
								>
									<SBBox
										style={{
											borderColor,
											backgroundColor,
										}}
										className={clsx(
											classes.sbSimulationChartFilterLineIndicator
										)}
									/>
									<SBTypography
										variant="body2"
										color={titleColor}
										noWrap
									>
										{t(chart.label)}
									</SBTypography>
								</SBBox>
							);
						})}
				</SBBox>
			</SBBox>
		</SBBox>
	);
};

export interface SimulationChartFilterProps {
	showingCharts?: IChart<ISimulationPoint>[];
	setShowingCharts?: (charts: IChart<ISimulationPoint>) => void;
	filters?: IChartFilter[];
	setFilters?: (charts: IChartFilter) => void;
}
