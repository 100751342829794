import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import { SBTranslate } from "i18n";
import { t } from "i18next";
import { SBBox, SBIcon } from "modules/Commons";
import { NotificationsRequester } from "modules/Notifications/services/NotificationsRequester";
import { formatInteger } from "utils/formatters";
import { useGlobalInventoriesStatusContext } from "..";
import SBInventoryTableTopItems from "../SBInventoryTableTopItems";
import { useSystemFilterContext } from "contexts/SystemFilterContext";

export default ({ topStats }: SBInventoryReservedTopItemsProps) => {
	const { filtersContext } = useSystemFilterContext();
	const { fetchData } = useGlobalInventoriesStatusContext();

	const fetchReserveds = async () => {
		fetchData(NotificationsRequester.reserveds(filtersContext), {
			title: SBTranslate("commons.pendingInventory"),
			columnName: SBTranslate("commons.pending"),
		});
	};

	return (
		<SBInventoryTableTopItems
			name={SBTranslate("commons.pendingInventory")}
			quantity={5}
			label={SBTranslate("commons.pending")}
			formatter={(c: any) =>
				`${formatInteger(+c?.inventory?.reserved!)} ${t("unit")}`
			}
			prepend={
				<SBBox pb={0.5}>
					<SBIcon icon="alert-triangle" color="warning" size={20} />
				</SBBox>
			}
			title={`${t("top5")} - ${SBTranslate("commons.pendingInventory")}`}
			items={topStats?.currentStatus?.productsWithReserved as any}
			showAll={fetchReserveds}
		/>
	);
};

interface SBInventoryReservedTopItemsProps {
	topStats: IHomeStatistics;
}
