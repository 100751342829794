import { SBBox, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";

export default () => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<SBBox className={classes.container} mt={2} mb={2}>
			<SBBox display="flex" flexDirection="column">
				<SBTypography p={2} variant="body1" fontWeight="400">
					{t("transferInfoWarning")}
				</SBTypography>
			</SBBox>
		</SBBox>
	);
};
