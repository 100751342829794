import { Typography } from "@mui/material";
import { Cell, Row, flexRender } from "@tanstack/react-table";
import { SBBox, SBIcon, SBIconButton } from "modules/Commons";
import { useSystemTheme } from "themes/base";

export default (props: SBTableColumnProps) => {
	const { cell, row, onClick, isSelected } = props;
	const theme = useSystemTheme();
	const width = isNaN(cell.column.getSize()) ? 0 : cell.column.getSize();
	//@ts-ignore
	const prepend = cell.column.columnDef.prepend;
	return (
		<td
			key={cell.id}
			onClick={onClick}
			style={{
				//minWidth: width,
				width,
				backgroundColor: isSelected ? `${theme.palette.border1.main}` : cell.getIsGrouped() ? `${theme.palette.lightPurple.main}` : cell.getIsAggregated() ? `${theme.palette.purple7.main}` : "",
				textAlign:
					//@ts-ignore
					cell.column.columnDef.align,
				//@ts-ignore
				styleFn: cell.column.styleFn?.(cell.row.original),
			}}
		>
			<div style={{ display: "flex", alignItems: "center" }}>
				{prepend?.(cell.row.original)}
				{cell.getIsGrouped() ? (
					<SBBox display="flex" alignItems="center">
						<SBIconButton onClick={row?.getToggleExpandedHandler()} sx={{ p: "2px", mr: "6px" }}>
							<SBIcon size={20} icon={row?.getIsExpanded() ? "chevron-down" : "chevron-right"} color={theme.palette.gray2.main} />
						</SBIconButton>
						{flexRender(cell.column.columnDef.cell, cell.getContext())}
						<Typography color="primary.main" fontWeight="bold" ml={0.5}>
							({row?.subRows.length})
						</Typography>
					</SBBox>
				) : cell.getIsAggregated() ? (
					flexRender(
						cell.column.columnDef.aggregatedCell ??
						cell.column.columnDef.cell,
						cell.getContext()
					)
				) : cell.getIsPlaceholder() ? null : (
					flexRender(
						cell.column.columnDef.cell,
						cell.getContext()
					)
				)}
			</div>
		</td>
	);
};

interface SBTableColumnProps {
	cell: Cell<any, unknown>;
	row?: Row<any>;
	isSelected: boolean;
	onClick: () => void;
}
