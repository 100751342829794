import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	leftbar: {
		position: "fixed",
		top: 76,
		left: 15,
		height: "calc(100vh - 90px)",
		alignContent: "center",
		boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
		backgroundColor: "white",
		zIndex: 1,
		"& button": {
			padding: 10,
			borderRadius: 4,
			margin: 8,
		},
	},
	hoverable: {
		marginBottom: 10,
		fontWeight: 600,
		padding: "4px 16px",
		color: theme.palette.blackTitle.main,
		"&:hover": {
			color: theme.palette.primary.main,
			backgroundColor: theme.palette.lightPurple2.main,
			fontWeight: 600,
		},
	},
	title: {
		marginTop: "20px",
		fontWeight: 700,
		lineHeight: "22px",
		color: theme.palette.blackTitle.main,
		padding: "4px 16px",
	},
}));
