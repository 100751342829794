import { SBTranslate } from "i18n";
import { IPurchasePlan } from "modules/Purchases/models/IPurchasePlans";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SBSummarization from "views/Commons/SBSummarization";

export const PurchasesSummarization = ({
	items,
	selectedItems,
	withShadow,
}: {
	items: IPurchasePlan[];
	selectedItems?: IPurchasePlan[];
	withShadow: boolean;
}) => {
	const { t, i18n } = useTranslation();

	const sumOfPurchaseUnits = (plans: IPurchasePlan[]) =>
		plans
			.reduce((a: number, b: IPurchasePlan) => a + +b.quantity, 0)
			.toLocaleString("pt-BR", { minimumFractionDigits: 0 });

	const sumOfEstimatedCostPlusFreight = (plans: IPurchasePlan[]) =>
		plans
			.reduce(
				(a: number, b: IPurchasePlan) =>
					a + (b.unitCost * b.quantity || 0),
				0
			)
			.toLocaleString("pt-BR", { minimumFractionDigits: 2 });

	const countOfInventories = (plans: IPurchasePlan[]) =>
		plans.map((c) => c.inventory.id).distinct().length;

	const countOfAlerts = (plans: IPurchasePlan[]) =>
		plans.filter((plan) => plan.warning.indexOf("None") === -1).length;

	const countOfEmergencials = (plans: IPurchasePlan[]) =>
		plans.filter((plan) => plan.cycle?.isEmergencial).length;

	const summarizations = useMemo(
		() => [
			{
				name: SBTranslate("purchaseUnit"),
				tip: SBTranslate("tips.purchaseUnit"),
				icon: "package",
				color: "darkPurple",
				value: sumOfPurchaseUnits(items),
				selectedItems:
					!!selectedItems && selectedItems.length > 0
						? sumOfPurchaseUnits(selectedItems)
						: undefined,
			},
			{
				name: SBTranslate("estimatedCostPlusFreight"),
				tip: SBTranslate("tips.estimatedCostPlusFreight"),
				icon: "dollar-sign",
				color: "darkPurple",
				value: sumOfEstimatedCostPlusFreight(items),
				selectedItems:
					!!selectedItems && selectedItems.length > 0
						? sumOfEstimatedCostPlusFreight(selectedItems)
						: undefined,
			},
			{
				name: SBTranslate("inventory_other"),
				tip: SBTranslate("tips.inventoryRecomendation"),
				icon: "package",
				color: "darkPurple",
				value: countOfInventories(items),
				selectedItems:
					!!selectedItems && selectedItems.length > 0
						? countOfInventories(selectedItems)
						: undefined,
			},
			{
				name: SBTranslate("alert_other"),
				tip: SBTranslate("tips.alertRecomendation"),
				icon: "sirene",
				fillColor: "darkPurple",
				value: countOfAlerts(items),
				selectedItems:
					!!selectedItems && selectedItems.length > 0
						? countOfAlerts(selectedItems)
						: undefined,
			},
			{
				name: SBTranslate("isEmergencial"),
				tip: SBTranslate("tips.isEmergencial"),
				icon: "alert-triangle",
				color: "darkPurple",
				value: countOfEmergencials(items),
				selectedItems:
					!!selectedItems && selectedItems.length > 0
						? countOfEmergencials(selectedItems)
						: undefined,
			},
		],
		[items, selectedItems, i18n.language]
	);

	if (!items || items.length === 0) return null;

	return <SBSummarization items={summarizations} withShadow={withShadow} />;
};
