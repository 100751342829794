import clsx from "clsx";
import { IInventory } from "domain/Inventory/Models/IInventory";
import {
	SBBox,
	SBIcon,
	SBIconButton,
	SBTooltip,
	SBTypography,
} from "modules/Commons";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalInventoryContext } from "modules/Inventory/components";
import { useStyles } from "./styles";

export default ({ item, isRow, hideStatistics, hideBorder }: SBTitleProps) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { openMenu } = useContext(useGlobalInventoryContext);

	const inventoriesInfo = [
		{
			name: "product",
			icon: "package",
			getter: (c: IInventory) => c?.product,
		},
		{
			name: "store",
			icon: "home",
			getter: (c: IInventory) => c?.store,
		},
	];

	return (
		<SBBox
			display="flex"
			className={clsx(!hideBorder && "bordered")}
			alignItems="center"
			width={0.7}
		>
			<SBBox
				width={0.9}
				className={clsx(classes.inventoryTitle, isRow && "row")}
			>
				{inventoriesInfo.map((inventoryInfo) => (
					<SBBox className="content" key={inventoryInfo.name}>
						<SBTooltip
							title={`${inventoryInfo.getter(item)?.clientID} ${inventoryInfo.getter(item)?.name
								}`}
						>
							<>
								<SBBox display="flex" alignItems="center">
									<SBIcon
										icon={inventoryInfo.icon}
										size={15}
									/>
									<SBTypography className="title">
										{t(inventoryInfo.name)}
									</SBTypography>
								</SBBox>
								<SBBox display="flex">
									<SBTypography className="name">
										{inventoryInfo.getter(item)?.clientID ??
											"-"}
									</SBTypography>
									<SBTypography className="name">
										{inventoryInfo.getter(item)?.name ??
											"-"}
									</SBTypography>
								</SBBox>
							</>
						</SBTooltip>
					</SBBox>
				))}
			</SBBox>
			{!hideStatistics ? (
				<SBBox width={0.1} ml="auto">
					<SBTooltip title={t("inventoryDetails")}>
						<SBIconButton onClick={() => openMenu(item.id)}>
							<SBIcon icon="inventory" hoverFillColor="primary" />
						</SBIconButton>
					</SBTooltip>
				</SBBox>
			) : null}
		</SBBox>
	);
};

interface SBTitleProps {
	/** Inventory to show */
	item: IInventory;
	/** Indicates if will show as row or as column */
	isRow?: boolean;
	/** Flag to enable button to show the statistics modal */
	hideStatistics?: boolean;
	hideBorder?: boolean;
}
