import { ClickAwayListener, Popper } from "@mui/material";
import { SBBox, SBButton, SBCard, SBIcon, SBIconButton, SBLoading, SBTypography } from "modules/Commons";
import { useEffect, useState } from "react";
import SBNotificationCard from "./SBNotificationCard";
import { useStyles } from "./styles";
import { INotification } from "domain/Utils/models/INotification";
import NotificationPubSub from "modules/SystemNotification/services/NotificationPubSub";
import { SystemNotificationsRequester } from "modules/SystemNotification/services/SystemNotificationsRequester";
import { SBTranslate } from "i18n";
import routerHistory from "hooks/useRouterHistory";
import SBEmptyState from "modules/Commons/SBLayouts/SBEmptyState";
import SBNotificationEmptyState from "./SBNotificationEmptyState";
import clsx from "clsx";

export default (() => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [shortNotifications, setShortNotifications] = useState<INotification[]>([]);
	const [notSeenCount, setNotSeenCount] = useState<number>(0);
	const [counterLimit, setCounterLimit] = useState<boolean>(false);

	const classes = useStyles();
	const maxNotificationCounter = 99;

	useEffect(() => {
		fetchNotSeenCount().then(result => setNotSeenCount(result));
	}, [])

	useEffect(() => {
		if (notSeenCount > maxNotificationCounter) setCounterLimit(true);
		else setCounterLimit(false);
	}, [notSeenCount])

	NotificationPubSub({
		onConnected: (msg: string) => { console.log(msg) },
		onReceiveNotification: () => {
			setNotSeenCount((current) => current + 1);
		},
	});

	const fetchNotSeenCount = async () => {
		return await SystemNotificationsRequester.fetchNotSeenCount()
			.then((result) => {
				return result;
			});
	};

	const fetchShortNotifications = async () => {
		return await SystemNotificationsRequester.fetchNotifications(0, 5)
			.then((result) => {
				return result;
			});
	};


	const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen(!open);

		if (!open) {
			var justSeen = [] as string[];
			setLoading(true);

			fetchShortNotifications()
				.then((result) => {
					setShortNotifications(result);
					justSeen = result.filter(r => !r.seen).map(n => n.guid);
				})
				.finally(() => {
					setLoading(false);
					setNotSeenCount((current) => current - justSeen.length);
				});
		}
	};

	const handleSeeAllNotifications = () => {
		routerHistory.push("/notifications");
		setOpen(false);
		setNotSeenCount(0);
	};

	const handleClickAway = () => {
		setOpen(false);
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<SBBox>
				<Popper
					className={classes.popper}
					placement="bottom-end"
					open={open}
					anchorEl={anchorEl}
				>
					<SBCard className={classes.notificationsContainer}>
						<SBLoading loading={loading} limited adjustedPadding={false}>
							<SBEmptyState
								emptyState={<SBNotificationEmptyState />}
								show={shortNotifications && shortNotifications.length > 0}
							>
								{shortNotifications && shortNotifications.map((notification: INotification) => (
									<SBNotificationCard notificationDTO={notification} onClose={() => setOpen(false)} />
								))}
								<SBBox className={classes.notificationsButtonSeeAll}>
									<SBButton variant="text" onClick={() => handleSeeAllNotifications()}>
										<SBTypography fontSize={14} textTransform="none">
											{SBTranslate("notification.seeAllNotifications")}
										</SBTypography>
									</SBButton>
								</SBBox>
							</SBEmptyState>
						</SBLoading>
					</SBCard>
				</Popper>
				<SBIconButton onClick={handleClick} className={classes.notificationIcon}>
					{notSeenCount > 0 ? <SBBox className={clsx(classes.notificationInfo, counterLimit && "counterLimit")}>{counterLimit ? "99+" : notSeenCount}</SBBox> : <></>}
					<SBIcon icon="bell" color="primary" />
				</SBIconButton>
			</SBBox>

		</ClickAwayListener>
	)
});