import { DateTime } from "luxon";
import {
	SBBox,
	SBCard,
	SBGrid,
	SBLoading,
	SBTypography,
} from "modules/Commons";
import SBStatusCard from "modules/SystemContext/components/SBStatusCard";
import { MaintenanceResult } from "modules/SystemContext/domain/models/MaintenanceResult";
import { CacheRequester } from "modules/SystemContext/services/CacheRequester";
import { SystemContextRequester } from "modules/SystemContext/services/SystemContextRequester";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default () => {
	const { t } = useTranslation();
	const [maintenenceResult, setMaintenenceResult] = useState(
		{} as MaintenanceResult
	);
	const [loading, setLoading] = useState(false);
	const [lastDatabaseUpdate, setLastDatabaseUpdate] = useState("");
	const [latestOrder, setLatestOrder] = useState("");
	const [isOnMaintenance, setIsOnMaintenance] = useState(false);

	useEffect(() => {
		setLoading(true);
		handleMaintenence();
	}, []);

	const handleConfirm = () => {
		CacheRequester.runCache().then(() => {
			handleMaintenence();
		});
	};

	const handleMaintenence = () => {
		SystemContextRequester.status()
			.then((d) => {
				setMaintenenceResult(d.result ?? {});
				setLatestOrder(
					DateTime.fromISO(d.latestOrder).toFormat(t("dateFormat"))
				);
				setLastDatabaseUpdate(
					DateTime.fromISO(d.lastDataBaseUpdate).toFormat(
						t("dateFormat")
					)
				);
				setIsOnMaintenance(d.onMaintenance);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<SBBox height={1} pl={11}>
			<SBLoading loading={loading}>
				<SBGrid container>
					<SBGrid item xs={6} pr={2}>
						<SBStatusCard
							handleConfirm={handleConfirm}
							maintenenceResult={maintenenceResult}
							canRunProcess={!isOnMaintenance}
						/>
					</SBGrid>
					<SBGrid item xs={3} pr={2}>
						<SBCard style={{ width: "100%" }}>
							<SBBox p={2}>
								<SBTypography mr={1} variant="h6">
									{t("lastDatabaseUpdate")}
								</SBTypography>

								<SBTypography>
									{t("date")}: {lastDatabaseUpdate}
								</SBTypography>
							</SBBox>
						</SBCard>
					</SBGrid>
					<SBGrid item xs={3} pr={2}>
						<SBCard style={{ width: "100%" }}>
							<SBBox p={2}>
								<SBTypography mr={1} variant="h6">
									{t("latestOrder")}
								</SBTypography>

								<SBTypography>
									{t("date")}: {latestOrder}
								</SBTypography>
							</SBBox>
						</SBCard>
					</SBGrid>
				</SBGrid>
			</SBLoading>
		</SBBox>
	);
};
