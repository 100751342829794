import { initReactI18next } from "react-i18next";
import ptBR from "./locales/pt/pt-br.json";
import enUS from "./locales/en/en-us.json";
import i18n from "i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";

const resources = {
	"pt-BR": ptBR,
	"en-US": enUS,
};

i18n.use(intervalPlural)
	.use(initReactI18next)
	.init({
		fallbackLng: "en",
		debug: false,
		resources,
		lng: navigator.language,
		keySeparator: ".",
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;

type NestedKeyOf<ObjectType extends object> = {
	[Key in keyof ObjectType &
		(string | number)]: ObjectType[Key] extends object
		? //@ts-ignore
		  `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
		: `${Key}`;
}[keyof ObjectType & (string | number)];

export const SBTranslate = (
	key: SBTranslateType,
	obj?: { count?: number } & { [key: string]: any }
) => {
	return i18n.t(key + "", obj);
};

export type SBTranslateType = NestedKeyOf<typeof ptBR.translation> &
	NestedKeyOf<typeof enUS.translation>;
