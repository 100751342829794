import { Button } from "@mui/material";
import { IValidation } from "domain/Utils/models/IValidation";
import { useWindowSize } from "hooks/useWindowResize";
import _ from "lodash";
import SBIcon from "modules/Commons/SBDataDisplays/SBIcon";
import SBIconButton from "modules/Commons/SBForms/SBIconButton";
import SBBox from "modules/Commons/SBLayouts/SBBox";
import { useEffect, useMemo, useState } from "react";
import { Treemap } from "recharts";
import { useSystemTheme } from "themes/base";
import { CustomizedContent } from "./Content";

export default ({
	onClick,
	data,
	accessibles,
	updateValidations,
}: SBTreemapMultilevelProps) => {
	const theme = useSystemTheme();
	const [width] = useWindowSize();
	const [depth, setDepth] = useState(0);
	const [treeModels, setTreeModels] = useState<SBTreeMapModel<IValidation>[]>(
		[]
	);
	const [selectedItems, setSelectedItems] = useState<
		SBTreeMapModel<IValidation>[]
	>([]);

	const colors = {
		fail: theme.palette.error.main,
		ok: theme.palette.success.main,
		warning: theme.palette.warning.main,
	};

	const [selectedNode, setSelectedNode] = useState<string>("");

	const buildTreeModel = (
		items: IValidation[],
		itemDepth: number,
		color?: string
	) => {
		if (itemDepth === accessibles.length) return [];

		const arr = [];
		const groupByKey = accessibles[itemDepth];
		const groupedModel = items.groupBy((c: IValidation) =>
			_.get(c, groupByKey)
		) as { [key: string]: IValidation[] };

		for (var groupedModelKey of Object.keys(groupedModel)) {
			const validation = groupedModel[groupedModelKey];
			const modelColor =
				//@ts-ignore
				color ?? colors[groupedModelKey.toLowerCase()] ?? "";
			const treeMapModel = {
				name: groupedModelKey,
				values: validation,
				size: validation.length,
				color: modelColor,
				//color: `${modelColor}${(255 - itemDepth * 30).toString(16)}`,
				children: buildTreeModel(validation, itemDepth + 1, modelColor),
			} as SBTreeMapModel<IValidation>;

			arr.push(treeMapModel);
		}

		return arr;
	};

	const zoomOut = () => {
		if (depth - 1 >= 0) {
			setSelectedItems(treeModels);
			updateValidations(treeModels);
			setDepth(0);
		}
	};

	const zoomIn = (label: string) => {
		if (depth < accessibles.length - 1) {
			setDepth((depth) => depth + 1);
			setSelectedNode(label);
		}
	};

	useEffect(() => {
		const currentData =
			selectedItems.find((model) => model.name === selectedNode)
				?.children ?? [];

		setSelectedItems(currentData);
		updateValidations(currentData);
	}, [selectedNode]);

	useEffect(() => {
		const models = buildTreeModel(data, 0, undefined);
		setTreeModels(models);
		setSelectedItems(models);
	}, [accessibles]);

	const treeMapWidth = useMemo(() => width - 390, [width]);

	return (
		<SBBox>
			{/* <Button variant="contained" onClick={zoomOut}>
				{"Reset"}
			</Button> */}
			<Treemap
				isAnimationActive={true}
				animationDuration={500}
				width={treeMapWidth}
				height={180}
				data={selectedItems}
				dataKey="size"
				aspectRatio={6}
				content={
					<CustomizedContent currentDepth={depth} onClick={zoomIn} />
				}
			/>
		</SBBox>
	);
};

interface SBTreemapMultilevelProps {
	onClick?: (name: string, value: any) => void;
	data: any[];
	accessibles: string[];
	updateValidations: (data: { values: IValidation[] }[]) => void;
}

interface SBTreeMapModel<T> {
	name: string;
	size: number;
	children: SBTreeMapModel<T>[];
	values: T[];
	color?: string;
}
