import { IEntity } from "domain/Domain/Entities/IEntity";
import { SBChip, SBGrid } from "modules/Commons";
import { useStyles } from "./style";

export default ({ items, onDelete }: SBSystemSearchChipsProps) => {
	const classes = useStyles();
	return (
		<SBGrid container spacing={0.5} className={classes.selectionBox}>
			{items?.map((c) => (
				<SBGrid key={c?.clientID} item>
					<SBChip
						color={c.color}
						icon={c.icon}
						label={c?.clientID}
						onDelete={() => onDelete(c)}
					/>
				</SBGrid>
			))}
		</SBGrid>
	);
};

interface SBSystemSearchChipsProps {
	/** Entities to show as chips */
	items: IEntity[];
	/** Action on click delete chip */
	onDelete: (value: IEntity) => void;
}
