import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	actions: {
		height: "0%",
		transition: "all 0s ease-in",
		"&.open": {
			height: "83px",
			transition: "all .5s ease-out",
		},
	},
	divider: {
		"&.MuiDivider-root": {
			margin: "0 16px",
		},
	},
	actionsButtons: {
		display: "flex",
		justifyContent: "space-between",
		padding: "24px 16px",
		"&.justifyEnd": {
			justifyContent: "end",
		},
	},
	deleteFileButton: {
		"&.MuiButton-root": {
			backgroundColor: `${theme.palette.gray7.main}`,
			boxShadow: "none",
			border: `1px solid ${theme.palette.gray5.main}`,
		},
		"&.MuiButton-root:hover": {
			backgroundColor: `${theme.palette.gray7.main}`,
		},
		"&.hide": {
			display: "none",
		},
	},
}));
