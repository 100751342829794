import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { useClickAway } from "hooks/useClickAway";
import {
	SBBox,
	SBChip,
	SBIcon,
	SBIconButton,
	SBTooltip,
	SBTypography,
} from "modules/Commons";
import { useState, useRef, useEffect } from "react";
import { useStyles } from "./style";
import { IEntity } from "domain/Domain/Entities/IEntity";
import SBSystemSearchContainer from "./SBSystemSearchContainer";
import { useTranslation } from "react-i18next";
import useArrayState from "hooks/useArrayState";
import { useSystemFilterContext } from "contexts/SystemFilterContext";

export default ({
	loading = false,
	defaultItems,
	itemSize = 59,
	fullWidth = false,
	filterIsolated = "",
	filterType = undefined,
	useFiltersOptions = undefined,
	categoryDeep,
	onlyActives = true,
	onClick = () => { },
	onChange = () => { },
	solo,
}: SBSystemSearchProps) => {
	const { t } = useTranslation();
	const { filtersContext } = useSystemFilterContext();
	const classes = useStyles();
	const [opened, setOpened] = useState(false);
	const [selectedItems, setSelectedItems] =
		useArrayState<IEntity>(solo ? defaultItems : filtersContext?.items);

	const containerRef = useRef();

	useClickAway(containerRef, () => setOpened(false));

	const handleOnChange = (items: IEntity[]) => {
		setSelectedItems(items);
		onChange(items);
	};

	const excessiveItems =
		selectedItems
			.slice(2, selectedItems.length)
			.map(item => <>{item.clientID}<br /></>);

	useEffect(() => {
		!solo && setSelectedItems(filtersContext?.items || [])
	}, [filtersContext]);

	return (
		<SBBox
			className={clsx(classes.mainBox, fullWidth && classes.fullWidth)}
			onClick={onClick}
		>
			<SBBox
				className={clsx(
					classes.selectMainContainer,
					fullWidth && classes.fullWidth,
					fullWidth && "fullWidthMainContainer",
					opened && classes.selectOpened
				)}
				onClick={() => setOpened((c) => !c)}
				bgcolor="white.main"
				ml={1}
			>
				<SBBox className={classes.selectMainContent}>
					{selectedItems && selectedItems.length > 0 ? (
						selectedItems.map((c, index) => {
							if (index < 2)
								return (
									<SBBox
										className={classes.chipBox}
										key={c?.name || index}
									>
										<SBChip
											color={c.color}
											icon={c.icon}
											label={
												<SBTypography
													noWrap
													maxWidth={100}
													fontSize={10}
												>
													{c?.clientID}
												</SBTypography>
											}
										/>
									</SBBox>
								);
							return undefined;
						})
					) : (
						<SBTypography className={classes.selectLabel}>
							{t("selectAFilter")}
						</SBTypography>
					)}
					{selectedItems.length >= 3 ? (
						<div style={{ paddingLeft: "3px" }}>
							<SBTooltip title={excessiveItems}>

								<SBChip
									color="primary"
									label={`+ ${selectedItems.length - 2}`}
								/>
							</SBTooltip>
						</div>
					) : undefined}
				</SBBox>

				<SBBox className={classes.openSelectButton}>
					<SBIconButton size="small">
						{!loading ? (
							<SBIcon icon="select" />
						) : (
							<CircularProgress size={20} />
						)}
					</SBIconButton>
				</SBBox>
			</SBBox>

			<SBBox
				ref={containerRef}
				className={clsx(
					classes.searchComboContainerBox,
					opened && classes.selectOpenedOptions
				)}
			>
				<SBSystemSearchContainer
					categoryDeep={categoryDeep}
					filterIsolated={filterIsolated}
					filterType={filterType}
					itemSize={itemSize}
					items={selectedItems}
					onApply={handleOnChange}
					onlyActives={onlyActives}
					useFiltersOptions={useFiltersOptions}
				/>
			</SBBox>
		</SBBox>
	);
};

interface SBSystemSearchProps {
	loading?: boolean;
	itemSize?: number;
	fullWidth?: boolean;
	onlyActives?: boolean;
	defaultItems?: IEntity[];
	categoryDeep?: number;
	filterIsolated?: any;
	filterType?: any;
	useFiltersOptions?: any;
	onClick?: any;
	onChange?: any;
	solo?: boolean;
}
