import { Stack } from "@mui/material";
import maintenenceImg from "assets/maintenence.svg";
import { SBBox, SBButton, SBCard, SBTypography } from "modules/Commons";
import SBConfirmDialog from "modules/Commons/SBForms/SBConfirmDialog";
import { MaintenanceResult } from "modules/SystemContext/domain/models/MaintenanceResult";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SBCacheStatusCard from "../SBCacheStatusCard";
import { SBTranslate } from "i18n";

export default ({
	maintenenceResult,
	canRunProcess,
	handleConfirm,
}: SBStatusCardProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	return maintenenceResult ? (
		<SBCard>
			<SBBox px={2} pt={3} pb={2} display="flex" minWidth={1}>
				<SBCacheStatusCard
					step={maintenenceResult}
					title={t("general")}
				/>
				<SBBox display="flex" ml={5} alignItems="center">
					<SBButton
						disabled={!canRunProcess}
						onClick={() => setOpen(true)}
						style={{ height: "100%" }}
					>
						<SBTypography noWrap>{t("runCache")}</SBTypography>
					</SBButton>
				</SBBox>
			</SBBox>

			<Stack m={2} spacing={2}>
				{maintenenceResult.steps &&
					Object.keys(maintenenceResult.steps)?.map((stepKey) => {
						const step = maintenenceResult.steps[stepKey];

						return (
							<SBCacheStatusCard step={step} title={stepKey} />
						);
					})}
			</Stack>
			<SBConfirmDialog
				open={open}
				title={SBTranslate("confirmation")}
				onClose={() => setOpen(false)}
				onCancel={() => setOpen(false)}
				onConfirm={handleConfirm}
				confirmColor={"error"}
			>
				<SBBox maxWidth={350}>
					<img src={maintenenceImg} width={350} />
					<SBTypography fontWeight="bold" mt={2}>
						{SBTranslate("runCacheTitle")}
					</SBTypography>
					<SBTypography mt="1.5rem" fontWeight="bold">
						{SBTranslate("notificationWarning")}
					</SBTypography>
				</SBBox>
			</SBConfirmDialog>
		</SBCard>
	) : (
		<></>
	);
};

interface SBStatusCardProps {
	maintenenceResult: MaintenanceResult;
	canRunProcess?: boolean;
	handleConfirm: () => void;
}
