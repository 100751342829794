import loading from "assets/loading_process.gif";
import { SBTranslate, SBTranslateType } from "i18n";
import { SBBox, SBTypography } from "modules/Commons";

export default ({ text }: ConfigLoadingStateProps) => {
	return (
		<SBBox
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			width="50%"
			height="100%"
		>
			<img
				height="75px"
				style={{ margin: "4px 0px 8px" }}
				src={loading}
				alt=""
			/>
			<SBTypography color="gray2.main" variant="body1" fontWeight="400">
				{SBTranslate(text)}
			</SBTypography>
		</SBBox>
	);
};

interface ConfigLoadingStateProps {
	text: SBTranslateType;
}