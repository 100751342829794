import { Stack, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { SBCard, SBTypography, SBBox } from "modules/Commons";
import { useTranslation } from "react-i18next";
import { TooltipProps } from "recharts";
import {
	ValueType,
	NameType,
} from "recharts/types/component/DefaultTooltipContent";
import { useSystemTheme } from "themes/base";

export default (props: TooltipProps<ValueType, NameType>) => {
	const { t } = useTranslation();
	const { palette } = useSystemTheme();
	const chartData = (props.payload as any)[0]?.payload;

	if (!chartData) return <></>;

	const label = {
		value: DateTime.fromFormat(chartData?.date, "yyyy-MM-dd").toFormat(
			t("dateFormat")
		),
	};
	const real = { value: chartData?.real as number };
	const projected = {
		min: chartData?.min as number,
		max: chartData?.max as number,
		value: chartData?.forecast as number,
	};

	return (
		<SBCard >
			<Stack p={1} px={2}>
				<SBTypography fontWeight="bold" variant="body2">
					{label?.value}
				</SBTypography>

				<SBBox display="flex" alignItems="center">
					<div
						style={{
							height: 3,
							width: 15,
							background: palette.real.main,
							content: "",
						}}
					/>
					<SBTypography ml={1} mr={2}>
						{t("real")}
					</SBTypography>
					<SBTypography ml="auto">
						{real?.value?.systemFormat({
							maximumFractionDigits: 0,
							minimumFractionDigits: 0,
						})}
					</SBTypography>
				</SBBox>
				<SBBox display="flex" alignItems="center">
					<SBBox mr={1}>
						<div
							style={{
								height: 5,
								width: 15,
								background: `${palette.lightPurple.main}`,
								content: "",
							}}
						/>
						<div
							style={{
								height: 3,
								width: 15,
								background: palette.projected.main,
								content: "",
							}}
						/>
						<div
							style={{
								height: 5,
								width: 15,
								background: `${palette.lightPurple.main}`,
								content: "",
							}}
						/>
					</SBBox>
					<SBTypography mr={2}>{t("projected")}</SBTypography>
					<SBTypography ml="auto">
						{projected?.value?.systemFormat({
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						})}
					</SBTypography>
				</SBBox>
				<SBBox display="flex">
					<SBTypography ml={2.9} mr={2}>
						{t("min")}
					</SBTypography>
					<SBTypography ml="auto">
						{projected?.min?.systemFormat({
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						})}
					</SBTypography>
				</SBBox>
				<SBBox display="flex">
					<SBTypography ml={2.9} mr={2}>
						{t("max")}
					</SBTypography>
					<SBTypography ml="auto">
						{projected?.max?.systemFormat({
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						})}
					</SBTypography>
				</SBBox>
			</Stack>
		</SBCard>
	);
};
