import { useForm, FormProvider } from "react-hook-form";
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
export default forwardRef(
	(
		{
			defaultValues,
			onSubmit,
			onError,
			children,
			foundErrors = {},
			validationSchema = null,
			onBlurValidation = null,
			mode = "onBlur",
		}: SBForm,
		ref
	) => {
		const [usedValidationSchema, setUsedValidationSchema] =
			useState(validationSchema);

		const [errors, setErrors] = useState<any>(foundErrors);
		const inputRef = useRef<any>();
		const methods = useForm({
			mode: "onChange",
			defaultValues,
			resolver: usedValidationSchema
				? yupResolver(usedValidationSchema)
				: undefined,
		});

		useImperativeHandle(ref, () => ({
			getValues: (ignoreErrors: boolean = false) => {
				methods.clearErrors();

				if (!ignoreErrors && errors && Object.keys(errors).length > 0)
					return { values: null, errors };
				return { values: methods.getValues(), errors };
			},
			submit: () => {
				inputRef.current.click();
			},
		}));

		const formMethods = methods.handleSubmit(
			(v) => {
				setErrors({});
				return onSubmit && onSubmit(v);
			},
			(v, e) => {
				setErrors(v);
				return onError && onError(v, e);
			}
		);

		return (
			<FormProvider
				{...{
					...methods,
					...formMethods,
					errors,
					onAnyBlur: (item: any, prop: string) => {
						if (onBlurValidation) {
							setUsedValidationSchema((c: any) => ({
								...c,
								...onBlurValidation(prop),
							}));
						}
					},
				}}
			>
				<form onSubmit={formMethods}>
					{children}
					<input
						type="submit"
						ref={inputRef}
						style={{ display: "none" }}
					/>
				</form>
			</FormProvider>
		);
	}
);

export interface SBForm {
	defaultValues?: any;
	validationSchema?: any;
	onSubmit?: (v: any) => void;
	onError?: (errors: any, e: any) => void;
	onBlur?: (v: any) => void;
	children: JSX.Element;
	onBlurValidation?: any;
	mode?: any;
	foundErrors?: any;
}
