import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	sbSimulationFilterItem: {
		cursor: "pointer",
		fontWeight: 400,
		color: "gray",
		"&.active": {
			color: theme.palette?.primary?.main,
			fontWeight: 700,
		},
	},
}));
