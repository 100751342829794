import { SBBox, SBButton, SBTextField, SBTypography } from "modules/Commons";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const SBDatePickerConfigYears = ({
	fetchPropError,
	disableYearsInput,
}) => {
	const { t } = useTranslation();
	const { getValues, setValue } = useFormContext();

	const [startYear, setStartYear] = useState(getValues("startYear"));
	const [endYear, setEndYear] = useState(getValues("endYear"));

	return (
		<SBBox>
			<SBBox
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				mb={1.5}
			>
				<SBTypography fontWeight="700">
					{t("customizeConfiguration")}
				</SBTypography>
				<SBButton ml="auto" type="submit">
					<SBTypography
						style={{ textTransform: "capitalize" }}
						fontWeight="400"
					>
						{t("save")}
					</SBTypography>
				</SBButton>
			</SBBox>
			<SBBox
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				mb={1.5}
			>
				<SBTypography fontWeight="500">
					{disableYearsInput
						? t("defaultRange")
						: t("determineRange")}
				</SBTypography>
			</SBBox>
			<SBBox display="flex">
				<SBBox mr={3}>
					<SBTextField
						disabled={disableYearsInput}
						value={startYear}
						onChange={(e) => {
							const v = e.target.value
								.naturalNumber()
								.limitedNumber({ final: endYear })
								.isYearInput();

							setValue("startYear", v);
							setStartYear(v);
						}}
						label={t("start")}
						helperText={fetchPropError("startYear")}
						error={!!fetchPropError("startYear")}
					/>
				</SBBox>
				<SBBox mr={1}>
					<SBTextField
						disabled={disableYearsInput}
						value={endYear}
						onChange={(e) => {
							const v = e.target.value
								.naturalNumber()
								.isYearInput();

							setValue("endYear", v);
							setEndYear(v);
						}}
						label={t("end")}
						helperText={fetchPropError("endYear")}
						error={!!fetchPropError("endYear")}
					/>
				</SBBox>
			</SBBox>
		</SBBox>
	);
};
