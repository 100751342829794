import { IInventory } from "domain/Inventory";
import { SBTranslate } from "i18n";
import {
	SBBox,
	SBLoading,
	SBOutlinedButton,
	SBSlideMenu,
	SBTypography,
} from "modules/Commons";
import { SBNewTable } from "modules/Commons/SBDataDisplays/SBTable/SBNewTable";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { formatInteger, percentageFormatter } from "utils/formatters";

export default (props: SBInventoryReservedModalProps) => {
	const { t } = useTranslation();
	const tableRef = useRef<any>();
	const { inventories, closeMenu, isMenuOpen, loading, title, itemName } =
		props;
	const downloadCSV = () => tableRef.current?.downloadCSV();

	return (
		<SBSlideMenu open={isMenuOpen}>
			<SBLoading loading={loading} limited>
				<SBBox
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					p={2}
				>
					<SBTypography fontWeight="bold" variant="h6" pb={2}>
						{t(title)}
					</SBTypography>
					<SBBox ml={2} display="flex">
						<SBBox mr={2}>
							<SBOutlinedButton onClick={downloadCSV}>
								{SBTranslate("commons.export")}
							</SBOutlinedButton>
						</SBBox>
						<SBOutlinedButton onClick={closeMenu}>
							{SBTranslate("commons.close")}
						</SBOutlinedButton>
					</SBBox>
				</SBBox>
				<SBBox>
					<SBNewTable
						title={t(itemName)}
						containerHeight={235}
						ref={tableRef}
						startRows={inventories}
						virtualize
						defaultColumns={[
							{
								id: () => "",
								name: t("product"),
								headers: [
									{
										id: (i) =>
											i.position?.product?.clientID,
										key: "productClientID",
										name: t("name"),
										align: "left",
										style: {
											fontWeight: "bold",
											color: "#7F51FF",
										},
									},
									{
										id: (i) => i.position?.product?.name,
										key: "productName",
										name: t("code"),
										align: "left",
									},
								],
							},
							{
								id: () => "",
								name: t("store"),
								headers: [
									{
										id: (i) => i.position?.store?.clientID,
										key: "storeClientID",
										name: t("name"),
										align: "left",
										style: {
											fontWeight: "bold",
											color: "#7F51FF",
										},
									},
									{
										id: (i) => i.position?.store?.name,
										key: "storeName",
										name: t("code"),
										align: "left",
									},
								],
							},
							{
								id: () => "",
								name: t("inventory"),
								headers: [
									{
										id: (i) => i.csl,
										key: "csl",
										name: t("csl"),
										formatter: percentageFormatter,
									},
									{
										id: (i) => i.position?.onHand,
										key: "onHand",
										name: t("onHand"),
										formatter: formatInteger,
									},
									{
										id: (i) => i.position?.onOrder,
										key: "onOrder",
										name: t("onOrder"),
										formatter: formatInteger,
									},
									{
										id: (i) => i.position?.position,
										key: "position",
										name: t("position"),
										formatter: formatInteger,
									},
									{
										id: (i) => i.position?.reserved,
										key: "pending",
										name: t("commons.pending"),
										formatter: formatInteger,
									},
									{
										id: (i) =>
											i.position?.positionWithoutReserved,
										key: "positionWithoutPending",
										name: t(
											"commons.positionWithoutPending"
										),
										formatter: formatInteger,
									},
								],
							},
						]}
						onClick={() => { }}
					/>
				</SBBox>
			</SBLoading>
		</SBSlideMenu>
	);
};

interface SBInventoryReservedModalProps {
	/** Show if modal is open or not */
	isMenuOpen: boolean;
	/** Indicates loading on the data fetch */
	loading: boolean;
	/** Object to fill the modal info */
	inventories: IInventory[];
	/** Func to close modal */
	closeMenu: () => void;
	/** Modal title */
	title: string;
	/** Table item name */
	itemName: string;
}
