import { useTheme } from "@mui/system";
import {
	createTheme,
	SimplePaletteColorOptions,
	ThemeOptions,
} from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

export const BaseTheme = () =>
	createTheme({
		palette: {
			primary: { main: "#7F51FF", contrastText: "#fff" },
			secondary: { main: "#7F51FF", contrastText: "#fff" },
			purpleHover: { main: "#6B42DC", contrastText: "#000" },
			violet: { main: "#F4F3F9", contrastText: "#000" },
			lightPurple: { main: "#EFECFF", contrastText: "#000" },
			lightPurple1: { main: "#F9F8FD", contrastText: "#000" },
			lightPurple2: { main: "#F4F3F9", contrastText: "#000" },
			darkPurple: { main: "#4E39A5", contrastText: "#fff" },
			blue1: {
				main: "#2F80ED",
				contrastText: "#fff",
				opacity1: "#2F80ED0D",
			},
			blue2: { main: "#2D9CDB", contrastText: "#fff" },
			blue3: { main: "#56CCF2", contrastText: "#fff" },
			blue4: { main: "#1254AD", contrastText: "#fff" },
			yellow1: { main: "#F3C362", contrastText: "#000" },
			orange1: { main: "#F2994A", contrastText: "#000" },
			darkerBlue: { main: "#0758C5", contrastText: "#000" },
			purple1: {
				main: "#9B51E0",
				contrastText: "#fff",
			},
			purple2: {
				main: "#BB6BD9",
				contrastText: "#000",
				opacity1: "#BB6BD90D",
			},
			purple3: {
				main: "#780AA1",
				contrastText: "#000",
			},
			purple6: {
				main: "#C7B9FF",
				contrastText: "#000",
			},
			purple7: {
				main: "#C5B5DF",
				contrastText: "#000",
			},
			real: { main: "#6FCF97", contrastText: "#fff" },
			projected: { main: "#7F51FF", contrastText: "#fff" },
			success: { main: "#6FCF97", contrastText: "#fff" },
			error: { main: "#EB5757", contrastText: "#fff" },
			error2: { main: "#B40000", contrastText: "#fff" },
			warning: { main: "#F2C94C", contrastText: "#000" },
			orange: { main: "#F2994A", contrastText: "#000" },
			blackTitle: { main: "#404040", contrastText: "#000" },
			iconGray: { main: "#A7A7A7", contrastText: "#000" },
			gray1: { main: "#333333", contrastText: "#000" },
			gray2: { main: "#4F4F4F", contrastText: "#000" },
			gray3: { main: "#828282", contrastText: "#000" },
			gray4: { main: "#BDBDBD", contrastText: "#000" },
			gray5: { main: "#E0E0E0", contrastText: "#000" },
			gray6: { main: "#F2F2F2", contrastText: "#000" },
			gray7: { main: "#FAFAFA", contrastText: "#000" },
			gray8: { main: "#FAFAFA", contrastText: "#000" },
			white: { main: "#FFFFFF", contrastText: "#000" },
			black: {
				main: "#000000",
				contrastText: "#fff",
				opacity1: "#00000014",
				opacity2: "#0000001a",
			},
			border1: {
				main: "#E2DDF2",
				contrastText: "#000",
				opacity1: "#E2DDF299",
			},
			green1: {
				main: "#2CB0CD",
				contrastText: "#000",
				opacity1: "#2CB0CD0D",
			},
			green2: {
				main: "#037993",
				contrastText: "#000",
			},
			randomColor1: { main: "#EFECFF" },
			transparent: {
				main: "#00000000",
				contrastText: "#7F51FF",
				dark: "#00000000",
			},
			scarce: { main: "#685454" },
			excess: { main: "#F2994A" },
			balanced: { main: "#7F51FF" },
		},
		typography: {
			fontFamily: ["Inter", "icomoon"].join(","),
			fontSize: 12,
		} as TypographyOptions,
		overrides: {
			MuiInput: {
				input: {
					"&::placeholder": {
						color: "gray",
					},
				},
			},
		},
	} as BaseThemeOptions);

export interface BaseThemeOptions extends ThemeOptions {
	palette: {
		primary: OpacitySimplePaletteColorOptions;
		secondary: OpacitySimplePaletteColorOptions;
		purpleHover: OpacitySimplePaletteColorOptions;
		violet: OpacitySimplePaletteColorOptions;
		lightPurple: OpacitySimplePaletteColorOptions;
		lightPurple1: OpacitySimplePaletteColorOptions;
		lightPurple2: OpacitySimplePaletteColorOptions;
		darkPurple: OpacitySimplePaletteColorOptions;
		blue1: OpacitySimplePaletteColorOptions;
		blue2: OpacitySimplePaletteColorOptions;
		blue3: OpacitySimplePaletteColorOptions;
		blue4: OpacitySimplePaletteColorOptions;
		yellow1: OpacitySimplePaletteColorOptions;
		orange1: OpacitySimplePaletteColorOptions;
		darkerBlue: OpacitySimplePaletteColorOptions;
		purple1: OpacitySimplePaletteColorOptions;
		purple2: OpacitySimplePaletteColorOptions;
		purple3: OpacitySimplePaletteColorOptions;
		purple6: OpacitySimplePaletteColorOptions;
		purple7: OpacitySimplePaletteColorOptions;
		success: OpacitySimplePaletteColorOptions;
		error: OpacitySimplePaletteColorOptions;
		error2: OpacitySimplePaletteColorOptions;
		warning: OpacitySimplePaletteColorOptions;
		orange: OpacitySimplePaletteColorOptions;
		blackTitle: OpacitySimplePaletteColorOptions;
		iconGray: OpacitySimplePaletteColorOptions;
		gray1: OpacitySimplePaletteColorOptions;
		gray2: OpacitySimplePaletteColorOptions;
		gray3: OpacitySimplePaletteColorOptions;
		gray4: OpacitySimplePaletteColorOptions;
		gray5: OpacitySimplePaletteColorOptions;
		gray6: OpacitySimplePaletteColorOptions;
		gray7: OpacitySimplePaletteColorOptions;
		gray8: OpacitySimplePaletteColorOptions;
		white: OpacitySimplePaletteColorOptions;
		border1: OpacitySimplePaletteColorOptions;
		green1: OpacitySimplePaletteColorOptions;
		green2: OpacitySimplePaletteColorOptions;
		randomColor1: OpacitySimplePaletteColorOptions;
		transparent: OpacitySimplePaletteColorOptions;
		black: OpacitySimplePaletteColorOptions;
		projected: OpacitySimplePaletteColorOptions;
		real: OpacitySimplePaletteColorOptions;
		scarce: OpacitySimplePaletteColorOptions;
		excess: OpacitySimplePaletteColorOptions;
		balanced: OpacitySimplePaletteColorOptions;
	};
	typography: TypographyOptions;
}

export interface OpacitySimplePaletteColorOptions
	extends SimplePaletteColorOptions {
	opacity1?: string;
	opacity2?: string;
}

export const useSystemTheme = () => useTheme<BaseThemeOptions>();
