import { IInventoryStatisticsSummary } from "domain/Inventory/Models/IInventoryStatisticsSummary";
import { SBBox, SBGrid, SBTooltip, SBTypography } from "modules/Commons";
import { SBTableColumn } from "modules/Commons/SBDataDisplays/SBTable/models/TableColumn";

export default ({ statistics, section }: SBInventoryStatisticsWarningProps) => {
	return (
		<SBGrid
			key={section.name}
			item
			xs={3}
			display="flex"
			alignItems="start"
		>
			<SBBox pt={0.4} width="100%">
				<SBTooltip title={section.tooltip || section.name}>
					<SBTypography
						noWrap
						color="#A7A7A7"
						textTransform="none"
						variant="body2"
						minWidth="95px"
					>
						{section.name}
					</SBTypography>

				</SBTooltip>
				<SBTooltip title={section.id(statistics) as any}>
					<SBTypography
						noWrap
						textTransform="uppercase"
						variant="body1"
						width="95%"
						fontWeight="400"
						minWidth="95px"
					>
						{section.id(statistics) as any}
					</SBTypography>

				</SBTooltip>
			</SBBox>
		</SBGrid >
	);
};
interface SBInventoryStatisticsWarningProps {
	section: SBTableColumn<any>;
	statistics: IInventoryStatisticsSummary;
}
