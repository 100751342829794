import { SBBox, SBCard, SBForm, SBIcon, SBIconButton, SBOutlinedButton, SBTypography } from "modules/Commons";
import { useStyles } from "./style";
import SupplyReviewPeriod from "views/ConfigsView/ConfigSideMenu/ConfigSupply/SupplyReviewPeriod";
import CopyToClipboard from "react-copy-to-clipboard";
import { Snackbar } from "@mui/material";
import { SBTranslate } from "i18n";
import { useState } from "react";
import clsx from "clsx";

export default function CronGeneratorHelper({ close, opened }: CronGeneratorHelperProps) {
	const classes = useStyles();
	const [copy, setCopy] = useState<boolean>(false);
	const [reviewPeriodExpression, setReviewPeriodExpression] =
		useState<string>("");


	return (
		<SBCard className={clsx(classes.cronGeneratorCard, opened && "border")}>
			<SBBox className={classes.cronGeneratorContainer}>
				<SBBox className={classes.cronGeneratorHeader}>
					<SBTypography variant="body1" fontWeight="700" color="gray2.main">
						{SBTranslate("cronExpressionConfiguration")}
					</SBTypography>
					<SBIconButton onClick={close}>
						<SBIcon icon="x" />
					</SBIconButton>
				</SBBox>
				<SBForm>
					<SupplyReviewPeriod
						onUpdate={(v: any) => setReviewPeriodExpression(v)}
						cronConfigurationStyles
						supplyReviewPeriodFlag={true}
					/>
				</SBForm>
				<SBBox className={classes.reviewPeriodExpressionFieldContainer}>
					<SBBox className={classes.reviewPeriodExpressionField}>
						<SBTypography>{reviewPeriodExpression}</SBTypography>
					</SBBox>
					<CopyToClipboard
						text={reviewPeriodExpression}
						onCopy={() =>
							reviewPeriodExpression ? setCopy(true) : {}
						}
					>
						<SBBox>
							<SBOutlinedButton
								disabled={reviewPeriodExpression ? false : true}
							>
								<SBIcon
									icon="copy"
									color={
										reviewPeriodExpression
											? "primary"
											: "gray4"
									}
								/>
								<SBTypography
									ml={1}
									py={0.3}
									fontWeight="700"
									textTransform="capitalize"
								>
									{SBTranslate("copy")}
								</SBTypography>
							</SBOutlinedButton>
						</SBBox>
					</CopyToClipboard>
				</SBBox>
				<Snackbar
					autoHideDuration={3000}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					open={copy}
					onClose={() => setCopy(false)}
				>
					<SBBox className={classes.copyToClipboardInfo}>
						{SBTranslate("copiedToClipboard")}
					</SBBox>
				</Snackbar>
			</SBBox>
		</SBCard>
	)
}

interface CronGeneratorHelperProps {
	close: () => void;
	opened?: boolean;
}