import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
	inventoryButton: {
		border: "1px dashed #7F51FF",
		borderRadius: "8px",
		cursor: "pointer",
		"&:hover": {
			boxShadow: "2px 3px 6px 1px rgba(0,0,0, 0.05)",
			textDecoration: "underline",
		},
	},
}));
