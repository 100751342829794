import SBDatePickerBase from "../SBDatePickerBase";
import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import { DateRangeType } from "../../models/dateRangeType";

export const SBDatePickerMonthly = (props) => {
	const { startYear = 0, endYear = 10 } = props;
	const range = endYear - startYear;

	const size = 1;
	const [years] = useState(
		[...Array(range + 1).keys()].flatMap((c) => [
			...[...Array(size).keys()].map((i) => endYear - c + i - size + 1),
		])
	);
	const currentYear = DateTime.now().year;
	const [selectedYear, setSelectedYear] = useState(
		// years.find((e) => e === currentYear) ?
		currentYear
		// : years[0]
	);

	const validQuarters = useMemo(
		() =>
			[...Array(12).keys()].map((c) => {
				const date = DateTime.now().set({
					month: c + 1,
					day: 1,
					year: selectedYear,
				});

				return {
					label: `${date.monthShort} <br/> ${date.year}`,
					date: date,
				};
			}),
		[selectedYear]
	);

	return (
		<SBDatePickerBase
			type={DateRangeType.Monthly}
			validItems={validQuarters}
			years={years}
			selectedYear={selectedYear}
			onChangeItem={setSelectedYear}
			{...props}
		/>
	);
};
