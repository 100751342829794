import { useStyles } from "./style";
import { useState } from "react";
import { SBBox, SBTypography } from "modules/Commons";
import { DateRangeType } from "../../models/dateRangeType";
import { useTranslation } from "react-i18next";

export const SBDatePickerRangeSelector = ({
	handleSelectType,
	selectedPicker,
	selectedPickers,
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [pickers] = useState([
		DateRangeType.Daily,
		DateRangeType.Monthly,
		DateRangeType.Trimester,
		DateRangeType.Semester,
		DateRangeType.Year,
	]);

	return (
		<SBBox
			display={"flex"}
			flexDirection="column"
			alignItems="end"
			justifyContent="center"
			className={classes.sbDatePickerRangeContainer}
		>
			<SBBox mb="auto" ml={2} width={70}>
				{pickers.map((picker) => {
					return selectedPickers &&
						selectedPickers.indexOf(picker) >= 0 ? (
						<SBBox
							key={picker}
							pb={1}
							onClick={() => handleSelectType(picker)}
						>
							<SBTypography
								fontWeight={
									selectedPicker === picker ? "bold" : "400"
								}
								color={
									selectedPicker === picker
										? "primary"
										: "black"
								}
								style={{ cursor: "pointer" }}
							>
								{t(picker)}
							</SBTypography>
						</SBBox>
					) : null;
				})}
			</SBBox>
		</SBBox>
	);
};
