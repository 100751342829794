import { IQueryFilterModel } from "domain/Utils";
import { useDateStorageCache } from "hooks/useDateStorageCache";
import { useRouteFilters } from "hooks/useRouteFilters";
import { DateTime } from "luxon";
import { SystemContextRequester } from "modules/SystemContext/services/SystemContextRequester";
import useFilterCache from "hooks/useFilterCache";
import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState
} from "react";
import { IEntity } from "domain/Domain";

const SystemFilterContext = createContext({} as SystemFilterContextProps);

export default ({ children }: { children: ReactNode }) => {
	const [lastDatabaseUpdate, setLastDatabaseUpdate] = useState<DateTime | undefined>(undefined);
	const [context, setContext] = useState<DateTime | undefined>(undefined);
	const [filtersContext, setFiltersContext] = useState<IQueryFilterModel | undefined>(undefined);
	const [buildContextSwitch, setBuildContextSwitch] = useState<boolean>(true);
	const { queryFilters } = useRouteFilters();
	const { setDateItem, getDateItem } = useDateStorageCache();
	const [clearContextFilter, setClearContextFilter] = useState<boolean>(false);
	const { fetchFilters } = useFilterCache();

	const getLastDataBaseUpdate = async () => {
		const result = (await SystemContextRequester.GET("")) as any;
		if (result.data) {
			return result.data.lastDataBaseUpdate;
		}
	};

	const notCurrentContext = () => lastDatabaseUpdate ? getDateItem() !== lastDatabaseUpdate?.toISODate() : false;

	const setFiltersContextState = async (entities: IEntity[] | undefined) => {
		setFiltersContext(prevState => ({
			...prevState,
			items: entities
		}));
	};

	const buildContext = async () => {
		var lastUpdate = DateTime.fromFormat(await getLastDataBaseUpdate(), "yyyy-MM-dd");
		fetchFilters().then(
			(res: IQueryFilterModel | undefined) => {
				const date = getDateItem() || queryFilters?.context?.toISODate() || lastUpdate.toISODate();
				setFiltersContextState(res?.items);
				setDateItem(date);
				setContext(DateTime.fromISO(date));
				setLastDatabaseUpdate(lastUpdate);
			}
		).finally(() => setBuildContextSwitch(false));
	}

	useEffect(() => {
		buildContextSwitch && buildContext();
	}, [buildContextSwitch]);


	return (
		<SystemFilterContext.Provider value={{
			context,
			lastDatabaseUpdate,
			filtersContext,
			setFiltersContextState,
			buildContextSwitch,
			setBuildContextSwitch,
			clearContextFilter,
			setClearContextFilter,
			getLastDataBaseUpdate,
			notCurrentContext
		}}>
			{children}
		</SystemFilterContext.Provider>
	);
};

export const useSystemFilterContext = () => useContext(SystemFilterContext);

interface SystemFilterContextProps {
	context: DateTime | undefined;
	lastDatabaseUpdate: DateTime | undefined;
	filtersContext: IQueryFilterModel | undefined;
	setFiltersContextState: (entities: IEntity[]) => void;
	buildContextSwitch: boolean;
	setBuildContextSwitch: (prev: boolean) => void;
	clearContextFilter: boolean;
	setClearContextFilter: (prev: boolean) => void;
	getLastDataBaseUpdate: () => Promise<string>;
	notCurrentContext: () => boolean;
}
