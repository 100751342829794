import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	errorsTableContainer: {
		height: "100%",

		"& div": {
			boxShadow: "none !important",
		},
	},
}));
