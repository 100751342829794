import { InventoryStatisticsStatus } from "domain/Inventory/Enums/InventoryStatisticsStatus";
import { InventoryStatus } from "domain/Inventory/Enums/InventoryStatus";
import { ForecastWarning } from "domain/Predictive/Enums/ForecastWarning";
import { IHomeRowStatistics } from "domain/Utils/models/IHomeRowStatistics";
import useTableColumnBuilder from "hooks/useTableColumnBuilder";
import { SBBox, SBTooltip } from "modules/Commons";
import { useTranslation } from "react-i18next";
import { useSystemTheme } from "themes/base";
import {
	formatDecimal,
	formatInteger,
	getFlagsByEnum,
	getKeyByValue,
	percentageFormatter,
} from "utils/formatters";

export const useHomeTableHeaders = () => {
	const { t } = useTranslation();
	const { palette } = useSystemTheme();
	const { add, build } = useTableColumnBuilder<IHomeRowStatistics>();

	add({
		id: () => "product",
		name: "product",
		headers: [
			{
				id: (e) => e.inventory?.product?.clientID,
				key: "productCode",
				name: "code",
				csvName: `${t("productCode")}`,
				align: "left",
				noWrap: true,
				fixed: true,
				style: {
					fontWeight: "bold",
					color: "#7F51FF",
				},
				tip: "productClientIDTip",
			},
			{
				id: (e) => e.inventory?.product?.name,
				csvName: `${t("productName")}`,
				key: "productName",
				noWrap: true,
				name: "name",
				align: "left",
				tip: "productNameTip",
			},
		],
	});
	add({
		id: () => "store",
		name: "store",
		headers: [
			{
				id: (e) => e.inventory?.store?.clientID,
				key: "storeCode",
				csvName: `${t("storeCode")}`,
				name: "code",
				align: "left",
				fixed: true,
				style: {
					fontWeight: "bold",
					color: "#7F51FF",
				},
				tip: "storeClientIDTip",
			},
			{
				id: (e) => e.inventory?.store?.name,
				key: "storeName",
				csvName: `${t("storeName")}`,
				name: "name",
				align: "left",
				noWrap: true,
				tip: "storeNameTip",
			},
		],
	});

	add({
		id: () => "category",
		name: "category",
		headers: [
			{
				id: (e) => e.inventory?.product?.category?.clientID,
				key: "categoryCode",
				name: "code",
				csvName: `${t("categoryCode")}`,
				align: "left",
				noWrap: true,
				style: {
					fontWeight: "bold",
					color: "#7F51FF",
				},
				tip: "categoryClientIDTip",
			},
			{
				id: (e) => e.inventory?.product?.category?.name,
				csvName: `${t("categoryName")}`,
				key: "categoryName",
				noWrap: true,
				name: "name",
				align: "left",
				tip: "categoryNameTip",
			},
		],
	});

	add({
		id: () => "status",
		name: t("status"),
		headers: [
			{
				id: (e) => t((e.status & 1) > 0 ? "yes" : "no"),
				key: "isOptimized",
				name: t("isOptimized"),
				noWrap: true,
			},
			{
				id: (e) => {
					var values = getFlagsByEnum(
						e.status,
						InventoryStatisticsStatus
					);
					return values
						.map((value) => t(value.toLowerCase()))
						.join(" | ");
				},
				key: "optimizationStatus",
				name: t("optimizationStatus"),
				noWrap: true,
			},
			{
				id: (e) => t(e.isEmergencySupply ? "yes" : "no"),
				key: "isEmergencySupply",
				name: t("emergency"),
				noWrap: true,
				styleFn: (e) => ({
					color: e?.isEmergencySupply ? palette.error.main : "",
				}),
			},
			{
				id: (e) => {
					return e.position?.csl;
				},
				formatter: percentageFormatter,
				prepend: (e) => {
					var color =
						e.inventoryStatus == InventoryStatus.Excess
							? palette.excess.main
							: e.inventoryStatus == InventoryStatus.Scarcity
								? palette.scarce.main
								: palette.primary.main;

					return (
						<SBTooltip
							placement="top"
							title={t(
								getKeyByValue(
									e.inventoryStatus,
									InventoryStatus
								).toLowerCase()
							)}
						>
							<SBBox
								style={{
									content: "",
									width: "12px",
									height: "12px",
									marginRight: "8px",
									background: color,
									borderRadius: "50%",
								}}
							/>
						</SBTooltip>
					);
				},
				key: "inventoryStatus",
				name: t("inventoryStatus"),
				noWrap: true,
			},
		],
	});

	add({
		id: () => "position",
		name: t("position"),
		headers: [
			{
				id: (e) => e.inventory?.onHand,
				key: "onHand",
				name: t("onHand"),
				noWrap: true,
				formatter: formatInteger,
			},
			{
				id: (e) => e.inventory?.onOrder,
				key: "onOrder",
				name: t("onOrder"),
				noWrap: true,
				formatter: formatInteger,
			},
			{
				id: (e) => e.inventory?.position,
				key: "position",
				name: t("position"),
				noWrap: true,
				formatter: formatInteger,
			},
			{
				id: (e) => e.inventory?.reserved,
				key: "pending",
				name: t("commons.pending"),
				noWrap: true,
				formatter: formatInteger,
			},
			{
				id: (e) => e.inventory?.positionWithoutReserved,
				key: "positionWithoutPending",
				name: t("commons.positionWithoutPending"),
				noWrap: true,
				formatter: formatInteger,
			},
			{
				id: (e) => e.position?.csl,
				key: "csl",
				name: t("csl"),
				noWrap: true,
				formatter: percentageFormatter,
			},
			{
				id: (e) => e.position?.risk,
				key: "risk",
				name: t("risk"),
				noWrap: true,
				formatter: percentageFormatter,
			},
		],
	});

	add({
		id: () => "recommendations",
		name: t("recommendation_other"),
		headers: [
			{
				id: (e) => e.quantityToSupply,
				key: "quantityToSupply",
				name: t("quantityToSupply"),
				align: "left",
				noWrap: true,
				width: "90%",
				formatter: formatInteger,
			},
			{
				id: (e) => e.quantityToTransferFrom,
				key: "quantityToTransferFrom",
				name: t("quantityToTransferFrom"),
				align: "left",
				noWrap: true,
				width: "90%",
				formatter: formatInteger,
			},
			{
				id: (e) => e.quantityToTransferTo,
				key: "quantityToTransferTo",
				name: t("quantityToTransferTo"),
				align: "left",
				noWrap: true,
				width: "90%",
				formatter: formatInteger,
			},
			{
				id: (e) => e.position?.excess,
				key: "excess",
				name: t("excess"),
				align: "left",
				noWrap: true,
				formatter: formatInteger,
			},
			{
				id: (e) => e.targetNeed,
				key: "need",
				name: t("need"),
				align: "left",
				noWrap: true,
				formatter: formatInteger,
			},
		],
	});

	add({
		id: () => "forecast",
		name: t("forecast"),
		headers: [
			{
				id: (e) => e?.evaluation?.rmse?.sum?.value,
				key: "rmse",
				name: t("rmse"),
				align: "left",
				noWrap: true,
				formatter: formatDecimal,
				csvValue: (e) => formatDecimal(e.evaluation?.dailyEvaluation?.r2),
			},
			{
				id: (e) => e?.evaluation?.r2?.sum?.value,
				key: "r2",
				name: t("r2"),
				align: "left",
				noWrap: true,
				formatter: percentageFormatter,
				csvValue: (e) => percentageFormatter(e.evaluation?.dailyEvaluation?.r2),
			},
			{
				id: (e) => e?.evaluation?.mpe?.sum?.value,
				key: "mpe",
				name: t("mpe"),
				noWrap: true,
				formatter: percentageFormatter,
				csvValue: (e) => percentageFormatter(e.evaluation?.dailyEvaluation?.mpe),
			},
			{
				id: (e) => e?.evaluation?.mape?.sum?.value,
				key: "mape",
				name: t("mape"),
				noWrap: true,
				formatter: percentageFormatter,
				csvValue: (e) => percentageFormatter(e.evaluation?.dailyEvaluation?.mape),
			},
			{
				id: (e) => e?.evaluation?.cv?.sum?.value,
				key: "cv",
				name: t("cv"),
				noWrap: true,
				formatter: formatDecimal,
				csvValue: (e) => formatDecimal(e.evaluation?.dailyEvaluation?.cv),
			},
			{
				id: (e) => t((e.status & 2) > 0 ? "yes" : "no"),
				key: "incompleteForecast",
				name: t("incompleteForecast"),
				noWrap: true,
			},
			{
				id: (e) =>
					t(
						(e.forecastWarning & ForecastWarning.MPE) > 0
							? "yes"
							: "no"
					),
				key: "biased",
				name: t("biased"),
				noWrap: true,
			},
			{
				id: (e) =>
					t(
						(e.forecastWarning & ForecastWarning.MAPE) > 0
							? "yes"
							: "no"
					),
				key: "highError",
				name: t("highError"),
				noWrap: true,
			},
		],
	});

	return { headers: build() };
};
