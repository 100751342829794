import { useClientIdentificator } from "hooks/useClientIdentificator";
import routerHistory from "hooks/useRouterHistory";
import mixpanel, { Mixpanel } from "mixpanel-browser";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import environment from "utils/environment";
import jwt from "jwt-decode";
import { DateTime } from "luxon";
import { StorageKey } from "domain/Utils";
import useRememberMe from "hooks/useRememberMe";

const MixpanelContext = createContext({} as Mixpanel & {
	trackExport: (data: { date: DateTime }) => void,
	trackHistoryChange: (data: { date: DateTime }) => void,
	trackLogin: () => void,
});

export default ({ children }: { children: ReactNode }) => {

	const { client, environment: env } = useClientIdentificator();
	const { storage } = useRememberMe();
	const [user, setUser] = useState<Partial<{ email: string }> | null>(null);

	const validateToken = () => {
		if (!user) {
			var key = storage().getItem(StorageKey.Identity) as string;

			if (!key) return null;

			var newUser = jwt(key) as Partial<{ email: string }>;
			setUser(newUser)
			buildMixpanel(newUser);
			return newUser;
		} else {
			return user;
		}
	}

	const buildMixpanel = (user: Partial<{ email: string }>) => {
		if (environment.MixpanelKey && !!user) {
			mixpanel.init(environment.MixpanelKey, {
				disable_persistence: true,
				disable_cookie: true,
				ignore_dnt: true
			});
			mixpanel.reset();

			mixpanel.identify(user.email);

			mixpanel.people.set("name", user.email?.split("@")[0])
			mixpanel.people.set("email", user.email)

			routerHistory.listen(location => {
				mixpanel.track('changed page', {
					'url': location.pathname,
					'fullUrl': window.location.href,
					'email': user.email,
					'client': client,
					'environment': env
				});
			});
		}
	}




	const trackExport = (data: { date: DateTime }) => {
		mixpanel.track('export', {
			...commonInfo(),
			...data
		});
	}

	const trackLogin = () => {
		mixpanel.track('login', {
			...commonInfo(),
		});
	}

	const trackHistoryChange = (data: { date: DateTime }) => {
		mixpanel.track('history change', {
			...commonInfo(),
			...data
		});
	}

	const commonInfo = () => {
		var user = validateToken();
		if (!user) throw new Error('invalid token');

		return ({
			'url': location.pathname,
			'fullUrl': window.location.href,
			'email': user!.email,
			'client': client,
			'environment': env,
		});
	}


	return (
		<MixpanelContext.Provider value={{ ...mixpanel, trackExport, trackHistoryChange, trackLogin }}>
			{children}
		</MixpanelContext.Provider>
	);
};

export const useMixpanelContext = () => useContext(MixpanelContext);
