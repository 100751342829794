import { TableCell, TableRow } from "@mui/material";
import clsx from "clsx";
import { SBTooltip } from "modules/Commons";
import { SBTableColumn } from "modules/Commons/SBDataDisplays/SBTable/models/TableColumn";

export default function SBBasicTableRow<T>({
	columns,
	row,
}: SBSimpleTableRow<T>) {
	return (
		<TableRow>
			{columns?.map((column) => {
				return (
					<TableCell
						key={column.key || column.name}
						className={clsx("column")}
					>
						{column.id(row)}
					</TableCell>
				);
			})}
		</TableRow>
	);
}

interface SBSimpleTableRow<T extends {}> {
	/** Column to access the real and projected values*/
	columns: SBTableColumn<T>[];
	/** Row to be rendered on the table */
	row: T;
}
