import { LineChart, Line } from "recharts";

export default ({ color, data, dataKey }: SBSimpleLineProps) => {
	return (
		<LineChart
			data={data}
			margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
			height={50}
			width={50}
		>
			<Line
				dataKey={dataKey}
				type="monotone"
				stroke={color}
				strokeWidth="3px"
				dot={false}
			/>
		</LineChart>
	);
};
export interface SBSimpleLineProps {
	color: string;
	data: any[];
	dataKey: string;
}
