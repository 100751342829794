import { Collapse } from "@mui/material";
import Cron from "i18n/cron";
import { SBBox, SBGrid, SBSelect, SBTypography } from "modules/Commons";
import { ReviewPeriodExpression, ReviewPeriodExpressionPosition } from "domain/Predictive/Enums/reviewPeriodExpressionPosition";
import { useContext, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TipsContext } from "views/ConfigsView/ConfigTips";
import EachDayInMonth from "./EachDayInMonth";
import EachDayInWeek from "./EachDayInWeek";
import { useStyles } from "./style";
import ConfigTextField from "../../ConfigTextField";
import { RecommendationMode } from "modules/InventoryConfig/models/recommendationMode";
import { SBTranslate } from "i18n";

export default ({
	onUpdate,
	fetchPropError,
	cronConfigurationStyles = false,
	supplyReviewPeriodFlag = false
}: SupplyReviewPeriodProps) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { getValues, setValue, control } = useFormContext();
	const { createCronExpression } = Cron({ t });
	const { Verifications, Modifiers, Default } = ReviewPeriodExpression;
	const tipsContext = useContext(TipsContext);
	const daysText = <>{t("days", { count: 2 })}</>;
	const reviewPeriodNumericFlag = 2;
	const initialReviewPeriodNumeric = getValues("reviewPeriodNumeric");

	const [pristine, setPristine] = useState(true);

	const [reviewPeriodExpression, setReviewPeriodExpression] = useState<
		string | null
	>(Modifiers.getValidExpressions(getValues("reviewPeriodExpression")));

	const [reviewPeriodNumeric, setReviewPeriodNumeric] = useState<number | null>(
		getValues("reviewPeriodNumeric") ? getValues("reviewPeriodNumeric") : null
	);

	const recomendationModeNew = useWatch({ control, name: "recommendationMode" });

	const [choosedCheckbox, setChoosedCheckbox] = useState(
		initialReviewPeriodNumeric ? reviewPeriodNumericFlag : Verifications.CheckType(getValues("reviewPeriodExpression"))
	);
	const [dayInMonth, setDayInMonth] = useState<string>(
		Modifiers.getDaysInMonth(reviewPeriodExpression).join(",")
	);
	const [daysInWeek, setDaysInWeek] = useState<any[]>(
		Modifiers.getDaysInWeek(reviewPeriodExpression, t) || []
	);

	const getRecommendationMode = getValues("recommendationMode");

	const RecomendationPeriodValues = [
		{ text: SBTranslate("notDefined"), value: ReviewPeriodExpressionPosition.NotDefined, tipsContext: "NotDefined" },
		{ text: SBTranslate("everyDay"), value: ReviewPeriodExpressionPosition.Default, tipsContext: "SupplyEveryday" },
		{ text: SBTranslate("each"), value: reviewPeriodNumericFlag, tipsContext: "SupplyEach" },
		{ text: SBTranslate("eachDayInMonth"), value: ReviewPeriodExpressionPosition.DayOfMonth, tipsContext: "SupplyEachDayInMonth" },
		{ text: SBTranslate("eachDaysInWeek"), value: ReviewPeriodExpressionPosition.DayOfWeek, tipsContext: "SupplyEachDayInWeek" }
	];

	const handleRecommendationModeSelect = (value: ReviewPeriodExpressionPosition) => {
		if (choosedCheckbox === value) return;

		tipsContext.showTip(RecomendationPeriodValues.find(r => r.value == value)?.tipsContext ?? "");
		setChoosedCheckbox(value);

		if (value === reviewPeriodNumericFlag)
			setReviewPeriodExpression(null);

		if (value !== reviewPeriodNumericFlag) {
			setReviewPeriodNumeric(null);
			setValue("reviewPeriodNumeric", null)
		}

		if (value === ReviewPeriodExpressionPosition.Default)
			setReviewPeriodExpression(Default.allDays);

		if (value === ReviewPeriodExpressionPosition.NotDefined)
			setReviewPeriodExpression(Default.nullable);

		setDayInMonth("");
		setDaysInWeek([]);
	};

	const changeDayInMonth = (value: string, e: any) => {
		var v = Modifiers.updateDayInMonth(value);

		if (v == null) return;
		var listOfDays = v
			.map((d: any) =>
				d.length == 2 || d === "L" || d == "l"
					? `${d},`.toUpperCase()
					: d
			)
			.join("");

		if (
			listOfDays[listOfDays.length - 1] === "," &&
			e.nativeEvent.inputType === "deleteContentBackward"
		)
			listOfDays = listOfDays.substring(0, listOfDays.length - 1);

		setDayInMonth(listOfDays);
		setReviewPeriodExpression(createCronExpression(v, false));
	};

	const changeDaysInWeek = (value: string) => {
		var newDaysInWeek = Modifiers.updateDaysInWeek(value, daysInWeek);

		setDaysInWeek(newDaysInWeek);
		setReviewPeriodExpression(createCronExpression(false, newDaysInWeek));
	};

	const updateReviewPeriodNumeric = (value: string) => setReviewPeriodNumeric(value.positiveNumber());

	useEffect(() => {
		if (recomendationModeNew === RecommendationMode.RecommendationScheduled && choosedCheckbox === reviewPeriodNumericFlag) {
			setChoosedCheckbox(ReviewPeriodExpressionPosition.NotDefined);
			setValue("reviewPeriodNumeric", null)
		}

		if (recomendationModeNew === RecommendationMode.RecommendationNotDefined) {
			setChoosedCheckbox(ReviewPeriodExpressionPosition.NotDefined);
			setReviewPeriodExpression(null);
			setValue("reviewPeriodNumeric", null)
		}

		if (!pristine) {
			setPristine(false);
			if (recomendationModeNew !== RecommendationMode.RecommendationOnDemand)
				handleRecommendationModeSelect(ReviewPeriodExpressionPosition.NotDefined);
		}
	}, [recomendationModeNew]);

	useEffect(() => {
		onUpdate(reviewPeriodExpression);
	}, [reviewPeriodExpression]);

	return (
		<SBBox display="flex" flexDirection="column" mt={2} className={cronConfigurationStyles ? classes.cronConfigurationContainerStyles : ""}>
			<SBBox display="flex">
				<SBTypography
					mb={1}
					fontWeight="500"
					color="gray3.main"
					variant={"body1"}
					textTransform={"uppercase"}>

					{t("whenToReceiveSupplyRecommendation")}
				</SBTypography>
			</SBBox>
			<SBBox mb={2} className={cronConfigurationStyles ? classes.cronConfigurationFormStyles : ""}>
				<SBGrid item xs={12}>
					<SBSelect
						fullWidth
						getText={item => t(item.text)}
						getValue={item => item.value}
						onChange={(event) => handleRecommendationModeSelect(Number(event.target.value))}
						value={choosedCheckbox}
						disabled={(getRecommendationMode == RecommendationMode.RecommendationNotDefined || getRecommendationMode == null) && supplyReviewPeriodFlag == false}
						items={getRecommendationMode == RecommendationMode.RecommendationOnDemand ?
							RecomendationPeriodValues : RecomendationPeriodValues.filter(r => r.value != reviewPeriodNumericFlag)}
					/>
				</SBGrid>
			</SBBox>
			<Collapse in={Verifications.IsDayOfMonth(choosedCheckbox)}>
				<EachDayInMonth
					value={dayInMonth}
					onChange={changeDayInMonth}
					fetchPropError={fetchPropError}
				/>
			</Collapse>
			<Collapse in={Verifications.IsDayOfWeek(choosedCheckbox)}>
				<EachDayInWeek
					daysInWeek={daysInWeek}
					onChange={changeDaysInWeek}
					fetchPropError={fetchPropError}
				/>
			</Collapse>
			<Collapse in={choosedCheckbox == reviewPeriodNumericFlag && recomendationModeNew === RecommendationMode.RecommendationOnDemand}>
				<SBGrid container spacing={4}>
					<SBGrid item xs={3} marginBottom={2}>
						<ConfigTextField
							section="supply"
							type="integer"
							prop="reviewPeriodNumeric"
							onChange={(e) => updateReviewPeriodNumeric(e.target.value.naturalNumber())}
							title={SBTranslate("each")}
							endAdornment={daysText}
						/>
					</SBGrid>
				</SBGrid>
			</Collapse>
		</SBBox>
	);
};

export interface SupplyReviewPeriodProps {
	recommendationMode?: RecommendationMode | null;
	onUpdate: (reviewPeriodExpression: string | null) => void;
	fetchPropError?: (props: any) => any;
	cronConfigurationStyles?: boolean;
	supplyReviewPeriodFlag?: boolean;
}