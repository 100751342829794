import { useSnackbar } from "modules/Commons/SBOverlays/SBSnackbar";
import TransferenceInfoModal from "modules/Transferences/components/TransferenceInfoModal";
import { TransferencePlan } from "modules/Transferences/models/transferencePlan";
import { TransferenceResponse } from "modules/Transferences/models/transferenceResponse";
import { TransferencesRequester } from "modules/Transferences/services/TransferencesRequester";
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

const TransferenceContext = createContext({} as TransferenceProps);

export default ({ children }: { children: ReactNode }) => {
	const { t } = useTranslation();
	const { error } = useSnackbar();
	const [transferenceStatus, setTransferenceStatus] = useState<any>("none");
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [transferences, setTransferences] = useState<TransferenceResponse[]>(
		[]
	);

	const setDescriptionByStatus = async (data: TransferenceResponse[]) => {
		await Promise.resolve(
			data.map((d) => {
				switch (d.status) {
					case 1: {
						d.description = t("invalidTransferStatus");
						break;
					}
					case 2: {
						d.description = t("notEnoughQuantityStatus", {
							count: d.totalAvailable,
						});
						break;
					}
				}
			})
		);
	};

	const handleTabClose = useCallback((event: any) => {
		event.preventDefault();

		return (event.returnValue = t("inProgressTransferProcessWarning"));
	}, []);

	useEffect(() => {
		return () => {
			window.removeEventListener("beforeunload", handleTabClose);
		};
	}, []);

	const transfer = async (
		selectedItems: string[],
		transferencePlans: TransferencePlan[]
	) => {
		handleOpen();
		setTransferenceStatus("loading");
		TransferencesRequester.createExternalOrder({
			selectedItems,
			transferencePlans,
		})
			.then((data) => {
				setDescriptionByStatus(data);
				setTransferences(data);
			})
			.catch((err) => {
				error(err);
			})
			.finally(() => setTransferenceStatus("done"));
	};

	const handleOpen = () => {
		setIsOpen(true);
		window.addEventListener("beforeunload", handleTabClose);
	};

	const handleClose = () => {
		setIsOpen(false);
		window.removeEventListener("beforeunload", handleTabClose);
	};

	return (
		<TransferenceContext.Provider
			value={{
				transferenceStatus,
				transferences,
				transfer,
				canTransfer: () => transferenceStatus !== "loading",
			}}
		>
			{children}
			<TransferenceInfoModal isOpen={isOpen} close={handleClose} />
		</TransferenceContext.Provider>
	);
};

export const useTransferenceContext = () => useContext(TransferenceContext);

interface TransferenceProps {
	transferenceStatus: "done" | "stopped" | "loading" | "none";
	canTransfer: () => boolean;
	transferences: TransferenceResponse[];
	transfer: (a: string[], b: TransferencePlan[]) => void;
}
