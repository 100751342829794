import clsx from "clsx";
import { useClickAway } from "hooks/useClickAway";
import {
	SBBox,
	SBIcon,
	SBIconButton,
	SBOutlinedButton,
	SBTypography,
} from "modules/Commons";
import { useMemo, useRef, useState, useEffect } from "react";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";

export default ({
	disabled,
	actions,
	color,
	loading,
	items,
}: SBActionsMenuProps) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const activeColor = color ?? "#7f51ff";
	const disabledColor = "gray4";
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef();
	const availableActions = useMemo(
		() => actions?.filter((action) => !action.hide),
		[actions]
	);

	const toShowOnDropActions = useMemo(() => availableActions.slice(1), [availableActions]);
	const firstAction = useMemo(() => availableActions[0], [availableActions]);

	useClickAway(ref, () => {
		setIsOpen(false);
	});

	return (
		<SBBox style={{ position: "relative" }} ref={ref}>
			<SBBox width={1}>
				<SBOutlinedButton
					fullWidth
					className={clsx(
						classes.sbExportButton,
						isOpen && "active",
						loading && "loading",
						disabled && "disabled"
					)}
					onClick={() => {
						firstAction.onClick(items);
						setIsOpen(false);
					}}
					disabled={disabled}
					loading={loading}
				>
					{!loading && (
						<SBIcon
							icon={firstAction.icon?.name ?? "download"}
							color={disabled || loading ? "gray4" : "primary"}
							size={17}
						/>
					)}
					<SBTypography mx={1} py={0.3} mr="auto" fontWeight="700">
						{t(firstAction.title)}
					</SBTypography>
					{toShowOnDropActions.length > 0 && (
						<SBBox
							onClick={(e: any) => {
								e.stopPropagation();
								setIsOpen((c) => !c);
							}}
							className={clsx(
								classes.sbExportButtonIcon,
								isOpen && "active"
							)}
							ml={1}
						>
							<SBIcon
								icon="select"
								fillColor={
									loading || disabled ? "gray4" : "primary"
								}
								size={17}
							/>
						</SBBox>
					)}
				</SBOutlinedButton>
			</SBBox>
			<SBBox
				className={clsx(
					classes.sbExportButtonContainer,
					isOpen && "active"
				)}
				display="flex"
				flexDirection="column"
				width={1}
				height={
					isOpen
						? `calc(100% * ${toShowOnDropActions?.length ?? 0})`
						: "0%"
				}
			>
				{toShowOnDropActions.map((action) => {
					const icon = action.icon?.name ?? "download";

					return (
						<SBOutlinedButton
							key={action.title}
							onClick={() => {
								action.onClick(items);
								setIsOpen(false);
							}}
							style={{ border: "none" }}
						>
							<SBIcon
								icon={icon}
								color={disabled ? disabledColor : activeColor}
								size={17}
							/>
							<SBTypography
								mx={1}
								py={0.3}
								mr="auto"
								fontWeight="700"
								style={{ textTransform: "none" }}
							>
								{t(action.title)}
							</SBTypography>
						</SBOutlinedButton>
					);
				})}
			</SBBox>
		</SBBox>
	);
};

export interface SBActionsMenuProps {
	disabled?: boolean;
	color?: string;
	loading?: boolean;
	items?: any[];
	actions: {
		title: string;
		disabled?: boolean;
		onClick: (items: any) => any;
		hide?: boolean;
		icon?: {
			name: string;
			color?: string;
		};
	}[];
}
