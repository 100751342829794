import { ButtonGroup } from "@mui/material";
import { IComposedFilter } from "domain/Utils";
import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import useFilterCache from "hooks/useFilterCache";
import routerHistory from "hooks/useRouterHistory";
import {
	SBBox,
	SBButton,
	SBGrid,
	SBLoading,
	SBTypography,
} from "modules/Commons";
import SBEmptyState from "modules/Commons/SBLayouts/SBEmptyState";
import { useSnackbar } from "modules/Commons/SBOverlays/SBSnackbar";
import { HomeEmptyState } from "modules/Home/HomeEmptyState";
import SBInventoryExcessTopItems from "modules/Inventory/components/SBInventoryExcessTopItems";
import SBInventoryReservedTopItems from "modules/Inventory/components/SBInventoryReservedTopItems";
import SBInventoryScarcityTopItems from "modules/Inventory/components/SBInventoryScarcityTopItems";
import { NotificationsRequester } from "modules/Notifications/services/NotificationsRequester";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import SBComposedFilter from "views/Commons/SBComposedFilter";
import SBCSLStatusCards from "views/Commons/SBCSLStatusCards";
import SBCurrentStatusInfo from "views/Commons/SBCurrentStatusInfo";
import SBInventoryGaugeInfo from "views/Commons/SBInventoryGaugeInfo";
import SBInventoryInfoSection from "views/Commons/SBInventoryInfoSection";
import SBInventoryOptimizationCard from "views/Commons/SBInventoryOptimizationCard";
import SBSupplyOptimizationAccordion from "views/Commons/SBSupplyOptimizationAccordion";
import { useStyles } from "./style";
import { SBForecastEvaluation } from "modules/Forecast/Components";
import SBTotalCostCard from "views/Commons/SBTotalCostCard";
import SBVirtualStockCard from "views/Commons/SBVirtualStockCard";
import SBDeadInventoryCard from "views/Commons/SBDeadInventoryCard";
import { useSystemFilterContext } from "contexts/SystemFilterContext";
import SBContextDateInfo from "views/Commons/SBContextDateInfo";
import { useDateStorageCache } from "hooks/useDateStorageCache";
import { DateTime } from "luxon";
import { useMixpanelContext } from "contexts/MixpanelContext";

export default () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { error } = useSnackbar();
	const { search } = useLocation();

	const { context, filtersContext, setFiltersContextState, buildContextSwitch, notCurrentContext } = useSystemFilterContext();
	const { getDateItem, setDateItem } = useDateStorageCache();
	const { trackHistoryChange } = useMixpanelContext();

	const [loading, setLoading] = useState(true);
	const [availableDates, setAvailableDates] = useState<string[]>([]);
	const [statistics, setStatistics] = useState({} as IHomeStatistics);
	const [filters, setFilters] = useState({} as IComposedFilter);
	const [isCost, setIsCost] = useState(false);

	const getAvailableDates = async () => {
		const result = await NotificationsRequester.homeStatisticsDates();
		if (result) setAvailableDates(result)
	};

	const handleInventoriesClick = () => {
		routerHistory.push("/home/details" + search);
	};

	const handleApply = async (filter: IComposedFilter) => {
		setLoading(true);
		filter.picker?.date && setDateItem(filter.picker.date.toFormat("yyyy-MM-dd"))
		setFilters(filter);
		setStatistics({} as IHomeStatistics);

		if (filter.filter)
			setFiltersContextState(filter.filter);
		else
			setFiltersContextState([]);

		trackHistoryChange({ date: getDateItem() });

		NotificationsRequester
			.homeStatistics(filter)
			.then((statistics) => setStatistics(statistics))
			.catch(() => error(t("error")))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (context) {
			handleApply({ filter: filtersContext?.items } as IComposedFilter);
		}
	}, [context]);

	useEffect(() => {
		getAvailableDates();
	}, [])

	useEffect(() => {
		buildContextSwitch && setLoading(true);
	}, [buildContextSwitch])

	return (
		<SBBox
			pl={10}
			pb={4}
			mx="auto"
			style={{ overflowY: "auto" }}
			height={1}
		>
			<SBGrid container display="flex" alignItems="center">
				<SBGrid item>
					<SBComposedFilter.Base onApply={handleApply}>
						<SBComposedFilter.Picker date={getDateItem() && DateTime.fromISO(getDateItem())} range={false} availableDates={availableDates} />
						<SBComposedFilter.Filter />
						<SBComposedFilter.Applicator />
					</SBComposedFilter.Base>
				</SBGrid>

				{statistics?.evaluation?.demand &&
					statistics?.currentStatus?.inventories > 0 && (
						<>
							<SBGrid item>
								<SBBox
									className={classes.inventoryButton}
									onClick={handleInventoriesClick}
									px={2}
									py={0.8}
									ml={3}
								>
									<SBTypography
										variant="body2"
										color="primary"
									>
										{t("foundedInventories", {
											count: statistics?.currentStatus
												?.inventories,
										})}
									</SBTypography>
								</SBBox>
							</SBGrid>
							<SBBox
								ml="auto"
								mr={3}
								display="flex"
								alignItems="center"
							>
								<SBTypography
									fontWeight="500"
									color="primary.main"
									mr={2}
								>
									{t(isCost ? "cost" : "quantity")}
								</SBTypography>
								<ButtonGroup
									size="small"
									aria-label="outlined button group"
								>
									<SBButton
										style={{ boxShadow: "none" }}
										onClick={() => setIsCost((c) => false)}
										color={!isCost ? "primary" : "gray6"}
									>
										#
									</SBButton>
									<SBButton
										style={{ boxShadow: "none" }}
										onClick={() => setIsCost((c) => true)}
										color={isCost ? "primary" : "gray6"}
									>
										$
									</SBButton>
								</ButtonGroup>
							</SBBox>
						</>
					)}
			</SBGrid>

			{notCurrentContext() ? <SBGrid container>
				<SBContextDateInfo context={DateTime.fromISO(getDateItem())} />
			</SBGrid> : <></>}
			<SBLoading loading={loading}>
				<SBEmptyState
					show={statistics?.currentStatus?.inventories > 0}
					emptyState={<HomeEmptyState />}
				>
					<SBBox pt={3} mt={1} pb={2} pl={1} pr={2} ml={1} mr={2}>
						<SBForecastEvaluation statistics={statistics} />
					</SBBox>

					<SBGrid container spacing={2} width={1} pl={1} pt={1}>
						<SBGrid item xs={12}>
							<SBInventoryInfoSection
								isCost={isCost}
								statistics={statistics}
							/>
						</SBGrid>
						<SBGrid item container spacing={2} columns={10}>
							<SBGrid item xs={4}>
								<SBSupplyOptimizationAccordion
									statistics={statistics}
									filters={filters}
								/>
							</SBGrid>
							<SBGrid item xs={3}>
								<SBInventoryGaugeInfo statistics={statistics} />
							</SBGrid>
							<SBGrid item xs={3}>
								<SBInventoryOptimizationCard
									statistics={statistics}
								/>
							</SBGrid>
						</SBGrid>
						<SBGrid item container spacing={2} columns={12}>
							<SBGrid item xs={6}>
								<SBTotalCostCard statistics={statistics} />
							</SBGrid>
							<SBGrid item xs={3}>
								<SBDeadInventoryCard statistics={statistics} />
							</SBGrid>
							<SBGrid item xs={3}>
								<SBVirtualStockCard statistics={statistics} />
							</SBGrid>
						</SBGrid>
						<SBGrid item container spacing={2} columns={10}>
							<SBGrid item xs={4} height={180}>
								<SBCurrentStatusInfo isCost={isCost} statistics={statistics} />
							</SBGrid>
							<SBGrid item xs={6} height={180}>
								<SBCSLStatusCards statistics={statistics} />
							</SBGrid>
						</SBGrid>
						<SBGrid item xs={4}>
							<SBInventoryReservedTopItems
								topStats={statistics}
							/>
						</SBGrid>
						<SBGrid item xs={4}>
							<SBInventoryExcessTopItems topStats={statistics} />
						</SBGrid>
						<SBGrid item xs={4}>
							<SBInventoryScarcityTopItems
								topStats={statistics}
							/>
						</SBGrid>
					</SBGrid>
				</SBEmptyState>
			</SBLoading>
		</SBBox>
	);
};
