import { useSystemFilterContext } from "contexts/SystemFilterContext";
import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import useFilterCache from "hooks/useFilterCache";
import { SBTranslate } from "i18n";
import { useGlobalInventoriesVirtualStatusContext } from "modules/Inventory/components/SBGlobalInventoriesVirtualStatus";
import { NotificationsRequester } from "modules/Notifications/services/NotificationsRequester";
import SBVirtualStockAbnormalCard from "modules/VirtualStock/components/SBVirtualStockAbnormalCard";
import SBVirtualStockNormalCard from "modules/VirtualStock/components/SBVirtualStockNormalCard";

export default ({ statistics }: SBVirtualStockCardProps) => {
	const { filtersContext } = useSystemFilterContext();
	const { fetchData } = useGlobalInventoriesVirtualStatusContext();

	const fetchVirtualStocks = async () => {
		fetchData(new Promise(async (res, rej) => {
			res(await NotificationsRequester.virtuals(filtersContext));
		}), {
			title: SBTranslate("commons.virtualStock_other"),
			columnName: SBTranslate("commons.virtualStock"),
		});
	};

	return (statistics?.evaluation?.virtualStockCount?.value > 0 ?
		<SBVirtualStockAbnormalCard
			statistics={statistics}
			onSeeAll={fetchVirtualStocks}
		/> : <SBVirtualStockNormalCard
			statistics={statistics}
		/>
	);
};

interface SBVirtualStockCardProps {
	statistics: IHomeStatistics;
}
