import { SBTableColumn } from "../../models/TableColumn";

export const fetchColumnSize = <T>({ columns, rows }: fetchMinSizeProps<T>) => {
	const charInPixels = 13;
	const charInPixels2 = 13;

	const dict = new Map<string, number | string>();
	const longest = (accessor: any, array: any[], formatter: any) =>
		Math.max(
			...array.map((it) => {
				const value = accessor(it);
				return formatter
					? ("" + formatter(value)).length + 4
					: ("" + value).length + 1;
			})
		);

	for (var column of columns.flatMap((c) => c.headers)) {
		const maxChar = longest(column!.id, rows, column!.formatter);

		const cellWidth = !!column?.style
			? maxChar * charInPixels
			: maxChar * charInPixels2 ?? 0;

		dict.set(column?.key!, column?.width ?? cellWidth);
	}
	return dict;
};

interface fetchMinSizeProps<T> {
	columns: SBTableColumn<T>[];
	rows: T[];
}
