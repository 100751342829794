import { IInventory, IInventoryPosition } from "domain/Inventory";
import { IComposedFilter } from "domain/Utils";
import { IHomeRowStatistics } from "domain/Utils/models/IHomeRowStatistics";
import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import { useRequest } from "hooks/useRequest";
import { SystemContextRequester } from "modules/SystemContext/services/SystemContextRequester";

export const NotificationsRequester = (() => {
	const request = useRequest("notifications");

	return {
		getTags: async () => {
			const result = await request.GET("tags");
			if (result.status === 204) return await request.GET("tags");
			return result;
		},
		notify: (selectedFilter: {
			name: string;
			icon: string;
			type: string;
		}) => {
			return request.GET(`notify/${selectedFilter?.name}`);
		},
		statistics: (id: number) => {
			return request.GET(`statistics/${id}`);
		},
		reserveds: async (filter: any) => {
			return request
				.POST(`home/statistics/reserved`, filter ?? {})
				.then((c) => {
					return c.data as IInventory[];
				});
		},
		optimizedInventories: async (filter: any) => {
			return request
				.POST(`home/statistics/optimizedInventories`, filter ?? {})
				.then((c) => {
					return c.data as IInventory[];
				});
		},
		notOptimizedInventories: async (filter: any) => {
			return request
				.POST(`home/statistics/notOptimizedInventories`, filter ?? {})
				.then((c) => {
					return c.data as IInventory[];
				});
		},
		balanced: async (filter: any) => {
			return request
				.POST(`home/statistics/balanced`, filter ?? {})
				.then((c) => {
					return c.data as IInventory[];
				});
		},
		unbalanced: async (filter: any) => {
			return request
				.POST(`home/statistics/unbalanced`, filter ?? {})
				.then((c) => {
					return c.data as IInventory[];
				});
		},
		excess: async (filter: any) => {
			return request
				.POST(`home/statistics/excess`, filter ?? {})
				.then((c) => {
					return c.data as IInventory[];
				});
		},
		unconfigureds: async (filter: any) => {
			return request.POST(`home/unconfigured`, filter ?? {}).then((c) => {
				return c.data as IInventory[];
			});
		},
		scarcity: async (filter: any) => {
			return request
				.POST(`home/statistics/scarcity`, filter ?? {})
				.then((c) => {
					return c.data as IInventory[];
				});
		},
		deadInventoriesCount: async (filter: any) => {
			return request
				.POST(`home/statistics/deadInventoriesCount`, filter ?? {})
				.then((c) => {
					return c.data;
				});
		},
		deadInventories: async (filter: any) => {
			return request
				.POST(`home/statistics/deadInventories`, filter ?? {})
				.then((c) => {
					return c.data as IInventoryPosition[];
				});
		},
		virtuals: async (filter: any) => {
			return request
				.POST(`home/statistics/virtuals`, filter ?? {})
				.then((c) => {
					return c.data as IInventory[];
				});
		},
		homeStatisticsDates: async () => {
			return request.GET(`home/statistics/dates`).then((d) => {
				return d.data as string[];
			});
		},
		homeStatistics: (composedFilter: IComposedFilter) => {
			const products = composedFilter?.filter?.filter(
				(c: any) => c.type === "product"
			);
			const categories = composedFilter?.filter?.filter(
				(c: any) => c.type === "category"
			);
			const stores = composedFilter?.filter?.filter(
				(c: any) => c.type === "store"
			);

			return request
				.POST(`home/statistics`, {
					products,
					categories,
					stores,
				})
				.then((c) => {
					return c.data as IHomeStatistics;
				});
		},
		homeStatisticsTable: async (composedFilter: IComposedFilter) => {
			const products = composedFilter?.filter?.filter(
				(c: any) => c.type === "product"
			);
			const categories = composedFilter?.filter?.filter(
				(c: any) => c.type === "category"
			);
			const stores = composedFilter?.filter?.filter(
				(c: any) => c.type === "store"
			);

			return request
				.POST(`home/statistics/table`, {
					products,
					categories,
					stores,
				})
				.then((c) => {
					return c.data as IHomeRowStatistics[];
				});
		},
	};
})();
