import { Tooltip } from "@mui/material";
import clsx from "clsx";
import { SBBox, SBTypography, SBIcon, SBGrid } from "modules/Commons";
import { ISimulationRow } from "modules/Simulations/models/ISimulationRow";
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";

export default ({ simulationRow }: SimulationCardCSLIndicatorProps) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const hasScarcity =
		simulationRow.inventoryConfig.minCSL &&
		simulationRow.currentCSL <= simulationRow.inventoryConfig.minCSL;

	const hasExcess =
		simulationRow.inventoryConfig.maxCSL &&
		simulationRow.currentCSL >= simulationRow.inventoryConfig.maxCSL;

	const hasDeviation = hasScarcity || hasExcess;

	const currentValue =
		simulationRow.currentCSL >= 0
			? `${(simulationRow.currentCSL * 100)?.systemFormatAsPercentage()}%`
			: "";

	const excessValue = simulationRow.inventoryConfig.maxCSL
		? `${(
				simulationRow.inventoryConfig.maxCSL * 100
		  ).systemFormatAsPercentage()}%`
		: "---";

	const scarcityValue = simulationRow.inventoryConfig.minCSL
		? `${(
				simulationRow.inventoryConfig.minCSL * 100
		  ).systemFormatAsPercentage()}%`
		: "---";

	return (
		<SBGrid
			container
			alignItems="center"
			className={classes.sbSimulationCardCSLIndicator}
		>
			<SBGrid item xs={4}>
				<SBTypography
					color="iconGray.main"
					fontWeight="500"
					fontSize={12}
					mr={1}
				>
					{`${t("csl")}`}
				</SBTypography>
				<SBBox display="flex">
					<SBTypography
						className={clsx(
							"text",
							hasDeviation ? "error" : "success"
						)}
					>
						{currentValue}
					</SBTypography>
					<Tooltip
						placement="right"
						title={
							<SBBox display="flex" flexDirection="column">
								<SBTypography variant="caption">
									{`${t("excess")}: ${excessValue}`}
								</SBTypography>
								<SBTypography variant="caption">
									{`${t("scarcity")}: ${scarcityValue}`}
								</SBTypography>
							</SBBox>
						}
					>
						<SBBox display="flex" alignItems="center">
							{hasDeviation && (
								<SBBox ml={0.5}>
									<SBIcon
										icon="alert-triangle"
										color="error"
										size={16}
									/>
									<SBIcon
										icon="arrow-right"
										color="error"
										size={14}
										className={clsx(
											"icon",
											hasScarcity && "rotate"
										)}
									/>
								</SBBox>
							)}
						</SBBox>
					</Tooltip>
				</SBBox>
			</SBGrid>
			<SBGrid item xs={4}>
				<SBTypography
					color="iconGray.main"
					fontWeight="500"
					fontSize={12}
					mr={1}
				>
					{t("onHand")}
				</SBTypography>
				<SBTypography color="darkPurple.main">
					{simulationRow.inventory.onHand}
				</SBTypography>
			</SBGrid>
			<SBGrid item xs={4}>
				<SBTypography
					color="iconGray.main"
					fontWeight="500"
					fontSize={12}
					mr={1}
				>
					{t("onOrder")}
				</SBTypography>
				<SBTypography color="darkPurple.main">
					{simulationRow.inventory.onOrder}
				</SBTypography>
			</SBGrid>
		</SBGrid>
	);
};

interface SimulationCardCSLIndicatorProps {
	simulationRow: ISimulationRow;
}
