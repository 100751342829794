import { Grid } from "@mui/material";
import { useAuthenticator } from "hooks/useAuthenticator";
import { SBBox } from "modules/Commons";
import { LoginInformational } from "./LoginInformational";
import { useStyles } from "./style";
import { Redirect } from "react-router-dom";
import { useEffect } from "react";
import LoginForm from "./LoginForm";

export const LoginView = ({ onLogin, onEnterLogin }: LoginViewProps) => {
	const { isLogged } = useAuthenticator();
	const classes = useStyles();

	useEffect(() => onEnterLogin(), [onEnterLogin]);

	return !isLogged() ? (
		<Grid
			container
			className={classes.mainContainer}
			alignContent="center"
			bgcolor="lightPurple"
		>
			<Grid item xs={1} />
			<Grid item xs={3}>
				<LoginForm onLogin={onLogin} />
			</Grid>
			<Grid item xs={2} />
			<Grid item xs={4} display="flex" alignContent="center">
				<SBBox my="auto">
					<LoginInformational />
				</SBBox>
			</Grid>
		</Grid>
	) : (
		<Redirect
			to={{
				pathname: "/home",
			}}
		/>
	);
};

interface LoginViewProps {
	/** Function that will trigger when clicked on login*/
	onLogin: (email: string, password: string) => any;
	/** Function that will trigger when clickedlogin has been successfull*/
	onEnterLogin: () => any;
}
