import { AccessorFnColumnDef, DisplayColumnDef } from "@tanstack/react-table";
import SBIndeterminateCheckbox from "../SBIndeterminateCheckbox";

export const buildSelectColumn = () => {
	return {
		id: "select",
		maxSize: 10,
		header: ({ table }: any) => (
			<SBIndeterminateCheckbox
				{...{
					checked: table.getIsAllRowsSelected(),
					indeterminate: table.getIsSomeRowsSelected(),
					onChange: table.getToggleAllRowsSelectedHandler(),
				}}
			/>
		),
		cell: ({ row }: any) => (
			<div className="px-1">
				<SBIndeterminateCheckbox
					{...{
						checked: row.getIsSelected(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler(),
					}}
				/>
			</div>
		),
	} as AccessorFnColumnDef<any, unknown>;
};
