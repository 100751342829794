import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { IStatisticsSupplyModel } from "domain/Utils/models/IStatisticsSupplyModel";
import { SBBox, SBTypography } from "modules/Commons";
import { Key, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useStyles } from "./style";

export default (props: SBStatisticSupplyInfoProps) => {
	const {
		onClick,
		route,
		color = "black",
		actionLabel,
		emergencial,
		recommended,
		hasMultiple,
		isTransfer = false,
		onChange,
	} = props;

	const { t } = useTranslation();
	const classes = useStyles({ color });
	const [selected, setSelected] = useState<string>("total");

	const items = [
		{
			label: t("value"),
			accessor: (e: IStatisticsSupplyModel) =>
				e.value?.systemFormatAsMoney(),
		},
		{
			label: t("quantity"),
			accessor: (e: IStatisticsSupplyModel) => e.quantity?.systemFormat(),
		},
		{
			label: t("inventory_other"),
			accessor: (e: IStatisticsSupplyModel) =>
				e.inventories?.length.systemFormat(),
		},
		{
			label: t("product_other"),
			accessor: (e: IStatisticsSupplyModel) =>
				e.products?.length.systemFormat(),
		},
		{
			label: t("store_other"),
			accessor: (e: IStatisticsSupplyModel) =>
				e.destinations?.length.systemFormat(),
		},
		{
			label: t("supplier_other"),
			accessor: (e: IStatisticsSupplyModel) =>
				e.sources?.length.systemFormat(),
		},
	];

	const handleChange = (event: SelectChangeEvent) => {
		setSelected(event.target.value);
		var selected = event.target.value;
		if (selected === "total") onChange?.(undefined);
		else onChange?.(selected === "emergencial");
	};

	const handleClick = () => {
		if (selected === "total") onClick(undefined);
		else onClick(selected === "emergencial");
	};

	const total = useMemo(() => {
		return Object.keys(recommended).reduce((a, b) => {
			const prop = b as keyof IStatisticsSupplyModel;

			const emergencialValue = !!emergencial ? emergencial[prop] : 0;
			const recommendedValue = !!recommended ? recommended[prop] : 0;

			if (isTransfer) {
				return {
					...a,
					[b]: recommendedValue,
				};
			}

			if (Array.isArray(recommendedValue)) {
				var filteredValues = (emergencialValue as number[]).concat(recommendedValue);
				filteredValues = [...new Set(filteredValues)];
				return {
					...a,
					[b]: filteredValues,
				};
			}
			return {
				...a,
				//@ts-ignore
				[b]: emergencialValue + recommendedValue,
			};
		}, {} as IStatisticsSupplyModel);
	}, [emergencial, recommended]);

	const currentValue = useMemo(() => {
		if (selected === "emergencial")
			return emergencial || ({} as IStatisticsSupplyModel);
		if (selected === "recommended") return recommended;

		return total;
	}, [selected, emergencial, recommended]);

	return (
		<SBBox className={classes.container}>
			<SBBox display="flex" justifyContent="space-between">
				{hasMultiple && (
					<SBBox mr={2} width={1}>
						<Select
							size="small"
							fullWidth
							value={selected}
							onChange={handleChange}
						>
							<MenuItem value={"total"}>{t("total")}</MenuItem>
							<MenuItem value={"recommended"}>
								{t("recommended")}
							</MenuItem>
							<MenuItem value={"emergencial"}>
								{t("emergencial")}
							</MenuItem>
						</Select>
					</SBBox>
				)}
				<Link to={route} className={classes.action} color={color}>
					<SBTypography
						color={color}
						textTransform="capitalize"
						fontWeight="500"
					>
						{actionLabel}
					</SBTypography>
				</Link>
			</SBBox>
			{items.map((item) => {
				return (
					<SBBox className={classes.value}>
						<SBTypography fontWeight="500">
							{item.label}
						</SBTypography>
						<SBTypography fontWeight="500">
							{item.accessor(currentValue) ?? "-"}
						</SBTypography>
					</SBBox>
				);
			})}
		</SBBox>
	);
};

interface SBStatisticSupplyInfoProps {
	color?: string;
	actionLabel: string;
	hasMultiple?: boolean;
	isTransfer?: boolean;
	route: string;
	onClick: (isEmergencial?: boolean) => void;
	onChange?: (isEmergencial?: boolean) => void;
	recommended: IStatisticsSupplyModel;
	emergencial?: IStatisticsSupplyModel;
}
