import { useTranslation } from "react-i18next";

export const useHeaders = () => {
	const { t } = useTranslation();
	const headers = [
		{
			id: "Groupment",
			name: t("groupment"),
			headers: [
				{
					id: (e: any) => e.name,
					key: "productName",
					name: t("name"),
					align: "left",
					noWrap: true,
					width: "90%",
				},
			],
		},
		{
			id: "csl",
			name: t("csl"),
			headers: [
				{
					id: (e: any) =>
						e.csl ? `${(e.csl * 100).toFixed(0)}%` : "",
					name: t("target"),
					width: "1%",
					align: "right",
				},
				{
					id: (e: any) =>
						e.csl != null && e.csl !== undefined
							? `${(100 - e.csl * 100).toFixed(0)}%`
							: "",
					name: t("rupture"),
					width: "1%",
					align: "right",
				},
			],
		},
		{
			id: "Supply",
			name: t("supply"),
			headers: [
				{
					id: (e: any) => e.leadTime,
					name: t("leadTime"),
					width: "5%",
					align: "right",
				},
				{
					id: (e: any) => e.moq,
					name: t("moq"),
					width: "5%",
					align: "right",
				},
				{
					id: (e: any) => e.lotSize,
					name: t("lotSize"),
					width: "5%",
					align: "right",
				},
				{
					id: (e: any) => e.capacity,
					name: t("capacity"),
					width: "5%",
					align: "right",
				},
				{
					id: (e: any) => e.max,
					name: t("max"),
					width: "3%",
					align: "right",
				},
				{
					id: (e: any) => e.min,
					name: t("min"),
					width: "3%",
					align: "right",
				},
			],
		},
	];

	return { headers };
};
