import { Typography, TypographyProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef((props: SBTypographyProps, ref) => {
	const { textTransform, textDecoration, ...otherProps } = props;
	return (
		<Typography
			style={{
				textTransform: !!textTransform ? textTransform : undefined,
				textDecoration: !!textDecoration ? textDecoration : undefined,
				...otherProps.style,
			}}
			{...(otherProps as TypographyProps)}
		/>
	);
});

export interface SBTypographyProps extends TypographyProps {
	textTransform?:
	| "inherit"
	| "none"
	| "capitalize"
	| "lowercase"
	| "uppercase";
	textDecoration?:
	| "auto"
	| "blink"
	| "dashed"
	| "dotted"
	| "double"
	| "from-font"
	| "grammar-error"
	| "line-through"
	| "none"
	| "overline"
	| "solid"
	| "spelling-error"
	| "underline";
}
