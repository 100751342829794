import { IGroupedStatistics } from "domain/Statistic/Models/IGroupedStatistics";
import SBTypography from "modules/Commons/SBDataDisplays/SBTypography";
import SBGrid from "modules/Commons/SBLayouts/SBGrid";

export default ({ item, formatter }: SBGroupedLineCardProps) => {
    return (
        <SBGrid container alignItems="center">
            <SBTypography textTransform="uppercase" fontWeight="400" color="gray2.main" mr={0.5}>
                {item.label}
            </SBTypography>
            <SBTypography fontWeight="600" color="gray2.main" variant="body1" mr={0.5}>
                {formatter
                    ? formatter(item?.value)
                    : item?.value}
            </SBTypography>
            <SBTypography fontSize="0.7rem" fontWeight="400" color="gray2.main">
                {item.unit}
            </SBTypography>
        </SBGrid>
    );
}

interface SBGroupedLineCardProps {
    item: IGroupedStatistics;
    formatter?: (val?: number) => string;
}