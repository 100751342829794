import { IEntity } from "domain/Domain/Entities/IEntity";
import { SBBox, SBSelect, SBTypography } from "modules/Commons";
import { useSimulationCharts } from "modules/Simulations/hooks/useSimulationCharts";
import { SimulationPeriod } from "modules/Simulations/models/simulationPeriod";
import { useTranslation } from "react-i18next";
import SimulationChartFilter from "./SimulationChartFilter";
import SimulationFilterItem from "./SimulationFilterItem";
import { useStyles } from "./styles";

function getKeyByValue<T>(value: string, enumeration: any) {
	const indexOfS = Object.values(enumeration).indexOf(value as unknown as T);
	const key = Object.keys(enumeration)[indexOfS];
	return key;
}

export default ({
	setSelectedChart,
	selectedChart,
	setChartsToShow,
	chartsToShow,
	filters,
	setFilters,
	simulationPeriod,
	onChangePeriod,
}: SimulationTableFilterProps) => {
	const { t } = useTranslation();
	const { variables } = useSimulationCharts();

	const classes = useStyles();
	const validPeriods = [
		SimulationPeriod.Daily,
		SimulationPeriod.Weekly,
		SimulationPeriod.Monthly,
		SimulationPeriod.Yearly,
	];

	return (
		<SBBox display="flex" data-testid="sb-simulaton-table-filter">
			<SBBox display="flex">
				{variables.map((v, index: number) => (
					<SimulationFilterItem
						key={index}
						value={v}
						onClick={(v) => setSelectedChart(v.name)}
						isSelected={selectedChart === v.name}
					/>
				))}
			</SBBox>
			<SBBox display="flex" ml={5} height={1}>
				<SimulationChartFilter
					filters={filters}
					showingCharts={chartsToShow}
					setShowingCharts={setChartsToShow}
					setFilters={setFilters}
				/>
			</SBBox>
			<SBBox ml={10} display="flex" flexDirection="column">
				<SBSelect
					items={validPeriods}
					value={simulationPeriod}
					getText={(e) =>
						t(getKeyByValue(e, SimulationPeriod).toLowerCase())
					}
					onChange={(e) => onChangePeriod(e.target.value)}
					className={classes.filterSelect}
				/>
			</SBBox>
		</SBBox>
	);
};

export interface SimulationTableFilterProps {
	setSelectedChart: any;
	selectedChart: any;
	setChartsToShow: any;
	chartsToShow: any;
	filters: any;
	setFilters: any;
	simulationPeriod: SimulationPeriod;
	onChangePeriod: (period: string) => void;
}
