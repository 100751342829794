import clsx from "clsx";
import SBButton from "../SBButton";
import { useStyles } from "./style";

export default (props) => {
	const { className, ...otherProps } = props;
	const buttonClasses = useStyles();
	return (
		<SBButton
			variant="outlined"
			size="small"
			{...otherProps}
			className={clsx(className, buttonClasses.outlinedButton)}
		/>
	);
};
