import { RowSelectionState } from "@tanstack/react-table";
import { useState, useEffect } from "react";

export default <T>({ data, onSelect }: useTableSelectionProps<T>) => {
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

	useEffect(() => {
		onSelect &&
			onSelect(
				Object.keys(rowSelection).map((c: any) => data[c]),
				Object.keys(rowSelection)
			);
	}, [rowSelection]);

	return { rowSelection, setRowSelection };
};

export interface useTableSelectionProps<T> {
	data: T[];
	onSelect?: (entities: T[], selections: any) => void;
}
