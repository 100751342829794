import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	sbDataTable: {
		borderCollapse: "collapse",
		backgroundColor: "white",
		boxShadow: "0px 2px 24px rgba(0, 0, 0, 0.08)",
		borderRadius: 8,
		"&.active": {
			border: "1px dashed rgba(0,0,0, .3)",
		},
	},
}));
