import { FormControlLabel } from "@mui/material";
import { SBBox, SBGrid, SBSwitch } from "modules/Commons";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ConfigGroupmentDivider from "../ConfigGroupmentDivider";
import ConfigTextField from "../ConfigTextField";
import { TipsContext } from "views/ConfigsView/ConfigTips";
import { useContext } from "react";


export default ({ fetchPropError }: CreateInventoryStockProps) => {
	const { t } = useTranslation();
	const unitsText = <>{t("unit", { count: 2 })}</>;
	const { getValues, register } = useFormContext();
	const getStartAllowBackorder = getValues("allowBackorder");
	const tipsContext = useContext(TipsContext);

	return (
		<SBBox height={1}>
			<ConfigGroupmentDivider title={t("inventoryConfig")} />
			<SBGrid container spacing={4}>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="stock"
						type="integer"
						prop="min"
						title={t("minimum")}
						endAdornment={unitsText}
						helperText={fetchPropError("min")}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="stock"
						type="integer"
						prop="max"
						title={t("maximum")}
						endAdornment={unitsText}
						helperText={fetchPropError("max")}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="stock"
						type="integer"
						prop="emergencyInventory"
						title={t("emergency")}
						endAdornment={unitsText}
						helperText={fetchPropError("emergencyInventory")}
					/>
				</SBGrid>
			</SBGrid>
			<SBGrid container>
				<SBGrid item xs={6}>
					<FormControlLabel
						label={t("allowBackorder") as string}
						{...register("allowBackorder")}
						control={
							<SBSwitch
								defaultChecked={getStartAllowBackorder}
								onClick={() => {
									tipsContext.showTip("AllowBackorder");
								}}
							/>

						}
					/>
				</SBGrid>
			</SBGrid>
		</SBBox>
	);
};

export interface CreateInventoryStockProps {
	onUpdate: (supply: {
		max: number | null;
		min: number | null;
		emergencyInventory: number | null;
	}) => void;
	values: {
		max: number | null;
		min: number | null;
		emergencyInventory: number | null;
	};
	fetchPropError: any;
}
