import { Alert, TableCell, TableHead, TableRow } from "@mui/material";
import Cron from "i18n/cron";
import { SBBox, SBIcon, SBTable, SBTypography } from "modules/Commons";
import { SBInventoryTitle } from "modules/Inventory/components";
import { useTranslation } from "react-i18next";
import { useStyles } from "../style";

export default ({
	onChooseConfig,
	inventoryConfig,
	isValidConfig,
	inventory,
	errors,
}: any) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { translateCronExpression } = Cron({ t });

	const configColumns = [
		{
			name: t("csl"),
			getter: (e: any) =>
				e?.csl?.value ? `${(e?.csl?.value * 100).toFixed(0)} %` : "",
			config: (e: any) => e?.csl?.config,
		},
		{
			name: t("excess"),
			getter: (e: any) =>
				e?.maxCSL?.value
					? `${(e?.maxCSL?.value * 100).toFixed(0)} %`
					: "",
			config: (e: any) => e?.maxCSL?.config,
		},
		{
			name: t("scarcity"),
			getter: (e: any) =>
				e?.minCSL?.value
					? `${(e?.minCSL?.value * 100).toFixed(0)} %`
					: "",

			config: (e: any) => e?.minCSL?.config,
		},
		{
			name: t("rupture"),
			getter: (e: any) =>
				e?.csl?.value
					? `${((1 - e?.csl?.value) * 100).toFixed(0)} %`
					: "",

			config: (e: any) => e?.csl?.config,
		},
		{
			name: t("maxRuptureChance"),
			getter: (e: any) =>
				e?.maxCSL?.value
					? `${((1 - e?.maxCSL?.value) * 100).toFixed(0)} %`
					: "",

			config: (e: any) => e?.maxCSL?.config,
		},
		{
			name: t("minRuptureChance"),
			getter: (e: any) =>
				e?.minCSL?.value
					? `${((1 - e?.minCSL?.value) * 100).toFixed(0)} %`
					: "",

			config: (e: any) => e?.minCSL?.config,
		},
		{
			name: t("forecast"),
			getter: (e: any) => e?.forecastAdjustment?.value,
			config: (e: any) => e?.forecastAdjustment?.config,
		},
		{
			name: t("limitForVirtualStock"),
			getter: (e: any) =>
				e?.virtualStock?.value
					? `${((e?.virtualStock?.value) * 100).toFixed(0)} %`
					: "",

			config: (e: any) => e?.virtualStock?.config,
		},
		{
			name: t("periodForVirtualStock"),
			getter: (e: any) => e?.virtualStockPeriod?.value,
			config: (e: any) => e?.virtualStockPeriod?.config,
		},
		{
			name: t("capacity"),
			getter: (e: any) => e?.capacity?.value,
			config: (e: any) => e?.capacity?.config,
		},
		{
			name: t("cost"),
			getter: (e: any) => e?.cost?.value,
			config: (e: any) => e?.cost?.config,
		},
		{
			name: t("emergencyInventory"),
			getter: (e: any) => e?.emergencyInventory?.value,
			config: (e: any) => e?.emergencyInventory?.config,
		},
		{
			name: t("leadTime"),
			getter: (e: any) => e?.leadTime?.value,
			config: (e: any) => e?.leadTime?.config,
		},
		{
			name: t("transferenceMode"),
			getter: (e: any) => {
				const v = e?.transferenceMode?.value;
				if (v === 1) return t("notPerform");
				if (v === 2) return t("perform");
				if (v === 3) return t("notDefined");
			},
			config: (e: any) => e?.transferenceMode?.config,
		},
		{
			name: t("emergencialLeadTime"),
			getter: (e: any) => e?.emergencialLeadTime?.value,
			config: (e: any) => e?.emergencialLeadTime?.config,
		},
		{
			name: t("emergencialCoverageMode"),
			getter: (e: any) => {
				const v = e?.emergencialCoverageMode?.value;
				if (v === 1) return t("UntilNextCycleCoverage");
				if (v === 2) return t("UntilNextCycleDelivery");
				if (v === 3) return t("notDefined");

			},
			config: (e: any) => e?.emergencialCoverageMode?.config,
		},
		{
			name: t("recommendationMode"),
			getter: (e: any) => {
				const v = e?.recommendationMode?.value;
				if (v === 1) return t("notDefined");
				if (v === 2) return t("onDemand");
				if (v === 3) return t("Scheduled");
				if (v === 4) return t("never");

			},
			config: (e: any) => e?.recommendationMode?.config,
		},

		{
			name: t("lotSize"),
			getter: (e: any) => e?.lotSize?.value,
			config: (e: any) => e?.lotSize?.config,
		},
		{
			name: t("max"),
			getter: (e: any) => e?.max?.value,
			config: (e: any) => e?.max?.config,
		},
		{
			name: t("min"),
			getter: (e: any) => e?.min?.value,
			config: (e: any) => e?.min?.config,
		},
		{
			name: t("moq"),
			getter: (e: any) => e?.moq?.value,
			config: (e: any) => e?.moq?.config,
		},
		{
			name: t("penalty"),
			getter: (e: any) => e?.penalty?.value,
			config: (e: any) => e?.penalty?.config,
		},
		{
			name: t("price"),
			getter: (e: any) => e?.price?.value,
			config: (e: any) => e?.price?.config,
		},
		{
			name: t("profit"),
			getter: (e: any) => e?.profit?.value,
			config: (e: any) => e?.profit?.config,
		},
		{
			name: t("reviewPeriod"),
			getter: (e: any) => {
				if (e?.reviewPeriodExpression?.value)
					return translateCronExpression(
						e?.reviewPeriodExpression?.value
					);
				if (e?.reviewPeriodNumeric?.value)
					return `${t("each")} ${e?.reviewPeriodNumeric.value} ${t("days")}`

			},
			config: (e: any) => {
				if (e?.reviewPeriodNumeric?.config)
					return e?.reviewPeriodNumeric?.config;
				if (e?.reviewPeriodExpression?.config)
					return e?.reviewPeriodExpression?.config;
			},
		},
		{
			name: t("salvage"),
			getter: (e: any) => e?.salvage?.value,
			config: (e: any) => e?.salvage?.config,
		},
		{
			name: t("sourcing"),
			getter: (e: any) => {
				const v = e?.sourcing?.value?.item1;
				if (v === 1) return t("supplier");
				if (v === 2) return t("production");
				if (v === 3) return t("distribution");
			},
			config: (e: any) => e?.sourcing?.config,
		},
		{
			name: t("volume"),
			getter: (e: any) => e?.volume?.value,
			config: (e: any) => e?.volume?.config,
		},
		{
			name: t("weight"),
			getter: (e: any) => e?.weight?.value,
			config: (e: any) => e?.weight?.config,
		},
	];

	return (
		<>
			<SBBox display="flex" mx={2} alignItems="center">
				{inventory && <SBInventoryTitle item={inventory} hideStatistics={!isValidConfig} />}
				<SBBox ml={2}>
					{errors && errors.length > 0 && (
						<SBTypography color="error" fontWeight="500" mx="auto">
							{errors.length} errors found!
						</SBTypography>
					)}
				</SBBox>
			</SBBox>
			<SBTable size="small" className={classes.sbConfigFinderTable}>
				<TableHead>
					<TableRow>
						<TableCell>
							<SBTypography py={1} fontWeight="700">
								{t("property")}
							</SBTypography>
						</TableCell>
						<TableCell>
							<SBTypography py={1} fontWeight="700">
								{t("value")}
							</SBTypography>
						</TableCell>
						<TableCell>
							<SBTypography py={1} fontWeight="700">
								{t("config")}
							</SBTypography>
						</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				{configColumns.map((configColumn) => (
					<TableRow>
						<TableCell width="25%">
							<SBTypography
								textTransform="capitalize"
								color="primary"
								fontWeight="700"
							>
								{configColumn.name}
							</SBTypography>
						</TableCell>
						<TableCell width="30%">
							{configColumn.getter(inventoryConfig)}
						</TableCell>
						<TableCell width="30%">
							<SBTypography
								noWrap
								width="20vw"
								color={
									configColumn.config(inventoryConfig)?.name
										? ""
										: "gray4.main"
								}
							>
								{configColumn.config(inventoryConfig)?.name ||
									t("noConfigFound")}
							</SBTypography>
						</TableCell>
						<TableCell width="5%" align="right">
							<SBBox
								onClick={() =>
									configColumn.config(inventoryConfig)
										?.name &&
									onChooseConfig(
										configColumn.config(inventoryConfig)?.id
									)
								}
								style={{
									cursor: "pointer",

								}}
							>
								{configColumn.config(inventoryConfig) ? (
									<SBIcon icon="settings" size={15} />
								) : null}
							</SBBox>
						</TableCell>
					</TableRow>
				))}
			</SBTable>
			<SBBox ml={2}>
				{errors &&
					errors.length > 0 &&
					errors.map((error: any) => (
						<SBBox mb={1}>
							<Alert severity="error" color="error">
								{error.errorMessage}
							</Alert>
						</SBBox>
					))}
			</SBBox>
		</>
	);
};
