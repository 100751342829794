import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	systemSearchContent: {
		cursor: "pointer",
		minHeight: 59,
		borderBottom: "1px solid #E2DDF2",
		backgroundColor: "white",
		"&.disabled": {
			backgroundColor: "#ccc",
		},
		"&.selected": {
			backgroundColor: "#7F51FF11",
		},
	},
}));
