import { CircularProgress } from "@mui/material";
import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import useFilterCache from "hooks/useFilterCache";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { SBTranslate } from "i18n";
import {
	SBBox,
	SBCard,
	SBGrid,
	SBTooltip,
	SBTypography,
} from "modules/Commons";
import SBWithIndicator from "modules/Commons/SBValueWithIndicator";
import { NotificationsRequester } from "modules/Notifications/services/NotificationsRequester";
import { useEffect, useMemo, useState } from "react";
import { useSystemTheme } from "themes/base";
import { formatInteger } from "utils/formatters";
import SBStrokedGauge from "modules/Commons/SBDataDisplays/SBStrokedGauge";
import { useGlobalInventoriesStatusContext } from "modules/Inventory/components";
import { useSystemFilterContext } from "contexts/SystemFilterContext";

enum filtersTypes {
	Store = "store",
	Category = "category",
	Product = "product"
}

export default ({ statistics }: SBInventoryOptimizationCardProps) => {
	const { getLanguage } = useSystemLanguage();
	const theme = useSystemTheme();
	const { filtersContext } = useSystemFilterContext();
	const { fetchData } = useGlobalInventoriesStatusContext();

	const getTotal = () => statistics.notOptimized + statistics.currentStatus.optimized ?? 0;
	const getUnconfigured = () => statistics.notOptimized;

	const filters = {
		stores: filtersContext?.items?.filter(item => item.type === filtersTypes.Store),
		categories: filtersContext?.items?.filter(item => item.type === filtersTypes.Category),
		products: filtersContext?.items?.filter(item => item.type === filtersTypes.Product)
	}

	const handleOptimizedClick = async () => {
		fetchData(NotificationsRequester.optimizedInventories(filters), {
			title: SBTranslate("commons.optimizedInventories"),
			columnName: SBTranslate("commons.optimized"),
		});
	};

	const optimized = useMemo(() => {
		const handleLabelClick = async () => {
			await handleOptimizedClick();
		};

		return {
			color: theme.palette.primary.main,
			label: SBTranslate("commons.optimized"),
			onClick: handleLabelClick,
			value: statistics?.currentStatus?.optimized ?? 20,
			formattedValue: `(${formatInteger(
				(statistics?.currentStatus?.optimized / getTotal()) * 100
			)}%)`,
		};
	}, [statistics, getLanguage()]);

	const handleNotOptimizedClick = async () => {
		fetchData(NotificationsRequester.notOptimizedInventories(filters), {
			title: SBTranslate("commons.notOptimizedInventories"),
			columnName: SBTranslate("commons.notOptimized"),
		});
	};

	const notOptimized = useMemo(() => {
		const handleLabelClick = async () => {
			await handleNotOptimizedClick();
		};

		return {
			color: theme.palette.gray4.main,
			label: SBTranslate("commons.notOptimized"),
			value: getUnconfigured(),
			onClick: handleLabelClick,
			formattedValue: `(${formatInteger(
				(getUnconfigured() / getTotal()) * 100
			)}%)`,
		};
	}, [statistics, getLanguage()]);


	const totalForecast = (item: number) => ({
		color: theme.palette.primary.main,
		label: SBTranslate("total"),
		hideLabel: true,
		value: statistics?.currentStatus?.inventories - item,
	});

	const invalidForecast = useMemo(
		() => ({
			label: SBTranslate("commons.incomplete"),
			value: `${statistics?.currentStatus?.invalidForecasts}/${totalForecast(0).value
				}`,
		}),
		[statistics, getLanguage()]
	);

	const highError = useMemo(
		() => ({
			label: SBTranslate("highError"),
			value: `${statistics?.currentStatus?.highError}/${totalForecast(0).value
				}`,
		}),
		[statistics, getLanguage()]
	);

	const highBias = useMemo(
		() => ({
			label: SBTranslate("biased"),
			value: `${statistics?.currentStatus?.highBias}/${totalForecast(0).value
				}`,
		}),
		[statistics, getLanguage()]
	);

	return (
		<SBCard style={{ height: "100%" }}>

			<SBBox
				height={1}
				p={2}
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
			>
				<SBTooltip
					placement="top"
					title={SBTranslate("tips.optimization")}
				>
					<SBTypography
						fontWeight="700"
						color="gray2.main"
					>
						{SBTranslate("commons.optimizationStatus")}
					</SBTypography>
				</SBTooltip>
				<SBStrokedGauge size={30} data={optimized} getTotal={getTotal} />
				<SBBox
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
				>
					<SBWithIndicator
						value={optimized}
						onClick={handleOptimizedClick}
						formatter={formatInteger}
					/>
					<SBWithIndicator
						value={notOptimized}
						onClick={handleNotOptimizedClick}
						formatter={formatInteger}
					/>
					<SBGrid container pt={2} pb={1} my="auto">
						{[invalidForecast, highError, highBias].map((c) => {
							return (
								<SBGrid item xs={4}>
									<SBTypography
										textAlign="center"
										fontWeight="bold"
										textTransform="uppercase"
										color="gray4.main"
										fontSize="0.65rem"
										noWrap
									>
										{c.label}
									</SBTypography>
									<SBTypography textAlign="center">
										{c.value}
									</SBTypography>
								</SBGrid>
							);
						})}
					</SBGrid>
				</SBBox>
			</SBBox>
		</SBCard>
	);
};

interface SBInventoryOptimizationCardProps {
	statistics: IHomeStatistics;
}
