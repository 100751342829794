import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	sbIconDateBox: {
		height: "100%",
		border: "1px solid #E2DDF2",
		backgroundColor: "white",
		borderRadius: 0,
		borderTopLeftRadius: 4,
		borderBottomLeftRadius: 4,
	},
	sbDateBox: {
		border: "1px solid #E2DDF2",
		borderLeft: "none",
		backgroundColor: "white",
		minWidth: 95,
		padding: "6px 10px",
	},
	sbLeftDateBox: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
	},
	sbRightDateBox: {
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
}));
