import { flexRender, HeaderGroup } from "@tanstack/react-table";
import clsx from "clsx";
import { head, isString } from "lodash";
import { SBIconButton } from "modules/Commons";
import SBIcon from "modules/Commons/SBDataDisplays/SBIcon";
import SBTooltip from "modules/Commons/SBDataDisplays/SBTooltip";
import SBBox from "modules/Commons/SBLayouts/SBBox";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function SBTableHeader<T extends object>(props: SBTableHeaderProps<T>) {
	const { headerGroups, reRenderRefs } = props;
	const { t } = useTranslation();

	const handleSortIcon = (header: any) => {
		const isAsc = (header.column?.getIsSorted() as string) === "asc";
		const isDesc = (header.column?.getIsSorted() as string) === "desc";
		const isNone = !isAsc && !isDesc;

		const currentIcon = isAsc ? "chevron-up" : "chevron-down";
		const currentColor = isNone ? "transparent" : "gray3";

		return (
			<SBIcon
				style={{ marginLeft: 7, paddingBottom: 3 }}
				icon={currentIcon}
				color={currentColor}
				size={14}
			/>
		);
	};

	return useMemo(() => (
		<thead style={{ zIndex: 1 }}>
			{headerGroups.map((headerGroup: any) => (
				<tr key={headerGroup.id}>
					{headerGroup.headers.map((header: any) => {
						const tooltipTitle = t(header.column.columnDef.tip);

						const width = !isNaN(header.getSize())
							? header.getSize()
							: 0;

						const render = flexRender(
							header.column.columnDef.header,
							header.getContext()
						);

						return (
							<th
								key={header.id}
								colSpan={header.colSpan}
								style={{
									width: width,
									minWidth: width,
								}}
							>
								<span style={{ display: header.column.columnDef.enableGrouping ? "flex" : "inline", alignItems: "center" }}>
									{header.column.columnDef.enableGrouping ? (
										<SBTooltip
											placement="top"
											title={header.column.columnDef.groupByTip}
											onClick={header.column.getToggleGroupingHandler()}
											sx={{ p: "8px 0 8px 14px !important" }}
										>
											<SBIcon
												size={18}
												icon="grid"
												color={header.column.getIsGrouped() ? "primary" : "icongray.main"}
											/>
										</SBTooltip>
									) : null
									}
									<span className={clsx(
										header.column.getCanSort() &&
										"cursor-pointer select-none"
									)}
										onClick={header.column.getToggleSortingHandler()}
									>
										{tooltipTitle || header.column.getCanSort() ? <SBTooltip
											title={clsx(tooltipTitle, header.column.getCanSort() && `(${t('clickToChangeSort')})`)}
											placement="top"
										>
											<span>
												{isString(render)
													? t(render)
													: render}
												{handleSortIcon(header)}
											</span>
										</SBTooltip>
											: <SBBox
											>
												<span>
													{isString(render)
														? t(render)
														: render}
													{handleSortIcon(header)}
												</span>
											</SBBox>
										}
									</span>
								</span>
							</th>
						);
					})}
				</tr>
			))}
		</thead>
	), reRenderRefs);
}

interface SBTableHeaderProps<T extends object> {
	headerGroups: HeaderGroup<T>[];
	reRenderRefs: any[];
}
