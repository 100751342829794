import { IComposedFilter } from "domain/Utils";
import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import useFilterCache from "hooks/useFilterCache";
import { useFilterToQueryString } from "hooks/useFilterToQueryString/indext";
import routerHistory from "hooks/useRouterHistory";
import { SBCard } from "modules/Commons";
import SBEmptyState from "modules/Commons/SBLayouts/SBEmptyState";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SupplyOptimizationEmptyState from "views/HomeViews/HomeEmptyState/SupplyOptimizationEmptyState";
import SBInventoryAccordion from "../../../modules/InventoryStatistics/components/SBInventoryAccordion";
import SBStatisticSupplyInfo from "../../../modules/InventoryStatistics/components/SBStatisticSupplyInfo";
import { SBTranslate } from "i18n";
import { useDateStorageCache } from "hooks/useDateStorageCache";
import { DateTime } from "luxon";
import { useSystemFilterContext } from "contexts/SystemFilterContext";

export default ({
	statistics,
	filters,
}: SBSupplyOptimizationAccordionProps) => {
	const { t } = useTranslation();
	const { fetchQueryString } = useFilterToQueryString();
	const { filtersContext } = useSystemFilterContext();
	const { getDateItem } = useDateStorageCache();
	const [transferenceRoute, setTransferenceRoute] = useState("");
	const [purchaseRoute, setPurchaseRoute] = useState("");

	const onBuyURL = async (isEmergencial?: boolean) => {
		const route = `/purchase/recomendation${fetchQueryString({
			...filtersContext,
			context: DateTime.fromISO(getDateItem()),
			emergencial: isEmergencial,
		})}`;
		setPurchaseRoute(route);
	};

	const onTransferURL = async () => {
		const route = `/transference${fetchQueryString({
			...filtersContext,
			context: DateTime.fromISO(getDateItem())
		})}`;
		setTransferenceRoute(route);
	};

	const handleBuy = async () => routerHistory.push(purchaseRoute);

	const handleTransfer = async () => routerHistory.push(transferenceRoute);

	useEffect(() => {
		onBuyURL();
		onTransferURL();
	}, [filters]);

	const hasRecomended =
		statistics?.orders?.recommendedPurchase?.quantity > 0;

	const hasEmergencial =
		statistics?.orders?.emergencialPurchase?.quantity > 0;

	const hasBothPurchaseType = hasRecomended && hasEmergencial;

	const hasPurchase =
		statistics?.orders?.emergencialPurchase?.quantity > 0 ||
		statistics?.orders?.recommendedPurchase?.quantity > 0;

	const hasTransference =
		statistics?.transferences.transferences?.quantity > 0;

	return (
		<SBCard style={{ height: "100%" }}>
			<SBEmptyState
				show={hasTransference || hasPurchase}
				emptyState={<SupplyOptimizationEmptyState label="" />}
			>
				<SBInventoryAccordion
					items={[
						{
							label: t("purchase"),
							icon: "shopping-cart",
							color: "#2F80ED",
							showItem: hasPurchase,
							isEmergencial: hasEmergencial,
							content: (
								<SBStatisticSupplyInfo
									hasMultiple={hasBothPurchaseType}
									actionLabel={SBTranslate("views.home.buy")}
									recommended={
										statistics?.orders
											?.recommendedPurchase ?? {}
									}
									emergencial={
										statistics?.orders
											?.emergencialPurchase ?? {}
									}
									onChange={onBuyURL}
									onClick={handleBuy}
									route={purchaseRoute}
									color="#2F80ED"
								/>
							),
						},
						{
							label: t("transference"),
							color: "#F2994A",
							icon: "send",
							showItem: hasTransference,
							content: (
								<SBStatisticSupplyInfo
									isTransfer
									actionLabel={SBTranslate("views.home.transfer")}
									recommended={
										statistics?.transferences
											?.transferences ?? {}
									}
									onClick={handleTransfer}
									route={transferenceRoute}
									color="#F2994A"
								/>
							),
						},
					]}
				/>
			</SBEmptyState>
		</SBCard>
	);
};
interface SBSupplyOptimizationAccordionProps {
	statistics: IHomeStatistics;
	filters: IComposedFilter;
}
