import { Slide } from "@mui/material";
import { useRef } from "react";
import { useStyles } from "./style";

function SBSlideMenu(props) {
	const classes = useStyles();
	const slideMenuRef = useRef();

	return (
		<Slide
			ref={slideMenuRef}
			timeout={300}
			direction="left"
			in={props.open}
			mountOnEnter
			unmountOnExit
		>
			<div className={classes.slideOutsideContainer}>
				{props.children}
			</div>
		</Slide>
	);
}

export default SBSlideMenu;
