import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	sbTableHeaderCell: {
		textTraensform: "upprcase",
		color: "#828282",
		sbTableHeaderName: {
			fontSize: 14,
			fontWeight: "bold",
		},
	},
}));
