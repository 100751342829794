import { IInventory } from "domain/Inventory";
import { ReactNode, useContext, useEffect, useState } from "react";
import SBInventoryPositionModal from "views/Commons/SBInventoryPositionModal";
import {
	InventoryStatusConfig,
	InventoryStatusContext,
	InventoryStatusContextProps
} from "./context";
import { useLocation } from "react-router";

export const SBGlobalInventoryiesStatusProvider = (
	props: SBGlobalInventoryStatusProviderProps
) => {
	const { children } = props;
	const { pathname } = useLocation();
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [configs, setConfigs] = useState({} as InventoryStatusConfig);
	const [statistics, setStatistics] = useState<IInventory[]>([]);

	const value = {
		fetchData: async (d, c) => {
			setLoading(true);
			setIsOpen(true);
			setConfigs(c);
			const result = await d;
			setStatistics(result);
			setLoading(false);
		},
	} as InventoryStatusContextProps;

	useEffect(() => {
		setIsOpen(false);
	}, [pathname]);

	return (
		<InventoryStatusContext.Provider value={value}>
			{children}
			<SBInventoryPositionModal
				inventories={statistics}
				isMenuOpen={isOpen}
				loading={loading}
				closeMenu={() => setIsOpen(false)}
				title={configs.title}
				itemName={configs.columnName}
			/>
		</InventoryStatusContext.Provider>
	);
};

export const useGlobalInventoriesStatusContext = () =>
	useContext(InventoryStatusContext);

interface SBGlobalInventoryStatusProviderProps {
	children: ReactNode;
}
