import { IInventoryStatisticsSummary } from "domain/Inventory/Models/IInventoryStatisticsSummary";
import {
	SBBox,
	SBLoading,
	SBOutlinedButton,
	SBSlideMenu,
} from "modules/Commons";
import SBInventoryStatisticsInfo from "../../../../views/Commons/SBInventoryStatisticsInfo";
import SBInventoryTitle from "../SBInventoryTitle";

export default (props: SBInventoryInfoModalProps) => {
	const { statistics, closeMenu, isMenuOpen, loading } = props;
	return (
		<SBSlideMenu open={isMenuOpen}>
			<SBLoading loading={loading} limited>
				<SBBox
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					p={2}
				>
					<SBInventoryTitle
						isRow
						hideStatistics
						item={statistics.inventory}
					/>
					<SBBox ml={2} height={1}>
						<SBOutlinedButton onClick={closeMenu}>
							Fechar
						</SBOutlinedButton>
					</SBBox>
				</SBBox>
				<SBInventoryStatisticsInfo statistics={statistics} />
			</SBLoading>
		</SBSlideMenu>
	);
};

interface SBInventoryInfoModalProps {
	/** Show if modal is open or not */
	isMenuOpen: boolean;
	/** Indicates loading on the data fetch */
	loading: boolean;
	/** Object to fill the modal info */
	statistics: IInventoryStatisticsSummary;
	/** Func to close modal */
	closeMenu: () => void;
}
