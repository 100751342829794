import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	sbSelect: {
		//"& fieldset": {
		//	border: "1px solid #E2DDF2",
		//	color: "black",
		//	marginLeft: -1,
		//	marginRight: 1,
		//},
	},
}));
