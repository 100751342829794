import { Grid } from "@mui/material";
import clsx from "clsx";
import { SBBox } from "modules/Commons";
import { ISimulation } from "modules/Simulations/models/ISimulation";
import { ISimulationRow } from "modules/Simulations/models/ISimulationRow";
import { SimulationPeriod } from "modules/Simulations/models/simulationPeriod";
import { useState } from "react";
import { SBInventoryTitle } from "modules/Inventory/components";
import SimulationCardActions from "../SimulationCardActions";
import SimulationCardCost from "../SimulationCardCost";
import SimulationCardCSLIndicator from "../SimulationCardCSLIndicator";
import SimulationCardSummarization from "../SimulationCardSummarization";
import SimulationCardChart from "../SimulationChart";
import { useStyles } from "./style";

export default ({
	item,
	chartID,
	chartFilter,
	selectedChart,
	chartsToShow,
	cardIndex,
	ignoreDateConstraint,
	simulationPeriod,
}: SimulationCardProps) => {
	const classes = useStyles();
	const { result, summarization } = item;
	const [showSummarization, setShowSummarization] = useState(false);

	const toggleSummarization = () => setShowSummarization((s) => !s);
	return (
		<SBBox className={clsx(classes.simulationCard)}>
			<Grid container columns={12}>
				<Grid item xs={3} className={"container"}>
					<SBInventoryTitle isRow item={result.inventory} />
					<SimulationCardCSLIndicator simulationRow={result} />
					<SimulationCardCost simulationRow={item} />
					<SimulationCardActions onClick={toggleSummarization} />
				</Grid>
				<Grid item xs={9} pr={2}>
					<SimulationCardChart
						chartID={chartID}
						chartFilter={chartFilter}
						dataArray={{ result, summarization }}
						selectedChart={selectedChart}
						realChart={chartsToShow[0]}
						projectedChart={chartsToShow[1]}
						cardIndex={cardIndex}
						ignoreDateConstraint={ignoreDateConstraint}
						simulationPeriod={simulationPeriod}
					/>
				</Grid>
			</Grid>
			<SBBox>
				{showSummarization && (
					<SimulationCardSummarization
						item={summarization}
						pointAsTable={ignoreDateConstraint}
						simulationPeriod={simulationPeriod}
						chartsToShow={chartsToShow}
					/>
				)}
			</SBBox>
		</SBBox>
	);
};

interface SimulationCardProps {
	item: ISimulation & { summarization: ISimulationRow };
	chartaccessor: string;
	chartFilter: any;
	selectedChart: any;
	chartsToShow: any;
	cardIndex: number;
	chartID: number;
	ignoreDateConstraint: boolean;
	simulationPeriod: SimulationPeriod;
}
