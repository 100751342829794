import {
	SBBox,
	SBTooltip,
	SBTypography
} from "modules/Commons";

export default (props: SBForecastEvaluationSectionProps) => {
	const { stat } = props;
	const { tooltip, ...item } = stat;
	return (

		<SBBox
			display="flex"
			flexDirection="column"
			alignItems="start"
			width={1}
			px={3}
			py={1}
		>
			<SBBox display="flex" justifyContent="center">
				<SBTooltip title={tooltip} placement="top">
					<SBTypography
						fontWeight="500"
						variant="body2"
					>
						{item?.label}
					</SBTypography>
				</SBTooltip>
			</SBBox>
			<SBTypography fontWeight="700" variant="h6" color="gray2.main">
				{item?.formatter
					? item.formatter(item?.value)
					: item?.value}
			</SBTypography>
			<SBTypography fontWeight="600" variant="caption">
				{item?.range}
			</SBTypography>
		</SBBox>
	);
};

interface SBForecastEvaluationSectionProps {
	stat: {
		tooltip: string;
		label: string;
		value: any;
		range: string;
		formatter: (v?: number | undefined) => string;
	}
}
