import React, { useMemo, useState } from "react";

export default function useArrayState<T>(initialArray: T[] = []) {
	const [array, setArray] = useState(initialArray);

	const count = useMemo(() => array.length, [array]);
	const hasAny = useMemo(() => array.length > 0, [array]);

	return [array, setArray, { count, hasAny }] as [
		a: T[],
		b: React.Dispatch<React.SetStateAction<T[]>>,
		c: { count: number; hasAny: boolean }
	];
}
