import { SBTableColumn } from "modules/Commons/SBDataDisplays/SBTable/models/TableColumn";

export default ({
	columns,
	showGroups,
	collapseFieldName,
}: {
	columns: SBTableColumn<any>[];
	showGroups: boolean;
	collapseFieldName?: string;
}) => {
	const exportCSV = (
		items: any[],
		selectedItems: any[],
		language: string | undefined = undefined
	) => {
		const usingLanguage = language ?? navigator.language;
		const separator = usingLanguage === "en-US" ? "," : ";";

		let csvHeader = showGroups
			? columns.flatMap((c: any) => c.headers)
			: columns;

		csvHeader = csvHeader.filter((header: any) => !header.csvHide);

		const csvChildHeaders = csvHeader.reduce(
			(a: [], b: SBTableColumn<any>) => {
				if (b.child) return [...a, b.child];
				return [...a, b];
			},
			[]
		);

		const csvBody = items
			.map((item: any) => {
				const firstHeader = csvHeader
					.map((header: SBTableColumn<any>) => {
						var headerToUse = !!header.csvValue
							? header.csvValue
							: header.id;

						const value = headerToUse(item);

						return !!value
							? `"${value.toLocaleString(language)}"`
							: "";
					})
					.join(separator);

				const childs = collapseFieldName
					? item[collapseFieldName]
							.filter(
								(item: any) =>
									selectedItems.length == 0 ||
									selectedItems.indexOf(item.uuid) >= 0
							)
							.map((childItem: any) => {
								return csvChildHeaders
									.map((header: any) => {
										var headerToUse = !!header.csvValue
											? header.csvValue
											: header.id;

										const value = headerToUse(childItem);

										return !!value
											? `"${value.toLocaleString(
													navigator.language
											  )}"`
											: "";
									})
									.join(separator);
							})
					: [];

				const linesToReturn = [];

				if (
					selectedItems.length > 0 &&
					selectedItems.indexOf(item.uuid) >= 0
				) {
					linesToReturn.push(firstHeader);
				} else if (!collapseFieldName && selectedItems.length == 0) {
					linesToReturn.push(firstHeader);
				}

				return [...linesToReturn, ...childs];
			})
			.flatMap((c) => c)
			.join("\n");

		const csv =
			csvHeader.map((c: any) => c.csvName || c.name).join(separator) +
			"\n" +
			csvBody;
		return csv;
	};

	return {
		exportCSV,
	};
};
