import { AxiosResponse } from "axios";
import { IConfigCSVErrors } from "domain/Config/Models/IConfigCSVErrors";
import { useRequest } from "hooks/useRequest";
import i18n from "i18n";

export const GroupConfigRequester = ((value) => {
	const request = useRequest("GroupConfigs");
	return {
		importCSV: (value: string, lang: string) => {
			const formData = new FormData();
			formData.append("file", value);

			return request.POST(`validateCSV/${lang}`, formData).then(
				(
					res: AxiosResponse<{
						item1: string;
						item2: IConfigCSVErrors[];
					}>
				) => {
					return {
						guid: res.data.item1,
						errors: res.data.item2,
					};
				}
			);
		},
		saveCSV: (value: string) => {
			return request
				.POST(`saveCSV/${value}`, {})
				.then((res: AxiosResponse<IConfigCSVErrors[]>) => {
					return res.data;
				});
		},
		exportCSV: (value: string) => {
			return request.GET(`exportCSV/${i18n.language}`);
		},
	};
})();
