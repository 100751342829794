import { SBBox, SBIcon, SBIconButton, SBSlideMenu, SBTypography } from "modules/Commons";
import { IPurchasePlan } from "modules/Purchases/models/IPurchasePlans";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import SBPurchaseReport from "../SBPurchaseReport";
import { SlideMenuContent } from "./style";
import { SBTranslate } from "i18n";
import { useLocation } from "react-router";
import { useSystemLanguage } from "hooks/useSystemLanguage";

export default ({ children }: SBPurchaseReportModalProps) => {

	const [open, setOpen] = useState(false);
	const [plan, setPlan] = useState({} as IPurchasePlan);
	const { pathname } = useLocation();
	const { getLanguage } = useSystemLanguage();


	const handleOpen = (plan: IPurchasePlan) => {
		setOpen(true);
		setPlan(plan);
	}

	const handleClose = () => {
		setOpen(false);
	}

	useEffect(() => {
		setOpen(false);
	}, [pathname]);

	return (
		<SBPurchaseReportModalContext.Provider value={{ open, handleOpen }}>
			{children}
			<SBSlideMenu open={open}>
				<SlideMenuContent>
					<SBBox p={2} alignItems="center" height={1} >
						<SBBox display="flex" justifyContent="space-between" mb={2}>
							<SBTypography fontWeight="bold" fontSize="1.5rem" key={getLanguage()}>
								{SBTranslate("description")}
							</SBTypography>
							<SBIconButton onClick={handleClose}>
								<SBIcon icon="x" />
							</SBIconButton>
						</SBBox>
						<SBPurchaseReport plan={plan} />
					</SBBox>
				</SlideMenuContent>
			</SBSlideMenu>
		</SBPurchaseReportModalContext.Provider>
	)
}


const SBPurchaseReportModalContext = createContext(
	{} as {
		open: boolean;
		handleOpen: (plan: IPurchasePlan) => void
	}
);

export const usePurchaseReportModal = () => useContext(SBPurchaseReportModalContext);

interface SBPurchaseReportModalProps {
	children: ReactNode;
}
