import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	simulationCard: {
		boxShadow: `0px 2px 16px rgba(0, 0, 0, 0.08)`,
		margin: "10px 10px",
		padding: "10px 0px",

		"& .container": {
			display: "flex",
			padding: 10,
			flexDirection: "column",
			justifyContent: "space-between",
			borderRight: "1px solid #ddd",
		},
	},
}));
