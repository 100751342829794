export const useClientIdentificator = () => {
	const clientEnvironment =
		process.env.NODE_ENV === "development"
			? process.env.REACT_APP_KEY!
			: location.host.split(".")[0];

	return {
		client: clientEnvironment.split("-")[0],
		environment: clientEnvironment.split("-")[1] ?? "",
		clientEnvironment,
	};
};
