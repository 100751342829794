import { IInventoryStatisticsSummary } from "domain/Inventory/Models/IInventoryStatisticsSummary";
import { SBBox, SBGrid, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";
import SBInventoryStatisticsForecast from "../SBInventoryStatisticsForecast";
import SBInventoryStatisticsSection from "../SBInventoryStatisticsSection";
import SBInventoryStatisticsWarnings from "../SBInventoryStatisticsWarnings";
import { useInventoryStatisticHeaders } from "./inventoryStatisticsHeaders";
import { SBNewTable } from "modules/Commons/SBDataDisplays/SBTable/SBNewTable";
import { useOrdersColumnBuilder } from "hooks/useOrdersColumnBuilder";
import { Cell } from "@tanstack/react-table";
import { SBTranslate } from "i18n";
import { useWindowSize } from "hooks/useWindowResize";

export default ({ statistics }: SBInventoryStatisticsInfoProps) => {
	const { t } = useTranslation();
	const { sections, modelSection } = useInventoryStatisticHeaders();

	const { headers: orderTableHeaders } = useOrdersColumnBuilder();

	const size = useWindowSize();

	const calculateDynamicHeight = () => {
		let minimumHeight = size[1] - 75;
		let rowHeight = 36;
		var totalHeigh = minimumHeight - (statistics?.orders.length * rowHeight);
		return totalHeigh;
	}

	return (
		<SBBox px={3}>
			<SBInventoryStatisticsWarnings statistics={statistics} />
			<SBInventoryStatisticsForecast statistics={statistics} />

			{sections?.map((section, index) => {
				return (
					<SBBox
						key={section.name}
						display="flex"
						flexDirection="column"
						my={2}
					>
						<SBTypography fontWeight="bold" variant="h6" pb={2}>
							{section.name}
						</SBTypography>
						<SBGrid container spacing={2}>
							{section?.headers?.map((prop) => (
								<SBInventoryStatisticsSection
									statistics={statistics}
									section={prop}
								/>
							))}
						</SBGrid>
					</SBBox>
				);
			})}
			<SBBox marginBottom={5}>
				<SBNewTable
					containerHeight={calculateDynamicHeight()}
					startRows={statistics?.orders}
					defaultColumns={orderTableHeaders}
					title={SBTranslate("onOrder")}
					onClick={() => { }}
					fullWidth
				/>
			</SBBox>
		</SBBox>
	);
};

interface SBInventoryStatisticsInfoProps {
	statistics: IInventoryStatisticsSummary;
}
