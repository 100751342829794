import { useEffect } from "react";

export default (props: useMousePositionProps) => {
	const { onMouseMove } = props;

	useEffect(() => {
		window.addEventListener("mousemove", onMouseMove);
		return () => {
			window.removeEventListener("mousemove", onMouseMove);
		};
	}, [onMouseMove]);
};

export interface useMousePositionProps {
	onMouseMove: (event: MouseEvent) => void;
}
