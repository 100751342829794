import { Stack } from "@mui/material";
import { TimePeriod } from "domain/DatePickerConfig/domain/enums/TimePeriod";
import { SBTranslate } from "i18n";
import { t } from "i18next";
import { SBChip } from "modules/Commons";

export default ({ timePeriod, setTimePeriod, onUpdate }: SBForecastPeriodFilterProps) => {
	const isDaily = timePeriod == TimePeriod.DailyUTC;

	return (
		<Stack direction="row" spacing={1} mr={4}>
			<SBChip
				label={SBTranslate("daily")}
				variant={isDaily ? "filled" : "outlined"}
				color="primary"
				onClick={() => {
					onUpdate(TimePeriod.DailyUTC);
					setTimePeriod(TimePeriod.DailyUTC);
				}}
			/>
			<SBChip
				label={SBTranslate("monthly")}
				variant={!isDaily ? "filled" : "outlined"}
				color="primary"
				onClick={() => {
					onUpdate(TimePeriod.MonthlyUTC);
					setTimePeriod(TimePeriod.MonthlyUTC);
				}}
			/>
		</Stack>
	);
};

interface SBForecastPeriodFilterProps {
	timePeriod: TimePeriod;
	onUpdate: (grouping: TimePeriod) => void;
	setTimePeriod: (grouping: TimePeriod) => void;
}