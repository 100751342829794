import { Collapse, ListItem } from "@mui/material";
import { TransferenceResponse } from "modules/Transferences/models/transferenceResponse";
import { useRef } from "react";
import { CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import SBFailedTransfersWarning from "views/Commons/SBFailedTransfersWarning";
import TransferenceInfoItem from "../TransferenceInfoItem";

export default ({
	failedTransferences,
	maximized,
}: FailedTransferencesInfoProps) => {
	const cache = useRef(
		new CellMeasurerCache({ fixedWidth: true, defaultHeight: 110 })
	);

	return (
		<Collapse in={maximized}>
			<SBFailedTransfersWarning />
			<List
				width={324}
				height={310}
				rowHeight={cache.current.rowHeight}
				deferredMeasurementCache={cache.current}
				rowCount={failedTransferences.length}
				rowRenderer={({ key, index, style, parent }) => {
					return (
						<CellMeasurer
							key={key}
							cache={cache.current}
							parent={parent}
							columnIndex={0}
							rowIndex={index}
						>
							<ListItem
								style={style}
								component="div"
								disablePadding
							>
								<TransferenceInfoItem
									transference={failedTransferences[index]}
									isLastItem={
										index === failedTransferences.length - 1
									}
								/>
							</ListItem>
						</CellMeasurer>
					);
				}}
			/>
		</Collapse>
	);
};

interface FailedTransferencesInfoProps {
	failedTransferences: TransferenceResponse[];
	maximized: boolean;
}
