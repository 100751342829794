import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	tableTopbarTitle: {
		display: "flex",
		alignItems: "center",
		padding: "0 30px",
		borderRight: "1px solid " + theme.palette?.gray5?.main,
		fontWeight: "bold",
		height: "64px",
		"& *": {
			padding: 0,
		},
	},
}));
