import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
	sbConfigFinderTable: {
		zIndex: 1000,
		borderRadius: "8px !important",
		marginBottom: "10px",

		"& .MuiTableCell-root": {
			borderBottom: "0.5px solid #E2DDF299;",
		},

		"& .MuiTableRow-root": {
			"&:nth-child(even)": {
				backgroundColor: theme.palette.lightPurple.main + "99",
			},

			"&.active": {
				backgroundColor: theme.palette.lightPurple.main,
				"& > *": {
					fontWeight: 700,
					"&.column": {
						color: theme.palette.primary.main,
					},
				},
			},

			"& > *": {
				"&.real": {
					color: theme.palette.success.main,
				},
				"&.projected": {
					color: theme.palette.blue2.main,
				},
			},
		},
	},
}));
