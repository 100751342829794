import PubSub from "./PubSub";

export default (props: NotificationPubSub) => {
	const { onConnected = () => null, onReceiveNotification = () => null } =
		props;

	var { send } = PubSub({
		hub: "notification",
		subs: [
			{ method: "onConnected", on: onConnected },
			{ method: "onReceiveNotification", on: onReceiveNotification },
		],
	});

	const handleSeen = (...params: any[]) => {
		send("onSeenNotifications", params);
	};

	return {
		handleSeen,
	};
};

interface NotificationPubSub {
	onConnected?: (...params: any[]) => any;
	onReceiveNotification?: (...params: any[]) => void;
}
