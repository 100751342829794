import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	onLoadingButton: {
		"&.MuiButton-root": {
			backgroundColor: `${theme.palette.gray4.main}`,
			"&:hover": {
				backgroundColor: `${theme.palette.gray4.main}`,
			},
		},
	},
}));
