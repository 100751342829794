import { SBBox } from "modules/Commons";
import { SBDatePickerConfigYears } from "./SBDatePickerConfigYears";
import { SBDatePickerConfigRange } from "./SBDatePickerConfigRange";
import { SBDatePickerConfigApply } from "./SBDatePickerConfigApply";

export const SBDatePickerConfig = ({
	fetchPropError,
	disableYearsInput,
}: any) => {
	return (
		<SBBox px={1} py={1}>
			<SBDatePickerConfigYears
				fetchPropError={fetchPropError}
				disableYearsInput={disableYearsInput}
			/>
			<SBDatePickerConfigRange />
			<SBDatePickerConfigApply />
		</SBBox>
	);
};
