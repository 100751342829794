export enum DemandPlanStatus {
	Planned = 1,
	RestrictedByMaxInventory = 2,
	RaisedByMinInventory = 4,
	RaisedByMOQ = 8,
	RestrictedByMOQ = 16,
	RestrictedByLotSize = 32,
	RaisedByLotSize = 64,
	RestrictedByCapacity = 128,
	RestrictedByScarcityOnDistribution = 256,
}
