import TranslateIcon from "@mui/icons-material/Translate";
import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box } from "@mui/system";
import SupplyBrainLogo from "assets/logo.svg";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import SBTooltip from "modules/Commons/SBDataDisplays/SBTooltip";
import SBIconButton from "modules/Commons/SBForms/SBIconButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import SBBox from "../SBBox";
import { useStyles } from "./style";
import SBNotification from "views/Commons/SBNotification";

export default ({ children }: any) => {
	const classes = useStyles();

	const { t, i18n } = useTranslation();
	const { changeLanguage } = useSystemLanguage();
	const history = useHistory();

	return (
		// @ts-ignore
		<AppBar color="white" position="fixed" className={classes.topbar}>
			<Toolbar>
				<Grid
					container
					alignItems="center"
					style={{ position: "relative" }}
				>
					<Grid item xs={3}>
						<img
							src={SupplyBrainLogo}
							alt="logo"
							onClick={() => history.push("/home")}
							className={classes.logo}
						/>
					</Grid>
					<Grid
						item
						xs={6}
						alignItems="center"
						justifyContent="center"
					>
						{/*{children}*/}
					</Grid>
					<Grid item xs={3}>
						<Box
							ml={"auto"}
							display="flex"
							alignItems="center"
							justifyContent="end"
						>
							<SBNotification />
							<SBTooltip
								title={t("changeLanguage")}
								placement="left"
							>
								<SBIconButton onClick={changeLanguage}>
									<TranslateIcon
										fontSize="small"
										color="primary"
									/>
								</SBIconButton>
							</SBTooltip>
						</Box>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};
