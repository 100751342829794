import clsx from "clsx";
import { SBBox, SBGrid, SBSquaredIcon, SBTypography } from "modules/Commons";
import { useStyles } from "./style";

export default ({ items, withShadow }: SBSummarizationProps) => {
	const classes = useStyles();

	return (
		<SBBox className={clsx(classes.mainBox, withShadow && "shadow")}>
			<SBGrid rowSpacing={2} container>
				{items.map((item) => {
					const hasActive =
						item.selectedItems != undefined &&
						item.selectedItems != null;

					return (
						<SBGrid
							key={item.name + item.value}
							item
							xs={6}
							lg={12 / items.length}
							minWidth={240}
							display="flex"
							justifyContent="start"
						>
							<SBSquaredIcon
								color={item.color}
								fillColor={item.fillColor}
								icon={item.icon}
								title={<SBTypography>{item.name}</SBTypography>}
								tip={item.tip}
								valueTip={item.selectedItems ? `${item.selectedItems} / ${item.value}` : `${item.value}`}
								value={item.value}
								append={
									<>
										{hasActive && (
											<span className={clsx(
												classes.bracketsBox,
												hasActive && "active"
											)}>{`${item.selectedItems} / `}</span>
										)}
									</>
								}
							/>
						</SBGrid>
					);
				})}
			</SBGrid>
		</SBBox>
	);
};

interface SBSummarizationProps {
	/** Items to show on summarization */
	items: {
		name: string;
		tip: string;
		value: number | string;
		selectedItems?: number | string;
		color?: string;
		fillColor?: string;
		icon: string;
	}[];
	/** Flag to specify if will use or not the shadow of the component */
	withShadow?: boolean;
}
