import homeVirtualStockIconCard from "assets/homeVirtualStockIconCard.svg";
import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import SBVirtualStockBaseCard from "../SBVirtualStockBaseCard";

export default ({ statistics, onSeeAll }: SBVirtualStockAbnormalCardProps) => {
	return (
		<SBVirtualStockBaseCard
			hasStats
			icon={<img height={104} src={homeVirtualStockIconCard} alt="" />}
			statistics={statistics}
			onSeeAll={onSeeAll}
			title="views.home.virtualStockCardTitle"
			subTitle="views.home.virtualStockCardSubTitle"
			description="views.home.virtualStockCardSubTitle_other"
		/>
	);
};

interface SBVirtualStockAbnormalCardProps {
	statistics: IHomeStatistics;
	hasStats?: boolean;
	onSeeAll?: () => void;
}
