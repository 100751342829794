import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	tableTopbar: {
		position: "relative",
		backgroundColor: theme.palette.white.main,
		padding: "0 !important",
		margin: 0,
		minHeight: 0,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottom: `1px solid ${theme.palette.gray5.main}`,
	},
}));
