import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	mainBox: {
		padding: 16,
		borderRadius: 8,
		backgroundColor: theme.palette.white.main,
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
		"&.shadow": {
			boxShadow: "0px 2px 24px rgb(0 0 0 / 10%)",
		},
	},
	bracketsBox: {
		position: "relative",
		alignItems: "center",

		color: theme.palette.darkPurple.main,
		"& span": { marginRight: 4 },
		// "&.active::after": {
		// 	content: "')'",
		// 	position: "absolute",
		// 	right: 0,
		// },
		// "&.active::before": {
		// 	content: "'('",
		// 	position: "absolute",
		// 	left: 0,
		// },
	},
}));
