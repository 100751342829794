import { number, object } from "yup";

export const datePickerConfigValidator = (props: PropsSchema) => {
	const schema = object().shape({
		startYear: number()
			.positive()
			.integer()
			.required()
			.moreThanOrEqualIfNotNull({
				prop: props.defaultStartYear,
				label: `${props.defaultStartYear}`,
			})
			.lessThanOrEqualIfNotNull({
				prop: "endYear",
				label: "End year",
			}),
		endYear: number()
			.positive()
			.integer()
			.required()
			.moreThanOrEqualIfNotNull({
				prop: "startYear",
				label: "Start year",
			})
			.lessThanOrEqualIfNotNull({
				prop: props.defaultEndYear,
				label: `${props.defaultEndYear}`,
			}),
	});

	const validate = async (obj: YearsSchema) => {
		try {
			await schema.validate(
				{
					startYear: obj.startYear,
					endYear: obj.endYear,
				},
				{
					abortEarly: false,
				}
			);
			return { isValid: true, errors: [] };
		} catch (e: any) {
			return {
				isValid: false,
				errors: e.inner.map((c: any) => ({
					field: c.path,
					message: c.message,
				})),
			};
		}
	};

	return {
		schema,
		validate,
	};
};

interface PropsSchema {
	defaultStartYear: number;
	defaultEndYear: number;
}

interface YearsSchema {
	startYear: number;
	endYear: number;
}
