import { SBTypography } from "modules/Commons";
import { SBBox } from "modules/Commons";
import { Link } from "@mui/material";
import loginInformational from "../../../assets/login-informational.svg";
import { useTranslation } from "react-i18next";

export const LoginInformational = () => {
	const { t } = useTranslation();
	return (
		<SBBox display="flex" flexDirection="column" width={0.9} mx="auto">
			<img src={loginInformational} alt="logistics" />
			<SBTypography fontWeight="700" color="gray2" variant="h4" my={3}>
				{t("informationalTitle")}
			</SBTypography>
			<SBTypography>{t("informationalText")}</SBTypography>
			<SBTypography fontWeight="700" color="gray2" my={3}>
				{t("learnMoreAbout")}
				<Link
					href="https://www.supplybrain.ai/"
					target="_blank"
					rel="noopener"
				>
					Supply Brain!
				</Link>
			</SBTypography>
		</SBBox>
	);
};
