import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import { SBGrid } from "modules/Commons";
import useForecastStats from "modules/Forecast/Hooks/useForecastStats";
import { useMemo } from "react";
import SBForecastEvaluationSectionItem from "../SBForecastEvaluationSectionItem";
import SBForecastPeriodRange from "../SBForecastPeriodRange";

export default (props: SBForecastEvaluationSectionProps) => {
	const { statistics, isMonthly = true, breakLine } = props;
	const { stats } = useForecastStats({ isMonthly, statistics });

	const gridLine = useMemo(() => {
		return breakLine ? 4 : 2;
	}, []);

	return (
		<SBGrid
			container
			columns={12}
			border="1px solid rgba(0,0,0,.1)"
			borderRadius="8px"
			py={3}
			width={1}
			style={{ position: "relative" }}
		>
			<SBForecastPeriodRange statistics={statistics} />
			{stats?.map((stat) => {
				return (
					<SBGrid item xs={gridLine} display="flex" justifyContent="center" width={1}>
						<SBForecastEvaluationSectionItem
							stat={stat}
						/>
					</SBGrid>
				);
			})}
		</SBGrid>
	);
};

interface SBForecastEvaluationSectionProps {
	statistics: IHomeStatistics;
	isMonthly?: boolean;
	breakLine?: boolean;
}