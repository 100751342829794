import { SBBox, SBDataTable } from "modules/Commons";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHeaders } from "./tableHeaders";

export default ({ items }: any) => {
	const { t } = useTranslation();
	const { headers } = useHeaders();
	const tableRef = useRef();
	return (
		<SBBox>
			<SBDataTable
				showCount
				collapseFieldName={"distributionPlans"}
				title={t("suggestedDistribution") as string}
				pagination
				showGroups
				columns={headers}
				ref={tableRef}
				rows={items}
				clickLine={() => {}}
			/>
		</SBBox>
	);
};
