import { SBButton, SBIcon, SBTypography } from "modules/Commons"
import { useStyles } from "./style";
import { SBTranslate, SBTranslateType } from "i18n";
import { useMemo } from "react";
import { useSystemTheme } from "themes/base";

export default (props: UploadButtonActionProps) => {
	const { currentState, restartUpload, onConclude } = props;
	const classes = useStyles();
	const theme = useSystemTheme();

	const fetchButtonAction = (): ActionModel => {
		switch (currentState) {
			case "onFinish":
				return {
					onClick: onConclude,
					icon: "check",
					label: "conclude",
					class: ""
				};
			case "onLoading":
				return {
					onClick: () => { },
					icon: "clock",
					label: "wait",
					class: classes.onLoadingButton,
					disabled: true
				};
			case "onError":
				return {
					onClick: restartUpload,
					icon: "upload",
					label: "reUpload",
				};
			case "onSaving":
				return {
					icon: "clock",
					label: "saving",
					class: classes.onLoadingButton,
					disabled: true
				};
			default:
				return {} as ActionModel;
		}
	}

	const state = useMemo(() => fetchButtonAction(), [currentState])

	return (
		<SBButton onClick={state.onClick} className={state.class}>
			<SBIcon icon={state.icon} color={theme.palette.white.main} />
			<SBTypography ml={1} fontWeight="700" color="white.main">
				{SBTranslate(state.label)}
			</SBTypography>
		</SBButton>
	)
}

interface UploadButtonActionProps {
	currentState: string;
	restartUpload: () => void;
	onConclude?: () => void;
}

type ActionModel = {
	onClick?: any;
	icon: string;
	label: SBTranslateType;
	class?: string;
	disabled?: boolean;
};