/* eslint-disable import/no-anonymous-default-export */
import {
	SBBox,
	SBButton,
	SBCard,
	SBIcon,
	SBIconButton,
	SBTypography,
	SBMenu,
} from "modules/Commons";
import { SBDatePickerTrimester } from "./SBDatePickerTrimester";
import { SBDatePickerSemestral } from "./SBDatePickerSemestral";
import { SBDatePickerAnual } from "./SBDatePickerAnual";
import { SBDatePickerMonthly } from "./SBDatePickerMonthly";

import { useState, useEffect, ElementType } from "react";
import { SBDatePickerDaily } from "./SBDatePickerDaily";
import { DateRangeType } from "../models/dateRangeType";
import { useStyles } from "./style";
import { SBDatePickerRangeSelector } from "./SBDatePickerRangeSelector";
import { useTranslation } from "react-i18next";
import { SBDatePickerConfig } from "./SBDatePickerConfig";
import { DateTime } from "luxon";
import { DatePickerConfiguration } from "../models/datePickerConfiguration";
import SBForm from "modules/Commons/SBForms/SBForm";
import { datePickerConfigValidator } from "domain/DatePickerConfig/Services/DatePickerConfigValidator";
import { IDatePicker } from "domain/Utils/models/IDatePicker";
import { StorageKey } from "domain/Utils";
import useRememberMe from "hooks/useRememberMe";

/** Supplybrain DatePicker */
export default (props: SBDatePicker) => {
	const {
		style,
		type = DateRangeType.Daily,
		range = true,
		minDate,
		maxDate,
		availableDates,
		fixedStart = false,
		defaultValue = { date: undefined, startDate: undefined, endDate: undefined },
		onApply = (value: IDatePicker) => { },
		onChangeType = (value: DateRangeType) => { },
	} = props;
	const { t } = useTranslation();
	const { storage } = useRememberMe();
	const classes = useStyles();

	const [validation, setValidation] = useState<any>({});
	const [dateValue, setDateValue] = useState<IDatePicker | null>({
		date: undefined,
		range: {
			startDate: fixedStart ? defaultValue?.startDate ?? undefined : undefined,
			endDate: undefined,
		}
	});

	const [config, setConfig] = useState<DatePickerConfiguration | null>(null);

	const [selectedPicker, setSelectedPicker] = useState<DateRangeType>(type);
	const [onConfig, setOnConfig] = useState<boolean>(false);
	const [selectedPickers, setSelectedPickers] =
		useState<Array<DateRangeType> | null>(null);

	const defaultSelectedPickers: Array<DateRangeType> = [
		DateRangeType.Daily,
		DateRangeType.Monthly,
		DateRangeType.Trimester,
		DateRangeType.Semester,
		DateRangeType.Year,
	];

	const useOnlyDefaultYears = true;

	const defaultConfig: DatePickerConfiguration = {
		startYear: 2015,
		endYear: DateTime.now().year + 7,
		selectedPickers: defaultSelectedPickers,
		toApplyItems: [
			{
				scope: "day",
				value: 1,
			},
			{
				scope: "day",
				value: 3,
			},
			{
				scope: "day",
				value: 5,
			},
			{
				scope: "week",
				value: 1,
			},
			{
				scope: "month",
				value: 1,
			},
			{
				scope: "year",
				value: 1,
			},
		],
	};

	const handleSelectType = (picker: DateRangeType) => {
		setSelectedPicker(picker);
		onChangeType(picker);
	};

	const onSelectDate = (e: IDatePicker) => {
		setDateValue({
			...e,
			type: selectedPicker,
		});

		if ((e.range?.startDate && e.range?.endDate) || e.date) handleApply(e);
	};

	const handleApply = (value: IDatePicker | null) => {
		if (value && (value.range?.startDate && value.range?.endDate || value.date)) onApply(value);
	};

	const handleApplyStart = (startDate: DateTime | undefined, value: DateTime) => {
		onApply({ range: { startDate: startDate, endDate: value } });
	};

	const handleSaveConfig = (config: DatePickerConfiguration) => {
		const savedConfig: DatePickerConfiguration = {
			startYear: config?.startYear,
			endYear: config?.endYear,
			selectedPickers: config?.selectedPickers,
			toApplyItems: config?.toApplyItems?.filter(
				(item) => item.value !== 0
			),
		};
		storage().setItem(StorageKey.SBDatePickerConfig, JSON.stringify(savedConfig));
		setConfig(savedConfig);
		setSelectedPickers(savedConfig.selectedPickers);
		setOnConfig((c) => !c);
	};

	const Component: ElementType = (() => {
		switch (selectedPicker) {
			case DateRangeType.Daily:
				return SBDatePickerDaily;
			case DateRangeType.Monthly:
				return SBDatePickerMonthly;
			case DateRangeType.Semester:
				return SBDatePickerSemestral;
			case DateRangeType.Year:
				return SBDatePickerAnual;
			case DateRangeType.Trimester:
				return SBDatePickerTrimester;
			default:
				return SBDatePickerDaily;
		}
	})();

	const fetchConfig = () => {
		const configString = storage().getItem(StorageKey.SBDatePickerConfig);
		if (configString) {
			const configObj = JSON.parse(configString) ?? {};
			setConfig({
				startYear: useOnlyDefaultYears
					? defaultConfig.startYear
					: configObj.startYear || defaultConfig.startYear,
				endYear: useOnlyDefaultYears
					? defaultConfig.endYear
					: configObj.endYear || defaultConfig.endYear,
				selectedPickers:
					configObj.selectedPickers || defaultConfig.selectedPickers,
				toApplyItems:
					configObj.toApplyItems || defaultConfig.toApplyItems,
			});
			setSelectedPickers(configObj.selectedPickers);
		} else {
			setConfig(defaultConfig);
			setSelectedPickers(defaultConfig.selectedPickers);
		}
	};

	useEffect(() => fetchConfig(), []);

	const onError = (e: any, v: any) => {
		setValidation([]);
		const errors = Object.keys(e).reduce(
			(a: any, b: any) => ({
				...a,
				[b]: e[b]?.message,
			}),
			{}
		);
		setValidation(errors);
	};

	const onSubmit = (form: any) => {
		handleSaveConfig(form);
	};

	const fetchPropError = (prop: string) => validation[prop];

	if (!Component) return null;

	return (
		<SBCard className={classes.sbDatePicker} style={{ ...style }}>
			{!onConfig ? (
				<SBBox padding={2}>

					<Component
						onSelectDate={onSelectDate}
						minDate={minDate}
						maxDate={maxDate}
						availableDates={availableDates}
						startYear={config?.startYear}
						endYear={config?.endYear}
						fixedStart={fixedStart}
						range={range}
						defaultValue={defaultValue}
					>
						{range ?
							<SBDatePickerRangeSelector
								handleSelectType={handleSelectType}
								selectedPicker={selectedPicker}
								selectedPickers={selectedPickers}
							/> : <></>}
					</Component>
					<SBBox display={range ? "flex" : "none"} mb={1} alignItems="center">
						<SBMenu
							canOpen={!!(dateValue && !dateValue.range?.endDate)}
							action={
								<SBButton
									fullWidth
									style={{
										width: 230,
										textTransform: "capitalize",
									}}
									onClick={() => handleApply(dateValue)}
									disabled={
										(dateValue &&
											!dateValue.range?.endDate &&
											!dateValue.range?.startDate) ||
										undefined
									}
								>
									<SBTypography
										variant="caption"
										fontWeight="bold"
									>
										{t("set")}
										{dateValue &&
											dateValue.range?.startDate &&
											` ${t(
												"from"
											)} ${dateValue.range?.startDate.toISODate()}`}
										{dateValue &&
											dateValue.range?.endDate &&
											` ${t(
												"to"
											)} ${dateValue.range?.endDate.toISODate()}`}

										{dateValue &&
											dateValue.range?.startDate &&
											!dateValue.range?.endDate ? (
											<SBIcon
												icon="select"
												size={15}
												fillColor="#fff"
											/>
										) : null}
									</SBTypography>
								</SBButton>
							}
							content={
								<SBBox
									display="flex"
									flexDirection="column"
									width={230}
									padding={2}

								>
									{config &&
										config.toApplyItems &&
										config.toApplyItems.map((item) => {
											const date =
												dateValue &&
												dateValue.range?.startDate &&
												dateValue.range?.startDate
													.plus({
														days:
															item.scope === "day"
																? +item.value
																: 0,
														weeks:
															item.scope ===
																"week"
																? +item.value
																: 0,
														months:
															item.scope ===
																"month"
																? +item.value
																: 0,
														years:
															item.scope ===
																"year"
																? +item.value
																: 0,
													})
													.minus({
														days:
															item.value &&
																String(
																	item.value
																) !== "0"
																? 1
																: 0,
													});

											return date ? (
												<SBButton
													key={
														item.scope + item.value
													}
													onClick={() =>
														handleApplyStart(
															dateValue?.range?.startDate,
															date
														)
													}
													style={{
														textTransform:
															"capitalize",
													}}
												>
													<SBTypography
														variant="caption"
														fontWeight="bold"
													>
														{`${t(
															"until"
														)} ${date.toISODate()} (+${item.value &&
															String(
																item.value
															) !== "0"
															? item.value
															: "0"
															} ${t(item.scope, {
																count: +item.value,
															})})`}
													</SBTypography>
												</SBButton>
											) : null;
										})}

								</SBBox>
							}
						/>

						<SBIconButton
							onClick={() => setOnConfig((c) => !c)}
							style={{ marginLeft: "auto" }}
						>
							<SBIcon icon="settings" />
						</SBIconButton>
					</SBBox>
				</SBBox>
			) : (
				<SBForm
					onSubmit={onSubmit}
					onError={onError}
					defaultValues={config}
					validationSchema={
						datePickerConfigValidator({
							defaultEndYear: 3000,
							defaultStartYear: 2000,
						}).schema
					}
				>
					<SBDatePickerConfig
						disableYearsInput={useOnlyDefaultYears}
						fetchPropError={fetchPropError}
					/>
				</SBForm>
			)}
		</SBCard>
	);
};

export interface SBDatePicker {
	style?: {} | null;
	type: DateRangeType;
	range?: boolean | null;
	minDate?: DateTime;
	maxDate?: DateTime;
	availableDates?: string[];
	fixedStart: boolean | null;
	defaultValue: {
		date: DateTime | undefined;
		startDate: DateTime | undefined;
		endDate: DateTime | undefined;
	};
	onApply: (value: IDatePicker) => any;
	onChangeType: (value: DateRangeType) => any;
}
