import { LinearProgress } from "@mui/material";
import { SBTypography } from "modules/Commons";
import SBBox from "modules/Commons/SBLayouts/SBBox";

export default (
	props: LinearProgressProps & { title: string; value: number; color: string }
) => {
	const { color, title, ...otherProps } = props;
	return (
		<>
			<SBTypography mb={1} textTransform="capitalize" fontStyle="italic">
				{title}
			</SBTypography>
			<SBBox
				sx={{
					display: "flex",
					alignItems: "center",
					position: "relative",
				}}
			>
				<SBBox sx={{ width: "100%", mr: 1 }}>
					<LinearProgress
						variant="determinate"
						{...otherProps}
						style={{ borderRadius: 8 }}
						color={color as any}
					/>
				</SBBox>
				<SBBox
					sx={{
						minWidth: 35,
						position: "absolute",
						right: 0,
						top: -28,
					}}
				>
					<SBTypography
						variant="body1"
						fontWeight="bold"
						color={`${color}.main` || color}
					>{`${Math.round(props.value)}%`}</SBTypography>
				</SBBox>
			</SBBox>
		</>
	);
};

export interface LinearProgressProps {}
