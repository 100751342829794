import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	sbTableContent: {
		borderBottom: "none",
		cursor: "pointer",
		"&.collapsed": {
			backgroundColor: theme.palette?.lightPurple?.main,
		},
		"&:hover td": {
			backgroundColor: theme.palette?.lightPurple?.main,
		},
		"&.bordered": {
			backgroundImage:
				"linear-gradient(to right, #ddd 33%, rgba(255,255,255,0) 0%)",
			backgroundPosition: "bottom",
			backgroundSize: "5px 2px",
			backgroundRepeat: " repeat-x",
		},
	},
}));
