import { SBTranslate } from "i18n";
import { SBBox } from "modules/Commons";
import { TransferencePlan } from "modules/Transferences/models/transferencePlan";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SBSummarization from "views/Commons/SBSummarization";

export default ({ transferencePlans }: TransferenceSummarization) => {
	const [summarizations, setSummarizations] = useState<any>([]);

	useEffect(() => {
		setSummarizations([
			{
				name: SBTranslate("available"),
				tip: SBTranslate("tips.availableTransference"),
				icon: "package",
				color: "darkPurple",
				value: transferencePlans
					.reduce((value, plan) => value + plan.initial?.excess, 0)
					.systemFormat(),
			},
			{
				name: SBTranslate("netNeed"),
				tip: SBTranslate("tips.netNeedTransference"),
				icon: "package",
				color: "darkPurple",
				value: transferencePlans
					.flatMap((c) => c.plans)
					.reduce((value, plan) => value + plan.netNeed, 0)
					.systemFormat(),
			},
			{
				name: SBTranslate("transferred"),
				tip: SBTranslate("tips.transferred"),
				icon: "package",
				color: "darkPurple",
				value: transferencePlans
					.flatMap((c) => c.plans)
					.reduce((value, plan) => value + plan.amount, 0)
					.systemFormat(),
			},
			{
				name: SBTranslate("store", { count: 2 }),
				tip: SBTranslate("tips.storeTransference"),
				icon: "home",
				color: "darkPurple",
				value: transferencePlans
					.flatMap((c) => c.plans)
					.map((c) => c.store.clientID)
					.distinct().length,
			},
			{
				name: SBTranslate("onScarcity", { count: 2 }),
				tip: SBTranslate("tips.scarcityTransference"),
				icon: "home",
				color: "darkPurple",
				value: transferencePlans
					.flatMap((c) => c.plans)
					.filter(
						(c) =>
							c.minCSL &&
							c.initial?.csl <= c?.minCSL
					).length,
			},
			{
				name: SBTranslate("reachedCSLs", { count: 2 }),
				tip: SBTranslate("tips.reachedCSLTransference"),
				icon: "home",
				color: "darkPurple",
				value: transferencePlans
					.flatMap((c) => c.plans)
					.filter((c) => c.final?.csl >= c?.csl).length,
			},
		]);
	}, [transferencePlans]);

	return (
		<SBBox my={2}>
			<SBSummarization items={summarizations} withShadow />
		</SBBox>
	);
};

interface TransferenceSummarization {
	transferencePlans: TransferencePlan[];
}
