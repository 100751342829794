import { SBBox, SBButton, SBGrid, SBIcon, SBTooltip, SBTypography } from "modules/Commons";
import { useContext, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ConfigTextField from "../ConfigTextField";
import { TipsContext } from "views/ConfigsView/ConfigTips";
import { SBTranslate } from "i18n";

export default ({ fetchPropError }: any) => {
	const { t } = useTranslation();
	const { control } = useFormContext();
	const moneyAdornment = <>{t("$")}</>;
	const tipsContext = useContext(TipsContext);

	const [optimalCSL, setOptimalCSL] = useState<number | "">(0);

	const updatedCost = useWatch({ control, name: "cost" });
	const updatedPrice = useWatch({ control, name: "price" });
	const updatedPenalty = useWatch({ control, name: "penalty" });
	const updatedSalvage = useWatch({ control, name: "salvage" });
	const updatedVolume = useWatch({ control, name: "volume" });
	const updatedWeight = useWatch({ control, name: "weight" });
	const updatedProfit = useWatch({ control, name: "profit" });

	useEffect(() => {
		setOptimalCSL(
			((+updatedPrice - +updatedCost + +updatedPenalty) /
				(+updatedPrice + +updatedPenalty - +updatedSalvage)) *
			100
		);
	}, [updatedCost, updatedPrice, updatedSalvage, updatedPenalty]);

	return (
		<SBBox>
			<SBGrid container spacing={4}>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="product"
						type="number"
						prop="cost"
						title={t("cost", { moneyTag: t("$") })}
						endAdornment={moneyAdornment}
						helperText={fetchPropError("cost")}
						onChange={(e) => updatedCost(e.target.value.positiveNumber())}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="product"
						type="number"
						prop="price"
						title={t("price")}
						endAdornment={moneyAdornment}
						helperText={fetchPropError("price")}
						onChange={(e) => updatedPrice(e.target.value.positiveNumber())}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="product"
						type="number"
						disabled
						title={t("profit")}
						prop="profit"
						endAdornment={moneyAdornment}
						value={
							(updatedPrice - updatedCost).systemFormat({
								maximumFractionDigits: 2,
							}) != "NaN" &&
							(updatedPrice - updatedCost).systemFormat({
								maximumFractionDigits: 2,
							})
						}
						helperText={fetchPropError("profit")}
						onChange={(e) => updatedProfit(e.target.value.positiveNumber())}
					/>
				</SBGrid>
			</SBGrid>
			<SBGrid container spacing={4}>
				<SBGrid item xs={3}>
					<ConfigTextField
						section="product"
						type="number"
						prop="penalty"
						title={t("penalty")}
						endAdornment={moneyAdornment}
						helperText={fetchPropError("penalty")}
						onChange={(e) => updatedPenalty(e.target.value.positiveNumber())}
					/>
				</SBGrid>
				<SBGrid item xs={3}>
					<ConfigTextField
						section="product"
						type="number"
						prop="salvage"
						title={t("salvage")}
						endAdornment={moneyAdornment}
						helperText={fetchPropError("salvage")}
						onChange={(e) => updatedSalvage(e.target.value.positiveNumber())}
					/>
				</SBGrid>
				<SBGrid item xs={3}>
					<ConfigTextField
						section="product"
						type="number"
						prop="weight"
						title={t("weight")}
						endAdornment={"Kg"}
						helperText={fetchPropError("weight")}
						onChange={(e) => updatedWeight(e.target.value.positiveNumber())}
					/>
				</SBGrid>
				<SBGrid item xs={3}>
					<ConfigTextField
						section="product"
						type="number"
						prop="volume"
						title={t("volume")}
						endAdornment={"m³"}
						helperText={fetchPropError("volume")}
						onChange={(e) => updatedVolume(e.target.value.positiveNumber())}
					/>
				</SBGrid>
				{!isNaN(+optimalCSL) &&
					isFinite(+optimalCSL) &&
					+optimalCSL > 0 ? (
					<SBGrid item xs={12} display="flex">
						<SBTypography mr={1} onClick={() => tipsContext.showTip("ProductOptimalCSL")}>
							<SBIcon icon="help-circle" color="darkPurple" />
						</SBTypography>
						<SBTypography fontWeight="700" mr={1}>
							{t("optimalCSL")}
						</SBTypography>
						<SBTypography color="primary" fontWeight="900">
							{(+optimalCSL <= 100 ? optimalCSL.toLocaleString("pt-BR", {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							}) : "100") + "%"}
						</SBTypography>
					</SBGrid>
				) : null}
			</SBGrid>
		</SBBox>
	);
};
