import { SBTranslate, SBTranslateType } from "i18n";
import { SBBox, SBCard, SBOutlinedButton, SBTypography } from "modules/Commons";
import { ReactNode, useMemo } from "react";
import { useStyles, Content } from "./style";
import { Trans } from "react-i18next";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { CircularProgress } from "@mui/material";
import { formatInteger } from "utils/formatters";

export default ({ deadInventoriesCount, deadInventoriesPositions, icon, onSeeAll, loading, title, subTitle, description }: SBVirtualStockBaseCardProps) => {
    const classes = useStyles();
    const { getLanguage } = useSystemLanguage();

    const config = useMemo(() => ({
        unitQuantity: formatInteger(deadInventoriesPositions),
        units: SBTranslate("unitComplete", { count: deadInventoriesPositions }),
        inventoryQuantity: formatInteger(deadInventoriesCount),
        inventory: SBTranslate("unmanagedInventory", { count: deadInventoriesCount })
    } as any), [deadInventoriesCount, deadInventoriesPositions, getLanguage()])

    const hasDeadInventoryPositions = () => deadInventoriesPositions > 0;
    return <SBCard className={classes.card}>
        {loading ? <CircularProgress size={104} />
            : <SBBox className={classes.container} justifyContent={hasDeadInventoryPositions() ? "space-between" : "center"}>
                <SBBox className={classes.section}>
                    {icon}
                    <SBTypography className={classes.title}>
                        {SBTranslate(title)}
                    </SBTypography>
                </SBBox>
                <SBBox className={classes.section}>
                    <SBTypography className={classes.subTitle}>
                        {SBTranslate(subTitle)}
                    </SBTypography>
                    <SBTypography className={classes.description}>
                        {SBTranslate(description)}
                    </SBTypography>

                </SBBox>

                {hasDeadInventoryPositions() ? (
                    <>
                        <SBTypography className={classes.countLabel}>
                            <Trans i18nKey="views.home.deadInventoryTrueCardDescription" lang={getLanguage()}>
                                <Content>
                                    {config}
                                </Content>
                            </Trans>
                        </SBTypography>
                        {onSeeAll &&
                            (<SBOutlinedButton fullWidth className={classes.action} onClick={onSeeAll}>
                                <SBTypography textTransform="none">
                                    {SBTranslate("commons.seeAll")}
                                </SBTypography>
                            </SBOutlinedButton>)
                        }
                    </>
                ) :
                    <SBTypography fontWeight="bold" className={classes.countLabel}>
                        {SBTranslate("views.home.noDeadInventory")}
                    </SBTypography>
                }
            </SBBox>}

    </SBCard>
};

interface SBVirtualStockBaseCardProps {
    title: SBTranslateType,
    subTitle: SBTranslateType,
    description: SBTranslateType,

    deadInventoriesCount: number;
    deadInventoriesPositions: number;
    icon: ReactNode;
    loading: boolean | undefined;
    onSeeAll?: () => void;
}
