import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
	titleBox: {
		display: "flex",
		justifyContent: "space-between",
	},
	searchBox: {
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
	},
	applyButton: {
		marginLeft: 12,
	},
}));
