import { alpha, useTheme } from "@mui/system";
import SBSquaredIconRange, {
	SBSquaredIconRangeProps,
} from "./SBSquaredIconRange";
import SBSquaredIconBox from "./SBSquaredIconBox";
import SBSquaredIconTitle, {
	SBSquaredIconTitleProps,
} from "./SBSquaredIconTitle";
import { ReactNode, useMemo } from "react";
import { SBBox, SBTypography } from "modules/Commons";
import SBTooltip from "../SBTooltip";
import { useStyles } from "./styles";
import clsx from "clsx";
export default ({
	width = 1,
	title,
	tip,
	valueTip,
	value,
	icon,
	min,
	max,
	disableIcon,
	color = "primary",
	fillColor = null,
	rangeOnTooltip,
	append,
}: SBSquaredIconProps) => {
	const theme = useTheme();
	const classes = useStyles();

	const squaredStyles = useMemo(() => {
		const isFillColor =
			fillColor != null && fillColor !== undefined && fillColor !== "";

		const selectedColor = isFillColor
			? theme.palette[fillColor].main
			: theme.palette[color].main;

		const alphaColor = alpha(selectedColor, 0.15);

		return {
			boxDimension: 48,
			font: 18,
			iconSize: 25,
			fontColor: selectedColor,
			fillColor: isFillColor ? selectedColor : "",
			color: isFillColor ? "" : selectedColor,
			bgColor: alphaColor,
		};
	}, [color, fillColor, theme.palette]);

	return (
		<SBBox display="flex" width={width} maxWidth={"65%"}>
			{!disableIcon ? (
				<SBSquaredIconBox
					bgColor={squaredStyles.bgColor}
					icon={icon}
					fillColor={squaredStyles.fillColor}
					color={squaredStyles.color}
				/>
			) : null}
			{min && max ?
				<SBTooltip
					className={clsx(classes.squaredIconBoxText)}
					disableHoverListener={!rangeOnTooltip}
					title={<SBSquaredIconRange min={min} max={max} />}
					placement="right"
				>
					<SBBox>
						<SBSquaredIconTitle
							title={title}
							tip={tip}
							valueTip={valueTip}
							value={value}
							fontColor={squaredStyles.fontColor}
							append={append}
						>
							{!rangeOnTooltip && (
								<SBTypography ml={2} variant="caption" position="absolute">
									<SBSquaredIconRange min={min} max={max} />
								</SBTypography>
							)}
						</SBSquaredIconTitle>
					</SBBox>
				</SBTooltip> :
				<SBBox width={1}>
					<SBSquaredIconTitle
						title={title}
						tip={tip}
						valueTip={valueTip}
						value={value}
						fontColor={squaredStyles.fontColor}
						append={append}
					>
						{!rangeOnTooltip && (
							<SBBox ml={2}>
								<SBSquaredIconRange min={min} max={max} />
							</SBBox>
						)}
					</SBSquaredIconTitle>
				</SBBox>
			}

		</SBBox>
	);
};

export interface SBSquaredIconProps
	extends Omit<SBSquaredIconTitleProps, "fontColor" | "children">,
	SBSquaredIconRangeProps {
	/** Icon tip */
	tip?: string;
	/** Icon value tip */
	valueTip?: string;
	/** Icon to be rendered  */
	icon: string;
	/** Color to be rendered  */
	color?: string;
	/** Width of the container  */
	width?: number;
	/** Fill color of the icon  */
	fillColor?: string | null;
	/** Remove the icon box of the indicators */
	disableIcon?: boolean;
	/** Show range of the tooltip */
	rangeOnTooltip?: boolean;
	/** Children */
	append?: ReactNode;
}
