import SBDatePickerBase from "../SBDatePickerBase";
import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import { DateRangeType } from "../../models/dateRangeType";
import { ReactNode } from "react";

export const SBDatePickerAnual = (
	props: ReactNode & { startYear: number; endYear: number }
) => {
	const { startYear = 0, endYear = 10 } = props;
	const currentYear = DateTime.now().year;
	const range = endYear - startYear;
	const size = 12;

	const [years] = useState(
		range < size
			? [0].map((c) => [
					...[...Array(range + 1).keys()].map(
						(i) => endYear - c + i - range
					),
			  ])
			: [...Array(range - 10).keys()].map((c) => [
					...[...Array(size).keys()].map(
						(i) => endYear - c + i - size + 1
					),
			  ])
	);

	const [selectedYear, setSelectedYear] = useState(() => {
		for (var i = 0; i < years.length; i++) {
			if (years[i][0] === currentYear) return years[i];
		}
		return years[0];
	});

	const validQuarters = useMemo(
		() =>
			selectedYear.flatMap((d) => {
				const date = DateTime.now().set({
					month: 1,
					day: 1,
					year: d,
				});
				return { label: date.year.toString(), date: date };
			}),
		[selectedYear]
	);

	return (
		<SBDatePickerBase
			type={DateRangeType.Year}
			validItems={validQuarters}
			years={years}
			selectedYear={selectedYear}
			onChangeItem={setSelectedYear}
			getText={(item: Array<number>) =>
				`${item[0]} - ${item[item.length - 1]}`
			}
			{...props}
		/>
	);
};
