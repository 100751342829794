import { useClientIdentificator } from "hooks/useClientIdentificator";

export enum Concept {
	PURCHASE = "PURCHASE",
	TRANSFERENCE = "TRANSFERENCE",
	DISTRIBUTION = "DISTRIBUTION",
}

export enum Authorization {
	READ = 0,
	CREATE = 1,
	UPDATE = 2,
	DELETE = 4,
	INTEGRATION = 8,
}

export const useClientRules = () => {
	const { client, environment, clientEnvironment } = useClientIdentificator();

	const contextViews = ["/purchase/recomendation", "/home", "/home/details", "/transference"]

	const rulers = (): string[] => {
		var accesses = ["purchase", "cost"] as string[];

		if (client === "sbrain") accesses.push("forecast");

		return accesses;
	};

	const views = (): string[] => {
		var views = [
			"purchase",
			"simulation",
			"analytics",
			"explorations",
			"configurations",
			"forecast",
			"home",
		] as string[];

		if (client !== "tutiplast") views.push("transference");

		return views;
	};

	const chooseAuthorizations = () => {
		const authorization =
			client === "simpress" || client === "sbrain"
				? Authorization.INTEGRATION
				: Authorization.READ;

		return {
			[Concept.PURCHASE]: authorization,
			[Concept.TRANSFERENCE]: authorization,
			[Concept.DISTRIBUTION]: authorization,
		};
	};

	return {
		rulers: rulers(),
		views: views(),
		authorizations: chooseAuthorizations(),
		contextViews
	};
};
