import greenCheck from "assets/greenCheck.svg";
import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import SBVirtualStockBaseCard from "../SBVirtualStockBaseCard";

export default ({ statistics }: SBVirtualStockNormalCardProps) => {
	return (
		<SBVirtualStockBaseCard
			icon={<img height={104} src={greenCheck} alt="" />}
			statistics={statistics}
			title="views.home.virtualStockNormalCardTitle"
			subTitle="views.home.virtualStockCardSubTitle"
			description="views.home.virtualStockNormalCardDescription"
		/>
	);
};

interface SBVirtualStockNormalCardProps {
	statistics: IHomeStatistics;
}