import { SBTextField } from "modules/Commons";
import { SBTextFieldProps } from "modules/Commons/SBForms/SBTextField";
import { memo, useContext } from "react";
import { TipsContext } from "views/ConfigsView/ConfigTips";

export default memo(
	({
		title,
		prop,
		endAdornment,
		value,
		placeholder,
		onChange,
		onClick,
		disabled,
		helperText,
		section,
		type = "text",
	}: ConfigTextField) => {
		const tipsContext = useContext(TipsContext);
		return (
			<SBTextField
				onClick={
					onClick ||
					(() =>
						tipsContext.showTip(
							section.capitalize() + prop?.capitalize() ?? ""
						))
				}
				prop={prop}
				titleTransform="uppercase"
				titleVariant="body2"
				title={title}
				placeholder={placeholder}
				type={type}
				disabled={disabled}
				value={value || undefined}
				onChange={!prop ? onChange : undefined}
				endAdornmentChild={endAdornment}
				helperText={helperText}
				error={!!helperText}
			/>
		);
	}
);

export interface ConfigTextField extends SBTextFieldProps {
	section: string;
	prop?: string;
	onClick?: () => void;
}
