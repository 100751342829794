import { Link, LinkProps } from "react-router-dom";
import { SBBox } from "modules/Commons";

/** SBLink */
export default (props: LinkProps<unknown>) => {
	return (
		<SBBox>
			<Link {...props} style={{ textDecoration: "none" }} />
		</SBBox>
	);
};
