import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	modalInfoIcon: {
		width: "24px",
		height: "24px",
		marginRight: "8px",
	},
}));
