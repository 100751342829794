import { styled } from "@mui/styles";
import { SBBox, SBTypography } from "modules/Commons";
import { BaseThemeOptions } from "themes/base";

const labelSize = "47px";

export const SlideMenuContent = styled(SBBox)(
	({ theme }: SlideMenuContentProps) => ({
		alignItems: "center",
		display: "flex",
		justifyContent: "space-between",
		//@ts-ignore
		padding: theme.spacing(1),
	})
);

interface SlideMenuContentProps {
	theme: BaseThemeOptions;
}

export const ReportContent = styled("span")(
	({ theme }: ReportContentProps) => ({
		fontWeight: "700",
		color: theme.palette.primary.main,
	})
);

interface ReportContentProps {
	theme: BaseThemeOptions;
}

export const EmergencialContent = styled("span")(
	({ theme }: ReportContentProps) => ({
		fontWeight: "700",
		color: theme.palette.error.main,
	})
);
