import { SBTranslate } from "i18n";
import { SBIcon, SBTooltip } from "modules/Commons";
import { IAccordionItem } from "modules/InventoryStatistics/domain/IAccordionItem";
import { TabContainer, TabContent, TabIconContainer, TabLabel, TabLabelContent } from "./style";

export default ({ index, item, isOpen, onLabelClick, countOfTabs }: SBInventoryAccordionTabProps) => {
	return (
		<TabContainer
			index={index}
			isOpen={isOpen}
			color={item.color}
			disabled={item.disabled}
			countOfTabs={countOfTabs}
		>
			<TabLabelContent
				color={item.color}
				disabled={item.disabled}
				onClick={() => onLabelClick(index)}
			>
				{item.isEmergencial && (
					<TabIconContainer>
						<SBTooltip title={SBTranslate("views.home.emergencialTip")}>
							<SBIcon icon="alert-triangle" color="white" size="1.2rem" />
						</SBTooltip>
					</TabIconContainer>
				)}
				<TabLabel> {item.label} </TabLabel>
				<SBTooltip title={SBTranslate("views.home.recommendationCardTip", { type: item.label })}>
					<SBIcon icon={item.icon} color="white" size="1.3rem" />
				</SBTooltip>
			</TabLabelContent>
			<TabContent isOpen={isOpen}>
				{item.content}
			</TabContent>
		</TabContainer>
	);
}

interface SBInventoryAccordionTabProps {
	/** Index of the tab to see if it is active or not */
	index: number;
	/** Items to show on tab */
	item: IAccordionItem;
	/** Total of itens that will be on the accordion */
	countOfTabs: number;
	/** Click to change the opened tab*/
	onLabelClick: (index: number) => void;
	/** Indication that the current tab is open or not */
	isOpen: boolean;
}