import SimulationChartTooltip from "../SimulationChartTooltip";
import { YAxis, Tooltip, ResponsiveContainer, XAxis, Brush } from "recharts";
import { useMemo, useEffect, useState } from "react";
import {
	NameType,
	ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { TooltipProps } from "recharts/types/component/Tooltip";
import SimulationChartTooltipXAxis from "../SimulationChartTooltipXAxis";
import { IChartFilter } from "domain/Chart/Entities/IChartFilter";
import { IChart } from "domain/Chart/Entities/IChart";
import { BarChart } from "recharts";
import { Bar } from "recharts";
import { useTranslation } from "react-i18next";
import SimulationChartNoData from "../SimulationChartNoData";
import { ISimulationPoint } from "modules/Simulations/models/ISimulationPoint";

export default ({
	dataArray,
	chartID,
	chartFilter,
	selectedChart,
	realChart,
	projectedChart,
	cardIndex,
	simulationPeriod,
	tooltipRows,
	ignoreDateConstraint,
}: any) => {
	const [min, setMin] = useState(0);
	const [max, setMax] = useState(0);
	const [line, setLine] = useState<any>([]);
	const [projectedLimit, setProjectedLimit] = useState<any>(100);
	const cardChartID = chartID + cardIndex;

	const isForecast = selectedChart === "forecast";
	const formatY = (a: number) => a.nearestThousand({});

	const fetchCurrentChart = useMemo(
		() => (selectedChart === "forecast" ? "sales" : selectedChart),
		[selectedChart]
	);

	const fetchChartData = () => {
		const data = (chartFilter ? line.filter(chartFilter) : line).map(
			(c: any) => ({
				...c,
				projected: {
					...c.projected,
					range: [c.projected.minForecast, c.projected.maxForecast],
				},
			})
		);
		return data.filter(
			(c: any) =>
				c.real[selectedChart] > 0 || c.projected[selectedChart] > 0
		);
	};

	useEffect(() => {
		var filteredData = chartFilter
			? dataArray.filter(chartFilter)
			: dataArray;

		setMin(
			Math.min(
				...filteredData.map((c: any) =>
					Math.round(
						Math.min(
							(c.real && c.real[fetchCurrentChart]) || 0,
							c.projected[selectedChart]
						)
					)
				)
			)
		);
		setMax(
			Math.max(
				...filteredData.map((c: any) =>
					Math.round(
						Math.max(
							(c.real && c.real[fetchCurrentChart]) || 0,
							c.projected[selectedChart]
						)
					)
				)
			)
		);

		const indexOfProjected = filteredData.findIndex(
			(d: any) => d.real.projected
		);

		if (indexOfProjected > 0)
			setProjectedLimit((indexOfProjected / filteredData.length) * 100);
		else if (indexOfProjected === -1) setProjectedLimit(100);
		else setProjectedLimit(0);

		setLine(filteredData);
	}, [selectedChart, fetchCurrentChart, chartFilter, dataArray]);

	return (
		<ResponsiveContainer height={238} id={cardChartID}>
			{fetchChartData().length > 0 ? (
				<BarChart
					margin={{ top: 15, left: -15, right: 10, bottom: -5 }}
					data={fetchChartData()}
					key={min + max + cardChartID}
					maxBarSize={50}
					barSize={50}
					width={10}
					barCategoryGap="1px"
					barGap="1px"
				>
					{realChart && realChart.show ? (
						<>
							<Bar
								label={{ fill: "white", fontSize: 10 }}
								type="monotone"
								dataKey={`real.${fetchCurrentChart}`}
								name="Real"
								stroke={realChart.color}
								strokeWidth={isForecast ? 2 : 1}
								fill={
									!isForecast
										? projectedLimit !== 100
											? "url(#realLimit)"
											: `${realChart.color}88`
										: "transparent"
								}
							/>

							<defs>
								<linearGradient
									id="realLimit"
									x1="0%"
									y1="0"
									x2="100%"
									y2="0"
								>
									<stop
										offset="0%"
										stopColor={`${realChart.color}88`}
									/>
									<stop
										offset={`100%`}
										stopColor={`${realChart.color}88`}
									/>
									<stop
										offset={`${projectedLimit}%`}
										stopColor={`${realChart.color}33`}
									/>
									<stop
										offset={`${100}%`}
										stopColor={`${realChart.color}33`}
									/>
								</linearGradient>
							</defs>
						</>
					) : null}

					{projectedChart && projectedChart.show ? (
						<>
							<Bar
								label={{ fill: "black", fontSize: 10 }}
								type="monotone"
								dataKey={`projected.${selectedChart}`}
								name="Projected"
								color={projectedChart.color}
								stroke={projectedChart.color}
								fill={
									isForecast
										? "transparent"
										: `${projectedChart.color}33`
								}
								strokeDasharray="5 1"
							/>
						</>
					) : null}
					<XAxis
						dataKey="date"
						type="category"
						interval={0}
						tickLine={false}
						tick={(props: any) => (
							<SimulationChartTooltipXAxis
								ignoreDateConstraint={ignoreDateConstraint}
								dateFormat="dd/MM/yyyy"
								payload={props.payload}
								simulationPeriod={simulationPeriod}
								x={props.x}
								y={props.y}
							/>
						)}
					/>
					<YAxis
						interval={0}
						type="number"
						tickLine={false}
						ticks={[0, max]}
						domain={["dataMin", "dataMax"]}
						style={{ fontSize: 10, color: "#828282" }}
						tickFormatter={formatY}
					/>
					<Tooltip
						cursor={{ fill: "#7F51FF33" }}
						content={(props: TooltipProps<ValueType, NameType>) => (
							<SimulationChartTooltip
								{...props}
								element={document.getElementById(chartID)}
								container={document.getElementById(cardChartID)}
								selectedChart={selectedChart}
								tooltipRows={tooltipRows}
							/>
						)}
					/>
				</BarChart>
			) : (
				<SimulationChartNoData />
			)}
		</ResponsiveContainer>
	);
};

export interface SimulateCardChartProps {
	dataArray: any[];
	chartID: any;
	chartFilter: any;
	selectedChart: IChartFilter;
	realChart: IChart<ISimulationPoint>;
	projectedChart: IChart<ISimulationPoint>;
	cardIndex: any;
	tooltipRows: string[];
}
