import { Stack } from "@mui/material";
import { TimePeriod } from "domain/DatePickerConfig/domain/enums/TimePeriod";
import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import SBForecastPeriodFilter from "modules/Forecast/Components/SBForecastPeriodFilter";
import SBForecastTitleLine from "modules/Forecast/Components/SBForecastTitleLine";
import { useForecastExporter } from "modules/Forecast/Hooks/useForecastExporter";
import { useMemo, useState } from "react";
import ForecastSummarizationChart from "views/PurchaseViews/PurchaseAlertsView/HomeAlertCard/Summarizations/ForecastSummarization/ForecastSummarizationChart";
import SBForecastEvaluationSection from "../SBForecastEvaluationSection";

export default ({ statistics, breakLine }: SBForecastEvaluationProps) => {
	const { asCSV } = useForecastExporter();
	const [timePeriod, setTimePeriod] = useState(TimePeriod.DailyUTC);

	const demand = useMemo(
		() =>
			timePeriod === TimePeriod.DailyUTC
				? statistics?.evaluation?.demand
				: statistics?.evaluation?.monthlyDemand,
		[timePeriod]
	);

	const exportCSV = () => asCSV({ demand });

	return (
		<Stack spacing={2}>
			<SBForecastTitleLine
				exportCSV={exportCSV}
			/>
			<ForecastSummarizationChart
				dataPoints={demand ?? []}
				dateFilter={({ onUpdate }) =>
					<SBForecastPeriodFilter
						setTimePeriod={setTimePeriod}
						timePeriod={timePeriod}
						onUpdate={onUpdate}
					/>
				}
			/>
			<SBForecastEvaluationSection
				breakLine={breakLine}
				statistics={statistics}
				isMonthly={timePeriod == TimePeriod.MonthlyUTC}
			/>
		</Stack>
	);
};

interface SBForecastEvaluationProps {
	statistics: IHomeStatistics;
	breakLine?: boolean;
}
