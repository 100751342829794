export const useCSV = () => {
	const toCSV = ({
		json,
		separator = ",",
		headers = [],
	}: {
		json: any;
		separator?: string;
		headers?: string[];
	}) => {
		const objectToBuildHeader = Array.isArray(json) ? json[0] : json;
		const headersToUse =
			headers.length > 0
				? headers.join(separator) + "\n"
				: Object.keys(objectToBuildHeader).join(separator) + "\n";

		const body = json
			.map((obj: any) => {
				const row = headers.map((o) => obj[o]).join(separator);
				return row;
			})
			.join("\n");

		return headersToUse + body;
	};
	const fromCSV = () => {};

	const downloadCSV = ({
		csv,
		title = "random",
	}: {
		csv: string;
		title: string;
	}) => {
		var downloadLink = document.createElement("a");
		var blob = new Blob(["\ufeff", csv]);
		var url = URL.createObjectURL(blob);
		downloadLink.href = url;
		downloadLink.download = `${title}.csv`;

		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	return {
		toCSV,
		fromCSV,
		downloadCSV,
	};
};
