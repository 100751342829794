import {
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getGroupedRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { IEntity } from "domain/Domain";
import { useEffect, useMemo, useState } from "react";
import { SBTableColumn } from "../../models/TableColumn";
import { columnsAdapter } from "../helpers/columnsAdapter";
import useTableExpanded from "./useTableExpanded";
import useTableFilter from "./useTableFilter";
import useTableGrouping from "./useTableGrouping";
import useTableSelection from "./useTableSelection";
import useTableSorting from "./useTableSorting";

export default <T extends IEntity>(props: useTableManagerProps<T>) => {
	const {
		defaultFilter,
		startRows,
		defaultColumns,
		onSelect,
		hasSelection,
		onDataUpdated,
	} = props;

	const [data, setData] = useState(() => startRows);

	const {
		filter,
		fuzzyFilter,
		setGlobalFilter,
		columnFilters,
		setColumnFilters,
		filterFunction,
		setExternalFilter,
	} = useTableFilter({ defaultColumns, defaultFilter });

	const { sorting, setSorting } = useTableSorting();

	const { grouping, setGrouping } = useTableGrouping();

	const { expanded, setExpanded } = useTableExpanded();

	const { rowSelection, setRowSelection } = useTableSelection({
		onSelect,
		data,
	});

	const [columnVisibility, setColumnVisibility] = useState({});

	const columns = useMemo(() => {
		const cachedColumns = defaultColumns;

		const newColumns = columnsAdapter<T>({
			columns: cachedColumns,
			rows: startRows,
			hasSelection,
		});

		return newColumns;
	}, []);

	const table = useReactTable<T>({
		data,
		columns,
		filterFns: {
			fuzzy: fuzzyFilter,
		},
		state: {
			columnFilters,
			sorting,
			grouping,
			expanded,
			columnVisibility,
			rowSelection,
			globalFilter: filter,
		},
		onGlobalFilterChange: setGlobalFilter,
		onColumnFiltersChange: setColumnFilters,
		globalFilterFn: filterFunction,
		onColumnVisibilityChange: setColumnVisibility,
		onSortingChange: setSorting,
		onGroupingChange: setGrouping,
		onExpandedChange: setExpanded,
		onRowSelectionChange: setRowSelection,
		getFilteredRowModel: getFilteredRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(), //Verificar
		getGroupedRowModel: getGroupedRowModel(),
		debugTable: true,
		meta: {
			updateData: (
				rowIndex: number,
				columnId: number,
				value: number | string
			) => {
				setData((old) =>
					old.map((row, index) => {
						if (index === rowIndex) {
							return {
								...old[rowIndex]!,
								[columnId]: value,
							};
						}
						return row;
					})
				);
			},
		},
	});

	const { rows } = table.getRowModel();

	const toUpdateRefs = [
		data,
		columnFilters,
		sorting,
		grouping,
		expanded,
		columnVisibility,
		rowSelection,
		filter,
	];

	useEffect(() => {
		onDataUpdated?.(data);
	}, [data]);

	return {
		manager: {
			toUpdateRefs,
			table,
			rows,
			columns: table.getAllColumns(),
		},
		selection: {
			rowSelection,
		},
		filter: {
			setGlobalFilter,
			setExternalFilter,
			setColumnFilters,
		},
	};
};

interface useTableManagerProps<T extends IEntity> {
	defaultColumns: SBTableColumn<T>[];
	startRows: T[];
	hasSelection?: boolean;
	defaultFilter?: { id: string; value: any }[];
	onDataUpdated: any;
	onSelect?: (entities: T[], selections: any) => void;
}
