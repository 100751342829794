import { IGroupedStatistics } from "domain/Statistic/Models/IGroupedStatistics";
import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import { SBTranslate } from "i18n";
import { SBBox } from "modules/Commons";
import SBGroupedStatisticsCard from "modules/Commons/SBGroupedStatisticsCard";
import { useMemo } from "react";
import { percentageFormatter } from "utils/formatters";

export default ({ statistics }: SBCSLStatusCardsProps) => {
	const items = useMemo(() => {
		const status = statistics?.currentStatus;

		return [
			{
				label: SBTranslate("avgCSL"),
				tootltip: SBTranslate("tips.averageCSL"),
				value: status?.targetCSL?.avg?.value,
				formatter: percentageFormatter,
				subItems: [
					{
						label: `${SBTranslate("minCSL")}:`,
						value: status?.targetCSL?.min?.value,
					},
					{
						label: `${SBTranslate("maxCSL")}:`,
						value: status?.targetCSL?.max?.value,
					}
				]
			},
			{
				label: SBTranslate("avgRisk"),
				tootltip: SBTranslate("tips.averageRisk"),
				value: 1 - status?.targetCSL?.avg?.value,
				formatter: percentageFormatter,
				subItems: [
					{
						label: `${SBTranslate("minCSL")}:`,
						value: 1 - status?.targetCSL?.min.value,
					},
					{
						label: `${SBTranslate("maxCSL")}:`,
						value: 1 - status?.targetCSL?.max.value,
					}
				]
			},
			{
				label: SBTranslate("avgTarget"),
				tootltip: SBTranslate("tips.averageTargetCSL"),
				value: status?.csl?.avg?.value,
				formatter: percentageFormatter,
				subItems: [
					{
						label: `${SBTranslate("minCSL")}:`,
						value: status?.minCSL?.value,
					},
					{
						label: `${SBTranslate("maxCSL")}:`,
						value: status?.maxCSL?.value,
					}
				]
			},
		] as IGroupedStatistics[];
	}, []);

	return <SBGroupedStatisticsCard items={items} />
};

interface SBCSLStatusCardsProps {
	statistics: IHomeStatistics;
}
