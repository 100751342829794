import { IComposedFilter } from "domain/Utils";
import { IValidation } from "domain/Utils/models/IValidation";
import { useRequest } from "hooks/useRequest";

export const ValidationsRequester = (() => {
	const request = useRequest("notifications");
	return {
		validations: async (composedFilter: IComposedFilter) => {
			const products = composedFilter?.filter?.filter(
				(c: any) => c.type === "product"
			);
			const categories = composedFilter?.filter?.filter(
				(c: any) => c.type === "category"
			);
			const stores = composedFilter?.filter?.filter(
				(c: any) => c.type === "store"
			);

			return request
				.POST("home/statistics/fails", { products, categories, stores })
				.then((c) => {
					return c.data as IValidation[];
				});
		},
	};
})();
