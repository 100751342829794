import { TableRow } from "@mui/material";
import SBTableCells from "../SBTableCells";
import { useStyles } from "../SBTableContent/style";
import { useTheme } from "@mui/system";
import { forwardRef, useState } from "react";
import clsx from "clsx";

export const SBTableRow = forwardRef(
	(
		{
			collapseFieldName,
			row,
			striped,
			formTableIndex,
			index,
			oneDimensionColumns,
			handleEditableChange,
			selectable,
			actionable,
			isSelected,
			isModified,
			onClick,
			onChecked,
			getID,
			rowRef,
			isChild,
			childClassName,
		}: SBTableRowProps,
		ref
	) => {
		const { palette } = useTheme();
		const classes = useStyles();
		const rowId = getID(row) || index;

		const [collapse, setCollapse] = useState(false);

		const stripedColor = (index: number) => {
			if (striped) return index % 2 === 0;
			return true;
		};

		const fetchRowColor = (rowId: string | number, index: number) => {
			if (!selectable)
				return stripedColor(index)
					? palette?.white?.main
					: palette?.lightPurple1?.main;

			return !isSelected(rowId)
				? stripedColor(index)
					? palette?.white?.main
					: palette?.lightPurple1?.main
				: palette?.randomColor1?.main;
		};

		const rowColor = fetchRowColor(rowId, index);

		const hasCollapse =
			collapseFieldName &&
			row[collapseFieldName] &&
			row[collapseFieldName].length > 0;

		const childIsSelected = () =>
			collapseFieldName &&
			row[collapseFieldName].every((c: any) => isSelected(getID(c)));

		const someChildIsSelected = () =>
			collapseFieldName &&
			row[collapseFieldName].some((c: any) => isSelected(getID(c)));

		return (
			<>
				<TableRow
					key={rowId}
					data-testid="sb-table-content-row"
					role="checkbox"
					tabIndex={-1}
					sx={{
						backgroundColor: rowColor,
					}}
					className={clsx(
						classes.sbTableContent,
						collapse && "collapsed",
						childClassName
					)}
				>
					<SBTableCells
						formTableIndex={formTableIndex}
						row={row}
						index={index}
						oneDimensionColumns={oneDimensionColumns}
						handleEditableChange={handleEditableChange}
						selectable={selectable}
						actionable={actionable}
						isSelected={hasCollapse ? childIsSelected : isSelected}
						isIndeterminate={
							hasCollapse &&
							someChildIsSelected() &&
							!childIsSelected()
						}
						isModified={isModified}
						onClick={(event: any) =>
							hasCollapse
								? setCollapse(!collapse)
								: onClick(event, rowId)
						}
						onChecked={(event: any) => onChecked(event, rowId)}
						getID={getID}
						isChild={isChild}
					/>
				</TableRow>

				{hasCollapse &&
					collapse &&
					row[collapseFieldName].map(
						(c: any, childrenIndex: number) => (
							<>
								<SBTableRow
									collapseFieldName={collapseFieldName}
									row={c}
									striped={false}
									formTableIndex={formTableIndex}
									index={childrenIndex}
									oneDimensionColumns={oneDimensionColumns}
									selectable={selectable}
									actionable={actionable}
									isSelected={isSelected}
									isModified={isModified}
									onClick={onClick}
									onChecked={onChecked}
									handleEditableChange={handleEditableChange}
									getID={getID}
									rowRef={rowRef}
									className={
										c[collapseFieldName] ? "bordered" : ""
									}
									isChild
									childClassName={
										row[collapseFieldName].length - 1 ===
											childrenIndex
											? "bordered"
											: ""
									}
								/>
							</>
						)
					)}
			</>
		);
	}
);

export interface SBTableRowProps {
	collapseFieldName: string | null;
	row: any;
	formTableIndex: number;
	index: number;
	oneDimensionColumns: any;
	handleEditableChange: any;
	selectable: boolean;
	actionable: boolean;
	isSelected: (id: string | number) => boolean;
	isModified: (id: string | number) => boolean;
	onClick: (event?: any, id?: string | number) => any;
	onChecked: (event?: any, id?: string | number) => any;
	getID: (event?: any) => number | string;
	rowRef?: any;
	isChild?: boolean;
	striped?: boolean;
	className?: any;
	sriped?: boolean;
	childClassName?: string;
}
