import { SBBox, SBTypography } from "modules/Commons";
import checked from "assets/checked.svg";
import { SBTranslate, SBTranslateType } from "i18n";

export default ({ text }: ConfigUploadFinishedStateProps) => {
	return (
		<SBBox
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			width="50%"
			height="100%"
		>
			<img
				height="125px"
				style={{ margin: "4px 0px 8px" }}
				src={checked}
				alt=""
			/>
			<SBTypography color="darkPurple.main" variant="body1" fontSize="1rem" fontWeight="700" mt={2}>
				{SBTranslate(text)}
			</SBTypography>
		</SBBox>
	);
};

interface ConfigUploadFinishedStateProps {
	text: SBTranslateType;
}