/* eslint-disable import/no-anonymous-default-export */
import { SBTranslate } from "i18n";
import { SBBox, SBGrid } from "modules/Commons";
import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TipsContext } from "views/ConfigsView/ConfigTips";
import ConfigGroupmentDivider from "../ConfigGroupmentDivider";
import ConfigTextField from "../ConfigTextField";

export default ({ fetchPropError }: any) => {
	const maxCSLValue = 100;
	const { getValues, setValue } = useFormContext();
	const tipsContext = useContext(TipsContext);
	const percentageText = <>%</>;
	const multiplyerAdornment = <>x</>;




	const [forecast, setForecast] = useState<number | "">(
		getValues("forecastAdjustment") ? getValues("forecastAdjustment") : ""
	);
	const [virtualStock, setVirtualStock] = useState<number | "">(
		getValues("virtualStock") ? +(getValues("virtualStock") * 100).toFixed(0) : ""
	);
	const [virtualStockPeriod, setVirtualStockPeriod] = useState<number | "">(
		getValues("virtualStockPeriod") ? getValues("virtualStockPeriod") : ""
	);
	const [csl, setCSL] = useState<number | "">(
		getValues("csl") ? +(getValues("csl") * 100).toFixed(0) : ""
	);
	const [minCSL, setMinCSL] = useState<number | "">(
		getValues("minCSL") ? +(getValues("minCSL") * 100).toFixed(0) : ""
	);
	const [maxCSL, setMaxCSL] = useState<number | "">(
		getValues("maxCSL") ? +(getValues("maxCSL") * 100).toFixed(0) : ""
	);


	const [invertedCSL, setInvertedCSL] = useState<number | "">(
		getValues("csl") ? maxCSLValue - +csl : ""
	);
	const [invertedMinCSL, setInvertedMinCSL] = useState<number | "">(
		getValues("minCSL") ? maxCSLValue - +minCSL : ""
	);
	const [invertedMaxCSL, setInvertedMaxCSL] = useState<number | "">(
		getValues("maxCSL") ? maxCSLValue - +maxCSL : ""
	);

	const updateInvertedCSL = (value: string) =>
		verifyLimit(value, maxCSLValue, setInvertedCSL, setCSL);
	const updateInvertedMinCSL = (value: string) =>
		verifyLimit(value, maxCSLValue, setInvertedMinCSL, setMinCSL);
	const updateInvertedMaxCSL = (value: string) =>
		verifyLimit(value, maxCSLValue, setInvertedMaxCSL, setMaxCSL);

	const updateCSL = (value: string) =>
		verifyLimit(value, maxCSLValue, setCSL, setInvertedCSL);
	const updateMinCSL = (value: string) =>
		verifyLimit(value, maxCSLValue, setMinCSL, setInvertedMinCSL);
	const updateMaxCSL = (value: string) =>
		verifyLimit(value, maxCSLValue, setMaxCSL, setInvertedMaxCSL);
	const updateForecast = (value: string) =>
		setForecast(value.positiveNumber());
	const updateVirtualStock = (value: string) =>
		setVirtualStock(value.positiveNumber());
	const updateVirtualStockPeriod = (value: string) =>
		setVirtualStockPeriod(value.positiveNumber());


	const verifyLimit = (
		value: string,
		maxValue: number,
		func: any,
		oppositeFunc: any
	) => {
		if (!value) {
			func("");
			oppositeFunc("");
			return;
		}

		const v = Number(value.naturalNumber());
		if (v > maxValue) {
			func(maxValue);
			oppositeFunc(0);
		} else {
			func(v);
			oppositeFunc(maxValue - v);
		}
	};

	useEffect(() => {
		setValue("forecastAdjustment", forecast);
		setValue("virtualStock", virtualStock !== null && virtualStock !== "" ? virtualStock / 100 : null);
		setValue("virtualStockPeriod", virtualStockPeriod);
		setValue("csl", csl !== null && csl !== "" ? csl / 100 : null);
		setValue(
			"minCSL",
			minCSL !== null && minCSL !== "" ? minCSL / 100 : null
		);
		setValue(
			"maxCSL",
			maxCSL !== null && maxCSL !== "" ? maxCSL / 100 : null
		);
	}, [
		csl,
		minCSL,
		maxCSL,
		invertedCSL,
		invertedMinCSL,
		invertedMaxCSL,
		forecast,
		virtualStock,
		virtualStockPeriod
	]);

	return (
		<SBBox>
			<ConfigGroupmentDivider title={SBTranslate("stockNotRunningOut")} />
			<SBGrid container spacing={4}>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="csl"
						title={SBTranslate("target")}
						type="number"
						value={csl}
						onClick={() => tipsContext.showTip("CSLCSL")}
						onChange={(e) =>
							updateCSL(e.target.value.naturalNumber())
						}
						endAdornment={percentageText}
						helperText={fetchPropError("csl")}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="csl"
						title={SBTranslate("excess")}
						type="number"
						value={maxCSL}
						onClick={() => {
							tipsContext.showTip("CSLMaxCSL");
						}}
						onChange={(e) =>
							updateMaxCSL(e.target.value.naturalNumber())
						}
						endAdornment={percentageText}
						helperText={fetchPropError("maxCSL")}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="csl"
						value={minCSL}
						type="number"
						onClick={() => {
							tipsContext.showTip("CSLMinCSL");
						}}
						onChange={(e) =>
							updateMinCSL(e.target.value.naturalNumber())
						}
						endAdornment={percentageText}
						title={SBTranslate("scarcity")}
						helperText={fetchPropError("minCSL")}
					/>
				</SBGrid>
			</SBGrid>
			<ConfigGroupmentDivider title={SBTranslate("stockRunningOut")} />
			<SBGrid container spacing={4}>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="csl"
						title={SBTranslate("target")}
						type="number"
						endAdornment={percentageText}
						value={invertedCSL}
						onClick={() => {
							tipsContext.showTip("CSLInvertedCSL");
						}}
						onChange={(e) =>
							updateInvertedCSL(e.target.value.naturalNumber())
						}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="csl"
						title={SBTranslate("excess")}
						type="number"
						endAdornment={percentageText}
						value={invertedMaxCSL}
						onClick={() => {
							tipsContext.showTip("CSLInvertedMaxCSL");
						}}
						onChange={(e) =>
							updateInvertedMaxCSL(e.target.value.naturalNumber())
						}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="csl"
						title={SBTranslate("scarcity")}
						type="number"
						endAdornment={percentageText}
						value={invertedMinCSL}
						onClick={() => {
							tipsContext.showTip("CSLInvertedMinCSL");
						}}
						onChange={(e) =>
							updateInvertedMinCSL(e.target.value.naturalNumber())
						}
					/>
				</SBGrid>
			</SBGrid>
			<ConfigGroupmentDivider title={SBTranslate("forecastAjustment")} />
			<SBGrid container spacing={4}>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="CSL"
						type="number"
						title={SBTranslate("commons.forecast")}
						endAdornment={multiplyerAdornment}
						value={forecast}
						onChange={(e) => updateForecast(e.target.value)}
						onClick={() => {
							tipsContext.showTip("CSLForecastAjustment");
						}}
					/>
				</SBGrid>
			</SBGrid>
			<ConfigGroupmentDivider title={SBTranslate("demandAnomalyDetection")} />
			<SBGrid container spacing={4}>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="csl"
						title={SBTranslate("limit")}
						value={virtualStock}
						type="number"
						onClick={() => tipsContext.showTip(("CSLVirtualStock"))}
						onChange={(e) => updateVirtualStock(e.target.value.naturalNumber())}
						endAdornment={percentageText}
						helperText={fetchPropError("virtualStock")}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="csl"
						title={SBTranslate("period")}
						value={virtualStockPeriod}
						type="number"
						onClick={() => tipsContext.showTip(("CSLVirtualStockPeriod"))}
						onChange={(e) => updateVirtualStockPeriod(e.target.value.naturalNumber())}
						endAdornment={SBTranslate("days")}

					/>
				</SBGrid>
			</SBGrid>
		</SBBox>
	);
};
