import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	modalContent: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
}));
