import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";
import { useStyles } from "./style";

/** Common Checkbox */
export default (props: SBCheckboxProps) => {
	const classes = useStyles();

	const defaultWrapper = (component: JSX.Element) => component;

	const formControlWrapper = (component: JSX.Element) => (
		<FormControlLabel control={component} label={props.label!} />
	);

	const label = props.label ? formControlWrapper : defaultWrapper;

	return label(
		<Checkbox
			data-testid="sb-checkbox"
			className={classes.sbCheckbox}
			color="default"
			{...props}
		/>
	);
};

export interface SBCheckboxProps extends CheckboxProps {
	/** Label to fill the form control*/
	label?: string;
	disabled?: boolean;
}
