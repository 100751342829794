import { rankItem } from "@tanstack/match-sorter-utils";
import { useState } from "react";
import { SBTableColumn } from "../../models/TableColumn";
import { ColumnFiltersState, FilterFn } from "@tanstack/react-table";

export default <T>({
	defaultColumns,
	defaultFilter,
}: useTableFilterProps<T>) => {
	const [globalFilter, setGlobalFilter] = useState("");
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
		defaultFilter ?? []
	);
	const [externalFilter, setExternalFilter] = useState("");

	const filterFunction = (...props: any) => {
		const [row, columnId, filteredValue, addMeta] = props;
		const value = row.getValue(columnId);
		const filters = filteredValue
			.split("|")
			.filter((f: any) => f && f.length > 0);
		const filtersFlags: boolean[] = [];
		const formatter = defaultColumns!
			.map(
				(c) =>
					c.headers!.find((d: any) => d.key === columnId)?.formatter
			)
			.filter((c) => !!c)[0];

		const realValue = formatter && formatter(value as never);

		if (filters.length) {
			filters.forEach((filter: any) => {
				filtersFlags.push(
					fuzzyFilter(realValue, value, filter, addMeta)
				);
			});
		} else {
			return true;
		}

		return filtersFlags.includes(true);
	};

	const fuzzyFilter: any = (
		formattedValue: any,
		value: any,
		filterValue: any,
		addMeta: any
	) => {
		const itemRank = rankItem(value, filterValue);
		const formattedItemRank = rankItem(formattedValue, filterValue);
		addMeta({ itemRank });
		addMeta({ itemRank: formattedItemRank });

		return itemRank.passed || formattedItemRank.passed;
	};

	const rowFuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
		const itemRank = rankItem(row.getValue(columnId), value);

		addMeta({ itemRank });

		return itemRank.passed;
	};

	return {
		filter: `${externalFilter}|${globalFilter}`,
		setGlobalFilter,
		setExternalFilter,
		filterFunction,
		rowFuzzyFilter,
		fuzzyFilter,
		columnFilters,
		setColumnFilters,
	};
};

interface useTableFilterProps<T> {
	defaultColumns: SBTableColumn<T>[];
	defaultFilter?: { id: string; value: any }[];
}
