import { IGroupedStatistics } from "domain/Statistic/Models/IGroupedStatistics";
import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import { SBTranslate } from "i18n";
import { SBBox } from "modules/Commons";
import SBGroupedStatisticsCard from "modules/Commons/SBGroupedStatisticsCard";
import { useCallback, useMemo } from "react";
import {
	formatCurrencyShort,
	formatIntegerShort
} from "utils/formatters";

export default ({ statistics, isCost }: SBCurrentStatusInfoProps) => {
	const usingFormatter = useCallback(() => {
		return isCost ? formatCurrencyShort : formatIntegerShort;
	}, [isCost]);

	const chooseUnit = useMemo(() => {
		return !isCost ? SBTranslate("unit") : "";
	}, [isCost])

	const items = useMemo(() => {
		const status = statistics?.currentStatus;

		const totalNeeded = !isCost
			? status?.totalNeeded
			: status?.totalNeededCost;

		const totalExcess = !isCost
			? status?.totalExcess
			: status?.totalExcessCost;

		return [
			{
				label: SBTranslate("needed"),
				value: totalNeeded?.sum?.value,
				unit: chooseUnit,
				tootltip: SBTranslate("tips.needed"),
				formatter: usingFormatter(),
				subItems: [
					{
						label: `${SBTranslate("min")}:`,
						value: totalNeeded?.min?.value,
						unit: chooseUnit,
					},
					{
						label: `${SBTranslate("max")}:`,
						value: totalNeeded?.max?.value,
						unit: chooseUnit,
					}
				],
			},
			{
				label: SBTranslate("excess"),
				value: totalExcess?.sum?.value,
				unit: chooseUnit,
				tootltip: SBTranslate("tips.excess"),
				formatter: usingFormatter(),
				subItems: [
					{
						label: `${SBTranslate("min")}:`,
						value: totalExcess?.min?.value,
						unit: chooseUnit,
					},
					{
						label: `${SBTranslate("max")}:`,
						value: totalExcess?.max?.value,
						unit: chooseUnit,
					}
				],
			},
		] as IGroupedStatistics[];
	}, [isCost]);

	return <SBGroupedStatisticsCard items={items} />

};

interface SBCurrentStatusInfoProps {
	statistics: IHomeStatistics;
	isCost: boolean;
}
