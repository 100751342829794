import { IInventoryStatisticsSummary } from "domain/Inventory/Models/IInventoryStatisticsSummary";
import { SBBox, SBIcon, SBTypography } from "modules/Commons";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";

export default ({ statistics }: SBInventoryStatisticsWarningProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const filteredWarnings = useMemo(
		() =>
			statistics.warning?.filter(
				(w) => w == "MPE" || w == "MAPE" || w == "CVUnstable"
			),
		[statistics]
	);

	const needToShow = useMemo(
		() => filteredWarnings?.length > 0,
		[statistics]
	);

	if (!needToShow) return <></>;

	return (
		<SBBox className={classes.container} p={1} mb={2}>
			<SBBox display="flex" flexDirection="column">
				<SBBox display="flex" mb={0.5}>
					<SBIcon icon="alert-triangle" color="error" size={16} />
					<SBTypography
						pl={0.5}
						color="error.main"
						textTransform="uppercase"
						fontWeight="bold"
						variant="body2"
					>
						{t("alert")}
					</SBTypography>
				</SBBox>
				{filteredWarnings.map((c: string) => (
					<SBTypography key={c} pl={1} fontWeight="bold">
						- {t("error" + c)}
					</SBTypography>
				))}
			</SBBox>
		</SBBox>
	);
};
interface SBInventoryStatisticsWarningProps {
	statistics: IInventoryStatisticsSummary;
}
