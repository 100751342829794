const Environment = {
	IsDevelopment: process.env.NODE_ENV === "development",
	API: process.env.REACT_APP_API!,
	SignalRAPI: process.env.REACT_APP_SIGNALR_API!,
	Authority: process.env.REACT_APP_AUTHORITY!,
	AuthoritySecret: process.env.REACT_APP_AUTHORITY_SECRET!,
	AuthorityClientId: process.env.REACT_APP_AUTHORITY_CLIENTID!,
	AuthorityGrantType: process.env.REACT_APP_AUTHORITY_GRANT_TYPE!,
	AuthorityScope: process.env.REACT_APP_AUTHORITY_SCOPE!,
	AuthoritySignIn: process.env.REACT_APP_AUTHORITY_SIGN_IN!,
	MixpanelKey: process.env.REACT_APP_MIXPANEL_KEY!,
};

export default {
	...Environment,
	LoginURL: `${Environment.Authority}${Environment.AuthoritySignIn}`,
};
