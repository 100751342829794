import { SBBox, SBCard, SBTypography } from "modules/Commons";
import SBProgress from "modules/Commons/SBDataDisplays/SBProgress";
import { useTranslation } from "react-i18next";

export default ({ exploration }: ExplorationCSLInfoProps) => {
	const { t } = useTranslation();
	return (
		<SBCard>
			<SBBox px={3}>
				<SBTypography fontWeight="bold" pt={3} pb={1}>
					{t("csl")}
				</SBTypography>
				<SBBox pb={3}>
					<SBProgress
						title={t("actual")}
						value={exploration?.initPosition?.csl?.value * 100}
						color="darkPurple"
					/>
				</SBBox>
				<SBBox pb={3}>
					<SBProgress
						title={t("configured")}
						value={
							exploration?.inventoryConfig?.csl?.avg?.value * 100
						}
						color="primary"
					/>
				</SBBox>
				<SBBox pb={3}>
					<SBProgress
						title={t("optimal")}
						value={exploration?.optimalCSL?.value * 100}
						color="blue2"
					/>
				</SBBox>
			</SBBox>
		</SBCard>
	);
};

export interface ExplorationCSLInfoProps {
	exploration: any;
}
