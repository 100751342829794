import { SBBox, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";
import loading from "assets/loading_process.gif";

export default () => {
	const { t } = useTranslation();
	return (
		<SBBox
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
		>
			<img
				height="40px"
				style={{ margin: "4px 0px 8px" }}
				src={loading}
				alt=""
			/>
			<SBTypography color="#4F4F4F" variant="body1" fontWeight="400">
				{t("transferLoadingWarning")}
			</SBTypography>
		</SBBox>
	);
};
