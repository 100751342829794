import { Chip, FormControl, InputLabel, Select, Tooltip } from "@mui/material";
import { useStyles } from "./style";
import { ReactNode } from "react";
import { SBBox, SBTypography, SBMenuItem } from "modules/Commons";
import clsx from "clsx";

export default (
	props: Partial<{
		items: any;
		value: any;
		fullWidth: boolean;
		onChange: (value: { target: { value: string } }) => any;
		getValue: (c: any) => any;
		getText: (value: any) => any;
		style: {};
		defaultValue: string;
		label: string;
		maxHeight: string;
		multiple: boolean;
		disabled: boolean;
		placeholder: string;
		className: any;
		itemRenderer: (item: any) => ReactNode;
		startAdornment: (item: any) => any;
	}>
) => {
	const classes = useStyles();
	const {
		items,
		value,
		label,
		onChange = (c: any) => c,
		getValue = (c: any) => c,
		getText = (c: any) => c,
		style,
		defaultValue = "",
		maxHeight = 300,
		multiple,
		disabled,
		fullWidth = false,
		placeholder,
		itemRenderer,
		startAdornment = null,
		className,
	} = props;

	return (
		<FormControl fullWidth={fullWidth}>
			<InputLabel
				id="sb-simple-select-label"
				style={{ top: value ? 0 : -7 }}
			>
				{label}
			</InputLabel>
			<Select
				labelId="sb-simple-select-label"
				disabled={disabled}
				multiple={multiple}
				className={clsx(classes.sbSelect, className)}
				value={value || ""}
				size="small"
				onChange={onChange}
				placeholder={placeholder}
				data-testid="sb-select"
				defaultValue={defaultValue}
				label={label}
				style={{ backgroundColor: "white", padding: 0, ...style }}
				MenuProps={{ style: { maxHeight, padding: 0 } }}
				renderValue={
					multiple
						? (selected: any) => (
								<SBBox
									sx={{
										display: "flex",
										flexWrap: "wrap",
										gap: 0.5,
									}}
								>
									{selected.map((value: any) => (
										<Chip
											size="small"
											key={getValue(value)}
											label={
												<Tooltip title="delete">
													<SBTypography
														noWrap
														style={{ maxWidth: 80 }}
													>
														{getText(value)}
													</SBTypography>
												</Tooltip>
											}
										></Chip>
									))}
								</SBBox>
						  )
						: undefined
				}
			>
				{items.map((item: any) => {
					return !itemRenderer ? (
						<SBMenuItem
							key={getText(item)}
							value={getValue(item)}
							data-testid="sb-select-item"
							style={{ zIndex: 100 }}
						>
							<SBBox display="flex">
								{startAdornment && (
									<SBBox mr={1.5}>
										{startAdornment(item)}
									</SBBox>
								)}
								<SBTypography noWrap style={{ maxWidth: 350 }}>
									{getText(item)}
								</SBTypography>
							</SBBox>
						</SBMenuItem>
					) : (
						itemRenderer({ ...item })
					);
				})}
			</Select>
		</FormControl>
	);
};
