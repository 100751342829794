import { SBChip } from "modules/Commons"

export default (({ stack }: NotificationCardStack) => {
    return stack && stack > 1 ?
        <SBChip
            label={stack < 100 ? `${stack}` : "+99"}
            variant="filled"
            color="lightPurple"
        /> : <></>
})

interface NotificationCardStack {
    stack?: number
}