import { IEntity } from "domain/Domain/Entities/IEntity";
import { FilterType, IComposedFilter } from "domain/Utils";

export default () => {
	const fromItems = (filter: {
		products: IEntity[];
		stores: IEntity[];
		categories: IEntity[];
	}) => {
		const products = filter.products?.map((p: any) => ({
			...p,
			icon: "package",
			color: "primary",
			type: FilterType.Product,
		}));
		const categories = filter.categories?.map((p: any) => ({
			...p,
			icon: "bookmark",
			color: "secondary",
			type: FilterType.Category,
		}));

		const stores = filter.stores?.map((p: any) => ({
			...p,
			icon: "home",
			color: "darkPurple",
			type: FilterType.Store,
		}));

		return {
			products,
			categories,
			stores,
			items: [...products, ...categories, ...stores],
		};
	};

	const toItems = (composedFilter: IComposedFilter) => {
		const products =
			composedFilter.filter
				?.filter((c) => c.type === FilterType.Product)
				?.map((p) => ({
					...p,
					icon: "package",
					color: "primary",
					type: FilterType.Product,
				})) ?? [];
		const categories =
			composedFilter.filter
				?.filter((c) => c.type === FilterType.Category)
				?.map((p: any) => ({
					...p,
					icon: "bookmark",
					color: "secondary",
					type: FilterType.Category,
				})) ?? [];

		const stores =
			composedFilter.filter
				?.filter((c) => c.type === FilterType.Store)
				?.map((p: any) => ({
					...p,
					icon: "home",
					color: "darkPurple",
					type: FilterType.Store,
				})) ?? [];

		return {
			...composedFilter,
			products,
			categories,
			stores,
			items: [...products, ...categories, ...stores],
		};
	};

	return {
		fromItems,
		toItems,
	};
};
