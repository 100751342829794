/**
 * Func to return a new array with the modified value passed
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, "triggerChange", {
	value: function ({
		condition = (l) => true,
		newValue,
		beforeFind = (l) => false,
		afterFind = (l) => false,
		children = null,
	}) {
		return this.map((l, index) => {
			if (children && Array.isArray(l[children])) {
				return {
					...l,
					[children]: l[children].triggerChange({
						condition,
						newValue,
						beforeFind,
						afterFind,
					}),
				};
			} else if (condition(l, index)) {
				beforeFind(l);
				const value = { ...l, ...newValue(l) };
				afterFind(value);
				return value;
			}
			return l;
		});
	},
});

Object.defineProperty(Number.prototype, "add", {
	value: function ({ replace, value }) {
		if (!replace) return this + value;
		return value;
	},
});

/**
 * Func to return a new array with or without the item selected! will toggle the existence
 * of the item inside the array
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, "toggleItem", {
	value: function (value) {
		const selectedIndex = this.indexOf(value);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(this, value);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(this.slice(1));
		} else if (selectedIndex === this.length - 1) {
			newSelected = newSelected.concat(this.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				this.slice(0, selectedIndex),
				this.slice(selectedIndex + 1)
			);
		}
		return newSelected;
	},
});

/**
 * Func to search inside all props of the object for a value
 * of the item inside the array
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Object.prototype, "hasAny", {
	value: function (search, { deepSearch }) {
		if (search === "") return true;

		const lowerCaseSearch = search.toLowerCase();

		return Object.keys(this).some((c) => {
			if (deepSearch && Array.isArray(this[c]))
				if (this[c].some((d) => d.hasAny(search, { deepSearch })))
					return true;

			if (deepSearch && this[c] && typeof this[c] === "object") {
				if (
					Object.keys(this[c]).some((d) => {
						const value = this[c][d];
						if (value) return value.hasAny(search, { deepSearch });
						return false;
					})
				)
					return true;
			}

			return (
				this[c] &&
				this[c].toString().toLowerCase().indexOf(lowerCaseSearch) >= 0
			);
		});
	},
});

/**
 * Func to search inside all props of the object for a value
 * of the item inside the array
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, "flatten", {
	value: function () {
		const flat = [];

		this.forEach((item) => {
			flat.push(item);
			Object.keys(item).forEach((subItem) => {
				if (Array.isArray(item[subItem])) {
					flat.push(...item[subItem].flatten());
				}
			});
		});

		return flat;
	},
});

/**
 * Func to group a array by a getter function
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, "groupBy", {
	value: function (getter) {
		return this.reduce((rv, x) => {
			(rv[getter(x)] = rv[getter(x)] || []).push(x);
			return rv;
		}, {});
	},
});

/**
 * Func to remove a property of object
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Object.prototype, "except", {
	value: function (exceptionKey) {
		return Object.keys(this).reduce((acc, key) => {
			if (key !== exceptionKey) {
				acc[key] = this[key];
			}
			return acc;
		}, {});
	},
});

/**
 * Func to remove a property of object
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Number.prototype, "isBetween", {
	value: function ({
		start = null,
		end = null,
		exclusiveStart = false,
		exclusiveEnd = false,
	}) {
		const minCondition = exclusiveStart ? start <= this : start < this;
		const maxCondition = exclusiveEnd ? end >= this : end > this;

		if (start != null && end != null) return minCondition && maxCondition;
		if (start != null) return minCondition;
		if (end != null) return maxCondition;
	},
});

/**
 * Func to return only natural numbers into an string
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, "naturalNumber", {
	value: function () {
		// if (!!String(this)) {
		// 	if (!withoutZero && Number(this) < 0) return "0";
		// 	else if (withoutZero && Number(this) < 1) return "1";
		return String(this.replace(/\D/g, ""));
		// }
		// return "";
	},
});
/**
 * Func to return only positive natural numbers into an string
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, "positiveNumber", {
	value: function () {
		return String(this.replace(/[^\d,.]/g, ""));
	},
});

/**
 * Func to capitalize string
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, "capitalize", {
	value: function () {
		return (
			this[0].toUpperCase() + this.substring(1, this.length).toLowerCase()
		);
	},
});

/**
 * Func to read a number from an input and return value limited in maximum
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, "limitedNumber", {
	value: function ({ final = null }) {
		if (!!String(this)) {
			if (String(this) !== "-") {
				if (final && Number(this) > final) return final.toString();
			}
			return String(this);
		}
		return "";
	},
});

/**
 * Get only distinct items on array
 */
Object.defineProperty(Array.prototype, "distinct", {
	value: function () {
		return this.filter((value, index, self) => {
			return self.indexOf(value) === index;
		});
	},
});

/**
 * Func to Round the number to the nearest thousand
 * Ex: 	299 	-> 0 k
 * 		888 	-> 1 k
 * 		29999	-> 30 k
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Number.prototype, "nearestThousand", {
	value: function ({ maximumFractionDigits = 0 }) {
		if (Math.abs(this) >= 1000) {
			let value = this / 1000;

			value = Math.round(value);

			return `${value.toLocaleString("pt-BR", {
				maximumFractionDigits,
			})} k`;
		} else {
			return `${this.toLocaleString("pt-BR", {
				maximumFractionDigits,
			})}`;
		}
	},
});

/**
 * Check if object is valid
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Object.prototype, "isValid", {
	value: function () {
		return this !== null && this !== undefined && this !== "";
	},
});

/**
 * Check if object is valid
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Number.prototype, "systemFormat", {
	value: function (props) {
		return this.toLocaleString("pt-BR", props);
	},
});

/**
 * Check if object is valid
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Number.prototype, "systemFormatAsPercentage", {
	value: function (props) {
		return this.toLocaleString("pt-BR", {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
			...props,
		});
	},
});

Object.defineProperty(Number.prototype, "systemFormatAsInteger", {
	value: function (props) {
		return this.toLocaleString("pt-BR", {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
			...props,
		});
	},
});

Object.defineProperty(Number.prototype, "systemFormatAsMoney", {
	value: function (props) {
		return this.toLocaleString("pt-BR", {
			...props,
			style: "currency",
			currency: "BRL",
		});
	},
});

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, "isYearInput", {
	value: function () {
		let value = this;
		if (value.length > 4) {
			return String(value.slice(0, 4));
		}
		return String(value);
	},
});

const flattenAsArray = (obj, objKeys = []) => {
	let flattenObj = [];
	Object.keys(obj).forEach((key) => {
		if (typeof obj[key] === "object")
			flattenObj = [
				...flattenObj,
				...flattenAsArray(obj[key], [...objKeys, key]),
			];
		else if (typeof obj[key] === "string" || typeof obj[key] === "number")
			flattenObj.push({ keys: objKeys, value: obj[key] });
	});
	return flattenObj;
};

// eslint-disable-next-line no-extend-native
Object.defineProperty(Object.prototype, "flatAsArray", {
	value: function () {
		return flattenAsArray(this);
	},
});
