import { SBTranslate, SBTranslateType } from "i18n";
import { NotificationsTypeEnum } from "../Enums/Notificationtype";
import { INotification } from "../models/INotification";
import Notification, { TranslatedNotification } from "../models/Notification";
import { DateTime } from "luxon";
import routerHistory from "hooks/useRouterHistory";
import { FilterType, StorageKey } from "domain/Utils";
import { useSystemFilterContext } from "contexts/SystemFilterContext";
import useRememberMe from "hooks/useRememberMe";

const NotificationFactory = () => {
	const {
		setBuildContextSwitch,
		setClearContextFilter,
		getLastDataBaseUpdate,
	} = useSystemFilterContext();
	const changeRoute = (route: string) => {
		routerHistory.push(route);
		setBuildContextSwitch(true);
	};
	const { storage } = useRememberMe();
	const notifications = new Map<
		NotificationsTypeEnum,
		(notification: INotification) => TranslatedNotification
	>([
		[
			NotificationsTypeEnum.cacheStarted,
			(notification: INotification) => {
				let products = [],
					stores = [],
					categories = [],
					context = "";
				try {
					const {
						products: p,
						stores: s,
						categories: c,
						context: ctx,
					} = JSON.parse(notification.parameters as string);
					products = p || [];
					stores = s || [];
					categories = c || [];
					context = ctx || "";
				} catch (error) {
					console.error(
						"Error parsing notification parameters:",
						error
					);
				}

				const hasAny = (arr: string[]): boolean => arr.length > 0;
				const selectExpression = (
					arr: string[],
					key: SBTranslateType
				) =>
					hasAny(arr)
						? `${SBTranslate(key, {
								count: arr.length,
						  })} <b>${arr.join(", ")}</b>`
						: "";

				const filter = {
					products: selectExpression(products, FilterType.Product),
					stores: selectExpression(stores, FilterType.Store),
					categories: selectExpression(
						categories,
						FilterType.Category
					),
				};

				const parts = [
					filter.products,
					filter.stores,
					filter.categories,
				]
					.filter((el) => el !== "")
					.join(` ${SBTranslate("And")} `);
				const expression =
					parts !== "" ? `${SBTranslate("for")} ${parts}` : "";

				return Notification({
					dto: notification,
					titleKey: "notification.CacheWarmupStartedTitle",
					descriptionKey:
						"notification.CacheWarmupStartedDescription",
					parameters: { expression },
				});
			},
		],

		[
			NotificationsTypeEnum.newRecommendation,
			(notification: INotification) => {
				const { products, stores, categories, context } = JSON.parse(
					notification.parameters as string
				);
				const defaultRoute = "/purchase/recomendation";

				const hasAny = (arr: string[]): boolean =>
					arr && arr.length > 0;
				const selectExpression = (
					arr: string[],
					key: SBTranslateType
				) =>
					hasAny(arr)
						? `${SBTranslate(key, {
								count: arr.length,
						  })} <b>${arr.join(", ")}</b>`
						: "";

				const filter = {
					products: selectExpression(products, FilterType.Product),
					stores: selectExpression(stores, FilterType.Store),
					categories: selectExpression(
						categories,
						FilterType.Category
					),
				};

				const parts = [
					filter.products,
					filter.stores,
					filter.categories,
				]
					.filter((el) => el !== "")
					.join(` ${SBTranslate("And")} `);
				const expression =
					parts !== "" ? `${SBTranslate("for")} ${parts}` : "";

				const selectRouter = (arr: string[], key: string) =>
					hasAny(arr) ? `${key}=${arr.join(`&${key}=`)}` : "";
				const routerFilter = {
					products: selectRouter(products, FilterType.Product),
					stores: selectRouter(stores, FilterType.Store),
					categories: selectRouter(categories, FilterType.Category),
				};

				const routerParts = [
					routerFilter.products,
					routerFilter.stores,
					routerFilter.categories,
				]
					.filter((el) => el !== "")
					.join("&");
				const contextExpression = DateTime.fromISO(context).toISODate();
				const routerExpression =
					routerParts !== ""
						? `${defaultRoute}?context=${contextExpression}&${routerParts}`
						: `${defaultRoute}?context=${contextExpression}`;
				return Notification({
					dto: notification,
					titleKey: "notification.newRecommendationTitle",
					descriptionKey: "notification.newRecommendation",
					actionTextKey: "notification.newRecommendationButton",
					parameters: { expression: expression },
					action: () => {
						storage().removeItem(StorageKey.SBFilters);
						storage().setItem(
							StorageKey.SBDate,
							JSON.stringify(context)
						);
						changeRoute(routerExpression);
					},
				});
			},
		],
		[
			NotificationsTypeEnum.dataUpdated,
			(notification: INotification) =>
				Notification({
					dto: notification,
					titleKey: "notification.dataUpdatedTitle",
					descriptionKey: "notification.dataUpdated",
					actionTextKey: "notification.dataUpdatedButton",
					parameters: {},
					action: async () => {
						await getLastDataBaseUpdate().then((date: string) => {
							storage().removeItem(StorageKey.SBFilters);
							storage().setItem(
								StorageKey.SBDate,
								JSON.stringify(date)
							);
							changeRoute(`/home?context=${date}`);
							setClearContextFilter(true);
						});
					},
				}),
		],
		[
			NotificationsTypeEnum.versionUpdate,
			(notification: INotification) =>
				Notification({
					dto: notification,
					titleKey: "notification.versionUpdateTitle",
					descriptionKey: "notification.versionUpdate",
					actionTextKey: "notification.versionUpdateButton",
					parameters: { version: notification.parameters as string },
					action: () => {},
				}),
		],
	]);

	const build = (notificationDTO: INotification) => {
		return notifications.get(notificationDTO.type)?.(notificationDTO);
	};

	return { build };
};

export default NotificationFactory;
