import { useRequest } from "hooks/useRequest";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { TransferencePlan } from "../models/transferencePlan";
import { TransferenceResponse } from "../models/transferenceResponse";

export const TransferencesRequester = (() => {
	const request = useRequest("Transference");

	const getTransferOrder = (
		itemsToPurchase: { sourceID?: string; plan: TransferencePlan }[]
	) => {
		const transferenceOrderCommand: {
			orders: {
				guid: string;
				sourceID?: string;
				destinationID: string;
				productClientID: string;
				quantity: number;
			}[];
		} = { orders: [] };

		itemsToPurchase.forEach((row) => {
			transferenceOrderCommand.orders.push({
				guid: row.plan.uuid,
				sourceID: row.sourceID,
				destinationID: row.plan.store.clientID,
				productClientID: row.plan.product.clientID,
				quantity: row.plan.amount,
			});
		});

		return transferenceOrderCommand;
	};

	return {
		createExternalOrder: ({
			transferencePlans,
			selectedItems,
		}: {
			transferencePlans: TransferencePlan[];
			selectedItems: string[];
		}) => {
			const itemsToPurchase: {
				sourceID?: string;
				plan: TransferencePlan;
			}[] = [];

			if (!!selectedItems && selectedItems.length > 0) {
				for (const transferencePlan of transferencePlans) {
					for (const plan of transferencePlan.plans) {
						if (selectedItems.indexOf(plan.uuid) >= 0) {
							itemsToPurchase.push({
								sourceID:
									transferencePlan.inventory.store.clientID,
								plan,
							});
						}
					}
				}
			}

			return request
				.POST("TransferenceOrder", getTransferOrder(itemsToPurchase))
				.then((result) => {
					return result.data as TransferenceResponse[];
				})
				.catch(() => {
					throw "Invalid Request to RP";
				});
		},
	};
})();
