export enum Months {
	January = "january",
	February = "february",
	March = "march",
	April = "april",
	May = "may",
	June = "june",
	July = "july",
	August = "august",
	September = "september",
	October = "october",
	November = "november",
	December = "december",
}

export const AllMonths = [
	Months.January,
	Months.February,
	Months.March,
	Months.April,
	Months.May,
	Months.June,
	Months.July,
	Months.August,
	Months.September,
	Months.October,
	Months.November,
	Months.December,
];
