import clsx from "clsx";
import useTableColumnBuilder from "hooks/useTableColumnBuilder";
import { SBTranslate } from "i18n";
import { SBBox, SBIcon, SBTooltip } from "modules/Commons";
import { TableCellType } from "modules/Commons/SBDataDisplays/SBTable/SBTableCells/tableCellType";
import { useTranslation } from "react-i18next";
import { useSystemTheme } from "themes/base";
import { deviationAggregator } from "utils/aggregators";
import { IPurchasePlan } from "./IPurchasePlans";
import { useStyles } from "./style";
import { formatInteger } from "utils/formatters";
import { rankItem } from "@tanstack/match-sorter-utils";
import { FilterFn } from "@tanstack/react-table";

export const usePurchaseHeaders = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const theme = useSystemTheme();

	const {
		add,
		build,
		percentageNoDecimalFormatter,
		systemFormatter,
		dateFormatter,
		integerFormatter,
		decimalFormatter,
	} = useTableColumnBuilder<IPurchasePlan>();

	add({
		id: () => "alerts",
		name: "alerts",
		hideToggle: true,
		headers: [
			{
				id: (c) => (
					<SBBox className={clsx(classes.inventoryIconBox)}>
						<SBIcon icon="inventory" hoverFillColor="primary" />
					</SBBox>
				),
				csvName: `${SBTranslate("inventory")}`,
				align: "center",
				csvHide: true,
				width: 1,
				key: "inventory",
				name: "",
				fixed: true,
			},
			{
				id: (e) => {
					const isValid = !(e.warning.indexOf("MPE") >= 0 || e.warning.indexOf("MAPE") >= 0 || e.warning?.indexOf("CVUnstable") >= 0);

					return (
						<div style={{ paddingLeft: 7 }}>
							{isValid ? (
								<SBTooltip
									title={SBTranslate("correctQualityParametersTip")}
								>
									<SBIcon icon={"check"} color={"green"} />
								</SBTooltip>
							) : (
								<SBTooltip
									title={SBTranslate("wrongQualityParametersTip")}
								>
									<SBIcon icon={"sirene"} fillColor={"red"} />
								</SBTooltip>
							)}
						</div>
					);
				},
				width: 10,
				key: "safe",
				name: "",
				align: "left",
				tip: "qualityParametersTip",
				csvHide: true,
			},
			{
				id: (c) =>
					c.cycle?.isEmergencial && (
						<SBTooltip title={SBTranslate("emergencialRecommendation")}>
							<SBIcon icon="alert-triangle" color="red" />
						</SBTooltip>
					),
				csvName: `${SBTranslate("isEmergencial")}`,
				csvValue: (c) => (c.cycle?.isEmergencial ? SBTranslate("yes") : SBTranslate("no")),
				align: "center",
				width: 1,
				key: "isEmergencial",
				name: "",
				tip: "isEmergencialTip",
			},
			{
				id: (c) =>
					c.transferenceQuantity > 0 ? (
						<SBTooltip
							title={SBTranslate("transferenceAmount", {
								count: c.transferenceQuantity,
							})}
						>
							<SBIcon icon="truck" />
						</SBTooltip>
					) : (
						""
					),
				csvName: `${SBTranslate("transference")}`,
				csvValue: (c) => formatInteger(c.transferenceQuantity),
				align: "center",
				width: 1,
				key: "transference",
				name: "",
				tip: "transferenceTip",
			},
			{
				id: (c) => c.cycle?.isEmergencial,
				name: "",
				key: "emergencial",
				filterFn: (row: any, columnId: any, value: any, addMeta: any) => {
					const itemRank = rankItem(row.getValue(columnId), value);

					addMeta({ itemRank });

					return itemRank.passed;
				}
			},
		],
	});

	add({
		id: () => "product",
		name: "product",
		headers: [
			{
				id: (e) => e.inventory?.product?.clientID,
				key: "productCode",
				name: "code",
				csvName: `${SBTranslate("productCode")}`,
				color: () => `${theme.palette.primary.main}`,
				align: "left",
				noWrap: true,
				fixed: true,
				style: {
					fontWeight: "bold",
					color: `${theme.palette.primary.main}`,
				},
				tip: "productClientIDTip",
				enableGrouping: true,
				groupByTip: SBTranslate("tips.groupByProductClientID"),
			},
			{
				id: (e) => e.inventory.product.name,
				csvName: `${SBTranslate("productName")}`,
				key: "productName",
				noWrap: true,
				name: "name",
				align: "left",
				tip: "productNameTip",
			},
		],
	});
	add({
		id: () => "store",
		name: "store",
		headers: [
			{
				id: (e) => e.inventory?.store?.clientID,
				key: "storeCode",
				csvName: `${SBTranslate("storeCode")}`,
				name: "code",
				align: "left",
				fixed: true,
				style: {
					fontWeight: "bold",
					color: `${theme.palette.primary.main}`,
				},
				tip: "storeClientIDTip",
				enableGrouping: true,
				groupByTip: SBTranslate("tips.groupByStoreClientID"),
			},
			{
				id: (e) => e.inventory?.store?.name,
				key: "storeName",
				csvName: `${SBTranslate("storeName")}`,
				name: "name",
				align: "left",
				noWrap: true,
				tip: "storeNameTip",
			},
		],
	});
	add({
		id: () => "category",
		name: "category",
		headers: [
			{
				id: (e) => e.inventory?.product?.category?.clientID,
				key: "categoryCode",
				name: "code",
				csvName: `${SBTranslate("categoryCode")}`,
				color: () => `${theme.palette.primary.main}`,
				align: "left",
				noWrap: true,
				style: {
					fontWeight: "bold",
					color: `${theme.palette.primary.main}`,
				},
				tip: "categoryClientIDTip",
				enableGrouping: true,
				groupByTip: SBTranslate("tips.groupByCategoryClientID"),
			},
			{
				id: (e) => e.inventory?.product?.category?.name,
				csvName: `${SBTranslate("categoryName")}`,
				key: "categoryName",
				noWrap: true,
				name: "name",
				align: "left",
				tip: "categoryNameTip",
			},
		],
	});
	add({
		id: () => "supplier",
		name: "supplier",
		headers: [
			{
				id: (e) => e.sourceClientID,
				key: "supplierCode",
				name: "code",
				csvName: `${SBTranslate("supplierCode")}`,
				color: () => `${theme.palette.primary.main}`,
				align: "left",
				noWrap: true,
				style: {
					fontWeight: "bold",
					color: `${theme.palette.primary.main}`,
				},
				tip: "supplierClientIDTip",
				enableGrouping: true,
				groupByTip: SBTranslate("tips.groupBySupplierClientID"),
			},
			{
				id: (e) => e.sourceName,
				csvName: `${SBTranslate("supplierName")}`,
				key: "supplierName",
				noWrap: true,
				name: "name",
				align: "left",
				tip: "supplierNameTip",
			},
		],
	});
	add({
		id: () => "recommendation",
		name: "recommendation",
		headers: [
			{
				key: "netNeed",
				id: (c) => c?.netNeed,
				csvName: `${SBTranslate("netNeed")} (${SBTranslate("unit")})`,
				name: "netNeed",
				formatter: integerFormatter,
				align: "right",
				tip: "recommendationNetNeedTip",
				aggregationFn: "sum",
			},
			{
				key: "recommended",
				id: (c) => c?.recommended,
				csvName: `${SBTranslate("recommended")} (${SBTranslate("unit")})`,
				name: "recommended",
				formatter: integerFormatter,
				align: "right",
				tip: "recommendationRecommendedTip",
				aggregationFn: "sum"
			},
			{
				key: "quantity",
				id: (c) => c.quantity,
				csvName: `${SBTranslate("quantity")} (${SBTranslate("unit")})`,
				name: "quantity",
				editable: true,
				align: "right",
				fixed: true,
				tip: "recommendationInputTip",
				aggregationFn: "sum",
				aggregatedCell: ({ getValue }) => integerFormatter(getValue())
			},
			{
				key: "report",
				csvHide: true,
				id: (c) => <SBIcon icon="message-circle" color="primary" style={{ cursor: "pointer" }} />,
				name: "report",
				hideToggle: true,
				width: "1%",

			},
			{
				key: "totalCost",
				id: (c) => c.quantity * c.unitCost,
				csvName: `${SBTranslate("totalCost")} (${SBTranslate("$")})`,
				name: "totalCost",
				align: "right",
				formatter: systemFormatter,
				tip: "recommendationTotalCostTip",
				aggregationFn: "sum"
			},
			{
				key: "status",
				id: (c) => c.statuses.map((c) => `${t(c)} `).join(", "),
				csvName: `${SBTranslate("status")}`,
				name: "status",
				align: "right",
				tip: "recommendationRestrictionsTip",
			},
			{
				key: "coverageStart",
				id: (c) => c.coverageStart,
				csvName: `${SBTranslate("startDate")}`,
				formatter: dateFormatter,
				name: "startDate",
				align: "right",
				tip: "recommendationCoverageStartTip",
				aggregationFn: "min"
			},
			{
				key: "coverageEnd",
				id: (c) => c.coverageEnd,
				csvName: `${SBTranslate("endDate")}`,
				formatter: dateFormatter,
				name: "endDate",
				align: "right",
				tip: "recommendationCoverageEndTip",
				aggregationFn: "max"
			},
			{
				key: "coverageDays",
				id: (c) => c.coverageDays,
				csvName: `${SBTranslate("coverageDays")} (${SBTranslate("days")})`,
				formatter: integerFormatter,
				name: "coverageDays",
				align: "right",
				tip: "recommendationCoverageDaysTip",
				aggregatedCell: () => null
			},
			{
				key: "coverageForecast",
				id: (c) => c.coverageForecast?.expected,
				csvName: `${SBTranslate("forecast")} (${SBTranslate("unit")})`,
				name: "forecast",
				align: "right",
				tip: "recommendationCoverageForecastTip",
				formatter: systemFormatter,
				aggregationFn: "sum"
			},
			{
				key: "coverageDeviation",
				id: (c) => c.coverageForecast?.rmse,
				csvName: `${SBTranslate("deviation")} (${SBTranslate("unit")})`,
				name: "deviation",
				align: "right",
				tip: "recommendationCoverageDeviationTip",
				formatter: systemFormatter,
				aggregationFn: (a, ...b) => deviationAggregator(a, b?.[0])
			},
			{
				key: "grossNeed",
				id: (c) => c.grossNeed,
				csvName: `${SBTranslate("grossNeed")} (${SBTranslate("unit")})`,
				name: "grossNeed",
				align: "right",
				tip: "recommendationGrossNeedTip",
				formatter: integerFormatter,
				aggregationFn: "sum",
			},
		],
	});
	add({
		id: () => "config",
		name: "config",
		headers: [
			{
				key: "csl",
				id: (c) => c?.csl * 100 ?? 0,
				csvName: `${SBTranslate("csl")} (${t("%")})`,
				name: "targetCSL",
				formatter: percentageNoDecimalFormatter,
				align: "right",
				tip: "recommendationCSLTip",
				disabled: true,
				aggregatedCell: () => null
			},
			{
				key: "minCSL",
				id: (c) => c?.minCSL * 100 ?? 0,
				csvName: `${SBTranslate("minCSL")} (${t("%")})`,
				name: "minCSL",
				formatter: percentageNoDecimalFormatter,
				align: "right",
				tip: "recommendationMinCSLTip",
				disabled: true,
				aggregatedCell: () => null
			},
			{
				key: "maxCSL",
				id: (c) => c?.maxCSL * 100 ?? 0,
				csvName: `${SBTranslate("maxCSL")} (${t("%")})`,
				name: "maxCSL",
				formatter: percentageNoDecimalFormatter,
				disabled: true,
				align: "right",
				tip: "recommendationMaxCSLTip",
				aggregatedCell: () => null
			},
		],
	});
	add({
		id: () => "initialPosition",
		name: "initialPosition",
		headers: [
			{
				key: "onHand",
				id: (e) => e?.initial?.position?.onHand,
				csvName: `${SBTranslate("initialOnHand")} (${SBTranslate("unit")})`,
				name: "onHand",
				type: TableCellType.Number,
				width: "5%",
				align: "right",
				formatter: integerFormatter,
				tip: "initialOnHandTip",
				aggregationFn: "sum"
			},
			{
				key: "onOrder",
				id: (e) => e?.initial?.position?.onOrder,
				csvName: `${SBTranslate("initialOnOrder")} (${SBTranslate("unit")})`,
				name: "onOrder",
				type: TableCellType.Number,
				align: "right",
				width: "5%",
				formatter: integerFormatter,
				tip: "initialOnOrderTip",
				aggregationFn: "sum"
			},
			{
				key: "pending",
				id: (e) => e?.initial?.position?.reserved,
				csvName: `${SBTranslate("commons.pending")} (${SBTranslate("unit")})`,
				name: "commons.pending",
				align: "right",
				width: "5%",
				formatter: integerFormatter,
				tip: "tips.pending",
				aggregationFn: "sum"
			},
			{
				key: "position",
				id: (e) => e?.initial?.position?.position,
				csvName: `${SBTranslate("position")} (${SBTranslate("unit")})`,
				align: "right",
				name: "position",
				formatter: integerFormatter,
				tip: "initialPositionTip",
				aggregationFn: "sum"
			},
			{
				key: "currentCSL",
				id: (e) => e?.initial?.csl * 100,
				csvName: `${SBTranslate("csl")}  (${t("%")})`,
				align: "right",
				name: "csl",
				formatter: percentageNoDecimalFormatter,
				tip: "initialCSLTip",
				aggregatedCell: () => null
			},
			{
				key: "doh",
				id: (e) => e?.initial?.doh,
				csvName: `${SBTranslate("doh")} (${SBTranslate("unit")})`,
				align: "right",
				name: "DOH",
				formatter: integerFormatter,
				tip: "initialDaysTip",
				aggregationFn: "mean",
			},
			{
				key: "expectedSales",
				id: (e) => e?.initial?.expectedSales,
				csvName: `${SBTranslate("expectedSales")} (${SBTranslate("unit")})`,
				align: "right",
				name: "expectedSales",
				formatter: decimalFormatter,
				tip: "initialExpectedSalesTip",
				aggregationFn: "sum",
			},
			{
				key: "rupture",
				id: (e) => e?.rupture,
				csvName: `${SBTranslate("rupture")}  (${SBTranslate("unit")}))`,
				align: "right",
				formatter: systemFormatter,
				name: "rupture",
				tip: "initialRuptureTip",
				aggregationFn: "sum"
			},
			{
				key: "risk",
				id: (e) => e?.initial?.eus,
				csvName: `${SBTranslate("initialEus")}  (${SBTranslate("unit")})`,
				align: "right",
				formatter: systemFormatter,
				name: "risk",
				tip: "initialRiskTip",
				aggregationFn: "sum"
			},
		],
	});
	add({
		id: () => "cycle",
		name: "cycle",
		headers: [
			{
				id: (c) => c.cycle?.request,
				key: "request",
				name: "request",
				csvName: `${SBTranslate("request")}`,
				formatter: dateFormatter,
				tip: "cycleRequestTip",
				aggregationFn: "min"
			},
			{
				id: (c) => c.cycle?.delivery,
				key: "delivery",
				name: "delivery",
				csvName: `${SBTranslate("delivery")}`,
				formatter: dateFormatter,
				tip: "cycleDeliveryTip",
				aggregationFn: "max"
			},
			{
				id: (c) => c.cycle?.coverage,
				key: "coverage",
				name: "coverage",
				csvName: `${SBTranslate("coverage")}`,
				formatter: dateFormatter,
				tip: "cycleCoverageTip",
				aggregationFn: "min"
			},
			{
				id: (c) => c.cycle?.nextReview,
				key: "nextReview",
				name: "nextReview",
				csvName: `${SBTranslate("nextReview")}`,
				formatter: dateFormatter,
				tip: "cycleNextReviewTip",
				aggregationFn: "min"
			},
			{
				key: "daysBetweenCount",
				id: (c) => c.cycle?.coveredDays,
				name: "days",
				csvName: `${SBTranslate("daysBetweenCount")} (${SBTranslate("days")})`,
				tip: "cycleDaysBetweenCountTip",
				aggregationFn: "max"
			},
			{
				key: "forecast",
				id: (c) => c.forecast?.expected,
				csvName: `${SBTranslate("forecast")} (${SBTranslate("unit")})`,
				name: "forecast",
				align: "right",
				tip: "cycleForecastTip",
				formatter: systemFormatter,
				aggregationFn: "sum"
			},
			{
				key: "deviation",
				id: (c) => c.deviation,
				csvName: `${SBTranslate("deviation")} (${SBTranslate("unit")})`,
				name: "deviation",
				align: "right",
				tip: "cycleDeviationTip",
				formatter: systemFormatter,
				aggregationFn: (a, ...b) => deviationAggregator(a, b?.[0])
			},
		],
	});

	add({
		id: () => "finalPosition",
		name: "finalPosition",
		headers: [
			{
				key: "finalonOrder",
				id: (e) => e?.final?.position?.onOrder,
				csvName: `${SBTranslate("finalonOrder")} (${SBTranslate("unit")})`,
				name: "onOrder",
				type: TableCellType.Number,
				width: "5%",
				formatter: integerFormatter,
				tip: "finalOnOrderTip",
				align: "right",
				aggregationFn: "sum"
			},
			{
				key: "finalposition",
				id: (e) => e?.final?.position?.position,
				csvName: `${SBTranslate("finalPosition")} (${SBTranslate("unit")})`,
				name: "position",
				formatter: integerFormatter,
				tip: "finalPostionTip",
				align: "right",
				aggregationFn: "sum"
			},
			{
				key: "finalcurrentCSL",
				id: (e) => e?.final?.csl * 100,
				csvName: `${SBTranslate("csl")} (${t("%")})`,
				name: "csl",
				formatter: percentageNoDecimalFormatter,
				tip: "finalCSLTip",
				align: "right",
				aggregatedCell: () => null
			},
			{
				key: "finalDays",
				id: (e) => e?.final?.doh,
				csvName: `${SBTranslate("doh")} (${SBTranslate("unit")})`,
				name: "doh",
				formatter: integerFormatter,
				tip: "finalDaysOnHandTip",
				align: "right",
				aggregationFn: "mean"
			},
			{
				key: "expectedSalesFinal",
				id: (e) => e?.final?.expectedSales,
				csvName: `${SBTranslate("expectedSalesFinal")} (${SBTranslate("unit")})`,
				align: "right",
				name: "expectedSales",
				formatter: decimalFormatter,
				tip: "finalExpectedSalesTip",
				aggregationFn: "sum",
			},
			{
				key: "finalRisk",
				id: (e) => e?.final?.eus,
				csvName: `${SBTranslate("finalEus")} (${SBTranslate("unit")})`,
				name: "risk",
				tip: "finalRiskTip",
				formatter: systemFormatter,
				align: "right",
				aggregationFn: "sum"
			},
		],
	});

	return build();
};