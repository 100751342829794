import SBTableCell from "modules/Commons/SBDataDisplays/SBTable/SBTableCells/SBTableCell";

/** Component to render a column if has the actionable flag */
export default ({ actionable }: SBTableHeaderActionProps) => {
	return actionable ? (
		<SBTableCell data-testid="sb-table-header-action" width={1} />
	) : null;
};

export interface SBTableHeaderActionProps {
	/** Make the action column active */
	actionable?: boolean;
}
