import { Grid } from "@mui/material";
import { SBBox, SBTypography } from "modules/Commons";
import { LoginInformational } from "views/LoginView/LoginInformational";
import { useStyles } from "./style";
import maintenenceImg from "../../assets/maintenence.svg";
import { useTranslation } from "react-i18next";

export const MaintenanceView = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Grid
			container
			className={classes.mainContainer}
			alignContent="center"
			bgcolor="lightPurple"
		>
			<Grid item xs={1} />
			<Grid item xs={3}>
				<SBBox
					pl={11}
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					height="calc(100vh - 100px)"
				>
					<SBBox>
						<SBTypography
							variant="h5"
							color="#4E39A5"
							pb={8}
							fontWeight="700"
							textAlign="center"
							textTransform="uppercase"
						>
							{t("maintenence")}
						</SBTypography>
						<img height="200" src={maintenenceImg} alt="any alt" />
						<SBTypography pt={8} textAlign="center">
							{t("maintenenceMessage")}
						</SBTypography>
					</SBBox>
				</SBBox>
			</Grid>
			<Grid item xs={2} />
			<Grid item xs={4} display="flex" alignContent="center">
				<SBBox my="auto">
					<LoginInformational />
				</SBBox>
			</Grid>
		</Grid>
	);
};
