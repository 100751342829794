import { AxiosResponse } from "axios";
import { IConfigCSVRaw } from "domain/Config/Models/IConfigCSVRaw";
import { useRequest } from "hooks/useRequest";
import { DateTime } from "luxon";

export const InventoryConfigRequester = ((value) => {
	const request = useRequest("InventoryConfig");
	return {
		...request,
		CONFIGVALIDATE: (value: IConfigCSVRaw[]) => {
			return request
				.POST("validate", value)
				.then((res: AxiosResponse<any>) => {
					if (res.data?.errors && res.data.errors.length > 0) {
						const errors = res.data.errors;
						const formatedErrors = errors.flatMap((c: any) =>
							c.validationFailures.flatMap((v: any) => ({
								line: c.line + 1,
								...v,
							}))
						);
						return { errors: formatedErrors };
					} else {
						const forecast = (res.data as any).forecast;
						const start = DateTime.fromISO((res.data as any).start);
						const end = DateTime.fromISO((res.data as any).end);
						return {
							forecast,
							range: {
								start,
								end,
								daysBetweenCount: end.diff(start, ["days"])
									.days,
							},
						};
					}
				});
		},
	};
})();
