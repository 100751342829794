import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	cronGeneratorCard: {
		borderRadius: "8px 0 0 8px !important",
		backgroundColor: `${theme.palette.lightPurple1.main} !important`,
		height: "100%",
		position: "relative",
		"&.border": {
			border: `1px solid ${theme.palette.primary.main}`,
		},
	},
	cronGeneratorContainer: {
		padding: "16px",
		width: "100%",
		height: "calc(100% - 60px)",
		overflow: "auto",
	},
	cronGeneratorHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	reviewPeriodExpressionFieldContainer: {
		display: "flex",
		position: "absolute",
		bottom: "0px",
		left: "0px",
		width: "100%",
		padding: "0 16px 16px 16px",
	},
	reviewPeriodExpressionField: {
		marginRight: "16px",
		padding: "8px",
		width: "100%",
		display: "flex",
		alignItems: "center",
		height: "33px",
		background: `${theme.palette.white.main}`,
		border: `1px solid ${theme.palette.purple6.main}`,
		borderRadius: "8px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	copyToClipboardInfo: {
		display: "flex",
		alignItems: "center",
		width: "auto",
		height: "55px",
		background: "rgb(50, 50, 50)",
		borderRadius: "6px",
		padding: "6px 16px",
		fontSize: "14px",
		color: `${theme.palette.primary.contrastText}`,
	},
}));
