import { TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
	NameType,
	Payload,
	ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { IChartTooltipRow } from "domain/Chart/Entities/IChartTooltipRow";
import { useMemo } from "react";
import { ISimulationModel } from "modules/Simulations/models/ISimulationModel";

export default (props: SimulationChartTooltipRowProps) => {
	const { payload, selectedChart, column } = props;
	const { t } = useTranslation();

	const selectionStyle = useMemo(
		() => (selectedChart === column.name ? "active" : ""),
		[selectedChart]
	);

	const realGetter = useMemo(() => payload[0]?.payload?.real, [payload[0]]);
	const projectedGetter = useMemo(
		() => payload[0]?.payload?.projected,
		[payload[0]]
	);

	const fetchRealTooltip = () => column.accessor(realGetter);
	const fetchProjectedTooltip = () => column.accessor(projectedGetter);

	return (
		<TableRow key={column.name} className={selectionStyle}>
			<TableCell className={clsx("column", selectionStyle)}>
				{t(column.label)}
			</TableCell>
			<TableCell className={clsx("real", selectionStyle)}>
				{fetchRealTooltip()}
			</TableCell>
			<TableCell className={clsx("projected", selectionStyle)}>
				{fetchProjectedTooltip()}
			</TableCell>
		</TableRow>
	);
};

export interface SimulationChartTooltipRowProps {
	/** Chart payload. Return all the charts.*/
	payload: Payload<ValueType, NameType>[];
	/** Indicates which chart is selected*/
	selectedChart: string;
	/** Column to access the real and projected values*/
	column: IChartTooltipRow<ISimulationModel>;
}
