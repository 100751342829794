import { IComposedFilter } from "domain/Utils";
import useArrayState from "hooks/useArrayState";
import { DateTime } from "luxon";
import { SBBox, SBLoading } from "modules/Commons";
import { SBNewTableRef } from "modules/Commons/SBDataDisplays/SBTable/SBNewTable";
import SBEmptyState from "modules/Commons/SBLayouts/SBEmptyState";
import { useSnackbar } from "modules/Commons/SBOverlays/SBSnackbar";
import { OptimizationRequester } from "modules/Optimizations/services/OptimizationRequester";
import { PurchaseDatePlaceholder } from "modules/Purchases/components/PurchaseDatePlaceholder";
import SBPurchaseTable from "modules/Purchases/components/SBPurchaseTable";
import { IPurchasePlan } from "modules/Purchases/models/IPurchasePlans";
import { SystemContextRequester } from "modules/SystemContext/services/SystemContextRequester";
import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SBComposedFilter from "views/Commons/SBComposedFilter";

export default () => {
	const { t } = useTranslation();
	const { error } = useSnackbar();

	const tableRef = createRef<SBNewTableRef>();

	const [startDate, setStartDate] = useState<DateTime>();
	const [loading, setLoading] = useState(true);

	const [rows, setRows, { hasAny: hasAnyRows }] =
		useArrayState<IPurchasePlan>([]);

	const fetchData = async (items: any, end: any) => {
		setLoading(true);
		setRows([]);

		return OptimizationRequester.purchase(items, end)
			.then((plans) => {
				if (plans.length > 0) {
					setRows(plans);
				} else {
					error(t("noItemsFound"));
				}
			})
			.catch(() => {
				error(t("problemPleaseContactSupport"));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleApply = async (obj: IComposedFilter) => {
		const picker = obj.picker;

		if (picker && picker.range?.endDate)
			await fetchData(obj.filter, picker.range?.endDate);

		setLoading(false);
	};

	const downloadCSV = () => tableRef.current?.downloadCSV();

	const fetchInitialQuery = async () => {
		setLoading(true);

		const result = (await SystemContextRequester.GET("")) as any;
		if (result.data)
			setStartDate(DateTime.fromISO(result.data.lastDataBaseUpdate));

		setLoading(false);
	};

	useEffect(() => {
		fetchInitialQuery();
	}, []);

	return (
		<SBBox height={1} pl={11}>
			<SBBox>
				<SBComposedFilter.Base
					onApply={handleApply}
					dateRangeRequired
					filterRequired
				>
					<SBComposedFilter.Picker
						fixedStart
						minDate={startDate}
						startDate={startDate}
					/>
					<SBComposedFilter.Filter />
					<SBComposedFilter.Applicator />
					<SBComposedFilter.Actions
						disabled={!hasAnyRows}
						actions={[
							{
								icon: { name: "download" },
								title: t("exportCSV"),
								onClick: downloadCSV,
							},
						]}
					/>
				</SBComposedFilter.Base>
			</SBBox>

			<SBLoading loading={loading}>
				<SBEmptyState
					show={hasAnyRows}
					emptyState={<PurchaseDatePlaceholder />}
				>
					<SBPurchaseTable
						title="suggestedPurchases"
						ref={tableRef}
						rows={rows}
						onDataUpdated={setRows}
					/>
				</SBEmptyState>
			</SBLoading>
		</SBBox>
	);
};
