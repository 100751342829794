import { SBBox } from "modules/Commons";
import { Dispatch } from "react";
import { useStyles } from "./style";

export default ({
	items,
	setItems,
	loading = false,
	setLoading,
}: DistributionViewTitleProps) => {
	const classes = useStyles();

	return (
		<SBBox className={classes.titleBox}>
			<SBBox className={classes.searchBox}></SBBox>
		</SBBox>
	);
};

export interface DistributionViewTitleProps {
	items: Array<any> | undefined;
	setItems: Dispatch<any>;
	handleApply: any;
	loading: boolean;
	setLoading: Dispatch<boolean>;
}
