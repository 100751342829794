import { useRequest } from "hooks/useRequest";

export const CacheRequester = (() => {
	const request = useRequest("cache");

	return {
		runCache: () => {
			return request.POST("warmup", {});
		},
	};
})();
