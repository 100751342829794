import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({

    DatePickerConfig: {
        overflowY: "auto",
        maxHeight: "40vh",
        paddingTop: 8,

        "&::-webkit-scrollbar": {
            width: "4px",
        },
        "&::-webkit-scrollbar-track": {
            background: "#fff",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#BDBDBD",
        },
    },
}));
