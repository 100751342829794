import gif from "assets/logoload_sbrain.gif";
import { SBBox } from "modules/Commons";

export default (props: SBLoadingInterface) => {
	const limited = !!props.limited;
	const isLoading = props.loading;
	const fullWidth = props.fullWidth;
	const adjustedPadding = props.adjustedPadding ?? true;
	return (
		<SBBox style={{ width: fullWidth ? "100%" : "initial" }}>
			{isLoading ? (
				<SBBox
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
					height={limited ? "100%" : "100vh"}
					width={limited ? "100%" : "calc(100vw - 110px)"}
					style={{
						backgroundColor: "#ffffff55",
						position: limited ? "initial" : "fixed",
						top: 0,
						left: "90px",
						zIndex: 1000,
						paddingRight: adjustedPadding ? "50px" : "0px",
					}}
				>
					<img src={gif} height="250" alt="loading" />
				</SBBox>
			) : (
				props.children
			)}
		</SBBox>
	);
};

export interface SBLoadingInterface {
	limited?: boolean | undefined;
	children?: any;
	loading: boolean;
	fullWidth?: boolean;
	adjustedPadding?: boolean;
}
