import { Translate } from "i18n/translate";
import { SBBox, SBTypography } from "modules/Commons";
import notFoundImage from "../../assets/notfound.svg";

export const NotFoundView = () => {
	return (
		<SBBox
			pl={11}
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height="calc(100vh - 100px)"
		>
			<img height="300" src={notFoundImage} alt="any alt" />
			<SBTypography
				variant="h5"
				color="#4E39A5"
				pt={8}
				pb={2}
				fontWeight="700"
			>
				{Translate("pageNotFound")}
			</SBTypography>
			<SBTypography>{Translate("pageNotFoundMessage")}</SBTypography>
		</SBBox>
	);
};
