import { SBBox } from "modules/Commons";
import { TipsContext } from "views/ConfigsView/ConfigTips";
import { useContext } from "react";

export default (props) => {
	const style = {
		opacity: props.selected ? 1 : 0.5,
		transition: "300ms",
		cursor: "pointer",
		textAlign: "center",
	};

	const borderStyle = {
		height: 2,
		width: props.selected ? "100%" : 0,
		marginTop: "7px",
		transition: "300ms",
		background: "#7F51FF",
	};
	const tipsContext = useContext(TipsContext);

	return (
		<SBBox
			width={1}
			pt={2}
			style={style}
			className={props.className}
			onClick={() => {
				tipsContext.showTip("");
				props.onClick();
			}}
			aria-hidden
		>
			{props.children}
			<div style={borderStyle} />
		</SBBox>
	);
};
