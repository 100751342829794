import { Translate } from "i18n/translate";
import {
	SBButton,
	SBBox,
	SBCheckbox,
	SBTypography,
	SBTextField,
} from "modules/Commons";
import { useState } from "react";
import logo from "assets/logo.svg";
import LoginFormInvalid from "./LoginFormInvalid";
import useLoadingScope from "hooks/useLoadingScope";
import { SBTranslate } from "i18n";
import useRememberMe from "hooks/useRememberMe";

export default ({ onLogin }: LoginFormProps) => {
	const { getRememberMe, setRememberMe } = useRememberMe();

	const { loading, useLoading } = useLoadingScope();

	const [invalidLogin, setInvalidLogin] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [rememberMeCheckbox, setRememberMeCheckbox] = useState<boolean>(getRememberMe());

	const onSubmit = async () => {
		await useLoading(async () => {
			setInvalidLogin(false);
			await handleLogin(email, password);
		});
	};

	const handleLogin = async (email: string, password: string) => {
		return await useLoading(async () => {
			const result = await onLogin(email, password);
			if (!result) setInvalidLogin(true);
			return result;
		});
	};

	const handleRememberMe = (re: boolean) => {
		setRememberMeCheckbox(re);
		setRememberMe(re);

	}

	return (
		<SBBox display="flex" flexDirection="column" alignItems="start">
			<img src={logo} height="64" alt="logo" />
			<SBTypography mt={10} variant="h4" fontWeight="700" color="gray2">
				{SBTranslate("welcome")}
			</SBTypography>
			<SBTypography variant="body1" fontWeight="400" my={3}>
				{SBTranslate("insertAccessData")}
			</SBTypography>
			{invalidLogin && (
				<LoginFormInvalid
					onClose={(e) => {
						setInvalidLogin(e);
					}}
				/>
			)}
			<SBTextField
				placeholder={SBTranslate("emailPlaceholder")}
				onChange={(e) => setEmail(e.target.value)}
				value={email}
				title={SBTranslate("email")}
				data-testid="login-form-email"
			/>
			<SBTextField
				type="password"
				placeholder={SBTranslate("yourPassword")}
				title={SBTranslate("password")}
				onChange={(e) => setPassword(e.target.value)}
				value={password}
				data-testid="login-form-password"
			/>
			<SBCheckbox
				label={SBTranslate("rememberMe")}
				checked={rememberMeCheckbox}
				onChange={(e) => handleRememberMe(e.target.checked)}
				data-testid="login-form-remember-me"
			/>
			<SBBox mx="auto" width={1} mt={2}>
				<SBButton
					variant="contained"
					fullWidth
					style={{ textTransform: "capitalize" }}
					onClick={onSubmit}
					loading={loading}
					data-testid="login-form-submit"
				>
					{SBTranslate("login")}
				</SBButton>
			</SBBox>
		</SBBox>
	);
};

interface LoginFormProps {
	onLogin: (email: string, password: string) => any;
}
