import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	editableColumn: {
		width: "100%",
		fontSize: 13.741,
		borderRadius: 4,
		marginLeft: "auto",
		textAlign: "end",
		padding: "3px 8px 3px 4px",
		outline: "none",
		border: "1px solid #aaa",
	},
	editableColumnPlaceholder: {
		width: "100%",
		backgroundColor: "white",
		border: "1px solid #aaa",
		padding: "0px 8px 0px 4px",
		borderRadius: 4,
		cursor: "text",
	},
}));
