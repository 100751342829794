function Cron({ t }: any) {
	const createCronExpression = (monthDays: any, weekDays: any) => {
		var monthDaysSorted = [];
		var weekDaysSorted = [];

		if (monthDays)
			monthDaysSorted = monthDays.sort(function (a: any, b: any) {
				return Number(a) - Number(b);
			});
		if (weekDays) weekDaysSorted = weekDays.sort();

		const expression = `* * * ${
			monthDays ? monthDaysSorted.join("|") : "?"
		} * ${weekDays ? weekDaysSorted.join("|") : "?"} *`;

		return expression;
	};

	const unmountCronExpression = (value = "") => {
		const expression = value.split(" ");
		var monthDays = expression[3].split(",");
		var weekDays: any = [];
		const w = expression[5].split("|");

		if (w[0] !== "*" && w[0] !== "?") {
			for (const weekDay of w) {
				weekDays.push(weekDay.split("#"));
			}
		}

		if (monthDays[0] === "*") {
			if (weekDays[0] === "*") {
				return { monthDays: [], weekDays: [] };
			}
			return { monthDays: [], weekDays };
		} else {
			if (weekDays[0] === "*") {
				return { monthDays, weekDays: [] };
			}
			return { monthDays, weekDays };
		}
	};

	const returnWeekPosition = (week: any) => {
		switch (week) {
			case "1":
				return t("1st");
			case "2":
				return t("2nd");
			case "3":
				return t("3rd");
			case "4":
				return t("4th");
			case "5":
				return t("5th");
			default:
				return;
		}
	};

	const returnWeekDayText = (day: any) => {
		switch (day) {
			case "1":
				return t("sunday");
			case "2":
				return t("monday");
			case "3":
				return t("tuesday");
			case "4":
				return t("wednesday");
			case "5":
				return t("thrusday");
			case "6":
				return t("friday");
			case "7":
				return t("saturday");
			default:
				return;
		}
	};

	const translateCronExpression = (expression: any) => {
		const { monthDays, weekDays } = unmountCronExpression(expression);

		var finalMessage = "";
		if (weekDays.length > 0) {
			const filterWeekDays = (e: any) => {
				return +e[1] === i;
			};

			for (var i = 1; i <= 5; ++i) {
				const a = weekDays.filter(filterWeekDays);

				if (a.length !== 0) {
					const weekPosition = returnWeekPosition(a[0][1]);
					const days = [];
					for (const day of a) {
						days.push(returnWeekDayText(day[0]));
					}

					var weekString = `${weekPosition} `;
					for (var j = 0; j < days.length; ++j) {
						if (days.length === 1)
							weekString = weekString.concat(`${days[j]}`);
						else if (j === days.length - 2)
							weekString = weekString.concat(`${days[j]} `);
						else if (j !== days.length - 1)
							weekString = weekString.concat(`${days[j]}, `);
						else
							weekString = weekString.concat(
								`${t("and")} ${days[j]}`
							);
					}

					finalMessage = finalMessage.concat(weekString, ";\n");
				}
			}
		} else if (monthDays.length > 0) {
			if (monthDays.length === 1) {
				finalMessage = `${t("everyDay")} ${monthDays.join(", ")}`;
			} else {
				const last = monthDays.splice(monthDays.length - 1, 1)[0];
				finalMessage = `${t("everyDay")} ${monthDays.join(", ")} ${t(
					"and"
				)} ${last}`;
			}
		} else {
			finalMessage = t("everyDay");
		}
		return finalMessage;
	};

	return {
		translateCronExpression,
		unmountCronExpression,
		createCronExpression,
	};
}

export default Cron;
