import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	slideOutsideContainer: {
		right: 0,
		position: "fixed",
		zIndex: 100,
		width: "45vw",
		boxShadow: "1px 1px 5px 5px rgba(0,0,0,.1)",
		background: "white",
		top: 75,
		borderRadius: 4,
		height: `calc(100vh - 88px)`,
		overflowY: "auto",
	},
}));
