import { SBTranslate } from "i18n"
import { SBBox, SBCard, SBTypography } from "modules/Commons"
import noNotifications from "assets/noNotifications.svg"

export default () => {
	return (
		<SBBox
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			py={5}
		>
			<img height={130} src={noNotifications} alt="" />
			<SBTypography
				textAlign="center"
				color="gray2.main"
				fontWeight="600"
				fontSize="16px"
				lineHeight="25.6px"
				letterSpacing="0.45px"
				mt={"25px"}
			>
				{SBTranslate("notification.noNotifications")}
			</SBTypography>
		</SBBox>
	)
}