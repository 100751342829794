import { SBBox, SBIcon, SBOutlinedButton, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";

export default ({ showOnlyActived, onDelete, onEnable, onDisable }: any) => {
	const { t } = useTranslation();
	return (
		<SBBox display="flex">
			{!showOnlyActived ? (
				<>
					<SBBox ml={1}>
						<SBOutlinedButton color="error" onClick={onDelete}>
							<SBBox
								display="flex"
								alignItems="center"
								px={0.5}
								py={0.3}
							>
								<SBIcon icon="trash-2" color="error" />
								<SBTypography
									textTransform="capitalize"
									pl={1}
									fontWeight="700"
								>
									{t("exclude")}
								</SBTypography>
							</SBBox>
						</SBOutlinedButton>
					</SBBox>
					<SBBox ml={1}>
						<SBOutlinedButton onClick={onEnable}>
							<SBBox
								display="flex"
								alignItems="center"
								px={0.5}
								py={0.3}
							>
								<SBIcon icon="eye" color="primary" />
								<SBTypography
									textTransform="capitalize"
									pl={1}
									fontWeight="700"
								>
									{t("enable")}
								</SBTypography>
							</SBBox>
						</SBOutlinedButton>
					</SBBox>
				</>
			) : (
				<SBBox ml={1}>
					<SBOutlinedButton onClick={onDisable}>
						<SBBox
							display="flex"
							alignItems="center"
							px={0.5}
							py={0.3}
						>
							<SBIcon icon="eye-off" color="primary" />
							<SBTypography
								textTransform="capitalize"
								pl={1}
								fontWeight="700"
							>
								{t("disable")}
							</SBTypography>
						</SBBox>
					</SBOutlinedButton>
				</SBBox>
			)}
		</SBBox>
	);
};
