import { Checkbox, FormControlLabel } from "@mui/material";
import { SBBox, SBGrid } from "modules/Commons";
import { useTranslation } from "react-i18next";
import ConfigGroupmentDivider from "views/ConfigsView/ConfigSideMenu/ConfigGroupmentDivider";
import { useStyles } from "./style";

export default (props: EachDayInWeekProps) => {
	const { daysInWeek, onChange, fetchPropError } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	const days = [
		{ day: t("sunday"), label: t("sunday").substring(0, 3), value: 1 },
		{ day: t("monday"), label: t("monday").substring(0, 3), value: 2 },
		{ day: t("tuesday"), label: t("tuesday").substring(0, 3), value: 3 },
		{
			day: t("wednesday"),
			label: t("wednesday").substring(0, 3),
			value: 4,
		},
		{ day: t("thrusday"), label: t("thrusday").substring(0, 3), value: 5 },
		{ day: t("friday"), label: t("friday").substring(0, 3), value: 6 },
		{ day: t("saturday"), label: t("saturday").substring(0, 3), value: 7 },
	];

	const weeks = [
		{ value: 1, label: t("weekNumber", { weekNumber: 1 }) },
		{ value: 2, label: t("weekNumber", { weekNumber: 2 }) },
		{ value: 3, label: t("weekNumber", { weekNumber: 3 }) },
		{ value: 4, label: t("weekNumber", { weekNumber: 4 }) },
		{ value: 5, label: t("weekNumber", { weekNumber: 5 }) },
	];

	return (
		<SBBox height={1}>
			<ConfigGroupmentDivider
				title={`${t("chooseDay")}. ${t("chooseDayWeekExample")}`}
			/>

			{fetchPropError ? (
				fetchPropError("reviewPeriodExpression") && (
					<SBBox className={classes.errorMessage}>
						{fetchPropError("reviewPeriodExpression")}
					</SBBox>
				)
			) : (
				<></>
			)}

			<SBBox style={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}>
				{weeks.map((week) => (
					<SBBox key={week.value}>
						<SBBox>{week.label}</SBBox>
						<SBGrid container spacing={1}>
							{days.map((day) => (
								<SBGrid key={day.value} item xs={12 / 7}>
									<FormControlLabel
										label={day.label}
										control={
											<Checkbox
												checked={
													daysInWeek.find(
														(e: any) =>
															e ===
															`${day.value}#${week.value}`
													) ?? false
												}
												onChange={(e) =>
													onChange({
														week: week.value,
														day: day.value,
														checked: e.target.checked,
													})
												}
											/>
										}
									/>
								</SBGrid>
							))}
						</SBGrid>
					</SBBox>
				))}
			</SBBox>
		</SBBox>
	);
};

export interface EachDayInWeekProps {
	daysInWeek: any[];
	onChange: (value: any) => any;
	fetchPropError?: (value: any) => any;
}
