import { Row } from "@tanstack/react-table";
import { VirtualItem } from "react-virtual";
import SBTableColumn from "../SBTableColumn";
import { useMemo } from "react";

export default ({ virtualRow, rows, onClick, reRenderRefs }: { virtualRow: VirtualItem, rows: Row<any>[], onClick: any, reRenderRefs: any[] }) => {
	const row = rows[virtualRow.index] as Row<any>;
	return useMemo(() => (
		<tr key={row.id}>
			{row.getVisibleCells().map((cell) => (
				<SBTableColumn
					key={cell.id}
					cell={cell}
					row={row}
					isSelected={row.getIsSelected()}
					onClick={() => onClick(cell)}
				/>
			))}
		</tr>
	), reRenderRefs);
}