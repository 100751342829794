import simulationDatePlaceholder from "assets/simulationDatePlaceholder.svg";
import { SBBox, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";

export default () => {
	const { t } = useTranslation();
	return (
		<SBBox
			id="simulation-placeholder"
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height="70vh"
		>
			<img height={200} src={simulationDatePlaceholder} alt="" />

			<SBTypography
				mt={2}
				variant="h5"
				color="darkPurple.main"
				fontWeight="700"
				textAlign="center"
			>
				{t("simulationPlaceholderTitle")}
			</SBTypography>

			<SBTypography mt={2} textAlign="center" variant="body1" width={0.3}>
				{t("simulationPlaceholderSubTitle")}
			</SBTypography>
		</SBBox>
	);
};
