import { DemandPlanStatus } from "domain/Inventory/Enums/DemandPlanStatus";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { SBTranslate } from "i18n";
import { SBBox, SBTypography } from "modules/Commons";
import { IPurchasePlan } from "modules/Purchases/models/IPurchasePlans";
import { useMemo } from "react";
import { Trans } from "react-i18next";
import { dateFormatter, formatDecimal, formatInteger, percentageFormatter } from "utils/formatters";
import { ReportContent } from "./style";

export default (props: SBPurchaseReportProps) => {
	const { getLanguage } = useSystemLanguage();
	const { plan } = props;


	const config = useMemo(() => ({
		ruptureQuantity: formatInteger(plan.rupture),
		restrictions: (plan?.status & DemandPlanStatus.Planned) > 0
			? SBTranslate("views.report.noRestriction")
			: SBTranslate("views.report.restrictedBy", { limits: plan.statuses.map((c: any) => `${SBTranslate(c)} `).join(", ") }),
		ruptureText: plan?.rupture > 0
			? SBTranslate("views.report.hasRupture", { ruptureQuantity: formatInteger(plan.rupture) })
			: SBTranslate("views.report.noRupture"),
		emergencialy: plan?.cycle?.isEmergencial ? SBTranslate("emergencialy") : "",
		recommendation: formatInteger(plan.recommended),
		start: dateFormatter(plan?.coverageStart, SBTranslate("dateFormat")),
		end: dateFormatter(plan?.coverageEnd, SBTranslate("dateFormat")),
		productName: plan?.inventory?.product?.name,
		productClientID: plan?.inventory?.product?.clientID,
		storeName: plan?.inventory?.store?.name,
		storeClientID: plan?.inventory?.store?.clientID,
		request: dateFormatter(plan?.cycle?.request, SBTranslate("dateFormat")),
		finalCSL: percentageFormatter(plan?.final?.csl),
		coverage: dateFormatter(plan?.cycle?.coverage, SBTranslate("dateFormat")),
		forecast: formatDecimal(plan?.coverageForecast?.expected),
		error: formatDecimal(plan?.coverageForecast?.rmse),
		currentCycleRupture: percentageFormatter(1 - plan?.initial?.csl),
		rupture: percentageFormatter(1 - plan?.initial?.csl),
		reservedStock: formatInteger(plan?.inventory?.reserved),
		risk: plan?.initial?.eus,
		targetCSL: percentageFormatter(plan?.csl),
		need: formatInteger(plan?.grossNeed),
		onHand: formatInteger(plan?.initial?.position?.onHand),
		onOrder: formatInteger(plan?.initial?.position?.onOrder),
		netNeed: formatInteger(plan?.netNeed),
		safetyStock: formatInteger(plan?.safety),
		emergencyStock: formatInteger(plan?.emergency),
		leadTime: plan?.leadTime,
		delivery: dateFormatter(plan?.cycle?.delivery, SBTranslate("dateFormat")),
		nextReview: dateFormatter(plan?.cycle?.nextReview, SBTranslate("dateFormat")),
		nextDelivery: dateFormatter(plan?.nextDelivery ?? '', SBTranslate("dateFormat")),
		nextDeliveryQuantity: formatInteger(plan?.nextDeliveryQuantity),
		currentCycleForecast: formatInteger(plan?.coverageForecast?.expected),
		currentCycleDeviation: formatInteger(plan?.coverageForecast?.rmse),
		deviation: formatInteger(plan?.deviation),
		inverseCSL: percentageFormatter(1 - plan?.initial?.csl),
		inverseMinCSL: percentageFormatter(1 - plan?.minCSL),
		inverseMaxCSL: percentageFormatter(1 - plan?.maxCSL),
		grossNeed: formatInteger(plan?.grossNeed),
		scarceNeed: formatInteger(Math.abs(plan?.csl < plan.minCSL ? plan?.grossNeed : plan?.initial?.position?.onHand - plan?.grossNeed))
	} as any), [plan, getLanguage()])

	return (
		<SBBox display="inline">
			<SBTypography mb={1}>
				<Trans i18nKey="views.report.recommendation" lang={getLanguage()}>
					<ReportContent>
						{config}
					</ReportContent>
				</Trans>
			</SBTypography>
			<SBTypography mb={1}>
				{plan.rupture === 0 ? (
					<Trans i18nKey="views.report.currentCycleNoRupture" lang={getLanguage()}>
						<ReportContent>
							{config}
						</ReportContent>
					</Trans>
				) : (
					<Trans i18nKey="views.report.currentCycleRupture" lang={getLanguage()}>
						<ReportContent>
							{config}
						</ReportContent>
					</Trans>
				)}
				<Trans i18nKey="views.report.currentCycleRisk" lang={getLanguage()}>
					<ReportContent>
						{config}
					</ReportContent>
				</Trans>
			</SBTypography>
			<SBTypography mb={1}>
				<Trans i18nKey="views.report.grossNeed" lang={getLanguage()}>
					<ReportContent>
						{config}
					</ReportContent>
				</Trans>
			</SBTypography>
			<SBTypography mb={1}>
				<Trans i18nKey="views.report.ordersToday" lang={getLanguage()}>
					<ReportContent>
						{config}
					</ReportContent>
				</Trans>
			</SBTypography>
			{/* <SBTypography mb={1}>
				<Trans i18nKey="views.report.beforeNextDelivery" lang={getLanguage()}>
					<ReportContent>
						{config}
					</ReportContent>
				</Trans>
			</SBTypography> */}
			<SBTypography mb={1}>
				<Trans i18nKey="views.report.scarceNeed" lang={getLanguage()}>
					<ReportContent>
						{config}
					</ReportContent>
				</Trans>
			</SBTypography>
			{plan?.csl < plan.minCSL && (
				<SBTypography mb={1}>
					<Trans i18nKey="views.report.onScarcity" lang={getLanguage()}>
						<ReportContent>
							{config}
						</ReportContent>
					</Trans>
				</SBTypography>
			)}
			{plan?.csl > plan.maxCSL && (
				<SBTypography mb={1}>
					<Trans i18nKey="views.report.onExcess" lang={getLanguage()}>
						<ReportContent>
							{config}
						</ReportContent>
					</Trans>
				</SBTypography>
			)}
		</SBBox>
	)
}

interface SBPurchaseReportProps {
	plan: IPurchasePlan;
}
