import { SBBox } from "modules/Commons";
import { Transition } from "react-transition-group";
import { useStyles } from "./style";

export default ({
	selected,
	list,
	summarization,
	getID = (c) => c.id,
}: SBTableSummarizationProps) => {
	const classes = useStyles();

	const value = summarization({
		items: list.filter((d) => selected.indexOf(getID(d)) >= 0),
	});

	const transitionClasses = (state: any) =>
		`${classes.sbTableSummarization} ${state}`;

	return (
		<SBBox>
			<Transition timeout={300} in={!!value}>
				{(
					state: any //TODO: Revisar tipo
				) => <div className={transitionClasses(state)}>{value}</div>}
			</Transition>
		</SBBox>
	);
};

export interface SBTableSummarizationProps {
	selected: any[];
	list: any[];
	summarization: (data: { items: any }) => any;
	getID: (item: any) => any;
}
