import { useSystemLanguage } from "hooks/useSystemLanguage";
import { SBTranslate } from "i18n";
import { t } from "i18next";
import { DateTime } from "luxon";
import { SBCard, SBGrid, SBTypography, SBIcon } from "modules/Commons";
import { MaintenanceStep } from "modules/SystemContext/domain/models/MaintenanceStep";
import { useMemo } from "react";

export default (props: SBCacheStatusCardProps) => {
	const { title, step } = props;

	const { getLanguage } = useSystemLanguage();

	const isError = (status: string) => status === "Error";
	const isDone = (status: string) => status === "Done";

	const [icon, color] = useMemo(() => {
		if (isError(step?.status)) return ["x", "error"];
		if (isDone(step?.status)) return ["check", "success"];
		return ["rotate-cw", "warning"];
	}, [step]);

	return (
		<SBCard style={{ width: "100%" }}>
			<SBGrid py={1} px={2} container>
				<SBGrid item xs={6} display="flex" alignItems="center">
					<SBTypography
						mr={1}
						variant="h6"
						textTransform="capitalize"
					>
						{`${t(title)}`}
					</SBTypography>
				</SBGrid>
				<SBGrid xs={5} item display="flex" flexDirection="column">
					<SBTypography variant="caption">
						<>
							{SBTranslate("startDate")}: {step?.start && DateTime.fromISO(step?.start).toLocaleString(DateTime.DATETIME_SHORT, { locale: getLanguage() })}
						</>
					</SBTypography>
					<SBTypography variant="caption">
						<>
							{SBTranslate("end")}: {step?.end && DateTime.fromISO(step?.end).toLocaleString(DateTime.DATETIME_SHORT, { locale: getLanguage() })}
						</>
					</SBTypography>
				</SBGrid>
				<SBGrid item display="flex" alignItems="center">
					<SBIcon icon={icon} color={color} />
				</SBGrid>
			</SBGrid>
		</SBCard>
	);
};

interface SBCacheStatusCardProps {
	title: string;
	step: MaintenanceStep;
}
