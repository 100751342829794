import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	mainBox: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		position: "relative",
		margin: 0,
		width: "auto",
	},
	selectMainContainer: {
		display: "flex",
		minWidth: 360,
		justifyContent: "space-between",
		width: "auto",
		height: "100%",
		cursor: "pointer",
		border: "1px solid #E2DDF2",
		borderRadius: 4,
		paddingLeft: 4,
		paddingTop: 1.7,
		paddingBottom: 1.7,

		"&.fullWidthMainContainer": {
			minWidth: 210,
		},
	},
	selectMainContent: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
	},
	searchComboContainerBox: {
		width: "calc(100% - 8px)",
		marginLeft: ".5rem",
		display: "none",
		transition: "all 0.25s ease",
	},
	selectLabel: {
		color: "#999",
		paddingLeft: 8,
	},
	openSelectButton: {
		marginLeft: "auto",
		display: "flex",
	},
	chipBox: {
		display: "flex",
		paddingLeft: 4,
	},
	fullWidth: {
		width: "100%",
	},
	selectOpened: {
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
	},
	selectOpenedOptions: {
		display: "inline-block",
	},
}));
