import { IEntity } from "domain/Domain/Entities/IEntity";
import { useRequest } from "hooks/useRequest";
import { MaintenanceResult } from "../domain/models/MaintenanceResult";

export const SystemContextRequester = (() => {
	const request = useRequest("systemContext");

	return {
		...request,
		filterSearch: async ({
			products,
			categories,
			stores,
		}: {
			products: IEntity[];
			categories: IEntity[];
			stores: IEntity[];
		}) => {
			return await request
				.POST("FilterSearch", {
					products,
					categories,
					stores,
				})
				.then((c) => {
					const result = c.data as {
						products: IEntity[];
						categories: IEntity[];
						stores: IEntity[];
					};

					return transformFilters(result);
				});
		},
		systemSearch: (text: string, onlyActive: boolean) => {
			return request
				.GET(
					`systemSearch?searchString=${text}&onlyActive=${onlyActive}`
				)
				.then((c) => {
					const result = c.data as {
						products: IEntity[];
						categories: IEntity[];
						stores: IEntity[];
					};

					return transformFilters(result);
				});
		},
		status: () => {
			return request.GET("status").then(
				(c) =>
					c.data as {
						result: MaintenanceResult;
						latestOrder: string;
						lastDataBaseUpdate: string;
						onMaintenance: boolean;
					}
			);
		},
	};
})();

export const transformFilters = (filter: {
	products: IEntity[];
	stores: IEntity[];
	categories: IEntity[];
}) => {
	const products = filter.products?.map((p: any) => ({
		...p,
		icon: "package",
		color: "primary",
		type: "product",
	}));
	const categories = filter.categories?.map((p: any) => ({
		...p,
		icon: "bookmark",
		color: "secondary",
		type: "category",
	}));

	const stores = filter.stores?.map((p: any) => ({
		...p,
		icon: "home",
		color: "darkPurple",
		type: "store",
	}));

	return {
		products,
		categories,
		stores,
		items: [...products, ...categories, ...stores],
	};
};
