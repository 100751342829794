import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	sbValueWithIndicator: {
		display: "flex",
		alignItems: "center",
		"&.hover:hover": {
			cursor: "pointer",
			background: "rgba(0,0,0,.05)",
			"& p": {
				textDecoration: "underline !important",
			},
		},
		"&.row": {
			justifyContent: "center",
			flexDirection: "column",
		},
	},
	label: {
		display: "flex",
		alignItems: "center",
	},
	indicator: {
		content: "",
		width: 13,
		height: 13,
		borderRadius: "50%",
		"&.nullValueIndicator": {
			backgroundColor: `${theme.palette?.gray3?.main} !important`
		}
	},
	nullValueText: {
		color: theme.palette?.gray4?.main,
	}
}));
