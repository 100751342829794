import { Cell, Row } from "@tanstack/react-table";
import { IEntity } from "domain/Domain/Entities/IEntity";
import SBTableColumn from "../SBTableColumn";

export function SBTableBody<T extends IEntity>(props: SBTableBodyProps<T>) {
	const { rows, onClick } = props;

	return (
		<tbody>
			{rows?.map((row: Row<any>) => {
				return (
					<tr key={row.id}>
						{row.getVisibleCells().map((cell) => (
							<SBTableColumn
								key={cell.id}
								cell={cell}
								row={row}
								isSelected={row.getIsSelected()}
								onClick={() => onClick(cell)}
							/>
						))}
					</tr>
				);
			})}
		</tbody>
	);
}

interface SBTableBodyProps<T extends IEntity> {
	rows: Row<T>[];

	onClick: (entity: Cell<T, any>) => void;
}
