import MixpanelProvider, { useMixpanelContext } from "contexts/MixpanelContext";
import TransferenceContext from "contexts/TransferenceContext";
import { useAuthenticator } from "hooks/useAuthenticator";
import { SBBox, SBLeftbar, SBPrivateRoute, SBTopbar } from "modules/Commons";
import { SBGlobalInventoryiesStatusProvider } from "modules/Inventory/components/SBGlobalInventoriesStatus";
import { SBGlobalInventoriesVirtualStatusProvider } from "modules/Inventory/components/SBGlobalInventoriesVirtualStatus";
import { SBGlobalInventoriesDeadStatusProvider } from "modules/Inventory/components/SBGlobalInventoriesDeadStatus";
import SBPurchaseReportModal from "modules/Purchases/components/SBPurchaseReportModal";
import { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import ConfigsView from "views/ConfigsView";
import DataProofView from "views/DataProofView";
import DistributionView from "views/DistributionView";
import ExplorationDashboardView from "views/ExplorationViews/ExplorationDashboardView";
import { FollowUpView } from "views/FollowUpView";
import HomeTableView from "views/HomeViews/HomeTableView";
import HomeView from "views/HomeViews/HomeView";
import { LoginView } from "views/LoginView";
import { NotFoundView } from "views/NotFoundView";
import PurchasePlanningView from "views/PurchaseViews/PurchasePlanningView";
import PurchaseSuggestionsView from "views/PurchaseViews/PurchaseRecommendationView";
import { SimulationView } from "views/SimulationsView/SimulationView";
import StatusView from "views/StatusViews/StatusView";
import TransferenceView from "views/TransferencesViews/TransferenceView";
import { SBGlobalInventoryInfoProvider } from "../../../modules/Inventory/components/SBGlobalInventoryInfoModal";
import SystemFilterContext from "contexts/SystemFilterContext";
import NotificationView from "views/NotificationView";

export const BaseView = () => {
	const auth = useAuthenticator();
	const [login, setLogin] = useState(auth.isLogged());
	const history = useHistory();
	const { trackLogin } = useMixpanelContext();

	const onLogout = async () => {
		await auth.logout();
		setLogin(false);
		history.push("/");
	};

	const onLogin = async (email: string, password: string) => {
		try {
			await auth.login(email, password);
			trackLogin();
			setLogin(true);
			return true;
		} catch {
			return false;
		}
	};

	const onEnterLogin = () => {
		if (!auth.isLogged()) setLogin(false);
	};

	if (!login)
		return <LoginView onLogin={onLogin} onEnterLogin={onEnterLogin} />;

	return (
		<SystemFilterContext>
			<SBGlobalInventoryInfoProvider>
				<SBGlobalInventoryiesStatusProvider>
					<SBGlobalInventoriesVirtualStatusProvider>
						<SBGlobalInventoriesDeadStatusProvider>
							<SBPurchaseReportModal>
								<TransferenceContext>
									<SBTopbar children={undefined} />
									<SBBox
										display="flex"
										height="100vh"
										pt={10}
										bgcolor="#FAFAFA"
									>
										<SBBox>
											<SBLeftbar onLogout={onLogout} />
										</SBBox>
										<SBBox width="calc(100vw - 9px)">
											<Switch>
												<SBPrivateRoute
													path="/"
													exact
													component={() => <Redirect to="/home" />}
												/>
												<SBPrivateRoute
													path="/followup"
													exact
													component={FollowUpView}
												/>
												<SBPrivateRoute
													path="/purchase/planning"
													exact
													component={PurchasePlanningView}
												/>
												<SBPrivateRoute
													path="/purchase/recomendation"
													exact
													component={PurchaseSuggestionsView}
												/>
												<SBPrivateRoute
													path="/simulation"
													exact
													component={SimulationView}
												/>
												<SBPrivateRoute
													path="/transference"
													exact
													component={TransferenceView}
												/>
												<SBPrivateRoute
													path="/config"
													exact
													component={ConfigsView}
												/>
												<SBPrivateRoute
													path="/status"
													exact
													component={StatusView}
												/>

												<SBPrivateRoute
													path="/home"
													exact
													component={() => (
														<HomeView />
													)}
												/>
												<SBPrivateRoute
													path="/home/details"
													exact
													component={() => (
														<HomeTableView />
													)}
												/>
												<SBPrivateRoute
													path="/distribution"
													exact
													component={DistributionView}
												/>
												<SBPrivateRoute
													path="/exploration"
													exact
													component={ExplorationDashboardView}
												/>
												<SBPrivateRoute
													path="/dataproof"
													exact
													component={DataProofView}
												/>
												<SBPrivateRoute
													path="/notifications"
													exact
													component={NotificationView}
												/>
												<Route
													path="/login"
													component={() => (
														<LoginView
															onLogin={onLogin}
															onEnterLogin={onEnterLogin}
														/>
													)}
												/>
												<SBPrivateRoute
													path="*"
													component={NotFoundView}
												/>
											</Switch>
										</SBBox>
									</SBBox>
								</TransferenceContext>
							</SBPurchaseReportModal>
						</SBGlobalInventoriesDeadStatusProvider>
					</SBGlobalInventoriesVirtualStatusProvider>
				</SBGlobalInventoryiesStatusProvider>
			</SBGlobalInventoryInfoProvider>
		</SystemFilterContext>
	);
};
