import { SBSelect, SBBox, SBPagination, SBTypography } from "modules/Commons";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { SBPaginationProps } from "modules/Commons/SBPagination";

/** Table Pagination */
export default ({
	rows,
	currentPage,
	rowsPerPage,
	handleChangePage,
	handleChangeRowsPerPage,
	fixedRowsPerPage = null,
}: SBTablePaginationProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const rowCount = rows?.length ?? 0;
	const pages = Math.ceil(rowCount / (rowsPerPage ?? 1));
	const listOfRowsPerPage = [1, 5, 10, 15, 25, 50, 100];

	return (
		<SBBox
			className={classes.sbTablePagination}
			display="flex"
			justifyContent="space-between"
			py={1}
			px={2}
		>
			<SBBox display="flex" alignItems="center">
				<SBPagination
					count={pages}
					page={(currentPage ?? 0) + 1}
					onChange={handleChangePage}
					variant="outlined"
					shape="rounded"
				/>
			</SBBox>
			{!fixedRowsPerPage ? (
				<SBBox display="flex" alignItems="center">
					<SBTypography mr={1}>{t("showing")}</SBTypography>
					<SBSelect
						value={rowsPerPage}
						onChange={handleChangeRowsPerPage}
						items={listOfRowsPerPage}
					/>
					<SBTypography ml={1}>{t("resultsPerPage")}</SBTypography>
				</SBBox>
			) : null}
		</SBBox>
	);
};

export interface SBTablePaginationProps extends SBPaginationProps {
	/** Array of items */
	rows?: [];
	/** Page that the table is current in */
	currentPage?: number;
	/** Amount of rows to show on each page */
	rowsPerPage?: number;
	/** Handle change of the page */
	handleChangePage?: (e: any, newValue: any) => void;
	/** Handle change of rows per page */
	handleChangeRowsPerPage?: (e: any) => void;
	/** Flag to indicates if the table will have fixed rows per page */
	fixedRowsPerPage?: boolean | null;
}
