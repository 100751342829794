import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	container: {
		"&.small": {
			height: "135px",
		},
		"& table": {
			borderSpacing: "0px 0px",
			borderCollapse: "separate",

			"&.fullWidth": {
				width: "100%",
			},
			"& td,th": {
				border: `1px solid${theme.palette.primary.main}11`,
				padding: "0 10px",
				whiteSpace: "nowrap",
			},
			"& .hovered": {
				background: `${theme.palette.lightPurple.main} !important`,
			},
			"& thead": {
				top: 0,
				position: "sticky",
				background: "white",
				"& th.firstColumn:first-of-type": {
					position: "sticky",
					zIndex: 1,
					left: 0,
				},
				"& tr": {
					"& th": {
						boxShadow: `0px 1px 0px 0px ${theme.palette.primary.main}`,
					},
				},
				"& tr:first-of-type": {
					"& th": {
						padding: "5px 0px",
						backgroundColor: theme.palette.lightPurple1.main,
						boxShadow: `0px 1px 0px 0px ${theme.palette.primary.main}`,
						color: theme.palette.black.main,
						zIndex: 1,
						textTransform: "uppercase",
						fontSize: "0.7rem",
					},
				},
				"& tr:not(:first-of-type)": {
					"& > th.firstColumn": {
						background: "white",
						zIndex: "0",
					},
					"& th": {
						paddingTop: "8px",
						paddingBottom: "5px",
						textTransform: "uppercase",
						fontSize: "0.6rem",
						color: theme.palette.gray3.main,
						boxShadow: `0px 1px 0px 0px ${theme.palette.black.main}22`,
					},
				},
			},
			"& tbody": {
				"& td:first-of-type": {
					textAlign: "start",
				},
				"& td:not(:first-of-type)": {
					textAlign: "end",
				},
				"& tr:nth-child(odd)": {
					background: `${theme.palette.lightPurple1.main}`,
				},
				"& tr:hover": {
					cursor: "pointer",
					background: `${theme.palette.purple6.main} !important`,
					"& td.firstColumn": {
						background: `${theme.palette.purple6.main} !important`,
					},
				},

				"& td.firstColumn:nth-child(odd)": {
					background: `${theme.palette.lightPurple2.main}`,
				},

				"& tr.oddRow": {
					"& td.firstColumn": {
						background: `${theme.palette.lightPurple2.main}`,
					},
				},
				"&:not(tr.oddRow)": {
					"& td.firstColumn": {
						background: `white`,
					},
				},
				"& td": {},
				"& td.firstColumn": {
					position: "sticky",
					left: 0,
					zIndex: 1,
					boxShadow: `1px 0px 0px 0px ${theme.palette.black.main}22`,
				},
			},
		},
	},
}));
