import { Table } from "@mui/material";
import SBTableEmptyRow from "../../SBTableEmptyRow";
import SBTableHeaders from "../../SBTableHeaders";
import SBTableContent from "../../SBTableContent";
import { SBTableUnfoldingContent } from "../../SBTableUnfoldingContent";
import { useMemo } from "react";
import { SBTableColumn } from "../../models/TableColumn";
import { SBTableColumnGroup } from "../../models/TableColumnGroup";

export default ({
	collapseFieldName,
	rows,
	formTableIndex,
	rowsToShow,
	columns,
	selectable,
	selected,
	modified,
	actionable,
	handleSelectAllClick,
	unfolding,
	unfoldingComponent,
	page,
	rowsPerPage,
	showGroups,
	noEmptyRows = false,
	handleClick = () => false,
	handleCheckboxClick = () => false,
	handleEditableChange = () => false,
	striped = false,
	fullWidth = false,
	getID = (c: any) => c.id,
}: SBDataTableContentProps) => {
	const rowCount = useMemo(
		() =>
			collapseFieldName
				? rows?.flatMap((row) => row[collapseFieldName]).length ?? 0
				: rows?.length ?? 0,
		[rows]
	);

	const selectedCount = useMemo(() => selected.length, [selected]);

	const checked = useMemo(
		() => rowCount > 0 && selectedCount === rowCount,
		[rowCount, selectedCount]
	);

	const indeterminate = useMemo(
		() => selectedCount > 0 && selectedCount < rowCount,
		[rowCount, selectedCount]
	);

	return unfolding ? (
		<SBTableUnfoldingContent
			page={page}
			rows={rows}
			rowsToShow={rowsToShow}
			unfoldingComponent={unfoldingComponent}
		/>
	) : (
		<Table size="small" style={{ width: fullWidth ? "100%" : "initial" }}>
			<SBTableHeaders
				columns={columns!}
				showGroups={showGroups}
				selectable={selectable}
				actionable={actionable}
				onSelectAllClick={handleSelectAllClick}
				checked={checked}
				indeterminate={indeterminate}
			/>
			<SBTableContent
				collapseFieldName={collapseFieldName}
				formTableIndex={formTableIndex}
				showGroups={showGroups}
				rows={rowsToShow}
				columns={columns}
				striped={striped}
				selectable={selectable}
				actionable={actionable}
				isSelected={(id: any) => selected.indexOf(id) !== -1}
				isModified={(id: any) => modified.indexOf(id) !== -1}
				handleEditableChange={handleEditableChange}
				handleClick={handleClick}
				handleCheckboxClick={handleCheckboxClick}
				getID={getID}
			/>
			{!noEmptyRows ? (
				<SBTableEmptyRow
					rows={rows}
					page={page}
					rowsPerPage={rowsPerPage}
					//@ts-ignore
					itemsOnPage={rowsToShow!.length ?? 0}
				/>
			) : null}
		</Table>
	);
};

export interface SBDataTableContentProps {
	collapseFieldName: string | null;
	/** Rows to be shown on the table */
	rows?: any[];
	fullWidth?: boolean;
	/** Index to show which table it is. Usefull when using forms */
	formTableIndex?: number;
	/** Amount to show */
	rowsToShow?: number;
	/** Columns to show on the table */
	columns?: SBTableColumn<any>[] | SBTableColumnGroup[];
	/** Flag to indicate that the table is selectable */
	selectable?: boolean;
	/** Selected items of the table */
	selected?: any;
	/** Modified items of the table */
	modified?: any;
	/** Modified items of the table */
	actionable?: any;
	handleSelectAllClick?: any;
	unfolding?: any;
	unfoldingComponent?: any;
	page?: any;
	rowsPerPage?: any;
	showGroups?: any;
	noEmptyRows?: any;
	handleClick?: any;
	handleCheckboxClick?: any;
	handleEditableChange?: any;
	striped?: any;
	getID?: any;
}
