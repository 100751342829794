import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	configUploaderTipsContainer: {
		padding: "16px 16px 0px",
		width: "100%",
		height: "calc(100% - 240px)",
	},
	configUploaderTips: {
		height: "100%",
		overflowY: "auto",
		"& ul": {
			listStyleType: "disc",
			padding: "8px 8px 8px 20px",
			"& li": {
				padding: "0",
				marginBottom: "18px",
				display: "list-item",
			},
			"& li::marker": {
				fontSize: "1rem",
				color: `${theme.palette.gray3.main}`,
			},
		},
		"&::-webkit-scrollbar": {
			width: "6px",
		},
		"&::-webkit-scrollbar-track": {
			background: `${theme.palette.white.main}`,
		},
		"&::-webkit-scrollbar-thumb": {
			background: `${theme.palette.gray4.main}`,
		},
	},
}));
