import { IEntity } from "domain/Domain/Entities/IEntity";
import { useRouteFilters } from "hooks/useRouteFilters";
import { useStorageCache } from "hooks/useStorageCache";
import { DateTime } from "luxon";
import { SystemContextRequester } from "modules/SystemContext/services/SystemContextRequester";
import routerHistory from "hooks/useRouterHistory";
import useSystemFilters from "hooks/useSystemFilters";
import { useFilterToQueryString } from "hooks/useFilterToQueryString/indext";
import { useDateStorageCache } from "hooks/useDateStorageCache";
import _ from "lodash";
import { StorageKey } from "domain/Utils";

export default () => {
	const queryFilter = useRouteFilters();
	const filtersCache = useStorageCache(StorageKey.SBFilters);
	const dateCache = useDateStorageCache();
	const { fetchQueryString } = useFilterToQueryString();
	const { fromItems, toItems } = useSystemFilters();

	const filterObject = (items: { products: IEntity[], stores: IEntity[], categories: IEntity[] }) => ({
		products: items.products as IEntity[] || [],
		stores: items.stores as IEntity[] || [],
		categories: items.categories as IEntity[] || []
	})
	const validateItems = async () => {
		const filters = filtersCache.getFilterCache();
		const urlFilters = filtersCache.getURLFilters();
		const date = dateCache.getDateItem() || dateCache.getURLDate();

		if (!!filters || filtersCache.hasURLFilters()) {
			let result = await SystemContextRequester.filterSearch({
				products: filters?.products || urlFilters.products,
				stores: filters?.stores || urlFilters.stores,
				categories: filters?.categories || urlFilters.categories
			})
			filtersCache.setFilterCache(filterObject(result));

			var filter = toItems({
				filter: result.items
			});
			const queryString = fetchQueryString({ ...filter, context: DateTime.fromISO(date) });
			routerHistory.push({ search: `${queryString}` });
			dateCache.setIfNotEmpty(queryFilter?.queryFilters?.context?.toISODate());
			return fromItems(filterObject(result));
		}
	};

	const fetchFilters = async () => {
		return await validateItems();
	};

	const setFilters = ({
		date,
		startDate,
		endDate,
		filterItems,
	}: {
		date?: DateTime;
		startDate?: DateTime;
		endDate?: DateTime;
		filterItems: IEntity[];
	}) => {
		if (!date && !startDate && !endDate && !filterItems) return {};

		var filter = toItems({ filter: filterItems });

		filtersCache.setFilterCache(filterObject(filter));

		const queryString = fetchQueryString({
			...filter,
			context: date,
			start: startDate,
			end: endDate,
		});
		routerHistory.push({ search: `${queryString}` });

		return filter;
	};
	return {
		fetchFilters,
		setFilters,
	};
};
