import {
	SBGrid,
	SBBox,
	SBIconButton,
	SBIcon,
	SBActiveLinkIcon,
	SBStyledTooltip,
	SBTypography,
} from "modules/Commons";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { Portal } from "@mui/core";
import SBActiveLink from "modules/Commons/SBForms/SBActiveLink";
import { useClientRules } from "hooks/useClientRules";

export default ({ onLogout }: { onLogout: () => {} }) => {
	const classes = useStyles();
	const rules = useClientRules();
	const { t } = useTranslation();

	const enabledRoutes = rules.views;

	const routes = [
		{
			name: "home",
			title: t("home"),
			to: "/home",
			icon: "home",
			children: [
				{
					name: "home",
					title: t("Home"),
					to: "/home",
				},
				{
					name: "details",
					title: t("details"),
					to: "/home/details",
				},
			]
		},
		{
			name: "purchase",
			title: t("purchase"),
			to: "/purchase/recomendation",
			icon: "shopping-cart",
			children: [
				{
					name: "recommendation",
					title: t("recommendation"),
					to: "/purchase/recomendation",
				},
				{
					name: "planning",
					title: t("planning"),
					to: "/purchase/planning",
				},
			],
		},
		{
			name: "distribution",
			title: t("distribution"),
			to: "/distribution",
			icon: "send",
			children: [
				{
					name: "distribution",
					title: t("distribution"),
					to: "/distribution",
					icon: "send",
				},
			],
		},
		{
			name: "transference",
			title: t("transference"),
			to: "/transference",
			icon: "truck",
			children: [
				{
					name: "transference",
					title: t("recommendation"),
					to: "/transference",
					icon: "truck",
				},
			],
		},
		{
			name: "analytics",
			title: t("analytics"),
			to: "/exploration",
			icon: "activity",
			children: [
				{
					name: "explorations",
					title: t("explorations"),
					to: "/exploration",
				},
				{
					name: "simulation",
					title: t("simulation"),
					to: "/simulation",
				},

			],
		},
		{
			name: "configurations",
			title: t("config"),
			to: "/config",
			icon: "settings",
			children: [
				{
					name: "configurations",
					title: t("config"),
					to: "/config",
				},
				{
					name: "status",
					title: t("status"),
					to: "/status",
				},
			],
		},
	] as Route[];

	const filteredRoutes = routes.filter(
		(route) => enabledRoutes.indexOf(route.name) >= 0
	);

	return (
		<Portal>
			<SBBox className={classes.leftbar}>
				<SBGrid
					container
					direction="column"
					height={1}
					justifyContent="center"
				>
					{filteredRoutes.map((route, index) => (
						<SBBox
							key={route.title}
							mb={
								index === filteredRoutes.length - 1
									? "auto"
									: "0"
							}
							mt={index === 0 ? "auto" : "0"}
						>
							<SBActiveLinkIcon
								title={
									<SBBox pb={1.5}>
										<SBTypography
											mt="8px"
											mb="4px"
											fontWeight={700}
											textTransform="capitalize"
											className={classes.title}
										>
											{route.title}
										</SBTypography>
										{route.children?.map((children) => (
											<SBActiveLink
												key={children.name}
												to={children.to}
											>
												<SBTypography
													textTransform="capitalize"
													color="gray3.main"
													className={
														classes.hoverable
													}
												>
													{children.title}
												</SBTypography>
											</SBActiveLink>
										)) ?? (
												<SBActiveLink
													key={route.name}
													to={route.to}
												>
													<SBTypography
														textTransform="capitalize"
														color="gray3.main"
														className={
															classes.hoverable
														}
													>
														{route.title}
													</SBTypography>
												</SBActiveLink>
											)}
									</SBBox>
								}
								to={route.to}
								icon={route.icon}
							/>
						</SBBox>
					))}
					<SBStyledTooltip
						placement="right"
						title={
							<SBTypography
								p={1}
								variant="caption"
								textTransform="capitalize"
							>
								{t("logout")}
							</SBTypography>
						}
						style={{ marginTop: 10 }}
					>
						<SBBox>
							<SBIconButton onClick={onLogout}>
								<SBIcon icon="power" />
							</SBIconButton>
						</SBBox>
					</SBStyledTooltip>
				</SBGrid>
			</SBBox>
		</Portal>
	);
};

export interface Route {
	name: string;
	title: string;
	to: string;
	icon: string;
	children: Route[];
}
