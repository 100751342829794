import axios, { AxiosResponse } from "axios";
import { StorageKey } from "domain/Utils";
import { useAuthenticator } from "hooks/useAuthenticator";
import { useClientRules } from "hooks/useClientRules";
import { useDateStorageCache } from "hooks/useDateStorageCache";
import Environment from "utils/environment";

export const requester = Object.freeze(() => {
	return { axios, onMaintenance: () => { } };
})();

export const useRequest = (url: string) => {
	const auth = useAuthenticator();
	const { getDateItem } = useDateStorageCache();
	const { contextViews } = useClientRules();

	const hasContext = () => contextViews.some((c) => location.href.includes(c))

	const Request = () =>
		requester.axios.create({
			baseURL: Environment.API + url,
			headers: {
				language:
					localStorage.getItem(StorageKey.SBLanguage)! ??
					navigator.language,
				Authorization: "Bearer " + auth.token(),
				contextDate: hasContext() ? getDateItem() : null
			},
		});

	const OnGeneralError = async <T,>(promise: Promise<T>) => {
		return await new Promise<AxiosResponse>(async (res, rej) => {
			promise
				.then((c: any) => {
					res(c);
				}).catch((e) => {
					if (e.response && e.response.status === 401) {
						auth.logout();
						location.href = "/";
					}
					else if (e.response && e.response.status === 423)
						requester.onMaintenance();
					else
						rej(e);
				});
		})
	};

	return {
		GET: async (url: string) => {
			return await OnGeneralError(Request().get(url));
		},
		POST: async (url: string, value: {}) => {
			return await OnGeneralError(Request().post(url, value));
		},
		PUT: async () => {
			return await OnGeneralError(Request().put(""));
		},
		DELETE: async () => {
			return await OnGeneralError(Request().delete(""));
		}
	};
};
