import {
	Autocomplete,
	CircularProgress,
	TextField
} from "@mui/material";
import { SBTranslate } from "i18n";
import { SBGrid, SBSelect, SBTextField, SBTypography } from "modules/Commons";
import { SourcingType } from "modules/InventoryConfig/models/sourcingType";
import { title } from "process";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { text } from "stream/consumers";
import { TipsContext } from "views/ConfigsView/ConfigTips";

export default ({
	onUpdate,
	sourcingValue,
	suppliers,
	inventoryFormulas,
	stores,
	fetchPropError,
}: any) => {
	const { t } = useTranslation();
	const tipsContext = useContext(TipsContext);
	const [loading, setLoading] = useState(true);

	const [sourcingType, setSourcingType] = useState<SourcingType | null>(
		sourcingValue?.sourcingType ?? null
	);
	const [sourcing, setSourcing] = useState({
		id: sourcingValue?.sourcingID || null,
		label: null,
	});

	const options = [
		{
			text: "notDefined",
			value: SourcingType.NotDefined,
			fieldLabel: "none",
		},
		{
			text: "supplier",
			value: SourcingType.Supplier,
			fieldLabel: "supplier",
			items: suppliers?.map((c: any) => ({
				id: c.id,
				label: c.name + `(${c.clientID})`,
			})),
		},
		{
			text: "distribution",
			value: SourcingType.Distribution,
			fieldLabel: "store",
			items: stores?.map((c: any) => ({
				id: c.id,
				label: c.name + ` (${c.clientID})`,
			})),
		},
	];

	useEffect(() => {
		if (sourcingType === SourcingType.Distribution) {
			setSourcing((s) => ({
				...s,
				label: stores.find((c: any) => +c.id === +sourcing.id)?.name,
			}));
		} else if (sourcingType === SourcingType.Supplier)
			setSourcing((s) => ({
				...s,
				label: suppliers.find((c: any) => +c.id === +sourcing.id)?.name,
			}));
		setLoading(false);
	}, [stores, suppliers, inventoryFormulas]);

	useEffect(() => {
		onUpdate({
			sourcingID: sourcing?.id,
			sourcingType: sourcingType,
		});
	}, [sourcing, sourcingType]);

	const updateInventoryStatus = (value: number) => {
		if (value === SourcingType.NotDefined)
			setSourcingType(null);
		else
			setSourcingType(value);

		setSourcing({ id: null, label: null });
		tipsContext.showTip(
			"Supply" + options.find(r => r.value == value)?.text.capitalize() ?? ""

		);
	}

	return (
		<SBGrid container mt={2}>
			<SBGrid item xs={12} display="flex">
				<SBTypography
					mb={1}
					fontWeight="500"
					color="gray3.main"
					variant={"body1"}
					textTransform={"uppercase"} >
					{SBTranslate("supplyProductBy")}
				</SBTypography>
			</SBGrid>
			<SBGrid item xs={12}>
				<SBSelect
					fullWidth
					getText={item => t(item.text)}
					getValue={item => item.value}
					onChange={(event) => updateInventoryStatus(Number(event.target.value))}
					value={sourcingType ?? SourcingType.NotDefined}
					items={options}
				/>
			</SBGrid>
			<SBGrid item xs={12} mt={1}>
				{options &&
					options.map(
						(option) =>
							option.value === sourcingType &&
							option.items && (
								<Autocomplete
									key={option.value}
									size="small"
									options={option.items}
									getOptionLabel={(option) =>
										option?.label || ""
									}
									loading={loading}
									isOptionEqualToValue={(option, value) => {
										return +option.id === +value.id;
									}}
									renderInput={(params) => (
										<><SBTypography
											mb={1}
											fontWeight="500"
											color="gray3.main"
											variant={"body1"}
											textTransform={"uppercase"}
										>
											{t(
												option.fieldLabel
											).toUpperCase()}
										</SBTypography>
											<TextField
												{...params}
												helperText={fetchPropError(
													"sourcingID"
												)}
												error={!!fetchPropError("sourcingID")}
												InputProps={{
													...params.InputProps
												}}
											/></>
									)}
									value={sourcing}
									onChange={(e, newValue: any) =>
										setSourcing(newValue)
									}
								/>
							)
					)}
			</SBGrid>
		</SBGrid>
	);
};
