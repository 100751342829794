import { Dialog, Divider } from "@mui/material";
import { SBTranslate } from "i18n";
import {
	SBBox,
	SBIcon,
	SBIconButton,
	SBTypography,
} from "modules/Commons";
import ConfigUploaderHelper from "./ConfigUploaderHelper";
import { useStyles } from "./style";
import { useState } from "react";
import CronGeneratorHelper from "./CronGeneratorHelper";
import clsx from "clsx";

export default (props: ConfigUploaderMenuProps) => {
	const { isUploading, setIsUploading, onSave } = props

	const classes = useStyles();
	const [configuringCron, setConfiguringCron] = useState<boolean>(false);
	const [isConcluded, setIsConcluded] = useState<boolean>(false);

	const handleClose = () => setConfiguringCron(false);

	const handleCloseModal = () => setIsUploading(false);

	const handleSave = () => onSave();

	return (
		<SBBox>
			<Dialog
				open={isUploading}
				hideBackdrop
				PaperProps={{
					sx: {
						zIndex: 100,
						margin: 0,
						maxWidth: 1,
						width: configuringCron ? "90vw" : "54vw",
						height: "100%",
						transition: "all 0.5s ease",
						borderRadius: "8px"
					}
				}}
			>
				<SBBox className={classes.configUploaderModal}>
					<SBBox className={clsx(classes.cronGeneratorContainer, configuringCron && "open")}>
						<CronGeneratorHelper close={handleClose} opened={configuringCron} />
					</SBBox>
					<SBBox width="54vw">
						<SBBox className={classes.configUploaderMenuHeader}>
							<SBTypography
								mr="auto"
								fontSize="1rem"
								fontWeight="700"
							>
								{SBTranslate("uploadCSV")}
							</SBTypography>
							<SBIconButton
								onClick={() => {
									setConfiguringCron(false);
									isConcluded ? handleSave() : handleCloseModal()
								}
								}
							>
								<SBIcon icon="x" />
							</SBIconButton>
						</SBBox>
						<Divider />
						<ConfigUploaderHelper
							configuringCron={configuringCron}
							setConfiguringCron={setConfiguringCron}
							setIsConcluded={setIsConcluded}
						/>
					</SBBox>
				</SBBox>
			</Dialog>
		</SBBox>
	);
};

interface ConfigUploaderMenuProps {
	isUploading: any;
	setIsUploading: any;
	onSave: () => void
}