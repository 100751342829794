import { useTranslation } from "react-i18next";
import SBSummarization from "views/Commons/SBSummarization";

export default ({ items }: any) => {
	const { t } = useTranslation();

	const returnFinalValue = (array: any, field: string) => {
		var finalValue = 0;
		for (const a of array) {
			const value = a[field] || 0;
			finalValue += value;
		}
		return finalValue;
	};

	const summarizations = [
		{
			name: t("distributionUnit"),
			icon: "package",
			color: "darkPurple",
			value: returnFinalValue(items, "amount"),
		},
		{
			name: t("volumeTotal"),
			icon: "maximize-2",
			color: "darkPurple",
			value: returnFinalValue(items, "volume"),
		},
		{
			name: t("stores"),
			icon: "package",
			color: "darkPurple",
			value: returnFinalValue(items, "storeQuantity"),
		},
	];

	return <></>;
};
