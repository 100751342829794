import { useSignalR } from "hooks/useSignalR";
import environment from "utils/environment";

export default (props: PubSubProps) => {
	const { hub, subs } = props;

	const { send } = useSignalR({
		url: environment.SignalRAPI + hub,
		events: subs,
	});

	return {
		send,
	};
};

interface PubSubProps {
	hub: string;
	subs: { method: string; on: (...args: any[]) => void }[];
}
