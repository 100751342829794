import { IConfigCSVErrors } from "domain/Config/Models/IConfigCSVErrors";
import { SBTranslate } from "i18n";
import { SBBox, SBTypography } from "modules/Commons";
import { SBNewTable } from "modules/Commons/SBDataDisplays/SBTable/SBNewTable";
import { useConfigErrorsHeaders } from "./useConfigErrorsHeaders";
import { useStyles } from "./style";

export default ({ errors }: ConfigErrosTableProps) => {
	const classes = useStyles();
	const { headers } = useConfigErrorsHeaders();

	return (
		<SBBox width="100%" height="100%">
			<SBTypography variant="body1" fontWeight="800" mb={2}>
				{SBTranslate("uploadErrors", { count: errors.length, errors: errors.length })}
			</SBTypography>
			<SBBox className={classes.errorsTableContainer}>
				<SBNewTable
					startRows={errors}
					defaultColumns={headers}
					onClick={() => { }}
					title={"errors"}
					disableToolbar
					virtualize
					containerHeight={295}
				/>
			</SBBox>
		</SBBox>
	);
};

interface ConfigErrosTableProps {
	errors: IConfigCSVErrors[];
}
