import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import { SBTranslate } from "i18n";
import { SBGrid } from "modules/Commons";
import SBStatisticsCard from "modules/Commons/SBStatisticsCard";
import { useCallback, useMemo } from "react";
import {
	formatCurrency,
	formatCurrencyShort,
	formatInteger,
	formatIntegerShort,
	minmax,
} from "utils/formatters";

export default ({ statistics, isCost }: SBInventoryInfoSectionProps) => {
	const [onHand, onOrder, position, reserved, positionWithoutReserved] =
		useMemo(() => {
			const status = statistics?.currentStatus;
			const onHand = !isCost ? status.onHand : status.onHandCost;
			const onOrder = !isCost ? status.onOrder : status.onOrderCost;
			const position = !isCost ? status.position : status.positionCost;
			const reserved = !isCost ? status.reserved : status.reservedCost;
			const positionWithoutReserved = !isCost
				? status.positionWithoutReserved
				: status.positionWithoutReservedCost;

			return [
				{
					value: onHand?.sum.value,
					min: onHand?.min.value,
					max: onHand?.max.value,
				},
				{
					value: onOrder?.sum.value,
					min: onOrder?.min.value,
					max: onOrder?.max.value,
				},
				{
					value: position?.sum.value,
					min: position?.min.value,
					max: position?.max.value,
				},
				{
					value: reserved?.sum.value,
					min: reserved?.min.value,
					max: reserved?.max.value,
				},
				{
					value: positionWithoutReserved?.sum.value,
					min: positionWithoutReserved?.min.value,
					max: positionWithoutReserved?.max.value,
				},
			];
		}, [isCost]);

	const usingShortFormatter = useCallback(() => {
		return isCost ? formatCurrencyShort : formatIntegerShort;
	}, [isCost]);

	const usingFormatter = useCallback(() => {
		return isCost ? formatCurrency : formatInteger;
	}, [isCost]);

	const formatMinMax = (data: { min: number; max: number }) => {
		return minmax(usingShortFormatter()(data.min), usingShortFormatter()(data.max));
	};

	return (
		<SBGrid container columns={15} spacing={5}>
			<SBGrid item xs={3}>
				<SBStatisticsCard
					tootltip={SBTranslate("tips.onHand")}
					item={{
						label: SBTranslate("onHand"),
						value: onHand.value,
						range: formatMinMax(onHand),
						formatter: usingShortFormatter(),
					}}
				/>
			</SBGrid>
			<SBGrid item xs={3}>
				<SBStatisticsCard
					tootltip={SBTranslate("tips.onOrder")}
					item={{
						label: SBTranslate("onOrder"),
						value: onOrder?.value,
						range: formatMinMax(onOrder),
						formatter: usingShortFormatter(),
					}}
				/>
			</SBGrid>
			<SBGrid item xs={3}>
				<SBStatisticsCard
					tootltip={SBTranslate("tips.position")}
					item={{
						label: SBTranslate("position"),
						value: position?.value,
						range: formatMinMax(position),
						formatter: usingShortFormatter(),
					}}
				/>
			</SBGrid>
			<SBGrid item xs={3}>
				<SBStatisticsCard
					tootltip={SBTranslate("tips.pending")}
					item={{
						label: SBTranslate("commons.pending"),
						value: reserved.value,
						range: formatMinMax(reserved),
						formatter: usingShortFormatter(),
					}}
				/>
			</SBGrid>
			<SBGrid item xs={3}>
				<SBStatisticsCard
					tootltip={SBTranslate("tips.positionWithoutPending")}
					item={{
						label: `${SBTranslate(
							"commons.positionWithoutPending"
						)}`,
						value: positionWithoutReserved?.value,
						range: formatMinMax(positionWithoutReserved),
						formatter: usingShortFormatter(),
					}}
				/>
			</SBGrid>
		</SBGrid>
	);
};

interface SBInventoryInfoSectionProps {
	statistics: IHomeStatistics;
	isCost: boolean;
}
