import { useAuthenticator } from "hooks/useAuthenticator";
import { Route, Redirect } from "react-router-dom";
import { SBBox } from "modules/Commons";

export default ({ component: Component, children, ...rest }: any) => {
	const auth = useAuthenticator();
	return (
		<Route
			{...rest}
			render={(props) =>
				auth.isLogged() ? (
					<SBBox pr={2} height={1}>
						<Component {...props} />
					</SBBox>
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: props.location },
						}}
					/>
				)
			}
			children={children}
		/>
	);
};
