import { FormControlLabel, Stack } from "@mui/material";
import { SBBox, SBChip, SBGrid, SBSwitch, SBTypography } from "modules/Commons";
import { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SBSystemSearch from "views/Commons/SBSystemSearch";
import { TipsContext } from "views/ConfigsView/ConfigTips";
import ConfigGroupmentDivider from "../ConfigGroupmentDivider";
import ConfigTextField from "../ConfigTextField";

export default ({ values, fetchPropError }: any) => {
	const { t } = useTranslation();
	const { getValues, setValue, register } = useFormContext();

	const [updateFilter, setUpdateFilter] = useState(false);
	const tipsContext = useContext(TipsContext);

	const buildFilterItems = () =>
		!!values
			? [
				getValues("products")?.map((p: any) => ({
					...p,
					color: "primary",
					icon: "package",
					type: "product",
				})) ?? [],
				getValues("stores")?.map((s: any) => ({
					...s,
					color: "darkPurple",
					icon: "home",
					type: "store",
				})) ?? [],
				getValues("categories")?.map((c: any) => ({
					...c,
					color: "secondary",
					icon: "bookmark",
					type: "category",
				})) ?? [],
			]
			: [];

	const selectedFiltersChips = buildFilterItems();
	const [filterItems, setFilterItems] = useState(
		selectedFiltersChips.flatMap((i) => i)
	);
	const updateFilterItems = (newItems: any[]) => {
		setFilterItems(newItems);
		setValue(
			"products",
			newItems.filter((c) => c.type === "product")
		);
		setValue(
			"categories",
			newItems.filter((c) => c.type === "category")
		);
		setValue(
			"stores",
			newItems.filter((c) => c.type === "store")
		);
	};

	const onRemoveFromChip = (itemName: string) => {
		const newFilterItems = filterItems.filter((v) => v.name !== itemName);
		updateFilterItems(newFilterItems);
		setUpdateFilter((c) => !c);
	};

	const getStartActive = getValues("active");
	const getStartBlockOptimization = getValues("blockOptimization");

	return (
		<SBBox>
			<ConfigGroupmentDivider title={t("nameThisConfiguration")} />
			<SBGrid alignItems="center">
				<SBGrid item xs={7}>
					<ConfigTextField
						section="filter"
						prop="name"
						placeholder={t("name")}
						title={t("name")}
						helperText={fetchPropError("name")}
						onClick={() => {
							tipsContext.showTip("FilterName");
						}}
					/>
				</SBGrid>
			</SBGrid>
			<SBGrid container mt={2}>
				<Stack>
					<FormControlLabel
						label={t("activeConfig") as string}
						{...register("active")}
						control={
							<SBSwitch
								defaultChecked={getStartActive}
								onClick={() => {
									tipsContext.showTip("FilterActive");
								}}
							/>
						}
					/>
				</Stack>
				<Stack>
					<FormControlLabel
						label={t("blockOptimization") as string}
						{...register("blockOptimization")}
						control={
							<SBSwitch
								defaultChecked={getStartBlockOptimization}
								onClick={() => {
									tipsContext.showTip("BlockOptimization");
								}}
							/>
						}
					/>
				</Stack>
			</SBGrid>
			<SBBox id="create-inventory-menu-filter-select" mt={2}>
				<SBBox
					onClick={() => {
						tipsContext.showTip("FilterFilters");
					}}
				>
					<ConfigGroupmentDivider title={t("selectFilters")} />
					{(fetchPropError("") ||
						fetchPropError("products") ||
						fetchPropError("categories") ||
						fetchPropError("stores")) && (
							<SBTypography color="error" variant="caption" mb={1}>
								{t("filterEmpty")}
							</SBTypography>
						)}
				</SBBox>
				<SBSystemSearch
					solo
					key={+updateFilter}
					filterIsolated="category"
					fullWidth
					onlyActives={false}
					loading={false}
					onChange={(items: any) => updateFilterItems(items)}
					onClick={() => tipsContext.showTip("FilterFilters")}
					defaultItems={filterItems}
					filterType={filterItems[0]?.type}
					categoryDeep={filterItems[0]?.deep}
				/>
				<SBGrid container spacing={1} marginTop={2}>
					{selectedFiltersChips &&
						selectedFiltersChips.map(
							(list, index) =>
								list.length > 0 && (
									<SBGrid item xs={6} mt={1} key={index}>
										{list.map((item: any) => (
											<SBBox mb={1} key={item.name}>
												<SBChip
													icon={item.icon}
													color={item.color}
													label={`(${item.clientID}) ${item.name}`}
													onDelete={() =>
														onRemoveFromChip(
															item.name
														)
													}
												/>
											</SBBox>
										))}
									</SBGrid>
								)
						)}
				</SBGrid>
			</SBBox>
		</SBBox>
	);
};
