import clsx from "clsx";
import { DateTime } from "luxon";
import { SBBox } from "modules/Commons";
import { DatePositionVerifier } from "../../services/datePositionVerifier";
import { useStyles } from "./style";
import { useMemo } from "react";

export default (props: SBDatePickerButtonProps) => {
	const classes = useStyles();
	const {
		index,
		buttonProps,
		onSelectDate,
		startDate,
		endDate,
		range,
		validItems,
		lineSize,
		minDate,
		maxDate,
		availableDates,
	} = props;

	const datePositionVerifier = useMemo(
		() =>
			DatePositionVerifier(
				buttonProps,
				validItems,
				startDate,
				endDate,
				minDate,
				maxDate,
				availableDates,
				lineSize
			),
		[
			buttonProps,
			validItems,
			startDate,
			endDate,
			lineSize,
			minDate,
			maxDate,
			availableDates,
		]
	);

	const directionsStatus = useMemo(() => {
		return datePositionVerifier.checkAllDirections(index);
	}, [index, datePositionVerifier]);

	const getDimension = lineSize === 4 ? 54 : 32;

	const isFillDays = () => !buttonProps.label;

	const handleItemClick = () =>
		buttonProps.date ? onSelectDate(buttonProps.date) : null;

	return (
		<SBBox
			className={clsx(
				classes.itemBaseContainer,
				!isFillDays() ? "hoverable" : "",
				!range && directionsStatus.isLastDataBaseUpdate ? "isLastDataBaseUpdate" : "",
				range && directionsStatus.isStartDate ? "active active-right" : "",
				range && directionsStatus.isEndDate ? "active active-left" : "",
				!directionsStatus.isAnAvailableDate ? "disabled" : "",
				range && !directionsStatus.isLowerThanMinDate ? "disabled" : "",
				range && !directionsStatus.isGreaterThanMaxDate ? "disabled" : "",
				range && directionsStatus.isOnMiddleOfRange ? "on-middle" : "",
				range && directionsStatus.topRight ? "top-right-radius" : "",
				range && directionsStatus.bottomRight ? "bottom-right-radius" : "",
				range && directionsStatus.topLeft ? "top-left-radius" : "",
				range && directionsStatus.bottomLeft ? "bottom-left-radius" : ""
			)}
		>
			<SBBox
				onClick={handleItemClick}
				style={{
					width: getDimension,
					height: getDimension,
				}}
				color={isFillDays() ? "#ccc" : ""}
				className={clsx(
					classes.item,
					range && (directionsStatus.isStartDate || directionsStatus.isEndDate)
						? "active"
						: ""
				)}
				dangerouslySetInnerHTML={{
					__html: isFillDays() ? "" : buttonProps.label,
				}}
			/>
		</SBBox>
	);
};

export interface SBDatePickerButtonProps {
	/** Position of the button inside the array */
	index: number;
	/** Amount of items inside a line of the datepicker */
	lineSize: 4 | 7;
	/** Start date of the datePicker */
	startDate: DateTime | undefined;
	/** End date of the datePicker */
	endDate: DateTime | undefined;
	/** Flag to enable the DatePicker to have a range selection */
	range?: boolean;
	/** Min date of the datePicker */
	minDate: DateTime | undefined;
	/** Max date of the datePicker */
	maxDate: DateTime | undefined;
	/** Available dates for the datePicker */
	availableDates: string[] | undefined;
	/** Properties of the button */
	buttonProps: { label: string; date: DateTime };
	/** Valid Items of buttonProps */
	validItems: Array<{ label: string; date: DateTime }>;
	/** Event that will be trigger when clicked on the button */
	onSelectDate: (item: DateTime) => void;
}
