import { Chip } from "@mui/material";
import { SBBox, SBIcon, SBTypography } from "modules/Commons";

export default ({
	icon,
	color,
	label,
	onDelete,
	fullWidth = true,
	disabled = false,
	fontWeight = "normal",
	variant,
	onClick,
}: SystemSearchChipProps) => {
	return (
		<Chip
			data-testid="sb-chip"
			color={color}
			variant={variant}
			size="small"
			onDelete={onDelete}
			onClick={onClick}
			disabled={disabled}
			style={{
				display: "flex",
				justifyContent: fullWidth ? "space-between" : "center",
			}}
			label={
				<SBBox display="flex">
					{icon && (
						<SBIcon
							icon={icon}
							size={15}
							color="white"
							style={{ marginBottom: 1 }}
						/>
					)}
					<SBTypography
						ml={!!icon ? 1 : 0}
						variant="caption"
						noWrap
						style={{ maxWidth: "100%", fontWeight: fontWeight }}
					>
						{label}
					</SBTypography>
				</SBBox>
			}
		/>
	);
};

interface SystemSearchChipProps {
	/** Chip icon */
	icon?: string;
	/** Chip color */
	color?:
	| "default"
	| "primary"
	| "secondary"
	| "error"
	| "info"
	| "success"
	| "warning"
	| undefined
	| any;
	/** Title of the chip */
	label?: string | JSX.Element;
	/** Function to trigger the chip removal when this function is not null */
	onDelete?: () => void;
	/** Function to trigger on click the chip */
	onClick?: () => void;
	/** Indicates if will occupy the full width */
	fullWidth?: boolean;
	/** Indicates if will disable the chip */
	disabled?: boolean;
	/** Style variable of the chip */
	variant?: "outlined" | "filled";
	/** Style variable of the label */
	fontWeight?: "bold" | "normal";
}
