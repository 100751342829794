import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import { SBTranslate, SBTranslateType } from "i18n";
import { SBBox, SBCard, SBOutlinedButton, SBTypography } from "modules/Commons";
import { ReactNode } from "react";
import { useStyles } from "./style";

export default ({ statistics, icon, onSeeAll, hasStats, title, subTitle, description }: SBVirtualStockBaseCardProps) => {
	const classes = useStyles();

	return (
		<SBCard className={classes.card}>
			<SBBox className={classes.container} justifyContent={hasStats ? "space-between" : "center"}>
				<SBBox className={classes.section}>
					{icon}
					<SBTypography className={classes.title}>
						{SBTranslate(title)}
					</SBTypography>

				</SBBox>
				<SBBox className={classes.section}>
					<SBTypography className={classes.subTitle}>
						{SBTranslate(subTitle)}
					</SBTypography>
					<SBTypography className={classes.description}>
						{SBTranslate(description)}
					</SBTypography>
				</SBBox>

				{hasStats && (
					<>
						<SBBox className={classes.section}>
							<SBTypography className={classes.count}>
								{statistics?.evaluation?.virtualStockCount?.value}
							</SBTypography>
							<SBTypography className={classes.countLabel}>
								{SBTranslate("stock")}
							</SBTypography>
							{onSeeAll &&
								(<SBOutlinedButton fullWidth className={classes.action} onClick={onSeeAll}>
									<SBTypography textTransform="none">
										{SBTranslate("commons.seeAll")}
									</SBTypography>
								</SBOutlinedButton>)
							}
						</SBBox>
					</>
				)}
			</SBBox>
		</SBCard>
	);
};

interface SBVirtualStockBaseCardProps {
	title: SBTranslateType,
	subTitle: SBTranslateType,
	description: SBTranslateType,


	statistics: IHomeStatistics;
	icon: ReactNode;
	hasStats?: boolean;
	onSeeAll?: () => void;
}
