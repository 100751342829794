import SBBox from "modules/Commons/SBLayouts/SBBox";
import { useStyles } from "./styles";
import error from "assets/error_icon.png";
import succeed from "assets/succeed_icon.png";
import { TransferenceResponse } from "modules/Transferences/models/transferenceResponse";
import FailedTransferencesInfo from "../FailedTransferencesInfo";
import TransferenceStatusCountInfo from "../TransferenceStatusCountInfo";

export default ({
	completedTransferencessCount,
	failedTransferencessCount,
	failedTransferences,
	maximized,
}: TransferenceProcessInfoProps) => {
	const classes = useStyles();

	return (
		<SBBox className={classes.modalContent}>
			<SBBox mt={2}>
				<TransferenceStatusCountInfo
					icon={succeed}
					statusLabel="completed"
					statusCount={completedTransferencessCount}
				/>
			</SBBox>
			<SBBox mt={2} mb={1}>
				<TransferenceStatusCountInfo
					icon={error}
					statusLabel="errors"
					statusCount={failedTransferencessCount}
				/>
			</SBBox>
			{failedTransferencessCount > 0 ? (
				<FailedTransferencesInfo
					failedTransferences={failedTransferences}
					maximized={maximized}
				/>
			) : (
				<></>
			)}
		</SBBox>
	);
};

interface TransferenceProcessInfoProps {
	completedTransferencessCount: number;
	failedTransferencessCount: number;
	failedTransferences: TransferenceResponse[];
	maximized: boolean;
}
