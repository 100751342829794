import clsx from "clsx";
import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import useFilterCache from "hooks/useFilterCache";
import { useSystemLanguage } from "hooks/useSystemLanguage";
import { SBTranslate } from "i18n";
import {
	SBBox,
	SBCard,
	SBGrid,
	SBTooltip,
	SBTypography,
} from "modules/Commons";
import SBValuesGauge from "modules/Commons/SBDataDisplays/SBValuesGauge";
import { useGlobalInventoriesStatusContext } from "modules/Inventory/components";
import { NotificationsRequester } from "modules/Notifications/services/NotificationsRequester";
import { useMemo } from "react";
import { useSystemTheme } from "themes/base";
import { formatInteger, percentageFormatter } from "utils/formatters";
import { useStyles } from "./style";
import { useSystemFilterContext } from "contexts/SystemFilterContext";

enum filtersTypes {
	Store = "store",
	Category = "category",
	Product = "product"
}

export default ({ statistics }: SBInventoryGaugeInfoProps) => {
	const theme = useSystemTheme();
	const classes = useStyles();
	const { getLanguage } = useSystemLanguage();
	const { filtersContext } = useSystemFilterContext();
	const { fetchData } = useGlobalInventoriesStatusContext();

	const filters = {
		stores: filtersContext?.items?.filter(item => item.type === filtersTypes.Store),
		categories: filtersContext?.items?.filter(item => item.type === filtersTypes.Category),
		products: filtersContext?.items?.filter(item => item.type === filtersTypes.Product)
	}

	const getTotal = () =>
		statistics?.currentStatus?.excessive +
		statistics?.currentStatus?.scarce +
		statistics?.currentStatus?.balanced ?? 0;

	const balanced = useMemo(
		() => ({
			color: theme.palette.balanced.main,
			label: SBTranslate("commons.balanced"),
			value: statistics?.currentStatus?.balanced,
			formattedValue: `(${formatInteger(
				(statistics?.currentStatus?.balanced / getTotal()) * 100
			)}%)`,
		}),
		[statistics, getLanguage()]
	);

	const scarce = useMemo(
		() => ({
			color: theme.palette.scarce.main,
			label: SBTranslate("commons.scarce"),
			value: statistics?.currentStatus?.scarce,
			formattedValue: `(${formatInteger(
				(statistics?.currentStatus?.scarce / getTotal()) * 100
			)}%)`,
		}),
		[statistics, getLanguage()]
	);
	const excessive = useMemo(
		() => ({
			color: theme.palette.excess.main,
			label: SBTranslate("commons.excess"),
			value: statistics?.currentStatus?.excessive,
			formattedValue: `(${formatInteger(
				(statistics?.currentStatus?.excessive / getTotal()) * 100
			)}%)`,
		}),
		[statistics, getLanguage()]
	);

	const total = useMemo(
		() => ({
			color: theme.palette.black.main,
			label: "",
			value: excessive.value + scarce.value + balanced.value,
		}),
		[statistics, getLanguage()]
	);

	const handleBalancedClick = async () => {
		fetchData(NotificationsRequester.balanced(filters), {
			title: "balancedInventories",
			columnName: "commons.balanced",
		});
	};

	const handleUnbalancedClick = async () => {
		fetchData(NotificationsRequester.unbalanced(filters), {
			title: "unbalancedInventories",
			columnName: "unbalanced",
		});
	};

	const handleExcessClick = async () => {
		fetchData(NotificationsRequester.excess(filters), {
			title: "excessInventories",
			columnName: "excess",
		});
	};

	const hancleScarceClick = async () => {
		fetchData(NotificationsRequester.scarcity(filters), {
			title: "scarceInventories",
			columnName: "scarce",
		});
	};

	const outter = [
		{
			name: SBTranslate("commons.onHand"),
			value: balanced.value,
			color: theme.palette.primary.main,
		},
		{
			name: SBTranslate("commons.excess"),
			value: excessive.value,
			color: theme.palette.excess.main,
		},
		{
			name: SBTranslate("commons.scarce"),
			value: scarce.value,
			color: theme.palette.scarce.main,
		},
	];

	const inner = [
		{
			name: SBTranslate("commons.balanced"),
			value: balanced.value,
			color: theme.palette.primary.main,
			onClick: handleBalancedClick,
		},
		{
			name: SBTranslate("commons.unbalanced"),
			value: scarce.value + excessive.value,
			color: theme.palette.error.main,
			onClick: handleUnbalancedClick,
			subItems: [
				{
					name: SBTranslate("excess"),
					value: excessive.value,
					color: theme.palette.excess.main,
					onClick: handleExcessClick,
				},
				{
					name: SBTranslate("scarce"),
					value: scarce.value,
					color: theme.palette.scarce.main,
					onClick: hancleScarceClick,
				},
			],
		},
	];

	return (
		<SBCard style={{ height: "100%" }}>
			<SBBox height={1} alignItems="center" p={2}>
				<SBTooltip
					title={SBTranslate("tips.inventoryStatus")}
					placement="top"
				>
					<SBTypography
						fontWeight="700"
						color="gray2.main"
						mb={3.5}
					>
						{SBTranslate("commons.inventoryStatus")}
					</SBTypography>
				</SBTooltip>
				<SBBox>
					<SBValuesGauge
						inner={inner}
						outter={outter}
						total={total.value}
					/>
				</SBBox>
				<SBGrid container>
					{inner.map((c) => {
						return (
							<SBGrid item xs={12} width={1} position="relative">
								{!!c.subItems && (
									<SBBox
										style={{
											position: "absolute",
											content: "",
											width: 2,
											height: 69,
											left: 21,
											top: 15,
											background: c.color,
										}}
									/>
								)}
								<SBBox
									display="flex"
									alignItems="center"
									onClick={c.onClick}
									className={clsx(classes.gaugeInfo, "hover")}
									py={1}
									px={2}
								>
									<SBBox
										mr={2}
										style={{
											content: "",
											width: 13,
											height: 13,
											borderRadius: "50%",
											background: c.color,
										}}
									/>
									<SBTypography
										textAlign="center"
										color="gray2.main"
										textTransform="capitalize"
										noWrap
										className="name"
									>
										{c.name}
									</SBTypography>
									<SBBox
										display="flex"
										justifyContent="center"
										ml="auto"
									>
										<SBTypography textAlign="center" mr={1}>
											{c.value}
										</SBTypography>
										<SBTypography
											textAlign="center"
											textTransform="uppercase"
											color="gray2.main"
											noWrap
										>
											(
											{percentageFormatter(
												c.value / total.value
											)}
											)
										</SBTypography>
									</SBBox>
								</SBBox>

								<SBBox
									display="flex"
									flexDirection="column"
									width={1}
								>
									{c.subItems?.map((subItem) => {
										return (
											<SBBox
												display="flex"
												alignItems="center"
												onClick={subItem.onClick}
												className={clsx(
													classes.gaugeInfo,
													"hover"
												)}
												py={1}
												pr={2}
												pl={5}
											>
												<SBBox
													style={{
														position: "absolute",
														content: "",
														width: 19,
														height: 10,
														top: 10,
														left: 21,
														borderBottom: `2px solid ${c.color}`,
														borderBottomLeftRadius:
															"20px",
														borderLeft: `2px solid ${c.color}`,
													}}
												/>
												<SBBox
													mr={2}
													style={{
														content: "",
														width: 13,
														height: 13,
														borderRadius: "50%",
														background:
															subItem.color,
													}}
												/>
												<SBTypography
													textAlign="center"
													textTransform="capitalize"
													color="gray2.main"
													noWrap
													className="name"
												>
													{subItem.name}
												</SBTypography>
												<SBBox
													display="flex"
													justifyContent="center"
													ml="auto"
												>
													<SBTypography
														textAlign="center"
														mr={1}
													>
														{subItem.value}
													</SBTypography>
													<SBTypography
														textAlign="center"
														textTransform="uppercase"
														color="gray2.main"
														noWrap
													>
														(
														{percentageFormatter(
															subItem.value /
															total.value
														)}
														)
													</SBTypography>
												</SBBox>
											</SBBox>
										);
									})}
								</SBBox>
							</SBGrid>
						);
					})}
				</SBGrid>
			</SBBox>
		</SBCard>
	);
};

interface SBInventoryGaugeInfoProps {
	statistics: IHomeStatistics;
	risk?: boolean;
}
