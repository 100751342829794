import { Stack } from "@mui/material";
import { SBTranslate } from "i18n";
import { SBChip } from "modules/Commons";
import { useState } from "react";

export default ({ emergencialStatus, filterByColumnName }: SBPurchaseTableProps) => {
	const [isEmergencial, setIsEmergencial] = useState<boolean | undefined>(emergencialStatus);

	const handleFilter = (value: boolean | undefined) => {
		if (value == isEmergencial) {
			setIsEmergencial(undefined)
			filterByColumnName("emergencial", undefined);
		} else {
			setIsEmergencial(value)
			filterByColumnName("emergencial", value);
		}
	}

	return <Stack width={1} ml="auto" spacing={2} direction="row">
		<SBChip
			color={isEmergencial == null ? "primary" : "gray4"}
			label={SBTranslate("all")}
			variant="outlined"
			onClick={() => handleFilter(undefined)} />
		<SBChip
			color={isEmergencial == true ? "error" : "gray4"}
			label={SBTranslate("emergencial")}
			variant="outlined"
			onClick={() => handleFilter(true)} />
		<SBChip
			color={isEmergencial == false ? "success" : "gray4"}
			label={SBTranslate("recommended")}
			variant="outlined"
			onClick={() => handleFilter(false)} />
	</Stack>;
};

interface SBPurchaseTableProps {
	filterByColumnName: (key: string, v: any) => void;
	emergencialStatus?: boolean;
}
