import { ITimeScope } from "domain/Domain/Models/ITimeScope";
import { ComposedFilterEvent, IComposedFilter } from "domain/Utils";
import useFilterCache from "hooks/useFilterCache";
import { useRouteFilters } from "hooks/useRouteFilters";
import _, { isArray } from "lodash";
import { SBBox, SBDateSlider, SBGrid } from "modules/Commons";
import { DateRangeType } from "modules/Commons/SBDate/models/dateRangeType";
import { cloneElement, ReactNode, useEffect, useState } from "react";
import SBActionsMenu, { SBActionsMenuProps } from "../SBActionsMenu";
import SBDatePickerMenu from "../SBDatePickerMenu";
import SBSystemSearch from "../SBSystemSearch";
import { IDatePicker } from "domain/Utils/models/IDatePicker";
import { useDateStorageCache } from "hooks/useDateStorageCache";
import { DateTime } from "luxon";
import SBApplyButton, { SBApplyButtonProps } from "../SBApplyButton";
import { useSystemFilterContext } from "contexts/SystemFilterContext";

const SBComposedFilterBase = ({
	children,
	onChange,
	onApply,
	defaultFilter = [],
	filterRequired = false,
	dateRangeRequired = false,
	defaultRangeType = DateRangeType.Daily,
}: {
	children?: ReactNode | ReactNode[];
	onChange?: (p: IComposedFilter) => any;
	onApply?: (p: IComposedFilter) => any;
	defaultFilter?: any[];
	filterRequired?: boolean;
	dateRangeRequired?: boolean;
	defaultRangeType?: DateRangeType;
}) => {
	const { queryFilters } = useRouteFilters();
	const { setFilters } = useFilterCache();
	const { filtersContext } = useSystemFilterContext();

	const dateRangeType = defaultRangeType;

	const { getDateItem } = useDateStorageCache();

	const [slider, setSlider] = useState<ITimeScope>({} as any);

	const [picker, setPicker] = useState<IDatePicker>({
		date: getDateItem() ? DateTime.fromISO(getDateItem()) : queryFilters.context,
		range: {
			startDate: queryFilters.start,
			endDate: queryFilters.end,
		}
	});
	const [filter, setFilter] = useState(filtersContext?.items ?? (queryFilters.filters || defaultFilter));

	useEffect(() => {
		setFilter(filtersContext?.items ?? []);
	}, [filtersContext?.items])

	const handleOnChange = (props: any, event: ComposedFilterEvent) => {
		let changes = { slider, picker, filter };
		switch (event) {
			case ComposedFilterEvent.Picker:
				setPicker(props);
				changes = { ...changes, picker: props };
				break;
			case ComposedFilterEvent.Slider:
				setSlider(props);
				changes = { ...changes, slider: props };
				break;
			case ComposedFilterEvent.Filter:
				setFilter(props);
				changes = { ...changes, filter: props };
				break;
		}

		onChange && onChange({ ...changes, event });
	};

	const getNotEmptyProps = () => {
		let v = {};
		if (!_.isEmpty(picker)) v = { ...v, picker };
		if (!_.isEmpty(slider)) v = { ...v, slider };
		if (!_.isEmpty(filter)) v = { ...v, filter };
		return v;
	};

	const getSliderProps = () => {
		return {
			startDay: picker.range?.startDate?.day,
			startMonth: picker.range?.startDate?.month,
			startYear: picker.range?.startDate?.year,
			endDay: picker.range?.endDate?.day,
			endMonth: picker.range?.endDate?.month,
			endYear: picker.range?.endDate?.year,
		};
	};

	const createChild = (child: ReactNode, index: number = 0) => {
		const name = (child as any).type.displayName;
		const typelessChild = child as any;
		if (!name) return typelessChild;

		const date = picker.date || typelessChild.props.date;
		const startDate = picker.range?.startDate || typelessChild.props.startDate;
		const endDate = picker.range?.endDate || typelessChild.props.endDate;

		const filterBlock = filterRequired && (!filter || filter.length <= 0);
		const rangeBlock = dateRangeRequired && !(startDate || endDate);

		const disabled = filterBlock || rangeBlock;

		return cloneElement(typelessChild, {
			key: name + index,
			items: getNotEmptyProps(),
			...getSliderProps(),
			date,
			startDate: startDate,
			endDate,
			disabled,
			dateRangeType,
			defaultItems: filter,
			onChange: (props: any) => handleOnChange(props, name),
			onClickParams: () => ({
				date: picker.date ?? DateTime.fromISO(getDateItem()),
				startDate: picker.range?.startDate,
				endDate: picker.range?.endDate,
				filter: filter,
			}),
			onApply: () => {
				onApply && onApply(getNotEmptyProps());

				setFilters({
					date: picker.date ?? DateTime.fromISO(getDateItem()),
					startDate: picker.range?.startDate,
					endDate: picker.range?.endDate,
					filterItems: filter,
				});
			},
		});
	};

	return (
		<SBBox height={1} width={1}>
			<SBGrid container display="flex">
				{isArray(children)
					? children?.map((child, index) => createChild(child, index))
					: createChild(children)}
			</SBGrid>
		</SBBox>
	);
};

const Base = SBComposedFilterBase;
//@ts-ignore
Base.displayName = "Base";

const Picker = SBDatePickerMenu;
Picker.displayName = ComposedFilterEvent.Picker;

const Slider = SBDateSlider;
Slider.displayName = ComposedFilterEvent.Slider;

const Filter = SBSystemSearch;
//@ts-ignore
Filter.displayName = ComposedFilterEvent.Filter;

const Actions = (props: SBActionsMenuProps) => {
	return (
		<SBBox mx={1}>
			<SBActionsMenu {...props} />
		</SBBox>
	);
};
//@ts-ignore
Actions.displayName = ComposedFilterEvent.Actions;

const Applicator = (props: SBApplyButtonProps) => {
	return (
		<SBApplyButton {...props} />
	);
};
//@ts-ignore
Applicator.displayName = "Applicator";

export default {
	Base,
	Picker,
	Slider,
	Filter,
	Applicator,
	Actions,
};
