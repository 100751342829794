import { IConfigCSVErrors } from "domain/Config/Models/IConfigCSVErrors";
import useTableColumnBuilder from "hooks/useTableColumnBuilder";
import { SBTranslate } from "i18n";

export const useConfigErrorsHeaders = () => {
	const { add, build } = useTableColumnBuilder<IConfigCSVErrors>();

	const linesFormatter = (lines: number[]) => {
		return `(${String(lines)})`
	}

	add({
		id: () => "errors",
		name: "uploadErrorsTitle",
		headers: [
			{
				id: (c) => c.field,
				key: "field",
				name: "field",
				maxWidth: "100px",
				tip: "uploadTableFieldTip",
			},
			{
				id: (c) => c.description,
				key: "description",
				name: "description",
				maxWidth: "100px",
				tip: "uploadTableDescriptionTip",
			},
			{
				id: (c) => linesFormatter(c.lines),
				key: "lines",
				name: "lines",
				maxWidth: "100px",
				tip: "uploadTableLinesTip",
			},
		],
	});

	return {
		headers: build(),
	};
};
