import { Grid } from "@mui/material";
import { SBSelect, SBIconButton, SBBox, SBIcon } from "modules/Commons";
import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import SBDatePickerButton from "./SBDatePickerButton";
import { SBDatePickerBaseHeader } from "./SBDatePickerBaseHeader";
import { DateMultipliers, DateRangeType } from "../../models/dateRangeType";
import { AllMonths } from "../../models/months";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useDateStorageCache } from "hooks/useDateStorageCache";

/** Date picker that can be used as base for any configuration */
export default (props: SBDatePickerBaseProps) => {
	const {
		children,
		minDate,
		maxDate,
		availableDates,
		selectedYear,
		selectedMonth,
		validItems = [],
		headers = [],
		years = [],
		defaultValue = { date: undefined, startDate: undefined, endDate: undefined },
		months = [],
		fixedStart = false,
		type = DateRangeType.Daily,
		range = false,
		lineSize = 4,
		getText = (item: any) => item,
		onSelectDate = (item: any) => { },
		onChangeItem = (item: any) => { },
		onChangeMonth = (item: any) => { },
	} = props;

	const { t } = useTranslation();
	const dateCache = useDateStorageCache();
	const [date, setDate] = useState(defaultValue.date);
	const [startDate, setStartDate] = useState(defaultValue.startDate);
	const [endDate, setEndDate] = useState(defaultValue.endDate);
	const selectItem = useMemo(() => selectedYear, [selectedYear]);
	const selectItems = useMemo(() => years, [years]);

	const selectDate = (date: DateTime) => {
		if (!range) {
			setDate(date);
			onSelectDate({ date: date, range: { startDate: undefined, endDate: undefined } });
		} else {
			const newEndDate = fetchDateByType(date, false);
			const newStartDate = fetchDateByType(date, true);

			if (fixedStart) {
				setEndDate(date);
				onSelectDate({ date: undefined, range: { startDate, endDate: newEndDate } });
			} else if (!!endDate && !!startDate) {
				setStartDate(date);
				setEndDate(undefined);
				onSelectDate(
					{
						date: undefined,
						range: {
							startDate: newStartDate,
							endDate: undefined,
						}
					});
			} else if (!endDate && !!startDate) {
				if (!!date && !!startDate && startDate > date) {
					const end = fetchDateByType(startDate, false);
					setStartDate(newStartDate);
					setEndDate(end);
					onSelectDate(
						{
							date: undefined,
							range: {
								startDate: newStartDate,
								endDate: end,
							}
						});
				} else {
					setEndDate(date);
					onSelectDate({ date: undefined, range: { startDate, endDate: newEndDate } });
				}
			} else if (!startDate) {
				setStartDate(date);
				onSelectDate({ date: undefined, range: { startDate: newStartDate, endDate } });
			}
		}
	};

	const fetchDateByType = (date: DateTime, isStart: boolean, inverse = 1) => {
		if (isStart) return date;
		else
			return date
				.set({
					day:
						type !== DateRangeType.Daily
							? date.daysInMonth
							: date.day,
				})
				.plus({
					month: inverse * fetchPickerMultiplier(),
				});
	};

	const fetchPickerMultiplier = () => {
		const multiplier = DateMultipliers(type) - 1;
		return multiplier < 0 ? 0 : multiplier;
	};

	const prev = () => {
		if (months && !!selectedMonth) {
			prevMonth();
		} else {
			//@ts-ignore
			const index = years.indexOf(selectedYear);
			if (index + 1 >= years.length) onChangeItem(years[0]);
			else onChangeItem(years[index + 1]);
		}
	};

	const next = () => {
		if (months && !!selectedMonth) {
			nextMonth();
		} else {
			//@ts-ignore
			const index = years.indexOf(selectedYear);
			if (index - 1 < 0) onChangeItem(years[years.length - 1]);
			else onChangeItem(years[index - 1]);
		}
	};

	const prevMonth = () => {
		if (months && !!selectedMonth && typeof selectedYear === "number") {
			let newDate = DateTime.now().set({
				day: 1,
				month: selectedMonth,
				year: selectedYear,
			});

			newDate = newDate.plus({ months: -1 });

			//@ts-ignore
			const yearIndex = years.indexOf(newDate.year);

			if (yearIndex === -1) {
				onChangeItem(years[0]);
				onChangeMonth(12);
			} else {
				onChangeItem(newDate.year);
				onChangeMonth(newDate.month);
			}
		}
	};

	const nextMonth = () => {
		if (months && !!selectedMonth && typeof selectedYear === "number") {
			let newDate = DateTime.now().set({
				day: 1,
				month: selectedMonth,
				year: selectedYear,
			});

			if (months && !!selectedMonth)
				newDate = newDate.plus({ months: 1 });
			else newDate = newDate.plus({ years: -1 });

			//@ts-ignore
			const yearIndex = years.indexOf(newDate.year);
			if (yearIndex === -1) {
				onChangeItem(years[years.length - 1]);
				onChangeMonth(1);
			} else {
				onChangeItem(newDate.year);
				onChangeMonth(newDate.month);
			}
		}
	};

	return (
		<SBBox>
			<SBBox pb={1} display="flex" justifyContent="space-between">
				<SBBox>
					{months && months.length > 0 ? (
						<SBSelect
							items={months}
							value={selectedMonth?.toString()}
							getText={(index: any) => t(AllMonths[index - 1])}
							onChange={(e: any) => onChangeMonth(e.target.value)}
							style={{
								minWidth: 130,
								background: "#F9F8FD",
								border: "none",
							}}
						/>
					) : null}

					<SBSelect
						style={{ marginLeft: 5, background: "#F9F8FD" }}
						items={selectItems}
						value={selectItem}
						getText={(item: any) => getText(item)}
						onChange={(e: any) => onChangeItem(e.target.value)}
					/>
				</SBBox>
				<SBBox display="flex" mb="auto">
					<SBIconButton onClick={prev}>
						<SBIcon
							style={{ transform: "rotate(90deg)" }}
							icon="select"
						/>
					</SBIconButton>
					<SBIconButton onClick={next}>
						<SBIcon
							style={{ transform: "rotate(-90deg)" }}
							icon="select"
						/>
					</SBIconButton>
				</SBBox>
			</SBBox>
			<SBBox display="flex">
				<SBBox width={"80%"}>
					<SBDatePickerBaseHeader
						headers={headers}
						dimension={lineSize === 4 ? 54 : 32}
					/>
					<Grid container display="flex" pb={range ? 2 : 0}>
						{validItems.map((item, index) => {
							return (
								<SBDatePickerButton
									key={index}
									buttonProps={item}
									index={index}
									onSelectDate={selectDate}
									startDate={startDate}
									endDate={endDate}
									range={range}
									validItems={validItems}
									lineSize={lineSize}
									minDate={minDate}
									maxDate={maxDate}
									availableDates={availableDates}
								/>
							);
						})}
					</Grid>
				</SBBox>
				<SBBox width={"20%"} mt={2}>
					{children}
				</SBBox>
			</SBBox>
		</SBBox>
	);
};

export interface SBDatePickerBaseProps {
	/** Children component  */
	children?: ReactNode;
	/** Min date that can be selected */
	minDate?: DateTime;
	/** Max date that can be selected */
	maxDate?: DateTime;
	/** Available dates that can be selected */
	availableDates?: string[];
	/** List of items to be rendered on the page */
	validItems?: Array<{ label: string; date: DateTime }>; //TODO: Change Variable name
	/** Flag to enable the DatePicker to have a range selection */
	range?: boolean;
	/** Headers to show on top of the list of items */
	headers?: Array<string>;
	/** List of valid years to select */
	years: Array<number> | Array<Array<number>>;
	/** Selected year to show */
	selectedYear: number | Array<number>;
	/** List of valid months to select */
	months?: Array<number>;
	/** Selected month to show */
	selectedMonth?: number;
	/** Start values for the startDate and endDate */
	defaultValue?: { date: DateTime, startDate: DateTime; endDate: DateTime };
	/** Flag to fix the start date */
	fixedStart?: boolean;
	/** Initial type of the date range */
	type: DateRangeType;
	/** Amount of items that will be shown per row */
	lineSize?: 4 | 7;
	/** Select year label formatter */
	getText?: (item: Array<number>) => string; //TODO: Make name more expressive
	/** Event to trigger when a date is selected */
	onSelectDate?: () => void;
	/** Event to trigger when a year is selected */
	onChangeItem?: (e: any) => void; //TODO: Change name to more expressivo
	/** Event to trigger when a month is selected */
	onChangeMonth?: () => void;
}
