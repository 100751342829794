import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Button, ButtonProps } from "@mui/material";
import clsx from "clsx";
import { useStyles } from "./style";

/** Common button  */
export default (props: SBButtonProps) => {
	const { loading, className, ...otherProps } = props;
	const classes = useStyles();

	return loading ? (
		<LoadingButton
			data-testid="sb-button"
			loading
			variant="contained"
			{...(otherProps as LoadingButtonProps)}
			className={clsx(classes.sbButton, className)}
		/>
	) : (
		<Button
			data-testid="sb-button"
			variant="contained"
			{...(otherProps as ButtonProps)}
			className={clsx(classes.sbButton, className)}
		/>
	);
};

export interface SBButtonProps extends ButtonProps {
	/** Flag that makes the button indicates  loading*/
	loading?: boolean | undefined;
	/** Change the button color*/
	color?: any;
}
