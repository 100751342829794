import { Divider } from "@mui/material";
import { ReactNode } from "react";
import SBTooltip from "../SBDataDisplays/SBTooltip";
import SBTypography from "../SBDataDisplays/SBTypography";
import SBBox from "../SBLayouts/SBBox";
import SBCard from "../SBLayouts/SBCard";
import SBGrid from "../SBLayouts/SBGrid";
import SBGroupedLineCard from "./SBGroupedLineCard";
import { IGroupedStatistics } from "domain/Statistic/Models/IGroupedStatistics";

export default ({ items, ignoreCard }: SBGroupedStatisticsCardProps) => {

	const content = (child: ReactNode) =>
		ignoreCard ? (
			<>{child}</>
		) : (
			<SBCard style={{ width: "100%", height: "100%" }}>
				<SBBox display="flex" flexDirection="column" justifyContent="space-evenly" height="100%" px={3} >
					{child}
				</SBBox>
			</SBCard>
		);


	return content(
		items?.map((item, index: number) => {
			return (
				<>
					<SBGrid container columns={14} m={0}>
						<SBGrid item xs={6} display="flex" justifyContent="space-between" m={0} pr={2}>
							<SBTooltip title={item.tootltip} placement="top">
								<SBTypography fontWeight="400" color="gray2.main">{item?.label}</SBTypography>
							</SBTooltip>
							<SBBox display="flex" alignItems="center">
								<SBTypography fontWeight="600" color="gray2.main" variant="body1" mr={0.5}>
									{item?.formatter
										? item.formatter(item?.value)
										: item?.value}
								</SBTypography>
								<SBTypography fontSize="0.7rem" fontWeight="400" color="gray2.main">
									{item.unit}
								</SBTypography>
							</SBBox>
						</SBGrid>

						{item?.subItems?.map(subItem => {
							return (
								<SBGrid item xs={4} display="flex" justifyContent="end">
									<SBGroupedLineCard item={subItem} formatter={item?.formatter} />
								</SBGrid>
							);
						})}

					</SBGrid>
					{index !== items.length - 1 && <Divider />}
				</>
			)
		})
	);
};

interface SBGroupedStatisticsCardProps {
	ignoreCard?: boolean;
	items?: IGroupedStatistics[];
}