/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useCallback, useMemo, useState, useEffect } from "react";
import { DateTime } from "luxon";
import { SBBox } from "modules/Commons";
import { useStyles } from "./style";
import { SBStyledDateSlider } from "./SBStyledDateSlider";

export default forwardRef(
	(
		{
			onChange = () => {},
			startDay = 1,
			startMonth = 1,
			startYear = 2021,
			endDay = 1,
			endMonth = 12,
			endYear = 2021,
		}: any,
		ref
	) => {
		const classes = useStyles();
		const [range, setRange] = useState([0, 1000]);
		const [startDate, setStartDate] = useState<DateTime>(null as any);
		const [endDate, setEndDate] = useState<DateTime>(null as any);

		const namedMonths = useMemo(
			() => [
				"Jan",
				"Fev",
				"Mar",
				"Abr",
				"Mai",
				"Jun",
				"Jul",
				"Ago",
				"Set",
				"Out",
				"Nov",
				"Dez",
			],
			[]
		);

		const changeToMonth = (month: number) => {
			const date = startDate.plus({ month });
			const newStart = date.diff(startDate, "days").days - date.day + 1;
			const newEnd =
				date.diff(startDate, "days").days + date.daysInMonth - date.day;
			setRange((r) => [newStart, newEnd]);
		};

		const changeRange = (v: any) => {
			const start = startDate.plus({ days: range[0] });
			const end = startDate.plus({ days: range[1] });

			onChange({
				startDate: start,
				endDate: end,
				diff: end.diff(start, "days").days,
			});
		};

		const transformDate = (day: number, month: number, year: number) => {
			var date = DateTime.fromObject({
				day,
				month,
				year,
			});
			return date;
		};

		const buildComponent = useCallback(() => {
			var startDate = transformDate(startDay, startMonth, startYear);
			var endDate = transformDate(endDay, endMonth, endYear);

			if (endDate && startDate) {
				setStartDate(startDate);
				setEndDate(endDate);
			}
		}, [endMonth, endYear, startMonth, startYear, namedMonths]);

		useEffect(() => {
			buildComponent();
		}, [buildComponent, startMonth, startYear, endMonth, endYear]);

		const fetchDateValue = (position: number) => {
			return (
				startDate &&
				DateTime.fromISO(startDate.toISO())
					.plus({ days: position })
					.toFormat("dd")
			);
		};

		const fetchMarks = useMemo(() => {
			if (endDate && startDate) {
				const months = endDate.diff(startDate, "months").months;

				const markers = [...Array(Math.ceil(months)).keys()].map(
					(c) => {
						const finalDate = startDate
							.plus({ months: c })
							.set({ day: 1 });

						const value = finalDate.diff(startDate, "days").days;
						const monthName =
							namedMonths[+finalDate.toFormat("MM") - (1 % 12)];

						return {
							value: value > 0 ? value : 0,
							label: (
								<SBBox onMouseUp={(e) => changeToMonth(c)}>
									{monthName}
								</SBBox>
							),
						};
					}
				);
				return markers;
			}
		}, [startDate, endDate]);

		const fetchRange = useMemo(() => {
			const range =
				endDate && startDate && endDate.diff(startDate, "days").days;
			return range;
		}, [startDate, endDate]);

		return (
			<SBBox mt={2} pr={1} className={classes.sbDateSliderContainer}>
				<SBStyledDateSlider
					disableSwap
					step={1}
					min={0}
					onChange={(_, newValue: any) => setRange(newValue)}
					onChangeCommitted={changeRange}
					max={fetchRange!}
					marks={fetchMarks}
					value={range}
					valueLabelDisplay="auto"
					valueLabelFormat={fetchDateValue}
				/>
			</SBBox>
		);
	}
);

export interface SBDateSlider {
	onChange: any;
	startDay: any;
	startMonth: any;
	startYear: any;
	endDay: any;
	endMonth: any;
	endYear: any;
}
