import { SBTranslate } from "i18n"
import { SBBox, SBIcon, SBTypography } from "modules/Commons"
import { useStyles } from "./style"

export default ({ exportCSV }: SBForecastTitleLineProps) => {
	const classes = useStyles();

	return (
		<SBBox className={classes.titleLine}>
			<SBTypography>{SBTranslate("commons.forecast")}</SBTypography>
			<SBBox className={classes.export}>
				<SBIcon icon="download" size={15} />
				<SBTypography onClick={exportCSV}>
					{SBTranslate("exportForecast")}
				</SBTypography>
			</SBBox>
		</SBBox>
	);
}
interface SBForecastTitleLineProps {
	exportCSV: () => void;
}