import { number, string, boolean, object, array } from "yup";
import { IGroupConfig } from "../Entities/IGroupConfig";
import i18n from "i18next";
import { max } from "lodash";

export const groupConfigValidator = () => {
	const baseIntegerSchema = number()
		.emptyStringAsNull()
		.nullable()
		.positive()
		.moreThan(0)
		.integer();

	const baseFloatSchema = number()
		.emptyStringAsNull()
		.nullable()
		.positive()
		.moreThan(0);

	const schema = object()
		.shape(
			{
				id: number().min(0).integer(),
				name: string().required(),
				active: boolean().required(),
				sourcingID: number().nullable(),
				csl: number()
					.notRequired()
					.optional()
					.emptyStringAsNull()
					.nullable(true)
					.moreThan(0.0)
					.between(
						{ prop: "minCSL", label: i18n.t("scarcity") },
						{ prop: "maxCSL", label: i18n.t("excess") }
					),
				virtualStock: number()
					.notRequired()
					.optional()
					.emptyStringAsNull()
					.nullable()
					.moreThan(0.5, (v) =>
						i18n.t("biggerThan_other", { v: v.more * 100 })
					)
					.lessThan(1.01, (v) =>
						i18n.t("lowerThan_other", { v: v.less * 100 })
					),

				minCSL: number()
					.emptyStringAsNull()
					.notRequired()
					.nullable()
					.moreThan(0.0)
					.lessThanIfNotNull({
						prop: "maxCSL",
						label: i18n.t("excess"),
					}),
				maxCSL: number()
					.emptyStringAsNull()
					.notRequired()
					.optional()
					.nullable()
					.moreThan(0.0, (v) =>
						i18n.t("biggerThan", { v: v.more * 100 })
					)
					.moreThanIfNotNull({
						prop: "minCSL",
						label: i18n.t("scarcity"),
					}),
				max: number()
					.notRequired()
					.emptyStringAsNull()
					.nullable()
					.integer()
					.moreThan(0, i18n.t("biggerThan0"))
					.moreThanIfNotNull({
						prop: "min",
						label: i18n.t("minimum"),
					}),
				min: number()
					.notRequired()
					.emptyStringAsNull()
					.nullable()
					.moreThan(0, i18n.t("biggerThan0"))
					.integer()
					.lessThanIfNotNull({
						prop: "max",
						label: i18n.t("maximum"),
					}),
				emergencyInventory: number()
					.emptyStringAsNull()
					.notRequired()
					.nullable()
					.moreThan(0)
					.integer()
					.lessThanIfNotNull({
						prop: "min",
						label: i18n.t("minimum"),
					})
					.lessThanIfNotNull({
						prop: "max",
						label: i18n.t("maximum"),
					}),
				moq: number()
					.notRequired()
					.emptyStringAsNull()
					.nullable()
					.lessThanIfNotNull({
						prop: "capacity",
						label: i18n.t("capacity"),
					})
					.moreThanOrEqualIfNotNull({
						prop: "lotSize",
						label: i18n.t("lotSize"),
					})
					.lessThanOrEqualIfNotNull({
						prop: "max",
						label: i18n.t("max"),
					})
					.isMultipleOf({ prop: "lotSize", label: i18n.t("lotSize") })
					.moreThan(0),
				lotSize: number()
					.emptyStringAsNull()
					.nullable()
					.notRequired()
					.moreThan(0)
					.lessThanIfNotNull({
						prop: "capacity",
						label: i18n.t("capacity"),
					}),
				cost: number()
					.emptyStringAsNull()
					.notRequired()
					.nullable()
					.moreThan(0)
					.lessThanIfNotNull({
						prop: "price",
						label: i18n.t("price"),
					}),
				price: number()
					.emptyStringAsNull()
					.notRequired()
					.nullable()
					.moreThan(0)
					.moreThanIfNotNull({
						prop: "salvage",
						label: i18n.t("salvage"),
					}),
				capacity: number()
					.emptyStringAsNull()
					.notRequired()
					.nullable()
					.moreThan(0)
					.isMultipleOf({
						prop: "lotSize",
						label: i18n.t("lotSize"),
					}),
				leadTime: baseIntegerSchema,
				emergencialLeadTime: number()
					.emptyStringAsNull()
					.notRequired()
					.nullable()
					.moreThan(0.0)
					.lessThanIfNotNull({
						prop: "leadTime",
						label: i18n.t("leadTime"),
					}),
				profit: baseIntegerSchema,
				penalty: baseIntegerSchema,
				salvage: baseIntegerSchema,
				weight: baseFloatSchema,
				volume: baseFloatSchema,
				reviewPeriodExpression: string()
					.nullable()
					.noDayZero()
					.daysInWeekReviewPeriodRequired()
					.daysInMonthReviewPeriodRequired(),
				products: array().of(object().shape({})),
				categories: array().of(object().shape({})),
				stores: array().of(object().shape({})),
			},
			[["max", "min"]]
		)
		.test("products", i18n.t("filterNotSelected"), function (value: any) {
			return [
				...value.products,
				...value.categories,
				...value.stores,
			].some((s) => s.status !== false);
		});
	const validate = async (obj: IGroupConfig) => {
		try {
			await schema.validate(obj, { abortEarly: false });
			return { isValid: true, errors: [] };
		} catch (e: any) {
			return {
				isValid: false,
				errors: e.inner.map((c: any) => ({
					field: c.path,
					message: c.message,
				})),
			};
		}
	};

	return {
		schema,
		validate,
	};
};
