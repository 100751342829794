import { SBTranslate } from "i18n";
import { SBBox, SBSelect, SBTypography } from "modules/Commons";
import { TransferenceMode } from "modules/InventoryConfig/models/transferenceMode";
import { useContext, useEffect, useState } from "react";
import { TipsContext } from "views/ConfigsView/ConfigTips";

export default (props: ConfigTransferenceModeProps) => {
	const { value, onUpdate } = props;
	const tipsContext = useContext(TipsContext);

	const [transferenceMode, setTransferenceMode] = useState<TransferenceMode | null>(value ?? null);
	const transferModeValues = [
		{ text: SBTranslate("notDefined"), value: TransferenceMode.NotTransferDefined, tipsContext: "NotDefined" },
		{ text: SBTranslate("notPerform"), value: TransferenceMode.NotTransfer, tipsContext: "SupplyTransferNotPerform" },
		{ text: SBTranslate("perform"), value: TransferenceMode.Transfer, tipsContext: "SupplyTransferPrioritizeTransfer" }
	];

	const updateTransferencemode = (value: number) => {
		tipsContext.showTip(transferModeValues.find(r => r.value == value)?.tipsContext ?? "");

		if (value === TransferenceMode.NotTransferDefined) {
			setTransferenceMode(null);
		} else {
			setTransferenceMode(value);
		}
	}

	useEffect(() => {
		onUpdate(transferenceMode);
	}, [transferenceMode]);

	return (
		<SBBox>
			<SBTypography
				mb={1}
				fontWeight="500"
				color="gray3.main"
				variant={"body1"}
				textTransform={"uppercase"}>
				{SBTranslate("transference")}
			</SBTypography>
			<SBSelect
				fullWidth
				getText={item => item.text}
				getValue={item => item.value}
				onChange={(event) => updateTransferencemode(Number(event.target.value))}
				value={transferenceMode ?? TransferenceMode.NotTransferDefined}
				items={transferModeValues}
			/>

		</SBBox>
	);
};

interface ConfigTransferenceModeProps {
	value: TransferenceMode;
	onUpdate: (v: TransferenceMode | null) => void;
}
