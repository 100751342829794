import { makeStyles, styled } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	notificationCard: {
		borderBottom: `1px solid ${theme.palette.lightPurple.main}`,
		paddingBottom: "16px",
		paddingRight: "16px",
		marginTop: "16px",
	},
	notificationCardDetails: {
		display: "-webkit-box",
		WebkitLineClamp: 3,
		WebkitBoxOrient: "vertical",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	notificationCardFooter: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
	},
	notificationTitle: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	seenDot: {
		marginLeft: "4px",
		width: "8px",
		height: "8px",
		borderRadius: "50%",
		background: theme.palette.primary.main,
	},
}));

export const Content = styled("span")(() => ({
	fontWeight: "700",
}));
