import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	sbExpandableField: {
		backgroundColor: "white",
		position: "relative",
		"& .sbTextField": {
			transition: "width 0.25s ease",
			borderRadius: "4px",
			padding: 5,
			backgroundColor: "white",
			width: "38px",
			"&.active": {
				border: "1px solid #A7A7A7",
				width: "200px",
			},
			"& input": {
				paddingLeft: "38px",
			},
		},
		"& .sbIconButton": {
			transition: "width 0.25s ease",
			padding: 6,
			position: "absolute",
			top: 2,
			left: 3,
			backgroundColor: "white",
		},
	},
}));
