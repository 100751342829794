import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	selectionBox: {
		borderTop: "1px solid #eee",
		marginTop: 10,
		padding: 10,
	},
}));
