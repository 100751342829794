import clsx from "clsx";
import { DateTime } from "luxon";
import { SimulationPeriod } from "modules/Simulations/models/simulationPeriod";
import { useStyles } from "./style";

export default ({
	payload,
	x,
	y,
	dateFormat = "MM/yyyy",
	lastDate,
	simulationPeriod = SimulationPeriod.Daily,
}: SimulationChartTooltipXAxisProps) => {
	const classes = useStyles();
	const isDaily = simulationPeriod === SimulationPeriod.Daily;

	if (payload) {
		const formattedValue = DateTime.fromFormat(
			payload.value,
			simulationPeriod!
		);
		const lastDateFormatted = lastDate
			? DateTime.fromISO(lastDate).toFormat(dateFormat)
			: "";

		const validate = () => {
			if (dateFormat === "MM/yyyy")
				return payload.value === lastDateFormatted;
			return lastDate === payload.value;
		};

		if (!isDaily || formattedValue.day === 1)
			return (
				<g transform={`translate(${x},${y})`}>
					<line
						x1="0"
						y1="-9"
						x2="0"
						y2="-2"
						className={clsx(classes.line, validate() && "lastDate")}
					/>
					<text
						dy={9}
						className={clsx(
							classes.text,
							!isDaily && "rotated",
							validate() && "lastDate"
						)}
						x={!isDaily ? 5 : 15}
					>
						{payload.value}
					</text>
				</g>
			);
	}
	return null;
};

export interface SimulationChartTooltipXAxisProps {
	payload: any;
	x: number;
	y: number;
	ignoreDateConstraint?: boolean;
	dateFormat?: string;
	lastDate?: string;
	simulationPeriod?: SimulationPeriod;
}
