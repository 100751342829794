import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	mainContainer: {
		backgroundColor: "#f9f8fd",
		backgroundPosition: "top right",
	},
	filterSelect: {
		minWidth: 130,
		height: 23,
		background: "#F9F8FD",
		border: "none",
	},
}));
