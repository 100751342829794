import { useTheme } from "@mui/material";
import { IChart } from "domain/Chart/Entities/IChart";
import { IChartFilter } from "domain/Chart/Entities/IChartFilter";
import { IChartInfo } from "domain/Chart/Entities/IChartInfo";
import { IChartTooltipRow } from "domain/Chart/Entities/IChartTooltipRow";
import { DomainEnum } from "domain/Domain/Enums/DomainEnum";
import { useTranslation } from "react-i18next";
import { BaseThemeOptions } from "themes/base";
import { ISimulationModel } from "../models/ISimulationModel";
import { ISimulationPoint } from "../models/ISimulationPoint";
import { SimulationPeriod } from "../models/simulationPeriod";

export const useSimulationCharts = () => {
	const theme = useTheme<BaseThemeOptions>(); //TODO: Usar cores dos themes
	const { t } = useTranslation();

	const tooltips = (simulationPeriod: SimulationPeriod) => {
		const columns: IChartTooltipRow<ISimulationModel>[] = [
			{
				accessor: (i) => i?.start ?? 0,
				label: DomainEnum.Start,
				name: DomainEnum.Start,
			},
			{
				accessor: (i) => i?.onOrder ?? 0,
				label: DomainEnum.OnOrder,
				name: DomainEnum.OnOrder,
			},
			{
				accessor: (i) => i?.position ?? 0,
				label: DomainEnum.Position,
				name: DomainEnum.Position,
			},
			{
				accessor: (i) =>
					!isNaN(i?.csl) ?? 0
						? `${(i?.csl * 100).systemFormatAsPercentage()}%` ?? 0
						: 0,
				label: DomainEnum.CSL,
				name: DomainEnum.CSL,
			},
			{
				accessor: (i) => i?.forecast?.systemFormatAsInteger() ?? 0,
				label: DomainEnum.Forecast,
				name: DomainEnum.Forecast,
			},
			{
				accessor: (i) => i?.sales ?? 0,
				label: DomainEnum.Sales,
				name: DomainEnum.Sales,
			},
			{
				accessor: (i) => i?.requested ?? 0,
				label: DomainEnum.Requested,
				name: DomainEnum.Requested,
			},
			{
				accessor: (i) => i?.received ?? 0,
				label: DomainEnum.Received,
				name: DomainEnum.Received,
			},
			{
				accessor: (i) => i?.rupture ?? 0,
				label: DomainEnum.Rupture,
				name: DomainEnum.Rupture,
			},
			{
				accessor: (i) => i?.end ?? 0,
				label: DomainEnum.End,
				name: DomainEnum.End,
			},
			{
				accessor: (i) => i?.projectedEnd ?? 0,
				label: "projected",
				name: DomainEnum.ProjectedEnd,
			},
		];

		if (simulationPeriod === SimulationPeriod.Daily)
			columns.push({
				accessor: (i) => i?.projectedEnd,
				label: DomainEnum.Need,
				name: DomainEnum.Need,
			});

		return columns;
	};

	const charts: () => IChart<ISimulationPoint>[] = () => [
		{
			name: "real",
			label: "real",
			show: true,
			color: theme.palette.real.main,
			accessor: (c) => c.real,
		},
		{
			name: "projected",
			label: "projected",
			show: true,
			color: theme.palette.projected.main,
			accessor: (c) => c.projected,
		},
	];

	const filters: () => IChartFilter[] = () => [
		{
			name: "rupture",
			label: "withRuptures",
			show: false,
			color: theme.palette.error.main,
		},
	];

	const variables: () => IChartInfo[] = () => [
		{
			name: "projectedEnd",
			label: "position",
		},
		{ name: "csl", label: "CSLs" },
		{ name: "forecast", label: "forecast" },
		{ name: "requested", label: "orders" },
		{ name: "rupture", label: "ruptures" },
		{ name: "sales", label: "sales" },
		{ name: "onOrder", label: "onOrder" },
	];

	return {
		tooltips,
		charts,
		filters,
		variables: variables(),
	};
};
