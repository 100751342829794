import { FilterType, StorageKey } from "domain/Utils";
import useRememberMe from "hooks/useRememberMe";
export const useStorageCache = (baseKey: string) => {
	const loc = window.location;
	const { storage } = useRememberMe();

	const getURLFilterArray = (filter: FilterType): string[] => new URLSearchParams(loc.search).getAll(filter);
	const buildURLFilterArray = (array: string[]): { clientID: string }[] => array.map(el => ({ clientID: el }));

	const setFilterCache = (items: any) => {
		const cachedItems = JSON.stringify(items);
		if (cachedItems !== "[]")
			storage().setItem(StorageKey.SBFilters, cachedItems);
	};

	const getFilterCache = () => {
		const filters = storage().getItem(baseKey);
		return !!filters ? JSON.parse(filters) : undefined;
	};

	const getURLFilters = () => ({
		products: buildURLFilterArray(getURLFilterArray(FilterType.Product)),
		stores: buildURLFilterArray(getURLFilterArray(FilterType.Store)),
		categories: buildURLFilterArray(getURLFilterArray(FilterType.Category)),
	})

	const hasURLFilters = (): boolean => getURLFilterArray(FilterType.Product).length > 0
		|| getURLFilterArray(FilterType.Store).length > 0
		|| getURLFilterArray(FilterType.Category).length > 0


	return {
		setFilterCache,
		getFilterCache,
		getURLFilters,
		hasURLFilters
	};
};
