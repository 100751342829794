import { makeStyles } from "@mui/styles";
import { BaseThemeOptions } from "themes/base";

export const useStyles = makeStyles((theme: BaseThemeOptions) => ({
	card: {
		height: "100%",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		height: "100%",
		padding: "24px",
	},
	section: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%"
	},
	title: {
		color: theme.palette.gray2.main,
		fontWeight: "700 !important",
		textAlign: "center",
		fontSize: "1rem !important",
		lineHeight: "22px",
		padding: "10px 0",
	},
	subTitle: {
		color: theme.palette.primary.main,
		fontWeight: "400",
		textAlign: "center",
		fontSize: ".8rem",
		lineHeight: "16px",
	},
	description: {
		color: theme.palette.gray2.main,
		fontWeight: "400 !important",
		fontSize: "0.75rem !important",
		textAlign: "center",
		lineHeight: ".7",
		letterSpacing: "0.314223px",
	},
	count: {
		color: theme.palette.gray2.main,
		fontWeight: "700 !important",
		textAlign: "center",
		fontSize: "2.75rem !important",
		paddingTop: "15px",
		lineHeight: "0.75 !important",
	},
	countLabel: {
		color: "gray2.main",
		textAlign: "center",
		fontSize: "0.75rem !important",
		margin: "8px !important"
	},
	action: {
		paddingTop: "15px",
		color: theme.palette.primary.main,
		fontWeight: "600 !important",
		textAlign: "center",
		fontSize: "0.75rem",
		textDecoration: "underline",
		cursor: "pointer",
	},
}));
