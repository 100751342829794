import { SBBox, SBTypography } from "modules/Commons";
import followupUnderconstruction from "assets/followup-underconstruction.svg";

export const FollowUpView = () => {
	return (
		<SBBox
			display="flex"
			pl={11}
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			height="calc(100vh - 200px)"
		>
			<img height={200} src={followupUnderconstruction} alt="" />
			<SBBox
				my={2}
				sx={{
					padding: "10px 80px",
					borderRadius: "4px",
					backgroundColor: "darkPurple.main",
					color: "white.main",
					textTransform: "uppercase",
				}}
			>
				<SBTypography fontWeight="600">Under construction</SBTypography>
			</SBBox>
			<SBTypography fontWeight="700">
				We are working to build a FOLLOW-UP section.
			</SBTypography>
			<SBTypography fontWeight="700">
				Where it will be possible to view data and map points of
			</SBTypography>
			<SBTypography fontWeight="700">
				attention for MRP, MPS and DRP operations.
			</SBTypography>
		</SBBox>
	);
};
