import { InputBase } from "@mui/material";
import clsx from "clsx";
import { SBBox, SBIcon, SBIconButton, SBTooltip } from "modules/Commons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";

export default (props: SBExpandableFieldProps) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		value,
		onChange,
		icon,
		placeholder,
		opened = false,
		direction = "right",
	} = props;
	const [selected, setSelected] = useState(opened);

	return (
		<SBBox
			data-testid="sb-expandable-field"
			display="flex"
			justifyContent="start"
			className={clsx(classes.sbExpandableField)}
		>
			<InputBase
				className={clsx("sbTextField", selected && "active", direction)}
				placeholder={placeholder}
				onChange={onChange}
				value={value}
			/>
			<SBTooltip title={t("searchOnTable")}>
				<SBIconButton
					className={clsx("sbIconButton")}
					onClick={() => setSelected((c) => !c)}
				>
					<SBIcon size={24} icon={icon} />
				</SBIconButton>
			</SBTooltip>
		</SBBox>
	);
};

export interface SBExpandableFieldProps {
	/** Function to be called when textfield is changed */
	onChange: (e: any) => void;
	/** Value of the the textfield */
	value?: string;
	/** Icon that will be on the button to be expanded */
	icon: string;
	/** Value to indicate if the field will start opened */
	opened?: boolean;
	/** Textfield placeholder*/
	placeholder?: string;
	/** Choose which direction the field will expand */
	direction?: "right" | "left";
}
