import { SBBox, SBGrid } from "modules/Commons";
import { useEffect, useState, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TipsContext } from "views/ConfigsView/ConfigTips";
import ConfigGroupmentDivider from "../ConfigGroupmentDivider";
import ConfigTextField from "../ConfigTextField";

export default ({ fetchPropError }: CreateInventoryStockProps) => {
	const { t } = useTranslation();
	const moneyText = <>{t("$")}</>;

	const { getValues, setValue } = useFormContext();
	const tipsContext = useContext(TipsContext);

	const [holdingCost, setHoldingCost] = useState<number | "">(
		getValues("holdingCost")
			? +(getValues("holdingCost") * 100).toFixed(0)
			: ""
	);

	useEffect(() => {
		setValue("holdingCost", +holdingCost / 100);
	}, [holdingCost]);
	return (
		<SBBox>
			<ConfigGroupmentDivider title={t("trc")} />
			<SBGrid container spacing={4}>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="stock"
						type="positiveNumber"
						prop="costPerPurchaseTransaction"
						title={t("costPerPurchaseTransaction")}
						endAdornment={moneyText}
						helperText={fetchPropError(
							"costPerPurchaseTransaction"
						)}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="stock"
						type="positiveNumber"
						prop="costPerStockoutEvent"
						title={t("costPerStockoutEvent")}
						endAdornment={moneyText}
						helperText={fetchPropError("costPerStockoutEvent")}
					/>
				</SBGrid>
				<SBGrid item xs={4}>
					<ConfigTextField
						section="stock"
						title={t("holdingCost")}
						type="number"
						value={holdingCost}
						onClick={() => {
							tipsContext.showTip("StockHoldingcost");
						}}
						onChange={(e) =>
							setHoldingCost(e.target.value.naturalNumber())
						}
						endAdornment="%"
						helperText={fetchPropError("holdingCost")}
					/>
				</SBGrid>
			</SBGrid>
		</SBBox>
	);
};

export interface CreateInventoryStockProps {
	fetchPropError: any;
}
