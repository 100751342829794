import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	sbTableSummarization: {
		transition: `all 300ms ease`,
		"&.entering": { height: "80px", opacity: 1 },
		"&.entered": { height: "80px", opacity: 1 },
		"&.exiting": { height: 0, opacity: 0 },
		"&.exited": { height: 0, opacity: 0 },
	},
}));
