import { IInventoryStatisticsSummary } from "domain/Inventory/Models/IInventoryStatisticsSummary";
import useTableColumnBuilder from "hooks/useTableColumnBuilder";
import { SBTranslate } from "i18n";
import Cron from "i18n/cron";
import { DateTime } from "luxon";
import { SBBox } from "modules/Commons";
import { SBTableColumn } from "modules/Commons/SBDataDisplays/SBTable/models/TableColumn";
import { useTranslation } from "react-i18next";

export const useInventoryStatisticHeaders = () => {
	const { t } = useTranslation();
	const {
		build,
		add,
		decimalFormatter,
		systemFormatter,
		percentageDecimalFormatter,
		decimalPercentageFormatter,
		decimalPercentageNoDecimalFormatter,
	} = useTableColumnBuilder<IInventoryStatisticsSummary>();

	const isWarning = (value: string) => {
		return (
			<SBBox display="flex" alignItems="center" style={{ color: "red" }}>
				{value}
			</SBBox>
		);
	};

	const modelSection = {
		id: () => "",
		name: (""),
		headers: [
			{
				name: SBTranslate("explainability"),
				id: (item) => percentageDecimalFormatter(item.r2),
			},
			{
				name: SBTranslate("bias"),
				id: (item) => {
					const value = decimalPercentageFormatter(item.mpe);
					return item.warning?.indexOf("MPE") >= 0
						? isWarning(value)
						: value;
				},
			},
			{
				name: SBTranslate("deviation"),
				id: (item) => systemFormatter(+item.evaluationRMSE),
			},
			{
				name: SBTranslate("error"),
				id: (item) => {
					const value = decimalPercentageFormatter(item.mape);
					return item.warning?.indexOf("MAPE") >= 0
						? isWarning(value)
						: value;
				},
			},
		],
	} as SBTableColumn<IInventoryStatisticsSummary>;
	add({
		id: () => "inventory",
		name: SBTranslate("inventory"),
		headers: [
			{
				name: `${SBTranslate("onHand")}`,
				tooltip: `${SBTranslate("tips.onHand")}`,
				id: (item) => `${item.onHand} ${SBTranslate("unit")}`,
			},
			{
				name: `${SBTranslate("onOrder")}`,
				tooltip: `${SBTranslate("tips.onOrder")}`,
				id: (item) => `${item.onOrder} ${SBTranslate("unit")}`,
			},
			{
				name: `${SBTranslate("reserved")}`,
				tooltip: `${SBTranslate("tips.reservedInventory")}`,
				id: (item) => `${item.reserved} ${SBTranslate("unit")}`,
			},
			{
				name: `${SBTranslate("position")}`,
				id: (item) => `${item.position} ${SBTranslate("unit")}`,
				tooltip: `${SBTranslate("tips.positionInventory")}`,
			},
			{
				name: SBTranslate("cyclic"),
				tooltip: `${SBTranslate("tips.cyclicInventory")}`,
				id: (item) => `${item?.levels?.cyclic} ${SBTranslate("unit")}`,
			},
			{
				name: `${SBTranslate("safety")}`,
				tooltip: `${SBTranslate("tips.safetyInventory")}`,
				id: (item) => `${item?.levels?.safety} ${SBTranslate("unit")}`,
			},
			{
				name: `${SBTranslate("emergency")}`,
				tooltip: `${SBTranslate("tips.emergencyInventory")}`,
				id: (item) => `${item?.levels?.emergency} ${SBTranslate("unit")}`,
			},
			{
				name: `${SBTranslate("positionWithoutReserved")}`,
				tooltip: `${SBTranslate("tips.inventoryPositionWithoutReserved")}`,
				id: (item) => `${item.positionWithoutReserved} ${SBTranslate("unit")}`,
			},
		],
	});
	add({
		id: () => "cycle",
		name: SBTranslate("currentCycle"),
		headers: [
			{
				name: SBTranslate("currentCSL"),
				tooltip: SBTranslate("tips.currentCSL"),
				id: (item) => {
					var isOutsideRange =
						item.currentCSL < item.minCSL ||
						item.currentCSL > item.maxCSL;
					var value = decimalPercentageNoDecimalFormatter(
						item.currentCSL
					);

					return isOutsideRange ? isWarning(value) : value;
				},
			},
			{
				name: SBTranslate("status"),
				tooltip: SBTranslate("tips.CSLStatus"),
				id: (item) => {
					const color =
						item?.status?.indexOf("OK") === -1 ? "red" : "initial";
					return (
						<SBBox
							display="flex"
							alignItems="center"
							style={{ color }}
						>
							<SBBox style={{ color }}>
								{item?.status?.map((s: string) =>
									t(s.toLowerCase())
								)}
							</SBBox>
						</SBBox>
					);
				},
			},
			{
				name: SBTranslate("nextDelivery"),
				tooltip: SBTranslate("tips.nextDelivery"),
				id: (item) =>
					DateTime.fromISO(item.nextDelivery).toFormat(
						SBTranslate("dateFormat")
					),
			},
			{
				name: SBTranslate("nextDeliveryQuantity"),
				tooltip: SBTranslate("tips.nextDeliveryQuantity"),
				id: (item) => {
					return `${decimalFormatter(item.nextDeliveryQuantity)} ${SBTranslate("unit")}`;
				},
			},
			{
				name: SBTranslate("daysUntilNextDelivery"),
				tooltip: SBTranslate("tips.daysUntilNextDelivery"),
				id: (item) => {
					return `${item.daysUntilNextDelivery} ${SBTranslate("day", {
						count: item.daysUntilNextDelivery,
					})}`;
				},
			},
			{
				name: SBTranslate("forecast"),
				tooltip: SBTranslate("tips.cycleForecast"),
				id: (item) => {
					return `${decimalFormatter(item.quantity)} ${SBTranslate("unit")}`;
				},
			},
			{
				name: SBTranslate("min"),
				tooltip: SBTranslate("tips.cycleMin"),
				id: (item) => {
					return `${decimalFormatter(
						Math.max(item.minForecast, 0)
					)} ${SBTranslate("unit")}`;
				},
			},
			{
				name: SBTranslate("max"),
				tooltip: SBTranslate("tips.cycleMax"),
				id: (item) => {
					return `${decimalFormatter(item.maxForecast)} ${SBTranslate("unit")}`;
				},
			},
			{
				name: SBTranslate("risk"),
				tooltip: SBTranslate("tips.cycleRisk"),
				id: (item) => {
					return `${decimalFormatter(+item?.risk)} ${SBTranslate("unit")}`;
				},
			},
			{
				name: SBTranslate("deviation"),
				tooltip: SBTranslate("tips.cycleDeviation"),
				id: (item) => {
					return `${decimalFormatter(item.forecastRMSE)} ${SBTranslate("unit")}`;
				}
			},
			{
				name: SBTranslate("rupture"),
				tooltip: SBTranslate("tips.cycleRupture"),
				id: (item) => {
					return `${item.rupture} ${SBTranslate("unit")}`;
				},
			},
			{
				name: SBTranslate("expectedSales"),
				tooltip: SBTranslate("tips.cycleExpectedSales"),
				id: (item) => {
					return `${decimalFormatter(item.sales)} ${SBTranslate("unit")}`;
				},
			},
		],
	});
	add({
		id: () => "nextCycle",
		name: SBTranslate("nextCycle"),
		headers: [
			{
				name: SBTranslate("request"),
				tooltip: SBTranslate("tips.nextCycleRequest"),
				id: (item) => {
					return DateTime.fromISO(item.nextCycleScope.request).toFormat(
						SBTranslate("dateFormat")
					);
				},
			},
			{
				name: SBTranslate("delivery"),
				tooltip: SBTranslate("tips.nextCycleDelivery"),
				id: (item) => {
					return DateTime.fromISO(item.nextCycleScope.delivery).toFormat(
						SBTranslate("dateFormat")
					);
				},
			},
			{
				name: SBTranslate("coverage"),
				tooltip: SBTranslate("tips.nextCycleCoverage"),
				id: (item) => {
					return DateTime.fromISO(item.nextCycleScope.coverage).toFormat(
						SBTranslate("dateFormat")
					);
				},
			},
			{
				name: SBTranslate("nextReview"),
				tooltip: SBTranslate("tips.nextCycleNextReviewDate"),
				id: (item) => {
					return DateTime.fromISO(
						item.nextCycleScope.nextReview
					).toFormat(SBTranslate("dateFormat"));
				},
			},
			{
				name: SBTranslate("forecast"),
				tooltip: SBTranslate("tips.nextCycleForecast"),
				id: (item) => {
					return `${decimalFormatter(item.nextCycleForecast.expected)} ${SBTranslate("unit")}`;
				},
			},
			{
				name: SBTranslate("min"),
				tooltip: SBTranslate("tips.nextCycleMin"),
				id: (item) => {
					return `${decimalFormatter(
						Math.max(item.nextCycleForecast.min, 0)
					)} ${SBTranslate("unit")}`;
				},
			},
			{
				name: SBTranslate("max"),
				tooltip: SBTranslate("tips.nextCycleMax"),
				id: (item) => {
					return `${decimalFormatter(item.nextCycleForecast.max)} ${SBTranslate("unit")}`;
				},
			},
			{
				name: SBTranslate("risk"),
				tooltip: SBTranslate("tips.nextCycleRisk"),
				id: (item) => {
					return `${decimalFormatter(+item?.nextCycleRisk)} ${SBTranslate("unit")}`;
				},
			},
			{
				name: SBTranslate("deviation"),
				tooltip: SBTranslate("tips.nextCycleDeviation"),
				id: (item) => {
					return `${decimalFormatter(item.nextCycleForecast.rmse)} ${SBTranslate("unit")}`;
				}
			},
			{
				name: SBTranslate("rupture"),
				tooltip: SBTranslate("tips.nextCycleRupture"),
				id: (item) => {
					return `${item.ruptureBeforeDelivery} ${SBTranslate("unit")}`;
				},
			},
			{
				name: SBTranslate("expectedSales"),
				tooltip: SBTranslate("tips.nextCycleExpectedSales"),
				id: (item) => {
					return `${decimalFormatter(item.nextCycleSales)} ${SBTranslate("unit")}`;
				},
			},
		],
	});
	add({
		id: () => "cycleTime",
		name: SBTranslate("configCycle"),
		headers: [
			{
				name: SBTranslate("cslTarget"),
				tooltip: SBTranslate("tips.targetCSL"),
				id: (item) => decimalPercentageNoDecimalFormatter(item.csl),
			},
			{
				name: `${SBTranslate("cslMin")}`,
				tooltip: SBTranslate("tips.minCSL"),
				id: (item) => {
					return `${decimalPercentageNoDecimalFormatter(
						item.minCSL
					)}`;
				},
			},
			{
				name: `${SBTranslate("cslMax")}`,
				tooltip: SBTranslate("tips.maxCSL"),
				id: (item) => {
					return `${decimalPercentageNoDecimalFormatter(item.maxCSL)}`;
				},
			},
			{
				name: SBTranslate("daysOfInventory"),
				tooltip: SBTranslate("tips.inventoryDays"),
				id: (item) => {
					return `${item?.daysOnHand} ${item.overflowDOH ? "+" : ""}
					${SBTranslate("day", {
						count: item.daysOnHand,
					})}`;
				},
			},
			{
				name: SBTranslate("leadTime"),
				tooltip: SBTranslate("tips.leadTime"),
				id: (item) => {
					return `${item.leadTime} ${SBTranslate("day", {
						count: item.leadTime,
					})}`;
				},
			},
			{
				name: SBTranslate("reviewPeriod"),
				tooltip: SBTranslate("tips.reviewPeriod"),
				id: (item) => {
					return item.reviewPeriodNumeric ?
						`${SBTranslate("each")} ${item.reviewPeriodNumeric} ${SBTranslate("day", {
							count: item.averageReviewPeriod,
						})}` : Cron({ t }).translateCronExpression(
							item.reviewPeriodExpression
						);
				},
			},
			{
				name: SBTranslate("averageReviewPeriod"),
				tooltip: SBTranslate("tips.averageReviewPeriod"),
				id: (item) => {
					return `${item.averageReviewPeriod} ${SBTranslate("day", {
						count: item.averageReviewPeriod,
					})}`;
				},
			},
			{
				name: SBTranslate("cycle"),
				tooltip: SBTranslate("tips.cycleTime"),
				id: (item) => {
					return `${item.coveredCycle} ${SBTranslate("day", {
						count: item.coveredCycle,
					})}`;
				},
			},
		],
	});
	return { sections: build(), modelSection };
};
