import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	sbCheckbox: {
		"&:checked": {
			color: "#7F51FF !important",
		},
	},
	checked: {},
}));
