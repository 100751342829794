import configDisableDialog from "assets/configDisableDialog.svg";
import { SBBox, SBTypography } from "modules/Commons";
import SBConfirmDialog from "modules/Commons/SBForms/SBConfirmDialog";
import { useTranslation } from "react-i18next";

export default ({ open, onClose, countOfItems, onConfirm, onCancel }: any) => {
	const { t } = useTranslation();
	return (
		<SBConfirmDialog
			key={open}
			open={open}
			title={t("disable")}
			onConfirm={onConfirm}
			onCancel={onCancel}
			onClose={onClose}
			confirmColor="primary"
		>
			<SBBox display="flex" flexDirection="column" width="25rem">
				<SBBox mx="auto">
					<img
						height={140}
						width={190.21}
						src={configDisableDialog}
					/>
				</SBBox>
				<SBTypography mt="1.5rem" color="blackTitle.main">
					{t("disableItemsFromSystem", { itemsCount: countOfItems })}
				</SBTypography>
			</SBBox>
		</SBConfirmDialog>
	);
};
