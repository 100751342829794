import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	cronConfigurationContainerStyles: {
		"& input": {
			background: "#FFFFFF",
		},
	},
	cronConfigurationFormStyles: {
		display: "flex",
		flexDirection: "column",
		marginTop: "16px",
	},
	cronConfigurationRadioStyles: {
		display: "none",
	},
}));
