import { useDropzone } from "react-dropzone";
import { forwardRef, useCallback, useState } from "react";
import upload from "assets/upload.svg";

import { SBBox, SBTypography, SBButton, SBIcon } from "modules/Commons";
import { SBTranslate } from "i18n";
import { useStyles } from "./style";
import clsx from 'clsx';
import { FormControlLabel, Radio } from "@mui/material";
import { useSystemLanguage } from "hooks/useSystemLanguage";

export default forwardRef((props: SBFileUploaderProps, ref) => {
	const { acceptedType, setFileName = () => { }, onUpload = () => { }, handleIncorrectType = () => { }, onUploadRaw = () => { }, style } = props;
	const classes = useStyles();
	const { getLanguage } = useSystemLanguage();
	const [onDrag, setOnDrag] = useState<boolean>(false);
	const [uploadLanguage, setUploadLanguage] = useState(getLanguage())

	const csvJSON = (csv: string) => {
		return csv.split("\r\n").filter((f) => f.length > 0);
	};

	const onDrop = useCallback(
		(acceptedFiles: any) => {
			setOnDrag(false);
			if (acceptedFiles[0].type !== "text/csv" && acceptedFiles[0].type !== "application/vnd.ms-excel") {
				handleIncorrectType();
				return;
			}
			setFileName(acceptedFiles[0].path);
			const reader = new FileReader();

			reader.onload = function (e: any) {
				const text = e.target.result;
				onUpload(csvJSON(text));
			};

			onUploadRaw(acceptedFiles[0], acceptedFiles[0].type, uploadLanguage)
			reader.readAsText(acceptedFiles[0]);
		},
		[onUpload]
	);

	const onDragEnter = () => setOnDrag(true)

	const onDragLeave = () => setOnDrag(false)

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		onDragEnter,
		onDragLeave,
		noClick: true
	});

	return (
		<SBBox
			{...getRootProps()}
			className={clsx(classes.dragAreaContainer, onDrag && "onDrag")}
		>
			<SBBox
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				pb={4}
			>
				<SBBox my={3}>
					<input {...getInputProps()} />
					<img src={upload} alt="img to upload" />
				</SBBox>

				<SBTypography variant="h5" fontSize="18px" textTransform="uppercase" color="darkPurple.main" fontWeight="700" mb={1}>
					{SBTranslate("dragACSVFileHere")}
				</SBTypography>
				<SBBox height={33}>
					{onDrag ?
						<SBTypography variant="h6">
							{SBTranslate("dropYourFile")}
						</SBTypography>
						:
						<SBBox >
							<SBButton variant="contained" onClick={open}>
								<SBIcon icon="upload" color="#fff" />
								<SBTypography ml={1} color="white.main" textTransform="none">
									{SBTranslate("searchInYourComputer")}
								</SBTypography>
							</SBButton>
							<SBTypography mt={4} mb={1} textAlign="center">
								{SBTranslate("selectLanguage")}
							</SBTypography>
							<SBBox display="flex" justifyContent="center">
								<FormControlLabel
									label={SBTranslate("pt-BR") as string}
									control={
										<Radio
											checked={uploadLanguage == "pt-BR"}
											onChange={() => setUploadLanguage("pt-BR")}
										/>
									}
								/>
								<FormControlLabel
									label={SBTranslate("en-US") as string}
									control={
										<Radio
											checked={uploadLanguage == "en-US"}
											onChange={() => setUploadLanguage("en-US")}
										/>
									}
								/>
							</SBBox>
						</SBBox>
					}
				</SBBox>
			</SBBox>
		</SBBox>
	);
});

interface SBFileUploaderProps {
	onUpload?: (v: any) => void;
	onUploadRaw?: (v: any, t: string, lang: string) => void;
	setFileName?: (v: any) => void;
	acceptedType?: string;
	style?: any;
	handleIncorrectType?: () => void;
}