import { TableBody, TableCell, TableRow } from "@mui/material";
import { useState } from "react";

export default ({
	page,
	itemsOnPage,
	rowsPerPage,
	rows,
}: SBTableEmptyRowProps) => {
	const [minEmptyRows] = useState(10);
	const [rowHeight] = useState(33.08);

	const emptySpace =
		minEmptyRows -
		Math.min(
			rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
				.length,
			rowsPerPage
		);

	const emptyRows = Math.max(
		emptySpace,
		Math.max(0, (1 + page) * rowsPerPage - rows.length)
	);

	const height = rowHeight * (emptyRows - (itemsOnPage ?? 0) + 0.85);

	return (
		<>
			{emptyRows > 0 && (
				<TableRow style={{ height }}>
					<TableCell style={{ borderBottom: "none" }} />
				</TableRow>
			)}
		</>
	);
};

export interface SBTableEmptyRowProps {
	page?: any;
	rowsPerPage?: any;
	rows?: any;
	itemsOnPage?: number;
}
