import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	outlinedButton: {
		borderColor: "#7F51FF",
		borderRadius: 4,
		borderWidth: "5px",
		textTransform: "capitalize !important",
		fontWeight: "bold",
	},
}));
