import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import SBTypography from "../SBDataDisplays/SBTypography";
import SBBox from "../SBLayouts/SBBox";
import { useStyles } from "./style";
import { SBTranslate } from "i18n";

export default ({
	value,
	row,
	onClick,
	loading,
	formatter,
	indicatorBorder
}: SBValueWithIndicatorProps) => {
	const classes = useStyles();
	const hasValue: boolean = value.value !== null && value.value !== undefined;
	return (
		<SBBox
			px={2}
			py={1}
			className={clsx(
				classes.sbValueWithIndicator,
				!!onClick && "hover",
				row && "row"
			)}
			onClick={onClick}
		>
			<SBBox className={clsx(classes.label)}>
				<SBBox
					mr={2}
					style={{
						backgroundColor: value.color,
						border: indicatorBorder ?? ""
					}}
					className={clsx(classes.indicator, !hasValue && "nullValueIndicator")}
				/>
				<SBTypography textTransform="none" className={clsx(!hasValue && classes.nullValueText)}>
					{value.label}
				</SBTypography>
			</SBBox>
			<SBBox display="flex" ml="auto" alignItems="center">
				{loading ? (
					<CircularProgress size={20} />
				) : (
					hasValue ? (
						<>
							<SBBox>{formatter(value.value)}</SBBox>
							<SBBox ml={1}>{value.formattedValue}</SBBox>
						</>
					) :
						<SBBox className={classes.nullValueText}>{SBTranslate("notConfigured")}</SBBox>
				)}
			</SBBox>
		</SBBox >
	);
};

interface SBValueWithIndicatorProps {
	onClick?: () => void;
	row?: boolean;
	loading?: boolean;
	value: {
		label: string;
		color: string;
		value?: number;
		formattedValue?: string;
	};
	formatter: (val?: number) => string;
	indicatorBorder?: string;
}
