import { SBBox, SBTypography } from "modules/Commons";
import { useTranslation } from "react-i18next";
import transferenceDatePlaceholder from "assets/transferenceDatePlaceholder.svg";

export default () => {
	const { t } = useTranslation();
	return (
		<SBBox
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			pt={20}
		>
			<img height={200} src={transferenceDatePlaceholder} alt="" />
			<SBTypography
				mt={2}
				py={2}
				variant="h5"
				color="darkPurple.main"
				fontWeight="700"
			>
				{t("transferenceDatePlaceholderTitle")}
			</SBTypography>

			<SBTypography
				textAlign="center"
				variant="body1"
				fontWeight="400"
				width={0.3}
			>
				{t("transferenceDatePlaceholderSubTitle")}
			</SBTypography>
		</SBBox>
	);
};
