import { IPolicyCost } from "domain/Inventory/Models/IPolicyCost";
import { ISimulation } from "modules/Simulations/models/ISimulation";
import { SBBox, SBTypography, SBGrid, SBTooltip } from "modules/Commons";
import { SBTableColumn } from "modules/Commons/SBDataDisplays/SBTable/models/TableColumn";
import { useTranslation } from "react-i18next";
import SBBasicTable from "views/Commons/SBBasicTable";
import { useStyles } from "./style";
import { IShortageCost } from "domain/Inventory/Models/IShortageCost";

export default ({ simulationRow }: SimulationCardCostProps) => {
	const { t } = useTranslation();
	const { projectedCost, realCost } = simulationRow;
	const classes = useStyles();

	const columns = [
		{
			name: t("name"),
			id: (e) => <SBTypography fontWeight="700">{e.name}</SBTypography>,
		},
		{
			name: t("purchase"),
			id: (e) => e?.purchased?.systemFormatAsMoney() ?? 0,
		},
		{
			name: t("order"),
			id: (e) => e?.order?.systemFormatAsMoney() ?? 0,
		},
		{
			name: t("holding"),
			id: (e: IPolicyCost & { name: string }) =>
				e?.holding?.systemFormatAsMoney() ?? 0,
		},
		{
			name: t("shortage"),
			id: (e) => e?.shortage?.total?.systemFormatAsMoney() ?? 0,
		},
		{
			name: t("totalRelevantCost"),
			id: (e) => e?.relevantCosts?.systemFormatAsMoney() ?? 0,
		},
	] as SBTableColumn<IPolicyCost>[];

	const shortageDiff = (real: IShortageCost, projected: IShortageCost) => {
		return Object.keys(real).reduce((a, b: string) => {
			//@ts-ignore
			return { ...a, [b]: real[b] - projected[b] };
		}, {} as IShortageCost);
	};

	var savingsCost = Object.keys(realCost).reduce((a, b: string) => {
		if (b === "shortage")
			return { ...a, [b]: shortageDiff(realCost[b], projectedCost[b]) };
		//@ts-ignore
		return { ...a, [b]: realCost[b] - projectedCost[b] };
	}, {} as IPolicyCost);

	var savings = realCost.relevantCosts - projectedCost.relevantCosts;
	return (
		<SBBox
			style={{
				border: "1px solid #eeeeeeaa",
				padding: "3px 10px",
				borderRadius: "4px",
			}}
		>
			<SBTooltip
				placement="right"
				arrow
				classes={{
					tooltip: classes.tooltip,
					arrow: classes.arrow,
				}}
				title={
					<SBBox className={classes.box}>
						<SBBasicTable<IPolicyCost>
							rows={[
								{ ...projectedCost, name: t("projected") },
								{ ...realCost, name: t("real") },
								{ ...savingsCost, name: t("savings") },
							]}
							columns={columns}
							className={classes.simulationCardCost}
						/>
					</SBBox>
				}
			>
				<SBGrid container>
					<SBGrid item xs={4}>
						<SBTypography variant="subtitle2" color="iconGray.main">
							{t("projected")}
						</SBTypography>
						<SBTypography
							variant="subtitle2"
							color="projected.main"
						>
							{projectedCost.relevantCosts.systemFormatAsMoney()}
						</SBTypography>
					</SBGrid>
					<SBGrid item xs={4}>
						<SBTypography variant="subtitle2" color="iconGray.main">
							{t("real")}
						</SBTypography>
						<SBTypography variant="subtitle2" color="real.main">
							{realCost.relevantCosts.systemFormatAsMoney()}
						</SBTypography>
					</SBGrid>
					<SBGrid item xs={4}>
						<SBTypography variant="subtitle2" color="iconGray.main">
							{t("savings")}
						</SBTypography>
						<SBTypography
							variant="subtitle2"
							color={
								savings > 0 ? "darkPurple.main" : "error.main"
							}
						>
							{savings.systemFormatAsMoney()}
						</SBTypography>
					</SBGrid>
				</SBGrid>
			</SBTooltip>
		</SBBox>
	);
};

interface SimulationCardCostProps {
	simulationRow: Omit<ISimulation, "result">;
}
