import { IHomeStatistics } from "domain/Utils/models/IHomeStatistics";
import { SBTranslate } from "i18n";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useSystemTheme } from "themes/base"

export default ({ statistics }: SBForecastPeriodRangeProps) => {
	const { palette } = useSystemTheme();

	const [start, end] = useMemo(() => {
		return [
			DateTime.fromISO(
				statistics.evaluation?.demandEvaluationScope?.start
			).toFormat(SBTranslate("dateFormat")),
			DateTime.fromISO(
				statistics.evaluation?.demandEvaluationScope.end
			).toFormat(SBTranslate("dateFormat")),
		];
	}, []);

	return (<div
		style={{
			position: "absolute",
			top: -10,
			left: 20,
			backgroundColor: palette.gray7.main,
			color: palette.gray2.main,
			fontSize: 12,
			padding: "0 10px",
			fontWeight: "600",
		}}
	>
		{`${start} - ${end}`}
	</div>)
}

interface SBForecastPeriodRangeProps {
	statistics: IHomeStatistics;
}