import { SBBox, SBTypography } from "modules/Commons";
import chartNoData from "assets/chartNoData.svg";
import { useTranslation } from "react-i18next";

export default () => {
	const { t } = useTranslation();
	return (
		<SBBox
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height={0.8}
			marginTop={2}
		>
			<img height={80} src={chartNoData} alt="" />
			<SBTypography
				mt={2}
				py={2}
				color="gray"
				fontWeight="600"
				marginTop={-3}
			>
				{t("noDataToDisplay")}
			</SBTypography>
		</SBBox>
	);
};
