import { SBBox, SBCard, SBLoading } from "modules/Commons";
import { useDataProofHeaders } from "./useDataProofHeaders";
import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { SBNewTable } from "modules/Commons/SBDataDisplays/SBTable/SBNewTable";
import SBTreemapMultilevel from "modules/Commons/SBChart/SBTreemapMultilevel";
import { ValidationsRequester } from "modules/DataProof/services/ValidationsRequester";
import { IValidation } from "domain/Utils/models/IValidation";
import ConfigViewEmptyState from "views/ConfigsView/ConfigViewEmptyState";
import SBEmptyState from "modules/Commons/SBLayouts/SBEmptyState";
import { IDataFail } from "domain/Utils/models/IDataFail";
import SBDonutChart from "modules/Commons/SBDataDisplays/SBDonutChart";
import { useSystemTheme } from "themes/base";
import { Divider, Stack } from "@mui/material";
import SBDataProofToolbarContent from "views/Commons/SBDataProofToolbarContent";
import SBComposedFilter from "views/Commons/SBComposedFilter";
import { IComposedFilter } from "domain/Utils";

export default () => {
	const theme = useSystemTheme();
	const { t } = useTranslation();
	const headers = useDataProofHeaders();
	const [validations, setValidations] = useState<IValidation[]>([]);
	const [fails, setFails] = useState<IDataFail[]>([]);
	const [storesValidation, setStoresValidation] = useState({
		okStores: 0,
		warningStores: 0,
		errorStores: 0,
		totalStores: 0,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [updatingTable, setUpdatingTable] = useState<boolean>(false);

	const [accessibles] = useState([
		"validationStatus",
		"inventory.product.category.clientID",
		"inventory.product.clientID",
	]);

	const okStores = useMemo(
		() => ({
			color: theme.palette.success.main,
			label: t("ok"),
			value: storesValidation?.okStores ?? 0,
		}),
		[storesValidation]
	);

	const warningStores = useMemo(
		() => ({
			color: theme.palette.warning.main,
			label: t("warning"),
			value: storesValidation?.warningStores ?? 0,
		}),
		[storesValidation]
	);

	const errorStores = useMemo(
		() => ({
			color: theme.palette.error.main,
			label: t("fail"),
			value: storesValidation?.errorStores ?? 0,
		}),
		[storesValidation]
	);

	const totalStores = useMemo(
		() => ({
			value: storesValidation.totalStores,
			label: t("total"),
			color: "#4F4F4F",
		}),
		[storesValidation]
	);

	const updateValidations = (data: { values: IValidation[] }[]) => {
		setUpdatingTable(true);

		if (data.length > 0) {
			var failData = data.flatMap((c) => c.values);
			setFails(getFails(failData));
		}

		var newData = data
			.flatMap((c) => c.values)
			.flatMap((c) => c.fails.map((d) => ({ ...d, ...c })));
		setValidations(newData);
		setTimeout(() => {
			setUpdatingTable(false);
		}, 1);
	};

	const getValidations = (filter: IComposedFilter) => {
		setLoading(true);
		ValidationsRequester.validations(filter)
			.then((validations) => {
				setValidations(validations);
				setFails(getFails(validations));
				setStoresValidation(getStoresValidation(validations));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getFails = (data: IValidation[]) => {
		const failsLoaded = [] as IDataFail[];
		data.forEach((validation) => {
			validation.fails.forEach((fail) => {
				failsLoaded.push({
					inventory: validation.inventory,
					status: fail.status,
					type: fail.type,
					field: fail.field,
					value: fail.value,
					date: fail.date,
				});
			});
		});
		return failsLoaded;
	};

	const checkValidationStatus = (storeValidation: any, status: string) => {
		return storeValidation.find(
			(validation: IValidation) => validation.validationStatus === status
		)
			? true
			: false;
	};

	const getStoresValidation = (data: IValidation[]) => {
		let storesWithError = 0;
		let storesWithWarning = 0;
		let storesWithOk = 0;

		const validationsByStores = data.groupBy((validation: IValidation) => {
			return validation.inventory.store.clientID;
		});
		for (const store in validationsByStores) {
			if (checkValidationStatus(validationsByStores[store], "Fail"))
				storesWithError++;
			else if (
				checkValidationStatus(validationsByStores[store], "Warning")
			)
				storesWithWarning++;
			else storesWithOk++;
		}
		return {
			okStores: storesWithOk,
			warningStores: storesWithWarning,
			errorStores: storesWithError,
			totalStores: Object.keys(validationsByStores).length,
		};
	};

	useEffect(() => {
		getValidations({});
	}, []);

	return (
		<SBBox>
			<SBLoading loading={loading}>
				<SBEmptyState
					emptyState={<ConfigViewEmptyState />}
					show={!!validations}
				>
					<SBBox pl={11}>
						<SBBox mb={2}>
							<SBComposedFilter.Base onApply={getValidations}>
								<SBComposedFilter.Filter />
								<SBComposedFilter.Applicator />
							</SBComposedFilter.Base>
						</SBBox>
						<SBBox mb={2}>
							<SBCard>
								<Stack
									p={"24px"}
									direction="row"
									alignItems="center"
									spacing={3}
									divider={
										<Divider
											orientation="vertical"
											flexItem
										/>
									}
								>
									<SBTreemapMultilevel
										data={validations}
										accessibles={accessibles}
										updateValidations={updateValidations}
									/>
									<SBDonutChart
										items={[
											okStores,
											warningStores,
											errorStores,
										]}
										label={<div>{t("stores")}</div>}
										total={totalStores}
										height={180}
										innerRadius={70}
										outerRadius={90}
										fontSize={"25px"}
									/>
								</Stack>
							</SBCard>
						</SBBox>

						<SBBox
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<SBLoading
								loading={updatingTable}
								limited
								fullWidth
							>
								<SBNewTable
									virtualize
									startRows={fails}
									defaultColumns={headers}
									containerHeight={458}
									title={t("alerts")}
									onClick={() => ""}
									fullWidth={fails.length > 0 ? false : true}
									toolbarContent={({ handleSearch }: any) => (
										<SBDataProofToolbarContent
											fails={fails}
											handleSearch={handleSearch}
										/>
									)}
								/>
							</SBLoading>
						</SBBox>
					</SBBox>
				</SBEmptyState>
			</SBLoading>
		</SBBox>
	);
};
