import { SBBox, SBTypography } from "modules/Commons";
import SBConfirmDialog from "modules/Commons/SBForms/SBConfirmDialog";
import packagePlus from "assets/packagePlus.svg"
import { SBTranslate } from "i18n";

export default ({
	open,
	onClose,
	onConfirm,
	onCancel,
}: {
	open: boolean;
	onClose: () => any;
	onConfirm: () => any;
	onCancel: () => any;
}) => {
	return (
		<SBConfirmDialog
			key={open + ""}
			open={open}
			title={SBTranslate("OnDemandRecommendationTitle")}
			onConfirm={onConfirm}
			onCancel={onCancel}
			onClose={onClose}
			confirmColor="primary"
		>
			<SBBox display="flex" flexDirection="column" width="25rem">
				<SBBox mx="auto">
					<img height={140} width={190.21} src={packagePlus} />
				</SBBox>
				<SBTypography mt="1.5rem" color="blackTitle.main">
					{SBTranslate("OnDemandRecommendationCardDescription")}
				</SBTypography>
				<SBTypography mt="1.5rem" color="blackTitle.main">
					{SBTranslate("notificationWarning")}
				</SBTypography>
			</SBBox>
		</SBConfirmDialog>
	);
};
