import { IChart } from "domain/Chart/Entities/IChart";
import { IChartFilter } from "domain/Chart/Entities/IChartFilter";
import { IEntity } from "domain/Domain/Entities/IEntity";
import { ITimeScope } from "domain/Domain/Models/ITimeScope";
import { ComposedFilterEvent, IComposedFilter } from "domain/Utils";
import useArrayState from "hooks/useArrayState";
import { useCSV } from "hooks/useCSV";
import { DateTime } from "luxon";
import { SBBox, SBDataTable, SBLoading } from "modules/Commons";
import SBEmptyState from "modules/Commons/SBLayouts/SBEmptyState";
import { useSnackbar } from "modules/Commons/SBOverlays/SBSnackbar";
import {
	ISimulationPoint,
	SimulationDatePlaceholder,
	SimulationPeriod,
	SimulationsRequester,
	SimulationTableFilter,
	SimulationUnfold,
} from "modules/Simulations";
import { useSimulationCharts } from "modules/Simulations/hooks/useSimulationCharts";
import { ISimulation } from "modules/Simulations/models/ISimulation";
import { SimulationFilterBody } from "modules/Simulations/services/SimulationsRequester";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SBComposedFilter from "views/Commons/SBComposedFilter";

export const SimulationView = () => {
	const chartID = `chart-simulation-view`;
	const tableRef = useRef<any>();

	const { t } = useTranslation();
	const { error } = useSnackbar();
	const csvWorker = useCSV();
	const { charts, filters, variables } = useSimulationCharts();

	const [dateRange, setDateRange] = useState<ITimeScope>(null as any);
	const [loading, setLoading] = useState(false);
	const [rows, setRows, { hasAny }] = useArrayState<ISimulation>([]);
	const [selectedChart, setSelectedChart] = useState(variables[0].name);
	const [ignoreDateConstraint, setIgnoreDateConstraint] = useState(false);
	const [simulationFilters, setSimulationFilters] = useState(filters);
	const [simulationCharts, setSimulationCharts] = useState(charts);
	const [simulationPeriod, setSimulationPeriod] = useState(
		SimulationPeriod.Daily
	);

	const changeFilters = (filter: IChartFilter) => {
		setSimulationFilters((f) =>
			f.triggerChange<IChartFilter>({
				condition: (item) => item.name === filter.name,
				newValue: (item) => ({ ...item, show: !item.show }),
			})
		);
	};

	const ruptureFilter = (item: any) => {
		const filter = simulationFilters.find((c) => c.name === "rupture");

		if (filter && filter.show)
			return item.result.realRuptured || item.result.projectedRuptured;
		return true;
	};

	const changeShowingCharts = (chart: IChart<ISimulationPoint>) => {
		setSimulationCharts((c) =>
			c.triggerChange<IChart<ISimulationPoint>>({
				condition: (item) => item.name === chart.name,
				newValue: (item) => ({ ...item, show: !item.show }),
			})
		);
	};

	const handleApply = (obj: IComposedFilter) => {
		const picker = obj.picker;
		if (picker && picker.range?.startDate && picker.range?.endDate)
			fetchSimulations(obj.filter, picker.range?.startDate, picker.range?.endDate);
		else setLoading(false);
	};

	const handleOnChange = (obj: IComposedFilter) => {
		if (obj.event === ComposedFilterEvent.Slider) setDateRange(obj.slider!);
	};

	const dateFilter = (c: any) => {
		if (!dateRange) return true;
		return (
			DateTime.fromFormat(c.date, simulationPeriod) >=
			dateRange.startDate! &&
			DateTime.fromFormat(c.date, simulationPeriod) <= dateRange.endDate!
		);
	};

	const fetchSimulations = (items: any, startDate: any, endDate: any) => {
		setLoading(true);
		setRows([]);

		SimulationsRequester.simulate(
			buildBody({
				startDate,
				endDate,
				items,
				simulationPeriod,
			})
		)
			.then((c) =>
				c && c.length > 0 ? setRows(c) : error(t("simulationNotFound"))
			)
			.catch(() => error(t("simulationError")))
			.finally(() => setLoading(false));
	};

	const exportCSV = (composedFilter: IComposedFilter) => {
		baseCSVExport(
			composedFilter,
			SimulationsRequester.simulateCSV,
			"Simulation"
		);
	};

	const exportSummarizedCSV = (composedFilter: IComposedFilter) => {
		baseCSVExport(
			composedFilter,
			SimulationsRequester.summarizedCSV,
			"Simulation_Summarized"
		);
	};

	const baseCSVExport = (
		composedFilter: IComposedFilter,
		func: (body: SimulationFilterBody) => Promise<string>,
		title: string
	) => {
		const items = composedFilter.filter ?? [];
		const startDate = composedFilter.picker?.range?.startDate;
		const endDate = composedFilter.picker?.range?.endDate;

		if (startDate && endDate) {
			setLoading(true);
			func(
				buildBody({
					startDate,
					endDate,
					items,
					simulationPeriod,
				})
			)
				.then((csv) => downloadCSV(csv, title))
				.catch(() => error(t("csvDownloadError")))
				.finally(() => setLoading(false));
		}
	};

	const downloadCSV = (csv: string, name: string) => {
		csvWorker.downloadCSV({
			csv,
			title: `${name}_${DateTime.now().toFormat("dd_MM_yyyy")}`,
		});
	};

	const buildBody = (props: {
		startDate: DateTime;
		endDate: DateTime;
		items: IEntity[];
		simulationPeriod: SimulationPeriod;
	}): SimulationFilterBody => {
		return {
			startDate: props.startDate,
			endDate: props.endDate,
			products: props?.items?.filter((c: any) => c.type === "product"),
			categories: props?.items?.filter((c: any) => c.type === "category"),
			stores: props?.items?.filter((c: any) => c.type === "store"),
			timePeriod: simulationPeriod,
		} as SimulationFilterBody;
	};

	return (
		<SBBox id={chartID} pl={11}>
			<SBComposedFilter.Base
				filterRequired
				dateRangeRequired
				onApply={handleApply}
				onChange={handleOnChange}
			>
				<SBComposedFilter.Picker />
				<SBComposedFilter.Filter />
				<SBComposedFilter.Applicator />
				<SBComposedFilter.Actions
					disabled={!hasAny}
					actions={[
						{
							title: "summarized",
							onClick: (items) => exportSummarizedCSV(items),
						},
						{
							title: "simulation",
							onClick: (items) => exportCSV(items),
						},
					]}
				/>
				<SBComposedFilter.Slider />
			</SBComposedFilter.Base>

			<SBLoading loading={loading}>
				<SBEmptyState
					show={hasAny}
					emptyState={<SimulationDatePlaceholder />}
				>
					<SBBox my={2}>
						<SBDataTable
							ref={tableRef}
							pagination
							unfolding
							columns={[]}
							title={t("simulation_other")}
							rows={rows}
							noEmptyRows={false}
							filters={[ruptureFilter]}
							toolbarContent={
								<SimulationTableFilter
									filters={simulationFilters}
									setFilters={changeFilters}
									setSelectedChart={setSelectedChart}
									selectedChart={selectedChart}
									setChartsToShow={changeShowingCharts}
									chartsToShow={simulationCharts}
									simulationPeriod={simulationPeriod}
									onChangePeriod={(s) => {
										setIgnoreDateConstraint(
											SimulationPeriod.Daily !== s
										);
										setSimulationPeriod(
											s as SimulationPeriod
										);
									}}
								/>
							}
							unfoldingComponent={(item: any) => (
								<SimulationUnfold
									key={item.id}
									chartID={chartID}
									selectedChart={selectedChart}
									chartsToShow={simulationCharts}
									chartFilter={dateFilter}
									simulationPeriod={simulationPeriod}
									ignoreDateConstraint={ignoreDateConstraint}
									{...item}
								/>
							)}
						/>
					</SBBox>
				</SBEmptyState>
			</SBLoading>
		</SBBox>
	);
};
