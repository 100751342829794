import { Divider } from "@mui/material";
import { SBBox, SBButton, SBIcon, SBTypography } from "modules/Commons";
import UploadButtonAction from "./UploadButtonAction";
import { useStyles } from "./style";
import clsx from "clsx";
import { useSystemTheme } from "themes/base";

export default (props: ConfigUploadActionsProps) => {
	const { currentState, restartUpload, fileName, onConclude } = props;
	const classes = useStyles();
	const theme = useSystemTheme();

	const actionsIsOpened = () => (currentState !== "onUpload" && currentState !== "onSaved")

	const hideDeleteButton = () => currentState === "onError" || currentState === "onLoading" || currentState === "onSaving"

	return (
		<SBBox className={clsx(classes.actions, actionsIsOpened() && "open")}>
			<Divider className={classes.divider} />
			<SBBox className={clsx(classes.actionsButtons, hideDeleteButton() && "justifyEnd")}>
				<SBButton className={clsx(classes.deleteFileButton, hideDeleteButton() && "hide")} onClick={restartUpload}>
					<SBTypography mr={1} color="gray3.main" textTransform="none" noWrap>
						{fileName}
					</SBTypography>
					<SBIcon icon="trash-2" color={theme.palette.error.main} />
				</SBButton>
				<UploadButtonAction currentState={currentState} restartUpload={restartUpload} onConclude={onConclude} />
			</SBBox>
		</SBBox>
	)
}

interface ConfigUploadActionsProps {
	currentState: string;
	restartUpload: () => void;
	onConclude?: () => void;
	fileName: string;
}