import { IInventoryStatisticsSummary } from "domain/Inventory/Models/IInventoryStatisticsSummary";
import { SBForecastEvaluation } from "modules/Forecast/Components";

export default ({ statistics }: SBInventoryStatisticsStatisticsProps) => {
	return <SBForecastEvaluation statistics={statistics as any} breakLine />;
};

interface SBInventoryStatisticsStatisticsProps {
	statistics: IInventoryStatisticsSummary;
}
